import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { GuideCodeCard } from '../../../../..';

function LocationCodeCard({
  guideCategory,
  guideCategoryGroupId,
  designType,
  onChange = null,
  noOther = false,
  searchValue = null,
  selectedCodes = {},
}) {
  const [currentGuideCode, setCurrentGuideCode] = useState(
    !guideCategory.showDropdown ? guideCategory.guideCodes[0] : {},
  );

  useEffect(() => {
    if (!guideCategory.showDropdown) {
      setCurrentGuideCode(guideCategory.guideCodes[0]);
    }
  }, [guideCategory]);

  const handleChange = ({ data, value }) => {
    let selectedDropdownCode;
    if (value) {
      selectedDropdownCode = data.category.guideCodes.find(
        (item) => item.id === value,
      );
      setCurrentGuideCode(selectedDropdownCode);
    } else {
      [selectedDropdownCode] = data.category.guideCodes;
    }

    if (onChange) {
      onChange(selectedDropdownCode, data.category);
    }
  };

  const guideCode = guideCategory.guideCodes.find(
    (item) => item.id === currentGuideCode?.id,
  );

  return (
    <GuideCodeCard
      isSelected={selectedCodes[currentGuideCode?.id]}
      handleChange={handleChange}
      image={<GuideCodeCard.Image />}
      actionButtons={
        <GuideCodeCard.ActionButtons>
          <GuideCodeCard.ButtonFavourites />
          <GuideCodeCard.ButtonInformation />
          <GuideCodeCard.ButtonDefaults />
        </GuideCodeCard.ActionButtons>
      }
      cardDropdown={
        <GuideCodeCard.Dropdown handleDropdown={handleChange}>
          <GuideCodeCard.OtherInput
            hideCreateOtherButton={noOther}
            handleOtherValue={handleChange}
          />
        </GuideCodeCard.Dropdown>
      }
      footer={<GuideCodeCard.CardFooter />}
      data={{
        ...(currentGuideCode || { id: 'other' }),
        default: guideCode?.default,
        guideCategoryGroupId,
        category: guideCategory,
        designType,
        searchValue,
        imageUrl: guideCode?.imageUrl || guideCategory.imageUrl,
        briefDescription: guideCategory.showDropdown
          ? guideCode?.description || guideCategory.short_description
          : guideCategory.short_description,
        fullDescription: guideCategory.description,
      }}
    />
  );
}

LocationCodeCard.propTypes = {
  guideCategory: PropTypes.object.isRequired,
  selectedCodes: PropTypes.object,
  guideCategoryGroupId: PropTypes.string.isRequired,
  designType: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  searchValue: PropTypes.string,
  noOther: PropTypes.bool,
};

export default LocationCodeCard;
