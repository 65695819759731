import { UPDATE_ORDER_DESIGNER_STATUS } from '@fullcontour/shared-api';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import Log from '../../../../../config/log';
import { CreateNotification } from '../../../../shared';
import statusUpdate from './statusUpdate';

function downloadableFiles(combinedFilesArray, checkedTypes) {
  const files = [];

  combinedFilesArray.forEach((fileArray) => {
    checkedTypes.forEach((checkType) => {
      const filteredFiles = fileArray.filter(
        (file) => file.fileType === checkType,
      );

      if (filteredFiles && filteredFiles.length === 1) {
        files.push(filteredFiles[0]);
      }

      if (filteredFiles && filteredFiles.length > 1) {
        const latestVers = filteredFiles.map((f) => f.latestVer);
        // eslint-disable-next-line prefer-spread
        const highestVer = Math.max.apply(Math, latestVers);
        const latestVerFiles = filteredFiles.filter(
          (f) => f.ver === highestVer,
        );

        files.push(latestVerFiles);
      }
    });
  });

  return files.flat();
}

function downloadFile(
  downloadableFile,
  updateDownloadProgress,
  setDownloading,
  setDownloadingOff,
) {
  setDownloading();

  const options = {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'binary/octet-stream',
    },
    onDownloadProgress: (progressEvent) => {
      const percent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (percent >= 100) {
        updateDownloadProgress(100);
        setDownloadingOff();
      } else {
        updateDownloadProgress(percent);
      }
    },
  };

  axios
    .get(downloadableFile.downloadUrl, options)
    .then((response) => {
      const u = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = u;
      link.setAttribute('download', downloadableFile.originalFileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => Log.error(error));
}

function filesDownload(
  combinedFilesArray,
  checkedTypes,
  client,
  statusAction,
  updateDownloadProgress,
  setDownloading,
  setDownloadingOff,
  orderState,
  hexOrderCode,
  selectedCasesNum,
) {
  const files = downloadableFiles(combinedFilesArray, checkedTypes);
  // ZIP file for quality control images only
  if (
    checkedTypes.length === 1 &&
    checkedTypes[0] === 'quality_control_images'
  ) {
    CreateNotification.createNotification({
      type: 'info',
      message: `Processing images...`,
    });
    let count = 0;
    const zip = new JSZip();
    const zipFilename = `${hexOrderCode}-quality-control-images.zip`;
    files.map((file) => {
      JSZipUtils.getBinaryContent(file.downloadUrl, (err, data) => {
        if (err) {
          CreateNotification.createNotification({
            type: 'error',
            message: `Something went wrong`,
          });
        }
        zip.file(file.originalFileName, data, {
          binary: true,
          compression: 'DEFLATE',
        });
        count += 1;
        if (count === files.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            CreateNotification.createNotification({
              type: 'success',
              message: `Images were successfully generated`,
            });
            saveAs(content, zipFilename);
          });
        }
      });
      return null;
    });
  } else {
    files.forEach((downloadableFile) => {
      downloadFile(
        downloadableFile,
        updateDownloadProgress,
        setDownloading,
        setDownloadingOff,
      );
      if (statusAction) {
        const params = [
          client,
          statusAction,
          downloadableFile.orderId,
          null,
          null,
          selectedCasesNum,
        ];
        if (
          ['rush_design', 'hold_released', 'redesign'].includes(orderState) &&
          statusAction === 'start_design'
        ) {
          params.push(null, UPDATE_ORDER_DESIGNER_STATUS);
        }
        statusUpdate(...params);
      }
    });
  }
}

export default filesDownload;
