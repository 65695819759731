const reviewCase = {
  admin_portal: {
    visible: true,
    statuses: {
      needs_approval: [0, 10, 11, 20, 30, 40],
      sent_for_approval: [0, 10, 11, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      at_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      shipped: [0, 10, 20, 30, 40, 50, 60, 70],
      ready_for_download: [0, 10, 11, 20, 30, 40],
      complete: [0, 10, 11, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      needs_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_for_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_to_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      at_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      shipped: [0, 10, 20, 30, 40, 50, 60, 70],
      ready_for_download: [0, 10, 20, 30, 40, 50, 60, 70],
      complete: [0, 10, 20, 30, 40, 50, 60, 70],
    },
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: true,
    statuses: {
      redesign: [0, 5, 10, 20],
      hold_released: [0, 5, 10, 20],
      needs_approval: [0, 5, 10, 20],
      sent_for_approval: [0, 5, 10, 20],
      ready_for_download: [0, 5, 10, 20],
      complete: [0, 5, 10, 20],
    },
  },
};

export default reviewCase;
