const markComplete = {
  admin_portal: {
    visible: true,
    statuses: {
      on_hold: [0, 10, 11, 20, 30, 40],
      needs_approval: [0, 10, 11, 20, 30, 40],
      sent_for_approval: [0, 10, 11, 20, 30, 40],
      ready_for_download: [0, 10, 11, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 11, 20, 30, 40],
      at_manufacturer: [0, 10, 11, 20, 30, 40],
      shipped: [0, 10, 11, 20, 30, 40],
      manufacturing_hold: [0, 10, 11, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      on_hold: [0, 10, 20, 30, 40, 50, 60, 70],
      needs_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_for_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      ready_for_download: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_to_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      at_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      shipped: [0, 10, 20, 30, 40, 50, 60, 70],
    },
  },
  manuf_portal: {
    visible: true,
    statuses: {
      at_manufacturer: [0, 10],
      shipped: [0, 10],
      manufacturing_hold: [0, 10],
    },
  },
  designer_portal: {
    visible: false,
  },
};

export default markComplete;
