import { gql } from '@apollo/client';

const OrderInfoListCustomer = gql`
  fragment OrderInfoListCustomer on Order {
    id
    createdAt
    hexOrderCode
    intOrderId
    isRush
    name
    percentComplete
    requiresDesignApproval
    state
    itemsMetadata
    redesignCount
    source
    feedbacks {
      id
      userId
      redesignReason
      redesignCount
      rating
      answers {
        orderItem {
          id
          designTypeName
          designTypeId
        }
        id
        rating
        options {
          id
          name
          category {
            name
            id
          }
        }
      }
    }
    orderItems {
      designType {
        name
        id
        clearAlignerDesignType
        feedbackCategories {
          id
          name
          options {
            id
            name
          }
        }
      }
    }
    turnaroundTimeName
    approvalLogs(sortBy: "created_at", order: "asc", limit: 1) {
      doctorEmail
      id
    }
    location {
      id
      name
      slug
      subType
      fullViewer
      numberingSystem
      pdfAllMovementPerStage
      doctorApprovalLight
    }
  }
`;

export default OrderInfoListCustomer;
