import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

function timestampFormatter(date, showTime = true) {
  let stringFormat = 'MM/dd/yyyy';

  if (showTime) {
    stringFormat += ' h:mma';
  }

  return format(parseISO(date), stringFormat);
}

export default timestampFormatter;
