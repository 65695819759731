import { useQuery } from '@apollo/client';
import { GET_REPORT_TYPES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Dropdown from '../../../../../shared/FormFields/Dropdowns/Dropdown';

function ReportTypeSelect(props) {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue },
    setreporttype,
  } = props;

  const [reportTypes, setReportTypes] = useState(null);
  const [loads, setLoads] = useState(true);

  const handleChange = (e) => {
    const reportTypeObj = reportTypes.find(
      (element) => element.id === e.target.value,
    );
    setreporttype(reportTypeObj);
    setFieldValue('reportTypeId', e.target.value);
  };

  const formatOptions = (newReportTypes) =>
    [...newReportTypes]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((reportType) => {
        const { inputParams, singleDate, ...rest } = reportType;
        return { ...rest };
      });

  const { error, loading, data } = useQuery(GET_REPORT_TYPES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      setLoads(false);
      setReportTypes(d.reportTypes);
    },
  });

  return (
    <Dropdown
      name={name}
      label="Report Type"
      errors={!!(errors[name] && touched[name])}
      error={error}
      loading={loading}
      options={loading || loads || error ? [] : formatOptions(data.reportTypes)}
      onChange={handleChange}
      placeholder="Select a Report..."
      value={value}
      disabled={loading || loads || error}
      search
      required
    />
  );
}

ReportTypeSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  setreporttype: PropTypes.func.isRequired,
};

export default ReportTypeSelect;
