import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateUserSigninTime($input: UserSigninTimeUpdateInput!) {
    updateUserSigninTime(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }

  ${ErrorMessageInfo}
`;
