import { graphql } from '@apollo/client/react/hoc';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState, useEffect, memo } from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import BoxIcon from '../../../Icons/BoxIcon';
import { FieldContainer } from '../../FieldContainer';
import {
  activeStyle,
  baseStyle,
  rejectedStyle,
} from './FileDropzoneOrderMessageStyle';

function FileDropzoneOrderMessage({
  field,
  form,
  signS3,
  order,
  t,
  labelText = null,
  accept = {
    'image/*': ['.jpeg', '.png', '.jpg'],
    'application/*': ['.pdf'],
  },
  ...rest
}) {
  const props = { field, form, signS3, order, t, labelText, accept, ...rest };
  const { name } = field;
  const { setValues, values } = form;
  const [{ file, formattedFilename }, setState] = useState({
    file: null,
    formattedFilename: null,
  });
  useEffect(() => {
    if (file && formattedFilename) {
      signAndSubmit();
    }
  }, [file, formattedFilename]);
  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }
  async function onDrop(accepted) {
    const file = accepted[0];
    setState((state) => ({
      ...state,
      file,
      formattedFilename: formatFilename(file.name),
    }));
  }

  async function uploadToS3(signedRequest) {
    const { updateUploadProgress, setUploadingOff, setUploading } = props;
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          updateUploadProgress(100);
          setUploadingOff();
        } else {
          updateUploadProgress(percent);
        }
      },
    };

    setUploading();
    await axios.put(signedRequest, file, options);
  }

  async function signAndSubmit() {
    const s3Key = `orders/${order.lab_location_id}/${order.intOrderId}/messages/${formattedFilename}`;
    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: s3Key,
            acl: 'private',
            filetype: file.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = response.data.signS3.s3Response;

    await uploadToS3(signedRequest);

    setValues({
      ...values,
      imageUrl: url,
      fileType: file.type,
      s3Id: s3Key,
    });
  }

  return (
    <FieldContainer name={name} label={labelText || name}>
      <Dropzone
        accept={accept}
        multiple={false}
        onDrop={(accepted) => onDrop(accepted)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

          return (
            <div style={styles} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="is-flex is-justify-content-center is-align-items-center">
                <BoxIcon
                  name="bx-cloud-upload"
                  className="has-text-primary is-size-3 mr-2"
                />
                <span>
                  {t(`Drop an attachment here, or click/tap to select an attachment to
                    upload`)}
                </span>
              </div>
            </div>
          );
        }}
      </Dropzone>
    </FieldContainer>
  );
}

FileDropzoneOrderMessage.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  labelText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  signS3: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  setUploading: PropTypes.func.isRequired,
  setUploadingOff: PropTypes.func.isRequired,
};

export default flowRight(
  graphql(REQUEST_S3_SIGN, { name: 'signS3' }),
  withTranslation('formfields'),
)(memo(FileDropzoneOrderMessage));
