/* eslint-disable no-plusplus */
/* eslint-disable default-case */
/* eslint-disable no-console */
/* eslint-disable no-continue */
function updateObjText(t, diff) {
  let text = t;

  if (text.indexOf('\r\n') !== -1) {
    // This is faster than String.split with regex that splits on both
    text = text.replace(/\r\n/g, '\n');
  }

  if (text.indexOf('\\\n') !== -1) {
    // join lines separated by a line continuation character (\)
    text = text.replace(/\\\n/g, '');
  }

  const lines = text.split('\n');
  let line = '';
  let lineFirstChar = '';
  let lineLength = 0;
  let verticeIndex = 0;
  const indexMatch = [];

  // Faster to just trim left side of the line. Use if available.
  const trimLeft = typeof ''.trimLeft === 'function';

  for (let i = 0, l = lines.length; i < l; i++) {
    line = lines[i];

    line = trimLeft ? line.trimLeft() : line.trim();

    lineLength = line.length;

    if (lineLength === 0) continue;

    lineFirstChar = line.charAt(0);

    if (lineFirstChar === 'v') {
      const data = line.split(/\s+/);

      if (data[0] === 'v') {
        indexMatch.push({ line: i, vIndex: verticeIndex });

        verticeIndex += 1;
      }
    }
  }

  diff.forEach((d) => {
    const i = d.Index;
    const currentIndex = indexMatch.find((m) => m.vIndex === i);

    if (currentIndex) {
      lines[currentIndex.line] = `v ${d.X} ${d.Y} ${d.Z}`;
    }
  });

  return lines.join('\n');
}

export default updateObjText;
