import { useMutation } from '@apollo/client';
import { CREATE_REPORT } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function ResultsActions(props) {
  const { setResults, resetForm, newReportValues } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [reportName, setReportName] = useState('');

  const [create] = useMutation(CREATE_REPORT, {
    onCompleted: () => {
      setReportName('');
      setModalOpen(false);
    },
  });

  const resetAndClearResults = () => {
    resetForm();
    setResults(null);
  };

  const handleModalClose = () => {
    setReportName('');
    setModalOpen(false);
  };

  const createReport = () => {
    const variables = {
      variables: {
        input: {
          input: { ...newReportValues, name: reportName },
          clientMutationId: uuidv4(),
        },
      },
    };

    create(variables);
  };

  return (
    <>
      <button
        type="button"
        className="button is-small is-danger is-outlined"
        onClick={resetAndClearResults}
      >
        clear
      </button>
      <button
        type="button"
        className="button is-small is-primary"
        onClick={() => setModalOpen(true)}
      >
        Save Report
      </button>
      {modalOpen && (
        <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" />
          <div className="modal-card has-text-left">
            <header className="modal-card-head">
              <p className="modal-card-title">Save Report</p>
              <button
                className="delete"
                aria-label="close"
                type="button"
                onClick={() => handleModalClose()}
              />
            </header>

            <section className="modal-card-body">
              <form noValidate>
                <div className="field">
                  <label htmlFor="reportName" className="label">
                    Report Name
                  </label>
                  <div className="control">
                    <input
                      name="reportName"
                      className="input"
                      placeholder="Title your report..."
                      onChange={(e) => setReportName(e.target.value)}
                      value={reportName}
                    />
                  </div>
                </div>
              </form>
            </section>
            <footer className="modal-card-foot is-justify-content-flex-end">
              <button
                type="button"
                className="button is-primary"
                disabled={reportName === ''}
                onClick={createReport}
              >
                Submit
              </button>
            </footer>
          </div>
        </div>
      )}
    </>
  );
}

ResultsActions.propTypes = {
  setResults: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  newReportValues: PropTypes.object.isRequired,
};

export default ResultsActions;
