import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideBook($input: GuideBookDestroyInput!) {
    destroyGuideBook(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
