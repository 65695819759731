import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

function OutsideClick({ children, fn = () => {}, style = {}, className = '' }) {
  const wrapperRef = useRef(null);

  const handleClick = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      fn();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <div className={className} ref={wrapperRef} style={style}>
      {children}
    </div>
  );
}

OutsideClick.propTypes = {
  children: PropTypes.element.isRequired,
  fn: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default OutsideClick;
