import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BrowseFiles(props) {
  const { inputRef, onChangeHandler, files } = props;

  const { t } = useTranslation('orders');

  return (
    <div
      className={files.length && 'is-flex is-justify-content-center'}
      style={{
        padding: '50px 0',
        verticalAlign: 'middle',
        height: '100%',
      }}
    >
      <div
        className="dropSectionSegment mx-6 is-align-items-center is-flex is-justify-content-center"
        style={{
          height: '100%',
        }}
      >
        <input
          type="file"
          accept=".zip,.lab"
          aria-label="file-upload"
          name="file"
          ref={inputRef}
          style={{ display: 'none' }}
          multiple
          onChange={onChangeHandler}
        />
        <span
          className="icon is-clickable has-text-primary mb-4 my-5"
          style={{ fontSize: '5rem' }}
        >
          <i className="bx bx-cloud-upload" />
        </span>
        <h5 className="title is-5 mb-3 px-5 has-text-centered">
          {t(
            files.length > 0
              ? 'Drop additional cases here'
              : 'Drop your ZIP folder here to create a new case',
          )}
        </h5>
        <h6 className="title is-5 has-text-centered mb-3">{t('or')}</h6>
        <button
          type="button"
          onClick={() => inputRef.current.click()}
          className="button is-primary ml-3"
        >
          {t('Browse')}
        </button>
      </div>
    </div>
  );
}

BrowseFiles.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default BrowseFiles;
