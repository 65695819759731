import { useEffect } from 'react';

function useGetGuideCategoryGroups({
  selectedDesignType,
  filterSelected,
  values,
  groupId,
  setGuideCategoryGroups,
  data,
}) {
  useEffect(() => {
    if (data && selectedDesignType) {
      let guideCategoryGroupsFromDesignType =
        data.labLocationGuideCategoryGroups.find(
          (item) => item.designTypeId === selectedDesignType,
        ).guideCategoryGroups;
      if (filterSelected) {
        const selectedValues = Object.keys(values.selectedCodes).reduce(
          (obj, item) => {
            Object.keys(values.selectedCodes[item]).map((el) => {
              if (item === groupId) {
                // eslint-disable-next-line no-param-reassign
                obj[el] = true;
              }
              return null;
            });
            return obj;
          },
          {},
        );
        if (Object.keys(selectedValues).length) {
          guideCategoryGroupsFromDesignType =
            guideCategoryGroupsFromDesignType.map((item) => ({
              ...item,
              guideCategories: item.guideCategories.filter(
                (el) => !selectedValues[el.id],
              ),
            }));
        }
      }
      setGuideCategoryGroups(guideCategoryGroupsFromDesignType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedDesignType]);
}

export default useGetGuideCategoryGroups;
