import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Locize from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';

import { locizePlugin } from 'locize';
import { initReactI18next } from 'react-i18next';
import fcDetector from './lngDetect';

const isDevelopment = import.meta.env.VITE_ENV === 'development';
const isProduction = import.meta.env.VITE_ENV === 'production';

if (!isProduction) {
  i18next.use(LastUsed);
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector(fcDetector);

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
  referenceLng: 'en',
  version: isDevelopment ? 'latest' : import.meta.env.VITE_ENV,
  allowedAddOrUpdateHosts: [
    'localhost',
    '*.fullcontour.com',
    '*.3shape.com',
    '*.3shapedesign.services',
    '*.smilesummary.com',
  ],
};

i18next
  .use(Locize)
  .use(locizePlugin)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'navigation',
      'signin',
      'formfields',
      'profile',
      'formheaders',
      'orderactions',
      'pdfReport',
      'formFieldsErrors',
      'designTypeDescription',
      'orders',
      'aggrid',
      'common',
    ],
    defaultNS: 'signin',
    debug: false,
    fallbackLng: 'en',
    nonExplicitWhitelist: true,
    whitelist: ['en', 'de', 'es', 'fr', 'it', 'ko', 'pt', 'zh', 'tr'],
    saveMissing: isDevelopment,
    version: isDevelopment ? 'latest' : import.meta.env.VITE_ENV,
    keySeparator: false,
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    detection: {
      order: ['fcDetector', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'defaultLanguage',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18next;
