import { gql } from '@apollo/client';

export default gql`
  query rolesDropdown($modelScope: String!, $scopeToLocation: Boolean) {
    roles(modelScope: $modelScope, scopeToLocation: $scopeToLocation) {
      id
      name
      value
      scopeToLocation
      modelScope
    }
  }
`;
