import { useFormikContext } from 'formik';
import { useCallback } from 'react';

const updateFormikValues = (
  setValues,
  selectedCodes,
  values,
  groupId,
  deleteGroup,
) => {
  setValues({
    selectedCodes,
    deletedGroups: {
      ...(values.deletedGroups || {}),
      ...(deleteGroup ? { [groupId]: true } : {}),
    },
    updatedGroups: {
      ...(values.updatedGroups || {}),
      ...(!deleteGroup ? { [groupId]: true } : {}),
    },
  });
};

const useDeleteItems = () => {
  const { values, setValues } = useFormikContext();

  const deleteGroup = useCallback(
    (groupId) => {
      const { selectedCodes } = values;
      delete selectedCodes[groupId];
      updateFormikValues(setValues, selectedCodes, values, groupId, true);
    },
    [values, setValues],
  );

  const deleteCard = useCallback(
    (groupId, categoryId) => {
      const { selectedCodes } = values;
      delete selectedCodes[groupId][categoryId];
      updateFormikValues(setValues, selectedCodes, values, groupId);
    },
    [values, setValues],
  );

  return { deleteGroup, deleteCard };
};

export default useDeleteItems;
