function calculateTeethElements(objRefs, currentStage, iprs) {
  if (objRefs) {
    return objRefs.reduce((result, currentMesh) => {
      if (currentMesh?.name?.includes('SimpleTeeth')) {
        const toothNumber = parseInt(currentMesh?.name?.match(/\d+/gi), 10);

        const isCurrentNumberIncluded = iprs[toothNumber]?.showIPR;
        const geometryArray = currentMesh.geometry.attributes.position.array;
        const previousNumber = objRefs.find(
          (item) =>
            parseInt(item?.name?.match(/\d+/gi), 10) === toothNumber - 1,
        );

        const nextNumber = objRefs.find(
          (item) =>
            parseInt(item?.name?.match(/\d+/gi), 10) === toothNumber + 1,
        );

        if (isCurrentNumberIncluded) {
          if (toothNumber < 17) {
            let nextGeometryArray = null;

            if (
              nextNumber?.geometry?.attributes?.position?.array !== null &&
              nextNumber?.geometry?.attributes?.position?.array !== undefined
            ) {
              nextGeometryArray = nextNumber.geometry.attributes.position.array;
            }

            if (toothNumber === 9) {
              nextGeometryArray = objRefs.find(
                (item) => parseInt(item?.name?.match(/\d+/gi), 10) === 10,
              )?.geometry?.attributes?.position?.array;
            }

            const element = nextGeometryArray
              ? [geometryArray, nextGeometryArray]
              : [geometryArray];

            const teethNumbers = [
              toothNumber,
              toothNumber === 9
                ? 10
                : parseInt(nextNumber?.name?.match(/\d+/gi), 10),
            ];
            const toothElement = {
              teeth: teethNumbers,
              teethVertexes: element,
            };

            result.push(toothElement);
          } else if (toothNumber >= 17) {
            let previousGeometryArray = null;

            if (
              previousNumber?.geometry?.attributes?.position?.array !== null &&
              previousNumber?.geometry?.attributes?.position?.array !==
                undefined
            ) {
              previousGeometryArray =
                previousNumber.geometry.attributes.position.array;
            }

            const element = previousGeometryArray
              ? [previousGeometryArray, geometryArray]
              : [geometryArray];

            const teethNumbers = [
              parseInt(previousNumber?.name?.match(/\d+/gi), 10),
              toothNumber,
            ];

            const toothElement = {
              teeth: teethNumbers,
              teethVertexes: element,
            };

            result.push(toothElement);
          } else {
            const teethNumbers = [toothNumber];
            const toothElement = {
              teeth: teethNumbers,
              teethVertexes: [geometryArray],
            };

            result.push(toothElement);
          }
        }
      }

      return result;
    }, []);
  }

  return [];
}

export default calculateTeethElements;
