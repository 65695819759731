import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import OutsideClick from '../../HelperFunctions/OutsideClick';
import BoxIcon from '../../Icons/BoxIcon';

function BasicDropdown({
  children,
  disabled = false,
  contentClasses = '',
  menuClasses = '',
  iconName = 'bx-cog',
  hideIcon = false,
  iconWrapperStyle = {},
  iconStyle = {},
  iconWrapperClasses = '',
  dropdownClasses = '',
  triggerClasses = '',
  buttonClasses = '',
  buttonText = '',
  hideChevron = false,
  noStyles = false,
  activeOverride = null,
  setActiveOverride = null,
  dropdownStyle = {},
  triggerStyle = {},
  buttonStyle = {},
  menuStyle = {},
  contentStyle = {},
  buttonTextStyle = {},
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownOpen = () => {
    if (setActiveOverride) {
      setActiveOverride((prev) => !prev);
    } else {
      setDropdownOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    setDropdownOpen(activeOverride);
  }, [activeOverride]);

  return (
    <OutsideClick fn={() => setDropdownOpen(false)}>
      <div
        className={`dropdown ${
          dropdownOpen ? 'is-active' : ''
        } ${dropdownClasses}`}
        style={dropdownStyle}
      >
        <div
          className={`dropdown-trigger ${triggerClasses}`}
          style={triggerStyle}
        >
          <button
            className={`button ${buttonClasses} ${
              noStyles ? '' : 'is-primary'
            }`}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            type="button"
            onClick={handleDropdownOpen}
            disabled={disabled}
            style={buttonStyle}
          >
            {!hideIcon && (
              <BoxIcon
                name={iconName}
                style={iconWrapperStyle}
                iconStyle={iconStyle}
                className={iconWrapperClasses}
              />
            )}
            {buttonText !== '' && (
              <>
                <p style={buttonTextStyle}>{buttonText}</p>
                {!hideChevron && (
                  <BoxIcon
                    name="bx-chevron-down"
                    style={iconWrapperStyle}
                    iconStyle={iconStyle}
                    className={iconWrapperClasses}
                  />
                )}
              </>
            )}
          </button>
        </div>
        <div
          className={`dropdown-menu ${menuClasses}`}
          id="dropdown-menu"
          role="menu"
          style={menuStyle}
        >
          <div
            className={`dropdown-content ${contentClasses}`}
            style={contentStyle}
          >
            {children || null}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
}

BasicDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  contentClasses: PropTypes.string,
  menuClasses: PropTypes.string,
  iconName: PropTypes.string,
  hideIcon: PropTypes.bool,
  iconWrapperStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  iconWrapperClasses: PropTypes.string,
  dropdownClasses: PropTypes.string,
  triggerClasses: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonText: PropTypes.string,
  hideChevron: PropTypes.bool,
  noStyles: PropTypes.bool,
  activeOverride: PropTypes.bool,
  setActiveOverride: PropTypes.func,
  dropdownStyle: PropTypes.object,
  triggerStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  buttonTextStyle: PropTypes.object,
};

export default BasicDropdown;
