import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../transforms';
import InvoiceAddressFormatter from './InvoiceAddressFormatter';

function InvoiceAddressRow(props) {
  const { invoice } = props;

  const { location } = invoice;
  const { organization } = invoice;

  const fullcontour = {
    name: '3Shape Design Service',
    address: {
      address: '17505 N. 79th Ave #412',
      city: 'Glendale',
      region: 'AZ',
      postalCode: '85387',
    },
    phone: '602-688-4133',
  };

  return (
    <div className="invoiceAddressRow columns mt-4 mb-6">
      <div className="invoiceAddressCol column">
        <h6 className="title is-6">From:</h6>
        {InvoiceAddressFormatter(fullcontour)}
      </div>
      <div className="invoiceAddressCol column">
        <h6 className="title is-6">To:</h6>
        <span className="is-capitalized">
          {invoice.location.locationBilling === true &&
            InvoiceAddressFormatter(location)}
          {invoice.location.locationBilling === false &&
            InvoiceAddressFormatter(organization)}
        </span>
      </div>
      {invoice.location.locationBilling === false && (
        <div className="invoiceAddressCol column">
          <h6 className="title is-6">location:</h6>
          {InvoiceAddressFormatter(location)}
        </div>
      )}
      <div className="invoiceAddressCol column">
        <h6 className="title is-6">Due Date:</h6>
        <span>
          <b>{timestampFormatter(invoice.dueDate, false)}</b>
        </span>
      </div>
    </div>
  );
}

InvoiceAddressRow.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoiceAddressRow;
