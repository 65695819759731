import { gql } from '@apollo/client';

export default gql`
  query customerLocation($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      logoUrl
      id
    }
  }
`;
