import { BasicDropdown, transformNestedData } from '@fullcontour/common';
import PropTypes from 'prop-types';

function GuideCodesSelected({
  index = null,
  removeAllButton = false,
  values: { orderItems } = {},
  orderItemIndex = null,
  allowNested = false,
  form: { setFieldValue } = {},
}) {
  const currentItem = orderItems[index ?? orderItemIndex];

  const currentGuideCodes = currentItem.guideCodes;
  const { nestedTeeth } = currentItem;
  const { nestedArches } = currentItem;

  const numberOfSelectedCodes = currentGuideCodes?.reduce(
    (num, currentGuideCode) => num + (currentGuideCode?.codes?.length || 0),
    0,
  );
  const deleteGuideCode = (idxGuide) => {
    const updatedCurrentGuideCodes = [...currentGuideCodes];
    updatedCurrentGuideCodes.splice(idxGuide, 1);
    setFieldValue(
      `orderItems[${index || index === 0 ? index : orderItemIndex}].guideCodes`,
      updatedCurrentGuideCodes,
    );
  };

  const removeHandler = () => {
    setFieldValue(
      `orderItems[${index || index === 0 ? index : orderItemIndex}].guideCodes`,
      [],
    );
  };

  const transformedTeeth = transformNestedData(
    nestedTeeth,
    allowNested,
    parseInt,
  );
  const transformedArches = transformNestedData(
    nestedArches,
    allowNested,
    (key) => key,
  );

  return currentGuideCodes ? (
    <div className="is-flex">
      <BasicDropdown
        buttonClasses="is-flex hover-primary-button"
        contentClasses="py-0"
        hideIcon
        noStyles
        buttonTextStyle={{ fontSize: '14px' }}
        disabled={!currentGuideCodes}
        buttonText={`Codes Selected (${numberOfSelectedCodes || 0})`}
      >
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Guide Category</th>
              <th>Guide Code</th>
              {allowNested && (
                <>
                  <th>Teeth</th>
                  <th>Arches</th>
                </>
              )}
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {currentGuideCodes?.map((item, idxGuide) =>
              item?.codes?.map((code) => (
                <tr key={code + Math.random()}>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    {item.showDropdown ? item.categoryName : code}
                  </td>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    {item.showDropdown ? code : '-'}
                  </td>
                  {allowNested && (
                    <>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '33%',
                        }}
                      >
                        {transformedTeeth[item.codeId]
                          ? transformedTeeth[item.codeId].join(', ')
                          : '-'}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '33%',
                        }}
                      >
                        {transformedArches[item.codeId]
                          ? transformedArches[item.codeId].join(', ')
                          : '-'}
                      </td>
                    </>
                  )}
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    <button
                      type="button"
                      className="button is-small"
                      onClick={() => deleteGuideCode(idxGuide)}
                    >
                      <span className="icon">
                        <i className="bx bx-x has-text-weight-bold is-size-4 has-text-grey" />
                      </span>
                    </button>
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      </BasicDropdown>
      {removeAllButton && (
        <button
          type="button"
          className="button ml-3 is-primary"
          onClick={removeHandler}
        >
          Remove all
        </button>
      )}
    </div>
  ) : null;
}

GuideCodesSelected.propTypes = {
  index: PropTypes.number,
  removeAllButton: PropTypes.bool,
  values: PropTypes.object,
  orderItemIndex: PropTypes.number,
  allowNested: PropTypes.bool,
  form: PropTypes.object,
};

export default GuideCodesSelected;
