import { GET_ADMIN_LOCS_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { CurrentUserContext } from '../../../../shared';
import { MultiSelect } from '../FormFields';
import DataPointWrapper from './DataPointWrapper';

function DataPointsAdminLocations(props) {
  const { inputParams } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const app = import.meta.env.VITE_APP_NAME;

  return (
    inputParams.adminLocations &&
    currentUser.scopeToLocation === false &&
    app === 'admin_portal' && (
      <DataPointWrapper>
        <Field
          name="adminLocationIds"
          required
          component={MultiSelect}
          label="Admin Locations"
          query={GET_ADMIN_LOCS_MULTI}
          dataname="adminLocations"
        />
      </DataPointWrapper>
    )
  );
}

DataPointsAdminLocations.propTypes = {
  inputParams: PropTypes.object.isRequired,
};

export default DataPointsAdminLocations;
