import { useReactiveVar } from '@apollo/client';
import {
  getHelpers,
  guidedFlow,
  guideNav,
  OverpanelFilesContext,
  sendingOrders,
} from '@fullcontour/common';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { guidedStepsNav, guidedStepsOverpanel } from './helpers/guidedSteps';

const activeTooltip = 'active-tooltip';
const activeTooltipText = 'active-tooltip-text';
const activeTooltipPseudo = 'active-tooltip-pseudo';

function GuidedFlow() {
  const isGuidedFlow = useReactiveVar(guidedFlow);
  const submittingOrders = useReactiveVar(sendingOrders);
  const isGuideNav = useReactiveVar(guideNav);
  const { overpanelOpen, files, guidesData, parsedCase } = useContext(
    OverpanelFilesContext,
  );

  const { t } = useTranslation('profile');

  const [guide, setActive] = useState(false);
  const [steps, setSteps] = useState(
    overpanelOpen ? guidedStepsOverpanel(t) : guidedStepsNav(t),
  );
  const [runGuide, setRunGuide] = useState(false);

  const guidedStepsOverpanelTargets = useMemo(
    () => [
      ...guidedStepsOverpanel(t).map((item) => item.target),
      guidedStepsNav(t).map((item) => item.target),
    ],
    [t],
  );

  useEffect(() => {
    if (overpanelOpen && !files.length) {
      setRunGuide(false);
    }
    if (files.length) {
      setSteps(guidedStepsOverpanel(t));
      setTimeout(() => setRunGuide(true), 1000);
    }

    if (isGuideNav) {
      setSteps(guidedStepsNav(t));
    }
  }, [files, guidesData, isGuideNav, overpanelOpen, t]);

  useEffect(() => {
    if (!overpanelOpen) {
      setActive(false);
    } else {
      setTimeout(() => {
        setActive(!!overpanelOpen);
      }, 600);
    }
  }, [overpanelOpen]);

  const clearGuidedClasses = useCallback(() => {
    guidedStepsOverpanelTargets.forEach((item) => {
      document.querySelector(item)?.classList?.remove(activeTooltip);
      document.querySelector(item)?.classList?.remove(activeTooltipText);
      document.querySelector(item)?.classList?.remove(activeTooltipPseudo);
    });
  }, [guidedStepsOverpanelTargets]);
  useEffect(() => {
    if (!isGuidedFlow) {
      clearGuidedClasses();
    }
  }, [isGuidedFlow, clearGuidedClasses]);

  const handleJoyrideCallback = (data) => {
    const { step, status } = data;

    if (step) {
      const { target } = step;
      const element = document.querySelector(target);
      if (status === 'finished' && isGuideNav) {
        guideNav(false);
      }

      if (data.lifecycle === 'complete' || data.lifecycle === 'ready') {
        clearGuidedClasses(target);
      }

      if (
        data.lifecycle === 'complete' &&
        data.action === 'next' &&
        target === 'designTypeId0'
      ) {
        document.querySelector('#teetharch').classList.add(activeTooltip);
      }

      if (
        (data.lifecycle === 'tooltip' || data.lifecycle === 'init') &&
        data.type !== 'error:target_not_found' &&
        data.type !== 'tour:end' &&
        data.action !== 'reset' &&
        data.action !== 'stop' &&
        element
      ) {
        if (element.tagName === 'P') {
          element.classList.add(activeTooltipText);
        } else if (element.tagName !== 'LABEL') {
          if (!element.classList.contains(activeTooltip)) {
            element.classList.add(activeTooltip);
          }
        } else {
          element.classList.add(activeTooltipPseudo);
        }
      }
    }
  };

  const getHelpersHandler = (helpFuncs) => {
    getHelpers({ helpFuncs });
  };

  const run =
    overpanelOpen !== 'pending' && overpanelOpen
      ? guide && isGuidedFlow && !submittingOrders.totalCases && parsedCase
      : isGuidedFlow && isGuideNav;

  return runGuide ? (
    <Joyride
      callback={handleJoyrideCallback}
      getHelpers={getHelpersHandler}
      continuous
      run={run}
      scrollToFirstStep={false}
      disableScrollParentFix
      disableScrolling
      hideBackButton
      disableOverlay
      steps={steps}
      locale={{
        last: 'Finish',
      }}
      styles={{
        tooltipContainer: {
          textAlign: 'left',
        },
        buttonNext: {
          outline: 'none',
        },
        tooltipContent: {
          padding: '14px',
        },
        options: {
          zIndex: 100,
          arrowColor: '#03b5e3',
          backgroundColor: '#03b5e3',
          primaryColor: '#1b77c8',
          textColor: 'white',
          width: 300,
        },
      }}
    />
  ) : null;
}

export default GuidedFlow;
