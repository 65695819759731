/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
import PropTypes from 'prop-types';
import { Component } from 'react';

class BillingGridFunctions extends Component {
  componentWillUnmount() {
    this.gridApi = undefined;
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onColumnVisible = (params) => {
    params.api.sizeColumnsToFit();
  };

  render() {
    const { children } = this.props;
    const funcs = {
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onFirstDataRendered: this.onFirstDataRendered,
      onColumnVisible: this.onColumnVisible,
    };

    return <>{children(funcs)}</>;
  }
}

BillingGridFunctions.propTypes = {
  children: PropTypes.func.isRequired,
};

export default BillingGridFunctions;
