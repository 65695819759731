import { gql } from '@apollo/client';
import { ErrorMessageInfo, ModifierInfo } from '../fragments';

export default gql`
  mutation updateModifier($input: ModifierUpdateInput!) {
    updateModifier(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      modifier {
        ...ModifierInfo
      }
    }
  }
  ${ErrorMessageInfo}
  ${ModifierInfo}
`;
