/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import ICON_ClearAligners from '@fullcontour/common/src/assets/icons/clearaligners.png';
import ICON_Dentures from '@fullcontour/common/src/assets/icons/dentures.png';
import ICON_SurgicalGuide from '@fullcontour/common/src/assets/icons/surgicalguide.png';
import ICON_Tooth from '@fullcontour/common/src/assets/icons/tooth.png';
import { memo, useEffect } from 'react';
import Proptypes from 'prop-types';
import initialValues from '../../helpers/initialValues';

function DesignTypesDropdown({
  field: { name },
  data: { designTypes },
  values: { businessUnitName },
  form: { resetForm, setFieldValue, setCurrentStepIndex },
  error = null,
  value,
  canShowErrors,
}) {
  useEffect(() => {
    if (
      designTypes[businessUnitName] &&
      designTypes[businessUnitName].length === 1
    ) {
      setTimeout(() => {
        changeValueHandler(designTypes[businessUnitName][0].id);
      }, 100);
    }
  }, [businessUnitName]);
  function changeValueHandler(newValue) {
    if (value !== newValue) {
      resetForm({ values: { ...initialValues, businessUnitName } });
      setFieldValue(name, newValue);
      setCurrentStepIndex({
        _sectionIndex: 1,
        _fieldIndex: 2,
      });
    }
  }
  function getImage() {
    switch (businessUnitName) {
      case 'core-lab':
        return <img src={ICON_Tooth} alt="Core Lab" />;
      case 'guided-surgery':
        return <img src={ICON_SurgicalGuide} alt="Guided Surgery" />;
      case 'ortho':
        return <img src={ICON_ClearAligners} alt="Ortho" />;
      default:
        return <img src={ICON_Dentures} alt="Removables" />;
    }
  }
  return (
    <div
      className="is-flex is-flex-direction-row is-justify-content-center columns mb-1 is-flex-wrap-wrap"
      style={
        canShowErrors && error
          ? { border: '1px solid #DE0019', backgroundColor: '#FFE3E6' }
          : null
      }
    >
      {designTypes[businessUnitName] &&
        designTypes[businessUnitName].map(({ id, name }) => (
          <div
            key={name}
            className={`is-clickable p-4 m-2 box column is-2 is-flex is-flex-direction-column is-align-items-center ${
              value === id ? 'has-background-grey-lighter' : ''
            }`}
            style={{ border: '1px solid black' }}
            onClick={() => {
              changeValueHandler(id);
            }}
          >
            <figure className="image is-48x48 is-flex ">{getImage()}</figure>
            <p className="has-text-centered">{name}</p>
          </div>
        ))}
      {designTypes[businessUnitName]
        ? designTypes[businessUnitName].length === 0
          ? 'No designs available.'
          : null
        : 'No designs available.'}
    </div>
  );
}
DesignTypesDropdown.propTypes = {
  field: Proptypes.object.isRequired,
  data: Proptypes.object.isRequired,
  values: Proptypes.object.isRequired,
  form: Proptypes.object.isRequired,
  error: Proptypes.string,
  value: Proptypes.any.isRequired,
  canShowErrors: Proptypes.bool.isRequired,
};
export default memo(DesignTypesDropdown);
