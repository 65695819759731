import PropTypes from 'prop-types';
import Styles from './AdditionalControls.module.scss';

function ThemeControl(props) {
  const { toggle, theme } = props;

  return (
    <button
      type="button"
      onClick={toggle}
      className={`has-tooltip-arrow has-tooltip-right ${Styles.additionalControlsButton}`}
      data-tooltip="toggle theme"
    >
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21.5" cy="21.5" r="21" stroke="#CFD5DB" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 41.994C21.1662 41.998 21.3329 42 21.5 42C32.8218 42 42 32.8218 42 21.5C42 10.1782 32.8218 0.999999 21.5 0.999998C21.3329 0.999998 21.1662 1.002 21 1.00598L21 41.994Z"
          fill={theme === 'light' ? '#444' : '#F9F9F9'}
        />
      </svg>
    </button>
  );
}

ThemeControl.propTypes = {
  toggle: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default ThemeControl;
