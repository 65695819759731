import { gql } from '@apollo/client';
import { DesignTypeCategoryInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateDesignTypeCategory($input: DesignTypeCategoryUpdateInput!) {
    updateDesignTypeCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designTypeCategory {
        ...DesignTypeCategoryInfo
      }
    }
  }
  ${DesignTypeCategoryInfo}
  ${ErrorMessageInfo}
`;
