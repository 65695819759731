import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModifierSelection } from '../ModifierSelection';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetaModifiers({ orderItem, selectedDesignType = null, index }) {
  const { t } = useTranslation('showelements');

  const isVisible = useMemo(
    () =>
      selectedDesignType?.modifierGroups?.reduce((visible, { modifiers }) => {
        let middleware = visible;
        if (modifiers.length) {
          middleware = true;
        }
        return middleware;
      }, false),
    [selectedDesignType],
  );

  const attrLength = orderItem.orderItemModifiersAttributes?.length;

  return selectedDesignType ? (
    <FieldMetadataWrapper
      title="Add-ons"
      disabled={!isVisible}
      infoText={t(
        `Modifiers are available for certain design types, sometimes for an additional fee. Modifiers are services offered by 3Shape Design Service that go above and beyond designing the case, such as providing a live consultation.`,
      )}
    >
      {isVisible ? (
        <ModifierSelection
          index={index}
          selectedDesignType={selectedDesignType}
        />
      ) : (
        <div />
      )}
      {attrLength ? (
        <span style={{ marginLeft: '.8em' }}>{attrLength}</span>
      ) : null}
    </FieldMetadataWrapper>
  ) : null;
}

FieldMetaModifiers.propTypes = {
  orderItem: PropTypes.object.isRequired,
  selectedDesignType: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default FieldMetaModifiers;
