/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

function InfoBlock({ infoCallback = null }) {
  return (
    <span
      type="button"
      onClick={infoCallback}
      data-tooltip="Click to learn more"
      className="has-text-info ml-2 is-4 is-clickable has-text-weight-bold is-inline-flex is-align-items-center has-tooltip-arrow has-tooltip-bottom"
    >
      <i
        className="bx bx-info-circle"
        style={{ position: 'relative', top: 0.5 }}
      />
    </span>
  );
}

InfoBlock.propTypes = {
  infoCallback: PropTypes.func,
};

export default InfoBlock;
