import { gql } from '@apollo/client';
import { ErrorMessageInfo, PriceListInfo } from '../fragments';

export default gql`
  mutation createPriceList($input: PriceListCreateInput!) {
    createPriceList(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      priceList {
        ...PriceListInfo
      }
    }
  }
  ${PriceListInfo}
  ${ErrorMessageInfo}
`;
