import { gql } from '@apollo/client';
import { AdminUserInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateAdminUser($input: AdminUserUpdateInput!) {
    updateAdminUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminUser {
        ...AdminUserInfo
      }
    }
  }
  ${AdminUserInfo}
  ${ErrorMessageInfo}
`;
