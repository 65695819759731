import { ACTION_RESEND_FTP } from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../config/log';

function resendFtp(
  client,
  labLocationId,
  manufacturerIds,
  id,
  setSuccess,
  setFailure,
) {
  client
    .mutate({
      mutation: ACTION_RESEND_FTP,
      variables: {
        input: {
          input: {
            id,
            labLocationId,
            manufacturerIds,
          },
          clientMutationId: uuidv4(),
        },
      },
    })
    .then(() => {
      setSuccess(true);
    })
    .catch((err) => {
      Log.error(err);
      setFailure(true);
    });
}

export default resendFtp;
