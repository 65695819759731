import { gql } from '@apollo/client';
import { DesignTypeCategoryInfo } from '../fragments';

export default gql`
  query designTypeCategory($id: ID, $slug: String) {
    designTypeCategory(id: $id, slug: $slug) {
      ...DesignTypeCategoryInfo
    }
  }
  ${DesignTypeCategoryInfo}
`;
