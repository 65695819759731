import { gql } from '@apollo/client';

export default gql`
  query customerUsersAdmin {
    customerUsersAdmin {
      id
      attributes
    }
  }
`;
