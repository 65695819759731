import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createOrderFile($input: OrderFileCreateInput!) {
    createOrderFile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderFile {
        id
        fileType
        originalFileName
        s3Key
        orderId
        ver
        latestVer
      }
      order {
        id
        files
      }
    }
  }
  ${ErrorMessageInfo}
`;
