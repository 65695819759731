import { gql } from '@apollo/client';
import {
  DesignPreferenceInfo,
  DesignPreferenceProfileInfo,
  DesignTypeInfo,
  ErrorMessageInfo,
} from '../fragments';

export default gql`
  mutation createDesignPreference($input: DesignPreferenceCreateInput!) {
    createDesignPreference(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designPreferenceProfile {
        ...DesignPreferenceProfileInfo
      }
    }
  }
  ${DesignPreferenceProfileInfo}
  ${DesignPreferenceInfo}
  ${DesignTypeInfo}
  ${ErrorMessageInfo}
`;
