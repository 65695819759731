import { Field } from 'formik';
import PropTypes from 'prop-types';
import LanguageDropdown from '../Dropdowns/LanguageDropdown/LanguageDropdown';
import FieldContainer from '../FieldContainer/FieldContainer';
import FormSection from '../FormContainer/FormSection';
import PhoneField from '../TextInputs/PhoneField';

function UserFormCommonFields({ onlyFirstName = false }) {
  return (
    <FormSection title="Profile Info">
      <div className="field is-grouped">
        <div className="control is-expanded">
          <FieldContainer
            required
            name="firstName"
            label={!onlyFirstName ? 'First Name' : 'Name'}
            style={{ paddingBottom: 10 }}
          >
            <Field
              id="firstName"
              name="firstName"
              required
              placeholder={!onlyFirstName ? 'First Name' : 'Name'}
              type="text"
              className="input"
            />
          </FieldContainer>
        </div>
        {!onlyFirstName ? (
          <div className="control is-expanded">
            <FieldContainer
              required
              name="lastName"
              label="Last Name"
              style={{ paddingBottom: 10 }}
            >
              <Field
                id="lastName"
                name="lastName"
                required
                placeholder="Last Name"
                className="input"
              />
            </FieldContainer>
          </div>
        ) : null}
      </div>
      <div className="field is-grouped">
        <div className="control is-expanded">
          <FieldContainer required name="email" style={{ paddingBottom: 10 }}>
            <Field
              id="email"
              name="email"
              type="email"
              required
              placeholder="hello@fullcontour.com"
              className="input"
            />
          </FieldContainer>
        </div>
        <div className="control is-expanded">
          <PhoneField />
        </div>
      </div>
      <div className="field">
        <div className="control is-expanded">
          <Field name="language" required component={LanguageDropdown} />
        </div>
      </div>
    </FormSection>
  );
}

UserFormCommonFields.propTypes = {
  onlyFirstName: PropTypes.bool,
};

export default UserFormCommonFields;
