const downloadMarkComplete = {
  admin_portal: {
    visible: true,
    statuses: {
      ready_for_download: [0, 10, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40],
      at_manufacturer: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      ready_for_download: [0, 10, 20, 30, 40, 50, 60, 70],
    },
  },
  manuf_portal: {
    visible: true,
    statuses: {
      at_manufacturer: [0, 10],
    },
  },
  designer_portal: {
    visible: false,
  },
};

export default downloadMarkComplete;
