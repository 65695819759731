import { gql } from '@apollo/client';
import { ErrorMessageInfo, IntegrationInfo } from '../fragments';

export default gql`
  mutation createIntegration($input: IntegrationCreateInput!) {
    createIntegration(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      integration {
        ...IntegrationInfo
      }
    }
  }
  ${IntegrationInfo}
  ${ErrorMessageInfo}
`;
