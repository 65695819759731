import PropTypes from 'prop-types';
import { BaseLoader } from '../../../../../shared';
import { FilesShowFileView } from './FilesShowFileView';
import { FilesShowFolderView } from './FilesShowFolderView';
import FilesShowHeader from './FilesShowHeader';
import FilesShowSelectMessage from './FilesShowSelectMessage';

function FilesShow({
  loadingFileView,
  client,
  refetch,
  toggleVersions,
  setSelectedToDefault,
  order,
  selectedFile = null,
  selectedFolder = null,
  selectedFileError = null,
}) {
  return (
    <>
      <FilesShowHeader
        order={order}
        selectedFile={selectedFile}
        selectedFolder={selectedFolder}
        setSelectedToDefault={setSelectedToDefault}
        client={client}
        refetch={refetch}
      />
      <FilesShowSelectMessage
        selectedFile={selectedFile}
        selectedFolder={selectedFolder}
      />

      {loadingFileView ? (
        <BaseLoader />
      ) : (
        <div>
          {selectedFolder && (
            <FilesShowFolderView
              selectedFolder={selectedFolder}
              order={order}
              toggleVersions={toggleVersions}
              selectedFileError={selectedFileError}
            />
          )}
          {selectedFile && (
            <FilesShowFileView
              selectedFile={selectedFile}
              selectedFileError={selectedFileError}
              order={order}
              toggleVersions={toggleVersions}
            />
          )}
        </div>
      )}
    </>
  );
}

FilesShow.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
  loadingFileView: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
  selectedFileError: PropTypes.string,
  setSelectedToDefault: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default FilesShow;
