import { gql } from '@apollo/client';

const ManufacturerUserInfo = gql`
  fragment ManufacturerUserInfo on ManufacturerUser {
    id
    firstName
    lastName
    email
    phone
    language
    state
    role {
      id
      name
      level
    }
    organization {
      id
      name
      shortName
      slug
    }
    createdAt
    updatedAt
  }
`;

export default ManufacturerUserInfo;
