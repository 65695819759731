import PropTypes from 'prop-types';
import { useState } from 'react';
import Feedback from '../../../../shared/Feedback/Feedback';

function ReviewModalBodyRedesign({
  changeMessage,
  redesign,
  redesignOff,
  docApproval,
  patientApproval,
  order,
  message = null,
  closeAndReset,
}) {
  const [triggerFeedbackSend, setTriggerFeedbackSend] = useState(false);
  const [sendEnable, setSendEnable] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  return redesign && !docApproval && !patientApproval ? (
    <div>
      <Feedback
        showFeedback={{
          app: import.meta.env.VITE_APP_NAME,
        }}
        data={order}
        bodyOnly
        setSendEnable={setSendEnable}
        triggerFeedbackSend={triggerFeedbackSend}
        setTriggerFeedbackSend={setTriggerFeedbackSend}
        message={message}
        setSendLoading={setSendLoading}
        callback={closeAndReset}
      />

      <div className="field mt-4">
        <label htmlFor="redesign-reason" className="label">
          Please provide a reason why this case needs to be redesigned:
        </label>
        <div className="control">
          <textarea
            rows={5}
            id="redesign-reason"
            placeholder="Reason"
            onChange={changeMessage}
            className="textarea"
          />
        </div>
      </div>
      <div className="is-flex is-justify-content-flex-end">
        <button
          type="button"
          className="button is-grey mr-3 is-danger is-outlined"
          onClick={redesignOff}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`button is-warning ${sendLoading ? 'is-loading' : ''}`}
          onClick={() => setTriggerFeedbackSend(true)}
          disabled={
            import.meta.env.VITE_APP_NAME === 'customer_portal' && !sendEnable
          }
          action="redesign_and_rush"
        >
          Send
        </button>
      </div>
    </div>
  ) : null;
}

ReviewModalBodyRedesign.propTypes = {
  changeMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
  redesign: PropTypes.bool.isRequired,
  docApproval: PropTypes.bool.isRequired,
  patientApproval: PropTypes.bool.isRequired,
  redesignOff: PropTypes.func.isRequired,
  closeAndReset: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default ReviewModalBodyRedesign;
