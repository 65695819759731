import * as Yup from 'yup';

function schema() {
  return Yup.object({
    notificationTypeUsers: Yup.array().of(
      Yup.object({
        id: Yup.string().nullable(true),
        notificationTypeId: Yup.string().required('Required'),
        email: Yup.boolean().required('Required'),
        _destroy: Yup.boolean().required('Required'),
      }),
    ),
  });
}

export default schema;
