import PropTypes from 'prop-types';
import { memo } from 'react';

function TotalLocations(props) {
  const { type, organization } = props;

  return type !== 'manufacturer'
    ? `${organization.totalLocations} ${
        type === 'design' ? 'Silos' : 'Locations'
      }`
    : null;
}

TotalLocations.propTypes = {
  type: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
};

export default memo(TotalLocations);
