import PropTypes from 'prop-types';

import BillingDetailsContacts from './BillingDetailsContacts';
import BillingDetailsCreditCards from './BillingDetailsCreditCards';
import BillingDetailsHeader from './BillingDetailsHeader';

function BillingDetails(props) {
  return (
    <div>
      <BillingDetailsHeader {...props} />
      <div className="is-flex is-flex-direction-column">
        <div className="columns">
          <div className="column">
            <BillingDetailsCreditCards {...props} />
          </div>
          <div className="column">
            <BillingDetailsContacts {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

BillingDetails.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingDetails;
