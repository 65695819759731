import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { DESTROY_ORDER_FEEDBACK } from '@fullcontour/shared-api';

import { useEffect, useMemo, useState } from 'react';
import { classesGenerator } from '../../../transforms';
import OutsideClick from '../HelperFunctions/OutsideClick';

import { deleteOrderFeedback } from './helpers';
import { checkIfOptionSelected } from './utils';

import FeedbackActionButtons from './FeedbackActionButtons';
import FeedbackCreatedByDropdown from './FeedbackCreatedByDropdown';
import FeedbackHeader from './FeedbackHeader';
import FeedbackLabels from './FeedbackLabels';
import FeedbackSearchInput from './FeedbackSearchInput';
import FeedbackSelectionList from './FeedbackSelectionList';

function FeedbackBody({
  bodyShow,
  setBodyShow,
  orderId,
  createFeedbackMutation,
  setSelectedOptions,
  handleOptionSelection,
  sendFeedback,
  setFeedbackUser,
  selectedOptions = {},
  feeedbackUser = null,
  editUserId = null,
  setRedesignActive = () => {},
  currentFeedback = {},
  bodyOnly = false,
  redesign = false,
  loading = false,
  isfeedbackToShow = false,
  approvalDoctorId = null,
  locationId = null,
  ...rest
}) {
  const [searchValue, setSearchValue] = useState('');

  const bodyClasses = useMemo(
    () =>
      classesGenerator({
        'is-hidden': !bodyShow,
        box: !bodyOnly,
      }),
    [bodyShow, bodyOnly],
  );

  const selectedOptionsKeys = Object.values(selectedOptions);

  const [deleteFeedbackMutation, { loading: deleteLoading }] = useMutation(
    DESTROY_ORDER_FEEDBACK,
  );

  const deleteFeedbackHandler = () => {
    deleteOrderFeedback({
      id: currentFeedback?.id,
      orderId,
      approvalDoctorId,
      deleteFeedbackMutation,
      closeModal: () => setBodyShow(false),
    });
  };

  useEffect(() => {
    if (redesign) {
      setRedesignActive(checkIfOptionSelected(redesign, selectedOptions));
    }
  }, [selectedOptions, redesign, setRedesignActive]);

  return (
    <OutsideClick
      style={bodyOnly ? { width: '100%' } : {}}
      fn={() => !bodyOnly && setBodyShow((prev) => !prev)}
    >
      <div
        className={bodyClasses}
        style={
          !bodyOnly
            ? {
                position: 'absolute',
                width: 400,
                right: 10,
                top: 40,
                zIndex: 9999,
              }
            : {}
        }
      >
        {!bodyOnly && <FeedbackHeader />}
        <FeedbackSearchInput
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
        <FeedbackSelectionList
          selectedOptions={selectedOptions}
          handleOptionSelection={handleOptionSelection}
          searchValue={searchValue.toLowerCase()}
          {...rest}
        />
        <FeedbackLabels
          setSelectedOptions={setSelectedOptions}
          labels={selectedOptionsKeys
            .map((item) => Object.keys(item).map((key) => item[key]))
            .flat()
            .filter(Boolean)}
        />
        {import.meta.env.VITE_APP_NAME === 'admin_portal' && (
          <>
            {currentFeedback &&
            currentFeedback?.id &&
            !currentFeedback?.userId &&
            !currentFeedback?.adminUser ? (
              <p>
                <b>Feedback created in Dr. Approval App</b>
              </p>
            ) : null}
            <FeedbackCreatedByDropdown
              setFeedbackUser={setFeedbackUser}
              feeedbackUser={feeedbackUser}
              locationId={locationId}
              editUserId={editUserId}
            />
          </>
        )}

        {!bodyOnly && (
          <FeedbackActionButtons
            disabledSend={!checkIfOptionSelected(redesign, selectedOptions)}
            setBodyShow={setBodyShow}
            sendFeedback={sendFeedback}
            deleteFeedback={deleteFeedbackHandler}
            isUpdate={isfeedbackToShow}
            loading={loading}
            deleteLoading={deleteLoading}
            isModifiedByAdmin={currentFeedback?.adminUser?.id}
          />
        )}
      </div>
    </OutsideClick>
  );
}

FeedbackBody.propTypes = {
  currentFeedback: PropTypes.object,
  selectedOptions: PropTypes.object,
  locationId: PropTypes.string,
  feeedbackUser: PropTypes.string,
  editUserId: PropTypes.string,
  bodyOnly: PropTypes.bool,
  redesign: PropTypes.bool,
  loading: PropTypes.bool,
  isfeedbackToShow: PropTypes.bool,
  bodyShow: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  approvalDoctorId: PropTypes.string,
  setBodyShow: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  setRedesignActive: PropTypes.func,
  createFeedbackMutation: PropTypes.func.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  handleOptionSelection: PropTypes.func.isRequired,
  setFeedbackUser: PropTypes.func.isRequired,
  orderItems: PropTypes.array.isRequired,
};

export default FeedbackBody;
