import { gql } from '@apollo/client';
import { ErrorMessageInfo, ManufacturerUserInfo } from '../fragments';

export default gql`
  mutation createManufacturerUser($input: ManufacturerUserCreateInput!) {
    createManufacturerUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      manufacturerUser {
        ...ManufacturerUserInfo
      }
    }
  }
  ${ManufacturerUserInfo}
  ${ErrorMessageInfo}
`;
