import { memo } from 'react';
import Proptypes from 'prop-types';

function Dropdown(props) {
  const { field, data } = props;
  return (
    <div className="select">
      <select {...field}>
        <option value="">Please Select</option>
        {data &&
          data.map(({ name, value }) => <option value={value}>{name}</option>)}
      </select>
    </div>
  );
}
Dropdown.propTypes = {
  field: Proptypes.object.isRequired,
  data: Proptypes.object.isRequired,
};
export default memo(Dropdown);
