import { gql } from '@apollo/client';

export default gql`
  query printers {
    printers {
      id
      name
      description
      imageUrl
      slug
      value
      printerMaterials {
        id
        name
        description
        imageUrl
        slug
      }
    }
  }
`;
