import PropTypes from 'prop-types';
import TempOrderActionsButtonRemoveItem from './TempOrderActionsButtonRemoveItem';
import TempOrderActionsButtonSubmit from './TempOrderActionsButtonSubmit';

function TempOrderActionsButtons({
  removeTempOrder,
  tempOrder,
  submitAll,
  setSingleCase,
}) {
  return (
    <div className="buttons is-centered is-flex-direction-column">
      <TempOrderActionsButtonSubmit
        submitAll={submitAll}
        setSingleCase={setSingleCase}
      />
      <TempOrderActionsButtonRemoveItem
        removeTempOrder={removeTempOrder}
        tempOrder={tempOrder}
      />
    </div>
  );
}

TempOrderActionsButtons.propTypes = {
  removeTempOrder: PropTypes.func.isRequired,
  tempOrder: PropTypes.object.isRequired,
  submitAll: PropTypes.bool.isRequired,
  setSingleCase: PropTypes.func.isRequired,
};

export default TempOrderActionsButtons;
