import { gql } from '@apollo/client';
import { DesignOrganizationInfo } from '../fragments';

export default gql`
  query designOrganization($slug: String, $id: ID) {
    designOrganization(slug: $slug, id: $id) {
      ...DesignOrganizationInfo
    }
  }
  ${DesignOrganizationInfo}
`;
