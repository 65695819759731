import { gql } from '@apollo/client';
import { CustomerOrganizationInfo } from '../fragments';

export default gql`
  query customerOrganization($slug: String, $id: ID) {
    customerOrganization(slug: $slug, id: $id) {
      ...CustomerOrganizationInfo
    }
  }
  ${CustomerOrganizationInfo}
`;
