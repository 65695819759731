import { gql } from '@apollo/client';
import { ErrorMessageInfo, QuotaProfileInfo } from '../fragments';

export default gql`
  mutation createQuotaProfile($input: QuotaProfileCreateInput!) {
    createQuotaProfile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      quotaProfile {
        ...QuotaProfileInfo
      }
    }
  }
  ${QuotaProfileInfo}
  ${ErrorMessageInfo}
`;
