import PropTypes from 'prop-types';

import OrderShowInfoDataWrapper from './OrderShowInfoDataWrapper';
import ShowCaseFields from './OrderShowInfoForm/ShowCaseFields';

function OrderShowCaseInfo({ order, turnaroundTimes }) {
  return (
    <div
      className="column is-4 orderShowCaseInfo"
      style={{ borderRight: '1px solid #ccc' }}
    >
      {import.meta.env.VITE_APP_NAME === 'admin_portal' ? (
        <ShowCaseFields turnaroundTimes={turnaroundTimes} />
      ) : (
        <>
          <OrderShowInfoDataWrapper title="Name" dataItem={order.name} />
          <OrderShowInfoDataWrapper
            title="Instructions"
            dataItem={order.instructions ? order.instructions : 'None'}
          />
          <OrderShowInfoDataWrapper
            title="Turnaround Time"
            dataItem={order.turnaroundTime ? order.turnaroundTime.name : ''}
          />
          <OrderShowInfoDataWrapper
            title="Design Approval?"
            dataItem={order.requiresDesignApproval ? 'Yes' : 'No'}
          />
        </>
      )}
    </div>
  );
}

OrderShowCaseInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowCaseInfo;
