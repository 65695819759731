import { XMLParser } from 'fast-xml-parser';
import JSZip from 'jszip';
import BaseDefinition from './BaseDefinition';
import {
  ZipDefinition3ShapeManuf,
  ZipDefinition3ShapeScan,
} from './ZipDefinitionFiles';

class FileTypeZip extends BaseDefinition {
  constructor(file, currentUser) {
    super(file, currentUser);
    this.jsonObj = null;
    this.unzippedFiles = null;
    this.mappedZipFiles = [];
  }

  buildCase = async ({ ...args }) => {
    const { customerLocation, softwareNodes } = args;
    this.unzippedFiles = await this.unzip();
    const zipType = await this.determineZipType();
    // eslint-disable-next-line
    return new zipType.definitionFile(this.file, this.currentUser).buildCase({
      unzippedFiles: this.unzippedFiles,
      jsonObj: this.jsonObj,
      mappedZipFiles: this.mappedZipFiles,
      customerLocation,
      softwareNodes,
    });
  };

  determineZipType = async () => {
    if (await this.containsXML(this.mappedZipFiles, '.3ox')) {
      return {
        type: '3shapeManufZip',
        definitionFile: ZipDefinition3ShapeManuf,
      };
    }

    if (await this.containsXML(this.mappedZipFiles)) {
      return { type: '3shapeZip', definitionFile: ZipDefinition3ShapeScan };
    }

    return { type: 'unknownZip', definitionFile: BaseDefinition };
  };

  containsXML = async (fileNames, extension = '.xml') => {
    const { order } = this.parsedCase;

    const fileExists = await fileNames.some(
      (fileName) =>
        fileName === `${order.intOrderId}/${order.intOrderId}${extension}`,
    );

    if (fileExists && extension === '.xml') {
      return this.checkFor3ShapeXML();
    }

    return fileExists;
  };

  checkFor3ShapeXML = async () => {
    const { order } = this.parsedCase;

    const parser = new XMLParser({
      attributeNamePrefix: '',
      attributesGroupName: false,
      ignoreAttributes: false,
      removeNSPrefix: true,
      allowBooleanAttributes: false,
      parseTagValue: false,
      parseAttributeValue: true,
      trimValues: true,
      tagValueProcessor: (b) => b.toLowerCase(),
    });

    this.jsonObj = await this.unzippedFiles
      .file(`${order.intOrderId}/${order.intOrderId}.xml`)
      .async('text')
      .then((xml) => parser.parse(xml));

    return 'DentalContainer' in this.jsonObj;
  };

  unzip = async () =>
    JSZip.loadAsync(this.file).then((zip) => {
      Object.values(zip.files).forEach((value) =>
        this.mappedZipFiles.push(value.name),
      );
      return zip;
    });
}

export default FileTypeZip;
