import { gql } from '@apollo/client';

const CadFileInfo = gql`
  fragment CadFileInfo on CadFile {
    id
    fileUrl
    s3Key
    createdAt
    filename
    location {
      id
      slug
      name
    }
  }
`;

export default CadFileInfo;
