import { useQuery } from '@apollo/client';
import { useAmplify } from '@fullcontour/auth';
import {
  AppHOC,
  CurrentUserProvider,
  CustomerLocationProvider,
  LoadingOrError,
  OrderFormModalContextProvider,
  OverpanelFilesProvider,
} from '@fullcontour/common';
import { GET_CUSTOMER_LOC } from '@fullcontour/shared-api';
import { useState } from 'react';
import BodyElement from './components/layouts/BodyElement';
import NavElements from './components/layouts/NavElements';
import {
  mainRoutes,
  redirects,
  rootPaths,
  sidebarRoutes,
} from './components/routing';
import GuidedFlow from './components/shared/GuidedFlow/GuidedFlow';
import navMenuList from './components/shared/OrdersNavMenu/navMenuList';

const routing = {
  mainRoutes,
  redirects,
  rootPaths,
  sidebarRoutes,
  navMenuList,
};

function App() {
  const values = useAmplify();
  const [files, setFiles] = useState([]);
  const [overpanelOpen, setOverpanelOpen] = useState(false);
  const [guidesData, setGuidesData] = useState(null);
  const [parsedCase, setParsedCase] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_CUSTOMER_LOC, {
    fetchPolicy: 'network-only',
    skip: !values.currentUser?.scopeToLocation,
  });

  function pushFiles(newFiles) {
    setFiles((prevState) => [...prevState, ...newFiles]);
    setOverpanelOpen(true);
  }

  function removeFile(e, { fileIndex }) {
    if (e) {
      e.preventDefault();
    }

    const index =
      fileIndex ??
      parseInt(e.target.closest('button').getAttribute('fileindex'), 10);

    const removableId = files[index].id;

    const newFiles = files.filter((file) => file.id !== removableId);

    setFiles(newFiles);
  }

  function toggleOverpanelHandler(status) {
    setOverpanelOpen((prevState) => (prevState && status ? false : status));
  }

  function removeAllFiles(e) {
    if (e) e.preventDefault();
    setFiles([]);
  }

  function updateGuides(data) {
    setGuidesData(data);
  }
  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} title="User" />;
  }
  return (
    <OverpanelFilesProvider
      value={{
        pushFiles,
        removeFile,
        removeAllFiles,
        toggleOverpanel: (status) => toggleOverpanelHandler(status),
        files,
        overpanelOpen,
        updateGuides: (data) => updateGuides(data),
        guidesData,
        setParsedCase,
        parsedCase,
      }}
    >
      <CurrentUserProvider
        value={{
          logOut: values.logOut,
          setCurrentUser: values.updateCurrentUser,
          currentUser: values.currentUser,
        }}
      >
        <CustomerLocationProvider
          value={{
            customerLocation: data?.customerLocation,
            refetchCustomerLocation: refetch,
          }}
        >
          <AppHOC
            app={import.meta.env.VITE_APP_NAME}
            routing={routing}
            values={values}
            bodyElement={BodyElement}
            guidedFlow={GuidedFlow}
          >
            <OrderFormModalContextProvider>
              <NavElements />
            </OrderFormModalContextProvider>
          </AppHOC>
        </CustomerLocationProvider>
      </CurrentUserProvider>
    </OverpanelFilesProvider>
  );
}

export default App;
