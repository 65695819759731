import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../../../../../transforms';
import { LanguageContext } from '../../../Context';
import FieldContainer from '../../FieldContainer/FieldContainer';

function LanguageDropdown({
  form: { errors },
  field: { name, value },
  enableContext = false,
}) {
  const { t } = useTranslation('formfields');
  const { changeDefaultLanguage, locale } = useContext(LanguageContext);
  const languages = languageOptions[locale || 'en'];
  const { setFieldTouched, setFieldValue } = useFormikContext();

  function handleChange(e) {
    if (changeDefaultLanguage && enableContext) {
      changeDefaultLanguage(e.target.value);
    }

    setFieldValue(name, e.target.value);
  }

  function handleBlur() {
    setFieldTouched(name, true);
  }

  return (
    languages && (
      <FieldContainer required name={name} label="Default Language">
        <div
          className={`select ${!languages ? 'is-loading' : ''}`}
          style={{ width: '100%' }}
        >
          <select
            onChange={handleChange}
            onBlur={handleBlur}
            value={!value ? `${t('Select a Language')}...` : value}
            id={name}
            name={name}
            className={`${errors ? 'is-danger' : ''}`}
            disabled={!languages}
            style={{ width: '100%' }}
          >
            <option disabled>{`${t('Select a Language')}...`}</option>
            {languages?.map((l) => (
              <option key={l.value} value={l.value}>
                {l.text}
              </option>
            ))}
          </select>
        </div>
      </FieldContainer>
    )
  );
}

LanguageDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
  }).isRequired,
  enableContext: PropTypes.bool,
};

export default memo(LanguageDropdown);
