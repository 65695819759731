import Proptypes from 'prop-types';
import { memo } from 'react';

function CaseNameInput({ form, field }) {
  const {
    currentStepIndex: { _sectionIndex },
  } = form;

  return (
    <>
      <input
        maxLength={100}
        disabled={_sectionIndex > 1}
        className="input"
        {...field}
      />
      {field.value.length >= 100 && _sectionIndex <= 1 && (
        <small className="has-text-danger">
          Maximum characters of 100 reached
        </small>
      )}
      {_sectionIndex > 1 && (
        <small className="has-text-danger">
          Case name can no longer be changed. To change the name please reload
          the form.
        </small>
      )}
    </>
  );
}

CaseNameInput.propTypes = {
  field: Proptypes.object.isRequired,
  form: Proptypes.object.isRequired,
};

export default memo(CaseNameInput);
