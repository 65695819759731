import PropTypes from 'prop-types';
import { memo } from 'react';
import TurnaroundTimeHeaderCell from './TurnaroundTimeHeaderCell';

function TableHeader(props) {
  const { listTurnaroundTimes } = props;
  return (
    <thead>
      <tr>
        <th>&nbsp;</th>
        <TurnaroundTimeHeaderCell listTurnaroundTimes={listTurnaroundTimes} />
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  listTurnaroundTimes: PropTypes.array.isRequired,
};

export default memo(TableHeader);
