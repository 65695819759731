import { gql } from '@apollo/client';

export default gql`
  query customerLocationSiloRoutes {
    customerLocations {
      id
      name
      slug
      siloRoutes {
        id
        designTypeCategory {
          id
          name
          slug
        }
        primarySilo {
          id
          name
          slug
        }
        secondarySilo {
          id
          name
          slug
        }
        catchAllSilo {
          id
          name
          slug
        }
      }
    }
  }
`;
