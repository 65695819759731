import { gql } from '@apollo/client';
import { ManufacturerOrganizationInfo } from '../fragments';

export default gql`
  query manufacturerOrganization($slug: String) {
    manufacturerOrganization(slug: $slug) {
      ...ManufacturerOrganizationInfo
    }
  }
  ${ManufacturerOrganizationInfo}
`;
