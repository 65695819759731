import ApiSettings from '@fullcontour/common/src/components/views/ApiSettings/ApiSettingsForm/ApiSettingsForm';
import LocationCustomerBilling from '@fullcontour/common/src/components/views/Locations/LocationCustomerBilling/LocationCustomerBilling';
import LocationCustomerDesignPreferences from '@fullcontour/common/src/components/views/Locations/LocationCustomerDesignPreferences/LocationCustomerDesignPreferencesForm';
import LocationDesignProfile from '@fullcontour/common/src/components/views/Locations/LocationDesignProfile/LocationDesignProfile';
import LocationFormCustomer from '../../views/Locations/LocationForms/LocationFormCustomer';
import LocationPriceList from '../../views/Locations/LocationPriceList/LocationPriceList';
import LocationShowInner from '../../views/Locations/LocationShow/LocationShowInner';
import LocationsListDataTable from '../../views/Locations/LocationsList/LocationsListDataTable';

const routes = [
  {
    path: '/:root(locations)',
    component: LocationsListDataTable,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)',
    component: LocationShowInner,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/:slug/:action(edit)',
    component: LocationFormCustomer,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/price-list',
    component: LocationPriceList,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/api-settings',
    component: ApiSettings,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/design-preferences',
    component: LocationCustomerDesignPreferences,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/billing',
    component: LocationCustomerBilling,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:action(edit)',
    component: LocationFormCustomer,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/price-list',
    component: LocationPriceList,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/api-settings',
    component: ApiSettings,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/design-preferences',
    component: LocationCustomerDesignPreferences,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/billing',
    component: LocationCustomerBilling,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/design-profile',
    component: LocationDesignProfile,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/:slug/design-profile',
    component: LocationDesignProfile,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug',
    component: LocationShowInner,
    sl: false,
    so: true,
  },
];

export default routes;
