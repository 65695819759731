import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DataWrap(props) {
  const { title, dataItem } = props;
  const { t } = useTranslation('formfields');
  return (
    <div className="infoWrapper">
      <p className="pLabel">{title === 'Name' ? title : t(title)}</p>
      <p>{t(dataItem)}</p>
    </div>
  );
}

DataWrap.propTypes = {
  title: PropTypes.string.isRequired,
  dataItem: PropTypes.any.isRequired,
};

export default DataWrap;
