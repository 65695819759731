import { useReactiveVar } from '@apollo/client';
import { Dropdown, getHelpers } from '@fullcontour/common';
import { InfoBlock } from '@fullcontour/common/src/components/shared/HelpCenterBar';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ManufacturerDropdown({
  data,
  infoCallback = null,
  id = null,
  field: { name, value },
  form: { errors, touched, setFieldValue },
}) {
  const helpFuncsData = useReactiveVar(getHelpers);
  const { t } = useTranslation('formfields');

  const label = t('Destination');

  const [disabled, setDisabled] = useState(!(data && data.length > 0));
  const [manufOrgs, setManufOrgs] = useState(
    data.map((m) => {
      const { value: val, text } = m;
      return {
        value: val,
        id: val,
        name: text,
      };
    }),
  );

  useEffect(() => {
    setManufOrgs(
      data.map((m) => {
        const { value: val, text } = m;
        return {
          value: val,
          id: val,
          name: text,
        };
      }),
    );
    setDisabled(!(data && data.length > 0));
    if (data.length === 1) {
      setFieldValue(name, data[0].value);
    }
  }, [data, name, setFieldValue]);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    helpFuncsData?.helpFuncs?.next();
  };

  return !disabled ? (
    <Dropdown
      id={id}
      name={name}
      label={label}
      error={errors[name]}
      errors={!!(errors[name] && touched[name])}
      options={manufOrgs}
      onChange={handleChange}
      placeholder={t('Destination...')}
      value={value}
      disabled={disabled}
      search
      required
      containerClassNames="mb-1"
      infoCallback={infoCallback}
    />
  ) : (
    <div>
      <label className="label mb-1">
        {label}
        <span className="has-text-danger">*</span>
        {infoCallback && <InfoBlock infoCallback={infoCallback} />}
      </label>
      <p className="has-text-danger">
        <small>{t('Please first select a design type')}</small>
      </p>
    </div>
  );
}

ManufacturerDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.string,
  infoCallback: PropTypes.func,
};

export default memo(ManufacturerDropdown);
