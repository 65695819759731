import { gql } from '@apollo/client';
import { InternalRejectionReasonInfo } from '../fragments';

export default gql`
  query internalRejectionReason($slug: String!) {
    internalRejectionReason(slug: $slug) {
      ...InternalRejectionReasonInfo
    }
  }
  ${InternalRejectionReasonInfo}
`;
