import { gql } from '@apollo/client';
import {
  DesignPreferenceInfo,
  DesignPreferenceProfileInfo,
  DesignTypeInfo,
} from '../fragments';

export default gql`
  mutation destroyDesignPreference($input: DesignPreferenceDestroyInput!) {
    destroyDesignPreference(input: $input) {
      clientMutationId
      designPreferenceProfile {
        ...DesignPreferenceProfileInfo
      }
    }
  }
  ${DesignPreferenceProfileInfo}
  ${DesignPreferenceInfo}
  ${DesignTypeInfo}
`;
