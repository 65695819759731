import { gql } from '@apollo/client';
import { ErrorMessageInfo, ModifierInfo } from '../fragments';

export default gql`
  mutation createModifier($input: ModifierCreateInput!) {
    createModifier(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      modifier {
        ...ModifierInfo
      }
    }
  }
  ${ErrorMessageInfo}
  ${ModifierInfo}
`;
