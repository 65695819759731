import { AccountMenuNew } from '@fullcontour/common';

const routes = [
  {
    path: [
      '/:root(users)/:action(new)',
      '/:root(locations)/:slug/:action(edit)',
      '/:root(organizations)/:action(edit)',
      '/:root(users)/:id/:action(edit)',
    ],
    exact: true,
    component: AccountMenuNew,
    so: true,
    sl: false,
  },
  {
    path: [
      '/:root(users)/:action(new)',
      '/:root(locations)/:action(edit)',
      '/:root(users)/:id/:action(edit)',
    ],
    exact: true,
    component: AccountMenuNew,
    so: false,
    sl: true,
  },
];

export default routes;
