import { useField, useFormikContext } from 'formik';
import { FieldContainer } from '../FieldContainer';

function WebsiteField() {
  const [field] = useField('website');
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { value } = field;

  const handleChange = (e) => {
    setFieldValue('website', e.target.value);
  };

  const handleBlur = () => {
    setFieldTouched('website', true);
  };

  return (
    <FieldContainer name="website" label="Website">
      <input
        id="website"
        name="website"
        required
        className="input"
        placeholder="https://example.com"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    </FieldContainer>
  );
}

export default WebsiteField;
