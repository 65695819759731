/* eslint-disable jsx-a11y/media-has-caption */
import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
import './GuideSelectionPopup.css';

function GuideSelectionPopup(props) {
  const { guideModalOpen, currentOrderItem } = props;

  return (
    <button
      type="button"
      className="button is-outlined is-primary mr-3 py-4"
      onClick={() => guideModalOpen()}
    >
      <BoxIcon
        className="icon is-flex is-align-items-center is-justify-content-center"
        name="bx-edit"
      />
      <span className="ml-1">Add Codes &nbsp;</span>
      <span>
        {currentOrderItem.guideCodes.length > 0
          ? `- selected (${currentOrderItem.guideCodes.length})`
          : ''}
      </span>
    </button>
  );
}

GuideSelectionPopup.propTypes = {
  currentOrderItem: PropTypes.object.isRequired,
  guideModalOpen: PropTypes.func.isRequired,
};

export default GuideSelectionPopup;
