import ReactHtmlParser from 'react-html-parser';
import { BaseDefinition } from '..';
import ThreeShapeXmlAttributes from '../../../ThreeShapeXmlAttributes';
import { OrderItems, tempOrderItems } from './ThreeShapeScan';

class ZipDefinition3ShapeScan extends BaseDefinition {
  buildCase = ({ ...args }) => {
    const {
      jsonObj,
      mappedZipFiles,
      unzippedFiles,
      customerLocation,
      softwareNodes,
    } = args;

    this.jsonObj = jsonObj;
    this.mappedZipFiles = mappedZipFiles;
    this.unzippedFiles = unzippedFiles;
    this.customerLocation = customerLocation;

    const softwareNodesIndication = softwareNodes.filter(
      (node) => node.nodeType === 'indication',
    );
    const softwareNodesMaterial = softwareNodes.filter(
      (node) => node.nodeType === 'material',
    );
    const softwareNodesTooth = softwareNodes.filter(
      (node) => node.nodeType === 'tooth',
    );

    this.softwareNodes = [
      softwareNodesTooth,
      softwareNodesMaterial,
      softwareNodesIndication,
    ];

    this.threeShape = new ThreeShapeXmlAttributes(this.jsonObj);

    return this.parseScan();
  };

  parseScan = () => {
    const { order } = this.parsedCase;
    const { software, instructions, files, orderItems, ...restOrder } = order;
    const updatedSoftware = this.threeShape.software();
    const threeShapeInstructions = this.threeShape.instructions();
    const updatedInstructions = threeShapeInstructions
      ? ReactHtmlParser(threeShapeInstructions)[0]
      : '';
    const updatedOrderItems = this.parseOrderItems();
    const name = this.threeShape.name();
    const fileType = 'initial_scans';

    const updatedFiles = [
      {
        originalFileName: this.file.name,
        fileType,
        fileUrl: null,
      },
    ];

    const updatedOrder = {
      ...restOrder,
      software: updatedSoftware,
      name,
      files: updatedFiles,
      instructions: updatedInstructions,
      orderItems: updatedOrderItems.length > 0 ? updatedOrderItems : orderItems,
    };

    const file = {
      fileType,
      file: this.file,
    };

    const metaData = {
      isParsed: updatedOrderItems.length > 0,
      softwareCompany: '3Shape',
      manufacturer: false,
    };

    return { order: updatedOrder, file, metaData };
  };

  parseOrderItems = () => {
    const parsedDesign = this.threeShape.parsedDesigns();
    const tempItems = tempOrderItems(parsedDesign.modelJobs);
    return new OrderItems({
      softwareNodes: this.softwareNodes,
      language: this.customerLocation.language,
      tempItems,
      customerLocation: this.customerLocation,
    }).parse();
  };
}

export default ZipDefinition3ShapeScan;
