import { gql } from '@apollo/client';

export default gql`
  query designLocationsAdmin {
    designLocationsAdmin {
      id
      attributes
    }
  }
`;
