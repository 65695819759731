import { useField, useFormikContext } from 'formik';

const useFormikConnect = (name) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const { value } = field;

  return { setFieldValue, value };
};

export default useFormikConnect;
