import { gql } from '@apollo/client';
import { ReportTypeInfo } from '../fragments';

export default gql`
  query reportTypes {
    reportTypes {
      ...ReportTypeInfo
    }
  }
  ${ReportTypeInfo}
`;
