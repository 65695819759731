import PropTypes from 'prop-types';
import Styles from './GlobalSearch.module.css';

function GlobalSearchResult({
  title,
  description,
  locationcode = null,
  image = null,
}) {
  return (
    <div style={{ maxHeight: '55px' }}>
      <div className={Styles.content}>
        <div>
          <div
            className={`has-text-left is-uppercase ${Styles.title}`}
            // style={{ textAlign: 'left', textTransform: 'uppercase' }}
          >
            {title}
          </div>
          <div
            className={`has-text-left ${Styles.description}`}
            style={{ wordBreak: 'break-all' }}
          >
            {description}
          </div>
        </div>
        <div>
          {locationcode && (
            <div className="is-size-7 has-text-right">{locationcode}</div>
          )}
          {image && (
            <div className="image has-text-right" style={{ maxWidth: '47px' }}>
              <img src={image} alt="fc logos" style={{ height: '100%' }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

GlobalSearchResult.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  locationcode: PropTypes.string,
  image: PropTypes.string,
};

export default GlobalSearchResult;
