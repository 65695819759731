/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { DESTROY_ORDER_FEEDBACK } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ConfirmationModal, ReactPortal } from '../../../../../..';
import Feedback from '../../../../../shared/Feedback/Feedback';
import { deleteOrderFeedback } from '../../../../../shared/Feedback/helpers';

function FeedbackModal(props) {
  const { modalOpen, setModalOpen, order, feedbackToEdit } = props;

  const [confirmationModal, setConfirmationModal] = useState(false);

  const { locationId } = order;

  const [triggerFeedbackSend, setTriggerFeedbackSend] = useState(false);
  const [sendEnable, setSendEnable] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const [deleteFeedbackMutation, { loading: deleteLoading }] = useMutation(
    DESTROY_ORDER_FEEDBACK,
  );

  const deleteFeedbackHandler = () => {
    deleteOrderFeedback({
      id: feedbackToEdit?.id,
      orderId: order.hexOrderCode,
      deleteFeedbackMutation,
      closeModal,
    });
  };

  return (
    <ReactPortal wrapperId="feedback-edit-modal" show={modalOpen}>
      <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit Feedback</p>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={closeModal}
            />
          </header>
          <section className="modal-card-body">
            <Feedback
              showFeedback={{
                state: order.state,
              }}
              data={order}
              bodyOnly
              message={feedbackToEdit.redesignReason}
              editUserId={feedbackToEdit.userId || feedbackToEdit.adminUser?.id}
              feedbackToEdit={feedbackToEdit}
              setSendEnable={setSendEnable}
              triggerFeedbackSend={triggerFeedbackSend}
              setTriggerFeedbackSend={setTriggerFeedbackSend}
              setSendLoading={setSendLoading}
              callback={closeModal}
              withoutRedesign
              locationId={locationId}
            />
          </section>
          <footer className="modal-card-foot is-flex">
            <button
              type="button"
              className="button is-dark"
              onClick={closeModal}
              style={{ marginRight: 'auto' }}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={deleteLoading}
              onClick={() => setConfirmationModal(true)}
              className={`button is-danger is-outlined ${
                deleteLoading ? 'is-loading' : ''
              }`}
            >
              Delete Feedback
            </button>
            <button
              type="button"
              disabled={!sendEnable}
              onClick={() => setTriggerFeedbackSend(true)}
              className={`button is-primary ${sendLoading ? 'is-loading' : ''}`}
            >
              Save changes
            </button>
          </footer>
        </div>
      </div>

      <ConfirmationModal
        open={confirmationModal}
        setModal={setConfirmationModal}
        text="Are you sure you want to delete?"
        mutation={deleteFeedbackHandler}
      />
    </ReactPortal>
  );
}

FeedbackModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  feedbackToEdit: PropTypes.object.isRequired,
};

export default FeedbackModal;
