import fdiMapping from './fdiMapping';
import palmerNotationDisplay from './palmerNotationDisplay';

const displayTeethBasedOnNotation = (notation, tooth) => {
  if (notation === 'uns') {
    return tooth;
  }

  if (notation === 'fdi') {
    return fdiMapping[tooth];
  }

  if (notation === 'palmer') {
    return palmerNotationDisplay(tooth);
  }
  return null;
};

export default displayTeethBasedOnNotation;
