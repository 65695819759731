import { Field } from 'formik';
import PropTypes from 'prop-types';
import FieldContainer from '../FieldContainer/FieldContainer';

function Textarea({
  label = 'description',
  name = 'description',
  placeholder = 'Fill out a description...',
  hideLabel = false,
  required = false,
}) {
  return (
    <FieldContainer
      name={name}
      label={label}
      hideLabel={hideLabel}
      required={required}
    >
      <Field
        id={name}
        name={name}
        placeholder={placeholder}
        component="textarea"
        className="textarea"
        rows="3"
      />
    </FieldContainer>
  );
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
};

export default Textarea;
