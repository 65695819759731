import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    active: Yup.boolean().required('Required'),
    integrationTypeId: Yup.string().required('Integration Type is Required'),
    labLocationId: Yup.string(),
    manufacturerOrganizationId: Yup.string(),
    credentials: Yup.object({
      token: Yup.string(),
      username: Yup.string(),
      password: Yup.string(),
      serverAddress: Yup.string(),
      workstationId: Yup.string(),
      initialPath: Yup.string(),
      passiveMode: Yup.boolean(),
      sftp: Yup.boolean(),
    }),
  });
}

export default schema;
