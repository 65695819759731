import PropTypes from 'prop-types';
import ElementImage from '../FilesShowElements/ElementImage/ElementImage';
import FilesShowFileMetadata from './FilesShowFileMetadata';

function FileViewImages({
  selectedFile,
  toggleVersions,
  selectedFileError = null,
}) {
  return (
    <>
      <ElementImage
        image={selectedFile}
        selectedFileError={selectedFileError}
      />
      <FilesShowFileMetadata
        selectedFile={selectedFile}
        toggleVersions={toggleVersions}
      />
    </>
  );
}

FileViewImages.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
  selectedFileError: PropTypes.string,
};

export default FileViewImages;
