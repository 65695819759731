/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMutation } from '@apollo/client';
import {
  DESTROY_GUIDE_CODE_GROUP,
  UPDATE_GUIDE_CODE_GROUP,
} from '@fullcontour/shared-api';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { groupsDataToFormValues } from '../utils';

function LocationGuideGroupsHeader({
  setModal,
  setEditMode,
  editMode,
  data,
  setSearchValue,
  searchValue = '',
}) {
  const searchRef = useRef(null);
  const { setValues, values } = useFormikContext();
  const { slug } = useParams();

  const { t } = useTranslation('designProfile');

  const cancelEdit = () => {
    setValues(groupsDataToFormValues(data.data.labLocationGuideCodeGroups));
    setEditMode(false);
  };

  const [updateGroup, { loading: loadingUpdate }] = useMutation(
    UPDATE_GUIDE_CODE_GROUP,
  );
  const [deleteGroup, { loading: loadingDelete }] = useMutation(
    DESTROY_GUIDE_CODE_GROUP,
  );

  const saveChanges = async () => {
    const locationSlug = slug ? { locationSlug: slug } : {};
    if (values.updatedGroups && Object.keys(values.updatedGroups).length) {
      const updatePromises = Object.keys(values.updatedGroups).map((id) =>
        updateGroup({
          variables: {
            input: {
              input: {
                id,
                name: data.data.labLocationGuideCodeGroups.find(
                  (item) => item.id === id,
                ).name,
                guideCodeIds: Object.values(values.selectedCodes[id]),
                ...locationSlug,
              },
            },
          },
        }),
      );
      await Promise.all(updatePromises);
    }
    if (values.deletedGroups && Object.keys(values.deletedGroups).length) {
      const deletedGroupsPromises = Object.keys(values.deletedGroups).map(
        (id) =>
          deleteGroup({
            variables: {
              input: {
                input: {
                  id,
                  ...locationSlug,
                },
              },
            },
          }),
      );
      await Promise.all(deletedGroupsPromises);
    }

    setEditMode(false);
    return null;
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div className="is-flex mb-2 flex-wrap guide-groups__header">
        <h5 className="title is-5 mr-auto">{t('Groups overview')}</h5>

        <div className="is-flex is-align-items-center mr-4 design-profile__header-search pr-3">
          <div className="search-box">
            <input
              type="text"
              className="search-input"
              placeholder={t('Search...')}
              value={searchValue || ''}
              onChange={handleSearch}
              ref={searchRef}
            />
            <div
              className="search-btn"
              onClick={() => searchRef.current.focus()}
            >
              <i className="bx bx-search" />
            </div>
          </div>
        </div>
        {editMode ? (
          <div className="buttons">
            <button
              type="submit"
              className="button is-outlined is-danger"
              onClick={cancelEdit}
            >
              <span className="icon">
                <i className="bx bx-x-circle" />
              </span>
              <span>{t('Cancel', { ns: 'common' })}</span>
            </button>

            <button
              type="submit"
              className={`button is-primary ${
                loadingUpdate || (loadingDelete && 'is-loading')
              }`}
              onClick={saveChanges}
            >
              <span className="icon">
                <i className="bx bx-check-circle" />
              </span>
              <span>{t('Save', { ns: 'common' })}</span>
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button
              type="submit"
              className="button is-primary"
              onClick={() => setModal(true)}
            >
              <span className="icon">
                <i className="bx bx-plus-circle" />
              </span>
              <span>{t('Create new group', { ns: 'common' })}</span>
            </button>
            <button
              type="submit"
              className="button is-primary"
              onClick={() => setEditMode(true)}
            >
              <span className="icon">
                <i className="bx bx-edit" />
              </span>
              <span>{t('Edit Groups', { ns: 'common' })}</span>
            </button>
          </div>
        )}
      </div>
      <div className="column is-6 mb-4">
        <p className="mb-2">
          {t(`We are aware that not every one wants the same designs and therefore
          we created Design Guide Groups where you can create a group containing
          the Design Guide Codes that fit your needs or a specific clients
          needs. This gives you the different abilities to select a group of
          codes to apply to a case.`)}
        </p>
        <p>
          {t(`By creating Design Guide Groups, you can save time as you through the
          order form can select and apply a wanted group which contains exactly
          the codes that you chose for that specific case, instead of selecting
          the Design Guide Codes one by one.`)}
        </p>
      </div>
    </div>
  );
}

LocationGuideGroupsHeader.propTypes = {
  setModal: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
};

export default LocationGuideGroupsHeader;
