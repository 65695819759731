import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from '../../../../shared/Context/CurrentUserContext';

function OrderShowInfoTotals({ order }) {
  const appName = import.meta.env.VITE_APP_NAME;
  const { currentUser } = useContext(CurrentUserContext);

  const { t } = useTranslation('common');

  return (
    <div>
      <div className="column has-text-right">
        <h6 className="title is-6 is-inline-block ml-3">
          {t('Total Units')}:{' '}
          {order.orderItems.reduce(
            (prev, current) => Number(prev) + Number(current.units),
            0,
          )}
        </h6>
        {appName === 'admin_portal' ||
        (appName === 'customer_portal' && currentUser?.roleLevel < 60) ? (
          <h6 className="title is-6 is-inline-block ml-3">
            {t('Total Price')}:{' $'}
            {order.orderItems
              .reduce(
                (prev, current) =>
                  Number(prev) + Number(current.units) * Number(current.price),
                0,
              )
              .toFixed(2)}
          </h6>
        ) : null}
      </div>
    </div>
  );
}

OrderShowInfoTotals.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowInfoTotals;
