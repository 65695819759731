/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';

function NotificationSettingsList(props) {
  const {
    data: { notificationTypes },
    values: { notificationTypeUsers },
    arrayHelpers: { push, remove },
    setFieldValue,
  } = props;

  const handleChange = (foundindex, notificationType) => {
    if (foundindex === -1) {
      push({
        email: true,
        notificationTypeId: notificationType.id,
        _destroy: false,
      });
    }

    if (
      foundindex !== -1 &&
      notificationTypeUsers[foundindex].id !== undefined &&
      notificationTypeUsers[foundindex]._destroy !== undefined &&
      notificationTypeUsers[foundindex]._destroy === false
    ) {
      setFieldValue(`notificationTypeUsers[${foundindex}]._destroy`, true);
      setFieldValue(`notificationTypeUsers[${foundindex}].email`, false);
    }

    if (
      foundindex !== -1 &&
      notificationTypeUsers[foundindex].id !== undefined &&
      notificationTypeUsers[foundindex]._destroy !== undefined &&
      notificationTypeUsers[foundindex]._destroy === true
    ) {
      setFieldValue(`notificationTypeUsers[${foundindex}]._destroy`, false);
      setFieldValue(`notificationTypeUsers[${foundindex}].email`, true);
    }

    if (
      foundindex !== -1 &&
      notificationTypeUsers[foundindex].id === undefined
    ) {
      remove(foundindex);
    }
  };

  return notificationTypes.length ? (
    notificationTypes.map((notificationType) => (
      <tr key={notificationType.id}>
        <td style={{ background: 'none' }}>
          <NonFormCheckboxField
            name={notificationType.id}
            removeLabel
            onChange={() => {
              handleChange(
                notificationTypeUsers.findIndex(
                  (typeUser) =>
                    typeUser.notificationTypeId === notificationType.id,
                ),
                notificationType,
              );
            }}
            checked={notificationTypeUsers.some(
              (typeUser) =>
                typeUser.notificationTypeId === notificationType.id && // eslint-disable-next-line
                typeUser._destroy === false
            )}
          />
        </td>
        <td>
          <h6 className="title is-6 has-text-info" style={{ marginBottom: 0 }}>
            {notificationType.name}
          </h6>
          <p>{notificationType.description}</p>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td>
        <NonFormCheckboxField name="disabled1" removeLabel disabled />
      </td>
      <td>
        <NonFormCheckboxField name="disabled2" removeLabel disabled />
      </td>
      <td>No Notifications Available</td>
    </tr>
  );
}

NotificationSettingsList.propTypes = {
  data: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
};

export default NotificationSettingsList;
