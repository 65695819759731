import { useTranslation } from 'react-i18next';

function useTranslatedPathname(params, segment) {
  const { t } = useTranslation('navigation');

  const entries = Object.entries(params);
  const segmentNoDashes = segment.replace(/[_-]/g, ' ');
  const filteredSegment =
    segmentNoDashes.charAt(0).toUpperCase() + segmentNoDashes.slice(1);

  const matchedEntry = entries.find((entry) => entry[1] === segment);

  if (matchedEntry && matchedEntry[0] !== 'slug' && matchedEntry[0] !== 'id') {
    return t(filteredSegment.toLowerCase());
  }

  return filteredSegment;
}

export default useTranslatedPathname;
