/* eslint-disable no-unsafe-optional-chaining */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { BoxIcon } from '../../../../../shared';

import FeedbackModal from './FeedbackModal';

function FeedbackTimeline(props) {
  const { order } = props;

  const { feedbacks } = order;

  const [feedbackToEdit, setFeedbackToEdit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditFeedback = (feedback) => {
    setFeedbackToEdit(feedback);
    setModalOpen(true);
  };

  const isAdmin = import.meta.env.VITE_APP_NAME === 'admin_portal';

  return (
    <>
      <div className="is-flex is-flex-direction-column">
        {feedbacks.map((feedback, idx) => (
          <div className="is-relative pb-6" key={feedback.id}>
            <div className="is-flex mb-4">
              <div
                style={{
                  flex: 1,
                  background: 'white',
                }}
              >
                <div
                  className={`order-pref__count ${
                    idx !== feedbacks?.length - 1
                      ? 'order-pref__redesign-count'
                      : ''
                  }`}
                >
                  {feedback.redesignCount}
                </div>
              </div>
              <div style={{ flex: 3 }}>Date:</div>
              <div style={{ flex: 6 }}>
                {moment(feedback.updatedAt).format('DD/MM/YYYY')}
              </div>
              {isAdmin && (
                <div>
                  <button
                    type="button"
                    onClick={() => handleEditFeedback(feedback)}
                    className="button is-primary"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', marginBottom: 20 }}>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 3 }}>Feedback category:</div>
              <div style={{ flex: 6 }}>
                {feedback.answers.map((answer) => (
                  <div style={{ display: 'flex' }} key={answer.id}>
                    {`${answer.orderItem.designTypeName}:`}

                    <div style={{ marginLeft: 10 }}>
                      {answer.options.map((option) => (
                        <div key={option?.id}>
                          <span style={{ marginRight: 5 }}>
                            {option?.category?.name}{' '}
                            <BoxIcon
                              name="bx-right-arrow-alt"
                              style={{ marginLeft: 5 }}
                            />
                          </span>
                          <span>{option?.name}</span>
                        </div>
                      ))}
                      {answer.customReason || ''}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 3, alignSelf: 'center' }}>
                Redesign reason:
              </div>
              {feedback.redesignReason ? (
                <div
                  style={{
                    flex: 6,
                  }}
                >
                  <div className="redesign-reason">
                    {feedback.redesignReason}
                  </div>
                </div>
              ) : (
                <div style={{ flex: 6 }}>
                  <i>No reason </i>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {isAdmin && modalOpen && (
        <FeedbackModal
          feedbackToEdit={feedbackToEdit}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          order={order}
        />
      )}
    </>
  );
}

FeedbackTimeline.propTypes = {
  order: PropTypes.object.isRequired,
};

export default FeedbackTimeline;
