import PropTypes from 'prop-types';
import FormSectionHeader from './FormSectionHeader';

function FormSection({
  children,
  style = {},
  flex = null,
  className = null,
  title = null,
  tileClass = null,
  hideHeader = false,
  fullWidth = false,
}) {
  return (
    <div className={`box ${className || ''}`} style={style}>
      <FormSectionHeader
        tileClass={tileClass}
        title={title}
        hideHeader={hideHeader}
      />
      <div
        className={flex ? 'is-flex' : 'is-full'}
        style={fullWidth ? { width: '100%' } : {}}
      >
        {children}
      </div>
    </div>
  );
}

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  flex: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  tileClass: PropTypes.string,
  hideHeader: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default FormSection;
