import palmerMapping from './palmerMapping';

const palmerNotationDisplay = (item, isPdf) => {
  const sharedBorderStyle = '1px solid black';
  const singleTooth = palmerMapping[item];

  if (isPdf) {
    return singleTooth;
  }
  let style = {};
  switch (singleTooth[1]) {
    case 'br':
      style = {
        borderBottom: sharedBorderStyle,
        borderRight: sharedBorderStyle,
      };
      break;
    case 'bl':
      style = {
        borderBottom: sharedBorderStyle,
        borderLeft: sharedBorderStyle,
      };
      break;
    case 'tl':
      style = {
        borderTop: sharedBorderStyle,
        borderLeft: sharedBorderStyle,
      };
      break;
    case 'tr':
      style = {
        borderTop: sharedBorderStyle,
        borderRight: sharedBorderStyle,
      };
      break;
    default:
      return null;
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 5px',
        margin: 3,
        ...style,
      }}
    >
      {singleTooth[0]}
    </div>
  );
};

export default palmerNotationDisplay;
