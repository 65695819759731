import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsSubmissionTimeCustomer(props) {
  const { reportDetails } = props;

  const gridOptionsByHour = {
    rowData: reportDetails[0],
    defaultColDef: {
      floatingFilter: true,
    },
    columnDefs: [
      {
        field: '12am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '1am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '2am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '3am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '4am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '5am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '6am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '7am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '9am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '10am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '11am',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '12pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '1pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '2pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '3pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '4pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '5pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '6pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '7pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '9pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '10pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '11pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  const gridOptionsByDay = {
    rowData: reportDetails[1],
    defaultColDef: {
      floatingFilter: false,
    },
    columnDefs: [
      {
        field: 'Sunday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Monday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Tuesday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Wednesday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Thursday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Friday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Saturday',
        type: 'numericColumn',
        minWidth: 75,
        suppressHeaderMenuButton: true,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  return (
    <>
      <div className="columns" style={{ marginBottom: 50 }}>
        <h5 className="title is-5">By Time</h5>
      </div>
      <div className="columns">
        <AgGridContainer gridOptions={gridOptionsByHour} containerHeight={90} />
      </div>
      <div className="columns" style={{ marginBottom: 50, marginTop: 50 }}>
        <h5 className="title is-5">By Day</h5>
      </div>
      <div className="columns">
        <AgGridContainer gridOptions={gridOptionsByDay} containerHeight={90} />
      </div>
    </>
  );
}

ResultsSubmissionTimeCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsSubmissionTimeCustomer;
