import { useApolloClient } from '@apollo/client';
import {
  ACTION_INVOICE_RESEND,
  ACTION_INVOICE_TRANSACTION_RESEND,
  GET_INVOICE,
  GET_INVOICE_CSV,
  UPDATE_INVOICE_STATUS,
} from '@fullcontour/shared-api';
import JsPDF from 'jspdf';
import { useState } from 'react';
import { renderToString } from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../config/log';
import InvoiceAddressRow from '../InvoiceShow/InvoiceAddressRow';
import InvoiceDetails from '../InvoiceShow/InvoiceDetails';
import InvoiceStatusMessage from '../InvoiceShow/InvoiceStatusMessage';
import InvoiceSummary from '../InvoiceShow/InvoiceSummary';

function useInvoice() {
  const client = useApolloClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(false);

  const closeModal = () => {
    setSelectedItem(null);
    setModalState(false);
  };

  const openModal = () => {
    setModalState(true);
  };

  const statusUpdate = (statusAction, invoice, refetch) => {
    client
      .mutate({
        mutation: UPDATE_INVOICE_STATUS,
        variables: {
          input: {
            input: {
              id: invoice.id,
              statusAction,
            },
            clientMutationId: uuidv4(),
          },
        },
      })
      .then(() => {
        refetch();
        setSelectedItem(null);
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const resendInvoice = (invoice, refetch) => {
    client
      .mutate({
        mutation: ACTION_INVOICE_RESEND,
        variables: {
          input: {
            id: invoice.id,
            clientMutationId: uuidv4(),
          },
        },
      })
      .then(() => {
        refetch();
        setSelectedItem(null);
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const resendInvoiceTransaction = (invoice, refetch) => {
    client
      .mutate({
        mutation: ACTION_INVOICE_TRANSACTION_RESEND,
        variables: {
          input: {
            id: invoice.id,
            clientMutationId: uuidv4(),
          },
        },
      })
      .then(() => {
        refetch();
        setSelectedItem(null);
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const getCsv = async () => {
    const { data } = await client.query({
      query: GET_INVOICE_CSV,
      variables: { id: selectedItem.id },
      fetchPolicy: 'no-cache',
    });

    const a = document.createElement('a');
    const file = new Blob([data.invoiceCsv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.style = 'display: none';
    document.body.appendChild(a);
    a.download = `${selectedItem.hexInvoiceNumber}-invoice.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const downloadPDF = () => {
    client
      .query({
        query: GET_INVOICE,
        variables: { hexInvoiceNumber: selectedItem.hexInvoiceNumber },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        const string = renderToString(
          <div
            className="ml-5"
            id="printableInvoice"
            style={{ width: '700px' }}
          >
            <div id="printableInvoiceInner">
              <div className="is-flex is-flex-direction-row">
                <InvoiceStatusMessage
                  invoice={data?.invoice}
                  toDownload
                  messageRightContent={
                    <h2 className="is-align-self-flex-end">
                      Invoice #
                      <span className="is-capitalized">
                        {data?.invoice?.hexInvoiceNumber}
                      </span>
                    </h2>
                  }
                />
              </div>
              <div style={{ width: '200mm' }}>
                <InvoiceAddressRow invoice={data?.invoice} />
                <InvoiceSummary invoice={data?.invoice} toDownload />
                <InvoiceDetails invoice={data?.invoice} toDownload />
              </div>
            </div>
          </div>,
        );
        const pdf = new JsPDF('p', 'mm', 'a4');
        pdf.html(string, { html2canvas: { scale: 0.28 } }).then(() => {
          pdf.save(`Invoice#${data?.invoice?.hexInvoiceNumber}.pdf`);
        });
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const onSelectionChanged = (event) => {
    const selectedItems = event.api.getSelectedNodes();
    const newSelectedItem =
      selectedItems.length > 0 ? selectedItems[0].data : null;
    setSelectedItem(newSelectedItem);
  };

  return {
    openModal,
    closeModal,
    modalState,
    statusUpdate,
    resendInvoice,
    resendInvoiceTransaction,
    getCsv,
    downloadPDF,
    selectedItem,
    onSelectionChanged,
    setSelectedItem,
  };
}

export default useInvoice;
