import { UPDATE_CONTACT } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
}) {
  const result = await client.mutate({
    mutation: UPDATE_CONTACT,
    variables,
  });
  const { updateContact } = result.data;
  if (updateContact.errorMessages.length) {
    setErrorMessages(updateContact.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    closeModal();
  }
}

export default update;
