import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    name: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    website: Yup.string().url('Must be a valid URL'),
    organizationId: Yup.string().required('Customer Organization is Required'),
    priceListId: Yup.string().required('Price List is Required'),
    paymentTermId: Yup.string().required('Price List is Required'),
    turnaroundTimeId: Yup.string().required(
      'Default Turnaround Time is Required',
    ),
    assignedManufacturers: Yup.array().of(
      Yup.object({
        manufacturerOrganizationId: Yup.string().required(),
        _destroy: Yup.boolean(),
      }),
    ),
    address: Yup.object({
      address: Yup.string().required('Address is Required'),
      address2: Yup.string(),
      city: Yup.string(),
      region: Yup.string().required('Region is required'),
      postalCode: Yup.string(),
      country: Yup.string().required('Country is required'),
    }),
    subType: Yup.string().required('Location Type is required'),
    logoUrl: Yup.string().url(),
    phone: Yup.string(),
    locationBilling: Yup.bool().required('Required'),
    fullViewer: Yup.bool().required('Required'),
  });
}

export default schema;
