import { gql } from '@apollo/client';

export default gql`
  query orderApprovalLogAuth($id: ID!, $accessToken: String!) {
    orderApprovalLogAuth(id: $id, accessToken: $accessToken) {
      id
      doctorApproval
      doctorEmail
      doctorIp
      doctorName
      accessToken
      doctorAcceptedAt
      doctorRejected
      order {
        id
        hexOrderCode
        requiresDesignApproval
        instructions
        location {
          id
          name
          website
          logoUrl
          approvalDownloadStl
        }
        communicateAccount {
          id
          isGuided
        }
        finishedScans {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        attachments {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        objFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
          category
          sortOrder
        }
        stlFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        qualityControlImages {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        alignerManufacturerFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        orderItems {
          id
          modifiers {
            name
          }
          designType {
            id
            name
            showStlFiles
            feedbackCategories {
              id
              name
              options {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
