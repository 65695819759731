import PropTypes from 'prop-types';

function DataPointWrapper({ children }) {
  return (
    <div className="column">
      <div className="field">{children}</div>
    </div>
  );
}

DataPointWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataPointWrapper;
