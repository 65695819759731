/* eslint-disable no-unused-vars */
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import FolderField from './FolderField';

function CaseFoldersField({ index, form, designTypes, error = null, touched }) {
  const { values, setFieldValue } = useFormikContext();

  const selectedDesignType = designTypes[values.businessUnitName].find(
    ({ id }) => id === values.orderItems[index].designTypeId,
  );
  const designTypeCaseFolders = useMemo(
    () =>
      selectedDesignType.designTypeCaseFolders.map((dCF) => ({
        ...dCF,
        ...selectedDesignType.caseFolders.find(
          (cF) => cF.id === dCF.caseFolderId,
        ),
      })),
    [values],
  );

  const filtered = useMemo(
    () =>
      designTypeCaseFolders.reduce(
        (filters, dCF) => {
          if (dCF.required) {
            filters.required.push(dCF);
          } else {
            filters.notRequired.push(dCF);
          }
          return filters;
        },
        { required: [], notRequired: [] },
      ),
    [values.orderItems],
  );

  const uniqueCaseId = useMemo(() => {
    const timestamp = Math.floor(Date.now() / 1000);
    const caseName = values.name.toLowerCase();
    return `${caseName}-${timestamp}`;
  }, [values.name]);

  let fileIndex = -1;

  useEffect(() => {
    setFieldValue('intOrderId', uniqueCaseId);
  }, [uniqueCaseId]);

  return (
    <FieldArray name="files">
      {({ push, replace }) => (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          {Object.entries(filtered).map(([_, dCFs], i) => {
            let title;

            if (i === 0) {
              title =
                'The below files are required. You can drag and drop or click to upload.';
            } else if (filtered.required.length === 0) {
              title = 'At least one scan is required';
            } else {
              title = 'Additional files';
            }
            return (
              <div
                key={`section_${i === 0 ? 'required' : 'non_required'}`}
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mb-2"
              >
                {dCFs.length > 0 && (
                  <div className="is-size-6 has-text-centered mb-2">
                    {title}
                  </div>
                )}
                <div className="mt-1 is-flex is-flex-wrap-wrap columns is-2 is-justify-content-center is-align-items-top mb-2">
                  {dCFs.map(
                    ({
                      name,
                      mimeTypes,
                      allowMultiple,
                      description,
                      ...rest
                    }) => {
                      fileIndex++;

                      return (
                        <FolderField
                          key={`caseFolder_${name}`}
                          uniqueCaseId={uniqueCaseId}
                          push={push}
                          replace={replace}
                          {...form}
                          values={values}
                          accept={mimeTypes}
                          allowMultiple={allowMultiple}
                          description={description}
                          {...rest}
                          name={`caseFolder[${fileIndex}]`}
                          error={error && error[rest.caseFolderId]}
                          touched={touched}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </FieldArray>
  );
}
CaseFoldersField.propTypes = {
  index: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  designTypes: PropTypes.object.isRequired,
  error: PropTypes.any,
  touched: PropTypes.bool.isRequired,
};
export default CaseFoldersField;
