import { useQuery } from '@apollo/client';
import { getRoot, LoadingOrError } from '@fullcontour/common';
import { GET_CUSTOMER_USER } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { ShowContainer } from '../../../shared/ShowElements';

function UserShowInner() {
  const { id } = useParams();
  const root = getRoot();

  const type = 'customer';
  const {
    error: cError,
    loading: cLoading,
    data: cData,
  } = useQuery(GET_CUSTOMER_USER, {
    fetchPolicy: 'cache-and-network',
    skip: type !== 'customer',
    variables: { id },
  });

  let loading;
  let error;
  let data;

  if (type === 'customer') {
    loading = cLoading;
    error = cError;
    data = cData;
  }

  if (loading || error)
    return (
      <LoadingOrError error={error} loading={loading} title={`${type} User`} />
    );

  const user = data[`${type}User`];
  let color;

  if (user.state === 'pending') {
    color = 'is-warning';
  }

  if (user.state === 'active') {
    color = 'is-success';
  }

  if (user.state === 'inactive') {
    color = 'is-danger';
  }

  return (
    <ShowContainer
      title={`${user.firstName} ${user.lastName}`}
      editLink={`/${root}/${id}/edit`}
      user={user}
      createdAt={user.createdAt}
      updatedAt={user.updatedAt}
      leftContent={
        <>
          <p className="has-text-white">
            <span className="has-text-weight-bold has-text-primary">
              Role:{' '}
            </span>
            <span className="has-text-grey-dark">{user.role.name}</span>
            <br />
            <span className="has-text-weight-bold has-text-primary">
              Email:{' '}
            </span>
            <span className="has-text-grey-dark">{user.email}</span>
            {type === 'design' && (
              <>
                <br />
                <span className="has-text-weight-bold has-text-primary">
                  ID:{' '}
                </span>
                <span className="has-text-grey-dark">{user.id}</span>
              </>
            )}
          </p>
          <span className="has-text-weight-bold has-text-primary">
            Status:{' '}
          </span>
          <span className={`tag ${color}`}>{user.state}</span>
        </>
      }
    />
  );
}

export default UserShowInner;
