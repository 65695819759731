import { gql } from '@apollo/client';

const AdminMessageInfo = gql`
  fragment AdminMessageInfo on AdminMessage {
    id
    name
    slug
    content
    active
    showAdministrator
    showDesign
    showCustomer
    showManufacturer
    labLocationIds
    labOrganizationIds
    createdAt
    updatedAt
  }
`;

export default AdminMessageInfo;
