import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceMetadata from './PreferenceMetadata';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeMidline(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Correct Midline">
      <div className="checkWrap">
        <NonFormCheckboxField
          name="midLineUpper"
          labelText="Upper"
          readOnly
          checked={
            'UpperJawPrescription' in prescription &&
            'Midline' in prescription.UpperJawPrescription
          }
        />
        {'UpperJawPrescription' in prescription &&
          'Midline' in prescription.UpperJawPrescription && (
            <PreferenceMetadata
              value={prescription.UpperJawPrescription.Midline}
            />
          )}
      </div>
      <div className="checkWrap">
        <NonFormCheckboxField
          name="midLineLower"
          labelText="Lower"
          readOnly
          checked={
            'LowerJawPrescription' in prescription &&
            'Midline' in prescription.LowerJawPrescription
          }
        />
        {'LowerJawPrescription' in prescription &&
          'Midline' in prescription.LowerJawPrescription && (
            <PreferenceMetadata
              value={prescription.LowerJawPrescription.Midline}
            />
          )}
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeMidline.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeMidline;
