/* eslint-disable no-restricted-syntax */
export default function fillDesignTypes(
  designTypesData,
  designTypeCategoriesData,
  filterNotPresentHere,
) {
  const designTypesDropdownData = {};
  for (const designTypeCategory of designTypeCategoriesData) {
    if (designTypesDropdownData[designTypeCategory.businessUnit.slug]) {
      designTypesDropdownData[designTypeCategory.businessUnit.slug].categories[
        designTypeCategory.slug
      ] = {
        name: designTypeCategory.name,
        slug: designTypeCategory.slug,
        value: designTypeCategory.value,
        designTypes: [],
      };
    } else {
      designTypesDropdownData[designTypeCategory.businessUnit.slug] = {
        categories: {
          [designTypeCategory.slug]: {
            name: designTypeCategory.name,
            slug: designTypeCategory.slug,
            value: designTypeCategory.value,
            designTypes: [],
          },
        },
      };
    }
  }
  if (Object.keys(designTypesDropdownData).length > 0) {
    for (const businessUnit of Object.keys(designTypesDropdownData)) {
      for (const designType of designTypesData) {
        const designTypeExist = filterNotPresentHere.find(
          (dT) => dT.id === designType.id,
        );
        if (
          designTypesDropdownData[businessUnit].categories[
            designType.designTypeCategory.slug
          ] &&
          designTypeExist &&
          designTypeExist.enableSimpleUploader
        ) {
          designTypesDropdownData[businessUnit].categories[
            designType.designTypeCategory.slug
          ].designTypes.push(designTypeExist);
        }
      }
    }
    const designTypes = {};
    for (const [name, { categories }] of Object.entries(
      designTypesDropdownData,
    )) {
      designTypes[name] = [];
      for (const category of Object.values(categories)) {
        designTypes[name] = [...designTypes[name], ...category.designTypes];
      }
    }
    return designTypes;
  }

  return designTypesDropdownData;
}
