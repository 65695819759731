import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCadFile($input: CadFileCreateInput!) {
    createCadFile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      cadFile {
        id
        s3Key
        filename
        location {
          id
          slug
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
