/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FeedbackSearchInput(props) {
  const { setSearchValue, searchValue } = props;

  const { t } = useTranslation('common');

  const searchHandler = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="field mt-5">
      <label className="label mb-0 pb-2" htmlFor="search">
        {t('Please choose a feedback option')}
      </label>
      <div className="control has-icons-right">
        <input
          onChange={searchHandler}
          className="input"
          name="search"
          id="search"
          autoFocus
          type="text"
          placeholder={t('Search Feedback Categories...')}
          value={searchValue}
        />

        <span className="icon is-small is-right">
          <i className="bx bx-search-alt" />
        </span>
      </div>
    </div>
  );
}

FeedbackSearchInput.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default FeedbackSearchInput;
