import { gql } from '@apollo/client';

export default gql`
  query integrationTypesDropdown {
    integrationTypes {
      id
      name
      value
      symbol
    }
  }
`;
