import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FilesShowSelectMessage({
  selectedFile = null,
  selectedFolder = null,
}) {
  const { t } = useTranslation('caseFiles');

  return !selectedFolder && !selectedFile ? (
    <article className="message is-info mt-3">
      <div className="message-body is-flex p-4">
        <span className="icon is-large mr-4">
          <i className="bx bx-info-square is-size-1" />
        </span>

        <div>
          <h6 className="title is-6 mb-4">{t('Info')}</h6>
          <p className="mb-2">
            {t(
              'Select a folder or file from the list to review details, download or add files, etc.',
            )}
          </p>
          <p className="mb-2">
            {t(
              'If your case is on hold and requires adjusting the сase or adding new scans, click on the "Initial Scans" folder and drag&drop an order exported from the design software or ZIP file with scans in the drop section. Initial scans must correspond to the case creation requirements for specific design types. You may use the "Attachments" folder to add any other types of files. To release the case from on hold, click on the "Actions" Tab and the "Release Hold" option.',
            )}
          </p>
        </div>
      </div>
    </article>
  ) : null;
}

FilesShowSelectMessage.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
};

export default FilesShowSelectMessage;
