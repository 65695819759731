/* eslint-disable jsx-a11y/media-has-caption */
import { GuideCodesSelected } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GuideSelection from '../GuideSelection/GuideSelection';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetaDesignGuide({
  orderItem,
  index,
  selectedDesignType = null,
  labLocationGuideCategoryGroups = null,
}) {
  const { t } = useTranslation('showelements');

  const guideCodes = orderItem.guideCodes?.length;

  if (labLocationGuideCategoryGroups) {
    const gcodes = [];
    const guideCategoryGroupsOfDesignType = labLocationGuideCategoryGroups.find(
      (item) => item.designTypeId === selectedDesignType?.id,
    );
    guideCategoryGroupsOfDesignType?.guideCategoryGroups.forEach(
      (categoryGroup) => {
        categoryGroup.guideCategories?.forEach((guideCategory) => {
          guideCategory.guideCodes.forEach((guideCode) => {
            gcodes.push(guideCode);
          });
        });
      },
    );
    const isDesignGuideExist = gcodes.length > 0;
    return (
      <FieldMetadataWrapper
        title="Design Guide Codes"
        disabled={!isDesignGuideExist}
        infoText={
          <p>
            {t(`Design Guide Codes is a visual representation of instructions for
            design. See the codes that we use for each design type. Select your
            design preferences and ensure that 3Shape Design Service designs the
            order according to your needs.`)}
            <video
              src="https://fc2-production.s3.us-west-2.amazonaws.com/assets/videos/How+to+Use+Design+Guide+Codes+(4).mp4"
              width="100%"
              className="mt-4"
              preload="auto"
              controls
              style={{ border: '1px solid #dddddd' }}
            />
          </p>
        }
      >
        {isDesignGuideExist && (
          <div
            className="is-flex is-align-items-center"
            style={{ width: '100%' }}
          >
            <GuideSelection
              index={index}
              selectedDesignType={selectedDesignType}
            />
            <GuideCodesSelected index={index} small />
          </div>
        )}
      </FieldMetadataWrapper>
    );
  }

  return selectedDesignType ? (
    <FieldMetadataWrapper
      title="Guide"
      required={guideCodes !== 0}
      disabled={!selectedDesignType?.designGuideCategories?.length}
    >
      {selectedDesignType?.designGuideCategories?.length ? (
        <div
          className="is-flex is-align-items-center"
          style={{ width: '100%' }}
        >
          {orderItem?.guideCodes?.map((g) => g.codes).join(', ')}
          <GuideSelection
            index={index}
            selectedDesignType={selectedDesignType}
            margin={!!orderItem?.guideCodes?.length}
          />
          <GuideCodesSelected index={index} small />
        </div>
      ) : null}
    </FieldMetadataWrapper>
  ) : null;
}

FieldMetaDesignGuide.propTypes = {
  orderItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedDesignType: PropTypes.object,
  labLocationGuideCategoryGroups: PropTypes.array,
};

export default FieldMetaDesignGuide;
