import { gql } from '@apollo/client';

export default gql`
  query adminMessageActive($showApp: Boolean) {
    adminMessage(showApp: $showApp) {
      id
      name
      content
    }
    readAdminMessage @client {
      id
    }
  }
`;
