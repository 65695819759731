import { useApolloClient } from '@apollo/client';
import { ACTION_ORDER_SEND_FOLLOW_UP_EMAIL } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  CreateNotification,
  FieldContainer,
  SubmitButton,
  Textarea,
} from '../../../../shared';
import { schema } from './helpers';

function FollowUpForm({ followUpModalData = {}, followUpModalActive }) {
  const client = useApolloClient();
  return (
    <Formik
      initialValues={{
        subject: followUpModalData.hexOrderCode
          ? `Follow up on review of design for case ${followUpModalData.hexOrderCode}`
          : '',
        message: '',
        email:
          (followUpModalData.approvalLogs?.length &&
            followUpModalData.approvalLogs[0]?.doctorEmail) ||
          '',
      }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await client.mutate({
            mutation: ACTION_ORDER_SEND_FOLLOW_UP_EMAIL,
            variables: {
              input: {
                input: values,
              },
            },
          });
          CreateNotification.createNotification({
            type: 'success',
            message: `You have successfully followed up!`,
          });
          followUpModalActive(false);
        } catch (e) {
          CreateNotification.createNotification({
            type: 'error',
            message: `Something went wrong`,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FieldContainer required name="email">
            <Field
              id="email"
              name="email"
              type="email"
              required
              placeholder="Doctor Email"
              className="input"
            />
          </FieldContainer>
          <FieldContainer required name="subject">
            <Field
              id="subject"
              name="subject"
              type="text"
              required
              placeholder="Subject"
              className="input"
            />
          </FieldContainer>
          <Textarea
            name="message"
            label="message"
            placeholder="Message..."
            required
          />

          <SubmitButton title="send" className="is-pulled-right" />
        </form>
      )}
    </Formik>
  );
}

FollowUpForm.propTypes = {
  followUpModalData: PropTypes.object.isRequired,
  followUpModalActive: PropTypes.func.isRequired,
};

export default FollowUpForm;
