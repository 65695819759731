import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function FormErrors({ name = '' }) {
  const { t } = useTranslation('formFieldsErrors');

  return (
    <ErrorMessage
      name={name}
      render={(msg) => (
        <p className="help is-danger">
          <b>
            <small>{typeof msg === 'object' ? msg : t(msg)}</small>
          </b>
        </p>
      )}
    />
  );
}

FormErrors.propTypes = {
  name: PropTypes.string,
};

export default memo(FormErrors);
