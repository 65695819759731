/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import ObjElementAttachmentInner from './ObjElementAttachmentInner';

function ObjElementAttachment(props) {
  const { file, unn, matrix } = props;

  const name = useMemo(
    () => file.originalFileName.split('.obj')[0],
    [file.originalFileName],
  );

  const obj = useLoader(OBJLoader, file.signedUrl);

  return (
    <ObjElementAttachmentInner
      obj={obj}
      name={name}
      unn={unn}
      matrix={matrix}
    />
  );
}

ObjElementAttachment.propTypes = {
  file: PropTypes.object.isRequired,
  unn: PropTypes.number.isRequired,
  matrix: PropTypes.array.isRequired,
};

export default ObjElementAttachment;
