import { gql } from '@apollo/client';
import { IntegrationInfo } from '../fragments';

export default gql`
  query integrations($slug: String, $root: String!) {
    integrations(slug: $slug, root: $root) {
      ...IntegrationInfo
    }
  }
  ${IntegrationInfo}
`;
