/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
const calculateMovementsPerStage = (stages) => {
  const updatedStages = stages.length
    ? stages.map((item, idx) => ({
        ...item,

        Teeth: (() =>
          item.Teeth.map((tooth, toothIdx) => ({
            ...tooth,
            Attachments: (() => {
              if (!idx) {
                return tooth.Attachments;
              }
              return (
                stages[Number(idx - 1)].Teeth[toothIdx].Attachments !==
                tooth.Attachments
              );
            })(),
            InterproximalReduction: (() => {
              if (!tooth.InterproximalReduction) return null;

              const keysToCheck = {
                DistalReductionValue: true,
                MesialReductionValue: true,
              };
              const reverseKeys = {
                DistalReductionValue: 'MesialReductionValue',
                MesialReductionValue: 'DistalReductionValue',
              };

              if (tooth.InterproximalReduction.Value) {
                return Object.keys(tooth.InterproximalReduction.Value).reduce(
                  (obj, key) => {
                    if (keysToCheck[key]) {
                      if (
                        stages[Number(idx - 1)].Teeth[toothIdx]
                          .InterproximalReduction?.Value
                      ) {
                        const newValueForAKey =
                          tooth.InterproximalReduction.Value[key] -
                          stages[Number(idx - 1)].Teeth[toothIdx]
                            .InterproximalReduction.Value[key];

                        if (newValueForAKey) {
                          if (obj.Value) {
                            obj.Value[key] = newValueForAKey;
                            obj.Value.isVisible = true;
                            obj.Value[`${key}Visible`] = true;
                            obj.Value[`${reverseKeys[key]}Visible`] = false;
                          } else {
                            obj = {
                              Value: {
                                ...tooth.InterproximalReduction.Value,
                                [key]: newValueForAKey,
                                isVisible: true,
                                [`${key}Visible`]: true,
                                [`${reverseKeys[key]}Visible`]: false,
                              },
                            };
                          }
                        } else {
                          obj = {
                            Value: {
                              ...(Object.keys(obj).length
                                ? obj.Value
                                : {
                                    ...tooth.InterproximalReduction.Value,
                                    isVisible: false,
                                  }),
                            },
                          };
                        }
                      } else {
                        obj = {
                          Value: {
                            ...tooth.InterproximalReduction.Value,
                            isVisible: true,
                          },
                        };
                      }
                    }

                    return obj;
                  },
                  {},
                );
              }

              return {};
            })(),
            Movement: {
              ...tooth.Movement,
              Value: {
                ...tooth?.Movement?.Value,
                MovementValues: {
                  Value: (() => {
                    const valuesObj =
                      tooth.Movement?.Value.MovementValues?.Value;
                    if (valuesObj && typeof valuesObj === 'object') {
                      return Object.keys(valuesObj).reduce((obj, key) => {
                        const newObj = { ...obj };
                        if (stages[Number(idx)] && idx) {
                          newObj[key] =
                            valuesObj[key] -
                            stages[Number(idx) - 1].Teeth[Number(toothIdx)]
                              .Movement?.Value.MovementValues?.Value[key];
                        }

                        return newObj;
                      }, valuesObj);
                    }
                    return {};
                  })(),
                },
              },
            },
          })))(),
      }))
    : null;

  return updatedStages?.length
    ? updatedStages.map((stage) => {
        stage.Teeth = stage.Teeth.filter(
          (tooth) =>
            Object.keys(tooth.Movement.Value.MovementValues.Value).length,
        );
        return stage;
      })
    : null;
};

export default calculateMovementsPerStage;
