import * as Yup from 'yup';

function schema({ maxAmount }) {
  return Yup.object({
    invoiceId: Yup.string().required('An Invoice is required'),
    message: Yup.string().required('A Message is required'),
    cardBilled: Yup.string().required("Please enter an identifier for this payment"),
    isCard: Yup.boolean().required(),
    paymentAmount: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .max(maxAmount)
      .typeError('Must be a valid Amount'),
  });
}

export default schema;
