import { Placeholder } from '@fullcontour/common';

function OverpanelCaseItemLoader() {
  return (
    <div
      className="columns"
      style={{
        minHeight: 98,
      }}
    >
      <div
        className="column p-5 is-2"
        style={{ borderRight: '1px solid rgb(221, 217, 217)' }}
      >
        <Placeholder className="mb-3" width="80%" />
        <Placeholder className="mb-5" height="30px" />
        <Placeholder height="14px" width="60%" />
      </div>
      <div
        className="column is-6 p-5 is-flex"
        style={{ borderRight: '1px solid rgb(221, 217, 217)' }}
      >
        <Placeholder className="mr-5" height="30px" />
        <Placeholder className="mr-5" />
        <Placeholder className="mr-5" />
        <Placeholder height="30px" />
      </div>
      <div
        className="column is-3 p-5 is-flex"
        style={{ borderRight: '1px solid rgb(221, 217, 217)' }}
      >
        <Placeholder className="mr-5" height="30px" />
        <Placeholder className="mr-5" height="30px" />
      </div>
      <div className="column is-1 p-5">
        <Placeholder />
      </div>
    </div>
  );
}

OverpanelCaseItemLoader.propTypes = {};

export default OverpanelCaseItemLoader;
