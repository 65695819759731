import { memo } from 'react';
import { useLocation } from 'react-router';
import { Media } from '../../../config/media';
import { SidebarBreadcrumbRoutes, SidebarMainRoutes } from '../../routing';
import OrderDetailsSidebarLayout from './OrderDetailsSidebarLayout';
import SidebarArea from './SidebarArea';
import './SidebarLayout.css';

function SidebarLayout() {
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const isOrderDetails =
    splitPath[1] === 'orders' && splitPath[2] === 'details';
  return (
    <>
      <Media greaterThanOrEqual="tablet" className="breadCrumbs">
        <SidebarBreadcrumbRoutes />
      </Media>

      {isOrderDetails ? (
        <OrderDetailsSidebarLayout />
      ) : (
        <div className="sidebarDesktopContainer">
          <SidebarArea />
          <main className="sidebarMain" style={{ position: 'relative' }}>
            <SidebarMainRoutes />
          </main>
        </div>
      )}
    </>
  );
}

export default memo(SidebarLayout);
