import PropTypes from 'prop-types';

function HipaaModal(props) {
  const { modalOpen, handleModalClose } = props;

  return modalOpen ? (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-content">
        <p className="image is-4by3">
          <img
            src="https://cdn.fullcontour.com/logos/hipaa.png"
            alt="HIPAA Certification"
          />
        </p>
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={handleModalClose}
      />
    </div>
  ) : null;
}

HipaaModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default HipaaModal;
