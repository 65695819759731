import PropTypes from 'prop-types';
import { Highlighted } from '../../../Components';
import BoxIcon from '../../../Icons/BoxIcon';
import { useGuideCodeCardContext } from './context/GuideCodeCardContext';

function GuideCodeCardFooter({
  editMode = false,
  groupdId = null,
  removeCard = () => {},
}) {
  const { data } = useGuideCodeCardContext();

  const { category, name, searchValue, briefDescription } = data;

  return (
    <footer
      className="card-footer is-flex-direction-column is-justify-content-space-between"
      style={{ height: '100%' }}
    >
      {!category.showDropdown && (
        <p className="title is-6 p-3 mb-0 has-text-weight-semibold">
          <Highlighted text={name} highlight={searchValue} />
        </p>
      )}

      <p className="card-footer-item is-justify-content-flex-start is-size-7 mb-0">
        <Highlighted text={briefDescription} highlight={searchValue} />
      </p>

      {editMode && !!removeCard && (
        <p className="card-footer-item">
          <button
            type="button"
            className="button is-small is-danger is-outlined"
            onClick={() => removeCard(groupdId, data.category.id)}
          >
            <BoxIcon name="bx-trash" />
          </button>
        </p>
      )}
    </footer>
  );
}

GuideCodeCardFooter.propTypes = {
  groupdId: PropTypes.string,
  editMode: PropTypes.bool,
  removeCard: PropTypes.func,
};

export default GuideCodeCardFooter;
