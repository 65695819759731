/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import BoxIcon from '../../Icons/BoxIcon';
import FieldComponent from './FieldComponent';
import { doStep, getDefaultComponents, mapFieldsToSections } from './functions';

function FormFieldArrayInner({
  formTitle,
  dataSources = null,
  currentIndex,
  setCurrentIndex,
  showAllErrors,
  fieldsConfig,
  currentStepIndex,
  setCurrentStepIndex,
  showNextOnComplete,
  setShowAllErrors,
  components,
  stepperExceptions,
  actions,
  showModalOnSubmit,
  setShowModal,
  sections,
  linkedNames,
}) {
  const {
    errors,
    handleChange,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setValues,
    values,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    validateForm,
    validateField,
    touched,
    initialValues,
  } = useFormikContext();

  const mappedFields = useMemo(
    () => mapFieldsToSections(sections, fieldsConfig),
    [fieldsConfig, sections],
  );

  const jumpToSteps = useMemo(() => {
    const flatFields = Object.values(mappedFields).flatMap(
      (section) => section.fields,
    );
    const fieldIndexes = new Set(
      flatFields
        .filter(({ name }) => !stepperExceptions.includes(name))
        .map(({ _fieldIndex }) => _fieldIndex),
    );
    return [...fieldIndexes].sort((a, b) => a - b);
  }, [fieldsConfig, stepperExceptions]);

  const registeredComponents = useMemo(
    () => ({ ...getDefaultComponents(), ...components }),
    [components],
  );

  function stepChangeHandler(_sectionIndex, _fieldIndex) {
    setCurrentStepIndex((state) => ({ ...state, _sectionIndex, _fieldIndex }));
  }

  function getComponentProps(fieldConfig) {
    return {
      ...getFieldHelpers(fieldConfig.name),
      field: {
        ...getFieldProps(fieldConfig.name),
      },
      ...getFieldMeta(fieldConfig.name),
      ...fieldConfig.componentProps,
      dataSources,
      index: currentIndex,
      form: {
        initialValues,
        linkedNames,
        showAllErrors,
        setFieldValue,
        setFieldTouched,
        setValues,
        resetForm,
        validateField,
        doStep: () => {
          let canJump = true;
          for (
            let fieldIndex = 0;
            fieldIndex < fieldConfig._fieldIndex + 1;
            fieldIndex++
          ) {
            if (get(errors, fieldsConfig[fieldIndex].name) !== undefined) {
              canJump = false;
            }
          }
          if (
            canJump &&
            get(touched, fieldConfig.name) !== undefined &&
            currentStepIndex._fieldIndex <= fieldConfig._fieldIndex
          ) {
            setCurrentStepIndex((state) => ({
              ...state,
              ...doStep(mappedFields, currentStepIndex, jumpToSteps),
            }));
          }
        },
        setCurrentStepIndex,
        setCurrentIndex,
        currentStepIndex,
        touched,
      },
    };
  }

  return (
    <>
      <div
        className="control has-background-primary-light is-flex is-justify-content-space-between is-align-items-center "
        style={{
          position: 'sticky',
          top: '88px',
          zIndex: 5,
          margin: '-1em -1em 0px',
          padding: '1em',
        }}
      >
        <h1 className="title is-4 m-0 has-text-primary">{formTitle} </h1>
      </div>
      <div className="columns mt-0">
        <div className="column is-8">
          {mappedFields
            .filter(
              ({ _sectionIndex }) =>
                (_sectionIndex <= currentStepIndex._sectionIndex &&
                  showNextOnComplete) ||
                !showNextOnComplete,
            )
            .map(({ headerText, fields }) => (
              <div className="box" key={headerText}>
                <h5 className="title is-5 has-text-info mb-1">{headerText}</h5>
                {fields
                  .filter(
                    ({ _fieldIndex }) =>
                      (_fieldIndex <= currentStepIndex._fieldIndex &&
                        showNextOnComplete) ||
                      !showNextOnComplete,
                  )
                  .map((fieldConfig) => {
                    if (fieldConfig.hasOwnProperty('children')) {
                      return fieldConfig.children.map((childFieldConfig) => (
                        <FieldComponent
                          key={fieldConfig.id}
                          childFieldConfig={childFieldConfig}
                          registeredComponents={registeredComponents}
                          errors={errors}
                          {...getComponentProps(childFieldConfig)}
                          handleChange={handleChange}
                          values={values}
                          stepChangeHandler={stepChangeHandler}
                        />
                      ));
                    }
                    return (
                      <FieldComponent
                        key={fieldConfig.id}
                        childFieldConfig={fieldConfig}
                        registeredComponents={registeredComponents}
                        errors={errors}
                        {...getComponentProps(fieldConfig)}
                        handleChange={handleChange}
                        values={values}
                        stepChangeHandler={stepChangeHandler}
                      />
                    );
                  })}
              </div>
            ))}
        </div>
        <div className="column is-4" />
      </div>
      {values?.id > 0 ? (
        <button type="submit" className="button is-primary">
          <BoxIcon name="bx-save" className="FormSubmitButtonIcon mr-2" />
          {actions.update.submitButtonText}
        </button>
      ) : (
        currentStepIndex._sectionIndex === mappedFields.length - 1 && (
          <div className="is-flex is-justify-content-right column is-8">
            <button
              type="button"
              className="button is-primary"
              onClick={
                showModalOnSubmit
                  ? () => {
                      setShowAllErrors(true);
                      validateForm().then((er) => {
                        if (!Object.keys(er).length) {
                          setShowModal(true);
                        }
                      });
                    }
                  : actions.create.event
              }
            >
              {actions.create.submitButtonText}
            </button>
          </div>
        )
      )}
    </>
  );
}

FormFieldArrayInner.propTypes = {
  formTitle: PropTypes.string.isRequired,
  dataSources: PropTypes.object,
  currentIndex: PropTypes.number.isRequired,
  showAllErrors: PropTypes.bool.isRequired,
  currentStepIndex: PropTypes.object.isRequired,
  setCurrentStepIndex: PropTypes.func.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  showNextOnComplete: PropTypes.bool.isRequired,
  showModalOnSubmit: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowAllErrors: PropTypes.func.isRequired,
  sections: PropTypes.object.isRequired,
  linkedNames: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  stepperExceptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  actions: PropTypes.shape({
    create: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
      event: PropTypes.func,
    }),
    update: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
    }),
    destroy: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
    }),
  }).isRequired,
  fieldsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectionName: PropTypes.string.isRequired,
      labelText: PropTypes.string,
      placeholder: PropTypes.string,
      componentName: PropTypes.string.isRequired,
      initialValue: PropTypes.any.isRequired,
      componentProps: PropTypes.object,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          sectionName: PropTypes.string.isRequired,
          labelText: PropTypes.string.isRequired,
          placeholder: PropTypes.string,
          componentName: PropTypes.string.isRequired,
          fieldValidation: PropTypes.object.isRequired,
          initialValue: PropTypes.any.isRequired,
          fieldIndex: PropTypes.number.isRequired,
          componentProps: PropTypes.object,
        }).isRequired,
      ),
    }),
  ).isRequired,
};

export default memo(FormFieldArrayInner);
