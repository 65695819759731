import cadFileRoutes from './cadFileRoutes';
import helpCenterRoutes from './helpCenterRoutes';
import integrationRoutes from './integrationRoutes';
import invoiceRoutes from './invoiceRoutes';
import locationRoutes from './locationRoutes';
import orderRoutes from './orderRoutes';
import organizationRoutes from './organizationRoutes';
import reportRoutes from './reportRoutes';
import userRoutes from './userRoutes';

const combinedRoutes = [
  ...organizationRoutes,
  ...userRoutes,
  ...locationRoutes,
  ...cadFileRoutes,
  ...orderRoutes,
  ...integrationRoutes,
  ...invoiceRoutes,
  ...reportRoutes,
  ...helpCenterRoutes,
];

export default combinedRoutes;
