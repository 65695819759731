import { useMutation } from '@apollo/client';
import { BoxIcon, CurrentUserContext } from '@fullcontour/common';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { memo, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  activeStyle,
  baseStyle,
  fileErrorStyle,
  rejectedStyle,
  successStyle,
} from './CaseFolderStyle';

function FolderField({
  push = () => {},
  replace = () => {},
  values = {},
  name = null,
  accept = {},
  allowMultiple = false,
  description = '',
  folderName = null,
  caseFolderId = null,
  error = null,
  uniqueCaseId = null,
  touched = false,
}) {
  const user = useContext(CurrentUserContext);
  const { t } = useTranslation('formfields');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [componentFiles, setComponentFiles] = useState([]);

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept,
    multiple: allowMultiple,
    onDrop: (accepted) => onDrop(accepted),
  });

  const [signS3] = useMutation(REQUEST_S3_SIGN);

  const value = useMemo(
    () => values.files.some((v) => componentFiles.find((c) => v.s3Key === c)),
    [values, componentFiles],
  );
  const formatFilename = (filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  };

  const onDrop = async (accepted) => {
    await Promise.all(accepted.map(async (file) => signAndSubmit(file)));
  };

  const uploadToS3 = async (signedRequest, droppedFile) => {
    setUploading(true);
    const options = {
      headers: {
        'Content-Type': droppedFile.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          setUploadProgress(100);
          setUploading(false);
        } else {
          setUploadProgress(percent);
        }
      },
    };

    await axios.put(signedRequest, droppedFile, options);
  };

  const signAndSubmit = async (droppedFile) => {
    const s3Key = `orders/${
      user.currentUser.locationId
    }/${uniqueCaseId}/initial_scans/${formatFilename(droppedFile.name)}`;
    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: droppedFile.name,
            filename: s3Key,
            acl: 'private',
            filetype: droppedFile.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = response.data.signS3.s3Response;
    await uploadToS3(signedRequest, droppedFile);

    if (allowMultiple || componentFiles.length === 0) {
      push({
        fileUrl: url,
        s3Key,
        originalFileName: droppedFile.name,
        fileType: 'initial_scans',
        caseFolderId,
      });
      setComponentFiles((state) => [...state, s3Key]);
    } else {
      const index = values['files'].findIndex(
        (file) => file.s3Key === componentFiles[0],
      );
      replace(index, {
        fileUrl: url,
        s3Key,
        originalFileName: droppedFile.name,
        fileType: 'initial_scans',
        caseFolderId,
      });
      setComponentFiles([s3Key]);
    }
  };

  const acceptedFileItems = [];

  acceptedFiles.forEach((file) => {
    const acceptedFilesEndIndex = file.path.length;
    const acceptedCountFromEnd = 20;
    const acceptedStartIndex = acceptedFilesEndIndex - acceptedCountFromEnd;

    acceptedFileItems.push(
      <p key={file.path} style={{ color: 'rgba(83, 201, 87)' }}>
        <BoxIcon
          name="bx-check"
          className="is-size-small mt-2 has-text-centered"
        />{' '}
        &quot;...
        {file.path.substring(acceptedStartIndex, acceptedFilesEndIndex)}&quot;
      </p>,
    );
  });

  const acceptedFileExtension = Object.entries(accept)[0][1].join(', ');
  const fileError = fileRejections.length > 0;
  const fileErrorMessage = fileRejections[0]?.errors[0]?.message || '';

  let styles = { ...baseStyle };
  styles = isDragActive || uploading ? { ...styles, ...activeStyle } : styles;
  styles = error && touched ? { ...styles, ...rejectedStyle } : styles;
  styles = value && value !== '' ? { ...styles, ...successStyle } : styles;
  styles = fileError ? { ...styles, ...fileErrorStyle } : styles;
  return (
    <div className="is-flex is-flex-direction-column column">
      <div
        className="m-1 column is-flex is-3 is-justify-content-center has-text-centered align-items-center"
        style={styles}
        {...getRootProps()}
      >
        <figure className="is-justify-content-center has-text-centered align-items-center">
          {value && value !== '' ? (
            <BoxIcon
              name="bx-check"
              className="is-size-1 mt-2 has-text-centered"
            />
          ) : (
            <BoxIcon
              name={`${uploading ? 'bx-loader bx-spin' : 'bx-cloud-upload'}`}
              className="is-size-1 mt-2"
            />
          )}
          <input {...getInputProps()} name={name} />
          <figcaption>
            <p style={{ fontSize: '0.9rem' }}>
              {!error && <span>{t(folderName)}</span>}
              {error && <span>{t(`${folderName} is required*`)}</span>}
            </p>
            {fileErrorMessage?.includes('File type must be') && (
              <small
                style={{
                  fontSize: '0.7rem',
                  color: fileError ? 'rgba(228, 0, 0, 0.9)' : '',
                }}
              >
                <p>Only the following file types can be uploaded: </p>
              </small>
            )}
            <p
              style={{
                fontSize: '0.7rem',
                color: fileErrorMessage?.includes('File type must be')
                  ? 'rgba(228, 0, 0, 0.9)'
                  : '',
              }}
            >
              ({acceptedFileExtension})<br />
              {description}
            </p>
            {fileErrorMessage?.includes('Too many files') && (
              <small
                style={{
                  fontSize: '0.7rem',
                  color: 'rgba(228, 0, 0, 0.9)',
                }}
              >
                <p>Only one file can be uploaded here</p>
              </small>
            )}
            {uploading && (
              <progress
                value={uploadProgress}
                max="100"
                className="m2 progress is-small is-primary is-justify-content-center"
              />
            )}
          </figcaption>
        </figure>
      </div>
      <div className="is-flex is-flex-direction-column">
        {value && value !== '' ? <small>{acceptedFileItems}</small> : ''}
      </div>
    </div>
  );
}

FolderField.propTypes = {
  touched: PropTypes.bool,
  values: PropTypes.object,
  push: PropTypes.func,
  replace: PropTypes.func,
  name: PropTypes.string,
  accept: PropTypes.object,
  allowMultiple: PropTypes.bool,
  description: PropTypes.string,
  folderName: PropTypes.string,
  caseFolderId: PropTypes.string,
  error: PropTypes.string,
  uniqueCaseId: PropTypes.string,
};

export default memo(FolderField);
