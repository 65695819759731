import { useQuery } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import { memo, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchOnStatus, LoadingOrError } from '../../../shared';

import OrdersListGridContainer from './OrdersListGridContainer';

function OrdersListQueryWrapper({ query, gridOptions }) {
  const { status } = useParams();

  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedState, setSelectedState] = useState(-1);

  const [variables, setVariables] = useState(() => {
    let vari = {};

    if (status) {
      vari = { status };
    }

    if (!status) {
      vari = {
        startRange: moment().format(),
        endRange: moment().format(),
      };
    }

    if (!status && selectedState !== -1) {
      vari = { ...vari, states: selectedState };
    }

    return vari;
  });

  const [calState, setCalState] = useState({
    startDate: moment(),
    endDate: moment(),
    selectedRange: 'today',
  });

  const { startDate, endDate, selectedRange } = calState;

  useEffect(() => {
    function updateVariables() {
      let vari = {};

      if (!status && startDate && endDate) {
        vari = {
          startRange: startDate.format(),
          endRange: endDate.format(),
        };
      }

      if (!status && selectedState !== -1) {
        vari = { ...vari, states: selectedState };
      }

      setVariables(vari);
    }

    updateVariables();
  }, [endDate, status, startDate, selectedState]);

  const { queryToFetch, dataKey } = fetchOnStatus(query, status);

  const [fetchPolicy, setFetchPolicy] = useState('network-only');

  useEffect(() => {
    setFetchPolicy('cache-and-network');
  }, []);

  const { error, loading, data } = useQuery(queryToFetch, {
    variables: { ...variables, status },
    fetchPolicy,
  });

  const onCalendarDatesChange = ({
    startDate: startD,
    endDate: endD,
    selectedRange: selectedR = 'custom',
  }) => {
    let vari;
    let newEndD = endD;

    if (endD && moment.duration(endD.diff(startD)).as('days') > 30) {
      newEndD = startD.clone().add(30, 'days');
    }

    if (startD && endD) {
      vari = {
        startRange: startD.format(),
        endRange: newEndD.format(),
      };

      setVariables(vari);
    }

    setCalState({
      startDate: startD,
      endDate: newEndD,
      selectedRange: selectedR,
    });
  };

  const onCalendarFocusChange = (focusedI) => {
    setFocusedInput(focusedI);
  };

  if (error) {
    return <LoadingOrError error={error} loading={loading} title="Orders" />;
  }

  const orders = (data && data[dataKey]) || null;
  return (
    <Suspense fallback={<LoadingOrError loading />}>
      <OrdersListGridContainer
        orders={orders}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        focusedInput={focusedInput}
        selectedRange={selectedRange}
        onCalendarFocusChange={onCalendarFocusChange}
        onCalendarDatesChange={onCalendarDatesChange}
        gridOptions={gridOptions}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
    </Suspense>
  );
}

OrdersListQueryWrapper.propTypes = {
  query: PropTypes.object.isRequired,
  gridOptions: PropTypes.object.isRequired,
};

export default memo(OrdersListQueryWrapper);
