import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const OrderFormModalContext = createContext();
export default OrderFormModalContext;
export function OrderFormModalContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const values = useMemo(() => ({ isOpen, setIsOpen }), [isOpen]);
  return (
    <OrderFormModalContext.Provider value={values}>
      {children}
    </OrderFormModalContext.Provider>
  );
}
export function useOrderFormModalContext() {
  return useContext(OrderFormModalContext);
}
OrderFormModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
