import { gql } from '@apollo/client';
import { CustomerUserInfo } from '../fragments';

export default gql`
  query customerUser($id: ID!) {
    customerUser(id: $id) {
      ...CustomerUserInfo
    }
  }
  ${CustomerUserInfo}
`;
