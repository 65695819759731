function toothDisplayRange(teeth) {
  const sortedTeeth = teeth.map((tooth) => Number(tooth)).sort((a, b) => a - b);
  const ranges = [[sortedTeeth[0], sortedTeeth[0]]];
  let lastIndex = 0;

  for (let i = 1; i < sortedTeeth.length; i += 1) {
    if (sortedTeeth[i] - ranges[lastIndex][1] === 1) {
      ranges[lastIndex][1] = sortedTeeth[i];
    } else {
      lastIndex = 1 + lastIndex;
      ranges[lastIndex] = [sortedTeeth[i], sortedTeeth[i]];
    }
  }

  return ranges
    .map((range) => {
      if (range[0] === range[1]) {
        return `${range[0]}`;
      }

      return `${range[0]}-${range[1]}`;
    })
    .join(', ');
}

export default toothDisplayRange;
