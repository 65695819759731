import PropTypes from 'prop-types';
import { AgGridContainer, ratingRenderer } from '../../../../../shared';

function ResultsFeedbackAnswer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'order.hexOrderCode',
        headerName: 'Case ID',
        minWidth: 100,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: {
          color: '#1B77C8',
          cursor: 'pointer',
          textTransform: 'uppercase',
        },
        onCellClicked: (params) => {
          params.context.navigate(
            `/orders/details/${params.data.order.hexOrderCode}`,
          );
        },
      },
      {
        field: 'labLocation.name',
        headerName: 'Location',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designUser.name',
        headerName: 'Designer',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designUser.org',
        headerName: 'Design Org',
        minWidth: 120,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'designUser.loc',
        headerName: 'Silo',
        minWidth: 120,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'redesignCount',
        headerName: 'Redesign #',
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'redesignReason',
        headerName: 'Reason',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'rating',
        headerName: 'Overall Rating',
        minWidth: 60,
        filter: 'agTextColumnFilter',
        cellRenderer: ratingRenderer,
      },
      {
        field: 'designType',
        headerName: 'Design Type',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'option',
        headerName: 'Option',
        minWidth: 600,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'category',
        headerName: 'Category',
        minWidth: 600,
        filter: 'agTextColumnFilter',
      },
    ],
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Show / Hide Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: false,
            suppressPivots: false,
            suppressRowGroups: false,
            suppressValues: false,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsFeedbackAnswer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsFeedbackAnswer;
