import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCustomerLocationModifier(
    $input: CustomerLocationModifierUpdateInput!
  ) {
    updateCustomerLocationModifier(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerLocation {
        id
        name
        slug
        labLocationModifiers {
          id
          price
          modifierId
          modifier {
            id
            name
            price
            modifierGroup {
              id
              name
            }
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
