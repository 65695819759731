import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createLiveSignup($input: LiveSignupCreateInput!) {
    createLiveSignup(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }

  ${ErrorMessageInfo}
`;
