import { gql } from '@apollo/client';

export default gql`
  query cadFilesDesigner {
    cadFiles {
      id
      fileUrl
      s3Key
      createdAt
      filename
      location {
        code
      }
    }
  }
`;
