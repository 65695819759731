const languageOptions = {
  en: [
    {
      text: 'English',
      flag: 'us',
      value: 'en',
      label: 'English',
    },
    {
      text: 'Chinese',
      flag: 'cn',
      value: 'zh',
      label: 'Chinese',
    },
    {
      text: 'French',
      flag: 'fr',
      value: 'fr',
      label: 'French',
    },
    {
      text: 'German',
      flag: 'de',
      value: 'de',
      label: 'German',
    },
    {
      text: 'Italian',
      flag: 'it',
      value: 'it',
      label: 'Italian',
    },
    {
      text: 'Korean',
      flag: 'kr',
      value: 'ko',
      label: 'Korean',
    },
    {
      text: 'Portuguese(Brazilian)',
      flag: 'br',
      value: 'pt',
      label: 'Portuguese(Brazilian)',
    },
    {
      text: 'Spanish',
      flag: 'es',
      value: 'es',
      label: 'Spanish',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  zh: [
    {
      text: '英语',
      flag: 'us',
      value: 'en',
      label: '英语',
    },
    {
      text: '中文',
      flag: 'cn',
      value: 'zh',
      label: '中文',
    },
    {
      text: '法语',
      flag: 'fr',
      value: 'fr',
      label: '法语',
    },
    {
      text: '德国的语言',
      flag: 'de',
      value: 'de',
      label: '德国的语言',
    },
    {
      text: '意大利语',
      flag: 'it',
      value: 'it',
      label: '意大利语',
    },
    {
      text: '朝鲜语',
      flag: 'kr',
      value: 'ko',
      label: '朝鲜语',
    },
    {
      text: '葡萄牙语',
      flag: 'br',
      value: 'pt',
      label: '葡萄牙语',
    },
    {
      text: '西班牙语',
      flag: 'es',
      value: 'es',
      label: '西班牙语',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  fr: [
    {
      text: 'Anglais',
      flag: 'us',
      value: 'en',
      label: 'Anglais',
    },
    {
      text: 'chinois',
      flag: 'cn',
      value: 'zh',
      label: 'chinois',
    },
    {
      text: 'française',
      flag: 'fr',
      value: 'fr',
      label: 'française',
    },
    {
      text: 'allemande',
      flag: 'de',
      value: 'de',
      label: 'allemande',
    },
    {
      text: 'italienne',
      flag: 'it',
      value: 'it',
      label: 'italienne',
    },
    {
      text: 'coréenne',
      flag: 'kr',
      value: 'ko',
      label: 'coréenne',
    },
    {
      text: 'portugaise',
      flag: 'br',
      value: 'pt',
      label: 'portugaise',
    },
    {
      text: 'Espanol',
      flag: 'es',
      value: 'es',
      label: 'Espanol',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  de: [
    {
      text: 'Englisch',
      flag: 'us',
      value: 'en',
      label: 'Englisch',
    },
    {
      text: 'Chinesisch',
      flag: 'cn',
      value: 'zh',
      label: 'Chinesisch',
    },
    {
      text: 'Französisch',
      flag: 'fr',
      value: 'fr',
      label: 'Französisch',
    },
    {
      text: 'Deutsche',
      flag: 'de',
      value: 'de',
      label: 'Deutsche',
    },
    {
      text: 'Italienisch',
      flag: 'it',
      value: 'it',
      label: 'Italienisch',
    },
    {
      text: 'Koreanisch',
      flag: 'kr',
      value: 'ko',
      label: 'Koreanisch',
    },
    {
      text: 'Portugiesisch',
      flag: 'br',
      value: 'pt',
      label: 'Portugiesisch',
    },
    {
      text: 'Spanisch',
      flag: 'es',
      value: 'es',
      label: 'Spanisch',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  it: [
    {
      text: 'Inglese',
      flag: 'us',
      value: 'en',
      label: 'Inglese',
    },
    {
      text: 'Cinese',
      flag: 'cn',
      value: 'zh',
      label: 'Cinese',
    },
    {
      text: 'francese',
      flag: 'fr',
      value: 'fr',
      label: 'francese',
    },
    {
      text: 'Tedesco',
      flag: 'de',
      value: 'de',
      label: 'Tedesco',
    },
    {
      text: 'italiano',
      flag: 'it',
      value: 'it',
      label: 'italiano',
    },
    {
      text: 'coreano',
      flag: 'kr',
      value: 'ko',
      label: 'coreano',
    },
    {
      text: 'portoghese',
      flag: 'br',
      value: 'pt',
      label: 'portoghese',
    },
    {
      text: 'spagnolo',
      flag: 'es',
      value: 'es',
      label: 'spagnolo',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  ko: [
    {
      text: '영어',
      flag: 'us',
      value: 'en',
      label: '영어',
    },
    {
      text: '중국어',
      flag: 'cn',
      value: 'zh',
      label: '중국어',
    },
    {
      text: '프랑스어',
      flag: 'fr',
      value: 'fr',
      label: '프랑스어',
    },
    {
      text: '독일어',
      flag: 'de',
      value: 'de',
      label: '독일어',
    },
    {
      text: '이탈리아어',
      flag: 'it',
      value: 'it',
      label: '이탈리아어',
    },
    {
      text: '한국어',
      flag: 'kr',
      value: 'ko',
      label: '한국어',
    },
    {
      text: '포르투갈어',
      flag: 'br',
      value: 'pt',
      label: '포르투갈어',
    },
    {
      text: '스페인어',
      flag: 'es',
      value: 'es',
      label: '스페인어',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  pt: [
    {
      text: 'Inglês',
      flag: 'us',
      value: 'en',
      label: 'Inglês',
    },
    {
      text: 'chinês',
      flag: 'cn',
      value: 'zh',
      label: 'chinês',
    },
    {
      text: 'francês',
      flag: 'fr',
      value: 'fr',
      label: 'francês',
    },
    {
      text: 'alemão',
      flag: 'de',
      value: 'de',
      label: 'alemão',
    },
    {
      text: 'italiano',
      flag: 'it',
      value: 'it',
      label: 'italiano',
    },
    {
      text: 'coreano',
      flag: 'kr',
      value: 'ko',
      label: 'coreano',
    },
    {
      text: 'Português',
      flag: 'br',
      value: 'pt',
      label: 'Português(Brasileira)',
    },
    {
      text: 'espanhol',
      flag: 'es',
      value: 'es',
      label: 'espanhol',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  es: [
    {
      text: 'Inglés',
      flag: 'us',
      value: 'en',
      label: 'Inglés',
    },
    {
      text: 'chino',
      flag: 'cn',
      value: 'zh',
      label: 'chino',
    },
    {
      text: 'francés',
      flag: 'fr',
      value: 'fr',
      label: 'francés',
    },
    {
      text: 'alemán',
      flag: 'de',
      value: 'de',
      label: 'alemán',
    },
    {
      text: 'italiano',
      flag: 'it',
      value: 'it',
      label: 'italiano',
    },
    {
      text: 'coreano',
      flag: 'kr',
      value: 'ko',
      label: 'coreano',
    },
    {
      text: 'portugués',
      flag: 'br',
      value: 'pt',
      label: 'portugués',
    },
    {
      text: 'Español',
      flag: 'es',
      value: 'es',
      label: 'Español',
    },
    {
      text: 'Turkish',
      flag: 'tr',
      value: 'tr',
      label: 'Turkish',
    },
  ],
  tr: [
    {
      text: 'Ingilizce',
      flag: 'us',
      value: 'en',
      label: 'Ingilizce',
    },
    {
      text: 'Çince',
      flag: 'cn',
      value: 'zh',
      label: 'Çince',
    },
    {
      text: 'Fransızca',
      flag: 'fr',
      value: 'fr',
      label: 'Fransızca',
    },
    {
      text: 'Almanca',
      flag: 'de',
      value: 'de',
      label: 'Almanca',
    },
    {
      text: 'İtalyan',
      flag: 'it',
      value: 'it',
      label: 'İtalyan',
    },
    {
      text: 'Koreli',
      flag: 'kr',
      value: 'ko',
      label: 'Koreli',
    },
    {
      text: 'Portekizce',
      flag: 'br',
      value: 'pt',
      label: 'Portekizce',
    },
    {
      text: 'İspanyol',
      flag: 'es',
      value: 'es',
      label: 'İspanyol',
    },
    {
      text: 'Türkçe',
      flag: 'tr',
      value: 'tr',
      label: 'Türkçe',
    },
  ],
};

export default languageOptions;
