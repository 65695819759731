import { gql } from '@apollo/client';
import { ErrorMessageInfo, ManufacturerUserInfo } from '../fragments';

export default gql`
  mutation updateManufacturerUser($input: ManufacturerUserUpdateInput!) {
    updateManufacturerUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      manufacturerUser {
        ...ManufacturerUserInfo
      }
    }
  }
  ${ManufacturerUserInfo}
  ${ErrorMessageInfo}
`;
