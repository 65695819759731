function typeName(root, type) {
  const typeCheck = new Set([
    'categories',
    'business-units',
    'software-nodes',
    'turnaround-times',
    'design-preferences',
  ]);

  if (root === 'organizations' && type === 'design') return 'center';
  if (root === 'locations' && type === 'design') return 'silo';
  if (typeCheck.has(type)) return '';

  return root.replace(/-/g, ' ').slice(0, -1);
}

export default typeName;
