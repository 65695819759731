import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';

function HeaderInfoElapsedTime(props) {
  const { createdAt } = props;

  return (
    <>
      <span className="has-text-weight-bold">Elapsed Time:</span>{' '}
      {formatDistance(parseISO(createdAt), new Date())}
    </>
  );
}

HeaderInfoElapsedTime.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default HeaderInfoElapsedTime;
