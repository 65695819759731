import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().required('An Invoice is required'),
    memo: Yup.string().required('A Memo is Required'),
  });
}

export default schema;
