const updateNestedObject = (nestedObj, updatedGuideCodes) => {
  const updatedNestedObj = {};
  if (Object.keys(nestedObj).length) {
    Object.keys(nestedObj).forEach((tooth) => {
      updatedNestedObj[tooth] = nestedObj[tooth].map((id) => {
        if (id.startsWith('other-')) {
          const categoryId = id.substring(6);
          const category = updatedGuideCodes.find(
            (c) => c.categoryId === categoryId,
          );
          if (category) {
            return category.codeId;
          }
        }
        return id;
      });
    });
  }
  return updatedNestedObj;
};

const updateCodeIdsSelectedTeethArch = ({
  updatedGuideCodes,
  setFieldValue,
  orderItemIndex,
  values,
}) => {
  const { nestedTeeth, nestedArches } = values.orderItems[orderItemIndex];

  if (nestedTeeth) {
    setFieldValue(
      `orderItems[${orderItemIndex}].nestedTeeth`,
      updateNestedObject(nestedTeeth, updatedGuideCodes),
    );
  }

  if (nestedArches) {
    setFieldValue(
      `orderItems[${orderItemIndex}].nestedArches`,
      updateNestedObject(nestedArches, updatedGuideCodes),
    );
  }
};

export default updateCodeIdsSelectedTeethArch;
