import { useContext } from 'react';
import { CurrentUserContext, Tabs } from '../../../../shared';
import LocationCustomerDesignPreferencesForm from '../../LocationCustomerDesignPreferences/LocationCustomerDesignPreferencesForm';

function LocationDesignProfilePreferences() {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <Tabs.TabPanel tabId="preferences">
      <h5 className="title is-5 mb-2">Design preferences</h5>
      <LocationCustomerDesignPreferencesForm currentUser={currentUser} />
    </Tabs.TabPanel>
  );
}

LocationDesignProfilePreferences.propTypes = {};

export default LocationDesignProfilePreferences;
