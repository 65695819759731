import PropTypes from 'prop-types';
import { useState } from 'react';
import GuideApplyBtn from './GuideApplyBtn';
import GuideCloseBtn from './GuideCloseBtn';
import GuideCodesSelected from './GuideCodesSelected';
import GuideSearch from './GuideSearch';

function GuideHeader({
  designType,
  guideModalClose,
  hideApplyAll = false,
  helpFuncsData = {},
  ...rest
}) {
  const props = {
    designType,
    guideModalClose,
    hideApplyAll,
    helpFuncsData,
    ...rest,
  };
  const [applyToAll] = useState(false);

  return (
    <>
      <div className="is-flex is-align-items-center">
        <p className="modal-card-title mr-4" style={{ flexGrow: 'unset' }}>
          Design Guide Selection
        </p>
        <GuideSearch {...props} />
      </div>

      <GuideCodesSelected removeAllButton {...props} />

      <div className="is-flex is-align-items-center">
        <GuideApplyBtn
          designType={designType}
          applyToAll={applyToAll}
          {...props}
        />
        <GuideCloseBtn guideModalClose={guideModalClose} {...props} />
      </div>
    </>
  );
}

GuideHeader.propTypes = {
  designType: PropTypes.object.isRequired,
  hideApplyAll: PropTypes.bool,
  guideModalClose: PropTypes.func.isRequired,
  helpFuncsData: PropTypes.object,
};

export default GuideHeader;
