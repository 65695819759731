import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { LoadingOrError } from '../../Loader';
import Styles from './DynamicMultiSelect.module.scss';

function NoQueryDynamicMultiSelect({
  name,
  height = 150,
  required = true,
  label = '',
  optionsUsed = null,
  value = [],
  externalLoading = false,
  externalError = null,
  onChange = null,
  options = [],
}) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (options) {
      let newData = options;
      if (optionsUsed) {
        newData = newData.filter((d) => !new Set(optionsUsed).has(d.id));
      }
      setFilteredData(newData);
    }
  }, [options, optionsUsed]);

  const selectAll = () => {
    const ids = filteredData.map((d) => d.id);
    onChange(ids);
  };

  const removeAll = () => {
    onChange([]);
  };

  const filterData = (e) => {
    const val = e.target.value;
    const filtered = options.filter(
      (d) => d.name.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
    setFilteredData(filtered);
  };

  function findLabel(id) {
    const foundItem = options.find((d) => d.id === id);
    if (foundItem) {
      return foundItem.name;
    }
    return null;
  }

  const handleSelectClick = (event) => {
    event.preventDefault();

    const optionValue = event.target.value;
    const optionIndex = value.indexOf(optionValue);

    let updatedValues = [...value];

    if (optionIndex === -1) {
      updatedValues.push(optionValue);
    } else {
      updatedValues = updatedValues.filter((val) => val !== optionValue);
    }

    onChange(updatedValues);
  };

  return (
    <>
      <div className={`field ${required ? 'required' : ''}`}>
        <label htmlFor={name} className="label">
          {`${label}` !== 'undefined' ? `${label}` : ''}
        </label>
        <div className="control has-icons-right">
          <input
            className={`input is-small ${Styles.MultiSelectSearch}`}
            placeholder="Filter..."
            onChange={filterData}
            type="text"
          />
          <span className="icon is-small is-right">
            <i className="bx bx-search" />
          </span>
        </div>
        <div className="control">
          <div className="select is-multiple" style={{ width: '100%' }}>
            {!externalError && !externalLoading && (
              <select
                name={name}
                multiple
                className={Styles.MultiSelect}
                value={value}
                onMouseDown={handleSelectClick}
                style={{ height, minHeight: height }}
              >
                {[...filteredData]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        {externalLoading || externalError ? (
          <div style={{ height }}>
            <LoadingOrError
              error={externalError}
              loading={externalLoading}
              hideText
            />
          </div>
        ) : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexBasis: '50%' }}>
          {value &&
            value.length > 0 &&
            value.map((id) => (
              <span
                key={id}
                className="tag is-dark"
                style={{ marginBottom: 5, marginRight: 5 }}
              >
                {findLabel(id)}
              </span>
            ))}
        </div>
        <div
          style={{
            textAlign: 'right',
            flexBasis: '50%',
            justifyContent: 'end',
          }}
        >
          <button
            className="button is-small is-outlined is-danger"
            type="button"
            onClick={removeAll}
          >
            clear
          </button>
          <button
            className="button is-small is-primary"
            type="button"
            onClick={selectAll}
            style={{ marginLeft: 8 }}
          >
            select all
          </button>
        </div>
      </div>
    </>
  );
}

NoQueryDynamicMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  height: PropTypes.number,
  required: PropTypes.bool,
  optionsUsed: PropTypes.array,
  value: PropTypes.array,
  externalLoading: PropTypes.bool,
  externalError: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default NoQueryDynamicMultiSelect;
