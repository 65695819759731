import { gql } from '@apollo/client';
import { AdminLocationInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createAdminLocation($input: AdminLocationCreateInput!) {
    createAdminLocation(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminLocation {
        ...AdminLocationInfo
      }
    }
  }
  ${AdminLocationInfo}
  ${ErrorMessageInfo}
`;
