import { gql } from '@apollo/client';
import { CreditCardInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCreditCard($input: CreditCardCreateInput!) {
    createCreditCard(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      creditCard {
        ...CreditCardInfo
      }
    }
  }
  ${CreditCardInfo}
  ${ErrorMessageInfo}
`;
