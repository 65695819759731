import { gql } from '@apollo/client';

export default gql`
  query customerLocationDesignPreferences($slug: String) {
    designPreferenceProfiles {
      id
      name
      designPreferences {
        id
        name
        values
      }
    }
    customerLocation(slug: $slug) {
      id
      name
      slug
      designProfiles
      keepExistingCustomInstructions
      customInstructions
      designTypeInstructions {
        createdAt
        instruction
        id
        designType {
          id
          name
        }
      }
      priceList {
        id
        designTypes {
          id
          name
          designPreferenceProfileId
        }
      }
    }
  }
`;
