import { gql } from '@apollo/client';

const ModifierGroupInfo = gql`
  fragment ModifierGroupInfo on ModifierGroup {
    id
    name
    label
    description
    isActive
    slug
    designTypeModifierGroups {
      id
      designTypeId
    }
    designTypes {
      id
      name
      slug
    }
    modifiers {
      id
      name
      slug
      price
      description
      isActive
      default
      modifierGroup {
        id
        name
        slug
      }
    }
  }
`;

export default ModifierGroupInfo;
