import { useQuery } from '@apollo/client';
import { GET_ORDER_STATES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function OrdersListGridStateDropdown({ selectedState, setSelectedState }) {
  const { t } = useTranslation('formfields');

  const { error, loading, data } = useQuery(GET_ORDER_STATES, {
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = (e) => {
    setSelectedState(parseInt(e.target.value, 10));
  };

  return !error ? (
    <div className={`select mr-5 ${loading ? 'is-loading' : ''}`}>
      <select
        id="stateDropdown"
        name="stateDropdown"
        onChange={handleChange}
        value={selectedState}
        disabled={loading || error}
      >
        <option disabled>{t('Select Statuses')}</option>
        {data?.orderStates?.map((d) => (
          <option key={d.id} value={d.value}>
            {t(d.name)}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <p>{t('Error...')}</p>
  );
}

OrdersListGridStateDropdown.propTypes = {
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
};

export default OrdersListGridStateDropdown;
