import PropTypes from 'prop-types';
import ScanContent from './ScanContent';

function ScanItem(props) {
  const { file } = props;

  return (
    <article className="media">
      <figure className="media-left is-align-self-center">
        <p className="image is-square" style={{ width: '80px' }}>
          <img
            size="small"
            src="https://cdn.fullcontour.com/icons/archive-icon.svg"
            alt="file icon"
          />
        </p>
      </figure>
      <div className="media-content">
        <ScanContent file={file} />
      </div>
    </article>
  );
}

ScanItem.propTypes = {
  file: PropTypes.object.isRequired,
};

export default ScanItem;
