import BaseDefinition from './BaseDefinition';

class FileTypeLab extends BaseDefinition {
  buildCase() {
    const { order } = this.parsedCase;
    const { software, ...restOrder } = order;
    const updatedSoftware = 'Sirona';

    const updatedOrder = { ...restOrder, software: updatedSoftware };

    const metaData = {
      isParsed: false,
      softwareCompany: 'Sirona',
      manufacturer: false,
    };

    return { order: updatedOrder, file: this.file, metaData };
  }
}

export default FileTypeLab;
