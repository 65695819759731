import { gql } from '@apollo/client';

export default gql`
  mutation destroyCustomerTag($input: CustomerTagDestroyInput!) {
    destroyCustomerTag(input: $input) {
      clientMutationId
      customerTag {
        id
      }
    }
  }
`;
