import PropTypes from 'prop-types';
import DataPointsAdminLocations from './DataPointsAdminLocations';
import DataPointsCustomerLocations from './DataPointsCustomerLocations';
import DataPointsCustomerOrganizations from './DataPointsCustomerOrganizations';
import DataPointsDesignOrganizations from './DataPointsDesignOrganizations';
import DataPointsManufacturerOrganizations from './DataPointsManufacturerOrganizations';

function DataPoints(props) {
  const {
    currentReportType: { inputParams },
    values,
  } = props;

  return (
    <>
      <DataPointsAdminLocations inputParams={inputParams} />
      <DataPointsCustomerOrganizations
        inputParams={inputParams}
        adminLocationIds={values.adminLocationIds}
      />
      <DataPointsCustomerLocations
        inputParams={inputParams}
        customerOrganizationIds={values.customerOrganizationIds}
      />
      <DataPointsDesignOrganizations
        inputParams={inputParams}
        adminLocationIds={values.adminLocationIds}
      />
      <DataPointsManufacturerOrganizations
        inputParams={inputParams}
        adminLocationIds={values.adminLocationIds}
      />
    </>
  );
}

DataPoints.propTypes = {
  currentReportType: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default DataPoints;
