import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../../../../../transforms';

function ScanContent(props) {
  const { file } = props;

  const type = file.fileType;

  return (
    <>
      <h6
        className="title is-6 has-text-black mb-2 mt-4"
        style={{ wordBreak: 'break-all' }}
      >
        {file.originalFileName}
      </h6>
      <b>Type:</b>{' '}
      <span style={{ textTransform: 'capitalize' }}>
        {type.replace(/_/g, ' ')}
      </span>
      <br />
      <b>Date Created:</b> {timestampFormatter(file.createdAt)}
      <br />
      <b>Version:</b> {file.ver === 0 ? 1 : file.ver}
    </>
  );
}

ScanContent.propTypes = {
  file: PropTypes.object.isRequired,
};

export default ScanContent;
