import PropTypes from 'prop-types';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingInvoiceMemoFormModal from './BillingInvoiceMemoFormModal';

function BillingInvoiceMemoForm({
  modalOpen,
  openModal,
  closeModal,
  selectedItem = null,
  refetch,
}) {
  return (
    <>
      <BasicDropdownItem itemText="Edit Memo" clickHandler={openModal} />
      {selectedItem && (
        <BillingInvoiceMemoFormModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          openModal={openModal}
          selectedItem={selectedItem}
          refetch={refetch}
        />
      )}
    </>
  );
}

BillingInvoiceMemoForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  refetch: PropTypes.func.isRequired,
};

export default BillingInvoiceMemoForm;
