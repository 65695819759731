import PropTypes from 'prop-types';
import ThreeStlFiber from './ThreeStlFiber';

function StlViewer({ file = null, ptsData = null, containerStyle = {} }) {
  return file ? (
    <ThreeStlFiber
      file={file}
      containerStyle={containerStyle}
      ptsData={ptsData}
    />
  ) : null;
}

StlViewer.propTypes = {
  file: PropTypes.object,
  ptsData: PropTypes.object,
  containerStyle: PropTypes.object,
};

export default StlViewer;
