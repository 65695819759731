import PropTypes from 'prop-types';

function Tooth25({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 25)
    : lowerArch;
  const variable = designType.toothChart ? 25 : 'lower';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[25]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st1 activeTooth ${selected ? 'toothSelected' : ''}` : 'st1'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M1627.6 4732.8c-13 28.5-22.2 60.1-45.9 80.1 -10.6 10.7-19.8 24.3-8 80.2 12.3 23.3 33.3 36.9 66.3 37.2 26.6-5 49.5-12.1 91.4-8.4 20.2-4.9 38.5 1.7 63-30.9 8.1-15.4 20.7-25.1 0.2-75.9l-62.7-83.8c-17-12.7-18.9-32.2-58.5-34.6C1642.4 4697.7 1636.6 4716.3 1627.6 4732.8z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M1586.7 4885.3c17.1 2.9 33.3 8.5 52.6 2.7l31 2.7c21.8 3.2 40.6 0.3 60.3-0.7 29.9-1.6 60.7-2.7 52.6-22.3"
      />
    </>
  );
}

Tooth25.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth25;
