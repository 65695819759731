import { gql } from '@apollo/client';

export default gql`
  query customerLocations(
    $customerOrganizationIds: [ID!]
    $organizationId: ID
  ) {
    customerLocations(
      customerOrganizationIds: $customerOrganizationIds
      organizationId: $organizationId
    ) {
      id
      name
      slug
      code
      createdAt
      address
      priceListIsCustom
      suspended
      inactiveBilling
      customerTags {
        id
        name
      }
      designerTags {
        id
        name
      }
      priceList {
        id
        name
      }
      logoUrl
      organization {
        id
        name
        slug
        administratorLocation {
          id
          name
        }
      }
    }
  }
`;
