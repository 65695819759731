import { gql } from '@apollo/client';

const InternalRejectionReasonInfo = gql`
  fragment InternalRejectionReasonInfo on InternalRejectionReason {
    id
    name
    value
    slug
    subject
    message
    isActive
    requiresCustomMessage
    designTypeIds
    designTypes {
      name
    }
  }
`;

export default InternalRejectionReasonInfo;
