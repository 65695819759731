import { gql } from '@apollo/client';

export default gql`
  query customerTagsMultiSelect {
    customerTags {
      id
      name
      value
    }
  }
`;
