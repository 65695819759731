import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BoxIcon from '../Icons/BoxIcon';

function ShowEditButton({ editLink, editBtnText = null, styles = {} }) {
  const { t } = useTranslation('common');

  return (
    <Link
      to={editLink}
      style={styles}
      type="button"
      className="button is-primary"
    >
      <span className="icon">
        <BoxIcon name="bx bx-edit" />
      </span>
      <span>{t(editBtnText || 'Edit')}</span>
    </Link>
  );
}

ShowEditButton.propTypes = {
  editLink: PropTypes.string.isRequired,
  editBtnText: PropTypes.string,
  styles: PropTypes.object,
};

export default memo(ShowEditButton);
