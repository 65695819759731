import PropTypes from 'prop-types';

import GuideBodyCategoryGroups from './GuideBodyCategoryGroups';
import GuideBodyDesignGroups from './GuideBodyDesignGroups';
import GuideBodyFavourites from './GuideBodyFavourites';

function GuideBodyMainContent(props) {
  const { displayedComponent, filteValue } = props;
  if (displayedComponent === 'designGuideGroups' && !filteValue) {
    return <GuideBodyDesignGroups {...props} />;
  }
  return displayedComponent === 'favorites' && !filteValue ? (
    <GuideBodyFavourites {...props} />
  ) : (
    <GuideBodyCategoryGroups
      {...props}
      displayedComponent={displayedComponent}
    />
  );
}

GuideBodyMainContent.propTypes = {
  displayedComponent: PropTypes.string.isRequired,
  filteValue: PropTypes.string.isRequired,
  designType: PropTypes.object.isRequired,
};

export default GuideBodyMainContent;
