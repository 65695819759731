import { Message } from '@fullcontour/common';
import UpdatePasswordSuccess from '@fullcontour/common/src/components/views/Profile/UpdatePassword/UpdatePasswordSuccess';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

function NewPassword({
  changePassword,
  tempCredentials,
  logOut,
  cleanError,
  error = null,
  login,
}) {
  const navigate = useNavigate();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: { newPassword: '' },
    onSubmit: async (values) => {
      try {
        await changePassword({
          password: values.newPassword.trim(),
        });
        setIsSuccessful(true);
      } catch {
        resetForm();
      }
    },
    validate: (values) => {
      const errors = {};
      if (values.newPassword.trim() === '') {
        errors.newPassword = 'Please enter your new password. It is required';
      }
      if (values.newPassword === '') {
        errors.newPassword = 'Please enter your new password. Required';
      }
      return errors;
    },
  });

  useEffect(
    () => () => {
      cleanError();
    },
    [],
  );

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        (async () => {
          await login({
            username: tempCredentials.username,
            password: values.newPassword,
          });
          navigate('/');
        })();
      }, 3000);
    }
  }, [isSuccessful]);
  return tempCredentials && tempCredentials.username ? (
    <>
      {error &&
        error !== 'User needs to be authenticated to call this API.' && (
          <Translation ns={['signin']}>
            {(t) => (
              <Message
                type="danger"
                title="Password Update"
                dismissable
                error={error}
                onDismiss={cleanError}
              >
                <p>
                  {t(
                    'Your password did not meet the requirements. Please make sure you are entering a valid password',
                  )}
                </p>
              </Message>
            )}
          </Translation>
        )}
      <UpdatePasswordSuccess success={isSuccessful} isNewUser />
      <form onSubmit={handleSubmit}>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type={revealPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.newPassword}
              placeholder="New Password"
              name="newPassword"
            />
            <span className="icon is-small is-left">
              <i className="bx bxs-lock" />
            </span>
            <span className="icon is-small is-right is-clickable">
              {revealPassword ? (
                <i
                  className="bx bxs-show"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              ) : (
                <i
                  className="bx bxs-hide"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              )}
            </span>
          </p>
        </div>
        <p className="help is-danger mb-3">{errors.newPassword}</p>
        <span className="is-size-7">
          * Minimum 8 characters. Must contain an uppercase, lowercase, number
          and special character.{' '}
        </span>
        <div className="field is-grouped is-grouped-centered mt-5">
          <p className="control">
            <button
              type="submit"
              className={`button is-primary ${
                isSubmitting ? 'is-loading' : ''
              }`}
            >
              Update Password
            </button>
          </p>
          <p className="control">
            <button
              type="button"
              className="button is-secondary"
              onClick={logOut}
            >
              Cancel
            </button>
          </p>
        </div>
      </form>
    </>
  ) : (
    <Navigate to="/signin" replace />
  );
}

NewPassword.propTypes = {
  tempCredentials: PropTypes.object,
  logOut: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  cleanError: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

export default NewPassword;
