/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { classesGenerator } from '../../../../transforms';

function PaginationStep({ pageNum, currentPage, pageHandler }) {
  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'is-current': currentPage === pageNum,
        'pagination-link': true,
      }),
    [pageNum, currentPage],
  );

  return (
    <li onClick={pageHandler} onKeyPress={pageHandler} role="presentation">
      <a
        className={modalClasses}
        aria-label="Page 1"
        aria-current="page"
        data-page={pageNum}
      >
        {pageNum + 1}
      </a>
    </li>
  );
}

PaginationStep.propTypes = {
  pageNum: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageHandler: PropTypes.func.isRequired,
};

export default PaginationStep;
