import { useApolloClient } from '@apollo/client';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { typeName } from '../../../../transforms';
import BoxIcon from '../../Icons/BoxIcon';

function DeleteButton({
  mutationType,
  title = null,
  redirectPath = '/',
  customMessage = null,
  className = null,
  style = {},
  match = {},
}) {
  const {
    params: { root, type, action },
  } = match;

  const client = useApolloClient();
  const navigate = useNavigate();
  const { initialValues } = useFormikContext();
  const [confirmState, setConfirmState] = useState({ open: false });
  const messTypeName =
    customMessage ||
    `${
      type
        ? `${type
            .replace('ies', 'y')
            .replace(/(?:s|[sx])$/, '')
            .replace(/-/g, ' ')} `
        : ''
    }${typeName(root, type)}`;

  const show = () => setConfirmState({ open: true });

  const handleCancel = () => setConfirmState({ open: false });

  const [payload] = useState(() => {
    if (title) {
      return { title, icon: false };
    }

    if (action === 'edit') {
      return { title: 'Delete', icon: true };
    }

    return { title: 'Delete', icon: false };
  });

  const destroyItem = async (item) => {
    await client.mutate({
      mutation: mutationType,
      variables: {
        input: {
          id: item.id,
          clientMutationId: uuidv4(),
        },
      },
    });

    navigate(redirectPath);
  };

  const handleConfirm = () => {
    setConfirmState({ open: false });
    destroyItem(initialValues);
  };

  return action === 'edit' ? (
    <>
      <button
        type="button"
        onClick={show}
        className={`button is-danger m-0 ${className}`}
        style={style}
      >
        {payload.icon && payload.icon !== null && <BoxIcon name="bx-trash" />}
        {payload.title && payload.title !== null && (
          <span>{payload.title}</span>
        )}
      </button>

      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div
          className="modal-content"
          style={{ backgroundColor: '#fff', padding: '1em', borderRadius: 12 }}
        >
          <div className="block">
            <h3 className="title is-3">Are you sure?</h3>
            <p>Are you sure you want to delete this {messTypeName}?</p>
          </div>
          <div className="block">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-light is-danger is-outlined"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button is-primary"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCancel}
        />
      </div>
    </>
  ) : null;
}

DeleteButton.propTypes = {
  title: PropTypes.string,
  mutationType: PropTypes.object.isRequired,
  redirectPath: PropTypes.string,
  customMessage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.any,
  match: PropTypes.object,
};

export default memo(DeleteButton);
