/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BoxIcon } from '../../../../shared';
import Message from '../../../../shared/Components/Message/Message';
import NonFormCheckboxField from '../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import ReassignModalDesignLocs from './ReassignModalDesignLocs';

function ReassignModal({
  modalOpen,
  closeModal,
  openModal,
  reassignSilo,
  messageTitle,
  actionTitle,
  designLocationId = null,
  onRowClicked,
  success,
  failure,
  toggleAutoReassign,
  autoReassign,
}) {
  const { t } = useTranslation('orderactions');

  return (
    <>
      <a className="dropdown-item" onClick={openModal}>
        {t(actionTitle)}
      </a>
      <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <BoxIcon name="bx-run bx-md is-size-3" />
            <span>
              <h4 className="title is-5 mb-0 ml-4 modal-card-title">
                {t(`${messageTitle}`)}
              </h4>
            </span>
          </header>
          <section className="modal-card-body" style={{ minHeight: 375 }}>
            {success && (
              <Message type="success" title={t('Case(s) Reassigned')}>
                <p>{t('Successful reassignment to new Design Silo.')}</p>
              </Message>
            )}
            {failure && (
              <Message type="danger" title={t('Reassignment Error')}>
                <p>
                  {t(
                    'There was a problem processing your request. Please try again later.',
                  )}
                </p>
              </Message>
            )}
            <div style={{ marginBottom: '1em' }}>
              <NonFormCheckboxField
                name="autoReassign"
                labelText="Attempt Auto Reassignment"
                onChange={toggleAutoReassign}
                checked={autoReassign}
              />
            </div>
            {!autoReassign && (
              <ReassignModalDesignLocs onRowClicked={onRowClicked} />
            )}
          </section>
          <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
            <button
              type="button"
              className="is-dark button"
              onClick={closeModal}
            >
              {t('Close')}
            </button>
            <button
              type="button"
              className="button is-primary"
              onClick={reassignSilo}
              disabled={(!designLocationId && !autoReassign) || success}
            >
              {t('Send')}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

ReassignModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  reassignSilo: PropTypes.func.isRequired,
  messageTitle: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
  designLocationId: PropTypes.string,
  onRowClicked: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  toggleAutoReassign: PropTypes.func.isRequired,
  autoReassign: PropTypes.bool.isRequired,
};

export default ReassignModal;
