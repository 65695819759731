import PropTypes from 'prop-types';

function SidebarStandardMenu(props) {
  const { children } = props;

  return (
    <aside
      className="menu"
      style={{
        width: 220,
        position: 'fixed',
      }}
    >
      <ul className="menu-list">{children}</ul>
    </aside>
  );
}

SidebarStandardMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarStandardMenu;
