import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createDesignerTag($input: DesignerTagCreateInput!) {
    createDesignerTag(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designerTag {
        id
        name
        slug
        locations {
          id
          name
          slug
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
