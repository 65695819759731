import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetaDataSplitFiles(props) {
  const { orderItem } = props;

  return (
    <FieldMetadataWrapper title="Split File" disabled={!orderItem.splitFile}>
      {orderItem.splitFile && (
        <BoxIcon name="bx-check has-text-success bx-md" />
      )}
    </FieldMetadataWrapper>
  );
}

FieldMetaDataSplitFiles.propTypes = {
  orderItem: PropTypes.object.isRequired,
};

export default FieldMetaDataSplitFiles;
