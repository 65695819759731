import { gql } from '@apollo/client';

export default gql`
  query customerLocation($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      id
      name
      slug
      priceList {
        id
        name
        slug
        turnaroundTimes {
          id
          name
          description
          priority
        }
        designTypes {
          id
          name
        }
        priceListItems {
          id
          turnaroundTimeId
          defaultTurnaroundTime
          designTypeId
          price
        }
      }
    }
  }
`;
