import * as Yup from 'yup';

function schema() {
  return Yup.object({
    message: Yup.string().required('Required'),
    orderId: Yup.string().required('Required'),
    imageUrl: Yup.string().nullable(true),
    fileType: Yup.string().nullable(true),
    s3Id: Yup.string().nullable(true),
  });
}

export default schema;
