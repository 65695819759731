import PropTypes from 'prop-types';

function FeedbackRedesignReason({ setMessage, message = null }) {
  return (
    <div className="field mt-4" style={{ width: '100%' }}>
      <label htmlFor="redesignReason" className="label">
        Additional redesign reason
      </label>
      <div className="control">
        <input
          className="input"
          id="redesignReason"
          value={message}
          type="text"
          placeholder="Provide your reason..."
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
    </div>
  );
}

FeedbackRedesignReason.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
};

export default FeedbackRedesignReason;
