import { gql } from '@apollo/client';
import { OrderHoldReasonInfo } from '../fragments';

export default gql`
  query orderHoldReasons {
    orderHoldReasons {
      ...OrderHoldReasonInfo
    }
  }
  ${OrderHoldReasonInfo}
`;
