import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TreatmentCardBody({
  feedbacks,
  v,
  designerMessages = [],
  final = false,
  useNewViewer = false,
  current = false,
}) {
  const displayedDesignerMessages = designerMessages.filter(
    (item) => !item.holdMessage,
  );

  const { t } = useTranslation('orderactions');

  const currentFeedback = feedbacks
    ? feedbacks.find((item) => item.redesignCount === v)
    : null;

  return current ? (
    <div
      className="treatment-card__body-wrapper"
      style={{ marginTop: final ? 0 : -30 }}
    >
      <b>{t('Designer notes')}:</b>
      {designerMessages?.length &&
      designerMessages.some((item) => !item.message.includes('FullContour')) &&
      useNewViewer ? (
        displayedDesignerMessages?.map((item) =>
          !item.message.includes('FullContour') ? (
            <p key={item.id}>{item.message}</p>
          ) : null,
        )
      ) : (
        <p>-</p>
      )}

      <p style={{ marginBottom: 0 }}>
        <b>{t('Redesign reason')}:</b>
      </p>
      <p>{currentFeedback?.redesignReason || '-'}</p>
    </div>
  ) : null;
}

TreatmentCardBody.propTypes = {
  feedbacks: PropTypes.array.isRequired,
  final: PropTypes.bool,
  useNewViewer: PropTypes.bool,
  current: PropTypes.bool,
  v: PropTypes.number.isRequired,
  designerMessages: PropTypes.array.isRequired,
};

export default TreatmentCardBody;
