import PropTypes from 'prop-types';

function locationNameRenderer({ data }) {
  const { __typename } = data || {};

  const name = data?.attributes?.name || data?.name;
  const code = data?.attributes?.code || data?.code;

  if (!data) {
    return null;
  }

  if (
    __typename === 'CustomerLocationChewy' ||
    __typename === 'CustomerLocation'
  ) {
    return (
      <div style={{ marginTop: '4px', lineHeight: '15px' }}>
        {name}
        <br />
        <small style={{ color: '#666' }}>{code}</small>
      </div>
    );
  }

  return <div>{name}</div>;
}

locationNameRenderer.propTypes = {
  data: PropTypes.shape({
    __typename: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
};

export default locationNameRenderer;
