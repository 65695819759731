import PropTypes from 'prop-types';
import ImageErrorMessage from './ImageErrorMessage';

function ElementImage({ image, selectedFileError = null }) {
  if (selectedFileError) {
    return <ImageErrorMessage selectedFileError={selectedFileError} />;
  }

  return (
    <div>
      <img src={image.signedUrl} alt={image.fileType} />
    </div>
  );
}

ElementImage.propTypes = {
  image: PropTypes.object.isRequired,
  selectedFileError: PropTypes.string,
};

export default ElementImage;
