import { gql } from '@apollo/client';
import {
  DesignPreferenceInfo,
  DesignPreferenceProfileInfo,
  DesignTypeInfo,
} from '../fragments';

export default gql`
  query designPreferenceProfiles {
    designPreferenceProfiles {
      ...DesignPreferenceProfileInfo
    }
  }
  ${DesignPreferenceProfileInfo}
  ${DesignPreferenceInfo}
  ${DesignTypeInfo}
`;
