import { useMutation } from '@apollo/client';
import { CurrentUserContext, guidedFlow } from '@fullcontour/common';
import { ACTION_CURRENT_USER_DEACTIVATE_HELP } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './WelcomeModal.css';

function WelcomeModal({ toggleOverpanel }) {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [open, setOpen] = useState(!currentUser?.dismissedHelp);

  const [update, { loading }] = useMutation(
    ACTION_CURRENT_USER_DEACTIVATE_HELP,
  );

  function dismissHelp({ status }) {
    guidedFlow(true);
    localStorage.setItem('isGuided', true);
    localStorage.setItem('showDesignGuideInfo', true);

    update({
      variables: {
        input: {
          clientMutationId: uuidv4(),
        },
      },
    });

    if (status) {
      toggleOverpanel(status);
    }

    setCurrentUser(true);
    setOpen(false);
  }

  return (
    <div className={`modal mt-6 ${open ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ width: '60%' }}>
        <header className="modal-card-head">
          <h4 className="title is-4 modal-card-title has-text-centered">
            Welcome to 3Shape Design Service!
          </h4>
        </header>
        <section className="modal-card-body">
          <div className="block py-4 px-6">
            <p>
              The 3Shape Design Service team created a unique way to add
              instuctions to your case: Design (Card) Codes. Instead of writing
              instructions manually, we have cards that contain a portion of
              that instruction.
            </p>
            <p>
              <b>
                Add that to your cases to best describe in the most accurate way
                how your case should be designed! When you select and add these
                Design Codes, the 3Shape Design Service Design Team gets the
                best, most accurate description of how your cases should be
                designed.
              </b>
            </p>
            <p>
              We already have selected default codes for you! <br /> You can
              always set up new Card Codes from <b>Design Guide</b> if you need
              aditional/different settings.
            </p>
            <p>Example of Design Guide Code:</p>
          </div>
          <div style={{ width: '60%', margin: '0 auto' }} className="my-5">
            <img
              className="image"
              src="https://cdn.fullcontour.com/help_images/welcomeDesignCode.png"
              alt="Design Guide Code"
              style={{ width: '100%' }}
            />
          </div>
          <div className="is-flex is-justify-content-center">
            <Link
              to="/locations/design-profile"
              className="is-flex is-align-items-center mb-5"
              onClick={() => dismissHelp({ status: null })}
            >
              Learn more about the Design Guide Codes
              <i className="bx bx-link-external is-size-4 ml-1" />
            </Link>
          </div>
        </section>
        <footer className="modal-card-foot py-6 is-flex is-flex-direction-column is-justify-content-center">
          <button
            type="button"
            onClick={() => dismissHelp({ status: 'order' })}
            disabled={loading}
            className={`button is-primary skip-button ${
              loading && 'is-loading'
            }`}
          >
            Create my first case
          </button>
          <span className="my-4 is-block">or</span>
          <p>
            Learn how to use the platform
            <Link
              to="/getting-started"
              className="is-inline-flex is-align-items-center ml-1"
              onClick={() => dismissHelp({ status: null })}
            >
              here <i className="bx bx-link-external is-size-5 ml-1" />
            </Link>
          </p>
        </footer>
      </div>
    </div>
  );
}

WelcomeModal.propTypes = {
  toggleOverpanel: PropTypes.func.isRequired,
};

export default WelcomeModal;
