import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderHoldReasonInfo } from '../fragments';

export default gql`
  mutation createOrderHoldReason($input: OrderHoldReasonCreateInput!) {
    createOrderHoldReason(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderHoldReason {
        ...OrderHoldReasonInfo
      }
    }
  }
  ${OrderHoldReasonInfo}
  ${ErrorMessageInfo}
`;
