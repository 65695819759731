import { createContext, useContext } from 'react';

const GuideCodeCardContext = createContext(null);

export function useGuideCodeCardContext() {
  const context = useContext(GuideCodeCardContext);

  if (!context) {
    throw new Error(
      'GuideCodeCard.* component must be rendered as child of GuideCodeCard component',
    );
  }
  return context;
}

export const GuideCodeCardProvider = GuideCodeCardContext.Provider;
