import { gql } from '@apollo/client';
import { CustomerUserInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCustomerUser($input: CustomerUserCreateInput!) {
    createCustomerUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerUser {
        ...CustomerUserInfo
      }
    }
  }
  ${CustomerUserInfo}
  ${ErrorMessageInfo}
`;
