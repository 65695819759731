/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import Dropdown from '../../../../../../shared/FormFields/Dropdowns/Dropdown';
import DataWrap from '../../OrderShowInfoDataWrapper';

const EDITABLE_DESTINATION = {
  design_in_progress: true,
  sent_for_design: true,
  rush_design: true,
  hold_released: true,
  redesign: true,
  needs_approval: true,
  sent_for_approval: true,
};

function InfoItemDestination({
  manufacturers,
  setFieldValue,
  index,
  values: { orderItems },
  item,
  order,
} ) {

  const handleChange = (e) => {
    orderItems[index].manufacturerOrganizationId = e.target.value;
    setFieldValue('orderItems', orderItems);
  };

  return (
    <div className="column">
      {import.meta.env.VITE_APP_NAME === 'admin_portal' &&
      EDITABLE_DESTINATION[order.state] ? (
        <>
          <p>Destination</p>
          <Dropdown
            name={`orderItems[${index}]`}
            required
            placeholder="Select Destination"
            value={orderItems[index].manufacturerOrganizationId}
            options={manufacturers}
            onChange={handleChange}
            hideLabel
            size="is-small"
          />
        </>
      ) : (
        <DataWrap
          title="Destination"
          dataItem={item.manufacturerOrganization.name}
        />
      )}
    </div>
  );
}

InfoItemDestination.propTypes = {
  index: PropTypes.number.isRequired,
  manufacturers: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default InfoItemDestination;
