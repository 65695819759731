import { gql } from '@apollo/client';

export default gql`
  query adminLocation($slug: String, $id: ID) {
    adminLocation(slug: $slug, id: $id) {
      id
      customerOrganizations {
        id
        text
      }
    }
  }
`;
