import { gql } from '@apollo/client';
import { DesignOrganizationInfo } from '../fragments';

export default gql`
  query designOrganizations($name: String) {
    designOrganizations(name: $name) {
      ...DesignOrganizationInfo
    }
  }
  ${DesignOrganizationInfo}
`;
