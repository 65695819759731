import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderInfo } from '../fragments';

export default gql`
  mutation createCustomerOrder($input: OrderCustomerCreateInput!) {
    createCustomerOrder(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        ...OrderInfo
      }
    }
  }
  ${OrderInfo}
  ${ErrorMessageInfo}
`;
