import PropTypes from 'prop-types';
import { useContext } from 'react';
import { DesignGuideOrderContext } from '../Context';

import './GuideSelectionPopup.css';

function GuideSelectionPopup({
  designGuideCategories = [],
  index,
  currentOrderItem,
}) {
  const { guideModalOpen } = useContext(DesignGuideOrderContext);

  return (
    <button
      id="guide-btn"
      type="button"
      className="button trigger"
      onClick={() =>
        guideModalOpen(designGuideCategories, index, currentOrderItem)
      }
    >
      <span className="icon is-small">
        <i className="bx bxs-pencil" />
      </span>
    </button>
  );
}

GuideSelectionPopup.propTypes = {
  designGuideCategories: PropTypes.array,
  index: PropTypes.number.isRequired,
  currentOrderItem: PropTypes.object.isRequired,
};

export default GuideSelectionPopup;
