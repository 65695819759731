import { gql } from '@apollo/client';
import { ContactInfo } from '../fragments';

export default gql`
  mutation destroyContact($input: ContactDestroyInput!) {
    destroyContact(input: $input) {
      clientMutationId
      contact {
        ...ContactInfo
      }
    }
  }
  ${ContactInfo}
`;
