import PropTypes from 'prop-types';
import { ElementScan } from '../FilesShowElements/ElementScan';
import FileViewImages from './FileViewImages';
import FileViewStl from './FileViewStl';

function FilesShowFileView({
  selectedFile: { fileType },
  selectedFile,
  toggleVersions,
  order = null,
  selectedFileError = null,
}) {
  if (
    fileType === 'initial_scans' ||
    fileType === 'finished_scans' ||
    fileType === 'manufacturer_scans'
  ) {
    return <ElementScan selectedFile={selectedFile} />;
  }

  if (fileType === 'quality_control_images') {
    return (
      <FileViewImages
        selectedFile={selectedFile}
        selectedFileError={selectedFileError}
        toggleVersions={toggleVersions}
      />
    );
  }

  if (fileType === 'stl_files' || fileType === 'visualization_output') {
    return (
      <FileViewStl
        selectedFile={selectedFile}
        toggleVersions={toggleVersions}
        allFiles={order?.files}
        orderName={order?.name}
        hexOrderCode={order?.hexOrderCode}
      />
    );
  }

  return null;
}

FilesShowFileView.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  order: PropTypes.object,
  toggleVersions: PropTypes.bool.isRequired,
  selectedFileError: PropTypes.string,
};

export default FilesShowFileView;
