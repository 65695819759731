import { gql } from '@apollo/client';
import { BusinessUnitInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createBusinessUnit($input: BusinessUnitCreateInput!) {
    createBusinessUnit(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      businessUnit {
        ...BusinessUnitInfo
      }
    }
  }
  ${BusinessUnitInfo}
  ${ErrorMessageInfo}
`;
