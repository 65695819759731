import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateGuideCodeGroup($input: GuideCodeGroupUpdateInput!) {
    updateGuideCodeGroup(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      guideCodeGroup {
        id
        name
        labLocationId
        guideCodes {
          id
          name
          description
          imageUrl
          guideCategory {
            name
            imageUrl
            description
            id
            showDropdown
            guideCategoryGroupId
            guideCodes {
              id
              name
              description
              imageUrl
            }
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
