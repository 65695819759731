import { GET_ADMIN_LOCS_DROPDOWN } from '@fullcontour/shared-api';
import { FastField, Field } from 'formik';
import { useParams } from 'react-router';
import DynamicDropdown from '../Dropdowns/DynamicDropdown';
import LanguageDropdown from '../Dropdowns/LanguageDropdown/LanguageDropdown';
import FieldContainer from '../FieldContainer/FieldContainer';

function CommonOrgFields() {
  const { action, type } = useParams();
  const app = import.meta.env.VITE_APP_NAME;

  return (
    <>
      <div className="field is-grouped">
        <div className="control is-expanded">
          <FieldContainer name="name" required label="Name">
            <FastField
              id="name"
              name="name"
              required
              className="input"
              placeholder="Organization Name"
            />
          </FieldContainer>
        </div>
        {app === 'admin_portal' && (
          <div className="control">
            <FieldContainer name="shortName" required label="Short Name">
              <FastField
                id="shortName"
                className="input"
                name="shortName"
                required
                placeholder="FCO"
              />
            </FieldContainer>
          </div>
        )}
      </div>
      {app === 'admin_portal' && type !== 'design' && (
        <Field
          name="administratorLocationId"
          required
          component={DynamicDropdown}
          placeholder="Select an Administrator Location..."
          dynamicQuery={GET_ADMIN_LOCS_DROPDOWN}
          dataName="adminLocations"
          labelText="Parent Admin Location"
          disabled={action !== 'new'}
        />
      )}
      <Field name="language" required component={LanguageDropdown} />
    </>
  );
}

export default CommonOrgFields;
