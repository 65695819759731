import { Drawer, OverpanelDrawerContext } from '@fullcontour/common';
import { useContext } from 'react';

function OverpanelDrawer() {
  const { open, toggle, body } = useContext(OverpanelDrawerContext);

  return (
    <Drawer
      open={open}
      onClose={toggle}
      direction="right"
      size={420}
      enableOverlay
    >
      {body}
    </Drawer>
  );
}

export default OverpanelDrawer;
