import PropTypes from 'prop-types';
function ratingRenderer({ value = null }) {
  if (value !== '' && value !== undefined && value !== null) {
    let labelClass;

    if (value === 5) {
      labelClass = 'tag is-success';
    } else {
      labelClass = 'tag is-danger';
    }

    const labelStyle = {
      margin: '2% 5px',
      verticalAlign: 'middle',
      display: 'inline-block',
      textTransform: 'capitalize',
      minWidth: '40px',
      textAlign: 'center',
      color: value ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
    };

    return (
      <span>
        <div className={labelClass} style={labelStyle}>
          {value ? '+' : '-'}
        </div>
      </span>
    );
  }
  return null;
}
ratingRenderer.propTypes = {
  value: PropTypes.string,
};
export default ratingRenderer;
