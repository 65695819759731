/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useContext, useMemo, useRef } from 'react';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';

function ObjElementAttachmentInner({ obj, name, unn, matrix }) {
  const { attachmentMaterial, visibility } = useContext(OrthoContext);

  const ref = useRef();

  const isUpper = useMemo(() => parseInt(unn, 10) < 17, [unn]);

  const isLower = useMemo(() => parseInt(unn, 10) >= 17, [unn]);

  return obj?.children[0]?.geometry ? (
    <mesh
      geometry={obj.children[0].geometry}
      name={name}
      receiveShadow
      castShadow
      material={attachmentMaterial}
      visible={
        (isUpper && visibility.upper && visibility.attachments) ||
        (isLower && visibility.lower && visibility.attachments)
      }
      ref={ref}
      matrixAutoUpdate={false}
      onUpdate={(self) => {
        if (matrix?.length > 0) {
          self.matrix.set(
            matrix[0],
            matrix[1],
            matrix[2],
            matrix[3],
            matrix[4],
            matrix[5],
            matrix[6],
            matrix[7],
            matrix[8],
            matrix[9],
            matrix[10],
            matrix[11],
            matrix[12],
            matrix[13],
            matrix[14],
            matrix[15],
          );
        }
      }}
    />
  ) : null;
}

ObjElementAttachmentInner.propTypes = {
  obj: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  unn: PropTypes.number.isRequired,
  matrix: PropTypes.array.isRequired,
};

export default ObjElementAttachmentInner;
