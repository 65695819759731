import fileIcon from './fileIcon';

function fileCellRenderer() {
  function FileCellRenderer() {}
  // eslint-disable-next-line
  FileCellRenderer.prototype.init = function (params) {
    const tempDiv = document.createElement('div');
    const { value } = params;
    const icon = fileIcon(params.value);
    tempDiv.innerHTML = icon
      ? `<span><span class="icon"><i class="bx ${icon} bx-xs"></i></span><span class="filename">${value}</span>`
      : value;
    this.eGui = tempDiv.firstChild;
  };
  // eslint-disable-next-line
  FileCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return FileCellRenderer;
}

export default fileCellRenderer;
