import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Highlighted } from '../../../Components';
import { NonFormDropdown } from '../../../FormFields';
import { useGuideCodeCardContext } from './context/GuideCodeCardContext';

function GuideCodeCardDropdown({
  children = null,
  handleDropdown = () => {},
  disabled = false,
  noOther = false,
}) {
  const { data } = useGuideCodeCardContext();

  const { category, imageUrl, searchValue, id } = data;

  const [otherValue, setOtherValue] = useState(id?.includes('other'));

  const options = category.guideCodes.map((item) => ({
    name: `${item.name} ${item.default ? '(default)' : ''}`,
    value: item.id,
  }));

  if (category.allowOther && !noOther) {
    options.push({
      name: 'Other',
      value: 'other',
    });
  }

  const onChange = (e) => {
    const { value } = e.target;
    const isValueOther = value === 'other';

    handleDropdown({ data, value, isValueOther });
    setOtherValue(isValueOther ? 'other' : null);
  };

  return category.showDropdown ? (
    <div className="card-content">
      <div className="is-flex">
        <div className="media-left card-picture">
          <figure className="image m-0">
            <img
              src={
                imageUrl ||
                'https://s3-us-west-2.amazonaws.com/fc2-development/assets/images/l/default.svg'
              }
              alt="card"
            />
          </figure>
        </div>
        <div className="media-content p-4 card-data">
          <p className="title is-6 has-text-weight-semibold">
            <Highlighted text={category.name} highlight={searchValue} />
          </p>
          <NonFormDropdown
            name="guide"
            hideLabel
            disabled={disabled}
            options={options}
            onChange={onChange}
            placeholder="Select An Option..."
            value={id?.includes('other') ? 'other' : id}
          />
          {children
            ? React.cloneElement(children, { showOtherValue: otherValue })
            : null}
        </div>
      </div>
    </div>
  ) : null;
}

GuideCodeCardDropdown.propTypes = {
  children: PropTypes.node,
  handleDropdown: PropTypes.func,
  disabled: PropTypes.bool,
  noOther: PropTypes.bool,
};

export default GuideCodeCardDropdown;
