import { CurrentUserContext } from '@fullcontour/common';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import HelpCenter from './HelpCenter';

function NavElements() {
  const { currentUser } = useContext(CurrentUserContext);
  const { t } = useTranslation('navigation');

  return (
    <>
      <NavLink to="/orders" className="navbar-item">
        {t('cases')}
      </NavLink>
      {!currentUser?.scopeToLocation && (
        <NavLink to="/organizations" className="navbar-item">
          {t('organization')}
        </NavLink>
      )}
      {currentUser?.roleLevel < 60 && (
        <>
          <NavLink to="/locations" className="navbar-item">
            {t(currentUser?.scopeToLocation ? 'location' : 'locations')}
          </NavLink>
          <NavLink to="/users" className="navbar-item">
            {t('users')}
          </NavLink>
        </>
      )}
      <HelpCenter />
    </>
  );
}

export default NavElements;
