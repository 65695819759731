/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import { Box3, Sphere, Vector3 } from 'three';
import { OrthoContext } from '../../../shared/Context/OrthoContext';

function CenterView(
  {
    children,
    disable = false,
    disableX = false,
    disableY = false,
    disableZ = false,
    left = false,
    right = false,
    top = false,
    bottom = false,
    front = false,
    back = false,
    precise = false,
    cacheKey = false,
    ...rest
  },
  fRef,
) {
  const ref = useRef(null);
  const outer = useRef(null);
  const inner = useRef(null);

  const { currentPerspective } = useContext(OrthoContext);

  useLayoutEffect(() => {
    outer.current.matrixWorld.identity();
    const box3 = new Box3().setFromObject(inner.current);
    const center = new Vector3();
    const sphere = new Sphere();
    const width = box3.max.x - box3.min.x;
    const height = box3.max.y - box3.min.y;
    const depth = box3.max.z - box3.min.z;
    box3.getCenter(center);
    box3.getBoundingSphere(sphere);
    const vAlign = top ? height / 2 : bottom ? -height / 2 : 0;
    const hAlign = left ? -width / 2 : right ? width / 2 : 0;
    const dAlign = front ? depth / 2 : back ? -depth / 2 : 0;

    outer.current.position.set(
      disable || disableX ? 0 : -center.x + hAlign,
      disable || disableY ? 0 : -center.y + vAlign,
      disable || disableZ ? 0 : -center.z + dAlign,
    );
  }, [
    cacheKey,
    top,
    left,
    front,
    disable,
    disableX,
    disableY,
    disableZ,
    precise,
    right,
    bottom,
    back,
    currentPerspective,
  ]);

  useImperativeHandle(fRef, () => ref.current, []);

  return (
    <group ref={ref} {...rest}>
      <group ref={outer}>
        <group ref={inner}>{children}</group>
      </group>
    </group>
  );
}

// CenterView.propTypes = {
//   children: PropTypes.node.isRequired,
//   disable: PropTypes.bool,
//   disableX: PropTypes.bool,
//   disableY: PropTypes.bool,
//   disableZ: PropTypes.bool,
//   left: PropTypes.bool,
//   right: PropTypes.bool,
//   top: PropTypes.bool,
//   bottom: PropTypes.bool,
//   front: PropTypes.bool,
//   back: PropTypes.bool,
//   precise: PropTypes.bool,
//   cacheKey: PropTypes.number,
// };

export default forwardRef(CenterView);
