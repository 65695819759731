import { gql } from '@apollo/client';

const DesignUserInfo = gql`
  fragment DesignUserInfo on DesignUser {
    id
    firstName
    lastName
    email
    language
    preferredUsername
    state
    role {
      id
      name
      level
      scopeToLocation
      assignmentOrganizations
      assignmentLocations
      assignmentDesignTypes
    }
    designLocationIds
    organization {
      id
      name
      shortName
      slug
    }
    location {
      id
      name
      slug
    }
    createdAt
    updatedAt
  }
`;

export default DesignUserInfo;
