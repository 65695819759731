import { gql } from '@apollo/client';

export default gql`
  query printerMaterials {
    printerMaterials {
      id
      name
      description
      imageUrl
      slug
      printer {
        id
        name
        description
        imageUrl
        slug
      }
    }
  }
`;
