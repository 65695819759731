const downloadMarkAtManuf = {
  admin_portal: {
    visible: true,
    statuses: {
      sent_to_manufacturer: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: true,
    statuses: {
      sent_to_manufacturer: [0, 10],
    },
  },
  designer_portal: {
    visible: false,
  },
};

export default downloadMarkAtManuf;
