import { gql } from '@apollo/client';

export default gql`
  query printerBrand($id: ID, $slug: String) {
    printerBrand(id: $id, slug: $slug) {
      id
      name
      description
      imageUrl
      slug
    }
  }
`;
