import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function ShowDetailsSubText({ subHeader = null, subHeaderLabel = null }) {
  const { t } = useTranslation('showelements');

  return (
    <>
      {subHeaderLabel && (
        <h5 className="title is-5 mb-0">{t(subHeaderLabel)}</h5>
      )}
      <div className="mt-0 mb-0">{subHeader}</div>
    </>
  );
}

ShowDetailsSubText.propTypes = {
  subHeader: PropTypes.node,
  subHeaderLabel: PropTypes.string,
};

export default memo(ShowDetailsSubText);
