import { gql } from '@apollo/client';

export default gql`
  query priceListDesignTypeCategories($id: ID!) {
    priceListDesignTypeCategories(id: $id) {
      id
      name
      designLocations {
        id
        name
        key
        text
        value
      }
    }
    designTypeCategories {
      id
      name
      designLocations {
        id
        name
        key
        text
        value
      }
    }
  }
`;
