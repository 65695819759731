import { gql } from '@apollo/client';

export default gql`
  query countries($locale: String) {
    countries(locale: $locale) {
      id
      name
      value
    }
  }
`;
