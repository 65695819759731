import PropTypes from 'prop-types';
import { OrthoStlViewer } from '../../../../../../shared';
import FilesShowFileMetadata from './FilesShowFileMetadata';

function FileViewStl({
  selectedFile,
  toggleVersions,
  allFiles = null,
  orderName = '',
  hexOrderCode = '',
}) {
  return (
    <>
      <OrthoStlViewer
        file={selectedFile}
        allFiles={allFiles}
        orderName={orderName}
        hexOrderCode={hexOrderCode}
      />
      <FilesShowFileMetadata
        selectedFile={selectedFile}
        toggleVersions={toggleVersions}
      />
    </>
  );
}

FileViewStl.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
  allFiles: PropTypes.array,
  orderName: PropTypes.string,
  hexOrderCode: PropTypes.string,
};

export default FileViewStl;
