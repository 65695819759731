import { gql } from '@apollo/client';

const GuideBookInfo = gql`
  fragment GuideBookInfo on GuideBook {
    id
    name
    slug
    createdAt
    updatedAt
    parentGuideBookId
    guideCategoryGroups {
      name
      id
      slug
      createdAt
      description
    }
  }
`;

export default GuideBookInfo;
