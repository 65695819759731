import PropTypes from 'prop-types';

function CutLineControl(props) {
  const { setCutLineVisible, cutLineVisible } = props;

  function toggle() {
    if (cutLineVisible) {
      setCutLineVisible(false);
    }

    if (!cutLineVisible) {
      setCutLineVisible(true);
    }
  }

  return (
    <div style={{ position: 'absolute', top: 20, left: 20 }}>
      <button
        className="button is-primary is-light"
        type="button"
        onClick={toggle}
      >
        <span className="icon">
          <i className={`bx ${cutLineVisible ? 'bx-low-vision' : 'bx-show'}`} />
        </span>
      </button>
    </div>
  );
}

CutLineControl.propTypes = {
  setCutLineVisible: PropTypes.func.isRequired,
  cutLineVisible: PropTypes.bool.isRequired,
};

export default CutLineControl;
