import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionCustomerLocationUnsuspend(
    $input: CustomerLocationUnsuspendActionInput!
  ) {
    actionCustomerLocationUnsuspend(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerLocation {
        id
        suspended
      }
    }
  }
  ${ErrorMessageInfo}
`;
