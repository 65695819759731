import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    organizationId: Yup.string().required('Required'),
    roleId: Yup.string().required('Required'),
    locationId: Yup.string().nullable(true),
    email: Yup.string().email('Email is not valid').required('Required'),
    phone: Yup.string().nullable(true),
    language: Yup.string().required('Language is required'),
    designTypeIds: Yup.array().of(Yup.string()),
    customerLocationIds: Yup.array().of(Yup.string()),
  });
}

export default schema;
