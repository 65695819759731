/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';

function SliderCard(props) {
  const {
    index,
    modIndex,
    stages,
    carouselState: { cursor },
    go,
  } = props;

  const app = import.meta.env.VITE_APP_NAME;
  const stage = stages[modIndex];

  const fullText = () =>
    stage.sortOrder > 500 &&
    app !== 'approval-patient' &&
    app !== 'approval-patient-full'
      ? `OC${stage.sortOrder - 600}`
      : stage.index + 1;

  return (
    <div
      key={index}
      className={`carousel-card ${
        -1 * cursor === stages[modIndex].index ? 'active' : ''
      }`}
      onClick={() => go(index)}
    >
      <div className="carousel-card-inner">
        <div className="carousel-text">{fullText()}</div>
      </div>
    </div>
  );
}

SliderCard.propTypes = {
  index: PropTypes.number.isRequired,
  stages: PropTypes.array.isRequired,
  modIndex: PropTypes.number.isRequired,
  carouselState: PropTypes.object.isRequired,
  go: PropTypes.func.isRequired,
};

export default SliderCard;
