import { gql } from '@apollo/client';

export default gql`
  query modifierGroups {
    modifierGroups {
      id
      key
      value
      text
    }
  }
`;
