function assignTurnaroundTimes(
  orderItems,
  priceListItems,
  turnaroundTimes,
  designTypes,
) {
  const designTypesRush = designTypes.reduce((obj, item) => {
    if (!item.acceptRushDesigns) {
      // eslint-disable-next-line no-param-reassign
      obj[item.id] = true;
    }

    return obj;
  }, {});

  const isEnabled = orderItems.reduce((bool, orderItem) => {
    if (bool && designTypesRush[orderItem.designTypeId]) {
      return false;
    }

    return bool;
  }, true);

  const listItemGroups = () => {
    const designTypeIds = [
      ...new Set(orderItems.map((item) => item.designTypeId)),
    ];

    return designTypeIds.map((id) =>
      priceListItems.filter((item) => item.designTypeId === id),
    );
  };

  const determineTurnaroundIntersection = () => {
    const priceListItemGroups = listItemGroups();

    if (priceListItemGroups.length === 1) {
      return priceListItemGroups[0];
    }

    return priceListItemGroups.reduce((a, b) =>
      a.filter((c) => b.some((x) => x.turnaroundTimeId === c.turnaroundTimeId)),
    );
  };

  const filterTurnaroundTimes = () => {
    const turnaroundIntersection = determineTurnaroundIntersection();

    return turnaroundTimes
      .filter((time) =>
        turnaroundIntersection.some((i) => i.turnaroundTimeId === time.id),
      )
      .map((t) => {
        const foundIntersection = turnaroundIntersection.find(
          (i) => i.turnaroundTimeId === t.id,
        );
        return {
          ...t,
          defaultTurnaroundTime: foundIntersection.defaultTurnaroundTime,
          disabled: t.isRush && !isEnabled,
        };
      });
  };

  const filteredTurnaroundTimes = filterTurnaroundTimes();

  const findDefaultTurnaroundTime = () => {
    if (filteredTurnaroundTimes.length === 1) {
      if (filteredTurnaroundTimes[0].isRush && !isEnabled) {
        return {};
      }
      return filteredTurnaroundTimes[0];
    }

    if (
      filteredTurnaroundTimes.length > 1 &&
      !filteredTurnaroundTimes.some((i) => i.defaultTurnaroundTime)
    ) {
      return filteredTurnaroundTimes.sort((a, b) => a.priority - b.priority)[
        filteredTurnaroundTimes.length - 1
      ];
    }

    if (
      filteredTurnaroundTimes.length > 1 &&
      filteredTurnaroundTimes.some((i) => i.defaultTurnaroundTime)
    ) {
      const filteredIntersection = filteredTurnaroundTimes
        .filter((i) => i.defaultTurnaroundTime && !(i.isRush && !isEnabled))
        .sort((a, b) => a.priority - b.priority);
      if (!filteredIntersection.length) {
        return filteredTurnaroundTimes.find((item) =>
          item.text.includes('Next'),
        );
      }
      return filteredIntersection[filteredIntersection.length - 1];
    }

    return {};
  };

  const defaultTurnaroundTime = findDefaultTurnaroundTime();

  return {
    filteredTurnaroundTimes,
    defaultTurnaroundTime,
  };
}

export default assignTurnaroundTimes;
