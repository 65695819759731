import { gql } from '@apollo/client';

export default gql`
  mutation destroyManufacturerOrganization(
    $input: ManufacturerOrganizationDestroyInput!
  ) {
    destroyManufacturerOrganization(input: $input) {
      clientMutationId
      organization {
        id
      }
    }
  }
`;
