import { gql } from '@apollo/client';

export default gql`
  query regions($locale: String, $country: String!) {
    regions(locale: $locale, country: $country) {
      id
      name
      value
    }
  }
`;
