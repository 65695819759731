import { gql } from '@apollo/client';

export default gql`
  query designTypeCategories($name: String) {
    designTypeCategories(name: $name) {
      id
      name
      slug
      designLocations {
        id
        name
        slug
        organization {
          id
          name
          slug
        }
      }
    }
  }
`;
