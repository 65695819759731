import { gql } from '@apollo/client';
import { GuideBookInfo } from '../fragments';

export default gql`
  query guideBook($id: ID, $slug: String) {
    guideBook(id: $id, slug: $slug) {
      ...GuideBookInfo
    }
  }
  ${GuideBookInfo}
`;
