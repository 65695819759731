import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideCodeGroup($input: GuideCodeGroupDestroyInput!) {
    destroyGuideCodeGroup(input: $input) {
      clientMutationId
      guideCodeGroup {
        id
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
