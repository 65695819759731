import { gql } from '@apollo/client';
import { ModifierInfo } from '../fragments';

export default gql`
  mutation destroyModifier($input: ModifierDestroyInput!) {
    destroyModifier(input: $input) {
      clientMutationId
      modifier {
        ...ModifierInfo
      }
    }
  }
  ${ModifierInfo}
`;
