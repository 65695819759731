import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceMetadata from './PreferenceMetadata';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeRelation(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Correct Ant/Post Relation">
      <div className="checkWrap">
        <NonFormCheckboxField
          name="relationUpper"
          labelText="Upper"
          readOnly
          checked={
            'AnteriorPosteriorRelationCorrection' in prescription &&
            'LeftSide' in prescription.AnteriorPosteriorRelationCorrection
          }
        />
        {'AnteriorPosteriorRelationCorrection' in prescription &&
          'LeftSide' in prescription.AnteriorPosteriorRelationCorrection && (
            <PreferenceMetadata
              value={prescription.AnteriorPosteriorRelationCorrection.LeftSide}
            />
          )}
      </div>
      <div className="checkWrap">
        <NonFormCheckboxField
          name="relationLower"
          labelText="Lower"
          readOnly
          checked={
            'AnteriorPosteriorRelationCorrection' in prescription &&
            'RightSide' in prescription.AnteriorPosteriorRelationCorrection
          }
        />
        {'AnteriorPosteriorRelationCorrection' in prescription &&
          'RightSide' in prescription.AnteriorPosteriorRelationCorrection && (
            <PreferenceMetadata
              value={prescription.AnteriorPosteriorRelationCorrection.RightSide}
            />
          )}
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeRelation.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeRelation;
