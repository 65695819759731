import { useMutation } from '@apollo/client';
import {
  CREATE_GUIDE_CATEGORY_USER,
  DESTROY_GUIDE_CATEGORY_USER,
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
} from '@fullcontour/shared-api';
import { useContext } from 'react';
import { handleTryCatch } from '../../../..';
import { CurrentUserContext } from '../../../../Context';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';
import { actionButtonsPermissions } from '../helpers';

function ButtonFavourites() {
  const { currentUser } = useContext(CurrentUserContext);
  const { data } = useGuideCodeCardContext();

  const { category } = data;

  const { favorite } = category;

  const [addOrRemoveFavourites, { loading }] = useMutation(
    favorite ? DESTROY_GUIDE_CATEGORY_USER : CREATE_GUIDE_CATEGORY_USER,
    {
      fetchPolicy: 'network-only',
      refetchQueries: [{ query: GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS }],
    },
  );
  const addOrRemoveFavouritesHandler = handleTryCatch(
    async () => {
      const variables = {
        input: {
          input: {
            guideCategoryId: category.id,
          },
        },
      };

      await addOrRemoveFavourites({ variables });
    },
    {
      successText: `Category was successfully ${
        !favorite ? 'added to' : 'removed from'
      } favorites!`,
    },
  );

  return actionButtonsPermissions(currentUser.roleLevel, {
    customer_portal: [40, 50],
  }) ? (
    <button
      type="button"
      className={`button is-rounded has-background-white p-0 mr-1 ${
        loading && 'is-loading'
      }`}
      onClick={() => addOrRemoveFavouritesHandler()}
    >
      <span className="icon">
        <i
          className={`bx ${favorite ? 'bxs-star has-text-primary' : 'bx-star'}`}
        />
      </span>
    </button>
  ) : null;
}

export default ButtonFavourites;
