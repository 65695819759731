import { gql } from '@apollo/client';

const OrderFileInfo = gql`
  fragment OrderFileInfo on OrderFile {
    id
    fileUrl
    s3Key
    ver
    latestVer
    createdAt
    fileType
    orderId
    sortOrder
    category
    originalFileName
    redesignCount
    downloadUrl
    caseFolderId
    caseFolder {
      id
      name
      folderName
      allowMultiple
    }
  }
`;

export default OrderFileInfo;
