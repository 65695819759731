/* eslint-disable react/no-array-index-key */
import { useLocation } from 'react-router';
import BreadCrumbPathHome from './BreadCrumbPathHome';
import BreadCrumbPathLast from './BreadCrumbPathLast';
import BreadCrumbPathsMiddle from './BreadCrumbPathsMiddle';

function BreadCrumbPaths() {
  const location = useLocation();
  const { pathname } = location;

  return pathname.split('/').map((p, i, arr) => {
    if (i === 0) return <BreadCrumbPathHome key={i} />;

    if (i === arr.length - 1) {
      return <BreadCrumbPathLast p={p} i={i} arr={arr} key={i} />;
    }

    return <BreadCrumbPathsMiddle p={p} i={i} arr={arr} key={i} />;
  });
}

export default BreadCrumbPaths;
