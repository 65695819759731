import { gql } from '@apollo/client';

export default gql`
  mutation destroyDesignOrganization($input: DesignOrganizationDestroyInput!) {
    destroyDesignOrganization(input: $input) {
      clientMutationId
      organization {
        id
      }
    }
  }
`;
