import { GET_DESIGN_TYPES_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import DataPointWrapper from '../DataPoints/DataPointWrapper';
import { MultiSelect } from '../FormFields';

function DataPointsDesignTypes(props) {
  const { inputParams } = props;
  const app = import.meta.env.VITE_APP_NAME;

  return inputParams.designTypes && app === 'admin_portal' ? (
    <DataPointWrapper>
      <Field
        name="designTypeIds"
        required
        component={MultiSelect}
        label="Design Types"
        query={GET_DESIGN_TYPES_MULTI}
        dataname="designTypes"
      />
    </DataPointWrapper>
  ) : null;
}

DataPointsDesignTypes.propTypes = {
  inputParams: PropTypes.object.isRequired,
};

export default DataPointsDesignTypes;
