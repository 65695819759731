import PropTypes from 'prop-types';
import { displayTeethBasedOnNotation } from '../../../../../../transforms';

function OrderCodesNested({ label, items = null, numberingSystem = null }) {
  return items ? (
    <div className="p-2">
      {label}:{' '}
      {items.map((item) => (
        <span key={item} className="tag mr-1 is-primary">
          {numberingSystem
            ? displayTeethBasedOnNotation(numberingSystem, item)
            : item}
        </span>
      ))}
    </div>
  ) : null;
}

OrderCodesNested.propTypes = {
  label: PropTypes.string.isRequired,
  numberingSystem: PropTypes.string,
  items: PropTypes.array,
};

export default OrderCodesNested;
