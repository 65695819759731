import { gql } from '@apollo/client';
import { DesignUserInfo } from '../fragments';

export default gql`
  query designUsers($locationId: String, $organizationId: String) {
    designUsers(locationId: $locationId, organizationId: $organizationId) {
      ...DesignUserInfo
    }
  }
  ${DesignUserInfo}
`;
