import PropTypes from 'prop-types';
import { memo, Suspense } from 'react';
import { BaseLoader } from '../../shared';
import SidebarLayout from '../SidebarLayout/SidebarLayout';
import PrimaryNavigation from './PrimaryNavigation';
import PriorityMessage from './PriorityMessage';
import './ResponsiveContainer.scss';

function ResponsiveContainer({ children, bodyElement: BodyElement = null }) {
  const app = import.meta.env.VITE_APP_NAME;
  return (
    <div>
      <PrimaryNavigation>{children}</PrimaryNavigation>
      {app === 'customer_portal' && <PriorityMessage />}
      <div className="bodyContainer">
        <Suspense fallback={<BaseLoader />}>
          {BodyElement ? <BodyElement /> : <SidebarLayout />}
        </Suspense>
      </div>
    </div>
  );
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
  bodyElement: PropTypes.object,
};

export default memo(ResponsiveContainer);
