import { gql } from '@apollo/client';

const AdminUserInfo = gql`
  fragment AdminUserInfo on AdminUser {
    id
    firstName
    lastName
    email
    phone
    language
    state
    role {
      id
      name
      level
      scopeToLocation
      assignmentLocations
      assignmentOrganizations
      assignmentDesignTypes
    }
    administratorLocationIds
    customerOrganizationIds
    designTypeIds
    organization {
      id
      name
      shortName
      slug
    }
    location {
      id
      name
      slug
    }
    createdAt
    updatedAt
  }
`;

export default AdminUserInfo;
