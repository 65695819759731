import { GET_AUTH_TYPES, GET_GRANT_TYPES } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import {
  CheckboxField,
  DynamicDropdown,
  FieldContainer,
  FormGridWrap,
  FormSection,
  PasswordField,
  withFormWrap,
} from '../../../shared';
import ApiAgreement from './ApiAgreement';
import { initialValues, schema, update } from './helpers';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/client';

function ApiSettingsFormInner({
  initialValues = () => {},
  schema = () => {},
  match = {},
  separateId = null,
  apiSettings = {},
  root = null,
  type = null,
  entityId = null,
  ...rest
}) {
  const client = useApolloClient();
  const app = import.meta.env.VITE_APP_NAME;
  let labelText;
  if (app === 'admin_portal') {
    labelText = `${root.slice(0, -1)} accepted terms`;
  } else {
    labelText =
      'I Accept Terms and conditions of the 3Shape Design Service API Platform';
  }
  function checkForPassFields(values) {
    return (
      (values.authorizationType && values.authorizationType === 'basic_auth') ||
      (values.authorizationType &&
        values.authorizationType !== 'basic_auth' &&
        values.grantType &&
        values.grantType === 'password')
    );
  }
  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }
    if (separateId) {
      delete variables.variables.input.input.id;
    }
    if (!match.params.action) {
      update({ variables, client, ...rest });
    }
  }
  return (
    <Formik
      initialValues={initialValues({ apiSettings, type, entityId })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <FormGridWrap match={match} customMessage="API Settings">
          <FormSection title="Terms and Conditions">
            <ApiAgreement />
            <Field
              name="termsAccepted"
              required
              disabled={app === 'admin_portal'}
              component={CheckboxField}
              labelText={labelText}
            />
          </FormSection>
          <FormSection title="3Shape Design Service Access Credentials">
            <FieldContainer name="fcClientId" label="Client ID">
              <Field
                id="fcClientId"
                className="input"
                name="fcClientId"
                placeholder="Client ID"
                disabled={app !== 'admin_portal'}
              />
            </FieldContainer>
            <FieldContainer name="fcClientSecret" label="Client Secret">
              <Field
                id="fcClientSecret"
                className="input"
                name="fcClientSecret"
                placeholder="Client Secret"
                disabled={app !== 'admin_portal'}
              />
            </FieldContainer>
          </FormSection>
          <FormSection title="Callback Hook Integration">
            <Field
              name="authorizationType"
              component={DynamicDropdown}
              placeholder="Select your Authorization Type"
              dynamicQuery={GET_AUTH_TYPES}
              dataName="authorizationTypes"
              labelText="Callback Authorization Type"
            />
            {values.authorizationType &&
              values.authorizationType !== 'basic_auth' && (
                <>
                  <Field
                    name="grantType"
                    component={DynamicDropdown}
                    placeholder="Select your oAuth Grant Type"
                    dynamicQuery={GET_GRANT_TYPES}
                    dataName="grantTypes"
                    labelText="Callback Auth Server oAuth Grant Type"
                  />
                  <FieldContainer
                    name="authServer"
                    label="Authorization Server"
                  >
                    <Field
                      id="authServer"
                      className="input"
                      name="authServer"
                      placeholder="Authorization Server"
                    />
                  </FieldContainer>
                  <FieldContainer
                    name="tokenResponseKey"
                    label="Token Response JSON key"
                  >
                    <Field
                      id="tokenResponseKey"
                      className="input"
                      name="tokenResponseKey"
                      placeholder="access_token"
                    />
                  </FieldContainer>
                </>
              )}

            {checkForPassFields(values) && (
              <>
                <FieldContainer name="username" label="Username" required>
                  <Field
                    id="username"
                    name="username"
                    className="input"
                    required
                    placeholder="Username"
                  />
                </FieldContainer>
                <PasswordField />
              </>
            )}

            {values.authorizationType &&
              values.authorizationType !== 'basic_auth' && (
                <>
                  <FieldContainer name="clientId" label="Client ID">
                    <Field
                      id="clientId"
                      name="clientId"
                      placeholder="Client ID"
                      className="input"
                    />
                  </FieldContainer>
                  <PasswordField name="clientSecret" label="Client Secret" />
                </>
              )}
            <FieldContainer name="callbackUrl" label="Webhook Callback URL">
              <Field
                id="callbackUrl"
                className="input"
                name="callbackUrl"
                placeholder="Webhook Callback URL"
              />
            </FieldContainer>
          </FormSection>
          <FormSection title="Active Integration">
            <Field
              name="active"
              required
              component={CheckboxField}
              labelText="Active?"
            />
          </FormSection>
        </FormGridWrap>
      )}
    </Formik>
  );
}

ApiSettingsFormInner.propTypes = {
  match: PropTypes.object,
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  separateId: PropTypes.number,
  apiSettings: PropTypes.object,
  root: PropTypes.string,
  type: PropTypes.string,
  entityId: PropTypes.string,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  ApiSettingsFormInner,
);
