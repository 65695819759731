import { gql } from '@apollo/client';

export default gql`
  query orderItems($id: ID!) {
    order(id: $id) {
      id
      orderItems {
        id
        units
        teeth
        arches
        color
        price
        designTypeName
        guideCodes
        modifiers {
          id
          name
        }
        designType {
          attachmentFiles
          requiresApprovalUpload
          clearAlignerDesignType
          name
          id
          feedbackCategories {
            id
            name
            options {
              id
              name
            }
          }
        }
        materialName
        splitFile
        designTypeId
        manufacturerOrganizationId
        manufacturerOrganization {
          id
          name
          isInHouse
        }
      }
    }
  }
`;
