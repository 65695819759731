import { gql } from '@apollo/client';
import { DebitInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createDebit($input: DebitCreateInput!) {
    createDebit(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      debit {
        ...DebitInfo
      }
    }
  }
  ${DebitInfo}
  ${ErrorMessageInfo}
`;
