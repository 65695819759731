import { gql } from '@apollo/client';

export default gql`
  query manufacturerOrganizationsAdmin {
    manufacturerOrganizationsAdmin {
      id
      attributes
    }
  }
`;
