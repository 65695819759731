import { useQuery } from '@apollo/client';
import { GET_ORDERS_COUNT_BY_STATES } from '@fullcontour/shared-api';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { capitalizeFirstLetter, navNamings } from '../../../../../transforms';
import {
  CurrentUserContext,
  CustomerLocationContext,
  RouteContext,
} from '../../../Context';
import BoxIcon from '../../../Icons/BoxIcon';
import { BaseLoader } from '../../../Loader';
import MenuLabelQuery from './MenuLabel/MenuLabelQuery';
import './OrdersList.scss';
import PendingMenuItem from './PendingMenuItem/PendingMenuItem';

function OrdersNavMenu() {
  let { navMenuList } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const { customerLocation } = useContext(CustomerLocationContext);

  const { data, loading } = useQuery(GET_ORDERS_COUNT_BY_STATES, {
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation('navigation');
  const app = import.meta.env.VITE_APP_NAME;

  if (app === 'customer_portal' && data) {
    const {
      ordersCountByStates: {
        sentToManufacturer,
        atManufacturer,
        manufacturingHold,
        shipped,
      },
    } = data;
    if (
      !sentToManufacturer &&
      !atManufacturer &&
      !manufacturingHold &&
      !shipped
    ) {
      navMenuList = navMenuList.filter((item) => item.name !== 'manufacturing');
    }
  }

  if (loading && !data) {
    return <BaseLoader loading={loading} />;
  }
  return (
    <aside
      style={{ margin: 0, marginTop: '0.1em' }}
      className={`menu ordersListNav ${
        app === 'customer_portal' ? 'portal' : ''
      }`}
    >
      {app === 'customer_portal' &&
        currentUser?.scopeToLocation &&
        customerLocation.enableSimpleUploader &&
        !customerLocation.suspended &&
        !customerLocation.inactiveBilling && (
          <div
            className="is-flex is-align-content-center is-justify-content-center py-4"
            style={{ backgroundColor: '#F2F2F2' }}
          >
            <Link to="/orders/new" className="button is-primary is-small">
              <BoxIcon
                name="bx-plus-circle"
                className="FormSubmitButtonIcon mr-2"
              />
              {t('Create New Case')}
            </Link>
          </div>
        )}
      <ul className="menu-list">
        <li>
          <NavLink
            // activeClassName="is-active"
            to="/orders"
            className="allOrdersItem"
          >
            <BoxIcon name="bx-calendar bx-xs" />
            <span className="orderItemTitle">{t('All Cases')}</span>
          </NavLink>
        </li>

        {app === 'admin_portal' && currentUser?.scopeToLocation === false ? (
          <PendingMenuItem />
        ) : null}
      </ul>
      {navMenuList.map((menuItems, idx) => (
        <div
          key={menuItems.key}
          className={`${
            app === 'customer_portal' && idx === navMenuList.length - 1
              ? 'portal'
              : ''
          }`}
        >
          <p className="menu-label">
            <BoxIcon name={`${menuItems.icon} bx-xs`} />
            {t(menuItems.name)}
          </p>
          <ul className="menu-list">
            {menuItems.items.map((item) => {
              let ordersCount = 0;
              if (data) {
                ordersCount = data.ordersCountByStates[navNamings[item.symbol]];
              }

              return (
                <li key={item.key}>
                  <NavLink
                    // activeClassName="is-active"
                    to={{ pathname: item.to }}
                    className={item.labelClass}
                    id={item.symbol}
                  >
                    <MenuLabelQuery
                      classProp={item.labelClass}
                      status={item.symbol}
                      label={t(item.label)}
                      ordersCount={ordersCount}
                    />
                    <span
                      className={ordersCount ? `${item.labelClass} bold` : ''}
                    >
                      {t(capitalizeFirstLetter(item.label))}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </aside>
  );
}

export default OrdersNavMenu;
