import { gql } from '@apollo/client';
import { BusinessUnitInfo } from '../fragments';

export default gql`
  query businessUnit($id: ID, $slug: String) {
    businessUnit(id: $id, slug: $slug) {
      ...BusinessUnitInfo
    }
  }
  ${BusinessUnitInfo}
`;
