import { useMutation } from '@apollo/client';
import { ACTION_GENERATE_REPORT } from '@fullcontour/shared-api';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LoadingOrError, withFormWrap } from '../../../shared';
import { initialValues, schema } from './helpers';
import ReportFormInputs from './ReportFormInputs';
import Results from './Results';

function ReportForm(props) {
  const { setErrorMessages, clearErrorMessages } = props;

  const [results, setResults] = useState(null);
  const [newReportValues, setNewReportValues] = useState({});

  const [create, { loading, error }] = useMutation(ACTION_GENERATE_REPORT, {
    onCompleted: (d) => {
      const { actionReportGenerate } = d;
      if (actionReportGenerate.errorMessages.length) {
        setErrorMessages(actionReportGenerate.errorMessages);
      } else {
        setResults(actionReportGenerate.results);
        clearErrorMessages();
      }
    },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Report" />;
  }

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schema()}
      enableReinitialize
      onSubmit={(values) => {
        const variables = {
          variables: { input: { input: values, clientMutationId: uuidv4() } },
        };

        create(variables);
        setNewReportValues(values);
      }}
    >
      {(formikProps) => (
        <>
          {results ? (
            <Results
              results={results}
              setResults={setResults}
              formikProps={formikProps}
              newReportValues={newReportValues}
            />
          ) : (
            <ReportFormInputs formikProps={formikProps} />
          )}
          <span />
        </>
      )}
    </Formik>
  );
}

ReportForm.propTypes = {
  setErrorMessages: PropTypes.func.isRequired,
  clearErrorMessages: PropTypes.func.isRequired,
};

export default withFormWrap({ initialValues, schema })(ReportForm);
