/* eslint-disable no-restricted-syntax */
const allTeeth = Array(32).fill(null);

const transformValue = (type, value) => {
  const absVal = Math.abs(value).toFixed(2);
  switch (type) {
    case 'Angulation':
    case 'LeftRight':
      return `${absVal}${value < 0 ? 'D' : 'M'}`;
    case 'Inclination':
      return `${absVal}${value < 0 ? 'L' : 'B'}`;
    case 'ForwardBackward':
      return `${absVal}${value < 0 ? 'L' : 'B'}`;
    case 'IntrusionExtrusion':
      return `${absVal}${value < 0 ? 'E' : 'I'}`;
    case 'Rotation':
      return `${absVal}${value < 0 ? 'M' : 'D'}`;
    default:
      return absVal;
  }
};

const bodyTableDataGenerate = (allTeethData) =>
  allTeethData.reduce(
    (obj, item) => {
      for (const key in obj) {
        if (item) {
          // eslint-disable-next-line no-param-reassign
          obj[key][item.Unn - 1] = Object.keys(item).length
            ? transformValue(
                key,
                item.Movement?.Value?.MovementValues?.Value
                  ? item.Movement.Value.MovementValues.Value[key]
                  : 0,
              )
            : null;
        }
      }
      return obj;
    },
    {
      Inclination: [...allTeeth],
      Angulation: [...allTeeth],
      Rotation: [...allTeeth],
      LeftRight: [...allTeeth],
      ForwardBackward: [...allTeeth],
      IntrusionExtrusion: [...allTeeth],
    },
  );

export default bodyTableDataGenerate;
