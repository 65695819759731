import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    customInstructions: Yup.string(),
    designProfiles: Yup.array(),
    designTypeInstructions: Yup.array().of(
      Yup.object({
        instruction: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
      }),
    ),
  });
}

export default schema;
