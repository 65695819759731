import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionDesignUserActivate($input: DesignUserActivateActionInput!) {
    actionDesignUserActivate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      user {
        id
        state
      }
    }
  }
  ${ErrorMessageInfo}
`;
