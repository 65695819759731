import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import { ServerErrors, withFormWrap } from '../../../../shared';
import { initialValues, schema, update } from './helpers';
import { useApolloClient } from '@apollo/client';

function BillingInvoiceMemoFormModal({
  modalOpen,
  closeModal,
  errorMessages,
  initialValues,
  schema,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  selectedItem,
}) {
  const client = useApolloClient();
  const resetAndCloseModal = (resetForm) => {
    resetForm();
    closeModal();
  };
  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    update({
      variables,
      client,
      setErrorMessages,
      clearErrorMessages,
      refetch,
      closeModal,
    });
    setSubmitting(false);
  }
  return (
    <Formik
      initialValues={initialValues({ selectedItem })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit, resetForm }) => (
        <form onSubmit={onSubmit} noValidate>
          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <h4 className="title is-5 mb-0 ml-4 modal-card-title">
                  Update Memo
                </h4>
              </header>
              <section className="modal-card-body">
                <div className="field">
                  <div className="control">
                    <Field
                      id="memo"
                      name="memo"
                      component="textarea"
                      rows="4"
                      required
                      placeholder="Memo"
                      className="textarea"
                    />
                  </div>
                </div>
                <ServerErrors errorMessages={errorMessages} />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  className="button is-dark"
                  type="button"
                  onClick={() => {
                    resetAndCloseModal(resetForm);
                  }}
                >
                  Close
                </button>
                <button className="button is-primary" type="submit">
                  Update
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

BillingInvoiceMemoFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  initialValues: PropTypes.func.isRequired,
  schema: PropTypes.func.isRequired,
  setErrorMessages: PropTypes.func.isRequired,
  clearErrorMessages: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  BillingInvoiceMemoFormModal,
);
