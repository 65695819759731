import { useParams } from 'react-router';
import Policy from './Policy';

function getPolicy(params) {
  if (params && params.type === 'privacy-policy') {
    return '3Shape Design Service Privacy Policy';
  }

  if (params && params.type === 'baa') {
    return '3Shape Design Service Business Associate Agreement';
  }

  if (params && params.type === 'smilesummary-terms') {
    return 'SmileSummary Terms and Conditions';
  }

  return '3Shape Design Service Terms and Conditions';
}

function LegalPage() {
  const params = useParams();

  const policy = getPolicy(params);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h1 className="title is-1">{policy}</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column" style={{ paddingBottom: 100 }}>
          <Policy policy={policy} />
        </div>
      </div>
    </div>
  );
}

export default LegalPage;
