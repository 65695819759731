import PropTypes from 'prop-types';

function OrderShowMessageUserImg({
  systemMessage,
  logoUrl,
  name = '',
  messageableType = null,
}) {
  return (
    <img
      src={
        systemMessage || messageableType !== 'User'
          ? 'https://cdn.fullcontour.com/logos/3DS-icon.png'
          : logoUrl
      }
      alt={name}
      style={{
        width: '120%',
        height: '100%',
        minWidth: 48,
      }}
    />
  );
}

OrderShowMessageUserImg.propTypes = {
  systemMessage: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string.isRequired,
  name: PropTypes.string,
  messageableType: PropTypes.string,
};

export default OrderShowMessageUserImg;
