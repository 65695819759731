import { gql } from '@apollo/client';

const OrderApprovalLogInfo = gql`
  fragment OrderApprovalLogInfo on OrderApprovalLog {
    id
    userId
    orderId
    userAcceptedAt
    userIp
    userName
    doctorApproval
    doctorName
    doctorAcceptedAt
    doctorEmail
    userRejected
    doctorRejected
    accessToken
    dentalNotation
  }
`;

export default OrderApprovalLogInfo;
