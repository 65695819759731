import PropTypes from 'prop-types';
import { useState } from 'react';

import LocationCustomerDesignPreferencesFieldArray from './LocationCustomerDesignPreferencesFieldArray';

function LocationCustomerDesignPreferencesForm({ data = {}, ...rest }) {
  const props = { data, ...rest };
  const [edit, setEdit] = useState(false);

  return (
    <div className="columns">
      <div className="column is-9">
        <LocationCustomerDesignPreferencesFieldArray
          edit={edit}
          toggleEdit={() => setEdit((prevState) => !prevState)}
          {...props}
        />
      </div>
    </div>
  );
}

LocationCustomerDesignPreferencesForm.propTypes = {
  data: PropTypes.object,
};

export default LocationCustomerDesignPreferencesForm;
