import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { useState } from 'react';
import Measure from 'react-measure';
import './Slider.css';
import SliderCard from './SliderCard';
import SliderContainer from './SliderContainer';
import TouchSlider from './TouchSlider';

function Slider(props) {
  const {
    sliderVal,
    changeSlider,
    stages,
    disabled,
    triggerIncrement,
    setTriggerIncrementOff,
    setTriggerIncrementOn,
    autoplay,
  } = props;

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  function onRest(_index, modIndex) {
    changeSlider({ target: { value: Math.abs(modIndex) } });
  }

  return stages.length > 2 ? (
    <>
      {!disabled && (
        <Measure
          bounds
          onResize={(contentRect) => {
            setDimensions(contentRect.bounds);
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className={`enhancedSliderWrap ${
                disabled ? 'enhancedSliderDisabled' : ''
              }`}
            >
              <TouchSlider
                cardCount={stages.length}
                cardSize={40}
                stages={stages}
                component={SliderContainer}
                dimensions={dimensions}
                renderCard={SliderCard}
                onRest={onRest}
                sliderVal={sliderVal}
                autoplay={autoplay}
                changeSlider={changeSlider}
                triggerIncrement={triggerIncrement}
                setTriggerIncrementOff={setTriggerIncrementOff}
                setTriggerIncrementOn={setTriggerIncrementOn}
              />
              <div className="sliderRecticle" />
            </div>
          )}
        </Measure>
      )}
      {disabled && (
        <div
          className="sliderLoading"
          style={{ color: 'rgba(255, 255, 255, 0.54)' }}
        >
          Loading Steps...
        </div>
      )}
    </>
  ) : null;
}

Slider.propTypes = exact({
  sliderVal: PropTypes.number.isRequired,
  changeSlider: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  triggerIncrement: PropTypes.bool.isRequired,
  setTriggerIncrementOff: PropTypes.func.isRequired,
  setTriggerIncrementOn: PropTypes.func.isRequired,
  autoplay: PropTypes.number.isRequired,
});

export default Slider;
