import { gql } from '@apollo/client';

export default gql`
  mutation destroyDesignLocation($input: DesignLocationDestroyInput!) {
    destroyDesignLocation(input: $input) {
      clientMutationId
      location {
        id
      }
    }
  }
`;
