import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../transforms';

function orderDueTimeRenderer({ value }) {
  const dueTime = Date.parse(value);
  return (
    <div style={{ color: Date.now() > dueTime ? '#E40000' : 'inherit' }}>
      {timestampFormatter(value)}
    </div>
  );
}

orderDueTimeRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export default orderDueTimeRenderer;
