import PropTypes from 'prop-types';
import { useState, useEffect, memo } from 'react';
import MenuLabelCount from './MenuLabelCount';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

function MenuLabelQuery({ classProp = null, ordersCount = 0 }) {
  const [{ appActive }, setState] = useState({
    appActive: true,
  });
  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);

  function handleVisibilityChange() {
    if (document[hidden]) {
      setState((state) => ({ ...state, appActive: false }));
    } else {
      setState((state) => ({ ...state, appActive: true }));
    }
  }

  return (
    <MenuLabelCount
      appActive={appActive}
      classProp={classProp}
      ordersCount={ordersCount}
    />
  );
}

MenuLabelQuery.propTypes = {
  ordersCount: PropTypes.number,
  classProp: PropTypes.string,
};

export default memo(MenuLabelQuery);
