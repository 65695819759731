import { DebitsListQueryWrapper, useDebit } from '../../Debits/DebitsList';

function BillingDebits(props) {
  const {
    selectedItem,
    action,
    debit,
    closeModal,
    openModal,
    destroyDebit,
    statusUpdate,
    onSelectionChanged,
    modalState,
    setSelectedItem,
  } = useDebit();

  return (
    <DebitsListQueryWrapper
      {...props}
      onSelectionChanged={onSelectionChanged}
      openModal={openModal}
      selectedItem={selectedItem}
      action={action}
      modalOpen={modalState}
      debit={debit}
      closeModal={closeModal}
      destroyDebit={destroyDebit}
      statusUpdate={statusUpdate}
      setSelectedItem={setSelectedItem}
    />
  );
}

export default BillingDebits;
