import PropTypes from 'prop-types';
import PreferenceSection from './PreferenceSection';
import Tooth from './Tooth';

function PreferenceTypeTeeth(props) {
  const { prescription } = props;

  const teeth = (type) => {
    let toothDataType = [];

    if (
      type === 'upper' &&
      prescription &&
      prescription.UpperJawPrescription &&
      prescription.UpperJawPrescription.ToothRestrictions
    ) {
      toothDataType = prescription.UpperJawPrescription.ToothRestrictions;
    }

    if (
      type !== 'upper' &&
      prescription &&
      prescription.LowerJawPrescription &&
      prescription.LowerJawPrescription.ToothRestrictions
    ) {
      toothDataType = prescription.LowerJawPrescription.ToothRestrictions;
    }

    const children = [];

    if (type === 'upper') {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 16; index++) {
        children.push(
          <Tooth
            toothNumber={index + 1}
            toothData={toothDataType.find((pres) => pres.Unn === index + 1)}
            key={index}
            type={type}
          />,
        );
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let index = 31; index > 15; index--) {
        children.push(
          <Tooth
            toothNumber={index + 1}
            toothData={toothDataType.find((pres) => pres.Unn === index + 1)}
            key={index}
            type={type}
          />,
        );
      }
    }

    return children;
  };

  return (
    <PreferenceSection title="Teeth Restrictions">
      <div className="toothRestrictions">
        <div>
          <div
            className="restrictionsUpperArch"
            style={{ marginBottom: '1em' }}
          >
            {teeth('upper')}
          </div>
          <div
            className="restrictionsLowerArch"
            style={{ marginBottom: '2em' }}
          >
            {teeth('lower')}
          </div>
        </div>
        <div style={{ verticalAlign: 'top' }} className="legendWrap">
          <Tooth
            type="upper"
            isLegend
            label="Extract"
            toothData={{
              MayBeExtracted: true,
              MayPlaceAttachment: false,
              MayBeMoved: true,
              IsPresent: true,
              MayPerformIpr: false,
            }}
          />
          <Tooth
            type="upper"
            isLegend
            label="Missing"
            toothData={{
              MayBeExtracted: false,
              MayPlaceAttachment: false,
              MayBeMoved: true,
              IsPresent: false,
              MayPerformIpr: false,
            }}
          />
          <Tooth
            type="upper"
            isLegend
            label="Allow Attachment"
            toothData={{
              MayBeExtracted: false,
              MayPlaceAttachment: true,
              MayBeMoved: true,
              IsPresent: true,
              MayPerformIpr: false,
            }}
          />
          <Tooth
            type="upper"
            isLegend
            label="Allow IPR"
            toothData={{
              MayBeExtracted: false,
              MayPlaceAttachment: false,
              MayBeMoved: true,
              IsPresent: true,
              MayPerformIpr: true,
            }}
          />
          <Tooth
            type="upper"
            isLegend
            label="immovable"
            toothData={{
              MayBeExtracted: false,
              MayPlaceAttachment: false,
              MayBeMoved: false,
              IsPresent: true,
              MayPerformIpr: false,
            }}
          />
        </div>
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeTeeth.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeTeeth;
