import { CurrentUserContext, NavMenuLink } from '@fullcontour/common';
import { useContext } from 'react';
import { useParams } from 'react-router';

function LocationShowNavMenu() {
  const { slug } = useParams();
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      <NavMenuLink
        to={currentUser?.scopeToLocation ? `/locations` : `/locations/${slug}`}
        name="details"
      />
      <NavMenuLink
        to={
          currentUser?.scopeToLocation
            ? `/locations/billing`
            : `/locations/${slug}/billing`
        }
        name="billing details"
      />
      <NavMenuLink
        to={
          currentUser?.scopeToLocation
            ? `/locations/integrations`
            : `/locations/${slug}/integrations`
        }
        name="integrations"
      />
      <NavMenuLink
        to={
          currentUser?.scopeToLocation
            ? `/locations/price-list`
            : `/locations/${slug}/price-list`
        }
        name="price list"
      />
      <NavMenuLink
        to={
          currentUser?.scopeToLocation
            ? `/locations/api-settings`
            : `/locations/${slug}/api-settings`
        }
        name="api settings"
      />
      <NavMenuLink
        to={
          currentUser?.scopeToLocation
            ? `/locations/design-profile`
            : `/locations/${slug}/design-profile`
        }
        name="design profile"
      />
    </>
  );
}

export default LocationShowNavMenu;
