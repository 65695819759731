import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionCurrentUserDeactivateHelp(
    $input: CurrentUserDeactivateHelpActionInput!
  ) {
    actionCurrentUserDeactivateHelp(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      currentUser {
        id
      }
    }
  }
  ${ErrorMessageInfo}
`;
