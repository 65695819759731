import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import './SearchHelp.css';

function SearchHelp({ setDisplayedData }) {
  const fetchData = async (params) => {
    try {
      const query = params
        ? `https://fullcontour.zendesk.com/api/v2/help_center/articles/search?query=${params}`
        : 'https://fullcontour.zendesk.com/api/v2/help_center/en-us/articles';
      const response = await fetch(query);
      const result = await response.json();
      setDisplayedData({
        data: params ? result.results : result.articles,
        error: null,
        value: params,
      });
    } catch (e) {
      setDisplayedData({ data: [], error: e, params });
    }

    return null;
  };

  useEffect(() => {
    fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      fetchData(criteria);
    }, 300),
  ).current;

  useEffect(() => {
    debouncedSearch.cancel();
  }, [debouncedSearch]);

  function handleChange(e) {
    debouncedSearch(e.target.value);
  }
  return (
    <div className="control has-icons-right mx-3">
      <input
        className="input is-rounded search__wrapper help-center__bar-search"
        type="text"
        onChange={handleChange}
        placeholder="Search..."
      />
      <span className="icon is-right">
        <i className="bx bx-search" />
      </span>
    </div>
  );
}

SearchHelp.propTypes = {
  setDisplayedData: PropTypes.func.isRequired,
};

export default SearchHelp;
