import { gql } from '@apollo/client';
import { GuideBookInfo } from '../fragments';

export default gql`
  query guideBooks {
    guideBooks {
      ...GuideBookInfo
    }
  }
  ${GuideBookInfo}
`;
