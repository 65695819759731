import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionCurrentUserActivate($input: CurrentUserActivateActionInput!) {
    actionCurrentUserActivate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      currentUser {
        id
      }
    }
  }
  ${ErrorMessageInfo}
`;
