import ReportsListNavMenu from '@fullcontour/common/src/components/shared/NavMenus/Reports/ReportsListNavMenu';

const routes = [
  {
    path: '/reports',
    component: ReportsListNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
