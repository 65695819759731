import { gql } from '@apollo/client';

export default gql`
  query integrationTypes {
    integrationTypes {
      id
      name
      description
      symbol
      klass
    }
  }
`;
