import { useQuery } from '@apollo/client';
import {
  ACTION_GUIDE_BOOK_CLONE,
  GET_GUIDE_BOOKS_DROPDOWN,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloneModal from './CloneModal/CloneModal';
import Dropdown from './Dropdown';

function GuideBookCloneDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
}) {
  const { t } = useTranslation('formfields');

  const [showAll, setShowAll] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState(null);
  const [firstLaunch, setFirstLaunch] = useState(true);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  const filterElements = (dta, show) => {
    if (dta && show) {
      const parentIds = dta?.guideBooks?.reduce((obj, item) => {
        if (item.parentGuideBookId) {
          // eslint-disable-next-line no-param-reassign
          obj[item.parentGuideBookId] = true;
        }
        return obj;
      }, {});
      const dataWithNamings = dta.guideBooks?.map((item) => {
        if (parentIds[item.id]) {
          return {
            ...item,
            name: `${item.name} - Master List`,
          };
        }
        if (item.parentGuideBookId) {
          return {
            ...item,
            name: `${item.name} - Child List`,
          };
        }
        return item;
      });
      setUpdatedOptions(dataWithNamings);
    } else {
      setUpdatedOptions(
        dta?.guideBooks
          .filter((item) => !item.parentGuideBookId || item.id === value)
          .map((item) => ({
            ...item,
            name:
              item.parentGuideBookId && item.id === value
                ? `${item.name} - Child List`
                : item.name,
          })),
      );
    }
  };

  const { error, loading, data, refetch, networkStatus } = useQuery(
    GET_GUIDE_BOOKS_DROPDOWN,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted(d) {
        filterElements(d, !firstLaunch);
        setFirstLaunch(false);
        if (!firstLaunch) {
          setShowAll(true);
        }
      },
    },
  );

  const handleShowAll = (e) => {
    e.preventDefault();
    setShowAll((prev) => !prev);
    filterElements(data, !showAll);
  };

  return (
    <div className="field has-addons is-grouped">
      <div className="control is-expanded">
        <Dropdown
          name={name}
          label="Design Guide Profile"
          error={error}
          errors={!!(errors[name] && touched[name])}
          loading={loading || networkStatus === 4}
          options={loading ? [] : updatedOptions || null}
          onChange={handleChange}
          placeholder={t('Select a Design Guide Profile...')}
          value={value}
          required
          disabled={loading}
        />
      </div>
      <div className="control is-align-self-flex-end">
        <button
          type="button"
          className={`button ${showAll && 'is-success'} ml-3`}
          onClick={handleShowAll}
        >
          {showAll ? 'Masters' : 'Show All'}
        </button>
      </div>
      <CloneModal
        cloneMutation={ACTION_GUIDE_BOOK_CLONE}
        mutationDataName="actionGuideBookClone"
        dataName="guideBook"
        valueToClone={value}
        options={data?.guideBooks || []}
        cloneName="Guide Book"
        refetch={refetch}
        setParentFieldValue={setFieldValue}
        fieldName={name}
        loading={loading}
        showClone={value.id !== '' && value.id !== null}
      />
    </div>
  );
}

GuideBookCloneDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(GuideBookCloneDropdown);
