/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useState } from 'react';

function NewTabs({ children, style = {} }) {
  const [activeTab, setActiveTab] = useState(children[0].props.id);
  const [activeChild, setActiveChild] = useState(children[0]);

  const handleTabClick = (id) => {
    const child = children.find((c) => c.props.id === id);

    setActiveTab(id);
    setActiveChild(child);
  };

  return (
    <div
      className="mt-4"
      style={{
        ...style,
        height: '100%',
        width: '100%',
      }}
    >
      <div className="tabs is-boxed mb-0">
        <ul>
          {children.map((child) => (
            <li
              id={child.props.id}
              key={(Math.random() + 1).toString()}
              className={activeTab === child.props.id ? 'is-active' : ''}
            >
              <a
                id={child.props.id}
                aria-hidden="true"
                onClick={(e) => handleTabClick(e.target.id)}
              >
                {child.props.id}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="box">{activeChild}</div>
    </div>
  );
}

NewTabs.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default NewTabs;
