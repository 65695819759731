/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import DesktopProfileLinks from './DesktopProfileLinks';

function PrimaryNavigation(props) {
  const { children } = props;
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav
      role="navigation"
      aria-label="main navigation"
      className="navbar is-fixed-top fc-primary-nav"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img
            src="https://cdn.fullcontour.com/logos/3ShapeDesignServiceLogoGrey.svg"
            alt="3Shape design services logo"
            className="mainLogo"
          />
        </Link>
        <a
          role="button"
          className={`navbar-burger ${toggleMenu && 'is-active'}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="fcNavbar"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div id="fcNavbar" className={`navbar-menu ${toggleMenu && 'is-active'}`}>
        <div className="navbar-start">{children}</div>
        <div className="navbar-end">
          <DesktopProfileLinks setToggleMenu={setToggleMenu} />
        </div>
      </div>
    </nav>
  );
}

PrimaryNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(PrimaryNavigation);
