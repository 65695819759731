import { gql } from '@apollo/client';

const OrderInfoListAdmin = gql`
  fragment OrderInfoListAdmin on Order {
    id
    createdAt
    hexOrderCode
    intOrderId
    labLocationTier
    isRush
    name
    percentComplete
    requiresDesignApproval
    softwareVersion
    feedbacks {
      id
      userId
      redesignCount
      redesignReason
      rating
      answers {
        orderItem {
          id
          designTypeName
          designTypeId
        }
        id
        rating
        options {
          id
          name
          category {
            name
            id
          }
        }
      }
    }
    state
    itemsMetadata
    designCompletionTime
    reassignedAt
    instructions
    turnaroundTimeName
    designSiloName
    designerTags
    designerState
    redesignCount
    location {
      id
      name
      slug
      code
      subType
      fullViewer
      numberingSystem
      pdfAllMovementPerStage
      doctorApprovalLight
      customerTier
    }
  }
`;

export default OrderInfoListAdmin;
