import { gql } from '@apollo/client';
import { CreditInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCredit($input: CreditCreateInput!) {
    createCredit(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      credit {
        ...CreditInfo
      }
    }
  }
  ${CreditInfo}
  ${ErrorMessageInfo}
`;
