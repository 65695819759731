import { ShowDetails } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import ShowHeader from './ShowHeader';

function ShowContainer({
  title,
  editLink,
  leftContent = null,
  rightContent = null,
  createdAt = null,
  updatedAt = null,
  subHeader = null,
  subHeaderLabel = null,
  children = null,
  logoUrl = null,
  code = null,
  customerTier = null,
  ...rest
}) {
  return (
    <>
      <ShowHeader
        title={title}
        code={code}
        logoUrl={logoUrl}
        editLink={editLink}
        leftContent={leftContent}
        rightContent={rightContent}
        customerTier={customerTier}
        {...rest}
      />
      {(subHeaderLabel || subHeader) && (
        <div className="box">
          <ShowDetails
            createdAt={createdAt}
            updatedAt={updatedAt}
            subHeaderLabel={subHeaderLabel}
            subHeader={subHeader}
          />
          <div className={`${children ? 'mt-2' : ''}`}>{children}</div>
        </div>
      )}
    </>
  );
}

ShowContainer.propTypes = {
  title: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  subHeader: PropTypes.node,
  subHeaderLabel: PropTypes.string,
  children: PropTypes.node,
  logoUrl: PropTypes.string,
  code: PropTypes.string,
  customerTier: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default memo(ShowContainer);
