import PropTypes from 'prop-types';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetaDataUnits({ orderItem }) {
  return (
    <FieldMetadataWrapper title="Units">{orderItem.units}</FieldMetadataWrapper>
  );
}

FieldMetaDataUnits.propTypes = {
  orderItem: PropTypes.object.isRequired,
};

export default FieldMetaDataUnits;
