import { gql } from '@apollo/client';

export default gql`
  query orderMessages($id: ID!) {
    orderMessages(id: $id) {
      id
      imageUrl
      images {
        createdAt
        id
        imageUrl
        updatedAt
      }
      userName
      message
      holdMessage
      redesignCount
      activity
      orderMessageableType
      fileType
      s3Id
      createdAt
      systemMessage
    }

    order(id: $id) {
      id
      state
    }
  }
`;
