import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createGuideCustomCode($input: GuideCustomCodeCreateInput!) {
    createGuideCustomCode(input: $input) {
      clientMutationId
      guideCustomCode {
        guideCategoryId
        guideCategory {
          guideCategoryGroupId
        }
        id
        labLocationId
        name
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
