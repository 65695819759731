import { gql } from '@apollo/client';
import { InternalRejectionReasonInfo } from '../fragments';

export default gql`
  query internalRejectionReasons {
    internalRejectionReasons {
      ...InternalRejectionReasonInfo
    }
  }
  ${InternalRejectionReasonInfo}
`;
