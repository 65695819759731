import { gql } from '@apollo/client';

export default gql`
  query designTypeCategories($name: String) {
    designTypeCategories(name: $name) {
      id
      name
      slug
      symbol
      key
      value
      businessUnit {
        id
        name
        slug
      }
    }
  }
`;
