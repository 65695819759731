const downloadMarkInProgress = {
  admin_portal: {
    visible: true,
    statuses: {
      sent_for_design: [0, 10, 20, 30, 40],
      rush_design: [0, 10, 20, 30, 40],
      hold_released: [0, 10, 20, 30, 40],
      redesign: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: true,
    statuses: {
      sent_for_design: [0, 5, 10, 20],
      rush_design: [0, 5, 10, 20],
      hold_released: [0, 5, 10, 20],
      redesign: [0, 5, 10, 20],
    },
  },
};

export default downloadMarkInProgress;
