import PropTypes from 'prop-types';
import { Placeholder } from '../../../../../../shared';
import HeaderInfoId from '../HeaderInfoId';
import HeaderInfoOrderMetadata from '../HeaderInfoOrderMetadata';

function HeaderOrderInfo({
  isLoading = false,
  order = null,
  isSubscribed = false,
}) {
  return !isLoading ? (
    <div>
      <HeaderInfoId
        hexOrderCode={order.hexOrderCode}
        redesignCount={order.redesignCount}
        isSubscribed={isSubscribed}
      />
      <HeaderInfoOrderMetadata
        name={order.name}
        location={order.location}
        designSilo={order.designSilo}
        labLocationTier={order.labLocationTier}
      />
    </div>
  ) : (
    <>
      <div style={{ width: 200 }}>
        <Placeholder className="mb-3" />
        <Placeholder width="70%" />
      </div>

      <Placeholder width="30%" />
    </>
  );
}

HeaderOrderInfo.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  isSubscribed: PropTypes.bool,
};

export default HeaderOrderInfo;
