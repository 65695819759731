function initialValues(props) {
  const { orderId } = props;
  return {
    message: '',
    orderId,
    imageUrl: '',
    fileType: '',
    s3Id: '',
  };
}

export default initialValues;
