import moment from 'moment';
import PropTypes from 'prop-types';

import './BillingContact.css';

function BillingContact({
  item: { name, title, createdAt, phone, email } = { phone: null },
  showName = false,
}) {
  return (
    <p className="is-size-6 m-0 p-0">
      {showName && (
        <>
          <span className="contact-title">{`${name},  ${title}`}</span>
          <br />
        </>
      )}
      <span className="is-size-7">{email}</span>
      {phone ? (
        <>
          {' '}
          | <span className="is-size-7">{phone}</span>
        </>
      ) : null}
      |{' '}
      <span className="is-size-7">
        Created {moment(createdAt).format('DD/MM/YYYY')}
      </span>
    </p>
  );
}

BillingContact.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
  }),
  showName: PropTypes.bool,
};

export default BillingContact;
