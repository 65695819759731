import { gql } from '@apollo/client';
import { ErrorMessageInfo, SignupTemplateInfo } from '../fragments';

export default gql`
  mutation updateSignupTemplate($input: SignupTemplateUpdateInput!) {
    updateSignupTemplate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      signupTemplate {
        ...SignupTemplateInfo
      }
    }
  }
  ${SignupTemplateInfo}
  ${ErrorMessageInfo}
`;
