import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SearchField({ searchValue = {}, setSearchValue }) {
  const { t } = useTranslation('common');

  return (
    <p className="control has-icons-right mb-5" style={{ width: 300 }}>
      <input
        className="input"
        type="text"
        value={searchValue}
        style={{ borderRadius: 100 }}
        placeholder={t('Search...')}
        onChange={(e) => setSearchValue(e.target.value.toLocaleLowerCase())}
      />

      <span className="icon is-small is-right">
        <i className="bx bx-search" />
      </span>
    </p>
  );
}

SearchField.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
};

export default SearchField;
