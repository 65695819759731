import { gql } from '@apollo/client';
import { DesignUserInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateDesignUser($input: DesignUserUpdateInput!) {
    updateDesignUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designUser {
        ...DesignUserInfo
      }
    }
  }
  ${DesignUserInfo}
  ${ErrorMessageInfo}
`;
