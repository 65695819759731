import { gql } from '@apollo/client';

export default gql`
  mutation destroyTempOrder($input: TempOrderDestroyInput!) {
    destroyTempOrder(input: $input) {
      clientMutationId
      tempOrder {
        id
      }
    }
  }
`;
