import { gql } from '@apollo/client';
import { CreditCardInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCreditCard($input: CreditCardUpdateInput!) {
    updateCreditCard(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      creditCard {
        ...CreditCardInfo
      }
    }
  }
  ${CreditCardInfo}
  ${ErrorMessageInfo}
`;
