import PropTypes from 'prop-types';

function customerTierRenderer({ value }) {
  if (!value) {
    return null;
  }

  return <div className={`tag location-tier-color-${value}`}>{value}</div>;
}

customerTierRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export default customerTierRenderer;
