import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateQuotaProfile($input: QuotaProfileUpdateInput!) {
    updateQuotaProfile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      quotaProfile {
        id
        createdAt
        updatedAt
      }
    }
  }
  ${ErrorMessageInfo}
`;
