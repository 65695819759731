/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CurrentUserContext } from '../..';
import DesignUserModalDesignInner from './DesignUserModalDesignInner';
import DesignUserModalInner from './DesignUserModalInner';

function DesignUserSetPassword({ userId, labelClasses = '' }) {
  const { t } = useTranslation('showelements');
  const { currentUser } = useContext(CurrentUserContext);
  const { type } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const propsData = {
    modalOpen,
    setModalOpen,
    userId,
  };

  const updatePassword = currentUser.roleLevel === 0 || type === 'design';

  return (
    <>
      <div
        className={`dropdown-item has-text-black-ter is-size-6 is-clickable ${labelClasses}`}
        onClick={() => setModalOpen(true)}
        aria-hidden="true"
      >
        {t(
          `${
            updatePassword ? 'Update Password' : 'Send password reset e-mail'
          }`,
        )}
      </div>
      {updatePassword ? (
        <DesignUserModalDesignInner {...propsData} />
      ) : (
        <DesignUserModalInner {...propsData} />
      )}
    </>
  );
}

DesignUserSetPassword.propTypes = {
  userId: PropTypes.string.isRequired,
  labelClasses: PropTypes.string,
};

export default DesignUserSetPassword;
