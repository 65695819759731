function TempOrderCasesHeader() {
  return (
    <div
      className="columns has-background-dark has-text-white"
      style={{ paddingRight: 10 }}
    >
      <div className="column is-2">Case Attributes</div>
      <div className="column">Case Instructions</div>
      <div className="column" style={{ display: 'flex', flex: 'none' }}>
        Actions
      </div>
    </div>
  );
}

export default TempOrderCasesHeader;
