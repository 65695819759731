import { gql } from '@apollo/client';

export default gql`
  query customerLocations($hasCadFiles: Boolean) {
    customerLocations(hasCadFiles: $hasCadFiles) {
      id
      name
      code
      slug
      organization {
        id
        slug
        name
      }
      cadFiles {
        id
        fileUrl
        s3Key
        filename
        createdAt
      }
    }
  }
`;
