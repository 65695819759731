import {
  OverpanelSubmitContext,
  OverpanelUploadContext,
} from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import CaseActionsButtonAddItem from './CaseActionsButtonAddItem';
import CaseActionsButtonCancel from './CaseActionsButtonCancel';
import CaseActionsButtonRemoveItem from './CaseActionsButtonRemoveItem';
import CaseActionsButtonResubmit from './CaseActionsButtonResubmit';
import CaseActionsButtonSubmit from './CaseActionsButtonSubmit';

function CaseActionsButtons(props) {
  const {
    parsedCase,
    fileIndex,
    arrayHelpers,
    submit,
    cancelSubmit,
    triggerSubmit,
    signAndSubmit,
  } = props;

  const {
    metaData: { isParsed },
  } = parsedCase;

  const { submitAll } = useContext(OverpanelSubmitContext);
  const { uploading, uploadPercentage } = useContext(OverpanelUploadContext);

  return (
    <div className="is-flex is-centered is-flex-direction-column">
      <CaseActionsButtonSubmit
        uploading={uploading}
        uploadPercentage={uploadPercentage}
        parsedCase={parsedCase}
        triggerSubmit={triggerSubmit}
        cancelSubmit={cancelSubmit}
        submitAll={submitAll}
        submit={submit}
        fileIndex={fileIndex}
      />

      <CaseActionsButtonCancel cancelSubmit={cancelSubmit} submit={submit} />
      <CaseActionsButtonAddItem
        arrayHelpers={arrayHelpers}
        isParsed={isParsed}
        submit={submit}
      />
      <CaseActionsButtonRemoveItem fileIndex={fileIndex} />
      <CaseActionsButtonResubmit signAndSubmit={signAndSubmit} />
    </div>
  );
}

CaseActionsButtons.propTypes = {
  parsedCase: PropTypes.shape({
    metaData: PropTypes.shape({
      isParsed: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired, // Passed
  arrayHelpers: PropTypes.object.isRequired, // Passed
  fileIndex: PropTypes.number.isRequired, // Passed
  submit: PropTypes.bool.isRequired, // Passed
  triggerSubmit: PropTypes.func.isRequired, // Passed
  cancelSubmit: PropTypes.func.isRequired, // Passed
  signAndSubmit: PropTypes.func.isRequired,
};

export default CaseActionsButtons;
