import PropTypes from 'prop-types';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeInfo(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Additional Information">
      <div>
        <p>{prescription.AdditionalInformation}</p>
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeInfo.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeInfo;
