import { useState } from 'react';
import { HeaderInfo } from './HeaderInfo';

function OrderShowHeader(props) {
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [downloading, setDownloading] = useState(false);

  function updateDownloadProgress(percentage) {
    setDownloadPercentage(percentage);
  }

  return (
    <div
      style={{
        borderBottom: '1px solid #ddd',
        padding: '0 0.5em',
        width: '100%',
      }}
    >
      {downloadPercentage > 0 && downloading && (
        <progress
          value={downloadPercentage}
          className={`progress filesDownloadProgress is-small ${
            downloadPercentage === 100 ? 'is-success' : 'is-primary'
          }`}
        />
      )}
      <HeaderInfo
        {...props}
        setDownloading={() => setDownloading(true)}
        setDownloadingOff={() => setDownloading(false)}
        updateDownloadProgress={updateDownloadProgress}
        downloading={downloading}
        downloadPercentage={downloadPercentage}
      />
    </div>
  );
}

export default OrderShowHeader;
