import axios from 'axios';
import { createSignedLink } from '../components/shared';

async function latestOtpFile(files, redesignCount, client, extension) {
  const filteredFiles = files
    .filter((file) =>
      // eslint-disable-next-line no-underscore-dangle
      file.originalFileName?.endsWith(extension),
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const isFileMatchOrderCount = filteredFiles.find(
    (file) => file.redesignCount === redesignCount,
  );

  let fileFromRedesignVersion = [];

  if (isFileMatchOrderCount && extension === '.otp') {
    // if it does then we show the latest otpFile which matches to orders redesign count
    // eslint-disable-next-line prefer-destructuring
    fileFromRedesignVersion = isFileMatchOrderCount;
  } else {
    // otherwise find the latest redesign version of the file
    const highestRedesignCountAvailable = filteredFiles.reduce(
      (count, file) =>
        file.redesignCount > count ? file.redesignCount : count,
      0,
    );

    // and filter all filteredFiles according to last redesign count
    fileFromRedesignVersion = filteredFiles.filter(
      (file) => file.redesignCount === highestRedesignCountAvailable,
    );

    if (extension === '.otp' && fileFromRedesignVersion !== null) {
      // eslint-disable-next-line prefer-destructuring
      fileFromRedesignVersion = fileFromRedesignVersion[0];
    }
  }

  if (extension === '.otp') {
    const signedUrlData = await createSignedLink({
      client,
      foundFile: fileFromRedesignVersion,
    });

    try {
      const { data } = await axios.get(signedUrlData.signedUrl, {
        headers: {
          Accept: 'binary/octet-stream',
        },
      });

      return data?.Stages;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error fetching OTP Data', e);
    }
  }

  if (extension === '.pts') {
    let signedFiles = [];
    await Promise.all(
      fileFromRedesignVersion.map(async (file) => {
        const signedUrlData = await createSignedLink({
          client,
          foundFile: file,
        });

        const { data } = await axios.get(signedUrlData.signedUrl, {
          headers: {
            Accept: 'binary/octet-stream',
          },
        });

        if (data) {
          const rawFormattedData = data.split('\r\n').slice(1, -1).slice(0, -1);

          const formattedData = rawFormattedData.map((el) => {
            const lineItem = el.split(' ');

            const newLine = lineItem.map((i) => parseFloat(i));

            return [newLine[1], newLine[2], newLine[0]];
          });

          signedFiles = [...signedFiles, { file, data: formattedData }];
        }
      }),
    );

    return signedFiles;
  }

  return null;
}

export default latestOtpFile;
