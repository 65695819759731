import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsInvoicesCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    rowSelection: 'single',
    columnDefs: [
      {
        headerName: 'Details',
        marryChildren: true,
        children: [
          {
            field: 'hex_invoice_number',
            headerName: 'Invoice ID',
            minWidth: 120,
            sort: 'desc',
            filter: 'agTextColumnFilter',
            cellStyle: {
              textTransform: 'uppercase',
              color: '#1B77C8',
              cursor: 'pointer',
            },
            onCellClicked: (params) => {
              const link = `/organizations/customer/${params.data.lab_organization_slug}/billing/invoices/${params.data.hex_invoice_number}`;

              params.context.navigate(link);
            },
          },
          {
            field: 'lab_organization_name',
            headerName: 'Organization',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              params.context.navigate(
                `/organizations/customer/${params.data.lab_organization_slug}`,
              );
            },
          },
          {
            field: 'lab_location_name',
            headerName: 'Location',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              if (params.data.location.slug) {
                params.context.navigate(
                  `/locations/customer/${params.data.lab_location_slug}`,
                );
              }

              return null;
            },
          },
          {
            field: 'state',
            headerName: 'Status',
            minWidth: 120,
            filter: 'agSetColumnFilter',
            cellRenderer: ({ value }) => {
              let labelClass;
              switch (value) {
                case 'paid':
                  labelClass = 'tag is-success';
                  break;
                case 'error':
                  labelClass = 'tag is-danger';
                  break;
                case 'past_due':
                  labelClass = 'tag is-warning';
                  break;
                case 'unpaid':
                  labelClass = 'tag is-danger';
                  break;
                case 'voided':
                  labelClass = 'tag';
                  break;
                default:
                  labelClass = '';
                  break;
              }
              const labelStyle = {
                margin: '2% 5px',
                verticalAlign: 'middle',
                display: 'inline-block',
                textTransform: 'capitalize',
                minWidth: '80px',
                textAlign: 'center',
              };

              return (
                <span>
                  <div className={labelClass} style={labelStyle}>
                    {value}
                  </div>
                </span>
              );
            },
          },
          {
            field: 'memo',
            minWidth: 250,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        headerName: 'Dates',
        marryChildren: true,
        children: [
          {
            field: 'invoicing_date',
            headerName: 'Invoice Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'due_date',
            headerName: 'Due Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'date_paid',
            headerName: 'Date Paid',
            columnGroupShow: 'open',
            minWidth: 160,
            type: 'dateColumn',
          },
        ],
      },
      {
        headerName: 'Amounts',
        marryChildren: true,
        children: [
          {
            field: 'total_price',
            headerName: 'Invoice Amount',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'total_debits',
            headerName: 'Charges',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'total_credits',
            headerName: 'Credits',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'applied_payments',
            headerName: 'Payments',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'balance_due',
            headerName: 'Balance Due',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
        ],
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsInvoicesCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsInvoicesCustomer;
