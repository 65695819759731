import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  gridDateComparator,
  timestampFormatter,
} from '../../../../../transforms';
import { AgGridLoader, BoxIcon, ExpireComponent } from '../../../../shared';
import '../../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../../shared/AgGrid/ag-theme-balham-overrides.css';
import { FollowUpModalWrapper } from '../../shared/FollowUpModal';
import { ReviewModal } from '../../shared/ReviewModal';

function OrdersListGrid({
  gridOptions,
  onGridReady,
  onGridSizeChanged,
  onFirstDataRendered,
  onColumnMoved,
  onDisplayedColumnsChanged,
  onColumnVisible,
  onSelectionChanged,
  onColumnGroupOpened,
  onGridPreDestroyed,
  onColumnResized,
  loading,
  rowData = null,
  containerHeight = 'calc(100vh - 129px - 3em)',
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const { t } = useTranslation('aggrid');
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = () => {
    setModalOpen(false);
    setOrder(null);
  };

  const reviewCase = (data) => {
    setModalOpen(true);
    setOrder(data);
  };

  const getContextMenuItems = useCallback((params = {}) => {
    if (params?.value) {
      params.defaultItems.push({
        name: 'Open in new tab',
        icon: `<span class="ag-icon ag-icon-small-up" unselectable="on" role="presentation"></span>`,
        action() {
          window
            .open(
              `${window.location.origin}/orders/details/${params.value}`,
              '_blank',
            )
            ?.focus();
        },
      });
    }
    return params.defaultItems;
  }, []);

  const translateHeaders = useCallback(
    (headers) =>
      headers.map((header) => {
        const translatedHeader = {
          ...header,
          headerName: t(header.headerName),
        };
        if (header.children) {
          translatedHeader.children = translateHeaders(header.children);
        }
        return translatedHeader;
      }),
    [t],
  );

  // Memoize the grid options
  const memoizedGridOptions = useMemo(
    () => ({
      ...gridOptions,
      getLocaleText: ({ defaultValue }) => t(defaultValue),
    }),
    [gridOptions, t],
  );
  const memoizedInitialState = useMemo(() => {
    const gridState = localStorage.getItem('agGridState');
    return gridState ? JSON.parse(gridState) : null;
  });
  const memoizedColumnDefs = useMemo(
    () => [
      {
        field: '',
        width: 40,
        minWidth: 40,
        filter: false,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        suppressMovable: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: 'left',
        headerClass: 'checkAllInput',
      },
      ...translateHeaders(gridOptions.columnDefs),
    ],
    [gridOptions.columnDefs, translateHeaders],
  );

  return (
    <>
      <div
        className="ag-theme-balham mainGridContainer ordersGridContainer"
        role={rowData ? 'gridStateCompleted' : 'gridStateLoading'}
        style={{
          height: containerHeight,
          position: 'relative',
        }}
      >
        <AgGridReact
          initialState={memoizedInitialState}
          maintainColumnOrder
          groupMaintainOrder
          getContextMenuItems={getContextMenuItems}
          gridOptions={memoizedGridOptions}
          headerHeight={40}
          rowHeight={40}
          rowData={rowData}
          animateRows
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          columnDefs={memoizedColumnDefs}
          context={{
            navigate,
            reviewCase,
          }}
          modules={[
            ClientSideRowModelModule,
            InfiniteRowModelModule,
            CsvExportModule,
            SparklinesModule,
            ClipboardModule,
            ColumnsToolPanelModule,
            ExcelExportModule,
            FiltersToolPanelModule,
            MasterDetailModule,
            MenuModule,
            RangeSelectionModule,
            RichSelectModule,
            RowGroupingModule,
            SetFilterModule,
            MultiFilterModule,
            AdvancedFilterModule,
            SideBarModule,
            StatusBarModule,
            ViewportRowModelModule,
          ]}
          components={{
            customLoadingOverlay: AgGridLoader,
            ...gridOptions.components,
          }}
          loadingOverlayComponent="customLoadingOverlay"
          floatingFiltersHeight={46}
          onGridReady={onGridReady}
          onGridSizeChanged={onGridSizeChanged}
          onFirstDataRendered={onFirstDataRendered}
          onColumnMoved={onColumnMoved}
          onDisplayedColumnsChanged={onDisplayedColumnsChanged}
          onColumnVisible={onColumnVisible}
          groupHeaderHeight={30}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          onColumnGroupOpened={onColumnGroupOpened}
          onGridPreDestroyed={onGridPreDestroyed}
          onColumnResized={onColumnResized}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
              },
            ],
          }}
          defaultColDef={{
            resizable: true,
            editable: false,
            sortable: true,
            filterParams: { newRowsAction: 'keep' },
            floatingFilter: true,
          }}
          columnTypes={{
            dateColumn: {
              width: 185,
              minWidth: 185,
              filter: 'agDateColumnFilter',
              filterParams: {
                newRowsAction: 'keep',
                comparator: (filterLocalDateAtMidnight, cellValue) =>
                  gridDateComparator(filterLocalDateAtMidnight, cellValue),
              },
              valueFormatter: (params) => {
                if (params.value) {
                  return timestampFormatter(params.value);
                }
                return params.value;
              },
            },
            orderIdColumn: {
              width: 150,
              minWidth: 150,
              filter: 'agTextColumnFilter',
              filterParams: { newRowsAction: 'keep' },
              cellStyle: {
                color: 'rgb(27, 119, 200)',
                cursor: 'pointer',
                textTransform: 'uppercase',
              },
              onCellClicked: (params) => {
                navigate(`/orders/details/${params.value}`, {
                  prevLocation: location.pathname,
                });
              },
            },
          }}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: t('Show / Hide Columns'),
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressPivots: true,
                  suppressRowGroups: true,
                  suppressValues: true,
                },
              },
              {
                id: 'filters',
                labelDefault: t('Filters'),
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
            ],
          }}
        />
        <ExpireComponent delay={40000}>
          <div style={{ position: 'absolute', bottom: '13px', right: '20px' }}>
            {loading ? (
              'loading'
            ) : (
              <span
                style={{
                  fontSize: '10px',
                  marginLeft: '12px',
                }}
              >
                {t('Table is up-to-date')}{' '}
                <BoxIcon name="bx-check-circle has-text-success bx-xs" />
              </span>
            )}
          </div>
        </ExpireComponent>
      </div>
      {modalOpen && (
        <ReviewModal
          closeModal={closeModal}
          modalOpen={modalOpen}
          order={order}
        />
      )}
      <FollowUpModalWrapper />
    </>
  );
}

OrdersListGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array,
  containerHeight: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onGridReady: PropTypes.func.isRequired,
  onGridSizeChanged: PropTypes.func.isRequired,
  onFirstDataRendered: PropTypes.func.isRequired,
  onColumnMoved: PropTypes.func.isRequired,
  onDisplayedColumnsChanged: PropTypes.func.isRequired,
  onColumnVisible: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
};

export default memo(OrdersListGrid);
