import PropTypes from 'prop-types';
import { statusColor } from '../../../../../../transforms';

function HeaderInfoProgressBar(props) {
  const { percentComplete, state, isRush } = props;
  const color = statusColor(state, isRush);

  return (
    <div style={{ maxWidth: 200, minWidth: 200 }}>
      <progress
        className={`progress is-${color} is-small`}
        value={percentComplete}
        max="100"
      />
    </div>
  );
}

HeaderInfoProgressBar.propTypes = {
  percentComplete: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  isRush: PropTypes.bool.isRequired,
};

export default HeaderInfoProgressBar;
