import PropTypes from 'prop-types';
import Policy from './Policy';

function LegalModal({
  handleModalClose,
  modalOpen,
  title = '3Shape Design Service Terms and Conditions',
}) {
  const handleClose = () => {
    handleModalClose();
  };

  return (
    <div className={`${modalOpen ? 'is-active' : ''} modal`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={handleClose}
          />
        </header>
        <section className="modal-card-body">
          <Policy policy={title} />
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            type="button"
            className="button is-dark"
            onClick={handleClose}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

LegalModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default LegalModal;
