import PropTypes from 'prop-types';

function GuideCodeCardActionButtons(props) {
  const { children } = props;

  return (
    <div
      className="guide-code-card__action-buttons"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.stopPropagation();
        }
      }}
      role="button"
      tabIndex="0"
    >
      {children}
    </div>
  );
}

GuideCodeCardActionButtons.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuideCodeCardActionButtons;
