import { gql } from '@apollo/client';
import { DesignLocationInfo } from '../fragments';

export default gql`
  query designLocation($slug: String!) {
    designLocation(slug: $slug) {
      ...DesignLocationInfo
    }
  }
  ${DesignLocationInfo}
`;
