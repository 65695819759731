import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionInvoiceTransactionResend(
    $input: InvoiceTransactionResendActionInput!
  ) {
    actionInvoiceTransactionResend(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      invoice {
        id
      }
    }
  }
  ${ErrorMessageInfo}
`;
