import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo } from 'react';
import DeleteButton from '../Buttons/DeleteButton';
import SubmitButton from '../Buttons/SubmitButton';
import FormHeader from './FormHeader';
import ServerErrors from './ServerErrors';

function FormGridWrap({
  children,
  sideForm = null,
  hideHeader = false,
  customMessage = null,
  errorMessages = [],
  title = null,
  dltTitle = null,
  fileUploading = false,
  mutationType = null,
  redirectPath = null,
  width = null,
  match = {},
}) {
  const { handleSubmit, initialValues } = useFormikContext();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div style={{ marginBottom: '3em', position: 'relative' }}>
        {!hideHeader && (
          <article
            className="has-background-primary-light pb-0"
            style={{
              position: 'sticky',
              top: 88,
              zIndex: 5,
              marginBottom: 0,
              margin: '-1em -1em 0',
            }}
          >
            <div
              className="is-flex is-justify-content-space-between is-align-items-flex-start"
              style={{ padding: '1em' }}
            >
              <FormHeader customMessage={customMessage} match={match} />
              <div className="buttons">
                {mutationType !== null ? (
                  <DeleteButton
                    title={dltTitle}
                    mutationType={mutationType}
                    redirectPath={redirectPath}
                    initialValues={initialValues}
                    match={match}
                  />
                ) : null}
                <SubmitButton
                  title={title}
                  fileUploading={fileUploading}
                  match={match}
                />
              </div>
            </div>
          </article>
        )}
        <div className="columns mt-0">
          <div className={`column ${width || 'is-8'}`}>
            <div className="block">
              <ServerErrors errorMessages={errorMessages} />
              {children}
              {hideHeader && (
                <SubmitButton
                  title={title}
                  fileUploading={fileUploading}
                  className="is-pulled-right mt-4"
                  match={match}
                />
              )}
            </div>
          </div>
          <div className="column is-4">{sideForm}</div>
        </div>
      </div>
    </form>
  );
}

FormGridWrap.propTypes = {
  children: PropTypes.node.isRequired,
  sideForm: PropTypes.any,
  hideHeader: PropTypes.bool,
  customMessage: PropTypes.string,
  errorMessages: PropTypes.array,
  title: PropTypes.string,
  dltTitle: PropTypes.string,
  fileUploading: PropTypes.bool,
  mutationType: PropTypes.object,
  redirectPath: PropTypes.string,
  width: PropTypes.string,
  match: PropTypes.object,
};

export default memo(FormGridWrap);
