import PropTypes from 'prop-types';

import GuideBodyCategoryGroups from './GuideBodyCategoryGroups';
import GuideBodyDesignGroups from './GuideBodyDesignGroups';
import GuideBodyFavourites from './GuideBodyFavourites';

function GuideBodyMainContent({
  displayedComponent,
  filteValue = '',
  ...rest
}) {
  if (displayedComponent === 'designGuideGroups' && !filteValue) {
    return <GuideBodyDesignGroups designType={rest.designType} />;
  }
  return displayedComponent === 'favorites' && !filteValue ? (
    <GuideBodyFavourites {...rest} />
  ) : (
    <GuideBodyCategoryGroups
      {...rest}
      filteValue={filteValue}
      displayedComponent={displayedComponent}
    />
  );
}

GuideBodyMainContent.propTypes = {
  displayedComponent: PropTypes.string.isRequired,
  filteValue: PropTypes.string,
};

export default GuideBodyMainContent;
