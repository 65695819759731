import { GET_CUSTOMER_TAGS_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import DataPointWrapper from '../DataPoints/DataPointWrapper';
import { MultiSelect } from '../FormFields';

function DataPointsCustomerTags(props) {
  const { inputParams } = props;
  const app = import.meta.env.VITE_APP_NAME;

  return inputParams.customerTags && app === 'admin_portal' ? (
    <DataPointWrapper>
      <Field
        name="customerTagIds"
        required
        component={MultiSelect}
        label="Customer Tags"
        query={GET_CUSTOMER_TAGS_MULTI}
        dataname="customerTags"
      />
    </DataPointWrapper>
  ) : null;
}

DataPointsCustomerTags.propTypes = {
  inputParams: PropTypes.object.isRequired,
};

export default DataPointsCustomerTags;
