import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoxIcon from '../../Icons/BoxIcon';
import Styles from './AccordionSingle.module.scss';

function AccordionSingle({
  title,
  children,
  index,
  parentName,
  isOpen = false,
  titleExtra = null,
  onClick = null,
  noStyle = false,
  titleExtraClasses = '',
}) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('common');

  const openBtn = useRef(null);
  const collapsibles = useRef(null);

  useEffect(() => {
    bulmaCollapsible.attach('.is-collapsible', {
      container: collapsibles.current,
    });

    if (isOpen) {
      openBtn.current.click();
      setOpen(true);
    }
  }, [isOpen]);

  const toggle = () => {
    if (onClick) {
      onClick();
    }
    setOpen((prev) => !prev);
  };

  return (
    <div className={Styles.accordionContainer}>
      <div id={`approval_accordion_${index}`} ref={collapsibles}>
        <article className={noStyle ? '' : 'message'}>
          <div
            className="message-header"
            style={{
              background: noStyle ? 'transparent' : 'rgba(80, 80, 80, 0.4)',
              color: noStyle ? 'rgba(80, 80, 80, 0.87)' : '#fff',
            }}
          >
            <p
              className="mr-5"
              style={{ maxWidth: '500px', minWidth: '150px' }}
            >
              {t(title)}
            </p>
            {titleExtra && (
              <div
                className={`is-flex is-flex-direction-row is-justify-content-flex-end ${titleExtraClasses}`}
                style={{ width: '100%' }}
              >
                {titleExtra}
              </div>
            )}
            <a
              onClick={toggle}
              ref={openBtn}
              href={`#collapsible-message-accordion-${index}`}
              data-action="collapse"
              className={Styles.iconLink}
            >
              <BoxIcon
                size="large"
                name="bxs-down-arrow"
                className={`${Styles.accordionIcon} ${
                  open ? Styles.isOpen : ''
                }`}
              />
            </a>
          </div>
          <div
            id={`collapsible-message-accordion-${index}`}
            className={`message-body is-collapsible ${open ? 'is-active' : ''}`}
            data-allow-multiple="true"
            data-parent={parentName}
          >
            <div className="message-body-content">{children}</div>
          </div>
        </article>
      </div>
    </div>
  );
}

AccordionSingle.propTypes = {
  title: PropTypes.string.isRequired,
  titleExtra: PropTypes.node,
  titleExtraClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parentName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  noStyle: PropTypes.bool,
};

export default AccordionSingle;
