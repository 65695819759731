import { gql } from '@apollo/client';

export default gql`
  query policy($name: String!) {
    policy(name: $name) {
      id
      document
    }
  }
`;
