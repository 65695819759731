import PropTypes from 'prop-types';

function Tooth1({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 1)
    : upperArch;
  const variable = designType.toothChart ? 1 : 'upper';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[1]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st0 activeTooth ${selected ? 'toothSelected' : ''}` : 'st0'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M624.4 2303.4c57.5 5.3 109.3 18.9 144.2 50.4 49.8 25.8 81.6 52.8 79 82.1 16 44.8 3.5 77.3-5.8 111.1l-26.6 55.6c-16.8 32.3-51.5 53.9-105.8 63.6 -63.9 10.6-127.8 23.3-191.6-13.1 -72.6-27.1-76.1-65.5-83.1-103.4 -21.4-38.8-35.8-85.6-27.3-157.9 7.5-33.1 17.1-75.1 64-94.3S560.4 2288.3 624.4 2303.4L624.4 2303.4z"
      />
      <path
        className="st1"
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M453.3 2362.5c-53.4 75.7-16.8 109.1 5.2 149.3 -6 68.2-4.2 120.6 42 114.2 68.1 48.5 117.7 22.5 171.8 14.5 7.8-12.3 8.3-28.3 29.7-33.7 0 0 47.7-102.2 53.8-118.2s11.2-45.5-20.9-77.5c0 0-49.6-51.9-81.5-66.4 -15.2-6.9-33-4.8-49.3-8.6 -33.3-7.7-98.5-28.4-98.5-28.4C474.3 2300.8 454.9 2317 453.3 2362.5L453.3 2362.5z"
      />
      <path
        className="st2"
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M672.5 2568.7c2-1.3-33.9-24.2-59.1-34 -12.9-5-20.6-20.5-23.9-33.9 -6.7-27.6 20-62.6 11.5-84.5s-26.4-22.8-42.2-29.7c-10.6-4.6-16.8-0.8-33.8-7.1"
      />
      <path
        className="st3"
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M651.9 2391.2c-22.5 6.6-51 12.2-52.2 22.4"
      />
      <path
        className="st2"
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M527.6 2509.2c9.1-4.5 20.8-10 35.2-5.5s22 5.8 26.8-7"
      />
      <path
        className="st2"
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M650.2 2501c-21.1-0.9-44.3 11.8-61.9-12.8"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M602.2 2373.9c5.1 12.6 7.8 24.7-1.2 34.5"
      />
    </>
  );
}

Tooth1.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth1;
