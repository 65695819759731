import TempOrderHeaderHide from './TempOrderHeaderHide';
import TempOrderHeaderRemoveAll from './TempOrderHeaderRemoveAll';
import TempOrderHeaderSubmitAll from './TempOrderHeaderSubmitAll';

function TempOrderHeader(props) {
  return (
    <div className="is-full has-text-right" style={{ marginBottom: '0.25em' }}>
      <TempOrderHeaderSubmitAll {...props} />
      <TempOrderHeaderRemoveAll {...props} />
      <TempOrderHeaderHide {...props} />
    </div>
  );
}

export default TempOrderHeader;
