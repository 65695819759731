import { gql } from '@apollo/client';

const OrderHoldReasonInfo = gql`
  fragment OrderHoldReasonInfo on OrderHoldReason {
    id
    name
    value
    slug
    subject
    message
    isActive
    requiresCustomMessage
    designTypeIds
    designTypes {
      name
    }
  }
`;

export default OrderHoldReasonInfo;
