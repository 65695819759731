import { OverpanelDrawerContext } from '@fullcontour/common';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import OverpanelInformation from '../../../../OverpanelInformation/OverpanelInformation';
import CaseItemActions from '../CaseItemActions/CaseItemActions';
import DesignTypeDropdown from './DesignTypeDropdown';

function DesignTypeSelection(props) {
  const {
    designTypes,
    setSelectedDesignType,
    index,
    removeOrderItem,
    isParsed,
  } = props;

  const { values } = useFormikContext();

  const { setDrawerBody, toggle } = useContext(OverpanelDrawerContext);

  const infoClick = () => {
    setDrawerBody(
      <OverpanelInformation
        designTypeName={values.orderItems[index].designTypeName}
        drawer
        isVisible
      />,
    );
    toggle();
  };

  const sortedDesignTypes = useMemo(
    () => [...designTypes].sort((a, b) => a?.name?.localeCompare(b?.name)),
    [designTypes],
  );

  return (
    <div className="is-flex is-flex-direction-row">
      <CaseItemActions
        index={index}
        removeOrderItem={removeOrderItem}
        isParsed={isParsed}
      />
      <Field
        name={`orderItems[${index}].designTypeId`}
        required
        id={`designTypeId${index}`}
        component={DesignTypeDropdown}
        setSelectedDesignType={setSelectedDesignType}
        data={sortedDesignTypes}
        infoCallback={infoClick}
      />
    </div>
  );
}

DesignTypeSelection.propTypes = {
  designTypes: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedDesignType: PropTypes.func.isRequired,
  removeOrderItem: PropTypes.func.isRequired,
  isParsed: PropTypes.bool.isRequired,
};

export default DesignTypeSelection;
