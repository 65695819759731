/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
import { useTexture } from '@react-three/drei';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';
import gums from './gums.jpg';
import updateObjText from './updateObjText';

function ObjElementGingiva(props) {
  const { index, isUpper, isLower, name } = props;

  const { parsedDiffs, otpData, gingivaText, currentOtp, visibility, objRefs } =
    useContext(OrthoContext);

  const textureProps = useTexture({ map: gums });

  const processedGingivaText = useMemo(
    () => (gingivaText ? gingivaText[name] : null),
    [gingivaText, name],
  );

  const currentDiff = useMemo(() => {
    if (otpData) {
      let diffName;

      if (isUpper && currentOtp) {
        diffName = currentOtp.UpperJawGingivaId.Value.split('.diff')[0];
      }

      if (isLower && currentOtp) {
        diffName = currentOtp.LowerJawGingivaId.Value.split('.diff')[0];
      }

      return parsedDiffs[diffName]?.sort((a, b) => a.Index - b.Index);
    }

    return null;
  }, [currentOtp, isLower, isUpper, otpData, parsedDiffs]);

  useEffect(() => {
    if (processedGingivaText && currentDiff) {
      const updatedText = updateObjText(processedGingivaText, currentDiff);

      const loader = new OBJLoader();

      const object = loader.parse(updatedText);

      if (objRefs.current[index]) {
        objRefs.current[index].geometry = object.children[0].geometry;
      }
    }
  }, [currentDiff, index, objRefs, processedGingivaText]);

  const update = useCallback((self) => {
    // eslint-disable-next-line no-param-reassign
    self.verticesNeedUpdate = true;
    self.geometry.computeBoundingSphere();
  }, []);

  return (
    <mesh
      name={name}
      receiveShadow
      castShadow
      visible={
        (isUpper && visibility.upper && visibility.gingiva) ||
        (isLower && visibility.lower && visibility.gingiva)
      }
      ref={(ref) => (objRefs.current[index] = ref)}
      onUpdate={update}
    >
      <meshPhysicalMaterial
        color={0xe06c75}
        roughness={0.5}
        metalness={0.1}
        clearcoat={0.2}
        clearcoatRoughness={0.3}
        sheen={0.2}
        sheenColor={0xffa07a}
        transmission={0.05}
        ior={1.4}
        wireframe={false}
        opacity={visibility.overlay ? 0.85 : 1}
        transparent={visibility.overlay}
        specularIntensity={0.2}
        castShadow
        receiveShadow
        {...textureProps}
      />
    </mesh>
  );
}

ObjElementGingiva.propTypes = {
  index: PropTypes.number.isRequired,
  isUpper: PropTypes.bool.isRequired,
  isLower: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default ObjElementGingiva;
