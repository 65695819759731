import { gql } from '@apollo/client';

export default gql`
  mutation updatePrinterBrand($input: PrinterBrandUpdateInput!) {
    updatePrinterBrand(input: $input) {
      errorMessages {
        message
      }
      printerBrand {
        id
        name
        description
        imageUrl
      }
    }
  }
`;
