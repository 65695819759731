/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';

const mockEventTypes = {
  mousedown: 'touchstart',
  mousemove: 'touchmove',
  mouseup: 'touchend',
  blur: 'touchcancel',
};
const distance = (x1, y1, x2, y2) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

function touchWithMouseHOC(Component, options = {}) {
  const clickTolerance = options.clickTolerance || 5;
  function TouchWithMouse({
    onTouchStart = () => {},
    onTouchMove = () => {},
    onTouchEnd = () => {},
    onTouchCancel = () => {},
    ...rest
  }) {
    const props = {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onTouchCancel,
      ...rest,
    };
    let isMouseDown = false;
    let lastMoveEvent = null;
    let clickStartX = null;
    let clickStartY = null;
    useEffect(() => {
      document.addEventListener('mousemove', onDocumentMouseMove);
      document.addEventListener('mouseup', onDocumentMouseUp);
      // Listen in the capture phase, so we can prevent the clicks
      document.addEventListener('click', onDocumentClick, true);
      window.addEventListener('blur', onWindowBlur);
      return () => {
        document.removeEventListener('mousemove', onDocumentMouseMove);
        document.removeEventListener('mouseup', onDocumentMouseUp);
        document.removeEventListener('click', onDocumentClick, true);
        window.removeEventListener('blur', onWindowBlur);
      };
    }, []);
    function mockTouchEvent(e, overwrites = {}) {
      return {
        changedTouches: overwrites.changedTouches || [
          {
            //  identifier: this.mouseTouchId,
            pageX: e.pageX,
            pageY: e.pageY,
          },
        ],
        type: mockEventTypes[e.type] || e.type,
        preventDefault: e.preventDefault.bind(e),
        stopPropagation: e.stopPropagation.bind(e),
      };
    }
    function onMouseDown(e) {
      const { onMouseDown } = props;
      onMouseDown(e);
      isMouseDown = true;
      clickStartX = e.pageX;
      clickStartY = e.pageY;
      onTouchStart(mockTouchEvent(e));
    }
    function onDocumentMouseMove(e) {
      if (!isMouseDown) {
        return;
      }
      lastMoveEvent = mockTouchEvent(e);
      onTouchMove(lastMoveEvent);
    }
    function onDocumentMouseUp(e) {
      if (!isMouseDown) {
        return;
      }
      isMouseDown = false;
      onTouchEnd(mockTouchEvent(e));
      // This waits for the click event, so we know to prevent it
      setTimeout(() => {
        clickStartX = null;
        clickStartY = null;
      }, 0);
    }
    function onWindowBlur(e) {
      if (!isMouseDown) {
        return;
      }
      isMouseDown = false;
      clickStartX = null;
      clickStartY = null;
      if (lastMoveEvent) {
        const mockTouchCancelEvent = mockTouchEvent(e, {
          changedTouches: lastMoveEvent.changedTouches,
        });
        onTouchCancel(mockTouchCancelEvent);
      }
    }
    function onDocumentClick(e) {
      if (
        clickStartX !== null &&
        clickStartY !== null &&
        distance(clickStartX, clickStartY, e.pageX, e.pageY) > clickTolerance
      ) {
        clickStartX = null;
        clickStartY = null;
        e.preventDefault();
        e.stopPropagation();
      }
    }

    return <Component {...props} onMouseDown={onMouseDown} />;
  }

  TouchWithMouse.propTypes = {
    onMouseDown: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchCancel: PropTypes.func,
  };

  TouchWithMouse.displayName = `TouchWithMouse(${
    Component.displayName || Component.name
  })`;

  return memo(TouchWithMouse);
}

export default touchWithMouseHOC;
