import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionGuideBookClone($input: GuideBookCloneActionInput!) {
    actionGuideBookClone(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      guideBook {
        id
        name
      }
    }
  }
  ${ErrorMessageInfo}
`;
