import LegalPage from '../../views/Legal/LegalPage';

const routes = [
  {
    path: ['/legal', '/legal/:type'],
    component: LegalPage,
  },
];

export default routes;
