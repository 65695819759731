import { gql } from '@apollo/client';
import { CustomerOrganizationInfo } from '../fragments';

export default gql`
  query customerOrganizations($name: String) {
    customerOrganizations(name: $name) {
      ...CustomerOrganizationInfo
    }
  }
  ${CustomerOrganizationInfo}
`;
