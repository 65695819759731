import IntegrationForm from '@fullcontour/common/src/components/views/Integrations/IntegrationForm/IntegrationForm';
import IntegrationsListQueryWrapper from '@fullcontour/common/src/components/views/Integrations/IntegrationsList/IntegrationsListQueryWrapper';

const routes = [
  {
    path: ['/:root(locations)/:slug/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: false,
    so: true,
  },
  {
    path: ['/:root(locations)/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: true,
    so: false,
  },
  {
    path: [
      '/:root(locations)/:slug/integrations/:action',
      '/:root(locations)/:slug/integrations/:id/:action',
    ],
    component: IntegrationForm,
    sl: false,
    so: true,
  },
  {
    path: [
      '/:root(locations)/integrations/:action',
      '/:root(locations)/integrations/:id/:action',
    ],
    component: IntegrationForm,
    sl: true,
    so: false,
  },
];

export default routes;
