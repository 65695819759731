import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { classesGenerator } from '../../../../transforms';

function Step({
  title,
  currentActive = null,
  isActive = false,
  last = false,
  idx = null,
}) {
  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'steps__step-active': isActive || idx - 1 < currentActive,
        'is-flex': true,
        'is-align-items-center': true,
      }),
    [isActive, currentActive, idx],
  );

  return (
    <div className={modalClasses}>
      <div className="steps__step-number">
        {idx - 1 < currentActive ? (
          <i className="bx bx-check is-size-3" />
        ) : (
          idx
        )}
      </div>
      <span className="steps__title">{title}</span>
      {!last && <div className="steps__line" />}
    </div>
  );
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  currentActive: PropTypes.number,
  idx: PropTypes.number,
  isActive: PropTypes.bool,
  last: PropTypes.bool,
};

export default Step;
