/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-return-assign */
import { Line, RoundedBox, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MeshStandardMaterial } from 'three';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';
import ObjElementAttachment from '../ObjElementAttachment/ObjElementAttachment';

import './IprAnnotation.scss';

function findElementByTooth(children, type, tooth) {
  return children.find(
    (item) => item.name.includes(type) && tooth === item.tooth,
  );
}

const topMargin = 15;
const bottomMargin = -15;

const teethIprPositions = (radius, { x, z }, type, newOtpVersion) => {
  const sign = newOtpVersion ? -1 : 1;
  const positions = {};
  const createPosition = (dx, dy, dz) => [x + dx * sign, dy, z + dz * sign];

  positions[1] = createPosition(0, 0, radius / 1.5);
  positions[2] = createPosition(-radius * 2, topMargin, radius / 1.2);
  positions[3] = createPosition(-radius * 2, topMargin, radius * 1.4);
  positions[4] = createPosition(-radius * 2.3, topMargin, radius * 1.6);
  positions[5] = createPosition(-radius * 2.3, topMargin, radius * 1.8);
  positions[6] = createPosition(-radius * 1.4, topMargin, radius * 2);
  positions[7] = createPosition(-radius, topMargin, radius * 2);
  positions[8] = createPosition(radius / 1.5, topMargin, radius * 1.4);
  positions[9] = createPosition(radius * 1.2, topMargin, radius * 1.2);
  positions[10] = createPosition(radius * 2.5, topMargin, radius);
  positions[11] = createPosition(radius * 2.4, topMargin, radius / 3);
  positions[12] = createPosition(radius * 2.5, topMargin, 0);
  positions[13] = createPosition(radius * 2.5, topMargin, 0);
  positions[14] = createPosition(radius * 2, topMargin, -radius / 2.5);
  positions[15] = createPosition(radius / 1.2, topMargin, -radius / 2.5);
  positions[16] = createPosition(radius / 1.2, topMargin, -radius / 2);
  positions[17] = createPosition(radius / 1.2, topMargin, -radius / 2);
  positions[18] = createPosition(radius / 1.2, bottomMargin, -radius / 2);
  positions[19] = createPosition(radius * 2.5, bottomMargin, -radius / 2);
  positions[20] = createPosition(radius * 2.5, bottomMargin, -radius / 2);
  positions[21] = createPosition(radius * 2.5, bottomMargin, -radius / 2);
  positions[22] = createPosition(radius * 2, bottomMargin, radius);
  positions[23] = createPosition(radius * 1.8, bottomMargin, radius * 1.5);
  positions[24] = createPosition(radius / 2.3, bottomMargin, radius * 1.8);
  positions[25] = createPosition(radius / 2.3, bottomMargin, radius * 1.8);
  positions[26] = createPosition(radius / 2.3, bottomMargin, radius * 1.8);
  positions[27] = createPosition(-radius, bottomMargin, radius * 2);
  positions[28] = createPosition(-radius * 2, bottomMargin, radius * 1.8);
  positions[29] = createPosition(-radius * 2, bottomMargin, radius * 1.4);
  positions[30] = createPosition(-radius * 2, bottomMargin, radius * 1.4);
  positions[31] = createPosition(-radius * 2, bottomMargin, radius * 1.4);
  positions[32] = createPosition(-radius * 2, bottomMargin, radius / 1.2);

  return positions;
};

function ObjElementToothInner({
  index,
  files,
  obj,
  name,
  isUpper = false,
  isLower = false,
  currentTooth = null,
  iprTooth = null,
}) {
  const {
    toothMaterial,
    visibility,
    objRefs,
    currentStage,
    iprs,
    setShowAttachmentsButton,
    showAttachmentsButton,
    newOtpVersion,
  } = useContext(OrthoContext);

  useFrame((state) => {
    if (visibility.ipr && currentTooth && !visibility.grid) {
      const { children } = state.scene.children.at(-1);

      const currentPlate = findElementByTooth(
        children[0].children[0].children[0].children,
        'BackgroundPlate',
        currentTooth.Unn,
      );
      if (currentPlate) {
        currentPlate.lookAt(state.camera.position);
      }
    }
  });

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    let currentAttachments;

    if (currentTooth) {
      const allAttachments = currentTooth.Attachments;

      currentAttachments = allAttachments.map((a) => {
        const f = files.find((fi) => {
          const visualMode = a.VisualModelId || a.VisualModelID;

          return (
            visualMode.split('.dcm')[0] === fi.originalFileName.split('.obj')[0]
          );
        });

        const attachmentTransform = a.InitialPositionOnNonTransformedTooth;

        const matrix = [
          attachmentTransform[0][0],
          attachmentTransform[0][1],
          attachmentTransform[0][2],
          attachmentTransform[0][3],
          attachmentTransform[1][0],
          attachmentTransform[1][1],
          attachmentTransform[1][2],
          attachmentTransform[1][3],
          attachmentTransform[2][0],
          attachmentTransform[2][1],
          attachmentTransform[2][2],
          attachmentTransform[2][3],
          attachmentTransform[3][0],
          attachmentTransform[3][1],
          attachmentTransform[3][2],
          attachmentTransform[3][3],
        ];

        return { ...a, file: f, matrix, unn: currentTooth.Unn };
      });

      const isAttachment = !!currentAttachments.length;

      if (!showAttachmentsButton && isAttachment) {
        setShowAttachmentsButton(isAttachment);
      }

      setAttachments(currentAttachments);
    }
  }, [currentTooth, files, setShowAttachmentsButton, showAttachmentsButton]);

  const currentMatrix = useMemo(() => {
    let matrix = [];

    if (currentTooth) {
      const m = currentTooth.Movement.Value.Transformation;
      matrix = [
        m[0][0],
        m[0][1],
        m[0][2],
        m[0][3],
        m[1][0],
        m[1][1],
        m[1][2],
        m[1][3],
        m[2][0],
        m[2][1],
        m[2][2],
        m[2][3],
        m[3][0],
        m[3][1],
        m[3][2],
        m[3][3],
      ];
    }

    return matrix;
  }, [currentTooth]);

  useEffect(() => {
    if (currentMatrix?.length > 0) {
      objRefs.current[index].matrix.set(
        currentMatrix[0],
        currentMatrix[1],
        currentMatrix[2],
        currentMatrix[3],
        currentMatrix[4],
        currentMatrix[5],
        currentMatrix[6],
        currentMatrix[7],
        currentMatrix[8],
        currentMatrix[9],
        currentMatrix[10],
        currentMatrix[11],
        currentMatrix[12],
        currentMatrix[13],
        currentMatrix[14],
        currentMatrix[15],
      );
    }
  }, [currentMatrix, index, objRefs]);

  const iprAnnotationPosition = (type) => {
    if (obj?.children[0]?.geometry?.boundingSphere) {
      const { x, y, z } = obj.children[0].geometry.boundingSphere.center;
      const { radius } = obj.children[0].geometry.boundingSphere;
      return teethIprPositions(radius, { x, y, z }, type, newOtpVersion)[
        currentTooth.Unn
      ];
    }

    return [0, 0, 0];
  };

  const transparentMaterial = new MeshStandardMaterial({
    transparent: true,
    opacity: Number(!!currentStage),
    color: '#141414',
  });

  const transparentMaterialTooth = new MeshStandardMaterial({
    transparent: true,
    opacity: Number(!!currentStage),
    color: '#ffffff',
  });

  return obj?.children[0]?.geometry ? (
    <>
      <mesh
        geometry={obj.children[0].geometry}
        name={name}
        receiveShadow
        castShadow
        visible={(isUpper && visibility.upper) || (isLower && visibility.lower)}
        ref={(ref) => (objRefs.current[index] = ref)}
        material={toothMaterial}
        matrixAutoUpdate={false}
      >
        {attachments.map((attachment) => (
          <ObjElementAttachment
            key={attachment.file.id}
            file={attachment.file}
            unn={attachment.unn}
            matrix={attachment.matrix}
          />
        ))}
      </mesh>
      {currentTooth &&
        currentTooth.Unn &&
        visibility.ipr &&
        ((isUpper && visibility.upper) || (isLower && visibility.lower)) &&
        iprTooth &&
        iprs &&
        iprs[currentTooth.Unn].showIPR && (
          <>
            <RoundedBox
              args={[5, 3, 0.1]}
              position={iprAnnotationPosition(true)}
              tooth={currentTooth?.Unn}
              name="BackgroundPlate"
              radius={1}
              smoothness={12}
            >
              <Text
                scale={[2, 2, 2]}
                position={[0, 0, 0.1]}
                anchorX="center"
                anchorY="middle"
                name="Text"
                tooth={currentTooth?.Unn}
              >
                {iprs[currentTooth.Unn].aggregatedValue}
                <meshStandardMaterial
                  attach="material"
                  {...transparentMaterialTooth}
                />
              </Text>
              <meshStandardMaterial
                attach="material"
                {...transparentMaterial}
              />
            </RoundedBox>

            <Line
              points={[
                iprAnnotationPosition('lineTop') || [0, 0, 0],
                ...(iprTooth.closestPoint || [0, 0, 0]),
              ]}
              color="red"
              lineWidth={Number(!!currentStage) / 2}
              name="Line"
              tooth={currentTooth?.Unn}
            />
          </>
        )}
    </>
  ) : null;
}

ObjElementToothInner.propTypes = {
  index: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  obj: PropTypes.object.isRequired,
  isUpper: PropTypes.bool,
  isLower: PropTypes.bool,
  currentTooth: PropTypes.object,
  iprTooth: PropTypes.object,
  name: PropTypes.string.isRequired,
};

export default ObjElementToothInner;
