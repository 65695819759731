import PropTypes from 'prop-types';

function PreferenceSection({ title, children, width = 3 }) {
  return (
    <div className="column" width={width}>
      <h6
        className="title is-6"
        style={{
          borderBottom: '1px solid rgba(128, 128, 128, 0.25)',
          paddingBottom: 5,
        }}
      >
        {title}
      </h6>
      {children}
    </div>
  );
}

PreferenceSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default PreferenceSection;
