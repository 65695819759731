/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import ICON_ClearAligners from '@fullcontour/common/src/assets/icons/clearaligners.png';
import ICON_Dentures from '@fullcontour/common/src/assets/icons/dentures.png';
import ICON_SurgicalGuide from '@fullcontour/common/src/assets/icons/surgicalguide.png';
import ICON_Tooth from '@fullcontour/common/src/assets/icons/tooth.png';
import PropTypes from 'prop-types';

function BusinessUnitsField({
  value,
  form: { resetForm, setFieldValue, setCurrentStepIndex, canShowErrors },
  field: { name },
  error = null,
  data: { designTypes },
}) {
  function changeValueHandler(newValue) {
    if (value !== newValue) {
      resetForm();
      setFieldValue(name, newValue);
      setCurrentStepIndex({
        _sectionIndex: 0,
        _fieldIndex: 1,
      });
    }
  }
  return (
    <div
      className="is-justify-content-center columns p-5"
      style={
        canShowErrors && error
          ? { border: '1px solid #DE0019', backgroundColor: '#FFE3E6' }
          : null
      }
    >
      {designTypes['core-lab'] && designTypes['core-lab'].length > 0 && (
        <div
          className={`is-clickable p-4 m-2 box column is-3 is-flex is-flex-direction-column is-align-items-center ${
            value === 'core-lab' ? 'has-background-grey-lighter' : ''
          }`}
          style={{ border: '1px solid black' }}
          onClick={() => {
            changeValueHandler('core-lab');
          }}
        >
          <figure className="image is-96x96 is-flex ">
            <img src={ICON_Tooth} alt="Core Lab" />
          </figure>
          <p className="has-text-centered">Crown</p>
        </div>
      )}
      {designTypes['guided-surgery'] &&
        designTypes['guided-surgery'].length > 0 && (
          <div
            className={`is-clickable p-4 m-2 box column is-3 is-flex is-flex-direction-column is-align-items-center ${
              value === 'guided-surgery' ? 'has-background-grey-lighter' : ''
            }`}
            style={{ border: '1px solid black' }}
            onClick={() => {
              changeValueHandler('guided-surgery');
            }}
          >
            <figure className="image is-96x96">
              <img src={ICON_SurgicalGuide} alt="Guided Surgery" />
            </figure>

            <p className="has-text-centered">Guided Surgery</p>
          </div>
        )}
      {designTypes['ortho'] && designTypes['ortho'].length > 0 && (
        <div
          className={`is-clickable p-4 m-2 box column is-3 is-flex is-flex-direction-column is-align-items-center ${
            value === 'ortho' ? 'has-background-grey-lighter' : ''
          }`}
          style={{ border: '1px solid black' }}
          onClick={() => {
            changeValueHandler('ortho');
          }}
        >
          <figure className="image is-96x96">
            <img src={ICON_ClearAligners} alt="Ortho" />
          </figure>

          <p className="has-text-centered">Orthodontics</p>
        </div>
      )}
      {designTypes['removables'] && designTypes['removables'].length > 0 && (
        <div
          className={`is-clickable p-4 m-2 box is-3 column is-flex is-flex-direction-column is-align-items-center ${
            value === 'removables' ? 'has-background-grey-lighter' : ''
          }`}
          style={{ border: '1px solid black' }}
          onClick={() => {
            changeValueHandler('removables');
          }}
        >
          <figure className="image is-96x96">
            <img src={ICON_Dentures} alt="Removables" />
          </figure>
          <p className="has-text-centered">Dentures</p>
        </div>
      )}
    </div>
  );
}
BusinessUnitsField.propTypes = {
  value: PropTypes.any.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  error: PropTypes.string,
  data: PropTypes.object.isRequired,
};
export default BusinessUnitsField;
