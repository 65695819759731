import { gql } from '@apollo/client';

export default gql`
  query orderOtpFiles($id: ID!) {
    order(id: $id) {
      otpFiles {
        stages
        redesignCount
        createdAt
        id
      }
    }
  }
`;
