import { gql } from '@apollo/client';
import { ManufacturerUserInfo } from '../fragments';

export default gql`
  query manufacturerUser($id: ID!) {
    manufacturerUser(id: $id) {
      ...ManufacturerUserInfo
    }
  }
  ${ManufacturerUserInfo}
`;
