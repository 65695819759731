/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import {
  GET_ORDER_MESSAGES,
  GET_ORDER_OTP_FILES,
} from '@fullcontour/shared-api';
import { MovementTableWithViewerProvider } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseLoader from '../Loader/BaseLoader';
import {
  PatientLinkModal,
  TeethChart,
  TreatmentHistory,
  ViewerContainer,
} from './components';
import './styles.css';
import useIprsCalculations from './utils/useIprsCalculations';

function OrthoObjViewer({
  order,
  dentalNotation = null,
  minified = false,
  setRedesignCountDoc = null,
  light = null,
  height = '80vh',
}) {
  const [designUserMessages, setDesignUserMessages] = useState([]);
  const [
    designUserMessagesCurrentRedesign,
    setDesignUserMessagesCurrentRedesign,
  ] = useState([]);

  const isDocApp =
    import.meta.env.VITE_APP_NAME === 'approval-doctor' ||
    import.meta.env.VITE_APP_NAME === 'approval-doctor-lite';

  const isDesignerApp = import.meta.env.VITE_APP_NAME === 'designer_portal';
  const orderRedesignCount =
    isDesignerApp && order?.state === 'redesign'
      ? order.redesignCount - 1
      : order.redesignCount;

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStage, setActiveStage] = useState(0);
  const [redesignCountObj, setRedesignCountObj] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [otpFiles, setOtpFiles] = useState(order.otpFiles || []);
  const [iprs, setIprs] = useState({});
  const { t } = useTranslation('common');

  useEffect(() => {
    if (isDocApp) {
      setOtpFiles(order.otpFiles);
    }
  }, [isDocApp, order.otpFiles]);

  const { loading } = useQuery(GET_ORDER_OTP_FILES, {
    variables: {
      id: order.hexOrderCode,
    },
    onCompleted(data) {
      setOtpFiles(data.order.otpFiles);
    },
    skip: isDocApp || order.otpFiles,
  });

  useQuery(GET_ORDER_MESSAGES, {
    variables: { id: order.hexOrderCode },
    skip: isDocApp,
    onCompleted(data) {
      const { orderMessages } = data;
      setDesignUserMessages(
        orderMessages.filter(
          (item) => item.orderMessageableType === 'DesignUser',
        ),
      );
    },
  });

  useEffect(() => {
    if (order && !redesignCountObj) {
      setRedesignCountObj(
        order.dataFromUrl
          ? parseInt(order.lastRedesign, 10)
          : orderRedesignCount,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.redesignCount]);

  useEffect(() => {
    if (setRedesignCountDoc) {
      setRedesignCountDoc(redesignCountObj);
    }
  }, [redesignCountObj, setRedesignCountDoc]);

  useEffect(() => {
    setDesignUserMessagesCurrentRedesign(
      designUserMessages.filter(
        (item) => item.redesignCount === redesignCountObj,
      ),
    );
  }, [redesignCountObj, designUserMessages]);

  function handleClick(_, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  }

  const designerMessages = isDocApp
    ? order.designUserMessages?.filter(
        (item) => item.redesignCount === redesignCountObj,
      )
    : designUserMessagesCurrentRedesign;
  const designerMessagesCurrent = [
    ...(isDocApp ? order?.designUserMessages || [] : designUserMessages),
  ]?.filter((item) => item.redesignCount === orderRedesignCount);

  const useNewViewer = useMemo(() => {
    const startDateForNewViewer = new Date('January 01, 2022 00:00:00');
    const filesFromCurrentRedesign =
      order.treatmentReviewFiles &&
      order.treatmentReviewFiles.filter(
        (item) => item.redesignCount === redesignCountObj,
      );
    // in case if treatmentReviewFiles array is empty -> NaN
    const fileDateForCheck = new Date(
      order.treatmentReviewFiles &&
        filesFromCurrentRedesign[0] &&
        filesFromCurrentRedesign[0].createdAt,
    );

    return fileDateForCheck.getTime() > startDateForNewViewer.getTime();
  }, [order.treatmentReviewFiles, redesignCountObj]);

  const {
    orderStages,
    isTotal,
    setIsTotal,
    total,
    recalculatedStage,
    bodyTeethData,
    headerTableData,
  } = useIprsCalculations({
    setIprs,
    otpFiles,
    redesignCountObj,
    activeStage,
  });

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <MovementTableWithViewerProvider
      value={{
        activeStage,
        setActiveStage,
        iprs,
        orderStages,
        isTotal,
        setIsTotal,
        total,
        recalculatedStage,
        bodyTeethData,
        headerTableData,
      }}
    >
      {isDocApp && (
        <PatientLinkModal
          handleModalClose={() => setModalOpen(false)}
          modalOpen={modalOpen}
        />
      )}

      {minified ? (
        <ViewerContainer
          objFiles={order.objFiles}
          useNewViewer={useNewViewer}
          convertedTreatmentReviewFiles={order.convertedTreatmentReviewFiles}
          otpFiles={otpFiles || []}
          treatmentReviewFiles={order.treatmentReviewFiles}
          feedbacks={order.feedbacks}
          designerMessages={designerMessages}
          designerMessagesCurrent={designerMessagesCurrent}
          redesignCountObj={redesignCountObj}
          onClick={handleClick}
          activeIndex={activeIndex}
          height={height}
          setStage={setActiveStage}
        />
      ) : (
        <>
          <div className="is-flex is-justify-content-right">
            {isDocApp &&
              (order?.objFiles?.length > 0 ||
                order?.convertedTreatmentReviewFiles?.length > 0) && (
                <button
                  onClick={() => setModalOpen(true)}
                  type="button"
                  className="button has-tooltip-left has-tooltip-arrow"
                  data-tooltip={t(
                    'Click here to create a link you can send to the patient so they can review the case',
                  )}
                >
                  <span className="icon">
                    <i className="bx bxs-share is-size-4" />
                  </span>
                  <span>{t(' Send to patient')}</span>
                </button>
              )}
          </div>
          <div className="treatment-viewer-wrapper">
            <ViewerContainer
              objFiles={order.objFiles}
              otpFiles={otpFiles || []}
              useNewViewer={useNewViewer}
              treatmentReviewFiles={order.treatmentReviewFiles}
              convertedTreatmentReviewFiles={
                order.convertedTreatmentReviewFiles
              }
              redesignCountObj={redesignCountObj}
              onClick={handleClick}
              activeIndex={activeIndex}
              setStage={setActiveStage}
              iprs={iprs}
            />
          </div>
          {otpFiles?.length && useNewViewer ? (
            <TeethChart
              order={{ ...order, otpFiles }}
              treatmentReviewFiles={order.orderFiles}
              redesignCountObj={redesignCountObj}
              dentalNotation={dentalNotation}
              designerMessagesCurrent={designerMessagesCurrent}
            />
          ) : null}
          {otpFiles?.length ? (
            <TreatmentHistory
              useNewViewer={useNewViewer}
              files={otpFiles}
              light={light}
              dataFromUrl={order.dataFromUrl}
              lastRedesign={order.lastRedesign}
              objFiles={order.objFiles}
              orderRedesignCount={orderRedesignCount}
              treatmentReviewFiles={order.treatmentReviewFiles}
              feedbacks={order.feedbacks}
              designerMessages={designerMessages}
              designerMessagesCurrent={designerMessagesCurrent}
              redesignCountObj={redesignCountObj}
              setRedesignCountObj={setRedesignCountObj}
              currentRedesign={orderRedesignCount}
              resetHistory={() => setRedesignCountObj(orderRedesignCount)}
            />
          ) : null}
        </>
      )}
    </MovementTableWithViewerProvider>
  );
}

OrthoObjViewer.propTypes = {
  order: PropTypes.object.isRequired,
  minified: PropTypes.bool,
  light: PropTypes.bool,
  dentalNotation: PropTypes.string,
  setRedesignCountDoc: PropTypes.func,
  height: PropTypes.string,
};

export default OrthoObjViewer;
