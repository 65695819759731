function UnsNumbers() {
  return (
    <>
      <path className="st2" d="M3024.8 2374.4c-5.1 12.6-7.8 24.7 1.2 34.5" />
      <path d="M259.8 2585.7h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V2585.7z" />
      <path d="M334.2 2097.9v21.1H215.9c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9L334.2 2097.9z" />
      <path d="M302.2 1630.1l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9 6.1 5.5 13.4 8.2 22.2 8.2 10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1 -4.2 6.3-10.3 11.4-18.4 15.1 10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14C309.5 1657.2 303.6 1645 302.2 1630.1z" />
      <path d="M455.8 1319v-42.8h-77.6v-20.1l81.7-116h17.9v116H502v20.1h-24.2v42.8H455.8zM455.8 1256.1v-80.7l-56 80.7H455.8z" />
      <path d="M502 972.2l23.1-2c1.7 11.2 5.7 19.7 11.9 25.3 6.2 5.7 13.7 8.5 22.5 8.5 10.6 0 19.5-4 26.9-12 7.3-8 11-18.6 11-31.7 0-12.5-3.5-22.4-10.6-29.7 -7-7.2-16.3-10.9-27.6-10.9 -7.1 0-13.5 1.6-19.2 4.8 -5.7 3.2-10.2 7.4-13.4 12.5l-20.6-2.7 17.3-91.9h89v21h-71.4l-9.6 48.1c10.7-7.5 22-11.2 33.8-11.2 15.6 0 28.8 5.4 39.6 16.2 10.7 10.8 16.1 24.7 16.1 41.7 0 16.2-4.7 30.2-14.2 42 -11.5 14.5-27.1 21.7-47 21.7 -16.3 0-29.6-4.6-39.9-13.7C509.4 999.3 503.5 987.2 502 972.2L502 972.2z" />
      <path d="M841.1 517.2l-21.9 1.7c-2-8.6-4.7-14.9-8.3-18.8 -5.9-6.3-13.3-9.4-22-9.4 -7 0-13.1 2-18.4 5.9 -6.9 5-12.4 12.4-16.4 22.1 -4 9.7-6.1 23.5-6.2 41.4 5.3-8.1 11.8-14 19.4-17.9 7.6-3.9 15.7-5.9 24-5.9 14.6 0 27.1 5.4 37.4 16.2 10.3 10.8 15.4 24.7 15.4 41.8 0 11.2-2.4 21.7-7.3 31.3 -4.8 9.6-11.5 17-20 22.2 -8.5 5.1-18.1 7.7-28.8 7.7 -18.3 0-33.2-6.7-44.8-20.2 -11.6-13.5-17.3-35.7-17.3-66.6 0-34.6 6.4-59.7 19.2-75.4 11.1-13.7 26.2-20.5 45-20.5 14.1 0 25.6 3.9 34.6 11.8C833.9 492.4 839.3 503.3 841.1 517.2zM751.4 594.4c0 7.6 1.6 14.8 4.8 21.7 3.2 6.9 7.7 12.2 13.5 15.8 5.8 3.6 11.8 5.4 18.2 5.4 9.3 0 17.3-3.7 23.9-11.2 6.7-7.5 10-17.7 10-30.5 0-12.4-3.3-22.1-9.9-29.2 -6.6-7.1-14.9-10.7-24.9-10.7 -9.9 0-18.4 3.6-25.3 10.7C754.8 573.5 751.4 582.8 751.4 594.4z" />
      <path d="M1070.2 246.9v-21.1H1186v17.1c-11.4 12.1-22.7 28.2-33.9 48.3 -11.2 20.1-19.8 40.8-25.9 62 -4.4 15-7.2 31.4-8.4 49.2h-22.6c0.2-14.1 3-31.1 8.3-51 5.3-19.9 12.9-39.2 22.8-57.7 9.9-18.5 20.4-34.1 31.6-46.8H1070.2z" />
      <path d="M1575.5 182.6c-9.1-3.3-15.9-8.1-20.3-14.3 -4.4-6.2-6.6-13.6-6.6-22.2 0-13 4.7-24 14-32.8 9.4-8.9 21.8-13.3 37.4-13.3 15.6 0 28.2 4.5 37.7 13.6 9.5 9.1 14.3 20.1 14.3 33.1 0 8.3-2.2 15.5-6.5 21.7 -4.4 6.1-11 10.9-19.8 14.2 11 3.6 19.3 9.4 25.1 17.3 5.7 8 8.6 17.5 8.6 28.6 0 15.3-5.4 28.2-16.2 38.6 -10.8 10.4-25.1 15.6-42.7 15.6 -17.7 0-31.9-5.2-42.7-15.7 -10.8-10.5-16.2-23.5-16.2-39.1 0-11.6 3-21.4 8.9-29.2C1556.2 190.8 1564.6 185.4 1575.5 182.6L1575.5 182.6zM1571.1 145.3c0 8.5 2.7 15.4 8.2 20.8 5.5 5.4 12.5 8.1 21.2 8.1 8.5 0 15.4-2.7 20.8-8 5.4-5.3 8.1-11.9 8.1-19.6 0-8.1-2.8-14.8-8.4-20.3 -5.6-5.5-12.5-8.2-20.8-8.2 -8.4 0-15.3 2.7-20.9 8.1C1573.9 131.4 1571.1 137.8 1571.1 145.3zM1564 228c0 6.3 1.5 12.3 4.5 18.2 3 5.9 7.4 10.4 13.2 13.6 5.9 3.2 12.2 4.8 18.9 4.8 10.5 0 19.2-3.4 26-10.1 6.8-6.8 10.3-15.3 10.3-25.8 0-10.6-3.5-19.3-10.6-26.2 -7-6.9-15.8-10.4-26.4-10.4 -10.3 0-18.9 3.4-25.7 10.3C1567.4 209.2 1564 217.7 1564 228z" />
      <path d="M1978.3 227.6l21.1-2c1.8 9.9 5.2 17.1 10.3 21.6 5 4.5 11.5 6.7 19.4 6.7 6.8 0 12.7-1.5 17.8-4.6 5.1-3.1 9.3-7.2 12.5-12.4 3.3-5.2 6-12.1 8.2-20.9 2.2-8.8 3.3-17.7 3.3-26.9 0-1 0-2.4-0.1-4.4 -4.4 7-10.4 12.7-18 17 -7.6 4.4-15.8 6.5-24.7 6.5 -14.8 0-27.3-5.4-37.6-16.1C1980.2 181.5 1975 167.3 1975 149.8c0-18.1 5.4-32.8 16.1-43.8 10.7-11.1 24.1-16.6 40.2-16.6 11.6 0 22.3 3.1 31.9 9.4 9.6 6.3 17 15.2 22 26.8 5 11.6 7.5 28.4 7.5 50.4 0 22.9-2.5 41.1-7.4 54.6 -5 13.5-12.3 23.9-22.2 30.9 -9.8 7.1-21.3 10.6-34.5 10.6 -14 0-25.4-3.9-34.3-11.7C1985.5 252.6 1980.1 241.7 1978.3 227.6zM2068.3 148.7c0-12.6-3.4-22.6-10.1-30 -6.7-7.4-14.8-11.1-24.2-11.1 -9.8 0-18.3 4-25.5 12 -7.2 8-10.9 18.3-10.9 31 0 11.4 3.4 20.7 10.3 27.8 6.9 7.1 15.4 10.7 25.5 10.7 10.2 0 18.5-3.6 25.1-10.7C2065 171.1 2068.3 161.3 2068.3 148.7z" />
      <path d="M2440.9 348.5h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V348.5z" />
      <path d="M2497.3 260.3c0-21.2 2.2-38.2 6.5-51.1 4.4-12.9 10.8-22.8 19.4-29.8 8.6-7 19.4-10.5 32.4-10.5 9.6 0 18 1.9 25.3 5.8 7.2 3.9 13.2 9.4 17.9 16.7 4.7 7.3 8.4 16.2 11.1 26.6 2.7 10.5 4 24.6 4 42.3 0 21-2.2 37.9-6.5 50.8 -4.3 12.9-10.8 22.9-19.3 29.9 -8.6 7-19.4 10.6-32.5 10.6 -17.3 0-30.8-6.2-40.6-18.6C2503.2 318.1 2497.3 293.9 2497.3 260.3zM2519.9 260.3c0 29.4 3.4 48.9 10.3 58.7 6.9 9.7 15.4 14.6 25.5 14.6 10.1 0 18.6-4.9 25.5-14.6 6.9-9.8 10.3-29.3 10.3-58.6 0-29.5-3.4-49-10.3-58.7 -6.9-9.7-15.4-14.5-25.7-14.5 -10.1 0-18.1 4.3-24.2 12.8C2523.7 210.7 2519.9 230.9 2519.9 260.3z" />
      <path d="M2792.7 586.4h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V586.4z" />
      <path d="M2931.9 586.4h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V586.4z" />
      <path d="M3048.7 942.7h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V942.7z" />
      <path d="M3220.6 921.6v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H3220.6z" />
      <path d="M3193.1 1285.7h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V1285.7z" />
      <path d="M3249.7 1238.5l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9 6.1 5.5 13.4 8.2 22.2 8.2 10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1 -4.2 6.3-10.3 11.4-18.4 15.1 10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14S3251.1 1253.4 3249.7 1238.5z" />
      <path d="M3288.7 1686.6h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V1686.6z" />
      <path d="M3415.5 1686.6v-42.8h-77.6v-20.1l81.7-116h17.9v116h24.2v20.1h-24.2v42.8H3415.5zM3415.5 1623.6V1542.9l-56 80.7H3415.5z" />
      <path d="M3365.2 2115.2h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V2115.2z" />
      <path d="M3421.6 2068.3l23.1-2c1.7 11.2 5.7 19.7 11.9 25.3s13.7 8.5 22.5 8.5c10.6 0 19.5-4 26.9-12 7.3-8 11-18.6 11-31.7 0-12.5-3.5-22.4-10.6-29.7 -7-7.2-16.3-10.9-27.6-10.9 -7.1 0-13.5 1.6-19.2 4.8 -5.7 3.2-10.2 7.4-13.4 12.5l-20.6-2.7 17.3-91.9h89v21H3460.4l-9.6 48.1c10.7-7.5 22-11.2 33.8-11.2 15.6 0 28.8 5.4 39.6 16.2 10.7 10.8 16.1 24.7 16.1 41.7 0 16.2-4.7 30.2-14.2 42 -11.5 14.5-27.1 21.7-47 21.7 -16.3 0-29.6-4.6-39.9-13.7C3428.9 2095.4 3423.1 2083.4 3421.6 2068.3L3421.6 2068.3z" />
      <path d="M3385.9 2553.3h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V2553.3z" />
      <path d="M3556.3 2418.1l-21.9 1.7c-2-8.6-4.7-14.9-8.3-18.8 -5.9-6.3-13.3-9.4-22-9.4 -7 0-13.1 2-18.4 5.9 -6.9 5-12.4 12.4-16.4 22.1 -4 9.7-6.1 23.5-6.2 41.4 5.3-8.1 11.8-14 19.4-17.9 7.6-3.9 15.7-5.9 24-5.9 14.6 0 27.1 5.4 37.4 16.2 10.3 10.8 15.4 24.7 15.4 41.8 0 11.2-2.4 21.7-7.3 31.3 -4.8 9.6-11.5 17-20 22.2 -8.5 5.1-18.1 7.7-28.8 7.7 -18.3 0-33.2-6.7-44.8-20.2 -11.6-13.5-17.3-35.7-17.3-66.6 0-34.6 6.4-59.7 19.2-75.4 11.1-13.7 26.2-20.5 45-20.5 14.1 0 25.6 3.9 34.6 11.8C3549.2 2393.3 3554.6 2404.2 3556.3 2418.1zM3466.6 2495.3c0 7.6 1.6 14.8 4.8 21.7s7.7 12.2 13.5 15.8c5.8 3.6 11.8 5.4 18.2 5.4 9.3 0 17.3-3.7 23.9-11.2s10-17.7 10-30.5c0-12.4-3.3-22.1-9.9-29.2 -6.6-7.1-14.9-10.7-24.9-10.7 -9.9 0-18.4 3.6-25.3 10.7C3470.1 2474.4 3466.6 2483.7 3466.6 2495.3z" />
      <path d="M3367.9 3073.5h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V3073.5z" />
      <path d="M3425.8 2918v-21.1h115.8v17.1c-11.4 12.1-22.7 28.2-33.9 48.3 -11.2 20.1-19.8 40.8-25.9 62 -4.4 15-7.2 31.4-8.4 49.2H3450.8c0.2-14.1 3-31.1 8.3-51 5.3-19.9 12.9-39.2 22.8-57.7 9.9-18.5 20.4-34.1 31.6-46.8H3425.8z" />
      <path d="M3361.1 3535.9h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V3535.9z" />
      <path d="M3451.3 3438.9c-9.1-3.3-15.9-8.1-20.3-14.3 -4.4-6.2-6.6-13.6-6.6-22.2 0-13 4.7-24 14-32.8 9.4-8.9 21.8-13.3 37.4-13.3 15.6 0 28.2 4.5 37.7 13.6 9.5 9.1 14.3 20.1 14.3 33.1 0 8.3-2.2 15.5-6.5 21.7 -4.4 6.1-11 10.9-19.8 14.2 11 3.6 19.3 9.4 25.1 17.3 5.7 8 8.6 17.5 8.6 28.6 0 15.3-5.4 28.2-16.2 38.6 -10.8 10.4-25.1 15.6-42.7 15.6 -17.7 0-31.9-5.2-42.7-15.7 -10.8-10.5-16.2-23.5-16.2-39.1 0-11.6 2.9-21.4 8.9-29.2C3432 3447.1 3440.4 3441.7 3451.3 3438.9zM3447 3401.6c0 8.5 2.7 15.4 8.2 20.8 5.5 5.4 12.5 8.1 21.2 8.1 8.5 0 15.4-2.7 20.8-8 5.4-5.3 8.1-11.9 8.1-19.6 0-8.1-2.8-14.8-8.4-20.3 -5.6-5.5-12.5-8.2-20.8-8.2 -8.4 0-15.3 2.7-20.9 8.1C3449.7 3387.7 3447 3394.1 3447 3401.6zM3439.9 3484.3c0 6.3 1.5 12.3 4.5 18.2 3 5.9 7.4 10.4 13.2 13.6 5.9 3.2 12.2 4.8 18.9 4.8 10.5 0 19.2-3.4 26-10.1 6.8-6.8 10.3-15.3 10.3-25.8 0-10.6-3.5-19.3-10.6-26.2 -7-6.9-15.8-10.4-26.4-10.4 -10.3 0-18.9 3.4-25.7 10.3C3443.3 3465.5 3439.9 3474 3439.9 3484.3z" />
      <path d="M3296.5 4045.9h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V4045.9z" />
      <path d="M3356.2 4004.5l21.1-2c1.8 9.9 5.2 17.1 10.3 21.6s11.5 6.7 19.4 6.7c6.8 0 12.7-1.5 17.8-4.6 5.1-3.1 9.3-7.2 12.5-12.4 3.3-5.2 6-12.1 8.2-20.9 2.2-8.8 3.3-17.7 3.3-26.9 0-1 0-2.4-0.1-4.4 -4.4 7-10.4 12.7-18 17 -7.6 4.4-15.8 6.5-24.7 6.5 -14.8 0-27.3-5.4-37.6-16.1s-15.4-24.9-15.4-42.5c0-18.1 5.4-32.8 16.1-43.8 10.7-11.1 24.1-16.6 40.2-16.6 11.6 0 22.3 3.1 31.9 9.4 9.6 6.3 17 15.2 22 26.8 5 11.6 7.5 28.4 7.5 50.4 0 22.9-2.5 41.1-7.4 54.6 -5 13.5-12.3 23.9-22.2 30.9 -9.8 7.1-21.3 10.6-34.5 10.6 -14 0-25.4-3.9-34.3-11.7C3363.3 4029.5 3358 4018.6 3356.2 4004.5zM3446.2 3925.5c0-12.6-3.4-22.6-10.1-30 -6.7-7.4-14.8-11.1-24.2-11.1 -9.8 0-18.3 4-25.5 12 -7.2 8-10.9 18.3-10.9 31 0 11.4 3.4 20.7 10.3 27.8 6.9 7.1 15.4 10.7 25.5 10.7 10.2 0 18.5-3.6 25.1-10.7C3442.9 3948 3446.2 3938.1 3446.2 3925.5z" />
      <path d="M3200.2 4402.9v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8s16.2-16.8 28.7-27.1c19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22s-14.3-9-24.4-9c-10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H3200.2z" />
      <path d="M3223.8 4335.8c0-21.2 2.2-38.2 6.5-51.1 4.4-12.9 10.8-22.8 19.4-29.8 8.6-7 19.4-10.5 32.4-10.5 9.6 0 18 1.9 25.3 5.8 7.2 3.9 13.2 9.4 17.9 16.7 4.7 7.3 8.4 16.2 11.1 26.6 2.7 10.5 4 24.6 4 42.3 0 21-2.2 37.9-6.5 50.8 -4.3 12.9-10.8 22.9-19.3 29.9 -8.6 7-19.4 10.6-32.5 10.6 -17.3 0-30.8-6.2-40.6-18.6C3229.7 4393.6 3223.8 4369.4 3223.8 4335.8zM3246.4 4335.8c0 29.4 3.4 48.9 10.3 58.7s15.4 14.6 25.5 14.6c10.1 0 18.6-4.9 25.5-14.6 6.9-9.8 10.3-29.3 10.3-58.6 0-29.5-3.4-49-10.3-58.7 -6.9-9.7-15.4-14.5-25.7-14.5 -10.1 0-18.1 4.3-24.2 12.8C3250.2 4286.2 3246.4 4306.4 3246.4 4335.8z" />
      <path d="M2977.5 4756.5v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8s16.2-16.8 28.7-27.1c19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22s-14.3-9-24.4-9c-10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H2977.5z" />
      <path d="M3083.9 4777.6h-22v-140c-5.3 5-12.2 10.1-20.8 15.1s-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V4777.6z" />
      <path d="M2690.3 4997.2v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8s16.2-16.8 28.7-27.1c19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22s-14.3-9-24.4-9c-10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H2690.3z" />
      <path d="M2829.4 4997.2v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8s16.2-16.8 28.7-27.1c19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22s-14.3-9-24.4-9c-10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3s15 21.3 15 35.4c0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H2829.4z" />
      <path d="M2305.1 5120.9v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8s16.2-16.8 28.7-27.1c19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22s-14.3-9-24.4-9c-10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H2305.1z" />
      <path d="M2328.9 5094.8l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9s13.4 8.2 22.2 8.2c10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1s-10.3 11.4-18.4 15.1c10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14S2330.4 5109.7 2328.9 5094.8z" />
      <path d="M1990.5 5219.6v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H1990.5z" />
      <path d="M2084.6 5240.7v-42.8h-77.6v-20.1l81.7-116h17.9v116h24.2v20.1h-24.2v42.8H2084.6zM2084.6 5177.7v-80.7l-56 80.7H2084.6z" />
      <path d="M1663.1 5233.7v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H1663.1z" />
      <path d="M1686.7 5207.9l23.1-2c1.7 11.2 5.7 19.7 11.9 25.3 6.2 5.7 13.7 8.5 22.5 8.5 10.6 0 19.5-4 26.9-12 7.3-8 11-18.6 11-31.7 0-12.5-3.5-22.4-10.6-29.7 -7-7.2-16.3-10.9-27.6-10.9 -7.1 0-13.5 1.6-19.2 4.8 -5.7 3.2-10.2 7.4-13.4 12.5l-20.6-2.7 17.3-91.9h89v21h-71.4l-9.6 48.1c10.7-7.5 22-11.2 33.8-11.2 15.6 0 28.8 5.4 39.6 16.2 10.7 10.8 16.1 24.7 16.1 41.7 0 16.2-4.7 30.2-14.2 42 -11.5 14.5-27.1 21.7-47 21.7 -16.3 0-29.6-4.6-39.9-13.7S1688.2 5223 1686.7 5207.9L1686.7 5207.9z" />
      <path d="M1321.2 5169.3v21.1h-118.3c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5s-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5s-6.8 7.9-9.3 11.9H1321.2z" />
      <path d="M1458.9 5055.2l-21.9 1.7c-2-8.6-4.7-14.9-8.3-18.8 -5.9-6.3-13.3-9.4-22-9.4 -7 0-13.1 2-18.4 5.9 -6.9 5-12.4 12.4-16.4 22.1 -4 9.7-6.1 23.5-6.2 41.4 5.3-8.1 11.8-14 19.4-17.9 7.6-3.9 15.7-5.9 24-5.9 14.6 0 27.1 5.4 37.4 16.2 10.3 10.8 15.4 24.7 15.4 41.8 0 11.2-2.4 21.7-7.3 31.3 -4.8 9.6-11.5 17-20 22.2 -8.5 5.1-18.1 7.7-28.8 7.7 -18.3 0-33.2-6.7-44.8-20.2s-17.3-35.7-17.3-66.6c0-34.6 6.4-59.7 19.2-75.4 11.1-13.7 26.2-20.5 45-20.5 14.1 0 25.6 3.9 34.6 11.8C1451.7 5030.4 1457.1 5041.3 1458.9 5055.2L1458.9 5055.2zM1369.2 5132.4c0 7.6 1.6 14.8 4.8 21.7 3.2 6.9 7.7 12.2 13.5 15.8s11.8 5.4 18.2 5.4c9.3 0 17.3-3.7 23.9-11.2 6.7-7.5 10-17.7 10-30.5 0-12.4-3.3-22.1-9.9-29.2 -6.6-7.1-14.9-10.7-24.9-10.7 -9.9 0-18.4 3.6-25.3 10.7C1372.6 5111.5 1369.2 5120.8 1369.2 5132.4L1369.2 5132.4z" />
      <path d="M981.8 5029.9v21.1H863.5c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H981.8z" />
      <path d="M1006.9 4895.5v-21.1h115.8v17.1c-11.4 12.1-22.7 28.2-33.9 48.3 -11.2 20.1-19.8 40.8-25.9 62 -4.4 15-7.2 31.4-8.4 49.2H1031.9c0.2-14.1 3-31.1 8.3-51 5.3-19.9 12.9-39.2 22.8-57.7 9.9-18.5 20.4-34.1 31.6-46.8H1006.9z" />
      <path d="M721.4 4771.8v21.1H603.1c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H721.4z" />
      <path d="M778.9 4695.9c-9.1-3.3-15.9-8.1-20.3-14.3 -4.4-6.2-6.6-13.6-6.6-22.2 0-13 4.7-24 14-32.8 9.4-8.9 21.8-13.3 37.4-13.3 15.6 0 28.2 4.5 37.7 13.6 9.5 9.1 14.3 20.1 14.3 33.1 0 8.3-2.2 15.5-6.5 21.7 -4.4 6.1-11 10.9-19.8 14.2 11 3.6 19.3 9.4 25.1 17.3 5.7 8 8.6 17.5 8.6 28.6 0 15.3-5.4 28.2-16.2 38.6 -10.8 10.4-25.1 15.6-42.7 15.6 -17.7 0-31.9-5.2-42.7-15.7 -10.8-10.5-16.2-23.5-16.2-39.1 0-11.6 3-21.4 8.9-29.2C759.6 4704.1 768 4698.7 778.9 4695.9zM774.5 4658.6c0 8.5 2.7 15.4 8.2 20.8 5.5 5.4 12.5 8.1 21.2 8.1 8.5 0 15.4-2.7 20.8-8 5.4-5.3 8.1-11.9 8.1-19.6 0-8.1-2.8-14.8-8.4-20.3 -5.6-5.5-12.5-8.2-20.8-8.2 -8.4 0-15.3 2.7-20.9 8.1C777.3 4644.7 774.5 4651.1 774.5 4658.6zM767.4 4741.3c0 6.3 1.5 12.3 4.5 18.2 3 5.9 7.4 10.4 13.2 13.6 5.9 3.2 12.2 4.8 18.9 4.8 10.5 0 19.2-3.4 26-10.1 6.8-6.8 10.3-15.3 10.3-25.8 0-10.6-3.5-19.3-10.6-26.2 -7-6.9-15.8-10.4-26.4-10.4 -10.3 0-18.9 3.4-25.7 10.3C770.8 4722.5 767.4 4731 767.4 4741.3z" />
      <path d="M511.2 4425.9v21.1H392.9c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H511.2z" />
      <path d="M538.2 4405.6l21.1-2c1.8 9.9 5.2 17.1 10.3 21.6 5 4.5 11.5 6.7 19.4 6.7 6.8 0 12.7-1.5 17.8-4.6 5.1-3.1 9.3-7.2 12.5-12.4 3.3-5.2 6-12.1 8.2-20.9 2.2-8.8 3.3-17.7 3.3-26.9 0-1 0-2.4-0.1-4.4 -4.4 7-10.4 12.7-18 17 -7.6 4.4-15.8 6.5-24.7 6.5 -14.8 0-27.3-5.4-37.6-16.1 -10.3-10.7-15.4-24.9-15.4-42.5 0-18.1 5.4-32.8 16.1-43.8 10.7-11.1 24.1-16.6 40.2-16.6 11.6 0 22.3 3.1 31.9 9.4 9.6 6.3 17 15.2 22 26.8 5 11.6 7.5 28.4 7.5 50.4 0 22.9-2.5 41.1-7.4 54.6 -5 13.5-12.3 23.9-22.2 30.9 -9.8 7.1-21.3 10.6-34.5 10.6 -14 0-25.4-3.9-34.3-11.7C545.3 4430.6 540 4419.7 538.2 4405.6zM628.2 4326.6c0-12.6-3.4-22.6-10.1-30 -6.7-7.4-14.8-11.1-24.2-11.1 -9.8 0-18.3 4-25.5 12 -7.2 8-10.9 18.3-10.9 31 0 11.4 3.4 20.7 10.3 27.8 6.9 7.1 15.4 10.7 25.5 10.7 10.2 0 18.5-3.6 25.1-10.7C624.9 4349.1 628.2 4339.2 628.2 4326.6z" />
      <path d="M181.4 3969.2l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9 6.1 5.5 13.4 8.2 22.2 8.2 10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1 -4.2 6.3-10.3 11.4-18.4 15.1 10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14C188.8 3996.3 182.9 3984.1 181.4 3969.2L181.4 3969.2z" />
      <path d="M320.5 3928.2c0-21.2 2.2-38.2 6.5-51.1 4.4-12.9 10.8-22.8 19.4-29.8 8.6-7 19.4-10.5 32.4-10.5 9.6 0 18 1.9 25.3 5.8 7.2 3.9 13.2 9.4 17.9 16.7 4.7 7.3 8.4 16.2 11.1 26.6 2.7 10.5 4 24.6 4 42.3 0 21-2.2 37.9-6.5 50.8 -4.3 12.9-10.8 22.9-19.3 29.9 -8.6 7-19.4 10.6-32.5 10.6 -17.3 0-30.8-6.2-40.6-18.6C326.4 3986.1 320.5 3961.8 320.5 3928.2zM343.1 3928.2c0 29.4 3.4 48.9 10.3 58.7 6.9 9.7 15.4 14.6 25.5 14.6 10.1 0 18.6-4.9 25.5-14.6 6.9-9.8 10.3-29.3 10.3-58.6 0-29.5-3.4-49-10.3-58.7 -6.9-9.7-15.4-14.5-25.7-14.5 -10.1 0-18.1 4.3-24.2 12.8C346.8 3878.7 343.1 3898.8 343.1 3928.2z" />
      <path d="M106.2 3435.5l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9 6.1 5.5 13.4 8.2 22.2 8.2 10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1 -4.2 6.3-10.3 11.4-18.4 15.1 10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14C113.6 3462.5 107.7 3450.4 106.2 3435.5z" />
      <path d="M328 3482.7h-22v-140c-5.3 5-12.2 10.1-20.8 15.1 -8.6 5-16.3 8.8-23.1 11.4v-21.2c12.3-5.8 23-12.8 32.2-21 9.2-8.2 15.7-16.2 19.5-23.9h14.2V3482.7z" />
      <path d="M103.8 3009.6l22-2.9c2.5 12.5 6.8 21.4 12.9 26.9 6.1 5.5 13.4 8.2 22.2 8.2 10.3 0 19.1-3.6 26.2-10.7 7.1-7.2 10.7-16 10.7-26.6 0-10.1-3.3-18.4-9.9-25 -6.6-6.6-15-9.8-25.1-9.8 -4.2 0-9.3 0.8-15.5 2.4l2.4-19.3c1.5 0.2 2.6 0.2 3.5 0.2 9.4 0 17.8-2.4 25.3-7.3 7.5-4.9 11.2-12.4 11.2-22.6 0-8.1-2.7-14.7-8.2-20 -5.5-5.3-12.5-7.9-21.1-7.9 -8.5 0-15.7 2.7-21.4 8.1 -5.7 5.4-9.4 13.4-11 24.2l-22-3.9c2.7-14.7 8.8-26.1 18.3-34.2 9.5-8.1 21.4-12.1 35.5-12.1 9.8 0 18.8 2.1 27 6.3 8.2 4.2 14.5 9.9 18.9 17.2 4.4 7.2 6.5 14.9 6.5 23.1 0 7.7-2.1 14.8-6.2 21.1 -4.2 6.3-10.3 11.4-18.4 15.1 10.6 2.4 18.8 7.5 24.7 15.2 5.9 7.7 8.8 17.3 8.8 28.9 0 15.6-5.7 28.9-17.1 39.7 -11.4 10.9-25.8 16.3-43.2 16.3 -15.7 0-28.7-4.7-39.1-14C111.2 3036.6 105.3 3024.5 103.8 3009.6z" />
      <path d="M358.3 3035.7v21.1H240c-0.2-5.3 0.7-10.4 2.6-15.3 3-8.1 7.8-16 14.5-23.8 6.6-7.8 16.2-16.8 28.7-27.1 19.4-16 32.6-28.6 39.4-37.9 6.8-9.3 10.3-18.1 10.3-26.4 0-8.7-3.1-16.1-9.3-22 -6.2-6-14.3-9-24.4-9 -10.6 0-19 3.2-25.4 9.5 -6.3 6.3-9.6 15.1-9.6 26.4l-22.6-2.3c1.5-16.8 7.4-29.7 17.5-38.5 10.1-8.8 23.6-13.2 40.6-13.2 17.2 0 30.8 4.8 40.8 14.3 10 9.5 15 21.3 15 35.4 0 7.2-1.5 14.2-4.4 21.1 -2.9 6.9-7.8 14.2-14.6 21.9 -6.8 7.6-18.1 18.1-33.9 31.5 -13.2 11.1-21.6 18.6-25.4 22.5 -3.7 3.9-6.8 7.9-9.3 11.9H358.3z" />
    </>
  );
}

export default UnsNumbers;
