import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionReportGenerate($input: ReportGenerateActionInput!) {
    actionReportGenerate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      results
    }
  }
  ${ErrorMessageInfo}
`;
