import PropTypes from 'prop-types';
import BoxIcon from '../../../../Icons/BoxIcon';

import { transformNestedData } from '../../../../../..';
import SingleArchSVG from './Icons/SingleArchSVG';
import SingleToothSVG from './Icons/SingleToothSVG';
import './PopupTrigger.css';

function ChartSelectionPopupTrigger({
  nested = false,
  toothChart = true,
  nestedData = null,
  guideCodeId = null,
}) {
  if (nested) {
    const isNestedDataEmpty =
      (transformNestedData(nestedData)[guideCodeId]?.length || 0) === 0;
    if (toothChart) {
      return <SingleToothSVG isEmpty={isNestedDataEmpty} />;
    }

    return <SingleArchSVG isEmpty={isNestedDataEmpty} />;
  }

  return <BoxIcon name="bx-edit" />;
}

ChartSelectionPopupTrigger.propTypes = {
  nested: PropTypes.bool,
  toothChart: PropTypes.bool,
  nestedData: PropTypes.object,
  guideCodeId: PropTypes.string,
};

export default ChartSelectionPopupTrigger;
