import { gql } from '@apollo/client';

export default gql`
  query priceListOverpanel($id: ID) {
    softwareNodes {
      id
      name
      nodeType
      nodes
      designType {
        id
        name
        disableInHouse
        billByTooth
        billByArch
        archChart
        toothChart
        manufacturers {
          id
          name
          isInHouse
        }
      }
    }
    customerLocation(id: $id) {
      id
      turnaroundTimeId
      language
      allowNested
      numberingSystem
      assignedManufacturers {
        id
        isDefault
        manufacturerOrganization {
          id
          name
          isInHouse
          requires3ox
        }
      }
      priceList {
        id
        name
        slug
        priceListItems {
          id
          defaultTurnaroundTime
          turnaroundTimeId
          designTypeId
        }
        turnaroundTimes {
          id
          name
          key
          value
          text
          priority
          isRush
        }
        designTypes {
          id
          name
          key
          value
          text
          billByTooth
          billByArch
          toothChart
          archChart
          designApproval
          acceptRushDesigns
          disableInHouse
          enableSimpleUploader
          designTypeCaseFolders {
            id
            designTypeId
            caseFolderId
            required
          }
          caseFolders {
            id
            name
            folderName
            description
            mimeTypes
            allowMultiple
          }
          manufacturers {
            id
            name
            key
            value
            text
            isInHouse
          }
          modifierGroups {
            id
            name
            label
            modifiers {
              id
              default
              name
              text
              key
              value
            }
          }
        }
      }
    }
  }
`;
