import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCustomerLocationDesignPreference(
    $input: CustomerLocationDesignPreferenceUpdateInput!
  ) {
    updateCustomerLocationDesignPreference(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designPreferenceProfiles {
        id
        name
        designPreferences {
          id
          name
          values
        }
      }
      customerLocation {
        id
        name
        slug
        designProfiles
        customInstructions
        priceList {
          id
          designTypes {
            id
            name
            designPreferenceProfileId
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
