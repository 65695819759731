import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { BoxIcon, Placeholder } from '../../../../../../shared';
import Feedback from '../../../../../../shared/Feedback/Feedback';
import { HeaderActions } from '../../HeaderActions';

function HeaderOrderActions({
  changeableLayout,
  isLoading = false,
  order = null,
  operationNameHandler,
  setChangeableLayout,
  app,
  ...rest
}) {
  const { pathname } = useLocation();
  const props = {
    changeableLayout,
    isLoading,
    order,
    operationNameHandler,
    setChangeableLayout,
    app,
    ...rest,
  };
  return order || !isLoading ? (
    <div className="detailsHeaderActionColumn">
      <div className="is-flex is-align-items-center is-justify-content-flex-end">
        {changeableLayout && (
          <div className="buttons">
            <button
              type="button"
              className="button is-small is-danger is-outlined"
              onClick={() => operationNameHandler('reset')}
            >
              Reset
            </button>
            <button
              type="button"
              className="button is-small is-primary"
              onClick={() => operationNameHandler('save')}
            >
              Save
            </button>
            <button
              type="button"
              className="button is-small is-dark"
              onClick={() => operationNameHandler('exit')}
            >
              Exit
            </button>
          </div>
        )}

        {!changeableLayout && (
          <>
            {pathname.includes('design-preferences') && (
              <div style={{ position: 'relative', marginRight: '1em' }}>
                <button
                  onClick={() => setChangeableLayout((prev) => !prev)}
                  type="button"
                  className="button is-primary"
                >
                  <BoxIcon name="bx-edit" />
                  <span>Edit layout</span>
                </button>
              </div>
            )}

            <Feedback
              showFeedback={{
                app: import.meta.env.VITE_APP_NAME,
                state: order?.state,
              }}
              data={order}
              redesign={app === 'customer_portal'}
            />

            <div className="ml-2" style={{ position: 'relative' }}>
              <HeaderActions {...props} />
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <Placeholder width="100px" />
  );
}

HeaderOrderActions.propTypes = {
  order: PropTypes.object,
  changeableLayout: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  operationNameHandler: PropTypes.func.isRequired,
  setChangeableLayout: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
};

export default HeaderOrderActions;
