import getFileExtension from './getFileExtension';

function fileTypeFinder(acceptedTypes, otherType, file) {
  const extension = getFileExtension(file);

  const fileType = acceptedTypes.find((acceptedType) => {
    if (Array.isArray(acceptedType.extension)) {
      return acceptedType.extension.some((ext) => ext === extension);
    }

    return acceptedType.extension === extension;
  });

  if (fileType) {
    return fileType;
  }

  return otherType;
}

export default fileTypeFinder;
