import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './SingleDateSelect.css';

function customPropsBuilder(dateOptionsMode) {
  const props =
    dateOptionsMode === 'past'
      ? {
          isOutsideRange: (day) => moment().diff(day) < 0,
        }
      : {};
  return props;
}

function SingleDateSelect({
  onCalendarDateChange,
  date,
  anchorDirection = 'left',
  openDirection = 'down',
  dateOptionsMode = 'future',
  disabled = false,
}) {
  const [focus, setFocus] = useState(false);

  return (
    <div className="singleDateWrap">
      <SingleDatePicker
        onDateChange={(d) => onCalendarDateChange(d)}
        onFocusChange={({ focused }) => setFocus(focused)}
        focused={focus}
        numberOfMonths={1}
        date={date}
        showDefaultInputIcon
        anchorDirection={anchorDirection}
        openDirection={openDirection}
        {...customPropsBuilder(dateOptionsMode)}
        disabled={disabled}
      />
    </div>
  );
}

SingleDateSelect.propTypes = {
  date: PropTypes.object.isRequired,
  onCalendarDateChange: PropTypes.func.isRequired,
  anchorDirection: PropTypes.string,
  openDirection: PropTypes.string,
  dateOptionsMode: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SingleDateSelect;
