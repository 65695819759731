/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Info, OverpanelDrawerContext } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function FieldMetaDataWrapper({
  title,
  disabled = false,
  required = false,
  children = null,
  infoText = null,
}) {
  const { t } = useTranslation('orders');

  const { toggle, setDrawerBody } = useContext(OverpanelDrawerContext);

  const infoCallback = () => {
    toggle();
    setDrawerBody(<p>{infoText}</p>);
  };

  return (
    <div className="px-2">
      <label
        className="label"
        id={title.toLowerCase().replace(/ /g, '')}
        style={{
          color: disabled ? '#c7c7c7' : 'inherit',
        }}
      >
        {t(title)}
        {required && (
          <span
            style={{
              margin: '-.2em 0 0 .2em',
              color: '#E40000',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            *
          </span>
        )}
        {infoText && (
          <Info
            dataTooltip="Click to learn more"
            className="has-tooltip-arrow has-tooltip-bottom"
            infoCallback={infoCallback}
          />
        )}
      </label>
      <div className="is-flex is-align-items-center is-justify-content-flex-start">
        {children}
      </div>
    </div>
  );
}

FieldMetaDataWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  infoText: PropTypes.node,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FieldMetaDataWrapper;
