import { gql } from '@apollo/client';
import { ContactInfo } from '../fragments';

export default gql`
  query contacts($contactableId: ID, $contactableType: String, $role: Int) {
    contacts(
      contactableId: $contactableId
      contactableType: $contactableType
      role: $role
    ) {
      ...ContactInfo
    }
  }
  ${ContactInfo}
`;
