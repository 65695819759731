/* eslint-disable no-nested-ternary */
import {
  ChartSelection,
  fdiMapping,
  palmerNotationDisplay,
  toothDisplayRange,
} from '@fullcontour/common';
import PropTypes from 'prop-types';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetaDataTeeth({
  orderItem,
  selectedDesignType = null,
  numberingSystem,
  index,
  customerLocation,
}) {
  const displayedTooth = () => {
    if (numberingSystem === 'uns') {
      return toothDisplayRange(orderItem.teeth);
    }

    if (numberingSystem === 'fdi') {
      return toothDisplayRange(
        orderItem.teeth.map((tooth) => fdiMapping[tooth]),
      );
    }

    if (numberingSystem === 'palmer') {
      return orderItem.teeth.map((tooth) => palmerNotationDisplay(tooth));
    }
    return null;
  };
  return (
    <FieldMetadataWrapper
      title="Teeth #"
      disabled={!selectedDesignType?.toothChart}
      // infoText="Depending on the order type, you will see either an interactive arch
      // chart or tooth chart when you click on these icons."
    >
      <ChartSelection
        customerLocation={customerLocation}
        index={index}
        selectedDesignType={selectedDesignType}
      />
      {orderItem.teeth.length && selectedDesignType?.toothChart ? (
        <div style={{ display: 'inline' }}>{displayedTooth()}</div>
      ) : null}
    </FieldMetadataWrapper>
  );
}

FieldMetaDataTeeth.propTypes = {
  orderItem: PropTypes.object.isRequired,
  selectedDesignType: PropTypes.object,
  numberingSystem: PropTypes.string.isRequired,
  customerLocation: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default FieldMetaDataTeeth;
