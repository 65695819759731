import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import useTranslatedPathname from './useTranslatedPathname';

function BreadCrumbPathLast(props) {
  const { p, i, arr } = props;
  const params = useParams();
  const pathname = useTranslatedPathname(params, p);

  return (
    <li
      style={{ textTransform: 'capitalize' }}
      className={`${i === arr.length - 1 ? 'is-active' : ''}`}
    >
      <span className="ml-2">{pathname}</span>
    </li>
  );
}

BreadCrumbPathLast.propTypes = {
  p: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  arr: PropTypes.array.isRequired,
};

export default BreadCrumbPathLast;
