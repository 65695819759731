function nameValueGetter(params) {
  const data = params.data || {};

  const firstName = data?.attributes?.firstName || data?.firstName || '';
  const lastName = data?.attributes?.lastName || data?.lastName || '';

  return `${firstName} ${lastName}`.trim();
}

export default nameValueGetter;
