import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionDesignUserSetPassword(
    $input: DesignUserSetPasswordActionInput!
  ) {
    actionDesignUserSetPassword(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      user {
        id
      }
    }
  }
  ${ErrorMessageInfo}
`;
