const designTypes = [
  { id: 'empty', value: '', name: 'Select The Design Type' },
  { id: 'abutment', value: 'abutment', name: 'Abutment' },
  {
    id: 'allOnXHybrid',
    value: 'allOnXHybrid',
    name: 'All on X Hybrid',
    description:
      'All on X Hybrid screw retained implant bridge with gingiva as a monolithic design.',
  },
  {
    id: 'allOnXPreppedHybrid',
    value: 'allOnXPreppedHybrid',
    name: 'All on X Prepped Hybrid',
    description:
      'All on X Prepped Hybrid is screw retained implant bridge with prep attachments that will later have crowns designed over them.',
  },
  { id: 'bridge', value: 'bridge', name: 'Bridge' },
  {
    id: 'bridgeWithGingiva',
    value: 'bridgeWithGingiva',
    name: 'Bridge with Gingiva',
  },
  {
    id: 'bracketRemoval',
    value: 'bracketRemoval',
    name: 'Bracket Removal',
    description: 'Service that virtually removes the existing brackets.',
  },
  {
    id: 'clearAlignersAnterior',
    value: 'clearAlignersAnterior',
    name: 'Clear Aligners Anterior',
  },
  {
    id: 'clearAlignersFullArch',
    value: 'clearAlignersFullArch',
    name: 'Clear Aligner Full Arch',
  },
  {
    id: 'clearAlignerAnteriorRefinement',
    value: 'clearAlignerAnteriorRefinement',
    name: 'Clear Aligner Anterior Refinement',
    description:
      'Refinement aligner planning involves the same process as your previous aligner trays, except their job is to “fine-tune” your results with an additional adjusted course of trays. Refinements are to be selected only after an original Clear Aligner anterior plan has been designed by 3Shape Design Service.',
  },
  {
    id: 'clearAlignerFullArchRefinement',
    value: 'clearAlignerFullArchRefinement',
    name: 'Clear Aligner Full Arch Refinement',
    description:
      'Refinement aligner planning involves the same process as your previous aligner trays, except their job is to “fine-tune” your results with an additional adjusted course of trays. Refinements are to be selected only after an original Clear Aligner Full Arch plan has been designed by 3Shape Design Service.',
  },
  {
    id: 'clearRetainer',
    value: 'clearRetainer',
    name: 'Clear Retainer',
    description:
      'This design type is a model design for customers to print and produce a retainer tray for patients who have completed their clear aligner treatment plans. Retainers are to be worn nightly or as the Doctor recommends to ensure the teeth do not shift post-aligner treatment. ',
  },
  { id: 'coping', value: 'coping', name: 'Coping' },
  { id: 'crown', value: 'crown', name: 'Crown' },
  {
    name: 'Customized Impression Tray',
    id: 'customizedImpressionTray',
    value: 'customizedImpressionTray',
  },
  { id: 'denture', value: 'denture', name: 'Denture' },
  {
    id: 'implantBridgeWithGingiva',
    value: 'implantBridgeWithGingiva',
    name: 'Implant Bridge with Gingiva',
  },
  {
    id: 'guidedImplantSurgery',
    value: 'guidedImplantSurgery',
    name: 'Guided Implant Surgery',
  },
  { id: 'inlay', value: 'inlay', name: 'Inlay' },
  {
    id: 'lucid',
    value: 'lucid',
    name: 'LUCID',
    description:
      'LUCID is a premium Anterior design that allows users to fully customize their design with various design guide codes.',
  },
  { id: 'model', value: 'model', name: 'Model' },
  {
    id: 'modelCleanUp',
    value: 'modelCleanUp',
    name: 'Model Clean Up',
    description:
      'This service virtually cleans up STL files that have extra scan data and pulls. Our Model clean-up service defines margins, removes excess material, corrects double impressions, and bases models.',
  },
  {
    id: 'nightguard',
    value: 'nightguard',
    name: 'Nightguard',
    description:
      'A Nightguard is a flat plane occlusal guard that has no functionality. It is a removable, custom-fitted plastic appliance that fits between the upper and lower arch to prevent teeth from grinding against each other.',
  },
  { id: 'onlay', value: 'onlay', name: 'Onlay' },
  {
    id: 'orthoStudyModel',
    value: 'orthoStudyModel',
    name: 'Ortho Study Model',
    description: `Study models provide vital information about your patient's teeth and occlusion. The information given by a study model includes accurate replication of the teeth and the surrounding soft tissues. Our service adds a traditional ortho study model base and prepares the STL files for your printer.`,
  },
  {
    id: 'postCoreWithCrown',
    value: 'postCoreWithCrown',
    name: 'Post & Core with Crown',
  },
  {
    id: 'postAndCore',
    value: 'postAndCore',
    name: 'Post And Core',
  },
  {
    id: 'retainerModel',
    value: 'retainerModel',
    name: 'Retainer Model',
    description: `
  This design type is a model design for customers to print and produce a retainer tray for patients who have completed their clear aligner treatment plans. Retainers are to be worn nightly or as the Doctor recommends to ensure the teeth do not shift post-aligner treatment.`,
  },
  {
    id: 'RPD',
    value: 'RPD',
    name: 'RPD',
    description: `Removable Partial Denture is dental design that is used to replace multiple missing teeth as a removable option.`,
  },
  {
    id: 'screwRetainedFrame',
    value: 'screwRetainedFrame',
    name: 'Screw Retained Frame',
    description:
      'This design type is a coping bridge that is implant retained.',
  },
  {
    id: 'sironaSTLConversion',
    value: 'sironaSTLConversion',
    name: 'Sirona STL Conversion',
    description: 'Converting a .lab file from Sirona to .stl.',
  },
  {
    id: 'smileDesignVideo',
    value: 'smileDesignVideo',
    name: 'Smile Design Video',
  },
  {
    id: 'splint',
    value: 'splint',
    name: 'Splint',
    description: `An occlusal Splint is a functioning designed mouth guard for people who grind their teeth, have a history of pain and dysfunction associated with their bite or temporomandibular joints (TMJ), or have completed a full mouth reconstruction.`,
  },
  { id: 'temporaryCrown', value: 'temporaryCrown', name: 'Temporary Crown' },
  {
    id: 'temporaryPontic',
    value: 'temporaryPontic',
    name: 'Temporary Pontic',
  },
  { id: 'veneer', value: 'veneer', name: 'Veneer' },
];

export default designTypes;
