import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

function FormFieldArrayModal({
  showModal,
  modalTitle,
  setShowModal,
  buttontext,
  render,
}) {
  const { submitForm, values } = useFormikContext();

  return (
    <div className={`modal ${showModal ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </header>
        <section className="modal-card-body">{render(values)}</section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success"
            onClick={submitForm}
          >
            {buttontext}
          </button>
          <button
            type="button"
            className="button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

FormFieldArrayModal.propTypes = {
  buttontext: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default FormFieldArrayModal;
