import PropTypes from 'prop-types';
import { memo } from 'react';
import PriceListItemsTableBody from './PriceListItemsTableBody';
import ScrollingWrapper from './ScrollingWrapper';
import TableHeader from './TableHeader';

function PriceListItemsTable({
  listDesignTypes,
  listTurnaroundTimes,
  priceListItems,
  arrayHelpers = null,
  edit = false,
  ...rest
}) {
  const props = {
    listDesignTypes,
    listTurnaroundTimes,
    priceListItems,
    arrayHelpers,
    edit,
    ...rest,
  };
  return (
    <ScrollingWrapper>
      <TableHeader listTurnaroundTimes={listTurnaroundTimes} />
      <PriceListItemsTableBody
        priceListItems={priceListItems}
        listDesignTypes={listDesignTypes}
        listTurnaroundTimes={listTurnaroundTimes}
        arrayHelpers={arrayHelpers}
        edit={edit}
        {...props}
      />
    </ScrollingWrapper>
  );
}

PriceListItemsTable.propTypes = {
  listTurnaroundTimes: PropTypes.array.isRequired,
  listDesignTypes: PropTypes.array.isRequired,
  priceListItems: PropTypes.array.isRequired,
  arrayHelpers: PropTypes.object,
  edit: PropTypes.bool,
};

export default memo(PriceListItemsTable);
