import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { CurrentUserContext, GridContainer } from '../../../shared';

function IntegrationsListDataTable({ data = {} }) {
  const { slug, type, root } = useParams();

  const { currentUser } = useContext(CurrentUserContext);
  const app = import.meta.env.VITE_APP_NAME;

  const gridOptions = {
    rowData: data.integrations,
    defaultColDef: {
      floatingFilter: true,
    },
    context: {
      slug,
      type,
      root,
    },
    columnDefs: [
      {
        headerName: 'Type',
        field: 'integrationType.name',
        minWidth: 200,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (p) => {
          let clickLink;

          if (app === 'admin_portal') {
            clickLink =
              type === 'customer'
                ? `/locations/customer/${p.context.slug}/integrations/${p.data.id}/edit`
                : `/organizations/manufacturer/${p.context.slug}/integrations/${p.data.id}/edit`;
          }

          if (app === 'manuf_portal') {
            clickLink = `/organizations/integrations/${p.data.id}/edit`;
          }

          if (app === 'customer_portal') {
            clickLink = currentUser.scopeToLocation
              ? `/locations/integrations/${p.data.id}/edit`
              : `/locations/${p.context.slug}/integrations/${p.data.id}/edit`;
          }

          p.context.navigate(clickLink);
        },
      },
    ],
  };

  let link;

  if (app === 'admin_portal') {
    link =
      type === 'customer'
        ? `/locations/customer/${slug}/integrations/new`
        : `/organizations/manufacturer/${slug}/integrations/new`;
  }

  if (app === 'manuf_portal') {
    link = `/organizations/integrations/new`;
  }

  if (app === 'customer_portal') {
    link = currentUser.scopeToLocation
      ? `/locations/integrations/new`
      : `/locations/${slug}/integrations/new`;
  }

  return (
    <GridContainer
      gridOptions={gridOptions}
      to={link}
      content="New Integration"
    />
  );
}

IntegrationsListDataTable.propTypes = {
  data: PropTypes.object,
};

export default IntegrationsListDataTable;
