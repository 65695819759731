import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_LOCATION_GUIDE_CODE_GROUPS } from '@fullcontour/shared-api';
import { useMemo } from 'react';
import { Accordion, groupsDataToFormValues } from '@fullcontour/common';
import GuideCodeCard from '../GuideCodeCard/GuideCodeCard';

function GuideBodyDesignGroups(props) {
  const {
    designType,
    values,
    orderItemIndex,
    form: { setValues, setFieldValue },
    currentOrderItem,
  } = props;
  const { data, loading } = useQuery(GET_LOCATION_GUIDE_CODE_GROUPS, {
    onCompleted(response) {
      const initialValues = groupsDataToFormValues(
        response.labLocationGuideCodeGroups,
      );
      setValues({ ...values, ...initialValues });
    },
  });
  const selectedDesignTypeId = currentOrderItem.designTypeId;
  const addGroup = (group) => {
    const currentGuideCodes = values.orderItems[orderItemIndex].guideCodes;
    const currentGroup = values.selectedCodes[group.id];

    const updatedGuideCodes = currentGuideCodes.map((item) => {
      if (currentGroup[item.categoryId]) {
        const codeToUpdate = group.guideCodes.find(
          (code) => code.id === currentGroup[item.categoryId],
        );

        return {
          ...item,
          codes: [codeToUpdate.name],
          description:
            codeToUpdate.description ||
            codeToUpdate.guideCategory.shortDescription,
          imageUrl:
            codeToUpdate.imageUrl || codeToUpdate.guideCategory.imageUrl,
        };
      }

      return item;
    });

    const newGuideCodes = group.guideCodes.reduce((arr, guideCode) => {
      if (
        !currentGuideCodes.some(
          (currentGuideCode) =>
            currentGuideCode.categoryId === guideCode.guideCategory.id,
        )
      ) {
        arr.push({
          categoryId: guideCode.guideCategory.id,
          codeId: guideCode.id,
          categoryName: guideCode.guideCategory.name,
          codes: [guideCode.name],
          categoryAdditionalInfo: {
            guideCategoryImages: guideCode.guideCategory.guideCategoryImages,
            fullDescription: guideCode.guideCategory.description,
          },
          description:
            guideCode.description || guideCode.guideCategory.shortDescription,
          fileUrl: guideCode.imageUrl || guideCode.guideCategory.imageUrl,
          showDropdown: guideCode.guideCategory.showDropdown,
        });
      }

      return arr;
    }, []);

    setFieldValue(`orderItems[${orderItemIndex}].guideCodes`, [
      ...updatedGuideCodes,
      ...newGuideCodes,
    ]);
  };

  const designGuidesData = useMemo(() => {
    if (loading) {
      return null;
    }
    const filteredCodeGroups = data.labLocationGuideCodeGroups.reduce(
      (arr, group) => {
        const updatedGuideCodes = [];
        group.guideCodes.map((guideCode) => {
          const codeFromCurrentDesignType =
            guideCode.guideCategory.guideCategoryGroup.designTypes.some(
              (designType) => designType.id === selectedDesignTypeId,
            );

          if (codeFromCurrentDesignType) {
            updatedGuideCodes.push(guideCode);
          }
          return null;
        });

        if (updatedGuideCodes.length) {
          arr.push({
            ...group,
            guideCodes: updatedGuideCodes,
          });
        }

        return arr;
      },
      [],
    );

    return {
      children: filteredCodeGroups.map((item) => {
        let allElementsSelected = true;
        const cardElements = item.guideCodes.map((guideCode) => {
          const currentGuideCodes =
            values.orderItems[orderItemIndex].guideCodes;

          if (
            allElementsSelected &&
            !currentGuideCodes.some(
              (currentGuideCode) =>
                currentGuideCode.categoryId === guideCode.guideCategory.id &&
                currentGuideCode.codes[0] === guideCode.name,
            )
          ) {
            allElementsSelected = false;
          }

          const guideCodeData = {
            ...guideCode,
            imageUrl: guideCode.imageUrl,
            category: guideCode.guideCategory,
            designType,
          };

          return (
            <GuideCodeCard
              {...props}
              key={guideCodeData.id}
              actionButtons={
                <GuideCodeCard.ActionButtons {...props}>
                  <GuideCodeCard.ButtonNestedSelection
                    {...props}
                    customNested
                  />
                </GuideCodeCard.ActionButtons>
              }
              image={<GuideCodeCard.Image {...props} />}
              cardDropdown={<GuideCodeCard.Dropdown {...props} disabled />}
              nestedLabels={<GuideCodeCard.NestedLabels {...props} />}
              footer={<GuideCodeCard.CardFooter {...props} />}
              data={guideCodeData}
            />
          );
        });

        const cardBody = (
          <div>
            <div className="card__element-group">{cardElements}</div>
            <div className="mt-1 is-flex is-justify-content-flex-end">
              {allElementsSelected ? (
                <div className="is-flex is-align-items-center has-text-success">
                  Added <i className="bx bx-check-circle ml-1 mr-3" />
                </div>
              ) : (
                <button
                  onClick={() => addGroup(item)}
                  type="button"
                  className="button is-primary"
                >
                  <span>Add codes</span>
                  <span className="icon is-small">
                    <i className="bx bx-plus-circle" />
                  </span>
                </button>
              )}
            </div>
          </div>
        );

        return {
          cardHeader: item.name,
          cardId: item.id,
          cardBody,
          cardHeaderText: !cardElements.length ? (
            <span className="tag ml-3">Empty</span>
          ) : (
            `${cardElements.length} ${'codes'}`
          ),
        };
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDesignTypeId, data, values]);

  return (
    <section>
      <h6 className="title is-6">Design Guide Groups</h6>
      <p className="mb-5">
        Only codes that belong to the selected design type are displayed in the
        groups
      </p>
      {designGuidesData && <Accordion data={designGuidesData} />}
    </section>
  );
}
GuideBodyDesignGroups.propTypes = {
  designType: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  orderItemIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  currentOrderItem: PropTypes.object.isRequired,
};

export default GuideBodyDesignGroups;
