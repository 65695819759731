import { graphql } from '@apollo/client/react/hoc';
import { CREATE_BUSINESS_UNIT } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_BUSINESS_UNIT, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createBusinessUnit } = data;
      if (createBusinessUnit.errorMessages.length) {
        props.setErrorMessages(createBusinessUnit.errorMessages);
      } else {
        props.clearErrorMessages();
        props.navigate('/design-types/business-units');
      }
    },
  }),
});

export default withCreate;
