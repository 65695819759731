import { gql } from '@apollo/client';

const PriceListInfo = gql`
  fragment PriceListInfo on PriceList {
    id
    name
    slug
    description
    currency
    parentListId
    turnaroundTimes {
      id
      name
      description
      priority
    }
    designTypes {
      id
      name
    }
    priceListItems {
      id
      turnaroundTimeId
      designTypeId
      defaultTurnaroundTime
      price
    }
    createdAt
    updatedAt
  }
`;

export default PriceListInfo;
