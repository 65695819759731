import { gql } from '@apollo/client';

const OrderInfoListDesigner = gql`
  fragment OrderInfoListDesigner on Order {
    id
    createdAt
    hexOrderCode
    csvDesignPreferences
    intOrderId
    labLocationTier
    jobCode
    isRush
    platform
    name
    percentComplete
    requiresDesignApproval
    softwareVersion
    state
    designLocationId
    itemsMetadata
    instructions
    designCompletionTime
    redesignCount
    feedbacks {
      id
      userId
      redesignReason
      redesignCount
      rating
      answers {
        orderItem {
          id
          designTypeName
          designTypeId
        }
        id
        rating
        options {
          id
          name
          category {
            name
            id
          }
        }
      }
    }
    reassignedAt
    statusUpdatedAt
    turnaroundTimeName
    designSiloName
    designerTags
    designerState
    location {
      id
      code
      customInstructions
      numberingSystem
      pdfAllMovementPerStage
      doctorApprovalLight
      customerTier
    }
  }
`;

export default OrderInfoListDesigner;
