import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsTransactionCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'invoice.hexInvoiceNumber',
        headerName: 'Invoice',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: {
          color: '#1B77C8',
          cursor: 'pointer',
          textTransform: 'uppercase',
        },
      },
      {
        field: 'status',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'organization.name',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.navigate(
            `/organizations/customer/${params.data.organization.slug}`,
          );
        },
      },
      {
        field: 'location.name',
        headerName: 'Location',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          if (params.data.location.slug) {
            params.context.navigate(
              `/locations/customer/${params.data.location.slug}`,
            );
          }

          return null;
        },
      },
      {
        field: 'location.tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        type: 'dateColumn',
        columnGroupShow: false,
      },
      {
        field: 'message',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'paymentMethod',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'authCode',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'amount',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsTransactionCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsTransactionCustomer;
