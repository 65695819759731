import { useEffect, useMemo, useState } from 'react';
import calculateMovementsPerStage from './calculateMovementsPerStage';
import headerIPR from './headerIPR';
import useTeethChartDataTransform from './useTeethChartDataTransform';

const floatAggregatedValue = (aggregatedValue) =>
  aggregatedValue &&
  aggregatedValue.toFixed(1) >= 0.1 &&
  aggregatedValue.toFixed(1);

const arrOfKeys = [...Array(32).keys()];
const TEETH_HEADER = arrOfKeys.splice(0, 16).concat(arrOfKeys.reverse());

function useIprsCalculations(data) {
  const { setIprs, otpFiles, redesignCountObj, activeStage } = data;
  const [isTotal, setIsTotal] = useState(true);
  const [orderStages, setOrderStages] = useState([]);

  useEffect(() => {
    if (otpFiles) {
      const otpFileForCurrentRedesign = otpFiles.find(
        (item) => item.redesignCount === redesignCountObj,
      );
      setOrderStages(
        otpFileForCurrentRedesign ? otpFileForCurrentRedesign.stages : [],
      );
    }
  }, [otpFiles, redesignCountObj]);

  useEffect(() => {
    if (activeStage && activeStage !== 1) {
      setIsTotal(false);
    }
  }, [activeStage]);

  const recalculatedStage = useMemo(
    () => calculateMovementsPerStage(orderStages),
    [orderStages],
  );

  const total = useMemo(
    () =>
      orderStages.length
        ? {
            ...orderStages[orderStages.length - 1],
            Teeth: orderStages[orderStages.length - 1].Teeth.filter(
              (tooth) =>
                tooth.Movement.Value.MovementValues?.Value &&
                Object.keys(tooth.Movement.Value.MovementValues?.Value).length,
            ),
          }
        : {},
    [orderStages],
  );

  const { headerTableData, bodyTeethData } = useTeethChartDataTransform(
    recalculatedStage,
    activeStage,
    isTotal,
    total,
  );

  useEffect(() => {
    if (headerTableData) {
      const result = TEETH_HEADER.reduce((obj, toothUnn) => {
        const { style, aggregatedValue } = headerIPR(toothUnn, headerTableData);

        const showIPR = aggregatedValue && style && Object.keys(style).length;

        // eslint-disable-next-line no-param-reassign
        obj[toothUnn + 1] = {
          showIPR: !!(showIPR && style?.borderRight),
          aggregatedValue: floatAggregatedValue(aggregatedValue),
        };
        return obj;
      }, {});

      setIprs(result);
    }
  }, [headerTableData, setIprs]);

  return {
    orderStages,
    isTotal,
    setIsTotal,
    total,
    recalculatedStage,
    bodyTeethData,
    headerTableData,
  };
}

export default useIprsCalculations;
