import InvoiceShow from '@fullcontour/common/src/components/views/Invoices/InvoiceShow';

const routes = [
  {
    path: [
      '/locations/:slug/billing/invoices/:hexInvoiceNumber',
      '/organizations/billing/invoices/:hexInvoiceNumber',
    ],
    component: InvoiceShow,
    sl: false,
    so: true,
  },
  {
    path: ['/locations/billing/invoices/:hexInvoiceNumber'],
    component: InvoiceShow,
    sl: true,
    so: false,
  },
];

export default routes;
