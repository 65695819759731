/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable class-methods-use-this */
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { GET_ORDER_FILES } from '@fullcontour/shared-api';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { timestampFormatter } from '../../../../../transforms';
import createSignedLink from '../../../../shared/HelperFunctions/createSignedLink';
import { fileCellRenderer, FilesGrid, FilesGridActions } from './FilesGrid';
import { FilesShow } from './FilesShow';
import FilesUpload from './FilesUpload/FilesUpload';
import './OrderShowFiles.css';

class OrderShowFiles extends Component {
  state = {
    selectedFile: null,
    selectedFolder: null,
    selectedFileError: null,
    loadingFileView: false,
    rowData: [],
    toggleVersions: false,
    gridOptions: {
      columnDefs: [
        {
          field: 'createdAt',
          headerName: this.props.t('Created At'),
          minWidth: 150,
          maxWidth: 175,
          sort: 'desc',
          comparator(d1, d2) {
            return new Date(d1).getTime() < new Date(d2).getTime() ? -1 : 1;
          },
        },
      ],
      components: { fileCellRenderer: fileCellRenderer() },
      groupDefaultExpanded: -1,
      getDataPath(data) {
        return data.filePath;
      },
      getRowNodeId(data) {
        return data.id;
      },
      rowSelection: 'single',
      autoGroupColumnDef: {
        headerName: this.props.t('Files'),
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: 'fileCellRenderer',
        },
      },
    },
  };

  componentDidUpdate(prevProps) {
    const {
      data: { orderFiles = [], loading },
    } = this.props;

    const { rowData } = this.state;

    if (
      (loading === false && orderFiles.length > 0 && !rowData.length) ||
      (prevProps.data.orderFiles && orderFiles !== prevProps.data.orderFiles)
    ) {
      this.setUpGridData();
    }
  }

  componentWillUnmount() {
    this.gridApi = undefined;
  }

  setUpGridData = () => {
    const { toggleVersions } = this.state;

    const fileTypes = this.fileTypes();
    const rootTypes = this.rootTypes(fileTypes);
    const fileRowData = this.fileRowData(toggleVersions);

    const rowData = [...rootTypes, ...fileRowData];

    this.setState({
      rowData,
      selectedFileError: null,
    });
  };

  fileTypes = () => {
    const {
      order: { orderItems },
      data: { orderFiles },
    } = this.props;

    const requiresAttachments = orderItems.some(
      (item) => item.designType.attachmentFiles === true,
    );

    const hasObjs = orderFiles.some((file) => file.fileType === 'obj_files');

    const hasTreatmentReviewFiles = orderFiles.some(
      (file) => file.fileType === 'treatment_review_files',
    );

    const hasConvertedFiles = orderFiles.some(
      (file) => file.fileType === 'converted_treatment_review_files',
    );

    const hasFinishedPackage = orderFiles.some(
      (file) => file.fileType === 'designer_finished_package',
    );

    const hasVisualizationOutput = orderFiles.some(
      (file) => file.fileType === 'visualization_output',
    );

    let fileTypes = [
      'initial_scans',
      'finished_scans',
      'manufacturer_scans',
      'stl_files',
      'quality_control_images',
    ];

    if (
      requiresAttachments ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'customer_portal' ||
      import.meta.env.VITE_APP_NAME === 'designer_portal'
    ) {
      fileTypes = [...fileTypes, 'attachments'];
    }

    if (
      hasVisualizationOutput ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'customer_portal' ||
      import.meta.env.VITE_APP_NAME === 'designer_portal'
    ) {
      fileTypes = [...fileTypes, 'visualization_output'];
    }

    if (
      hasObjs ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'designer_portal'
    ) {
      fileTypes = [...fileTypes, 'obj_files'];
    }

    if (
      hasFinishedPackage ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'designer_portal'
    ) {
      fileTypes = [...fileTypes, 'designer_finished_package'];
    }

    if (
      hasTreatmentReviewFiles ||
      import.meta.env.VITE_APP_NAME === 'admin_portal'
    ) {
      fileTypes = [...fileTypes, 'treatment_review_files'];
    }

    if (
      hasConvertedFiles ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'designer_portal'
    ) {
      fileTypes = [...fileTypes, 'converted_treatment_review_files'];
    }

    return fileTypes;
  };

  rootTypes = (fileTypes) =>
    fileTypes.map((type, index) => ({
      id: index + 1,
      filePath: ['files', type],
      createdAt: null,
    }));

  checkFor3dFiles = (file) =>
    file.fileType === 'stl_files' ||
    file.fileType === 'visualization_output' ||
    file.fileType === 'obj_files' ||
    file.fileType === 'converted_treatment_review_files';

  fileRowData = (showVersions = false) => {
    const {
      data: { orderFiles },
      order: { redesignCount },
    } = this.props;

    let filteredFiles;

    if (!showVersions) {
      filteredFiles = orderFiles.filter((file) => {
        if (this.checkFor3dFiles(file)) {
          return (
            file.ver === file.latestVer && file.redesignCount === redesignCount
          );
        }

        return file.ver === file.latestVer;
      });
    } else {
      filteredFiles = [...orderFiles]
        .sort((a, b) => a.redesignCount - b.redesignCount)
        .filter((item) => {
          if (this.checkFor3dFiles(item)) {
            return item.ver === item.latestVer;
          }

          return true;
        });
    }

    return filteredFiles.map((file) => {
      const fileName = !showVersions
        ? file.originalFileName
        : `v${
            this.checkFor3dFiles(file) ? file.redesignCount + 1 : file.ver
          }:${'  '} ${file.originalFileName}`;
      return {
        id: file.id,
        createdAt: timestampFormatter(file.createdAt),
        filePath: ['files', file.fileType, fileName],
      };
    });
  };

  toggleAllVersions = () => {
    const { toggleVersions } = this.state;

    const fileTypes = this.fileTypes();
    const rootTypes = this.rootTypes(fileTypes);
    const fileRowData = this.fileRowData(!toggleVersions);

    const rowData = [...rootTypes, ...fileRowData];

    this.setState({
      rowData,
      toggleVersions: !toggleVersions,
      selectedFile: null,
      selectedFolder: null,
      selectedFileError: null,
      loadingFileView: false,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onRowDataChanged = (event) => {
    const { node } = this.state;
    if (node) {
      event.api.deselectAll();
      event.api.forEachNode((newNode) => {
        if (newNode.data && newNode.data.id === node.data.id) {
          newNode.setSelected(true);
        } else {
          newNode.setSelected(false);
        }
      });
    }
  };

  setSelectedToDefault = () => {
    this.setState({
      selectedFile: null,
      selectedFolder: null,
      selectedFileError: null,
    });
  };

  onRowSelected = async (event) => {
    const { toggleVersions } = this.state;
    const { client } = this.props;

    if (event.node.isSelected()) {
      this.setState({ loadingFileView: true, node: event.node });
      const {
        data: { orderFiles },
      } = this.props;

      if (event?.data?.createdAt) {
        const foundFile = orderFiles.find((file) => file.id === event.data.id);
        this.setState({
          loadingFileView: false,
          selectedFile: await createSignedLink({
            client,
            foundFile,
          }),
          selectedFolder: null,
        });
      }

      if (!event?.data?.createdAt) {
        const foundType = orderFiles.filter((file) => {
          if (toggleVersions) {
            return file.fileType === event.data.filePath[1];
          }

          return (
            file.fileType === event.data.filePath[1] &&
            file.ver === file.latestVer
          );
        });

        const selectedFolderItems = await Promise.all(
          foundType.map((foundFile) =>
            createSignedLink({
              client,
              foundFile,
            }),
          ),
        );

        this.setState({
          loadingFileView: false,
          selectedFolder: {
            fileType: event.data.filePath[1],
            selectedItems: selectedFolderItems,
          },
          selectedFile: null,
        });
      }
    }
  };

  render() {
    const {
      gridOptions,
      rowData,
      selectedFile,
      selectedFileError,
      loadingFileView,
      selectedFolder,
      toggleVersions,
    } = this.state;

    const {
      order,
      client,
      data: { refetch },
    } = this.props;

    return rowData ? (
      <div className="columns">
        <div className="column is-half">
          <FilesGridActions
            toggleAllVersions={this.toggleAllVersions}
            toggleVersions={toggleVersions}
          />
          <FilesGrid
            gridOptions={gridOptions}
            rowData={rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            onFirstDataRendered={this.onFirstDataRendered}
            onRowSelected={this.onRowSelected}
            onRowDataChanged={this.onRowDataChanged}
          />
        </div>
        <div className="column is-half is-relative is-flex is-flex-direction-column">
          <FilesShow
            selectedFile={selectedFile}
            client={client}
            refetch={refetch}
            selectedFolder={selectedFolder}
            selectedFileError={selectedFileError}
            loadingFileView={loadingFileView}
            toggleVersions={toggleVersions}
            setSelectedToDefault={this.setSelectedToDefault}
            order={order}
          />
          {selectedFile || selectedFolder ? (
            <FilesUpload
              order={order}
              selectedFile={selectedFile}
              selectedFolder={selectedFolder}
              refetch={refetch}
            />
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

OrderShowFiles.propTypes = {
  order: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
};

OrderShowFiles.defaultProps = {
  data: null,
};

export default flowRight(
  graphql(GET_ORDER_FILES, {
    options: (props) => ({
      variables: { orderId: props.order.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withApollo,
  withTranslation('aggrid'),
)(OrderShowFiles);
