import { gql } from '@apollo/client';

export default gql`
  query designTypes {
    designTypes {
      id
      name
      slug
      designTypeCategory {
        id
        name
        slug
      }
    }
  }
`;
