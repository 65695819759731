import { useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import { isHelpCenterBarActive } from '../../../config/apollo';
import { OutsideClick } from '../HelperFunctions';
import BoxIcon from '../Icons/BoxIcon';
import { DisplayedData, SearchHelp } from './components';

import './HelpCenterBar.css';

function HelpCenterBar() {
  const [displayedData, setDisplayedData] = useState({
    data: [],
    error: null,
    value: '',
  });
  const visible = useReactiveVar(isHelpCenterBarActive);

  const visibleClass = visible
    ? 'help-center__bar--open'
    : 'help-center__bar--closed';

  const closeHelperBar = () => {
    isHelpCenterBarActive(false);
  };

  return (
    <OutsideClick fn={closeHelperBar}>
      <div className={`card help-center__bar ${visibleClass}`}>
        <header className="card-header">
          <p className="card-header-title">Help Center</p>
          <button
            className="card-header-icon"
            type="button"
            onClick={closeHelperBar}
          >
            <BoxIcon name="bx-x bx-md" />
          </button>
        </header>
        <div className="card-content" style={{ overflowY: 'scroll' }}>
          <SearchHelp
            className="help-center__bar-search"
            setDisplayedData={setDisplayedData}
          />
          <aside className="menu help-center__bar-menu">
            <ul className="menu-list">
              <DisplayedData displayedData={displayedData} />
            </ul>
          </aside>
        </div>
      </div>
    </OutsideClick>
  );
}

export default HelpCenterBar;
