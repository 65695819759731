import { gql } from '@apollo/client';

const InvoiceInfo = gql`
  fragment InvoiceInfo on Invoice {
    id
    invoiceNumber
    hexInvoiceNumber
    organizationId
    organization {
      id
      name
      slug
    }
    locationId
    location {
      id
      name
      slug
      address
    }
    state
    memo
    datePaid
    invoicingDate
    dueDate
    totalPrice
    totalCredits
    totalDebits
    adjustedTotal
    balanceDue
    appliedPayments
    token
    reconciled
    createdAt
    updatedAt
  }
`;

export default InvoiceInfo;
