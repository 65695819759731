import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
function booleanCheckRenderer({ value }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {value ? (
        <BoxIcon
          name="bx bxs-check-circle"
          className="has-text-success"
          style={{ fontSize: '24px' }}
        />
      ) : (
        <BoxIcon
          name="bx bxs-x-circle"
          className="has-text-danger"
          style={{ fontSize: '24px' }}
        />
      )}
    </div>
  );
}

booleanCheckRenderer.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default booleanCheckRenderer;
