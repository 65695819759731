import { gql } from '@apollo/client';

export default gql`
  query designerTag($slug: String!) {
    designerTag(slug: $slug) {
      id
      name
      slug
      locations {
        id
        name
        slug
      }
    }
  }
`;
