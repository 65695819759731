/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BoxIcon, ReactPortal } from '../../../../shared';
import DownloadSelection from './DownloadSelection';

function DownloadModal(props) {
  const {
    modalOpen,
    closeModal,
    openModal,
    downloadSelectedFiles,
    selectedTypes,
    actionTitle,
    loadingData,
  } = props;

  const { t } = useTranslation('orderactions');

  return (
    <>
      <a className="dropdown-item" onClick={openModal}>
        {t(actionTitle)}
      </a>
      <ReactPortal wrapperId="download-modal" show={modalOpen}>
        <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <BoxIcon name="bx-cloud-download bx-md is-size-3" />
              <span>
                <h4 className="title is-5 mb-0 ml-4 modal-card-title">
                  {t('Download Selected Files')}
                </h4>
              </span>
            </header>
            <section className="modal-card-body">
              <DownloadSelection {...props} />
            </section>
            <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
              <button
                type="button"
                className="is-dark button"
                onClick={closeModal}
              >
                {t('Close')}
              </button>
              <button
                type="button"
                className={`button is-primary ${
                  loadingData ? 'is-loading' : ''
                }`}
                onClick={downloadSelectedFiles}
                disabled={
                  !selectedTypes.some((type) => type.selected) || loadingData
                }
              >
                {t('Download')}
              </button>
            </footer>
          </div>
        </div>
      </ReactPortal>
    </>
  );
}

DownloadModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  loadingData: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  downloadSelectedFiles: PropTypes.func.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  actionTitle: PropTypes.string.isRequired,
};

export default DownloadModal;
