import {
  OverpanelFilesContext,
  OverpanelSubmitContext,
  sendingOrders,
} from '@fullcontour/common';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function OverpanelHeaderSubmitAll() {
  const { files, overpanelOpen } = useContext(OverpanelFilesContext);
  const { setSubmitAll, errors } = useContext(OverpanelSubmitContext);
  const { t } = useTranslation('designProfile');

  const submitHandler = () => {
    setSubmitAll();
    sendingOrders({
      totalCases: files.length,
      casesSent: 0,
    });
  };

  function areValuesEmptyObjects(obj) {
    return Object.values(obj).every((value) => Object.keys(value).length === 0);
  }
  const isDisabled = useMemo(() => !areValuesEmptyObjects(errors), [errors]);

  return overpanelOpen && overpanelOpen !== 'pending' && files.length > 0 ? (
    <button
      type="button"
      className="button is-primary is-small mr-3 has-tooltip-left has-tooltip-arrow"
      id="send-new-orders"
      disabled={isDisabled}
      data-tooltip={
        isDisabled
          ? t('Please fill out all case details prior to sending')
          : null
      }
      onClick={submitHandler}
      style={{
        ...(isDisabled
          ? {
              background: 'rgba(61, 172, 209, 0.5)',
              border: 'none',
              opacity: '1',
            }
          : {}),
      }}
    >
      <span className="icon">
        <i className="bx bxs-paper-plane" />
      </span>
      <span>{t('Send All Cases')}</span>
    </button>
  ) : null;
}

export default OverpanelHeaderSubmitAll;
