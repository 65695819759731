import { gql } from '@apollo/client';

const OrderInfo = gql`
  fragment OrderInfo on Order {
    id
    createdAt
    hexOrderCode
    customerDesignPreferences
    intOrderId
    isRush
    name
    percentComplete
    requiresDesignApproval
    softwareVersion
    state
    itemsMetadata
    designCompletionTime
    instructions
    reassignedAt
    feedbacks {
      id
      redesignCount
      userId
      rating
      answers {
        id
        rating
        options {
          id
          name
        }
      }
    }
    currentDesignUser {
      id
      designUser {
        id
        firstName
        lastName
      }
    }
    designSilo {
      id
      name
      slug
    }
    location {
      id
      name
      slug
      code
      subType
      customInstructions
      doctorApprovalLight
      showVisOutput
    }
    turnaroundTime {
      id
      name
    }
  }
`;

export default OrderInfo;
