import { gql } from '@apollo/client';
import { GuideCategoryInfo } from '../fragments';

export default gql`
  query guideCategoryGroups {
    guideCategoryGroups {
      id
      name
      slug
      guideCategories {
        ...GuideCategoryInfo
      }
      designTypes {
        id
        name
      }
      guideBooks {
        id
        name
      }
    }
  }
  ${GuideCategoryInfo}
`;
