import * as Yup from 'yup';

function schema() {
  return Yup.object({
    fileUrl: Yup.string().required('Required'),
    filename: Yup.string(),
    s3Key: Yup.string().required('Required'),
    customerSlug: Yup.string().required('Customer Location is Required'),
  });
}

export default schema;
