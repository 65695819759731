import { gql } from '@apollo/client';
import { GuideCategoryGroupInfo } from '../fragments';

export default gql`
  query guideCategoryGroup($id: ID, $slug: String) {
    guideCategoryGroup(id: $id, slug: $slug) {
      ...GuideCategoryGroupInfo
    }
  }
  ${GuideCategoryGroupInfo}
`;
