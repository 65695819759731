import { guideNav } from '../../../config/apollo';
import { newOrderToCache } from '../../../transforms';
import CreateNotification from './createNotification';
import fetchOnStatus from './fetchOnStatus';

class UpdateCache {
  filesToAdd = {};

  filesToAddCount = 0;

  totalFiles = 0;

  addToCacheHandler({
    order,
    total,
    cache,
    query,
    singleClick,
    progressInfo,
    pending,
    isOrders,
    removeSubmitAll,
    toggleOverpanel,
  }) {
    this.filesToAdd[order.state] = this.filesToAdd[order.state]
      ? [...this.filesToAdd[order.state], newOrderToCache(order)]
      : [newOrderToCache(order)];

    this.filesToAddCount += 1;

    const ordersSedning = progressInfo();

    if (total > this.totalFiles) this.totalFiles = total;

    progressInfo({
      ...ordersSedning,
      casesSent: this.filesToAddCount,
    });

    if (this.filesToAddCount === this.totalFiles || singleClick) {
      Object.entries(this.filesToAdd).forEach(([state, stateArray]) => {
        const { queryToFetch, dataKey } = fetchOnStatus(query, state);
        try {
          const data = cache.readQuery({
            query: queryToFetch,
            variables: { status: state },
          });
          const updatedArray = [...data[dataKey], ...stateArray];
          cache.writeQuery({
            query: queryToFetch,
            variables: { status: state },
            data: {
              [dataKey]: updatedArray,
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-unused-expressions
          () => {};
        } finally {
          const stateForDisplay = state.replace(/_/g, ' ');
          CreateNotification.createNotification({
            type: 'success',
            message: `${stateArray.length} ${
              stateArray.length === 1 ? 'case was' : 'cases were'
            } ${
              state === 'sent_for_design'
                ? stateForDisplay
                : `sent for "${stateForDisplay}"`
            }`,
          });
          if (isOrders) {
            const isGuided = JSON.parse(localStorage.getItem('isGuided'));
            if (isGuided) guideNav(true);
          }

          if (pending) {
            cache.writeQuery({
              query: pending,
              data: {
                tempOrdersCount: 0,
              },
            });
          }
          if (!singleClick) {
            toggleOverpanel(false);
            removeSubmitAll();
          }
        }
      });
      this.resetFiles(progressInfo);
    }
  }

  static filterElementFromCache({ cache, field, id }) {
    cache.modify({
      fields: {
        [field]: (existingFilesRefs, { readField }) =>
          existingFilesRefs.filter(
            (fileRef) => id !== readField('id', fileRef),
          ),
      },
    });
  }

  resetFiles(progressInfo) {
    this.filesToAdd = {};
    this.filesToAddCount = 0;
    this.totalFiles = 0;
    progressInfo({
      totalCases: 0,
      casesSent: 0,
    });
  }
}

export default UpdateCache;
