import { useQuery } from '@apollo/client';
import { GET_REPORTS } from '@fullcontour/shared-api';
import { LoadingOrError } from '../../../shared';
import ReportsListDataTable from './ReportsListDataTable';

function ReportsListQueryWrapper(props) {
  const { error, loading, data } = useQuery(GET_REPORTS, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error)
    return (
      <LoadingOrError error={error} loading={loading} title="Saved Reports" />
    );

  return <ReportsListDataTable data={data} {...props} />;
}

export default ReportsListQueryWrapper;
