import { gql } from '@apollo/client';
import { InvoiceInfo } from '../fragments';

export default gql`
  query invoices($labLocationId: ID, $labOrganizationId: ID) {
    invoices(
      labLocationId: $labLocationId
      labOrganizationId: $labOrganizationId
    ) {
      ...InvoiceInfo
    }
  }
  ${InvoiceInfo}
`;
