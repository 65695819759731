import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    name: Yup.string().required('Required'),
    title: Yup.string(),
    role: Yup.string().required('Required'),
    phone: Yup.string(),
    extension: Yup.string(),
    email: Yup.string().email(),
    contactableId: Yup.string().required(),
    contactableType: Yup.string().required(),
  });
}

export default schema;
