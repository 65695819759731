import PropTypes from 'prop-types';
import { memo } from 'react';
import Dropdown from './Dropdown';

function ModifierModalDropdown({
  modifiers,
  group,
  orderItemIndex,
  groupIndex = 0,
  values: { orderItems } = {},
  setFieldValue = () => {},
  setFieldTouched = () => {},
}) {
  function handleChange(e) {
    if (e.target.value !== '') {
      setFieldValue(
        `orderItems[${orderItemIndex}].orderItemModifiersAttributes[${groupIndex}].modifierId`,
        e.target.value,
      );
    }
  }

  const scrubbedModifiers = modifiers.map((m) => {
    const { default: isDefault, ...rest } = m;
    return { ...rest };
  });
  return (
    <Dropdown
      id={group.id}
      name={group.id}
      hideLabel
      options={scrubbedModifiers}
      onChange={handleChange}
      placeholder="Select.."
      value={
        orderItems[orderItemIndex].orderItemModifiersAttributes[groupIndex]
          ?.modifierId || ''
      }
      search
      setFieldTouched={setFieldTouched}
    />
  );
}

ModifierModalDropdown.propTypes = {
  modifiers: PropTypes.array.isRequired,
  group: PropTypes.object.isRequired,
  orderItemIndex: PropTypes.number.isRequired,
  groupIndex: PropTypes.number,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default memo(ModifierModalDropdown);
