/* eslint-disable react/no-unescaped-entities */
import AccordionCard from '@fullcontour/common/src/components/shared/Components/Accordion/AccordionCard';
import panels from '../AccordionPanels';
import { DocumentSvg, FaceSvg, NewspaperSvg, TriosSvg, X1Svg } from '../assets';
import Trios from '../assets/TriosSvg';

import faceDocument from '../assets/faceDocument.png';
import faceWithThingy from '../assets/faceWithThingy.png';

const designTypesGrouped = (designType, t) => ({
  shapeExocadSirona: {
    types: new Set([
      'abutment',
      'allOnXHybrid',
      'anteriorDesign',
      'artificialTooth',
      'bridge',
      'coping',
      'crown',
      'inlay',
      'onlay',
      'positioningGuide',
      'screwRetainedFrame',
      'veneer',
      'waxup',
      'allOnXPreppedHybrid',
      'bridgeWithGingiva',
      'customizedImpressionTray',
      'denture',
      'implantBridgeWithGingiva',
      'lucid',
      'model',
      'nightguard',
      'splint',
      'veneer',
      'temporaryPontic',
      'temporaryCrown',
      'postCoreWithCrown',
      'postAndCore',
      'RPD',
      'sironaSTLConversion',
    ]),
    content: (
      <div>
        <p className="mb-5">
          {['splint', 'nightguard'].includes(designType) ? (
            t(
              'The case must be created and setup in 3Shapes Splint Studio Module in Dental System.',
            )
          ) : (
            <>
              {t(
                'Alternatively, the case must be created and setup in design software, STL files are not accepted.',
              )}
            </>
          )}
        </p>

        <b>
          {t(
            'Click your design software below for instructions on how to zip your files.',
          )}
        </b>
        <div>
          {panels.shapeExocadSirona(designType, t).map((c, i) => (
            <div
              key={c.key}
              className={i === 0 ? 'mt-5' : ''}
              style={{ width: '100%' }}
            >
              <AccordionCard
                card={{
                  cardBody: c.content,
                  cardHeader: c.title,
                }}
              />
            </div>
          ))}
        </div>
        {['nightguard', 'splint'].includes(designType) && (
          <p className="mt-4">
            <b>{t(`Info to Include: `)}</b>
            {t(`If the bite has not been pre-opened to the desired
            position, please include a numerical value for which the bite will
            be opened in "Additional Case Comments" tab of the
            case`)}
          </p>
        )}
        {['RPD'].includes(designType) && (
          <>
            <p>
              {t(`As the other way you can to provide the following into one zipped
              folder:`)}
            </p>
            <div className="design-type-list">
              <ul className="mb-5 is-size-5 mt-6 list-with-icons">
                <li>
                  <div className="icon-wrapper">
                    <Trios />
                  </div>
                  {t(`Upper + Lower STL files`)}
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    ),
  },
  rpd: {
    types: new Set(['guidedImplantSurgery']),
    content: (
      <div className="mb-5 design-type-list">
        <b>
          {t(`You will need to zip up the following into one zipped folder:`)}
        </b>
        <p className="my-3">
          {t(
            `Remember to choose implant sites and indicate in the "Additional Case Comments" implant brand, pilot- or fully guided, printer model and details`,
          )}
        </p>

        <b>{t(`Planning only:`)}</b>
        <ul className="ml-5 is-size-5 list-with-icons mt-3">
          <li className="mb-3">
            <div className="icon-wrapper">
              <X1Svg />
            </div>
            {t(`CBCT scan of the patient`)}
          </li>
        </ul>
        <b>{t(`Tooth supported:`)}</b>
        <ul className="ml-5 is-size-5 list-with-icons mt-3">
          <li className="mb-3">
            <div className="icon-wrapper">
              <X1Svg />
            </div>
            {t(`CBCT scan of the patient`)}
          </li>
          <li className="mb-3">
            <div className="icon-wrapper">
              <TriosSvg />
            </div>
            {t(`Upper + Lower + Bite STL files`)}
          </li>
        </ul>
        <b>{t(`Bone supported:`)}</b>
        <ul className="ml-5 is-size-5 list-with-icons mt-3">
          <li className="mb-3">
            <div className="icon-wrapper">
              <X1Svg />
            </div>
            {t(`CBCT scan of the patient`)}
          </li>
        </ul>
        <b>{t(`Edentulous/dual scan supported:`)}</b>
        <ul className="ml-5 is-size-5 list-with-icons mt-3">
          <li className="mb-3">
            <div className="icon-wrapper">
              <X1Svg />
            </div>
            {t(
              `CBCT scan of the patient wearing appliance with radiopaque markers`,
            )}
          </li>
          <li className="mb-3">
            <div className="icon-wrapper">
              <X1Svg />
            </div>
            {t(`CBCT scan of appliance with radiopaque markers`)}
          </li>
        </ul>
      </div>
    ),
  },
  br: {
    types: new Set([
      'bracketRemoval',
      'clearAligner',
      'clearRetainer',
      'modelCleanUp',
      'orthoStudyModel',
      'retainerModel',
      'clearAlignersAnterior',
      'clearAlignersFullArch',
      'clearAlignerAnteriorRefinement',
      'clearAlignerFullArchRefinement',
      'modelCleanUp',
      'retainerModel',
      'smileDesignVideo',
    ]),
    content: (
      <div className="design-type-list">
        <b>
          {t(`You will need to zip up the following into one zipped folder:`)}
        </b>
        <ul className="mb-5 is-size-5 mt-6 list-with-icons">
          {[
            'clearAlignersAnterior',
            'clearAlignersFullArch',
            'clearAlignerAnteriorRefinement',
            'clearAlignerFullArchRefinement',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <Trios />
              </div>
              {t(`Upper + Lower + Bite STL files`)}
            </li>
          )}
          {[
            'bracketRemoval',
            'clearRetainer',
            'modelCleanUp',
            'orthoStudyModel',
            'retainerModel',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <Trios />
              </div>
              {t(`Upper + Lower STL files`)}
            </li>
          )}
          {[
            'bracketRemoval',
            'modelCleanUp',
            'orthoStudyModel',
            'retainerModel',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <FaceSvg />
              </div>
              {t(`Patient photos (recommended)`)}
            </li>
          )}
          {[
            'clearAlignersAnterior',
            'clearAlignersFullArch',
            'clearAlignerAnteriorRefinement',
            'clearAlignerFullArchRefinement',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <DocumentSvg />
              </div>
              {t(`An RX (or use the Modifiers selection in the next step to enter RX
              information)`)}
            </li>
          )}
          {[
            'clearAlignersAnterior',
            'clearAlignersFullArch',
            'clearAlignerAnteriorRefinement',
            'clearAlignerFullArchRefinement',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <FaceSvg />
              </div>
              {t(`Patient photos`)}
            </li>
          )}
          {['clearAlignerFullArchRefinement'].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <DocumentSvg />
              </div>
              {t(`CBCT scan (recommended)`)}
            </li>
          )}
          {[
            'clearAlignerAnteriorRefinement',
            'clearAlignerFullArchRefinement',
          ].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <NewspaperSvg />
              </div>
              {t(
                `The case ID from the original Clear Aligner Anterior treatment plan must be included in the "Additional Case Comments" tab of the case`,
              )}
            </li>
          )}
          {['clearAlignersFullArch'].includes(designType) && (
            <li>
              <div className="icon-wrapper">
                <FaceSvg />
              </div>
              {t(`CBCT scan (recommended)`)}
            </li>
          )}
        </ul>
        {['smileDesignVideo'].includes(designType) && (
          <>
            <b>{t(`A full smile image with the option of retracted image`)}</b>{' '}
            {t(`(top of head to chin)`)}
            <div style={{ width: 100 }} className="mt-3 mb-5 is-flex">
              <FaceSvg />
              <img
                src={faceWithThingy}
                style={{ height: 62 }}
                className="ml-5"
                alt="face diagnostic"
              />
            </div>
            <b>
              {t(
                `Optional – Retracted image of a digital diagnostic if one was made & approved`,
              )}
            </b>
            <div style={{ width: 100 }} className="mt-3 mb-5 is-flex">
              <FaceSvg />
              <img
                src={faceWithThingy}
                style={{ height: 62 }}
                className="ml-5"
                alt="face diagnostic"
              />
            </div>
            <b>{t(`Optional - Supplemental images`)}</b>
            <div style={{ width: 100 }} className="mt-5">
              <img src={faceDocument} alt="face document" />
            </div>
          </>
        )}
      </div>
    ),
  },
});

export default designTypesGrouped;
