import { getRoot, ShowEditButton, TierLogo } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import ShowUserDropdown from './ShowUserDropdown';

function ShowHeader({
  title,
  editLink,
  leftContent = null,
  logoUrl = '',
  code = null,
  customerTier = '',
  user,
}) {
  const root = getRoot();

  return (
    <div className={`section box location-tier-color-${customerTier}`}>
      <div className="is-flex is-flex-direction-row is-align-items-flex-start">
        <div className="is-flex is-flex-direction-row">
          {logoUrl && (
            <figure
              className="image mr-4 is-128x128 p-2 is-flex is-flex-direction-column is-justify-content-center"
              style={{ background: '#ffffff' }}
            >
              <img src={logoUrl} alt={title} />
            </figure>
          )}
        </div>
        <div
          style={{
            display: 'inline-block',
            width: 'calc(100% - 80px - 1rem)',
          }}
        >
          <h3
            className="title is-3"
            style={{ marginBottom: '0.25em', color: 'inherit' }}
          >
            {title}
          </h3>
          {code && (
            <h6
              className="title is-6"
              style={{ marginBottom: '0.25em', marginTop: 0, color: 'inherit' }}
            >
              {code}
            </h6>
          )}
          {leftContent}
        </div>
        {root !== 'organizations' && root !== 'users' && (
          <TierLogo customerTier={customerTier} />
        )}
        {root !== 'organizations' && root !== 'users' && (
          <div className="is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-flex-start has-text-right">
            <ShowEditButton editLink={editLink} />
          </div>
        )}
        {user && <ShowUserDropdown editLink={editLink} user={user} />}
      </div>
    </div>
  );
}

ShowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  customerTier: PropTypes.string,
  logoUrl: PropTypes.string,
  leftContent: PropTypes.node,
  code: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default memo(ShowHeader);
