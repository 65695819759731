import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function TurnaroundTimeHeaderCell(props) {
  const { listTurnaroundTimes } = props;

  const { t } = useTranslation('pricelist');

  return listTurnaroundTimes.map((time) => (
    <th key={time.id}>
      <h6 className="title is-6 has-text-primary">{t(time.name)}</h6>
      <small>{t(time.description)}</small>
    </th>
  ));
}

TurnaroundTimeHeaderCell.propTypes = {
  listTurnaroundTimes: PropTypes.array.isRequired,
};

export default memo(TurnaroundTimeHeaderCell);
