import PropTypes from 'prop-types';
import {
  AgGridContainer,
  caseItemReasonRenderer,
  ratingRenderer,
} from '../../../../../shared';

function ResultsFeedbackOrder(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    masterDetail: true,
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'order.hexOrderCode',
        headerName: 'Case ID',
        minWidth: 100,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: {
          color: '#1B77C8',
          cursor: 'pointer',
          textTransform: 'uppercase',
        },
        onCellClicked: (params) => {
          params.context.navigate(
            `/orders/details/${params.data.order.hexOrderCode}`,
          );
        },
      },
      {
        field: 'labLocation.name',
        headerName: 'Location',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designUser.name',
        headerName: 'Designer',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'redesignCount',
        headerName: 'Redesign #',
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'redesignReason',
        headerName: 'Reason',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'rating',
        headerName: 'Overall Rating',
        minWidth: 60,
        filter: 'agTextColumnFilter',
        cellRenderer: ratingRenderer,
      },
      {
        field: 'answers',
        headerName: 'Case Item Reasons',
        minWidth: 120,
        cellRenderer: caseItemReasonRenderer,
      },
    ],
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'designType',
            headerName: 'Design Type',
            minWidth: 120,
            filter: 'agTextColumnFilter',
          },
          {
            field: 'reasons',
            headerName: 'Reasons',
            minWidth: 600,
            filter: 'agTextColumnFilter',
            cellRenderer: (params) =>
              params.value.map((r) => `${r.category} - ${r.option}`).join(),
          },
        ],
      },
      getDetailRowData: (params) => params.successCallback(params.data.answers),
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Show / Hide Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: false,
            suppressPivots: false,
            suppressRowGroups: false,
            suppressValues: false,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsFeedbackOrder.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsFeedbackOrder;
