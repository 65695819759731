import { GET_CUSTOMER_LOC_SLUG } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  CurrentUserContext,
  FileDropzoneCadFile,
  FormGridWrap,
  FormSection,
  LoadingOrError,
  withFormWrap,
} from '../../../shared';
import { initialValues, schema, create } from './helpers';

function CadFileForm({
  initialValues = () => {},
  schema = () => {},
  match = {},
  separateId = null,
  ...rest
}) {
  const client = useApolloClient();
  const app = import.meta.env.VITE_APP_NAME;
  const { slug } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const [uploadPercentage, setUploadingPercentage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { data, loading, error } = useQuery(GET_CUSTOMER_LOC_SLUG, {
    skip: !(currentUser.scopeToLocation && app === 'customer_portal'),
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="DME files" />;

  const cadPathPrefix =
    currentUser.scopeToLocation && app === 'customer_portal'
      ? data.customerLocation.slug
      : slug;

  function updateUploadProgress(percentage) {
    setUploadingPercentage(percentage);
  }
  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      delete variables.variables.input.input.id;
    }

    if (match.params.action && match.params.action === 'new') {
      create({ variables, client, ...rest });
    } else {
      update({ variables, client, ...rest });
    }
  }
  return (
    <Formik
      initialValues={initialValues({ data, currentUser, match })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <FormGridWrap
          match={match}
          customMessage="DME file"
          fileUploading={uploading}
        >
          <FormSection>
            <Field
              name="fileUrl"
              component={FileDropzoneCadFile}
              required
              labelText="Upload A DME File"
              keyPrefix={`dme-files/${cadPathPrefix}/`}
              updateUploadProgress={updateUploadProgress}
              setUploading={() => setUploading(true)}
              setUploadingOff={() => setUploading(false)}
            />
            {uploadPercentage > 0 && (
              <progress
                value={uploadPercentage}
                max="100"
                className={`progress is-small caseProgressBar ${
                  uploadPercentage > 99 ? 'is-success' : 'is-primary'
                }`}
              >
                {uploadPercentage < 100 ? 'uploading ' : 'uploaded '}
                {values.filename}
              </progress>
            )}

            {uploadPercentage === 100 && (
              <p>DME uploaded, click create to save record.</p>
            )}
          </FormSection>
        </FormGridWrap>
      )}
    </Formik>
  );
}

CadFileForm.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
};

export default flowRight(withFormWrap({ initialValues, schema }))(CadFileForm);
