import { gql } from '@apollo/client';
import { SignupTemplateInfo } from '../fragments';

export default gql`
  mutation destroySignupTemplate($input: SignupTemplateDestroyInput!) {
    destroySignupTemplate(input: $input) {
      clientMutationId
      signupTemplate {
        ...SignupTemplateInfo
      }
    }
  }
  ${SignupTemplateInfo}
`;
