import { gql } from '@apollo/client';

export default gql`
  mutation createPrinter($input: PrinterCreateInput!) {
    createPrinter(input: $input) {
      errorMessages {
        message
      }
      printer {
        id
        name
        description
        imageUrl
        printerBrandId
      }
    }
  }
`;
