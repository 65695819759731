import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FieldContainer from '../FieldContainer/FieldContainer';

function Dropdown({
  name,
  label = null,
  value = null,
  id = null,
  placeholder = null,
  onChange = () => null,
  options = [],
  error = null,
  errors = false,
  loading = false,
  containerStyle = {},
  hideLabel = false,
  clearable = false,
  disabled = false,
  dropdownStyle = {},
  required = false,
  dropdownClasses = null,
  containerClassNames = null,
  infoCallback = null,
  size = '',
}) {
  const { setFieldTouched } = useFormikContext();

  function handleBlur() {
    setFieldTouched(name, true);
  }

  return (
    <FieldContainer
      required={required}
      name={name}
      label={label}
      style={containerStyle}
      hideLabel={hideLabel}
      classNames={containerClassNames}
      size={size}
      infoCallback={infoCallback}
    >
      {error && <p>Error...</p>}

      {!error && (
        <div
          className={`select ${loading ? 'is-loading' : ''} ${size}`}
          style={{ width: '100%' }}
        >
          <select
            style={{ width: '100%', dropdownStyle }}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            onBlur={handleBlur}
            value={!value && placeholder ? placeholder : value}
            id={id || name}
            name={name}
            className={`${
              errors ? 'is-danger' : ''
            } ${dropdownClasses} ${size}`}
            disabled={disabled}
          >
            {placeholder && (
              <option className={size} disabled>
                {placeholder}
              </option>
            )}
            {clearable && (
              <option className={size} value="">
                {' '}
              </option>
            )}

            {options?.map((d) => (
              <option
                className={size}
                key={d.id || d.value}
                value={d.value}
                style={{ width: '100%' }}
              >
                {d.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </FieldContainer>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.object,
  errors: PropTypes.bool,
  loading: PropTypes.bool,
  containerStyle: PropTypes.object,
  hideLabel: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownStyle: PropTypes.object,
  required: PropTypes.bool,
  dropdownClasses: PropTypes.string,
  containerClassNames: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  infoCallback: PropTypes.func,
};

export default Dropdown;
