import {
  DESTROY_ORDER_FEEDBACK,
  GET_ORDER,
  GET_ORDER_APPROVAL_LOG,
} from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';
import { handleTryCatch } from '../../HelperFunctions';

const deleteOrderFeedback = handleTryCatch(
  async ({
    deleteFeedbackMutation,
    approvalDoctorId,
    orderId,
    id,
    closeModal,
  }) => {
    await deleteFeedbackMutation({
      mutation: DESTROY_ORDER_FEEDBACK,
      variables: {
        input: {
          id,
          clientMutationId: uuidv4(),
        },
      },
      refetchQueries: [
        {
          query: approvalDoctorId ? GET_ORDER_APPROVAL_LOG : GET_ORDER,
          variables: { id: approvalDoctorId || orderId },
        },
      ],
    });
    if (closeModal) {
      closeModal();
    }
  },
  {
    successText: 'Feedback was successfully deleted',
  },
);

export default deleteOrderFeedback;
