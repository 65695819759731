import { ObjViewer } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import {
  ElementActionsConvertedTreatmentFiles,
  matchedObjFiles,
} from '../../../../..';

function ViewerContainer({
  convertedTreatmentReviewFiles,
  treatmentReviewFiles,
  redesignCountObj = null,
  objFiles = [],
  useNewViewer = true,
  height = '70vh',
  iprs = null,
}) {
  const [files, setFiles] = useState([]);
  const [isShown, setIsShown] = useState(true);

  const isNewViewer = useMemo(
    () =>
      convertedTreatmentReviewFiles?.find(
        (item) => item.redesignCount === redesignCountObj,
      ) &&
      treatmentReviewFiles?.find(
        (item) =>
          item.originalFileName.endsWith('.otp') &&
          item.redesignCount === redesignCountObj,
      ) &&
      useNewViewer,

    [
      convertedTreatmentReviewFiles,
      redesignCountObj,
      treatmentReviewFiles,
      useNewViewer,
    ],
  );

  useEffect(() => {
    setIsShown(false);
    setTimeout(() => {
      setIsShown(true);
    }, 0);
  }, [redesignCountObj]);

  useEffect(() => {
    function getFileUrls() {
      const latestObjs = objFiles.filter(
        (file) =>
          file.ver === file.latestVer && file.originalFileName.endsWith('.obj'),
      );

      const objFilesFromRedesignVersion = matchedObjFiles(
        latestObjs,
        redesignCountObj,
      );

      setFiles(
        objFilesFromRedesignVersion.map((item) => ({
          ...item,
          signedUrl: item.downloadUrl,
        })),
      );
    }

    const getConvertedFilesUrl = () => {
      const filesFromCurrentRedesign = convertedTreatmentReviewFiles
        ?.filter(({ redesignCount }) => redesignCount === redesignCountObj)
        .map((item) => ({
          ...item,
          signedUrl: item.downloadUrl,
        }));
      setFiles(filesFromCurrentRedesign);
    };

    if (isNewViewer) {
      getConvertedFilesUrl();
    } else {
      getFileUrls();
    }
  }, [objFiles, redesignCountObj, convertedTreatmentReviewFiles, isNewViewer]);

  const viewerComponent = () => {
    if (isNewViewer) {
      return (
        <div style={{ height: '100%' }}>
          <ElementActionsConvertedTreatmentFiles
            files={treatmentReviewFiles}
            selectedItems={files}
            redesignCount={redesignCountObj}
            containerStyle={{ height, minHeight: '500px' }}
            iprs={iprs}
          />
        </div>
      );
    }

    return <ObjViewer files={files} />;
  };

  return isShown && files?.length > 0 ? viewerComponent() : <div />;
}

ViewerContainer.propTypes = {
  objFiles: PropTypes.array,
  useNewViewer: PropTypes.bool,
  redesignCountObj: PropTypes.number,
  convertedTreatmentReviewFiles: PropTypes.array.isRequired,
  treatmentReviewFiles: PropTypes.array.isRequired,
  iprs: PropTypes.object,
  height: PropTypes.string,
};

export default ViewerContainer;
