import PropTypes from 'prop-types';
import Styles from './SignInLogo.module.css';

function SignInLogo({ authState = null }) {
  return authState && authState !== 'signedIn' && authState !== 'loading' ? (
    <div className={Styles.logoContainer}>
      <div
        className="my-4"
        style={{
          maxWidth: 500,
          margin: '0 auto',
          overflow: 'hidden',
          borderRadius: 20,
        }}
      >
        <img
          src="https://cdn.fullcontour.com/logos/3ShapeDesignServiceLogoGrey.svg"
          alt="3Shape design action service logo"
        />
      </div>
    </div>
  ) : null;
}

SignInLogo.propTypes = {
  authState: PropTypes.string,
};

export default SignInLogo;
