import PropTypes from 'prop-types';

import useAvaliableManufactures from './helpers/useAvaliableManufactures';
import InfoItemBasic from './InfoItemBasic';
import InfoItemDesign from './InfoItemDesign';
import InfoItemDestination from './InfoItemDestination';
import InfoItemUnitPrice from './InfoItemUnitPrice';

function InfoItem(
{  item,
  designTypes,
  assignedManufacturers,
  setFieldValue,
  index,
  values,
  order,}
) {
  const availableManufacturers = useAvaliableManufactures({
    designTypes,
    item,
    assignedManufacturers,
  });

  return (
    <div className="columns">
      <InfoItemBasic item={item} />
      <InfoItemDesign item={item} />
      <InfoItemDestination
        item={item}
        manufacturers={availableManufacturers}
        setFieldValue={setFieldValue}
        index={index}
        values={values}
        order={order}
      />
      <InfoItemUnitPrice item={item} />
    </div>
  );
}

InfoItem.propTypes = {
  item: PropTypes.object.isRequired,
  designTypes: PropTypes.array.isRequired,
  assignedManufacturers: PropTypes.array.isRequired,
};

export default InfoItem;
