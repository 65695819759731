function extractObject(element) {
  const elementObj = {};

  element.forEach((property) => {
    elementObj[property.name] = property.value;
  });

  return elementObj;
}

export default extractObject;
