import moment from 'moment';
import PropTypes from 'prop-types';
import { isSameDay } from 'react-dates';
import { useTranslation } from 'react-i18next';
import './DateRangeSelectPresets.css';

function DateRangePresets({
  onDatesChange,
  startDate = null,
  endDate = null,
  showExtraPresets,
}) {
  const { t } = useTranslation('navigation');

  let presets = [
    {
      text: 'Today',
      start: moment(),
      end: moment(),
    },
    {
      text: 'Yesterday',
      start: moment().subtract(1, 'day'),
      end: moment().subtract(1, 'day'),
    },
    {
      text: 'Last Week',
      start: moment().subtract(1, 'week').startOf('isoWeek'),
      end: moment().subtract(1, 'week').endOf('isoWeek'),
    },
  ];

  if (showExtraPresets) {
    presets = [
      ...presets,
      {
        text: 'Last Month',
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
      },
      { text: 'QTD', start: moment().startOf('quarter'), end: moment() },
      { text: 'YTD', start: moment().startOf('year'), end: moment() },
    ];
  }

  return (
    <div className="PresetDateRangePicker_panel">
      {presets.map(({ text, start, end }) => {
        const isSelected =
          isSameDay(start, startDate) && isSameDay(end, endDate);

        return (
          <button
            key={text}
            className={`PresetDateRangePicker_button ${
              isSelected ? 'PresetDateRangePicker_button__selected' : ''
            }`}
            type="button"
            onClick={() =>
              onDatesChange({
                startDate: start,
                endDate: end,
                selectedRange: text,
              })
            }
          >
            {t(text)}
          </button>
        );
      })}
    </div>
  );
}

DateRangePresets.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showExtraPresets: PropTypes.bool.isRequired,
};

export default DateRangePresets;
