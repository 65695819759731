import parseNode from './threeShapeNode';

class ThreeShapeXmlAttributes {
  constructor(jsonObj) {
    this.jsonObj = jsonObj;
    this.items = this.itemsNode();
  }

  software = () => this.jsonObj.DentalContainer.version;

  instructions = () =>
    this.items.find((item) => item.name === 'OrderComments').value;

  name = () => {
    const first = this.items.find(
      (item) => item.name === 'Patient_FirstName',
    ).value;
    const last = this.items.find(
      (item) => item.name === 'Patient_LastName',
    ).value;

    const fullName = `${first} ${last}`;

    return fullName.trim();
  };

  itemsNode = () =>
    this.jsonObj.DentalContainer.Object.Object.find(
      (obj) => obj.name === 'OrderList',
    ).List.Object.Property;

  parsedDesigns = () => {
    const elementsWithTeeth = this.mappedTeethToElement();

    const modelJobs = parseNode(this.jsonObj, 'ModelJobList').map((job) => {
      const modelElements = elementsWithTeeth.filter(
        (element) => job.ModelJobID === element.ModelJobID,
      );

      return { ...job, modelElements };
    });

    const teeth = [
      ...new Set(
        elementsWithTeeth.flatMap((el) =>
          el.toothElements.map((tooth) => tooth.ToothNumber),
        ),
      ),
    ];

    const materials = [
      ...new Set(elementsWithTeeth.map((el) => el.CacheMaterialName)),
    ];

    return { modelJobs, teeth, materials };
  };

  mappedTeethToElement = () =>
    parseNode(this.jsonObj, 'ModelElementList').map((element) => {
      const toothElements = parseNode(this.jsonObj, 'ToothElementList').filter(
        (toothEl) => element.ModelElementID === toothEl.ModelElementID,
      );

      return { ...element, toothElements };
    });
}

export default ThreeShapeXmlAttributes;
