import { gql } from '@apollo/client';
import { ReportTimeFrameInfo } from '../fragments';

export default gql`
  query reportTimeFrames {
    reportTimeFrames {
      ...ReportTimeFrameInfo
    }
  }
  ${ReportTimeFrameInfo}
`;
