import { gql } from '@apollo/client';

export default gql`
  query caseFolders {
    caseFolders {
      id
      name
      slug
      folderName
      description
      mimeTypes
      allowMultiple
    }
  }
`;
