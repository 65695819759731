const pushToFinished = {
  admin_portal: {
    visible: true,
    statuses: {
      needs_approval: [0, 10, 20, 30, 40],
      sent_for_approval: [0, 10, 20, 30, 40],
      ready_for_download: [0, 10, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40],
      at_manufacturer: [0, 10, 20, 30, 40],
      manufacturing_hold: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: false,
  },
};

export default pushToFinished;
