import { useMutation, useQuery } from '@apollo/client';
import { DESTROY_REPORT, GET_REPORT_RESULTS } from '@fullcontour/shared-api';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { LoadingOrError } from '../../../shared';
import ResultTypes from '../ReportForm/Results/ResultTypes';

function ReportSaved() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_REPORT_RESULTS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const [destroy, { loading: destroyLoading, error: destroyError }] =
    useMutation(DESTROY_REPORT, {
      onCompleted: () => {
        navigate(`/reports`);
      },
    });

  const deleteReport = () => {
    destroy({
      variables: {
        input: { clientMutationId: uuidv4(), id },
      },
    });
  };

  if (loading || error || destroyLoading || destroyError)
    return (
      <LoadingOrError
        error={error || destroyError}
        loading={loading || destroyLoading}
        title="Saved Reports"
      />
    );

  return (
    <div>
      <div className="columns">
        <div className="column has-text-right">
          <button
            className="button is-danger is-outlined mr-3"
            type="button"
            onClick={deleteReport}
          >
            <span className="icon is-small">
              <i className="bx bx-block" />
            </span>
            <span>Delete</span>
          </button>
          <Link to="/reports/new" type="button" className="button">
            <i className="bx bx-plus-medical has-text-grey mr-2" />
            New Report
          </Link>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <ResultTypes results={data.reportResults.results} />
        </div>
      </div>
    </div>
  );
}

export default ReportSaved;
