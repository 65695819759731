import PropTypes from 'prop-types';
import { useContext } from 'react';
import TabsContext from './TabsContext';

function TabPanel({ tabId, children = null, idx = null }) {
  const { selectedTabId } = useContext(TabsContext);

  return selectedTabId === tabId || (idx === 0 && !selectedTabId)
    ? children
    : null;
}

TabPanel.displayName = 'Tabs.TabPanel';

TabPanel.propTypes = {
  tabId: PropTypes.string.isRequired,
  idx: PropTypes.number,
  children: PropTypes.node,
};

export default TabPanel;
