import { gql } from '@apollo/client';

export default gql`
  query customerLocationCategories($slug: String!) {
    designTypeCategories {
      id
      name
      designLocations {
        id
        name
        key
        text
        value
      }
    }
    customerLocation(slug: $slug) {
      id
      name
      slug
      siloRoutes {
        id
        designTypeCategoryId
        primarySiloId
        secondarySiloId
        catchAllSiloId
        designTypeCategory {
          id
          name
        }
      }
      priceListCategories {
        id
        name
        designLocations {
          id
          name
          key
          text
          value
        }
      }
    }
  }
`;
