export const baseStyle = {
  position: 'relative',
  width: 200,
  height: 'auto',
  borderWidth: 2,
  borderColor: '#8e8e8e',
  borderStyle: 'dashed',
  borderRadius: 5,
  cursor: 'pointer',
  padding: '0.5em',
  margin: '1em 0',
};

export const activeStyle = {
  ...baseStyle,
  borderColor: 'rgba(61, 172, 209, 0.9)',
  backgroundColor: 'rgba(61, 172, 209, 0.1)',
  color: 'rgba(61, 172, 209, 0.9)',
};

export const rejectedStyle = {
  ...baseStyle,
  borderColor: 'rgba(228, 0, 0, 0.9)',
  backgroundColor: 'rgba(228, 0, 0, 0.1)',
  color: 'rgba(228, 0, 0, 0.9)',
};

export const successStyle = {
  ...baseStyle,
  borderColor: 'rgba(83, 201, 87)',
  backgroundColor: 'rgba(83, 201, 87, 0.1)',
  color: 'rgba(83, 201, 87)',
};

export const fileErrorStyle = {
  ...baseStyle,
  borderColor: 'rgba(228, 0, 0, 0.9)',
};
