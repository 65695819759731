import PropTypes from 'prop-types';

import ProfileSegment from './ProfileSegment';

function ProfileContainer(props) {
  const {
    priceListProfiles,
    values: { designProfiles },
  } = props;

  return (
    <div className="columns">
      <div className="column">
        {priceListProfiles?.map((profile) => (
          <ProfileSegment
            profile={profile}
            designProfileId={profile.id}
            rowLabel="active"
            foundIndex={designProfiles.findIndex((p) => p.id === profile.id)}
            {...props}
            key={profile.id}
          />
        ))}
      </div>
    </div>
  );
}

ProfileContainer.propTypes = {
  data: PropTypes.shape({
    customerLocation: PropTypes.object.isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
  priceListProfiles: PropTypes.array.isRequired,
  nonPriceListProfiles: PropTypes.array.isRequired,
};

export default ProfileContainer;
