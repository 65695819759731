/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { useMutation } from '@apollo/client';
import {
  GET_LOCATION_GUIDE_CODE_GROUPS,
  UPDATE_GUIDE_CODE_GROUP,
} from '@fullcontour/shared-api';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { cloneElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { classesGenerator } from '../../../../../../transforms';

function LocationGuideGroupsBodyAddModal(props) {
  const { modal, setModal, children, groupId, groups, groupName } = props;
  const { slug } = useParams();

  const { t } = useTranslation('orderactions');

  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'is-active': modal,
        modal: true,
      }),
    [modal],
  );

  const [updateGroup, { loading }] = useMutation(UPDATE_GUIDE_CODE_GROUP, {
    update(cache, { data: { updateGuideCodeGroup } }) {
      const data = cache.readQuery({
        query: GET_LOCATION_GUIDE_CODE_GROUPS,
      });
      const updatedData = data?.labLocationGuideCodeGroups.map((item) => {
        if (item.id === groupId) {
          return updateGuideCodeGroup.guideCodeGroup;
        }

        return item;
      });

      cache.writeQuery({
        query: GET_LOCATION_GUIDE_CODE_GROUPS,
        data: {
          labLocationGuideCodeGroups: updatedData,
        },
      });
    },
  });

  const { values, setValues, setFieldValue } = useFormikContext();

  const handleSubmit = async () => {
    const selectedCodes = Object.values(values.selectedCodes[groupId]);

    await updateGroup({
      variables: {
        input: {
          input: {
            id: groupId,
            name: groupName,
            locationSlug: slug,
            guideCodeIds: [
              ...Object.keys(values.codes).filter(Boolean),
              ...selectedCodes,
            ],
          },
        },
      },
    });

    setValues({ selectedCodes: values.selectedCodes });
    setModal(false);
  };

  const onChange = (code, category) => {
    const { codes = {} } = values;
    let updatedCodes = codes;
    if (category.showDropdown) {
      updatedCodes = Object.keys(codes).reduce((obj, key) => {
        if (category.guideCodes.some((item) => item.id === key)) {
          return obj;
        }
        obj[key] = codes[key];

        return obj;
      }, {});
    }
    setFieldValue('codes', {
      ...(updatedCodes || {}),
      [code.id]: !codes[code.id],
    });
  };

  return (
    <div className={modalClasses}>
      <div
        className="modal-background"
        onClick={() => setModal(false)}
        onKeyDown={() => setModal(false)}
        role="button"
        tabIndex={0}
        aria-label="close"
      />
      <div
        className="modal-card"
        style={{
          maxWidth: 1100,
          width: '100%',
          padding: '100px 0',
          minHeight: '60vh',
        }}
      >
        <section className="modal-card-body">
          {cloneElement(children, { onChange, selectedCodes: values.codes })}
        </section>
        <footer className="modal-card-foot is-justify-content-end">
          <button
            type="button"
            aria-label="close"
            className="button is-danger is-outlined"
            onClick={() => setModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            {t('Save')}
          </button>
        </footer>
      </div>
    </div>
  );
}

LocationGuideGroupsBodyAddModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default LocationGuideGroupsBodyAddModal;
