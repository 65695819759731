import { useLocation, useNavigate, useParams } from 'react-router';
import { generatePathParams, getRoot } from './functions';

/**
 * Custom hook that provides easy access to react router related properties.
 *
 * @typedef {Object} Match
 * @property {Object} params - The route parameters.
 *
 * @typedef {Object} RouterProps
 * @property {Match} match - Object containing route parameters.
 * @property {Object} location - The current location object.
 * @property {Function} navigate - Function to navigate programmatically.
 * @property {String} root - Root path, returns null if path is /.
 *
 * @returns {RouterProps} An object containing match, location, and navigate properties.
 */
export default function useRouter({ pathParamOptions }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const root = getRoot();
  const pathParams = generatePathParams(pathParamOptions ?? []);
  return {
    match: { params: { root, ...pathParams, ...params } },
    location,
    navigate,
  };
}
