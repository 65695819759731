/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BoxIcon } from '../../../..';

function TreatmentCardHeader({
  v,
  stages,
  last = false,
  idx = null,
  current = false,
  handleTitleClickVersion = () => null,
  resetHistory = () => null,
  light = null,
  active = null,
  date = null,
}) {
  const { t } = useTranslation('common');

  function elapsedTime() {
    if (light) {
      return stages * 10 ? `${stages * 10} ${t('days')}` : '';
    }

    return stages * 2 ? `${stages * 2} ${t('weeks')}` : '';
  }

  function titleHandler() {
    handleTitleClickVersion({ index: idx, active, v });
    resetHistory();
  }

  return (
    <div
      className="treatment-card__header is-clickable"
      onClick={titleHandler}
      role="button"
      tabIndex={0}
      onKeyDown={titleHandler}
      style={{
        ...(last
          ? {
              background: '#CCDCEB',
              cursor: 'pointer',
            }
          : {}),
        ...(current
          ? {
              boxShadow: `0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5,
        inset 0px 0px 0px 1px #1777c1`,
            }
          : {}),
      }}
    >
      <div>
        <div
          className="treatment-card__header-version"
          style={{ ...(last ? { background: '#1A77C8' } : {}) }}
        >
          v{v}
        </div>
        <div className="treatment-card__header-date">{date}</div>
      </div>
      <div>
        <div className="treatment-card__header-stages">
          {stages ? `${stages} stages,` : ''}
        </div>
        <div className="treatment-card__header-weeks">
          <BoxIcon name="bx bx-calendar" />
          {elapsedTime()}
        </div>
      </div>
    </div>
  );
}

TreatmentCardHeader.propTypes = {
  v: PropTypes.number.isRequired,
  idx: PropTypes.string,
  date: PropTypes.string,
  resetHistory: PropTypes.func,
  handleTitleClickVersion: PropTypes.func,
  stages: PropTypes.number.isRequired,
  current: PropTypes.bool,
  last: PropTypes.bool,
  light: PropTypes.bool,
  active: PropTypes.bool,
};

export default TreatmentCardHeader;
