import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TempOrderHeaderRemoveAll(props) {
  const { tempOrders, removeAllTempOrders } = props;

  const { t } = useTranslation('designProfile');

  return tempOrders.length > 0 ? (
    <button
      type="button"
      className="button is-size-7 mr-3 is-danger is-outlined"
      onClick={removeAllTempOrders}
    >
      <span className="icon">
        <i className="bx bx-trash" />
      </span>
      <span>{t('Remove All')}</span>
    </button>
  ) : null;
}

TempOrderHeaderRemoveAll.propTypes = {
  tempOrders: PropTypes.array.isRequired,
  removeAllTempOrders: PropTypes.func.isRequired,
};

export default TempOrderHeaderRemoveAll;
