/* eslint-disable no-restricted-syntax */
const makeUnique = (arr) => {
  const uniqueObjMap = {};
  for (const object of arr) {
    uniqueObjMap[object.name] = object;
  }

  return Object.values(uniqueObjMap);
};

function makeNestedArraysUnique(arr) {
  const updatedArray = [];
  arr.forEach((item, index) => {
    const updatedDesignPref = makeUnique(item.designPreferences);

    updatedArray[index] = {
      ...item,
      designPreferences: updatedDesignPref,
    };
  });
  return updatedArray;
}

function initialValues(props) {
  const { data: { customerLocation = {} } = {} } = props;
  return {
    id: customerLocation.id,
    selectedDesignTypes: [],
    keepExistingCustomInstructions:
      customerLocation.keepExistingCustomInstructions || false,
    designTypeInstructions:
      customerLocation.designTypeInstructions?.map((item) => ({
        value: item.designType.id,
        name: item.designType.name,
        instruction: item.instruction,
      })) || [],
    customInstructions: customerLocation.customInstructions || '',
    designProfiles:
      !Array.isArray(customerLocation.designProfiles) ||
      !customerLocation.designProfiles.length
        ? []
        : makeNestedArraysUnique(customerLocation.designProfiles),
  };
}

export default initialValues;
