import { useApolloClient } from '@apollo/client';
import { OrthoViewer } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import {
  latestOtpFile,
  matchedConvertedFiles,
} from '../../../../../../../../transforms';

function ElementActionsConvertedTreatmentFiles({
  selectedItems,
  redesignCount,
  files,
  containerStyle: { height = '90vh' } = {},
  iprs = null,
}) {
  const client = useApolloClient();
  const [otpData, setOtpData] = useState();
  const [newOtpVersion, setNewOtpVesrion] = useState(false);

  const otpMemo = useMemo(() => otpData, [otpData]);

  const parsedFiles = useMemo(
    () => matchedConvertedFiles(selectedItems, redesignCount),
    [redesignCount, selectedItems],
  );

  useEffect(() => {
    if (otpMemo !== undefined) return;
    let active = true;
    async function loadOtp() {
      setOtpData(undefined); // this is optional
      const otpRes = await Promise.resolve(
        latestOtpFile(files, redesignCount, client, '.otp'),
      );
      if (!active) {
        return;
      }

      // TODO: find the diff between old and new OTP
      // const isNewOtp =
      //   otpRes &&
      //   otpRes[0].Teeth.some(
      //     (tooth) =>
      //       tooth.InterproximalReduction &&
      //       Object.keys(tooth.InterproximalReduction).length === 0
      //   );

      setNewOtpVesrion(false);
      setOtpData(otpRes);
    }
    loadOtp();
    // eslint-disable-next-line consistent-return
    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrthoViewer
      files={parsedFiles.objFiles}
      diffFiles={parsedFiles.diffFiles}
      otpData={otpMemo}
      containerStyle={{ height }}
      iprs={iprs}
      newOtpVersion={newOtpVersion}
    />
  );
}

ElementActionsConvertedTreatmentFiles.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  redesignCount: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  containerStyle: PropTypes.object,
  iprs: PropTypes.object,
};

export default ElementActionsConvertedTreatmentFiles;
