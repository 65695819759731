import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import ResultsOrderCustomerGrid from './ResultsOrderCustomerGrid';

function ResultsOrderCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'Case Details',
        marryChildren: true,
        children: [
          {
            field: 'hex_order_code',
            headerName: 'Case ID',
            type: 'orderIdColumn',
          },
          {
            field: 'int_order_id',
            headerName: 'Filename',
            width: 175,
            minWidth: 175,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'closed',
          },
          {
            field: 'name',
            width: 175,
            minWidth: 175,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'closed',
          },
          {
            field: 'requires_design_approval',
            headerName: 'Approval',
            type: 'approvalColumn',
            columnGroupShow: 'closed',
          },
          {
            field: 'turnaround_time_name',
            headerName: 'Turnaround',
            width: 200,
            minWidth: 200,
            filter: 'agSetColumnFilter',
            columnGroupShow: 'closed',
          },
          {
            field: 'software_version',
            headerName: 'Software',
            width: 125,
            minWidth: 125,
            filter: 'agSetColumnFilter',
            columnGroupShow: 'closed',
          },
        ],
      },
      {
        field: 'design_location_name',
        headerName: 'Design Silo',
        width: 150,
        minWidth: 150,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'state',
        headerName: 'Status',
        width: 175,
        minWidth: 175,
        filter: 'agSetColumnFilter',
        cellRenderer: 'gridCellStatus',
      },
      {
        headerName: 'Customer',
        marryChildren: true,
        children: [
          {
            field: 'lab_location_name',
            headerName: 'Customer Name',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            width: 150,
            minWidth: 150,
            filter: 'agSetColumnFilter',
            onCellClicked: (params) => {
              params.context.navigate(
                `/locations/customer/${params.data.lab_location_slug}`,
              );
            },
          },
          {
            field: 'lab_location_code',
            headerName: 'Customer Code',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Case Items',
        marryChildren: true,
        children: [
          {
            field: 'items_metadata.units',
            headerName: 'Total Units',
            width: 150,
            minWidth: 150,
            type: 'numericColumn',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.design_types',
            headerName: 'Design Types',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.teeth',
            headerName: 'Teeth',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.arches',
            headerName: 'Arches',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.materials',
            headerName: 'Materials',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.modifiers',
            headerName: 'Modifiers',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.design_guide_codes',
            headerName: 'Design Guide Codes',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'items_metadata.destinations',
            headerName: 'Manufacturers',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        headerName: 'Design Preferences',
        marryChildren: true,
        children: [
          {
            field: 'instructions',
            headerName: 'Instructions',
            width: 200,
            minWidth: 200,
            filter: 'agTextColumnFilter',
          },
          {
            field: 'lab_location_custom_instructions',
            headerName: 'Locations Custom Instructions',
            width: 220,
            minWidth: 220,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'csv_design_preferences.Contacts',
            headerName: 'Contact Preferences',
            width: 170,
            minWidth: 170,
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
          },
          {
            field: 'csv_design_preferences.Occlusion',
            headerName: 'Occlusion Preferences',
            width: 170,
            minWidth: 170,
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
          },
          {
            field: 'csv_design_preferences.Library',
            headerName: 'Design Library',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Time Data',
        marryChildren: true,
        children: [
          {
            field: 'created_at',
            headerName: 'Submitted',
            type: 'dateColumn',
          },
          {
            field: 'reassigned_at',
            headerName: 'Reassignment Time',
            type: 'dateColumn',
          },
          {
            field: 'design_completion_time',
            headerName: 'Time Due',
            columnGroupShow: 'open',
            cellStyle: (params) => ({
              color: isPast(new Date(params.value)) ? '#E40000' : 'inherit',
            }),
            type: 'dateColumn',
          },
        ],
      },
    ],
  };

  return (
    <ResultsOrderCustomerGrid
      gridOptions={gridOptions}
      reportDetails={reportDetails}
    />
  );
}

ResultsOrderCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsOrderCustomer;
