const transformCodeToStoreInFormik = (code) => ({
  categoryId: code.category.id,
  isDefault: code.default,
  codeId: code.id,
  categoryName: code.category.name,
  showDropdown: code.category.showDropdown,
  codes: [code.name],
  fileUrl: code.imageUrl || code.category.imageUrl,
  description: code.category.showDropdown
    ? code.description || code.category.short_description
    : code.category.short_description,
  categoryAdditionalInfo: code.categoryAdditionalInfo,
});

export default transformCodeToStoreInFormik;
