import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createSignedLink, Placeholder } from '../../../../shared';

function OrderMessageImage(props) {
  const {
    message,
    multipleFiles,
    setIsOpen,
    setMessageImages,
    setCurrentMessagePhotoModal,
    setPhotoIndex,
    messageId,
  } = props;

  const client = useApolloClient();
  const [image, setImage] = useState(null);
  const [imageDownloaded, setImageDownloaded] = useState(false);
  const [photoPosition, setPhotoPosition] = useState(0);

  useEffect(() => {
    async function getFileUrl() {
      const { signedUrl } = await createSignedLink({
        client,
        foundFile: {
          s3Key: multipleFiles
            ? message.imageUrl.split('/').slice(3).join('/')
            : message.s3Id,
        },
      });
      if (setImage) {
        setImage(signedUrl);
      }
      if (setMessageImages) {
        setMessageImages((prev) => ({
          ...prev,
          [messageId]: (() => {
            if (prev[messageId]) {
              const updatedArray = [...prev[messageId], signedUrl];
              setPhotoPosition(updatedArray.length - 1);
              return updatedArray;
            }
            setPhotoPosition(0);
            return [signedUrl];
          })(),
        }));
      }
    }

    getFileUrl();
  }, [message, client, multipleFiles, messageId, setMessageImages]);

  const imageClickHandler = () => {
    if (setPhotoIndex) setPhotoIndex(photoPosition);
    if (setCurrentMessagePhotoModal) setCurrentMessagePhotoModal(messageId);
    if (setIsOpen) setIsOpen(true);
  };

  return (
    <div
      style={{
        width: '140px',
        height: '100px',
        overflow: 'hidden',
        marginBottom: '10px',
        cursor: 'pointer',
        ...(multipleFiles ? { marginLeft: '10px' } : {}),
      }}
      onKeyDown={imageClickHandler}
      onClick={imageClickHandler}
      role="button"
      tabIndex={0}
    >
      {!imageDownloaded && <Placeholder height="100px" />}
      <img
        style={{
          width: '100%',
          cursor: 'pointer',
          objectFit: 'cover',
          height: '100%',
        }}
        src={image}
        onLoad={() => setImageDownloaded(true)}
        alt={message.s3Id}
      />
    </div>
  );
}

OrderMessageImage.propTypes = {
  message: PropTypes.object.isRequired,
  setMessageImages: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setPhotoIndex: PropTypes.func.isRequired,
  setCurrentMessagePhotoModal: PropTypes.func.isRequired,
  multipleFiles: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default OrderMessageImage;
