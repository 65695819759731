import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createOrderFeedback($input: OrderApprovalLogFeedbackCreateInput!) {
    createOrderFeedback(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      feedback {
        id
        redesignCount
        rating
        answers {
          orderItem {
            id
            designTypeName
            designTypeId
          }
          id
          rating
          options {
            id
            name
            category {
              name
              id
            }
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
