import { Suspense, useContext } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { BaseLoader } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import sharedRoutes from './main';
import { createRoutesArray } from '../../utils/functions';

function SidebarMainRoutes() {
  const { pathname } = useLocation();
  const { mainRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);
  const scopedRoutes = mainRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }
    return route.so === true;
  });
  const processedRoutes = createRoutesArray(
    [...scopedRoutes, ...sharedRoutes],
    false,
    pathname,
  );
  const Routes = useRoutes(processedRoutes);
  return <Suspense fallback={<BaseLoader />}>{Routes}</Suspense>;
}

export default SidebarMainRoutes;
