import { useTranslation } from 'react-i18next';

function FeedbackHeader() {
  const { t } = useTranslation('common');

  return (
    <h5 className="pb-4" style={{ borderBottom: '1px solid #ddd' }}>
      {t('Tell us more')}...
    </h5>
  );
}

export default FeedbackHeader;
