import { OverpanelUploadContext } from '@fullcontour/common';
import { useContext } from 'react';

function OverpanelFormProgress() {
  const { uploadPercentage, failedUpload } = useContext(OverpanelUploadContext);

  const getClassName = () => {
    if (uploadPercentage > 99 && !failedUpload) {
      return 'is-success';
    }
    if (failedUpload) {
      return 'is-danger';
    }
    return 'is-primary';
  };

  return (
    <progress
      value={uploadPercentage}
      className={`progress mb-0 ${getClassName()} is-small caseProgressBar`}
      max="100"
    />
  );
}

export default OverpanelFormProgress;
