/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import ChartSelectionPopup from './ChartSelectionPopup/ChartSelectionPopup';

function ChartSelection(props) {
  const {
    index,
    dataSources: {
      customerLocation: { numberingSystem },
    },
    form: { setFieldValue },
    values: { businessUnitName, orderItems },
    data: { designTypes },
    setShouldShow,
  } = props;
  if (businessUnitName === '') {
    return 'Please first select a category';
  }
  if (!designTypes[businessUnitName]) {
    return 'No Design Types available';
  }
  const selectedDesignType = designTypes[businessUnitName].find(
    ({ id }) => id === orderItems[index].designTypeId,
  );
  if (!selectedDesignType) {
    return 'Please select your indication';
  }
  const calculateUnits = ({ newTeeth = null, newArches = null }) => {
    let totalUnits = 1;

    if (selectedDesignType.billByTooth) {
      const teeth = newTeeth || orderItems[index].teeth;
      totalUnits = teeth.length > 0 ? teeth.length : 1;
    }

    if (selectedDesignType.billByArch) {
      if (selectedDesignType.toothChart && newTeeth) {
        let isToothFromLowerArch = false;
        let isToothFromUpperArch = false;

        for (const tooth of newTeeth) {
          if (tooth < 17) {
            isToothFromUpperArch = true;
          } else {
            isToothFromLowerArch = true;
          }
        }

        totalUnits = isToothFromLowerArch + isToothFromUpperArch || 1;
      } else {
        const arches = newArches || orderItems[index].arches;
        totalUnits = arches.length > 0 ? arches.length : 1;
      }
    }

    setFieldValue(`orderItems[${index}].units`, totalUnits, false);
  };

  useEffect(() => {
    if (
      selectedDesignType !== undefined &&
      !selectedDesignType.toothChart &&
      !selectedDesignType.archChart
    ) {
      setShouldShow(false);
    }
  }, []);

  useEffect(() => {
    if (selectedDesignType) {
      calculateUnits({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDesignType]);

  const toggleArch = (arch) => {
    let newArches;

    const foundArchIndex = orderItems[index].arches.findIndex(
      (arche) => arche === arch,
    );

    if (foundArchIndex === -1) {
      newArches = [...orderItems[index].arches, arch];
    }

    if (foundArchIndex !== -1) {
      newArches = orderItems[index].arches.filter((arche) => arche !== arch);
    }

    setFieldValue(`orderItems[${index}].arches`, newArches);
    calculateUnits({ newArches });
  };

  const toggleTooth = (toothNumber) => {
    let newTeeth;

    const foundToothIndex = orderItems[index].teeth.findIndex(
      (tooth) => Number(tooth) === toothNumber,
    );

    if (foundToothIndex === -1) {
      newTeeth = [...orderItems[index].teeth, toothNumber];
    }

    if (foundToothIndex !== -1) {
      newTeeth = orderItems[index].teeth.filter(
        (tooth) => tooth !== toothNumber,
      );
    }

    setFieldValue(`orderItems[${index}].teeth`, newTeeth);
    calculateUnits({ newTeeth });
  };

  return selectedDesignType &&
    (selectedDesignType.toothChart || selectedDesignType.archChart) ? (
    <ChartSelectionPopup
      designType={selectedDesignType}
      orderItem={orderItems[index]}
      toggleArch={toggleArch}
      toggleTooth={toggleTooth}
      numberingSystem={numberingSystem}
    />
  ) : null;
}

ChartSelection.propTypes = {
  index: PropTypes.number.isRequired,
  dataSources: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setShouldShow: PropTypes.func.isRequired,
};

export default memo(ChartSelection);
