/* eslint-disable no-underscore-dangle */
import { useApolloClient } from '@apollo/client';
import { CreateNotification, OutsideClick } from '@fullcontour/common';
import { ACTION_USER_RESEND_INVITE } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function ShowUserDropdown({ editLink, user }) {
  const client = useApolloClient();
  const { t } = useTranslation('showelements');

  const [actionsOpen, setActionsOpen] = useState(false);

  async function resendInvite() {
    setActionsOpen(false);

    await client.mutate({
      mutation: ACTION_USER_RESEND_INVITE,
      variables: {
        input: {
          input: {
            id: user.id,
            klass: 'User',
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    showMessage();
  }

  function showMessage() {
    CreateNotification.createNotification({
      type: 'success',
      message: 'The invitation has been resent',
    });
  }

  const handleActionsOpen = () => {
    setActionsOpen((prev) => !prev);
  };

  return (
    <OutsideClick fn={() => setActionsOpen(false)}>
      <div
        className={`dropdown is-right mb-2 ${actionsOpen ? 'is-active' : ''}`}
      >
        <div className="dropdown-trigger">
          <button
            className="button is-dark"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            type="button"
            onClick={handleActionsOpen}
          >
            <span className="icon is-size-4 has-text-grey-light mx-1">
              <i className="bx bx-cog" aria-hidden="true" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu pt-0" id="dropdown-menu" role="menu">
          <div className="dropdown-content is-pulled-right has-text-left is-align-content-center">
            <div className="dropdown-item">
              <Link to={editLink}>
                <div className="has-text-black-ter is-size-6">{t('Edit')}</div>
              </Link>
            </div>
            {user.state === 'pending' && (
              <div
                className="dropdown-item has-text-black-ter is-size-6 is-clickable"
                onClick={resendInvite}
                aria-hidden="true"
              >
                {t('Resend Invite')}
              </div>
            )}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
}

ShowUserDropdown.propTypes = {
  editLink: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default ShowUserDropdown;
