import PropTypes from 'prop-types';

function Tooth23({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 23)
    : lowerArch;
  const variable = designType.toothChart ? 23 : 'lower';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[23]?.includes(guideCodeId);

  return (
    <>
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M2188.9 4685.4c22.1 32.1 83.3 64.1 14 96.5"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2137.3 4719.1l-15.3 74.2c-10 35.7-20.1 21-30.2 0.1"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2267.5 4747.2c-1.6 26.2 0.9 54.9-31.7 61.3 -27.7 9.6-62.5 19.1-73.2 28.6 -45.9 25.3-50.2 12.1-68.9 12.2"
      />
      <path
        className={`${
          active ? `st1 activeTooth ${selected ? 'toothSelected' : ''}` : 'st1'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M2099.2 4664c16.4-45.8 56.4-46.3 83.6-24.4l38.9 49 45.8 43.8c6.7 20.5 24.6 19.3 3.7 93.1 -12.6 39.9-37.8 57.8-68.8 65.7 -39.7 11.4-73.7 4.1-104.3-14.4 -20.9-21.5-53.9-31.7-45-80.9 8.9-22.2 9.3-27.3 28.3-69.9C2087.2 4705.5 2093.2 4695.4 2099.2 4664L2099.2 4664z"
      />
    </>
  );
}

Tooth23.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth23;
