import { gql } from '@apollo/client';

export default gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      createdAt
      hexOrderCode
      intOrderId
      labLocationTier
      isRush
      name
      percentComplete
      requiresDesignApproval
      otpFiles {
        stages
        redesignCount
        createdAt
      }
      designUserOrders {
        id
        createdAt
        updatedAt
        redesignCount
        serviceRole
        designUser {
          id
          firstName
          lastName
          role {
            name
          }
        }
      }
      softwareVersion
      state
      customerDesignPreferences
      files
      itemsMetadata
      source
      instructions
      jobCode
      feedbacks {
        id
        userId
        redesignCount
        redesignReason
        rating
        updatedAt
        adminUser {
          id
        }
        answers {
          orderItem {
            id
            designTypeName
            designTypeId
          }
          customReason
          id
          rating
          options {
            id
            name
            category {
              name
              id
            }
          }
        }
      }
      designSilo {
        id
        name
        slug
      }
      location {
        id
        allowNested
        name
        slug
        code
        customInstructions
        subType
        keepExistingCustomInstructions
        fullViewer
        numberingSystem
        updatedAt
        pdfAllMovementPerStage
        doctorApprovalLight
        showVisOutput
        designTypeInstructions {
          createdAt
          instruction
          id
          designType {
            id
            name
          }
        }
      }
      turnaroundTimeId
      turnaroundTime {
        id
        name
      }
      redesignCount
      orderSubscriptions {
        id
        orderId
        orderSubscriptionableId
        orderSubscriptionableType
      }
      orderItems {
        id
        units
        teeth
        arches
        color
        price
        designTypeName
        guideCodes
        nestedArches
        nestedTeeth
        modifiers {
          id
          name
        }
        designType {
          attachmentFiles
          requiresApprovalUpload
          clearAlignerDesignType
          name
          id
          caseFolders {
            id
            name
            slug
            folderName
            description
            mimeTypes
            allowMultiple
          }
          enableSimpleUploader
          feedbackCategories {
            id
            name
            options {
              id
              name
            }
          }
        }
        materialName
        splitFile
        designTypeId
        manufacturerOrganizationId
        manufacturerOrganization {
          id
          name
          isInHouse
        }
      }
    }
  }
`;
