import { gql } from '@apollo/client';

export default gql`
  mutation createPrinterMaterial($input: PrinterMaterialCreateInput!) {
    createPrinterMaterial(input: $input) {
      errorMessages {
        message
      }
      printerMaterial {
        id
        name
        description
        imageUrl
      }
    }
  }
`;
