import { gql } from '@apollo/client';

export default gql`
  query invoice($hexInvoiceNumber: String!) {
    invoice(hexInvoiceNumber: $hexInvoiceNumber) {
      id
      invoiceNumber
      hexInvoiceNumber
      organizationId
      organization {
        id
        name
        address
        phone
        slug
      }
      locationId
      invoiceItems {
        id
        createdAt
        hexOrderCode
        orderCreatedAt
        order {
          intOrderId
        }
        invoiceItemCases {
          id
          unitPrice
          totalUnits
          teeth
          arches
          turnaroundTime
          service
          modifierPrice
        }
      }
      invoiceTransactions {
        id
        message
        status
        authorization
        errorCode
        cardBilled
        paymentAmount
        isCard
        createdAt
      }
      location {
        id
        name
        slug
        name
        address
        phone
        locationBilling
      }
      state
      memo
      datePaid
      invoicingDate
      dueDate
      totalPrice
      totalCredits
      totalDebits
      debits {
        id
        hexDebitNumber
        invoice {
          id
          hexInvoiceNumber
        }
        applied
        reason
        amount
        createdAt
      }
      adjustedTotal
      balanceDue
      appliedPayments
      reconciled
      createdAt
      updatedAt
    }
  }
`;
