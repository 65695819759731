import { gql } from '@apollo/client';
import { DesignTypeCategoryInfo } from '../fragments';

export default gql`
  query designTypeCategories($name: String) {
    designTypeCategories(name: $name) {
      ...DesignTypeCategoryInfo
    }
  }
  ${DesignTypeCategoryInfo}
`;
