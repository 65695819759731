const proceedAndRush = {
  admin_portal: {
    visible: true,
    statuses: {
      on_hold: [0, 10, 11, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      on_hold: [0, 10, 20, 30, 40, 50, 60, 70],
    },
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: true,
    statuses: {
      on_hold: [0, 5, 10, 20],
    },
  },
};

export default proceedAndRush;
