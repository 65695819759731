export const baseStyle = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '250px',
  borderWidth: 2,
  borderColor: '#8e8e8e',
  borderStyle: 'dashed',
  borderRadius: 4,
  cursor: 'pointer',
  padding: '1.0em',
  marginTop: '20px',
};

export const activeStyle = {
  borderColor: '#1B77C8',
  backgroundColor: 'rgba(47, 139, 220, 0.1)',
};

export const rejectedStyle = {
  borderColor: '#1B77C8',
  backgroundColor: 'rgba(47, 139, 220, 0.1)',
};
