import PropTypes from 'prop-types';
import { memo } from 'react';
import OverpanelHeaderRemoveAll from './OverpanelHeaderRemoveAll';
import OverpanelHeaderSubmitAll from './OverpanelHeaderSubmitAll';
import OverpanelHeaderToggleButton from './OverpanelHeaderToggleButton';
import OverpanelHeaderUploadCount from './OverpanelHeaderUploadCount';

function OverpanelHeader(props) {
  const { overpanelOpen } = props;

  return (
    <div
      className="columns mb-3 is-justify-content-space-between"
      style={{ paddingTop: !overpanelOpen ? '0' : '1rem' }}
    >
      <div className="column is-flex is-align-items-center">
        <OverpanelHeaderUploadCount overpanelOpen={overpanelOpen} />
      </div>
      <div className="column is-justify-content-flex-end is-flex is-align-items-center">
        <OverpanelHeaderSubmitAll overpanelOpen={overpanelOpen} />
        <OverpanelHeaderRemoveAll overpanelOpen={overpanelOpen} />
        <OverpanelHeaderToggleButton overpanelOpen={overpanelOpen} {...props} />
      </div>
    </div>
  );
}

OverpanelHeader.propTypes = {
  overpanelOpen: PropTypes.bool.isRequired,
};

export default memo(OverpanelHeader);
