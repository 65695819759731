import { gql } from '@apollo/client';

const DesignLocationInfo = gql`
  fragment DesignLocationInfo on DesignLocation {
    id
    name
    key
    value
    text
    slug
    email
    createdAt
    updatedAt
    address
    geoData
    organizationId
    operatingHours
    designTypeCategoryIds
    logoUrl
    allDayCenter
    organization {
      id
      name
      slug
      createdAt
      updatedAt
    }
  }
`;

export default DesignLocationInfo;
