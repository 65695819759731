/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { OrderDetailsContext } from '../../../../shared';
import OrderShowMessagesCustomer from '../OrderShowMessages/OrderShowMessagesCustomer';
import { orderCodesSplit, orderPreferencesInitialLayout } from './helpers';
import {
  CustomInstructions,
  FeedbackTimeline,
  Modifiers,
  OrderCodes,
  OrderInstructions,
} from './OrderPreferencesNewComponents';
import GridItem from './OrderPreferencesNewComponents/GridItem';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './styles.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const getLSdata = () =>
  JSON.parse(window.localStorage.getItem('layoutOrderPref'));

function OrderShowPreferencesNew(props) {
  const {
    feedbacks,
    orderItems,
    instructions,
    locationId,
    modifiers,
    orderId,
    redesignCount,
    hexOrderCode,
    allowNested,
    ...rest
  } = props;

  const { changeableLayout, setOperationName, operationName } =
    useContext(OrderDetailsContext);

  const app = import.meta.env.VITE_APP_NAME;

  const initialLayout = orderPreferencesInitialLayout(app, modifiers?.length);

  const [layout, setLayout] = useState(getLSdata() || initialLayout);
  const [lsLayout, setLsLayout] = useState(null);

  useEffect(() => {
    if (operationName) {
      // eslint-disable-next-line default-case
      switch (operationName) {
        case 'save':
          window.localStorage.setItem(
            'layoutOrderPref',
            JSON.stringify(lsLayout),
          );
          setLayout(lsLayout);
          break;
        case 'exit':
          setLayout(getLSdata() || initialLayout);
          break;
        case 'reset':
          window.localStorage.setItem('layoutOrderPref', null);
          setLayout(initialLayout);
          break;
      }
    }

    setOperationName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationName]);

  const initialData = {
    order_instructions: {
      title: 'Case instructions',
      component: (
        <OrderInstructions instructions={instructions} orderId={orderId} />
      ),
      isEmpty: !instructions?.length,
    },
    order_specific_codes: {
      title: 'Case specific codes',
      component: (
        <OrderCodes
          codes={orderCodesSplit({ orderItems, isSpecific: true, allowNested })}
        />
      ),
      isEmpty: !orderCodesSplit({ orderItems, isSpecific: true }).length,
    },
    custom_instructions: {
      title: 'Custom instructions',
      component: <CustomInstructions {...rest} orderItems={orderItems} />,
    },
    location_default_codes: {
      title: 'Location Default Codes',
      component: (
        <OrderCodes codes={orderCodesSplit({ orderItems, allowNested })} />
      ),
      isEmpty: !orderCodesSplit({ orderItems }).length,
    },
    feedback_timeline: {
      title: 'Case Feedback',
      component: (
        <FeedbackTimeline
          order={{
            orderItems,
            hexOrderCode,
            feedbacks,
            redesignCount,
            locationId,
          }}
        />
      ),
      isEmpty: !feedbacks?.length,
    },
    order_messages: {
      title: 'Case messages and activity',
      component: (
        <OrderShowMessagesCustomer locationId={locationId} noWrapper showFrom />
      ),
      specialHeight: 16,
    },
  };

  if (modifiers?.length) {
    initialData.order_modifiers = {
      title: 'Case modifiers',
      component: <Modifiers modifiers={modifiers} />,
    };
  }

  const onLayoutChange = (data) => {
    setLsLayout(data);
  };

  return (
    <ResponsiveReactGridLayout
      className="layout"
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      layouts={{
        lg: layout,
      }}
      cols={{ lg: 4, md: 4, sm: 4, xs: 2, xxs: 2 }}
      rowHeight={30}
      draggableHandle=".prefSection"
      isDraggable={changeableLayout}
      isResizable
      onLayoutChange={onLayoutChange}
      margin={[15, 15]}
    >
      {initialLayout.map((item, index) => (
        <GridItem
          setLayout={setLayout}
          operationName={operationName}
          layoutKey={item.i}
          key={item.i}
          changeableLayout={changeableLayout}
          component={initialData[item.i].component}
          title={initialData[item.i].title}
          isEmpty={initialData[item.i].isEmpty}
          specialHeight={initialData[item.i].specialHeight}
          index={index}
        />
      ))}
    </ResponsiveReactGridLayout>
  );
}

export default OrderShowPreferencesNew;
