const searchHandler = (data, value) => {
  const caseInsensitiveValue = value.toLowerCase();

  return data.reduce((arr, item) => {
    if (item.name.toLowerCase().includes(caseInsensitiveValue)) {
      arr.push(item);
      return arr;
    }

    const guideCodes = item.guideCodes.filter(
      (code) =>
        code.guideCategory?.name.toLowerCase().includes(caseInsensitiveValue) ||
        code.description?.toLowerCase().includes(caseInsensitiveValue),
    );

    if (guideCodes.length) {
      const updatedItem = {
        ...item,
        guideCodes,
      };

      arr.push(updatedItem);
      return arr;
    }

    return arr;
  }, []);
};

export default searchHandler;
