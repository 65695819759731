import { useReactiveVar } from '@apollo/client';
import { followUpModalActive } from '../../../../../config/apollo';
import FollowUpModal from './FollowUpModal';

function FollowUpModalWrapper() {
  const followUpModalData = useReactiveVar(followUpModalActive);
  const isModalActive = !!followUpModalData;

  return (
    <FollowUpModal
      followUpModalData={followUpModalData}
      isModalActive={isModalActive}
      followUpModalActive={followUpModalActive}
    />
  );
}

export default FollowUpModalWrapper;
