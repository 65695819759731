function PalmerNumbers() {
  return (
    <>
      <g id="g3949" transform="matrix(1.23 0.0 0 1.2 -60 -50)">
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1264.0132 300.6309)"
        />
        <text
          transform="matrix(1 0 0 1 1264.0132 300.6309)"
          className="st5 st6"
        >
          1
          <path id="path1" d="M0 150L150 150L0 0" />
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1610 300.6309)"
        />
        <text transform="matrix(1 0 0 1 1610 300.6309)" className="st5 st6">
          1
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 940 405.7134)"
        />
        <text transform="matrix(1 0 0 1 940 405.7134)" className="st5 st6">
          2
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 634 589.7134)"
        />
        <text transform="matrix(1 0 0 1 634 589.7134)" className="st5 st6">
          3
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 460 887.7134)"
        />
        <text transform="matrix(1 0 0 1 460 887.7134)" className="st5 st6">
          4
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 348 1183.7134)"
        />
        <text transform="matrix(1 0 0 1 348 1183.7134)" className="st5 st6">
          5
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 238 1485.7134)"
        />
        <text transform="matrix(1 0 0 1 238 1485.7134)" className="st5 st6">
          6
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 180 1815.7134)"
        />
        <text transform="matrix(1 0 0 1 180 1815.7134)" className="st5 st6">
          7
        </text>
        <polyline
          points="-20,30 120,30 120,-120"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 120 2185.7134)"
        />
        <text transform="matrix(1 0 0 1 120 2185.7134)" className="st5 st6">
          8
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1950.6582 405.7134)"
        />
        <text
          transform="matrix(1 0 0 1 1950.6582 405.7134)"
          className="st5 st6"
        >
          2
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2255.5596 589.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2255.5596 589.7134)"
          className="st5 st6"
        >
          3
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2429.999 887.7134)"
        />
        <text transform="matrix(1 0 0 1 2429.999 887.7134)" className="st5 st6">
          4
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2540.7539 1183.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2540.7539 1183.7134)"
          className="st5 st6"
        >
          5
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2651.6328 1485.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2651.6328 1485.7134)"
          className="st5 st6"
        >
          6
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2709.5596 1815.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2709.5596 1815.7134)"
          className="st5 st6"
        >
          7
        </text>
        <polyline
          points="-20,-120 -20,30 120,30"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2769.3398 2185.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2769.3398 2185.7134)"
          className="st5 st6"
        >
          8
        </text>
      </g>
      <g id="g3949" transform="matrix(1.23 0.0 0 1.2 -60 -300)">
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1322.8512 4615.7134)"
        />
        <text
          transform="matrix(1 0 0 1 1322.8512 4615.7134)"
          className="st5 st6"
        >
          1
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1562.8512 4615.7134)"
        />
        <text
          transform="matrix(1 0 0 1 1562.8512 4615.7134)"
          className="st5 st6"
        >
          1
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 100.4933 2772.3159)"
        />
        <text
          transform="matrix(1 0 0 1 100.4933 2772.3159)"
          className="st5 st6"
        >
          8
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1053.0367 4575.7134)"
        />
        <text
          transform="matrix(1 0 0 1 1053.0367 4575.7134)"
          className="st5 st6"
        >
          2
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 1813.0367 4575.7134)"
        />
        <text
          transform="matrix(1 0 0 1 1813.0367 4575.7134)"
          className="st5 st6"
        >
          2
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 752.4874 4415.7134)"
        />
        <text
          transform="matrix(1 0 0 1 752.4874 4415.7134)"
          className="st5 st6"
        >
          3
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2112.4875 4415.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2112.4875 4415.7134)"
          className="st5 st6"
        >
          3
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 532.7072 4216.4458)"
        />
        <text
          transform="matrix(1 0 0 1 532.7072 4216.4458)"
          className="st5 st6"
        >
          4
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2332.707 4215.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2332.707 4215.7134)"
          className="st5 st6"
        >
          4
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 326.0846 3975.7134)"
        />
        <text
          transform="matrix(1 0 0 1 326.0846 3975.7134)"
          className="st5 st6"
        >
          5
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2538.0847 3975.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2538.0847 3975.7134)"
          className="st5 st6"
        >
          5
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 164.524 3603.7134)"
        />
        <text transform="matrix(1 0 0 1 164.524 3603.7134)" className="st5 st6">
          6
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2700.5242 3603.7134)"
        />
        <text
          transform="matrix(1 0 0 1 2700.5242 3603.7134)"
          className="st5 st6"
        >
          6
        </text>
        <polyline
          points="-20,-140 120,-140 120,20"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 102.7078 3144.8794)"
        />
        <text
          transform="matrix(1 0 0 1 102.7078 3144.8794)"
          className="st5 st6"
        >
          7
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2762.2671 3144.147)"
        />
        <text
          transform="matrix(1 0 0 1 2762.2671 3144.147)"
          className="st5 st6"
        >
          7
        </text>
        <polyline
          points="-20,20 -20,-140 120,-140"
          stroke="black"
          strokeWidth="20"
          strokeLinecap="butt"
          fill="none"
          strokeLinejoin="miter"
          transform="matrix(1 0 0 1 2749.833 2772.3159)"
        />
        <text
          transform="matrix(1 0 0 1 2749.833 2772.3159)"
          className="st5 st6"
        >
          8
        </text>
      </g>
    </>
  );
}

export default PalmerNumbers;
