import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideCodeLabLocation(
    $input: GuideCodeLabLocationDestroyInput!
  ) {
    destroyGuideCodeLabLocation(input: $input) {
      clientMutationId
      guideCodeLabLocation {
        default
        guideCodeId
        id
        labLocationId
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
