import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideCategoryUser($input: GuideCategoryUserDestroyInput!) {
    destroyGuideCategoryUser(input: $input) {
      clientMutationId
      guideCategoryUser {
        guideCategoryId
        id
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
