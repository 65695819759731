import {
  OverpanelFilesContext,
  OverpanelSubmitContext,
} from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import TempOrderForm from '../TempOrderForm/TempOrderForm';

function TempOrderCaseItem(props) {
  const { index, customerLocation, tempOrder, tempOrders, ...rest } = props;
  const { toggleOverpanel } = useContext(OverpanelFilesContext);
  const { removeSubmitAll } = useContext(OverpanelSubmitContext);

  const [singleCase, setSingleCase] = useState(false);

  return (
    <TempOrderForm
      fileIndex={index}
      tempOrder={tempOrder}
      singleCase={singleCase}
      ordersCount={tempOrders.length}
      setSingleCase={() => setSingleCase(true)}
      customerLocation={customerLocation}
      toggleOverpanel={toggleOverpanel}
      removeSubmitAll={removeSubmitAll}
      tempOrders={tempOrders}
      {...rest}
    />
  );
}

TempOrderCaseItem.propTypes = {
  index: PropTypes.number.isRequired,
  tempOrder: PropTypes.object.isRequired,
  customerLocation: PropTypes.object.isRequired,
};

export default TempOrderCaseItem;
