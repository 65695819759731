import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { statusColor } from '../../../../transforms';

function orderStatusRenderer({ value, data }) {
  const { t } = useTranslation('navigation');
  const [color, setColor] = useState('');

  useEffect(() => {
    setColor(statusColor(value, data.isRush));
  }, [value, data.isRush]);

  return (
    <div style={{ paddingTop: 4 }}>
      <h6
        className={`title is-6 has-text-${color} is-capitalized`}
        style={{
          lineHeight: 'initial',
          fontWeight: '700',
          fontSize: '0.85rem',
          marginBottom: 4,
        }}
      >
        {t(value.replace(/_/gi, ' '))}
      </h6>
      <progress
        className={`progress is-${color} is-small`}
        value={data.percentComplete}
        max="100"
      />
    </div>
  );
}

orderStatusRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  data: PropTypes.shape({
    isRush: PropTypes.bool,
    percentComplete: PropTypes.number,
  }).isRequired,
};

export default orderStatusRenderer;
