import { gql } from '@apollo/client';

export default gql`
  query printerBrands {
    printerBrands {
      id
      name
      description
      imageUrl
      slug
      value
    }
  }
`;
