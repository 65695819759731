import PropTypes from 'prop-types';

function BoxIcon({
  name,
  style = {},
  iconStyle = {},
  className = '',
  ...rest
}) {
  return (
    <span className={`icon ${className}`} style={style} {...rest}>
      <i className={`bx ${name}`} style={iconStyle} />
    </span>
  );
}

BoxIcon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
};

export default BoxIcon;
