import { gql } from '@apollo/client';
import { ErrorMessageInfo, PriceListInfo } from '../fragments';

export default gql`
  mutation updatePriceList($input: PriceListUpdateInput!) {
    updatePriceList(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      priceList {
        ...PriceListInfo
      }
    }
  }
  ${PriceListInfo}
  ${ErrorMessageInfo}
`;
