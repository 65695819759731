import { useApolloClient, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';

function OrganizationDropdown({
  form: { errors, touched, setFieldValue },
  field: { name, value },
  orgQuery,
  type,
  orgFullQuery = null,
  disabled = false,
  isRestricted = false,
  setSelectedOrg = null,
  selectedRole = null,
  placeholder = 'Please Select...',
}) {
  const client = useApolloClient();
  const { t } = useTranslation('formfields');
  const [orgs, setOrgs] = useState(null);

  const {
    error,
    loading,
    data: orgData,
  } = useQuery(orgQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (orgData) {
      const orgsArray = orgData[`${type}Organizations`];
      const sortedData = [
        ...(isRestricted && selectedRole?.name.includes('Organization')
          ? orgsArray.filter((item) => !item.restricted)
          : orgsArray),
      ];
      setOrgs(
        sortedData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        ),
      );
    }
  }, [selectedRole, orgData, isRestricted, type]);

  async function fullOrg(id) {
    const { data } = await client.query({
      query: orgFullQuery,
      skip: !orgFullQuery,
      fetchPolicy: 'network-only',
      variables: { id },
    });
    if (data) {
      if (setSelectedOrg) {
        setSelectedOrg(data[`${type}Organization`]);
      }
      setFieldValue(name, id);
    }
  }

  const handleChange = (e) => {
    if (orgFullQuery) {
      fullOrg(e.target.value);
    } else {
      if (setSelectedOrg) {
        setSelectedOrg(orgs.find((o) => o.id === e.target.value));
      }

      setFieldValue(name, e.target.value);
    }
  };

  return !error ? (
    <Dropdown
      name={name}
      label={`Parent ${type} ${type === 'design' ? 'Center' : 'Organization'}`}
      error={error}
      errors={!!(errors[name] && touched[name])}
      loading={loading}
      options={loading ? [] : orgs || null}
      onChange={handleChange}
      placeholder={t(placeholder)}
      value={value}
      required
      disabled={disabled || loading}
    />
  ) : (
    <p>Error...</p>
  );
}

OrganizationDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  orgQuery: PropTypes.object.isRequired,
  orgFullQuery: PropTypes.object,
  selectedRole: PropTypes.object,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isRestricted: PropTypes.bool,
  setSelectedOrg: PropTypes.func,
};

export default memo(OrganizationDropdown);
