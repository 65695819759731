import PropTypes from 'prop-types';
import { Message } from '../../../../../../../shared';

function ElementImage({ selectedFileError = null }) {
  return (
    <Message type="danger" title="File Error, Try Again Later">
      <p>{selectedFileError}</p>
    </Message>
  );
}

ElementImage.propTypes = {
  selectedFileError: PropTypes.string,
};
export default ElementImage;
