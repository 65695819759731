import { gql } from '@apollo/client';
import { OrderHoldReasonInfo } from '../fragments';

export default gql`
  query orderHoldReason($slug: String!) {
    orderHoldReason(slug: $slug) {
      ...OrderHoldReasonInfo
    }
  }
  ${OrderHoldReasonInfo}
`;
