import { gql } from '@apollo/client';

export default gql`
  query customerLocationsDropdown(
    $customerOrganizationIds: [ID!]
    $organizationId: ID
  ) {
    customerLocations(
      customerOrganizationIds: $customerOrganizationIds
      organizationId: $organizationId
    ) {
      id
      name
      value
    }
  }
`;
