const sendForApproval = {
  admin_portal: {
    visible: true,
    statuses: {
      needs_approval: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      needs_approval: [0, 10, 20, 30, 40, 50],
    },
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: false,
  },
};

export default sendForApproval;
