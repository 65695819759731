import { gql } from '@apollo/client';

const ReportTypeInfo = gql`
  fragment ReportTypeInfo on ReportType {
    id
    name
    inputParams
    singleDate
    # key
    value
    # text
  }
`;

export default ReportTypeInfo;
