import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createOrderSubscription($input: OrderSubscriptionCreateInput!) {
    createOrderSubscription(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        id
        createdAt
        hexOrderCode
        intOrderId
        isRush
        name
        percentComplete
        requiresDesignApproval
        softwareVersion
        state
        customerDesignPreferences
        files
        instructions
        designSilo {
          id
          name
          slug
        }
        location {
          id
          name
          slug
          code
          customInstructions
          subType
        }
        turnaroundTimeId
        turnaroundTime {
          id
          name
        }
        redesignCount
        orderSubscriptions {
          id
          orderId
          orderSubscriptionableId
          orderSubscriptionableType
        }
        orderItems {
          id
          units
          teeth
          arches
          color
          price
          designTypeName
          designType {
            attachmentFiles
            requiresApprovalUpload
          }
          materialName
          splitFile
          designTypeId
          manufacturerOrganizationId
          manufacturerOrganization {
            id
            name
            isInHouse
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
