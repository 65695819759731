import { gql } from '@apollo/client';
import DesignPreferenceInfo from './DesignPreferenceInfo';
import DesignTypeInfo from './DesignTypeInfo';

const DesignPreferenceProfileInfo = gql`
  fragment DesignPreferenceProfileInfo on DesignPreferenceProfile {
    id
    name
    slug
    createdAt
    updatedAt
    designTypeIds
    designPreferences {
      ...DesignPreferenceInfo
    }
    designTypes {
      ...DesignTypeInfo
    }
  }
  ${DesignPreferenceInfo}
  ${DesignTypeInfo}
`;

export default DesignPreferenceProfileInfo;
