import PropTypes from 'prop-types';

function tagRenderer({ value = [], tagClassName = '' }) {
  if (!value) {
    return null;
  }

  const tags = Array.isArray(value) ? value : [value];

  const isObjectArray =
    tags.length > 0 && typeof tags[0] === 'object' && tags[0] !== null;

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const getLabelClass = (tagValue) => {
    if (
      tagValue === 'pending' ||
      tagValue === 'past_due' ||
      tagValue === 'Child'
    ) {
      return 'tag is-warning';
    }
    if (
      tagValue === 'active' ||
      tagValue === 'Active' ||
      tagValue === 'paid' ||
      tagValue === 'Master'
    ) {
      return 'tag is-success';
    }
    if (
      tagValue === 'inactive' ||
      tagValue === 'Inactive' ||
      tagValue === 'Billing Inactive' ||
      tagValue === 'Suspended' ||
      tagValue === 'error' ||
      tagValue === 'unpaid'
    ) {
      return 'tag is-danger';
    }
    return `tag ${tagClassName}`;
  };

  return (
    <span
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {tags.map((tag, index) => (
        <div
          key={isObjectArray ? tag.id : `${tag}-${index}`}
          className={getLabelClass(isObjectArray ? tag.name : tag)}
          style={{
            margin: '2px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {isObjectArray
            ? capitalizeFirstLetter(tag.name)
            : capitalizeFirstLetter(tag)}
        </div>
      ))}
    </span>
  );
}

tagRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        PropTypes.string,
      ]),
    ),
    PropTypes.string,
  ]),
  tagClassName: PropTypes.string,
};

export default tagRenderer;
