/* eslint-disable react/prop-types */
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PreferenceContainer from './PreferenceContainer';

function ProfileSegment(props) {
  const { profile, rowLabel, foundIndex, designProfileId } = props;

  const { t } = useTranslation('designProfile');

  return (
    <FieldArray
      name={`designProfiles[${foundIndex}].designPreferences`}
      render={(arrayHelpers) => (
        <div className="box">
          <div className="columns">
            <div className="column">
              <h5 className="title is-5">{profile.name}</h5>
            </div>
            <div className="column has-text-right">
              <div className="tag is-success is-medium">{t(rowLabel)}</div>
            </div>
          </div>
          {profile.designPreferences.map((preference) => (
            <PreferenceContainer
              key={preference.id}
              labelText={preference.name}
              designProfileId={designProfileId}
              foundIndex={foundIndex}
              preference={preference}
              options={preference.values}
              arrayHelpers={arrayHelpers}
              {...props}
            />
          ))}
        </div>
      )}
    />
  );
}

ProfileSegment.propTypes = {
  profile: PropTypes.object.isRequired,
  rowLabel: PropTypes.string.isRequired,
  foundIndex: PropTypes.number.isRequired,
};

export default ProfileSegment;
