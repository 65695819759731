import { gql } from '@apollo/client';

export default gql`
  query priceListsDropdownWithParent($onlyMaster: Boolean) {
    priceLists(onlyMaster: $onlyMaster) {
      id
      name
      value
      parentListId
    }
  }
`;
