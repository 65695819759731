function initialValues(props) {
  const {
    match: {
      params: { slug },
    },
    data: { customerLocation = {} } = {},
    currentUser,
  } = props;

  const app = import.meta.env.VITE_APP_NAME;
  let customerSlug;

  if (app === 'customer_portal' && currentUser.scopeToLocation) {
    customerSlug = customerLocation.slug;
  } else {
    customerSlug = slug;
  }

  return {
    fileUrl: '',
    filename: '',
    s3Key: '',
    customerSlug,
  };
}

export default initialValues;
