import PropTypes from 'prop-types';
import { useState } from 'react';

import FeedbackBody from './FeedbackBody';
import FeedbackRedesignReason from './FeedbackRedesignReason';

import { createFeedback } from './helpers';

function FeedbackRedesignModal(props) {
  const { data, modalOpen, setModalOpen } = props;
  const [redesignActive, setRedesignActive] = useState(true);
  const [message, setMessage] = useState(null);

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ width: 400 }}>
        <header className="modal-card-head">
          <p className="modal-card-title">Redesign</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => setModalOpen(false)}
          />
        </header>
        <div className="modal-card-body px-5 pt-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <FeedbackBody
            {...data}
            bodyShow
            bodyOnly
            redesign
            setRedesignActive={setRedesignActive}
          />
          <FeedbackRedesignReason setMessage={setMessage} message={message} />
        </div>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            type="button"
            className="button is-dark"
            onClick={() => setModalOpen(false)}
          >
            Close
          </button>
          <button
            type="button"
            className={`button is-primary ${data.loading ? 'is-loading' : ''}`}
            onClick={() =>
              createFeedback({
                selectedOptions: data.selectedOptions,
                orderId: data.orderId,
                createFeedbackMutation: data.createFeedbackMutation,
                doRedesign: true,
                feedbackId: data.currentFeedback?.id,
                message,
                closeModal: () => setModalOpen(false),
              })
            }
            disabled={!redesignActive || data.loading}
          >
            Redesign
          </button>
        </footer>
      </div>
    </div>
  );
}

FeedbackRedesignModal.propTypes = {
  data: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};

export default FeedbackRedesignModal;
