import PropTypes from 'prop-types';
import './Placeholder.scss';

function Placeholder({ width = '100%', height = '10px', className = '' }) {
  return (
    <div
      className={`animated-background-placeholder ${className}`}
      style={{ width, height }}
    />
  );
}

Placeholder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default Placeholder;
