import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeCrowding(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Crowding and Spacing">
      <div className="checkWrap">
        <NonFormCheckboxField
          name="addressCrowding"
          labelText="Address Crowding"
          readOnly
          checked={prescription.SpaceCorrection.AddressCrowding}
        />
      </div>
      <div className="checkWrap">
        <NonFormCheckboxField
          name="closeSpacing"
          labelText="Close Spacing"
          readOnly
          checked={prescription.SpaceCorrection.CloseSpacing}
        />
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeCrowding.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeCrowding;
