import { gql } from '@apollo/client';
import { DesignOrganizationInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateDesignOrganization($input: DesignOrganizationUpdateInput!) {
    updateDesignOrganization(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designOrganization {
        ...DesignOrganizationInfo
      }
    }
  }
  ${DesignOrganizationInfo}
  ${ErrorMessageInfo}
`;
