import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Importing all components used by the GuideCodeCard component
import { classesGenerator } from '@fullcontour/common';
import {
  ButtonDefaults,
  ButtonFavourites,
  ButtonInformation,
  ButtonNestedSelection,
} from './components';

import { GuideCodeCardProvider } from './context/GuideCodeCardContext';

import GuideCodeCardActionButtons from './GuideCodeCardActionButtons';
import GuideCodeCardDropdown from './GuideCodeCardDropdown';
import GuideCodeCardFooter from './GuideCodeCardFooter';
import GuideCodeCardImage from './GuideCodeCardImage';
import GuideCodeCardNestedLabels from './GuideCodeCardNestedLabels';
import GuideCodeCardOtherInput from './GuideCodeCardOtherInput';

import './styles/GuideCodeCard.scss';

function GuideCodeCard({
  data,
  image = null,
  actionButtons = null,
  cardDropdown = null,
  nestedLabels = null,
  footer = null,
  isSelected = false,
  guideCode = {},
  handleChange = () => {},
}) {
  // Destructuring the props object
  const { category } = data;

  // Generating the classes for the component using the classesGenerator function
  const cardClasses = useMemo(
    () =>
      classesGenerator({
        card: true,
        'is-flex': true,
        'guide-card': true,
        'is-flex-direction-column': true,
        'is-active-code': isSelected,
        'card__big-card': !!category.showDropdown,
      }),
    // Only recompute the classes when the selectedGuideCode or category.showDropdown values change
    [isSelected, category.showDropdown],
  );

  return (
    // Providing the GuideCodeCardProvider with the data prop value
    <GuideCodeCardProvider value={{ data }}>
      {/* The entire component is wrapped in a div with the generated classes and an onClick function */}
      <div
        className={cardClasses}
        onClick={() => handleChange({ data, guideCode })}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleChange({ data, guideCode });
          }
        }}
        role="button"
        tabIndex="0"
      >
        {/* Rendering all the subcomponents of the GuideCodeCard component */}
        {image}
        {actionButtons}
        {cardDropdown}
        {nestedLabels}
        {footer}
      </div>
    </GuideCodeCardProvider>
  );
}

// Defining the propTypes and defaultProps for the GuideCodeCard component
GuideCodeCard.propTypes = {
  image: PropTypes.node,
  data: PropTypes.object.isRequired,
  actionButtons: PropTypes.node,
  cardDropdown: PropTypes.node,
  nestedLabels: PropTypes.node,
  footer: PropTypes.node,
  handleChange: PropTypes.func,
  guideCode: PropTypes.object,
  isSelected: PropTypes.bool,
};

// Exporting all the subcomponents of the GuideCodeCard component
GuideCodeCard.Image = GuideCodeCardImage;
GuideCodeCard.ActionButtons = GuideCodeCardActionButtons;
GuideCodeCard.ButtonNestedSelection = ButtonNestedSelection;
GuideCodeCard.ButtonFavourites = ButtonFavourites;
GuideCodeCard.ButtonInformation = ButtonInformation;
GuideCodeCard.ButtonDefaults = ButtonDefaults;
GuideCodeCard.ActionButtons = GuideCodeCardActionButtons;
GuideCodeCard.Dropdown = GuideCodeCardDropdown;
GuideCodeCard.NestedLabels = GuideCodeCardNestedLabels;
GuideCodeCard.CardFooter = GuideCodeCardFooter;
GuideCodeCard.OtherInput = GuideCodeCardOtherInput;

// Exporting the GuideCodeCard component as the default export
export default GuideCodeCard;
