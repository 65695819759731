import { gql } from '@apollo/client';

export default gql`
  query businessUnitsDropdown($name: String) {
    businessUnits(name: $name) {
      value
      label: name
    }
  }
`;
