import { gql } from '@apollo/client';

const ReportInfo = gql`
  fragment ReportInfo on Report {
    id
    name
    createdAt
    updatedAt
    reportType {
      id
      name
    }
    reportTimeFrame {
      id
      name
    }
  }
`;

export default ReportInfo;
