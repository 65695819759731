import PropTypes from 'prop-types';

function Modifiers({ modifiers }) {
  return <div>{modifiers.join(', ')}</div>;
}

Modifiers.propTypes = {
  modifiers: PropTypes.array.isRequired,
};

export default Modifiers;
