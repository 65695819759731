const updateOrderCache = ({ cache, data, isfeedbackToShow, order }) => {
  const currentFeedback =
    data[
      isfeedbackToShow ? 'updateOrderCurrentFeedback' : 'createOrderFeedback'
    ];

  const updatedOrder = {
    ...order,
    feedbacks: isfeedbackToShow
      ? order.feedbacks.map((item) => {
          if (item.id === currentFeedback.id) {
            return currentFeedback;
          }

          return item;
        })
      : [currentFeedback],
  };

  cache.modify({
    fields: {
      order() {
        return updatedOrder;
      },
    },
    broadcast: false,
  });
};

export default updateOrderCache;
