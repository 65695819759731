import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormErrors } from '../FieldContainer';

import './styles/SelectField.scss';

const animatedComponents = makeAnimated();

function SelectField({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  label = null,
  optionsFormatCallback = (key) => key,
  query = null,
  isMulti = false,
  selectAll = false,
  disabled = false,
  initialMenu = false,
  options = [],
  variables = {},
  styles = {},
}) {
  const [selectOptions, setSelectOptions] = useState(options);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const client = useApolloClient();

  useEffect(() => {
    if (query) {
      const executeQuery = async () => {
        setLoading(true);
        try {
          const { data } = await client.query({
            query,
            variables,
          });

          setSelectOptions(optionsFormatCallback(Object.values(data)[0]));
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };
      executeQuery();
    }
  }, [query]);

  const handleChange = (selectedOption) => {
    if (selectAll && selectedOption.at(-1)?.value === 'selectAll') {
      return setFieldValue(
        name,
        selectOptions.map((item) => item.value),
      );
    }

    return setFieldValue(
      name,
      isMulti ? selectedOption.map((item) => item.value) : selectedOption.value,
    );
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const getOptionLabel = (option) =>
    option.value === 'selectAll' ? (
      <span style={{ fontWeight: 'bold' }}>{option.label}</span>
    ) : (
      option.label
    );

  const selectValue = useMemo(
    () =>
      isMulti
        ? selectOptions.filter((option) => value?.includes(option.value))
        : selectOptions.find((item) => item.value === value),
    [isMulti, selectOptions, value],
  );

  const selectAllOptionsOption = {
    value: 'selectAll',
    label: 'Select All',
  };

  const expandedOptions =
    selectAll && selectOptions.length >= 2
      ? [selectAllOptionsOption, ...selectOptions]
      : selectOptions;

  if (error) return <p>Error fetching options!</p>;

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Select
        components={animatedComponents}
        className="react-select-container"
        classNamePrefix={initialMenu ? 'react-select' : undefined}
        getOptionLabel={getOptionLabel}
        closeMenuOnSelect={!isMulti}
        id={name}
        isDisabled={disabled}
        options={expandedOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        isLoading={loading}
        value={selectValue}
        isMulti={isMulti}
        styles={styles}
      />
      <FormErrors name={name} />
    </div>
  );
}

SelectField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.any),
    ]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  optionsFormatCallback: PropTypes.func,
  options: PropTypes.array,
  query: PropTypes.object,
  variables: PropTypes.object,
  isMulti: PropTypes.bool,
  selectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  initialMenu: PropTypes.bool,
  styles: PropTypes.object,
};

export default SelectField;
