import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import Dropdown from './Dropdown';

function DynamicDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  dynamicQuery,
  dataName,
  placeholder = 'Select...',
  labelText = null,
  variables = {},
  disabled = false,
  hideLabel = false,
  style = {},
  search = false,
  required = false,
  selectionFullData = null,
}) {
  const [remoteData, setRemoteData] = useState([]);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    if (selectionFullData) {
      selectionFullData(remoteData.find((d) => d.id === e.target.value));
    }
  };

  const { error, loading } = useQuery(dynamicQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setRemoteData(
        [...data[dataName]].sort((a, b) => a.name.localeCompare(b.name)),
      );
    },
  });

  return (
    <Dropdown
      name={name}
      label={labelText || name}
      hideLabel={hideLabel}
      error={error}
      containerStyle={style}
      errors={!!(errors[name] && touched[name])}
      loading={loading}
      options={remoteData}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      search={search}
      required={required}
    />
  );
}

DynamicDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  dynamicQuery: PropTypes.object.isRequired,
  dataName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  variables: PropTypes.object,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  style: PropTypes.object,
  search: PropTypes.bool,
  required: PropTypes.bool,
  selectionFullData: PropTypes.func,
};

export default memo(DynamicDropdown);
