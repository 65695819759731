import { XMLParser } from 'fast-xml-parser';
import JSZip from 'jszip';
import BaseDefinition from './BaseDefinition';

class FileTypeZip extends BaseDefinition {
  constructor(file, designUserId, order, fileType) {
    super(file, designUserId, order, fileType);
    this.order = order;
    this.jsonObj = null;
    this.unzippedFiles = null;
    this.mappedZipFiles = [];
  }

  buildCase = async () => {
    if (this.parsedCase.order === null) {
      return this.parsedCase;
    }

    this.unzippedFiles = await this.unzip();
    const zipType = await this.determineZipType();

    return new BaseDefinition(
      this.file,
      this.parsedCase.designUserId,
      this.order,
      zipType,
    ).buildCase({
      unzippedFiles: this.unzippedFiles,
      jsonObj: this.jsonObj,
      mappedZipFiles: this.mappedZipFiles,
    });
  };

  determineZipType = async () => {
    if (await this.finishedPayload()) {
      return 'designer_finished_package';
    }

    if (await this.containsXML(this.mappedZipFiles)) {
      return 'finished_scans';
    }

    return 'manufacturer_scans';
  };

  finishedPayload = async () => this.file.name.startsWith('fcf_');

  containsXML = async (fileNames, extension = '.xml') => {
    const { order } = this.parsedCase;

    const fileExists = await fileNames.some(
      (fileName) =>
        fileName === `${order.intOrderId}/${order.intOrderId}${extension}`,
    );

    if (fileExists && extension === '.xml') {
      return this.checkFor3ShapeXML();
    }

    return fileExists;
  };

  checkFor3ShapeXML = async () => {
    const { order } = this.parsedCase;

    const parser = new XMLParser({
      attributeNamePrefix: '',
      attributesGroupName: false,
      ignoreAttributes: false,
      removeNSPrefix: true,
      allowBooleanAttributes: false,
      parseTagValue: false,
      parseAttributeValue: true,
      trimValues: true,
      tagValueProcessor: (b) => b.toLowerCase(),
    });

    this.jsonObj = await this.unzippedFiles
      .file(`${order.intOrderId}/${order.intOrderId}.xml`)
      .async('text')
      .then((xml) => parser.parse(xml));

    return 'DentalContainer' in this.jsonObj;
  };

  unzip = async () =>
    JSZip.loadAsync(this.file).then((zip) => {
      Object.values(zip.files).forEach((value) =>
        this.mappedZipFiles.push(value.name),
      );
      return zip;
    });
}

export default FileTypeZip;
