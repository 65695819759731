import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createSignedS3Link($input: SignedS3LinkCreateInput!) {
    createSignedS3Link(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      signedS3Link {
        url
      }
    }
  }
  ${ErrorMessageInfo}
`;
