import { gql } from '@apollo/client';
import { DebitInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateDebit($input: DebitUpdateInput!) {
    updateDebit(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      debit {
        ...DebitInfo
      }
    }
  }
  ${DebitInfo}
  ${ErrorMessageInfo}
`;
