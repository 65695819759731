import { gql } from '@apollo/client';

const ViewerLinkInfo = gql`
  fragment ViewerLinkInfo on ViewerLink {
    id
    link
    linkHash
    orderId
    accessToken
  }
`;

export default ViewerLinkInfo;
