import { gql } from '@apollo/client';
import { CustomerUserInfo } from '../fragments';

export default gql`
  query customerUsers($locationId: String, $organizationId: String) {
    customerUsers(locationId: $locationId, organizationId: $organizationId) {
      ...CustomerUserInfo
    }
  }
  ${CustomerUserInfo}
`;
