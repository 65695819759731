import { gql } from '@apollo/client';

export default gql`
  query orderHoldReasons($orderId: ID) {
    orderHoldReasons(orderId: $orderId) {
      id
      name
      value
      requiresCustomMessage
    }
  }
`;
