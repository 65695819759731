import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { checkPermissions, resendFtp } from '../helpers';
import ResendModal from './ResendModal';

function ContainerActionResendFtp({
  order = {},
  actionTitle,
  messageTitle,
  ...rest
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [labLocationId, setLabLocationId] = useState(null);
  const [manufacturerIds, setManufacturerIds] = useState([]);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const props = { order, actionTitle, messageTitle, ...rest };
  const client = useApolloClient();

  const closeModal = () => {
    setModalOpen(false);
    setSuccess(false);
    setFailure(false);
  };

  const openModal = () => {
    setModalOpen(true);
    setSuccess(false);
    setFailure(false);
  };

  const resendToFtp = () => {
    resendFtp(
      client,
      labLocationId,
      manufacturerIds,
      order.id,
      setSuccess,
      setFailure,
    );
  };

  const updateEntities = (checked, entityid, iscustomer) => {
    if (checked && iscustomer) {
      setLabLocationId(entityid);
    }

    if (!checked && iscustomer) {
      setLabLocationId(null);
    }

    if (checked && !iscustomer) {
      setManufacturerIds([entityid, ...manufacturerIds]);
    }

    if (!checked && !iscustomer) {
      const updatedIds = manufacturerIds.filter((id) => id !== entityid);
      setManufacturerIds(updatedIds);
    }
  };

  return checkPermissions(props) ? (
    <ResendModal
      closeModal={closeModal}
      modalOpen={modalOpen}
      openModal={openModal}
      resendToFtp={resendToFtp}
      updateEntities={updateEntities}
      success={success}
      failure={failure}
      order={order}
      actionTitle={actionTitle}
      messageTitle={messageTitle}
    />
  ) : null;
}

ContainerActionResendFtp.propTypes = {
  order: PropTypes.object,
  actionTitle: PropTypes.string.isRequired,
  messageTitle: PropTypes.string.isRequired,
};

export default ContainerActionResendFtp;
