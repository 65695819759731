/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationGuidInfoVideo from './LocationGuidInfoVideo';

function LocationDesignGuideInfo() {
  const [mimized, setMinimized] = useState(true);

  const { t } = useTranslation('designProfile');

  return (
    <div className="is-flex is-justify-content-space-between">
      <div>
        <div className="mb-5 design-guide">
          <p className="column is-6">
            <div className="is-flex is-justify-content-space-between is-align-items-center">
              <h6 className="title is-6 mb-4">
                {t('What are Design Guide Codes?')}
              </h6>
            </div>
            {t(
              'Please use this section to specify details and instructions related to your case designs based on the clickable images.',
            )}
          </p>
        </div>
        {!mimized && (
          <LocationGuidInfoVideo
            mimized={mimized}
            setMinimized={setMinimized}
          />
        )}
        <p className="column is-6">
          {t(
            'See the codes that we use for each design type. Select as defaults the ones that indicate your design preferences.',
          )}
        </p>
      </div>
      {mimized && (
        <LocationGuidInfoVideo mimized={mimized} setMinimized={setMinimized} />
      )}
    </div>
  );
}

LocationDesignGuideInfo.propTypes = {};

export default LocationDesignGuideInfo;
