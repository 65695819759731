import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../transforms';

function InvoiceStatusMessage({
  invoice,
  toDownload = false,
  messageRightContent = null,
}) {
  let color = '';
  let messageContent = '';

  if (invoice.state === 'unpaid') {
    color = 'is-danger';
    messageContent = `Invoice due on:  ${timestampFormatter(
      invoice.dueDate,
      false,
    )}`;
  }

  if (invoice.state === 'paid') {
    color = 'is-success';
    messageContent = `Invoice paid in full on:  ${timestampFormatter(
      invoice.datePaid,
      false,
    )}`;
  }

  if (invoice.state === 'past_due') {
    color = 'is-warning';
    messageContent = `Invoice was due on:  ${timestampFormatter(
      invoice.dueDate,
      false,
    )}`;
  }

  if (invoice.state === 'error') {
    color = 'is-danger';
    messageContent = 'There was an error processing this invoice.';
  }

  if (invoice.state === 'voided') {
    color = 'is-dark';
    messageContent = 'This Invoice was voided';
  }

  return (
    <article
      className={`message py-0 ${color}`}
      style={{
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
        ...(toDownload
          ? { boxShadow: 'none', width: '175mm', marginTop: '1.5rem' }
          : {}),
      }}
    >
      <div
        className={`message-body px-5 py-2 ${
          toDownload && 'is-flex is-justify-content-space-between'
        }`}
      >
        <div>
          <p
            className="is-size-6 has-text-weight-bold is-capitalized"
            style={{
              ...(toDownload ? { width: '100mm' } : {}),
            }}
          >
            {invoice.state.replace(/_/g, ' ')}
          </p>
          <p
            className="is-size-7"
            style={{ ...(toDownload ? { width: '100mm' } : {}) }}
          >
            {messageContent}
          </p>
        </div>
        {toDownload && messageRightContent && (
          <p className="is-flex" style={{ width: '100mm' }}>
            {messageRightContent}
          </p>
        )}
      </div>
    </article>
  );
}

InvoiceStatusMessage.propTypes = {
  invoice: PropTypes.object.isRequired,
  toDownload: PropTypes.bool,
  messageRightContent: PropTypes.node,
};

export default InvoiceStatusMessage;
