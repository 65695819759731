import PropTypes from 'prop-types';
import { useState } from 'react';
import { GuideCodeCard } from '../../../../shared';

function LocationDesignFavoritesGuideCode(props) {
  const { guideCategory, item, group } = props;

  const [currentGuideCode, setCurrentGuideCode] = useState(
    !guideCategory.showDropdown ? guideCategory.guideCodes[0] : {},
  );

  const handleChange = ({ data, value }) => {
    if (data.category.showDropdown && value) {
      setCurrentGuideCode(
        data.category.guideCodes.find((code) => code.id === value),
      );
    }
  };

  const guideCode = guideCategory.guideCodes.find(
    (guideCodeItem) => guideCodeItem.id === currentGuideCode?.id,
  );

  return (
    <GuideCodeCard
      image={<GuideCodeCard.Image />}
      actionButtons={
        <GuideCodeCard.ActionButtons>
          <GuideCodeCard.ButtonFavourites />
          <GuideCodeCard.ButtonInformation />
          <GuideCodeCard.ButtonDefaults />
        </GuideCodeCard.ActionButtons>
      }
      cardDropdown={
        <GuideCodeCard.Dropdown noOther handleDropdown={handleChange} />
      }
      footer={<GuideCodeCard.CardFooter />}
      data={{
        ...guideCode,
        default: guideCode?.default,
        guideCategoryGroupId: item.id,
        category: guideCategory,
        designType: {
          designTypeName: group.designTypeName,
          designTypeId: group.designTypeId,
        },
      }}
    />
  );
}

LocationDesignFavoritesGuideCode.propTypes = {
  group: PropTypes.shape({
    designTypeId: PropTypes.string,
    designTypeName: PropTypes.string,
  }).isRequired,
  guideCategory: PropTypes.shape({
    guideCodes: PropTypes.array,
    id: PropTypes.string,
    showDropdown: PropTypes.bool,
    imageUrl: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default LocationDesignFavoritesGuideCode;
