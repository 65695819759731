import { gql } from '@apollo/client';

export default gql`
  query adminUsersAdmin {
    adminUsersAdmin {
      id
      attributes
    }
  }
`;
