import { getIn } from 'formik';

function testForErrors(errors, touched, name) {
  const splitName = name.split('.');

  if (splitName.length === 1) {
    return errors[name] && touched[name];
  }

  return getIn(errors, name) && getIn(touched, name);
}

export default testForErrors;
