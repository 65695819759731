import { gql } from '@apollo/client';

export default gql`
  query roles($modelScope: String!, $scopeToLocation: Boolean) {
    roles(modelScope: $modelScope, scopeToLocation: $scopeToLocation) {
      id
      name
      symbol
      level
      modelScope
      scopeToLocation
      key
      value
      text
      assignmentOrganizations
      assignmentLocations
      assignmentDesignTypes
    }
  }
`;
