import { memo } from 'react';
import Proptypes from 'prop-types';

function CheckBox(props) {
  const { field } = props;

  return <input {...field} type="checkbox" />;
}
CheckBox.propTypes = {
  field: Proptypes.object.isRequired,
};
export default memo(CheckBox);
