import { fullAddress } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Styles from './ShowHeaderLeftContent.module.css';

function ShowHeaderAddress(props) {
  const { address } = props;
  return address && address.address && address.address !== '' ? (
    <p style={{ marginBottom: '0.5em' }}>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
          fullAddress(address),
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className={Styles.showHeaderLink}
      >
        {fullAddress(address)}
      </a>
    </p>
  ) : null;
}

ShowHeaderAddress.propTypes = {
  address: PropTypes.object.isRequired,
};

export default memo(ShowHeaderAddress);
