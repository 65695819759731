const orderTypesHandler = (orderSingle) =>
  orderSingle?.orderItems?.some((item) =>
    item.designType.name.toLowerCase().includes('surgery'),
  ) &&
  ![
    'ready_for_download',
    'complete',
    'closed',
    'sent_to_manufacturer',
    'at_manufacturer',
    'shipped',
  ].includes(orderSingle?.state);

export default orderTypesHandler;
