import { gql } from '@apollo/client';
import { SoftwareNodeInfo } from '../fragments';

export default gql`
  query softwareNode($slug: String, $id: ID) {
    softwareNode(slug: $slug, id: $id) {
      ...SoftwareNodeInfo
    }
  }
  ${SoftwareNodeInfo}
`;
