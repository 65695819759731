import { gql } from '@apollo/client';
import { ErrorMessageInfo, InternalRejectionReasonInfo } from '../fragments';

export default gql`
  mutation createInternalRejectionReason(
    $input: InternalRejectionReasonCreateInput!
  ) {
    createInternalRejectionReason(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      internalRejectionReason {
        ...InternalRejectionReasonInfo
      }
    }
  }
  ${InternalRejectionReasonInfo}
  ${ErrorMessageInfo}
`;
