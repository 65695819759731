import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { DesignGuideOrderContext } from '../../../../Context';

function useFindCurrentDesignType() {
  const { values } = useFormikContext();

  const { customerLocation, orderItemIndex } = useContext(
    DesignGuideOrderContext,
  );

  return customerLocation.priceList.designTypes.find(
    (item) => item.id === values.orderItems[orderItemIndex].designTypeId,
  );
}

export default useFindCurrentDesignType;
