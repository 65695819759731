/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */
import JsPDF from 'jspdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fdiMapping,
  palmerNotationDisplay,
} from '../../../../../../transforms';
import imageTeeth from '../assets/TeethChartImage';
import {
  getBase64ImageUrl,
  headerIPR,
  pdfControlImagesNamings,
} from '../helpers';

import bodyTableDataGenerate from '../../../utils/bodyTableDataGenerate';
import headerTableDataGenerate from '../../../utils/headerTableDataGenerate';
import '../assets/NotoSans-Regular-normal';

const findFinalStl = (item, position) => {
  const itemName = item.originalFileName;
  return (
    !itemName.includes('OC_') &&
    !itemName.includes('Subsetup') &&
    !itemName.includes('template') &&
    !itemName.includes('attachments') &&
    (itemName.includes(position[0]) || itemName.includes(position[1]))
  );
};

const drawPalmer = (tooth, doc, data) => {
  const xPoisitveWidth = data.cell.x + data.cell.width / 2 + 8;
  const xNegativeWidth = data.cell.x + data.cell.width / 2 - 8;
  const YMaxHeight = data.cell.y + 14;
  const YMinHeight = data.cell.y + 3;

  if (tooth === 'br') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xPoisitveWidth, YMinHeight, xPoisitveWidth, YMaxHeight);

    doc.line(xPoisitveWidth, YMaxHeight, xNegativeWidth, YMaxHeight);
  }

  if (tooth === 'bl') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xNegativeWidth, YMaxHeight);

    doc.line(xPoisitveWidth, YMaxHeight, xNegativeWidth, YMaxHeight);
  }
  if (tooth === 'tl') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xNegativeWidth, YMaxHeight);

    doc.line(xNegativeWidth, YMinHeight, xPoisitveWidth, YMinHeight);
  }
  if (tooth === 'tr') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xPoisitveWidth, YMinHeight);

    doc.line(xPoisitveWidth, YMinHeight, xPoisitveWidth, YMaxHeight);
  }
};

const allTeeth = Array(32).fill(null);
const arrOfKeys = [...Array(32).keys()];
const TEETH_HEADER = arrOfKeys.splice(0, 16).concat(arrOfKeys.reverse());

const MOVEMENT_NAMINGS = {
  Angulation: 'Angulation (deg)',
  Inclination: 'Inclination (deg)',
  Rotation: 'Rotation (deg)',
  ForwardBackward: 'Forward/Backward (mm)',
  IntrusionExtrusion: 'Intrusion/Extrusion (mm)',
  LeftRight: 'Left/Right (mm)',
};

function TeethChartPDF({
  stages,
  caseId,
  total: totalData,
  location,
  patientName = null,
  dentalNotation = null,
  stl = [],
  designerMessagesCurrent = [],
  treatmentReviewFiles = [],
}) {
  const { t } = useTranslation('pdfReport');

  const [startLoading, setStartLoading] = useState(false);

  const qualityControlImages = useMemo(
    () =>
      pdfControlImagesNamings
        .map((name) =>
          treatmentReviewFiles.find(
            (item) =>
              item.originalFileName === `${name}.jpg` ||
              item.originalFileName === `${name}.png`,
          ),
        )
        .filter((item) => item),
    [treatmentReviewFiles],
  );

  const { numberingSystem: locationNotation, pdfAllMovementPerStage } =
    location;
  const numberingSystem = dentalNotation || locationNotation;
  const headerBasedOnNotation = (item) => {
    if (numberingSystem === 'uns') {
      return item;
    }

    if (numberingSystem === 'palmer') {
      const res = palmerNotationDisplay(item, true);
      return res && res[0];
    }

    if (numberingSystem === 'fdi') {
      return fdiMapping[item];
    }

    return null;
  };

  const IPRData = [];

  stages.slice(1).map((stage) => {
    const currentStageTeeth = stage.Teeth;
    const headerTable = headerTableDataGenerate(currentStageTeeth);

    const foundData = {
      stage: stage.Number,
      tooth: [],
    };

    const firstTable = TEETH_HEADER.slice(0, 16).map((item) => ({
      dataKey: item + 1,
    }));
    const secondTable = TEETH_HEADER.slice(16, 32).map((item) => ({
      dataKey: item + 1,
    }));

    for (const pg of firstTable) {
      const { correctItemCount, aggregatedValue } = headerIPR(
        pg.dataKey - 2,
        headerTable,
      );

      if (aggregatedValue) {
        foundData.tooth.push(correctItemCount);
      }
    }

    for (const pg of secondTable) {
      const { correctItemCount, aggregatedValue } = headerIPR(
        pg.dataKey,
        headerTable,
      );

      if (aggregatedValue) {
        foundData.tooth.push(correctItemCount);
      }
    }

    if (foundData.tooth.length) {
      IPRData.push(foundData);
    }

    return null;
  });

  // eslint-disable-next-line no-unused-vars
  const firstAttachments = useMemo(
    () =>
      stages.reduce((arr, item) => {
        const currentToothData = { elements: [], stage: item.Number };
        item.Teeth.map((tooth) => {
          if (tooth.Attachments) {
            currentToothData.elements.push(tooth.Unn);
          }
          return null;
        });

        if (currentToothData.elements.length) {
          arr.push(currentToothData);
        }

        return arr;
      }, []),
    [stages],
  );

  const generateTableData = (singleStage, doc, total, allStages) => {
    const currentStageTeeth = singleStage.Teeth;
    const allTeethData = [...allTeeth];

    currentStageTeeth.forEach((item) => {
      if (item) {
        allTeethData[item.Unn - 1] = item;
      }
    });

    const headerTable = headerTableDataGenerate(currentStageTeeth, total);

    const bodyTable = bodyTableDataGenerate(allTeethData);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 2; i++) {
      const body = Object.keys(bodyTable).reduce((arr, item) => {
        const bodyTableData = [
          ...bodyTable[item]
            .slice(i ? 16 : 0, i ? 32 : 16)
            .map((value) =>
              !value ||
              ['0.00', '-0.00'].includes(value.replace(/[DMLVEIB-]/g, ''))
                ? null
                : value,
            ),
        ];

        if (i) {
          bodyTableData.reverse();
        }

        arr.push([t(MOVEMENT_NAMINGS[item]), ...bodyTableData]);
        return arr;
      }, []);
      doc.autoTable({
        theme: 'plain',
        margin: {
          left: 15,
          right: 15,
          top: 40,
          useFor: 'page',
        },
        ...(total ? { startY: i ? doc.lastAutoTable.finalY + 50 : 140 } : {}),
        styles: {
          fontSize: 6,
          cellWidth: 29,
          lineWidth: 1,
          halign: 'center',
          valign: 'middle',
        },
        columns: [
          { dataKey: 'headNaming', header: '' },
          ...TEETH_HEADER.slice(i ? 16 : 0, i ? 32 : 16).map((item) => ({
            dataKey: item + 1,
            header: headerBasedOnNotation(item + 1) || '',
          })),
        ],
        body,
        pageBreak:
          !singleStage.Number && i === 0 && !allStages ? 'always' : 'avoid',
        columnStyles: {
          headNaming: {
            cellWidth: 100,
            halign: 'left',
            valign: 'middle',
          },
        },
        didDrawCell(data) {
          if (data.cell.raw === headerBasedOnNotation(data.column.dataKey)) {
            const { aggregatedValue, correctItemCount } = headerIPR(
              i ? data.column.dataKey : data.column.dataKey - 2,
              headerTable,
            );

            if (
              !headerTable[data.column.dataKey] &&
              data.column.raw.dataKey !== 'headNaming'
            ) {
              doc.setDrawColor(255, 0, 0);

              doc.line(
                data.cell.x + 5,
                data.cell.y + 2,
                data.cell.x + 24,
                data.cell.y + 15,
              );

              doc.line(
                data.cell.x + 24,
                data.cell.y + 2,
                data.cell.x + 5,
                data.cell.y + 15,
              );
            }

            if (aggregatedValue && aggregatedValue.toFixed(1) >= 0.1) {
              doc.setFontSize(7);
              doc.setTextColor('#f00');
              doc.text(
                `${aggregatedValue.toFixed(1)}`,
                data.cell.x - 5,
                data.cell.y - 5,
              );

              const stageNumber = IPRData.reduce((num, ipr) => {
                let newNum = num;

                ipr.tooth.forEach((singleIpr) => {
                  if (singleIpr === correctItemCount) {
                    newNum = ipr.stage;
                  }
                });

                return newNum === null || newNum === undefined ? 1 : newNum;
              }, null);

              if (!allStages) {
                doc.text(
                  `Stage ${stageNumber}`,
                  data.cell.x + 1,
                  data.cell.y - 15,
                  null,
                  90,
                );
              }

              doc.setDrawColor(255, 0, 0);
              doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + 17);
            }
          }

          if (data.row.index === 0 && !Array.isArray(data.row.raw)) {
            if (numberingSystem === 'palmer') {
              const toothInfo = palmerNotationDisplay(
                data.column.dataKey,
                true,
              );

              drawPalmer(toothInfo && toothInfo[1], doc, data);
            }
            if (headerTable[data.column.dataKey]?.attachments) {
              doc.setDrawColor(0, 0, 255);
              doc.circle(data.cell.x + 14.5, data.cell.y + 8.5, 7);
            }
          }
        },
      });
    }
  };
  const generateTableDataImageTeeth = (singleStage, doc, total) => {
    const currentStageTeeth = singleStage.Teeth;
    const allTeethData = Array(32).fill(null);

    currentStageTeeth.forEach((item) => {
      if (item) {
        allTeethData[item.Unn - 1] = item;
      }
    });

    const headerTable = headerTableDataGenerate(currentStageTeeth, total);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 2; i++) {
      doc.autoTable({
        theme: 'plain',
        margin: {
          left: i ? 45 : 40,
          right: 15,
        },
        startY: i ? doc.lastAutoTable.finalY : 320,
        styles: {
          fontSize: 6,
          lineWidth: 0,
          halign: 'center',
          valign: 'middle',
          lineColor: '#000',
        },
        columns: [
          ...TEETH_HEADER.slice(i ? 16 : 0, i ? 32 : 16).map((item) => ({
            dataKey: item + 1,
            header: headerBasedOnNotation(item + 1) || '',
          })),
        ],
        body: [[]],
        pageBreak: !singleStage.Number && i === 0 ? 'always' : 'avoid',
        columnStyles: {
          1: { cellWidth: 30 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 27 },
          5: { cellWidth: 27 },
          6: { cellWidth: 30 },
          7: { cellWidth: 25 },
          8: { cellWidth: 32 },
          10: { cellWidth: 25 },
          11: { cellWidth: 29 },
          13: { cellWidth: 25 },
          14: { cellWidth: 40 },
          15: { cellWidth: 40 },
          16: { cellWidth: 32 },
          32: { cellWidth: 35 },
          31: { cellWidth: 44 },
          30: { cellWidth: 45 },
          29: { cellWidth: 29 },
          28: { cellWidth: 26 },
          27: { cellWidth: 27 },
          26: { cellWidth: 22 },
          25: { cellWidth: 19 },
          24: { cellWidth: 19 },
          23: { cellWidth: 21 },
          22: { cellWidth: 27 },
          21: { cellWidth: 27 },
          20: { cellWidth: 28 },
          19: { cellWidth: 45 },
          18: { cellWidth: 44 },
          17: { cellWidth: 30 },
        },
        didDrawCell(data) {
          if (data.cell.raw === headerBasedOnNotation(data.column.dataKey)) {
            const { aggregatedValue, correctItemCount } = headerIPR(
              i ? data.column.dataKey : data.column.dataKey - 2,
              headerTable,
            );
            if (
              !headerTable[data.column.dataKey] &&
              data.column.raw.dataKey !== 'headNaming'
            ) {
              doc.setDrawColor(255, 0, 0);
              doc.setLineWidth(2.0);
              doc.line(
                data.cell.x + 6,
                data.cell.y + 2 - (i ? -23 : 18),
                data.cell.x + data.cell.width - 6,
                data.cell.y + 15 - (i ? -23 : 18),
              );

              doc.line(
                data.cell.x + data.cell.width - 6,
                data.cell.y + 2 - (i ? -23 : 18),
                data.cell.x + 6,
                data.cell.y + 15 - (i ? -23 : 18),
              );
            }

            if (aggregatedValue && aggregatedValue.toFixed(1) >= 0.1) {
              doc.setFontSize(7);
              doc.setTextColor('#f00');
              doc.text(
                `${aggregatedValue.toFixed(1)}`,
                data.cell.x - 5,
                data.cell.y - (i ? -75 : 55),
              );

              const stageNumber = IPRData.reduce((num, ipr) => {
                let newNum = num;

                ipr.tooth.forEach((singleIpr) => {
                  if (singleIpr === correctItemCount) {
                    newNum = ipr.stage;
                  }
                });

                return newNum === null || newNum === undefined ? 1 : newNum;
              }, null);

              doc.setTextColor('#333');
              doc.text(
                `Stage ${stageNumber}`,
                data.cell.x + 1,
                data.cell.y + (i ? 110 : -70),
                null,
                90,
              );

              doc.setDrawColor(255, 0, 0);
              doc.setLineWidth(2.0);
              if (i) {
                doc.line(
                  data.cell.x,
                  data.cell.y + 45,
                  data.cell.x,
                  data.cell.y + 65,
                );
              } else {
                doc.line(
                  data.cell.x,
                  data.cell.y - 30,
                  data.cell.x,
                  data.cell.y - 50,
                );
              }
            }
          }

          if (data.row.index === 0 && !Array.isArray(data.row.raw)) {
            if (numberingSystem === 'palmer') {
              const toothInfo = palmerNotationDisplay(
                data.column.dataKey,
                true,
              );

              doc.setLineWidth(1.0);
              drawPalmer(toothInfo && toothInfo[1], doc, data);
            }
            if (headerTable[data.column.dataKey]?.attachments) {
              doc.setLineWidth(2.0);
              doc.setFillColor(0, 0, 255);
              doc.circle(
                data.cell.x + data.cell.width / 2,
                data.cell.y + (i ? 28 : -10),
                5,
                'F',
              );
            }
          }
        },
      });
    }
  };
  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';
    const doc = new JsPDF(orientation, unit, size);

    doc.setFont('NotoSans-Regular');

    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const text = doc.splitTextToSize(
      t('Treatment Summary'),
      pageWidth - 35,
      {},
    );
    doc.text(text, 220, 30);

    doc.addImage(imageTeeth, 'JPEG', 15, 250, 545, 180);

    const head = [[t('Case Information')]];
    const body = [];
    doc.autoTable({
      head,
      body,
      theme: 'plain',
      margin: {
        left: 15,
      },
    });

    doc.setLineWidth(2.0);
    doc.line(20, 60, 560, 60);

    doc.autoTable({
      head: [[`${t('Patient name')}:`, patientName]],
      body: [[`${t('Case number')}:`, caseId]],
      styles: { font: 'NotoSans-Regular' },
      theme: 'plain',
      tableWidth: 'wrap',
      margin: {
        left: 15,
        top: 0,
      },
    });

    const calcAmountStl = (num, item, side) => {
      if (
        side.some((singleItem) => item.originalFileName.includes(singleItem)) &&
        !item.originalFileName.includes('OC_') &&
        !item.originalFileName.includes('template') &&
        !item.originalFileName.includes('attachments') &&
        item.originalFileName.includes('Subsetup')
      ) {
        // eslint-disable-next-line no-param-reassign
        num += 1;
      }

      return num;
    };

    const lower = stl.reduce(
      (num, item) => calcAmountStl(num, item, ['Lower', 'Mandibular']),
      0,
    );
    const upper = stl.reduce(
      (num, item) => calcAmountStl(num, item, ['Upper', 'Maxillary']),
      0,
    );

    const finalLower = stl.some((item) =>
      findFinalStl(item, ['Final_Lower', 'Mandibular']),
    );
    const finalUpper = stl.some((item) =>
      findFinalStl(item, ['Final_Upper', 'Maxillary']),
    );

    const ocUpperAmount = stl.reduce((num, item) => {
      if (
        item.originalFileName.includes('OC_') &&
        item.originalFileName.includes('Upper') &&
        !item.originalFileName.includes('template') &&
        !item.originalFileName.includes('attachments')
      ) {
        // eslint-disable-next-line no-param-reassign
        num += 1;
      }
      return num;
    }, 0);

    const ocLowerAmount = stl.reduce((num, item) => {
      if (
        item.originalFileName.includes('OC_') &&
        item.originalFileName.includes('Lower') &&
        !item.originalFileName.includes('template') &&
        !item.originalFileName.includes('attachments')
      ) {
        // eslint-disable-next-line no-param-reassign
        num += 1;
      }

      return num;
    }, 0);

    let ocTraysAmount = (ocLowerAmount + ocUpperAmount) / 2;

    if (ocUpperAmount > ocLowerAmount && ocLowerAmount !== 0) {
      ocTraysAmount = ocUpperAmount;
    }

    if (ocLowerAmount > ocUpperAmount && ocUpperAmount !== 0) {
      ocTraysAmount = ocLowerAmount;
    }

    if (ocLowerAmount === 0 && ocUpperAmount > 0) {
      ocTraysAmount = ocUpperAmount;
    }

    if (ocUpperAmount === 0 && ocLowerAmount > 0) {
      ocTraysAmount = ocLowerAmount;
    }

    doc.autoTable({
      body: [
        [`${t('Upper')}:`, upper + !!finalUpper],
        [`${t('Lower')}:`, lower + !!finalLower],
        [`${t('OC Trays')}:`, ocTraysAmount],
      ],
      theme: 'plain',
      tableWidth: 'wrap',
      margin: {
        left: 15,
        top: 0,
      },
    });

    generateTableDataImageTeeth(totalData, doc, true);

    const transformedData = firstAttachments.reduce((arr, item, idx) => {
      const transformedElements = item.elements.map((touthNum, index) => {
        if (numberingSystem === 'palmer') {
          const tooth = palmerNotationDisplay(touthNum, true);
          return tooth.join(',');
        }

        return index === item.elements.length - 1
          ? headerBasedOnNotation(touthNum)
          : `${headerBasedOnNotation(touthNum)},`;
      });
      if (!idx) {
        // eslint-disable-next-line no-param-reassign
        arr = [
          [
            `${t('Attachments')}:`,
            `${t('stage')}: ${item.stage}`,
            `${t('teeth')}: ${
              numberingSystem === 'palmer' ? '' : transformedElements.join(' ')
            }`,
            ...(numberingSystem === 'palmer' ? transformedElements : []),
          ],
        ];
      } else {
        arr.push([
          '',
          `${t('stage')}: ${item.stage}`,
          `${t('teeth')}: ${
            numberingSystem === 'palmer' ? '' : transformedElements.join(' ')
          }`,
          ...(numberingSystem === 'palmer' ? transformedElements : []),
        ]);
      }

      return arr;
    }, []);

    doc.autoTable({
      theme: 'plain',
      startY: 500,
      margin: {
        left: 15,
      },
      styles: {
        fontSize: 7,
        cellWidth: numberingSystem === 'palmer' ? 29 : 'auto',
        halign: numberingSystem === 'palmer' ? 'center' : 'left',
        valign: 'middle',
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 50 },
        ...(numberingSystem === 'palmer' ? { 2: { cellWidth: 35 } } : {}),
      },
      body: transformedData,
      willDrawCell(data) {
        if (numberingSystem === 'palmer') {
          const cellTextData = data?.cell?.text[0].split(',');
          const cellText = cellTextData && cellTextData[0];

          // eslint-disable-next-line no-param-reassign
          data.cell.text = cellText;
          if (
            cellTextData &&
            ['tr', 'tl', 'br', 'bl'].includes(cellTextData[1])
          ) {
            drawPalmer(cellTextData[1], doc, data);
          }
        }
      },
    });

    const symbols = (firstPage) => ({
      body: [
        [
          '7',
          '-',
          t(
            'The attachment icon shown at a specific stage indicates that an attachment needs to be placed at that stage.',
          ),
        ],
        [
          '|',
          '-',
          t(
            'The IPR (interproximal reduction) icon shown at a specific stage indicates that IPR needs to be performed at that stage. IPR values are displayed in millimeters.',
          ),
        ],
        ['x', '-', t('Missing tooth.')],
      ],
      head: ['', '', ''],
      theme: 'plain',
      startY: 500,
      didDrawCell(data) {
        if (data.cell.raw === '7') {
          if (firstPage) {
            doc.setLineWidth(2);
            doc.setFillColor(0, 0, 255);
            doc.circle(data.cell.x + 6, data.cell.y + 9.5, 7, 'F');
          } else {
            doc.setFillColor(255, 255, 255);
            doc.setLineWidth(1);
            doc.setDrawColor(0, 0, 255);
            doc.circle(data.cell.x + 6, data.cell.y + 9.5, 7, 'FD');
          }
        }

        if (data.cell.raw === '|') {
          doc.setLineWidth(2.0);
          doc.setDrawColor(255, 0, 0);
          doc.line(
            data.cell.x + 6,
            data.cell.y + 2,
            data.cell.x + 6,
            data.cell.y + 20,
          );
        }
        if (data.cell.raw === 'x') {
          doc.setLineWidth(2.0);
          doc.setDrawColor(255, 0, 0);
          doc.line(
            data.cell.x,
            data.cell.y,
            data.cell.x + 15,
            data.cell.y + 20,
          );
          doc.line(
            data.cell.x + 15,
            data.cell.y,
            data.cell.x,
            data.cell.y + 20,
          );
        }
      },
      margin: {
        left: 15,
      },
    });

    doc.autoTable(symbols(true));

    doc.setFontSize(12);
    doc.text(t('Designer notes:'), 20, 700);

    doc.setFillColor('eee');
    doc.roundedRect(20, 710, 555, 115, 10, 10, 'F');

    const designerMessagesText = designerMessagesCurrent.length
      ? designerMessagesCurrent
          .filter((item) => !item.message.includes('FullContour'))
          .map((item) => item.message)
          .join('. ')
          .replace(/^\s*[\r\n]/gm, '')
      : '';

    doc.setFontSize(designerMessagesText.length > 500 ? 8 : 10);

    const splitTitle = doc.splitTextToSize(designerMessagesText, 540);

    doc.text(splitTitle, 30, 730);

    doc.addPage();
    doc.autoTable({
      head: [[t('Total treatment overview')]],
      body,
      theme: 'plain',
      margin: {
        left: 15,
      },
    });
    generateTableData(totalData, doc, true);

    doc.autoTable(symbols());

    if (pdfAllMovementPerStage) {
      doc.addPage();
      let stageNum = 0;
      for (const singleStage of stages) {
        if (stageNum && !(stageNum % 2)) {
          doc.addPage();
        }
        doc.text(
          singleStage.Number === 0
            ? `${t('Stage')} 0`
            : `${t('Stage')} ${singleStage.Number - 1}-${singleStage.Number}`,
          14,
          stageNum % 2 ? doc.previousAutoTable.finalY + 17 : 35,
        );
        generateTableData(singleStage, doc, false, true);
        stageNum += 1;
      }
    }

    if (qualityControlImages.length === 5) {
      setStartLoading(true);

      doc.addPage();

      doc.autoTable({
        head: [[t('Design overview')]],
        body: [],
        theme: 'plain',
        margin: {
          left: 15,
        },
      });

      doc.setLineWidth(1.0);
      doc.line(20, 60, 560, 60);

      doc.text(t('Final position'), 20, 90);

      getBase64ImageUrl(qualityControlImages, (imgs) => {
        const { height, width } = doc.getImageProperties(imgs[0]);
        const ratioCoefficient = height / 200;
        const updatedWidth = width / ratioCoefficient;

        doc.addImage(imgs[0], 'JPEG', 20, 100, updatedWidth, 200);

        doc.text(t('Initial position'), 20, 330);
        doc.text(t('Final position'), 300, 330);

        doc.addImage(imgs[1], 'JPEG', 20, 340, 270, 230);
        doc.addImage(imgs[2], 'JPEG', 20, 580, 270, 230);
        doc.addImage(imgs[3], 'JPEG', 300, 340, 270, 230);
        doc.addImage(imgs[4], 'JPEG', 300, 580, 270, 230);

        setStartLoading(false);

        doc.save(`treatment-summary-${caseId}`);
      });
    } else {
      doc.save(`treatment-summary-${caseId}`);
    }
  };

  return (
    <div>
      <button
        type="button"
        className={`button ${startLoading ? 'is-loading' : ''}`}
        disabled={startLoading}
        onClick={exportPDF}
      >
        <span className="icon">
          <i className="bx bxs-file-pdf is-size-4" />
        </span>
        <span>{t('Download as PDF', { ns: 'common' })}</span>
      </button>
    </div>
  );
}

TeethChartPDF.propTypes = {
  stages: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  patientName: PropTypes.string,
  stl: PropTypes.array,
  designerMessagesCurrent: PropTypes.array,
  treatmentReviewFiles: PropTypes.array,
};

export default TeethChartPDF;
