import PropTypes from 'prop-types';
import './Accordion.scss';
import AccordionCard from './AccordionCard';

function Accordion(props) {
  const { data, ...rest } = props;

  const renderAccordionCards = (items) =>
    items.map((item) => {
      if (item.children) {
        return (
          <div
            key={(Math.random() + 1).toString(36).substring(7)}
            className="mb-5"
          >
            <p>{item.title}</p>
            {renderAccordionCards(item.children)}
          </div>
        );
      }
      return (
        <AccordionCard
          card={item}
          ref={item.ref}
          key={item.cardId}
          searchValue={item.searchValue}
          {...rest}
        />
      );
    });

  return (
    <ul className="accordion-general">{renderAccordionCards(data.children)}</ul>
  );
}

Accordion.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Accordion;
