import { gql } from '@apollo/client';

export default gql`
  query designOrganizationLocations($slug: String, $id: ID) {
    designOrganization(slug: $slug, id: $id) {
      id
      slug
      locations {
        id
        name
        key
        value
        text
      }
    }
  }
`;
