import { gql } from '@apollo/client';
import { AdminMessageInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateAdminMessage($input: AdminMessageUpdateInput!) {
    updateAdminMessage(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminMessage {
        ...AdminMessageInfo
      }
    }
  }
  ${AdminMessageInfo}
  ${ErrorMessageInfo}
`;
