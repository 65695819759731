import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BoxIcon from '../Icons/BoxIcon';

function ShowBodySection({ children, title, to = null, linkTitle = null }) {
  const { t } = useTranslation('showelements');

  return (
    <>
      <div className="columns is-align-items-center showBodySection">
        <div className="column">
          <h5 className="title is-5 is-capitalized">{t(title)}</h5>
        </div>
        {to && linkTitle && (
          <div className="column has-text-right">
            <Link
              to={to}
              style={{ marginBottom: 0, textTransform: 'capitalize' }}
              className="button is-small is-primary"
            >
              <BoxIcon name="bx-plus" />
              <span>{t(linkTitle)}</span>
            </Link>
          </div>
        )}
      </div>
      {children}
    </>
  );
}

ShowBodySection.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  linkTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ShowBodySection;
