function FdiNumbers() {
  return (
    <>
      <g id="g3949" transform="matrix(1.23 0.0 0 1.2 -60 -50)">
        <text
          transform="matrix(1 0 0 1 1264.0132 300.6309)"
          className="st5 st6"
        >
          11
        </text>
        <text transform="matrix(1 0 0 1 1610 300.6309)" className="st5 st6">
          21
        </text>
        <text transform="matrix(1 0 0 1 940 405.7134)" className="st5 st6">
          12
        </text>
        <text transform="matrix(1 0 0 1 634 589.7134)" className="st5 st6">
          13
        </text>
        <text transform="matrix(1 0 0 1 460 887.7134)" className="st5 st6">
          14
        </text>
        <text transform="matrix(1 0 0 1 348 1183.7134)" className="st5 st6">
          15
        </text>
        <text transform="matrix(1 0 0 1 238 1485.7134)" className="st5 st6">
          16
        </text>
        <text transform="matrix(1 0 0 1 180 1815.7134)" className="st5 st6">
          17
        </text>
        <text transform="matrix(1 0 0 1 120 2185.7134)" className="st5 st6">
          18
        </text>
        <text
          transform="matrix(1 0 0 1 1950.6582 405.7134)"
          className="st5 st6"
        >
          22
        </text>
        <text
          transform="matrix(1 0 0 1 2255.5596 589.7134)"
          className="st5 st6"
        >
          23
        </text>
        <text transform="matrix(1 0 0 1 2429.999 887.7134)" className="st5 st6">
          24
        </text>
        <text
          transform="matrix(1 0 0 1 2540.7539 1183.7134)"
          className="st5 st6"
        >
          25
        </text>
        <text
          transform="matrix(1 0 0 1 2651.6328 1485.7134)"
          className="st5 st6"
        >
          26
        </text>
        <text
          transform="matrix(1 0 0 1 2709.5596 1815.7134)"
          className="st5 st6"
        >
          27
        </text>
        <text
          transform="matrix(1 0 0 1 2769.3398 2185.7134)"
          className="st5 st6"
        >
          28
        </text>
      </g>
      <g id="g3949" transform="matrix(1.23 0.0 0 1.2 -60 -300)">
        <text
          transform="matrix(1 0 0 1 1322.8512 4615.7134)"
          className="st5 st6"
        >
          41
        </text>
        <text
          transform="matrix(1 0 0 1 1562.8512 4615.7134)"
          className="st5 st6"
        >
          31
        </text>
        <text
          transform="matrix(1 0 0 1 100.4933 2772.3159)"
          className="st5 st6"
        >
          48
        </text>
        <text
          transform="matrix(1 0 0 1 1053.0367 4575.7134)"
          className="st5 st6"
        >
          42
        </text>
        <text
          transform="matrix(1 0 0 1 1813.0367 4575.7134)"
          className="st5 st6"
        >
          32
        </text>
        <text
          transform="matrix(1 0 0 1 752.4874 4415.7134)"
          className="st5 st6"
        >
          43
        </text>
        <text
          transform="matrix(1 0 0 1 2112.4875 4415.7134)"
          className="st5 st6"
        >
          33
        </text>
        <text
          transform="matrix(1 0 0 1 532.7072 4216.4458)"
          className="st5 st6"
        >
          44
        </text>
        <text
          transform="matrix(1 0 0 1 2332.707 4215.7134)"
          className="st5 st6"
        >
          34
        </text>
        <text
          transform="matrix(1 0 0 1 326.0846 3975.7134)"
          className="st5 st6"
        >
          45
        </text>
        <text
          transform="matrix(1 0 0 1 2538.0847 3975.7134)"
          className="st5 st6"
        >
          35
        </text>
        <text transform="matrix(1 0 0 1 164.524 3603.7134)" className="st5 st6">
          46
        </text>
        <text
          transform="matrix(1 0 0 1 2700.5242 3603.7134)"
          className="st5 st6"
        >
          36
        </text>
        <text
          transform="matrix(1 0 0 1 102.7078 3144.8794)"
          className="st5 st6"
        >
          47
        </text>
        <text
          transform="matrix(1 0 0 1 2762.2671 3144.147)"
          className="st5 st6"
        >
          37
        </text>
        <text
          transform="matrix(1 0 0 1 2749.833 2772.3159)"
          className="st5 st6"
        >
          38
        </text>
      </g>
    </>
  );
}

export default FdiNumbers;
