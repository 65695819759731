const checkIfOptionSelected = (redesign, selectedOptions) =>
  Object.values(selectedOptions).reduce((isActive, key) => {
    if (!isActive) {
      return Object.values(key).some(
        (item) => !!item && !item.includes('> Other'),
      );
    }

    return isActive;
  }, false);

export default checkIfOptionSelected;
