import PropTypes from 'prop-types';
import './BillingCreditCard.css';
import BillingCreditCardDefaultButton from './BillingCreditCardButtons/BillingCreditCardDefaultButton';
import BillingCreditCardDeleteButton from './BillingCreditCardButtons/BillingCreditCardDeleteButton';

function BillingCreditCard({ creditCard, numberOfCards = 1, refetch }) {
  return (
    <div
      className="billing-credit-card__wrapper"
      style={{
        background: creditCard.defaultCard ? 'rgb(83 201 87 / 21%)' : 'none',
      }}
    >
      <div className="billing-credit-card__info-block">
        <p>
          {creditCard.brand.charAt(0).toUpperCase() + creditCard.brand.slice(1)}{' '}
          ending in
          {` ${creditCard.maskedNumber.slice(
            creditCard.maskedNumber.length - 6,
          )}`}
        </p>
        <p>Exp. Date {creditCard.expirationDate}</p>
      </div>
      <div className="billing-credit-card__info-block">
        <p>{creditCard.nameOnCard}</p>
        {numberOfCards > 0 && (
          <div className="my-0 mx-1">
            {!creditCard.defaultCard && numberOfCards > 1 && (
              <BillingCreditCardDefaultButton
                cardId={creditCard.id}
                refetch={refetch}
              />
            )}
            {numberOfCards > 1 ||
            import.meta.env.VITE_APP_NAME === 'admin_portal' ? (
              <BillingCreditCardDeleteButton
                cardId={creditCard.id}
                refetch={refetch}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

BillingCreditCard.propTypes = {
  creditCard: PropTypes.object.isRequired,
  numberOfCards: PropTypes.number,
  refetch: PropTypes.func.isRequired,
};

export default BillingCreditCard;
