export function getVariables(props) {
  const {
    match: {
      params: { action, id },
    },
  } = props;

  if (action === 'edit') {
    return { id };
  }

  return {};
}

export function skipQuery(props) {
  const {
    match: {
      params: { action },
    },
  } = props;
  return action === 'new';
}
