import ReportsListQueryWrapper from '@fullcontour/common/src/components/views/Reports/ReportsList/ReportsListQueryWrapper';
import ReportForm from '@fullcontour/common/src/components/views/Reports/ReportForm';
import ReportSaved from '@fullcontour/common/src/components/views/Reports/ReportSaved';

const routes = [
  {
    path: '/reports',
    component: ReportsListQueryWrapper,
    sl: true,
    so: true,
  },
  {
    path: '/reports/:type',
    component: ReportForm,
    sl: true,
    so: true,
  },
  {
    path: '/reports/:type/:id',
    component: ReportSaved,
    sl: true,
    so: true,
  },
];

export default routes;
