import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { withFormWrap } from '../../../../shared';
import { initialValues, schema } from './helpers';

import LocationGuideGroupsBody from './LocationGuideGroupBody/LocationGuideGroupsBody';
import LocationGuideGroupsHeader from './LocationGuideGroupsHeader/LocationGuideGroupsHeader';

function LocationGuideGroupsInner({ setModal, data }) {
  const [editMode, setEditMode] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues({ data })}
      validationSchema={schema()}
    >
      <>
        <LocationGuideGroupsHeader
          setModal={setModal}
          setEditMode={setEditMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          editMode={editMode}
          data={data}
        />
        <LocationGuideGroupsBody
          searchValue={searchValue}
          groupData={data}
          editMode={editMode}
        />
      </>
    </Formik>
  );
}

LocationGuideGroupsInner.propTypes = {
  setModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  LocationGuideGroupsInner,
);
