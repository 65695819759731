import { gql } from '@apollo/client';

export default gql`
  query designOrganizationsAdmin {
    designOrganizationsAdmin {
      id
      attributes
    }
  }
`;
