/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/scss';
import './Slider.scss';

function SliderNew(props) {
  const { totalStages, autoplay, changeSlider, currentStage } = props;

  const swiperRef = useRef(null);

  const slides = useMemo(
    () => Array.from({ length: totalStages }).map((el, index) => `${index}`),
    [totalStages],
  );

  useEffect(() => {
    if (
      swiperRef &&
      swiperRef.current &&
      currentStage !== swiperRef?.current?.activeIndex
    ) {
      swiperRef.current.slideTo(currentStage, 500, true);
    }
  }, [currentStage, swiperRef]);

  useEffect(() => {
    if (autoplay && swiperRef && swiperRef.current) {
      swiperRef.current.autoplay.start();
    }

    if (!autoplay && swiperRef && swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  }, [autoplay, swiperRef]);

  function onInit(swiperInstance) {
    swiperRef.current = swiperInstance;
  }

  return slides ? (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        bottom: 80,
        backgroundColor: '#333',
        zIndex: 50,
      }}
    >
      <Swiper
        onInit={onInit}
        rewind
        centeredSlides
        spaceBetween={2}
        grabCursor
        slidesPerView="auto"
        slideToClickedSlide
        onActiveIndexChange={(swiper) => changeSlider(swiper.activeIndex)}
        modules={[Autoplay]}
        autoplay={{
          delay: 500,
          disableOnInteraction: false,
        }}
      >
        {slides.map((content) => (
          <SwiperSlide key={content}>{content}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
}

SliderNew.propTypes = {
  totalStages: PropTypes.number.isRequired,
  autoplay: PropTypes.bool.isRequired,
  changeSlider: PropTypes.func.isRequired,
  currentStage: PropTypes.number.isRequired,
};

export default SliderNew;
