const navMenuList = [
  {
    name: 'design',
    icon: 'bxs-folder-open',
    key: 10,
    items: [
      {
        key: 2,
        to: '/orders/sent_for_design',
        label: 'sent for design',
        symbol: 'sent_for_design',
        labelClass: '',
        status: 10,
      },
      {
        key: 3,
        to: '/orders/design_in_progress',
        label: 'design in progress',
        symbol: 'design_in_progress',
        labelClass: '',
        status: 20,
      },
      {
        key: 4,
        to: '/orders/rush_design',
        label: 'rush designs',
        symbol: 'rush_design',
        labelClass: 'is-dark',
        status: 21,
      },
    ],
  },
  {
    name: 'holds',
    icon: 'bxs-error',
    key: 20,
    items: [
      {
        key: 1,
        to: '/orders/on_hold',
        label: 'on hold',
        symbol: 'on_hold',
        labelClass: 'is-danger',
        status: 30,
      },
      {
        key: 2,
        to: '/orders/hold_released',
        label: 'hold released',
        symbol: 'hold_released',
        labelClass: '',
        status: 24,
      },
      {
        key: 3,
        to: '/orders/redesign',
        label: 'redesign',
        symbol: 'redesign',
        labelClass: '',
        status: 22,
      },
    ],
  },
  {
    name: 'approval',
    icon: 'bxs-check-circle',
    key: 30,
    items: [
      {
        key: 1,
        to: '/orders/needs_approval',
        label: 'needs approval',
        symbol: 'needs_approval',
        labelClass: 'is-warning',
        status: 40,
      },
      {
        key: 2,
        to: '/orders/sent_for_approval',
        label: 'sent for approval',
        symbol: 'sent_for_approval',
        labelClass: '',
        status: 42,
      },
    ],
  },
  {
    name: 'manufacturing',
    icon: 'bxs-truck',
    key: 40,
    items: [
      {
        key: 1,
        to: '/orders/sent_to_manufacturer',
        label: 'sent to manufacturer',
        symbol: 'sent_to_manufacturer',
        labelClass: '',
        status: 60,
      },
      {
        key: 2,
        to: '/orders/at_manufacturer',
        label: 'at manufacturer',
        symbol: 'at_manufacturer',
        labelClass: '',
        status: 70,
      },
      {
        key: 3,
        to: '/orders/manufacturing_hold',
        label: 'manufacturing hold',
        symbol: 'manufacturing_hold',
        labelClass: '',
        status: 80,
      },
      {
        key: 4,
        to: '/orders/shipped',
        label: 'shipped',
        symbol: 'shipped',
        labelClass: '',
        status: 72,
      },
    ],
  },
  {
    name: 'finished',
    icon: 'bx-save',
    key: 50,
    items: [
      {
        key: 1,
        to: '/orders/ready_for_download',
        label: 'ready for download',
        symbol: 'ready_for_download',
        labelClass: 'is-success',
        status: 50,
      },
      {
        key: 2,
        to: '/orders/cancelled',
        label: 'cancelled',
        symbol: 'cancelled',
        labelClass: '',
        status: 90,
      },
      {
        key: 3,
        to: '/orders/complete',
        label: 'complete',
        symbol: 'complete',
        labelClass: '',
        status: 95,
      },
    ],
  },
];

export default navMenuList;
