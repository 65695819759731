import { gql } from '@apollo/client';
import { DesignTypeInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateDesignType($input: DesignTypeUpdateInput!) {
    updateDesignType(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designType {
        ...DesignTypeInfo
      }
    }
  }
  ${DesignTypeInfo}
  ${ErrorMessageInfo}
`;
