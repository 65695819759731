import { PasswordField } from '../../FormFields';

function DesignUserPasswordForm() {
  return (
    <div className="field mx-4">
      <div className="control mb-3">
        <PasswordField required />
      </div>
      <div className="control">
        <PasswordField
          name="passwordConfirm"
          label="Confirm New Password"
          required
        />
      </div>
    </div>
  );
}

export default DesignUserPasswordForm;
