import { gql } from '@apollo/client';

export default gql`
  query designLocationCategories {
    designLocations {
      id
      slug
      name
      designTypeCategories {
        id
        name
        slug
      }
    }
  }
`;
