const resendToFtp = {
  admin_portal: {
    visible: true,
    statuses: {
      ready_for_download: [0, 10, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40],
      at_manufacturer: [0, 10, 20, 30, 40],
      shipped: [0, 10, 20, 30, 40],
      complete: [0, 10, 20, 30, 40],
      closed: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: false,
  },
};

export default resendToFtp;
