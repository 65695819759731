import {
  GET_ADMIN_ORDERS,
  GET_CUSTOMER_ORDERS,
  GET_DESIGNER_ORDERS,
  GET_MANUF_ORDERS,
} from '@fullcontour/shared-api';

const queryFromAppName = () => {
  const app = import.meta.env.VITE_APP_NAME;

  switch (app) {
    case 'admin_portal':
      return GET_ADMIN_ORDERS;
    case 'customer_portal':
      return GET_CUSTOMER_ORDERS;
    case 'designer_portal':
      return GET_DESIGNER_ORDERS;
    case 'manuf_portal':
      return GET_MANUF_ORDERS;
    default:
      return null;
  }
};

export default queryFromAppName;
