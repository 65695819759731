import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { queryFromAppName } from '../../../../shared';
import { checkPermissions, reassignOrders } from '../helpers';
import ReassignModal from './ReassignModal';

function ActionReassignSilo({
  order = null,
  selectedCases,
  messageTitle,
  actionTitle,
  ...rest
}) {
  const props = { order, selectedCases, messageTitle, actionTitle, ...rest };
  const { pathname } = useLocation();

  const client = useApolloClient();

  const [modalOpen, setModalOpen] = useState(false);
  const [designLocationId, setDesignLocationId] = useState(null);
  const [autoReassign, setAutoReassign] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  function onRowClicked(event) {
    setDesignLocationId(event.data.id);
  }

  function toggleAutoReassign() {
    setAutoReassign(!autoReassign);
  }

  function closeModal() {
    setSuccess(false);
    setFailure(false);
    setModalOpen(false);
  }

  function openModal() {
    setSuccess(false);
    setFailure(false);
    setModalOpen(true);
  }

  const query = queryFromAppName();

  function reassignSilo() {
    let orderIds;
    let currentQuery;

    Object.keys(query).forEach((i) => {
      if (i.includes(pathname.split('/')[2].toUpperCase())) {
        currentQuery = i;
      }
    });

    if (order) {
      orderIds = [order.id];
    } else {
      orderIds = selectedCases.map((caseData) => caseData.data.id);
    }

    reassignOrders(
      client,
      orderIds,
      designLocationId,
      () => setSuccess(true),
      () => setFailure(true),
      autoReassign,
      query[currentQuery],
      pathname.split('/')[2],
    );
  }

  return checkPermissions(props) ? (
    <ReassignModal
      closeModal={closeModal}
      modalOpen={modalOpen}
      openModal={openModal}
      reassignSilo={reassignSilo}
      designLocationId={designLocationId}
      onRowClicked={onRowClicked}
      success={success}
      failure={failure}
      messageTitle={messageTitle}
      actionTitle={actionTitle}
      toggleAutoReassign={toggleAutoReassign}
      autoReassign={autoReassign}
    />
  ) : null;
}

ActionReassignSilo.propTypes = {
  selectedCases: PropTypes.array.isRequired,
  actionTitle: PropTypes.string.isRequired,
  messageTitle: PropTypes.string.isRequired,
  order: PropTypes.object,
};

export default ActionReassignSilo;
