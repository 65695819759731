import { gql } from '@apollo/client';
import { DesignLocationInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createDesignLocation($input: DesignLocationCreateInput!) {
    createDesignLocation(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      designLocation {
        ...DesignLocationInfo
      }
    }
  }
  ${DesignLocationInfo}
  ${ErrorMessageInfo}
`;
