import PropTypes from 'prop-types';

function Tooth11({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 11)
    : upperArch;
  const variable = designType.toothChart ? 11 : 'upper';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[11]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st2 activeTooth ${selected ? 'toothSelected' : ''}` : 'st2'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M2597.3 963.6c54-18 79.4-51 85.1-94.2 6.7-42.2-0.7-83.4-26.2-123.2 -22.3-62-44.4-58.6-66.5-62.8 -19.7 2.5-37.7 1.4-55.2-0.7 -52.8-2.3-72.5 21.6-85.2 37.3 -44.6 59.7-39.7 94.5-50.5 137.2 -5.6 47.2-3.1 87.5 21 109.4 73.3 54.8 85.8 28 116.4 25.6C2593.3 968.1 2578.6 972.5 2597.3 963.6L2597.3 963.6z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2630.7 902.3c-2.6-58.4-7.5-113.8-34.9-140.4 -27.1-28.4-61.9-48.9-109.6-56.6"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2464 757c-32.3 125.8-46.3 155.4 38.1 77.5"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2539.3 878.4c-71.2 86.1-24 73.4 52.7 36.1"
      />
    </>
  );
}

Tooth11.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth11;
