import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeArches(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Arch For Treatment">
      <div className="checkWrap">
        <NonFormCheckboxField
          name="upperArch"
          readOnly
          checked={'UpperJawPrescription' in prescription}
          labelText="Upper Arch"
        />
      </div>
      <div className="checkWrap">
        <NonFormCheckboxField
          name="lowerArch"
          readOnly
          checked={'LowerJawPrescription' in prescription}
          labelText="Lower Arch"
        />
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeArches.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeArches;
