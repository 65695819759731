import { NavLink, useParams } from 'react-router-dom';
import OrderShowNavLink from './OrderShowNavLink';
import Styles from './OrderShowNavMenu.module.scss';

function OrderShowNavMenu() {
  const { id } = useParams();
  const isCustomerPortal = import.meta.env.VITE_APP_NAME === 'customer_portal';
  const showCustomerActivity =
    import.meta.env.VITE_APP_NAME === 'admin_portal' ||
    import.meta.env.VITE_APP_NAME === 'manuf_portal';
  return (
    <>
      {isCustomerPortal && (
        <li>
          <NavLink
            // activeClassName="is-active"
            className={Styles.navMenuLink}
            id="activityTab"
            to={`/orders/details/${id}/activity`}
          >
            <OrderShowNavLink name="activity" icon="bx-list-ul" />
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          // activeClassName="is-active"
          className={Styles.navMenuLink}
          to={`/orders/details/${id}`}
        >
          <OrderShowNavLink name="files" icon="bx-folder" />
        </NavLink>
      </li>
      <li>
        <NavLink
          // activeClassName="is-active"
          className={Styles.navMenuLink}
          to={`/orders/details/${id}/order-info`}
        >
          <OrderShowNavLink name="case details" icon="bx-detail" />
        </NavLink>
      </li>
      {!isCustomerPortal &&
        import.meta.env.VITE_APP_NAME !== 'designer_portal' && (
          <>
            <li>
              <NavLink
                // activeClassName="is-active"
                className={Styles.navMenuLink}
                to={`/orders/details/${id}/activity`}
              >
                <OrderShowNavLink name="activity" icon="bx-list-ul" />
              </NavLink>
            </li>
            <li>
              <NavLink
                // activeClassName="is-active"
                className={Styles.navMenuLink}
                to={`/orders/details/${id}/messages`}
              >
                <OrderShowNavLink name="messages" icon="bxs-message-rounded" />
              </NavLink>
            </li>
          </>
        )}

      {import.meta.env.VITE_APP_NAME === 'designer_portal' ||
      import.meta.env.VITE_APP_NAME === 'admin_portal' ||
      import.meta.env.VITE_APP_NAME === 'customer_portal' ? (
        <NavLink
          // activeClassName="is-active"
          className={Styles.navMenuLink}
          to={`/orders/details/${id}/design-preferences`}
        >
          <OrderShowNavLink name="design preferences" icon="bx-devices" />
        </NavLink>
      ) : null}

      {(import.meta.env.VITE_APP_NAME === 'admin_portal' ||
        import.meta.env.VITE_APP_NAME === 'designer_portal') && (
        <NavLink
          // activeClassName="is-active"
          className={Styles.navMenuLink}
          to={`/orders/details/${id}/actors`}
        >
          <OrderShowNavLink name="actors" icon="bxs-group" />
        </NavLink>
      )}

      {showCustomerActivity && (
        <NavLink
          // activeClassName="is-active"
          className={Styles.navMenuLink}
          id="activityTab"
          to={`/orders/details/${id}/customer-activity`}
        >
          <OrderShowNavLink name="customer activity" icon="bxs-chat" />
        </NavLink>
      )}
    </>
  );
}

export default OrderShowNavMenu;
