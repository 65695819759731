import { gql } from '@apollo/client';

export default gql`
  mutation destroyPrinterBrand($input: PrinterBrandDestroyInput!) {
    destroyPrinterBrand(input: $input) {
      printerBrand {
        id
      }
    }
  }
`;
