import { useApolloClient } from '@apollo/client';
import { ACTION_CREDIT_CARD_DEFAULT } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import BoxIcon from '../../../../shared/Icons/BoxIcon';

function BillingCreditCardDefaultButton({ cardId, refetch }) {
  const client = useApolloClient();
  const [confirmState, setConfirmState] = useState({ open: false });

  const { t } = useTranslation('billing');

  const show = () => setConfirmState({ open: true });

  const handleCancel = () => setConfirmState({ open: false });

  const defaultCreditCard = async () => {
    await client.mutate({
      mutation: ACTION_CREDIT_CARD_DEFAULT,
      variables: {
        input: {
          id: cardId,
          clientMutationId: uuidv4(),
        },
      },
    });

    refetch();
  };

  const handleConfirm = () => {
    setConfirmState({ open: false });
    defaultCreditCard();
  };

  return (
    <>
      <button
        type="button"
        onClick={show}
        className="button mr-2 px-3 is-small is-primary is-outlined"
      >
        <BoxIcon className="is-size-4" name="bx-star" />
        <span className="is-size-6">{t('Make Default')}</span>
      </button>

      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div
          className="modal-content"
          style={{ backgroundColor: '#fff', padding: '1em', borderRadius: 12 }}
        >
          <div className="block">
            <p>
              {t(
                'Are you sure you want to make this credit card your default?',
              )}
            </p>
          </div>
          <div className="block">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-danger is-outlined"
                onClick={handleCancel}
              >
                {t('Cancel', { ns: 'common' })}
              </button>
              <button
                type="button"
                className="button is-primary"
                onClick={handleConfirm}
              >
                {t('Confirm', { ns: 'common' })}
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCancel}
        />
      </div>
    </>
  );
}

BillingCreditCardDefaultButton.propTypes = {
  cardId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default memo(BillingCreditCardDefaultButton);
