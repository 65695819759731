/* eslint-disable class-methods-use-this */
class OrderItems {
  constructor(props) {
    this.props = props;
    this.tempItems = props.tempItems;
    this.items = [];
  }

  parse = () => {
    this.tempItems.forEach((tempItem, index) => {
      this.loopSoftwareNodes(tempItem, index);
    });

    return this.items;
  };

  loopSoftwareNodes = (tempItem, tempItemIndex) => {
    const { softwareNodes } = this.props;

    softwareNodes.forEach((nodeItems) => {
      if (nodeItems.length > 0) {
        this.loopSoftwareNodeNodes(nodeItems, tempItem, tempItemIndex);
      }
    });
  };

  loopSoftwareNodeNodes = (nodeItems, tempItem, tempItemIndex) => {
    nodeItems.forEach((node) => {
      if (this.findNodeMatch(tempItem, node)) {
        this.tempItems[tempItemIndex].consumed = true;
        const existingItem = this.existingItem(node, tempItem);

        if (existingItem !== -1) {
          const item = this.items[existingItem];
          const teeth = [...item.teeth, ...tempItem.teeth];

          const updatedUnits = this.determineUnits(node.designType, teeth);
          const updatedTeeth = teeth;
          const updatedArches = this.determineArches(
            updatedTeeth,
            node.designType,
          );

          this.items[existingItem] = {
            ...item,
            units: updatedUnits,
            teeth: updatedTeeth,
            arches: updatedArches,
          };
        } else {
          this.items.push({
            designTypeId: node.designType.id,
            splitFile: tempItem.splitFile,
            designTypeName: node.designType.name,
            manufacturerOrganizationId: this.determineManufacturer(
              node.designType,
              tempItem.manufacturerName,
            ),
            orderItemModifiersAttributes: [],
            materialName: tempItem.materialName,
            color: tempItem.color,
            units: this.determineUnits(node.designType, tempItem.teeth),
            teeth: tempItem.teeth,
            arches: this.determineArches(tempItem.teeth, node.designType),
          });
        }
      }
    });
  };

  determineArches = (teeth, designType) => {
    const arches = [];

    if (designType.archChart) {
      const upperTeeth = teeth.some((tooth) => Number(tooth) < 17)
        ? 'upper'
        : null;
      const lowerTeeth = teeth.some((tooth) => Number(tooth) > 16)
        ? 'lower'
        : null;

      if (upperTeeth) {
        arches.push(upperTeeth);
      }

      if (lowerTeeth) {
        arches.push(lowerTeeth);
      }
    }

    return arches;
  };

  determineManufacturer = (designType, manufacturerName) => {
    const { customerLocation } = this.props;
    const regExp = new RegExp(manufacturerName, 'i');
    const defaultManufObj = customerLocation.assignedManufacturers.find(
      (manuf) => manuf.isDefault === true,
    );
    const inHouseObj = customerLocation.assignedManufacturers.find(
      (manuf) => manuf.manufacturerOrganization.isInHouse,
    );
    let manufacturer;
    let inHouse;
    let defaultManuf;

    if (inHouseObj) {
      inHouse = inHouseObj.manufacturerOrganization.id;
    }

    if (defaultManufObj) {
      defaultManuf = defaultManufObj.manufacturerOrganization.id;
    }

    const assignedManufacturerIds = customerLocation.assignedManufacturers.map(
      (m) => m.manufacturerOrganization.id,
    );

    designType.manufacturers.forEach((manuf) => {
      const manufExists = assignedManufacturerIds.find((id) => id === manuf.id);

      if (regExp.test(manuf.name) && manufExists) {
        manufacturer = manuf.id;
      }
    });

    if (manufacturer) {
      return manufacturer;
    }

    if (defaultManuf) {
      return defaultManuf;
    }

    if (
      designType &&
      'disableInHouse' in designType &&
      designType.disableInHouse !== true
    ) {
      return inHouse;
    }

    return null;
  };

  determineUnits = (designType, teeth) => {
    if (designType.billByTooth) {
      return teeth.length;
    }

    if (designType.billByArch) {
      const upperTeeth = teeth.some((tooth) => Number(tooth) > 16) ? 1 : 0;
      const lowerTeeth = teeth.some((tooth) => Number(tooth) < 17) ? 1 : 0;
      return upperTeeth + lowerTeeth;
    }

    return 1;
  };

  existingItem = (node, tempItem) =>
    this.items.findIndex(
      (item) =>
        item.designTypeId === node.designType.id &&
        item.materialName === tempItem.materialName,
    );

  nodeLng = (node) => {
    const { language } = this.props;
    return node.nodeType !== 'indication' ? 'en' : language;
  };

  findNodeMatch = (tempItem, node) => {
    if (tempItem.consumed) {
      return false;
    }

    const acceptedLanguages = ['de', 'es', 'fr', 'zh', 'it', 'ko', 'pt', 'tr'];
    const foundNode = this.nodeFinder(node, tempItem);

    if (node.nodeType !== 'tooth') {
      const enParsedNode = node.nodes.en.some((n) => n === foundNode);

      if (enParsedNode === true) {
        return true;
      }

      return acceptedLanguages
        .map((lng) => {
          if (node.nodes[lng]) {
            return node.nodes[lng].some((n) => n === foundNode);
          }
          return false;
        })
        .some((l) => l === true);
    }

    return node.nodes.en.filter((x) => foundNode.includes(x)).length > 0;
  };

  nodeFinder = (node, tempItem) => {
    if (node.nodeType === 'indication') {
      return tempItem.designTypeName;
    }

    if (node.nodeType === 'material') {
      return tempItem.materialName;
    }

    return tempItem.toothIndications;
  };
}

export default OrderItems;
