/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import PreferenceDropdown from './PreferenceDropdown';

function PreferenceActive(props) {
  const {
    preference,
    foundIndex,
    values: { designProfiles },
    designProfileId,
  } = props;

  return (
    <div className="columns">
      <div className="column" style={{ maxWidth: '350px' }}>
        <PreferenceDropdown
          designProfileId={designProfileId}
          labelText={preference.name}
          foundPrefIndex={designProfiles[
            foundIndex
          ].designPreferences.findIndex((p) =>
            preference.values.some((val) => val.value === p.value),
          )}
          foundIndex={foundIndex}
          {...props}
          preference={preference}
        />
      </div>
    </div>
  );
}

PreferenceActive.propTypes = {
  preference: PropTypes.object.isRequired,
  foundIndex: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default PreferenceActive;
