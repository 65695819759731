function ApiAgreement() {
  return (
    <div
      style={{
        maxHeight: 200,
        overflowY: 'scroll',
        border: '1px solid #ccc',
        marginBottom: '1em',
        padding: 10,
      }}
    >
      <h4 className="title is-4">API LICENSE AND SERVICES AGREEMENT</h4>
      <p>
        <i>Last Updated: January 30, 2020</i>
        <br />
        <br />
        This API License and Services Agreement (the &quot;Agreement&quot;) is a
        binding contract between you (&quot;you&quot; or &quot;your&quot; or the
        &quot;Customer&quot;) and 3Shape Design Service, LLC, an Arizona limited
        liability company (&quot;3Shape Design Service&quot;). This Agreement
        and the Statement of Work (defined below) governs your access to and use
        of the 3Shape Design Service application programming interface and
        Integration Software (defined below). For the avoidance of doubt, use of
        the 3Shape Design Service Platform is governed by the 3Shape Design
        Service Terms and Conditions currently posted at http://www.3Shape
        Design Service.com/terms.
      </p>
      <p>
        BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR
        ACCEPTANCE, OR OTHERWISE ELECTRONICALLY INDICATING ACCEPTANCE, THROUGH
        EXECUTION OF A STATEMENT OF WORK OR BY ACCESSING OR USING THE API YOU
        (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B)
        REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO
        ENTER INTO THIS AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT
        YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT ACCEPT THESE TERMS,
        YOU MAY NOT ACCESS OR USE THE API OR INTEGRATION SOFTWARE.
      </p>
      <p>
        <b>Definitions.</b>
        <br /> The following terms shall have the meanings set forth below:
        &quot;API&quot; means the means the application programming interface
        and all associated API Documentation or other API materials provided by
        3Shape Design Service that permits 3Shape Design Service to develop the
        Integration Software to connect Customer&apos;s System to the 3Shape
        Design Service platform. &quot;API Documentation&quot; means the API
        documentation provided to Customer, if any. &quot;Customer Data&quot;
        means, to the extent authorized under applicable law, the User data
        collected by the Customer or inputted into the Customer System.
        &quot;Customer System&quot; means the Customer&apos;s system developed,
        sold, licensed and/or maintained by Customer for use by Users and/or
        Customer&apos;s internal business purpose, as further defined in the
        Statement of Work. &quot;3Shape Design Service System&quot; means the
        3Shape Design Service platform developed, managed, operated and/or
        maintained by 3Shape Design Service, as further described in the
        Statement of Work. &quot;Integration Software&quot; means the computer
        software code permitting the integration of the Customer System to the
        3Shape Design Service System that utilizes the API and any
        modifications, alterations, translations, or derivative works relating
        thereto, as further defined in the Statement of Work. &quot;Statement of
        Work&quot; means a statement of work between the parties that sets out,
        among other things, the details of implementation of the Integration
        Software and API and any system requirements. &quot;User&quot; means a
        user of the 3Shape Design Service System and/or the Customer System.
        Users may include employees of the Customer, as well as, without
        limitation, non-employees, dental labs, manufactures and clinicians who
        have a relationship with Customer. Interface Development &
        Configuration. Configuration. 3Shape Design Service and Customer will
        work together in good faith to enable 3Shape Design Service to develop
        and configure the Integration Software with Customer&apos;s System and
        account functionality as mutually agreed upon and set forth in a
        Statement of Work. No API shall be available to Customer and no
        Statement of Work shall be binding on the parties hereto unless such
        Statement of Work is executed by both 3Shape Design Service and
        Customer. API Credentials Responsibility. 3Shape Design Service will
        provide Customer the necessary credentials to access the API. Customer
        will be responsible for (i) all uses of any User account that Customer
        has access to, whether or not Customer has authorized the particular use
        or User, and regardless of Customer&apos;s knowledge of such use; and
        (ii) securing its API access credentials. 3Shape Design Service is not
        responsible for any losses, damages, costs, expenses or claims that
        result from stolen or lost credentials or use of the API, Integration
        Software, or 3Shape Design Service System by a Customer&apos;s User.
        Acceptance Test. Following completion of the configuring of the
        Integration Software, both 3Shape Design Service and Customer shall
        perform operational and other tests (collectively, &quot;Acceptance
        Tests&quot;) as 3Shape Design Service may deem reasonably necessary to
        determine whether the API and Integration Software meets the
        parties&apos; respective operational criteria as set forth in the
        Statement of Work. Customer agrees to not put the API into commercial
        use until 3Shape Design Service has determined in its sole discretion
        that such API has satisfied the Acceptance Tests by providing written
        notice to Customer. Updates to Systems and API. 3Shape Design Service
        and Customer each retain the right at all times to make any changes in
        their respective systems that they may deem necessary or desirable. The
        parties acknowledge that over the Term (defined below) of this
        Agreement, 3Shape Design Service may modify and enhance the 3Shape
        Design Service System and API in the ordinary course of its business
        without prior notice to Customer. In the event any modification of
        enhancement renders the Integration Software or API no longer compatible
        or if Customer updates the Customer System, Customer shall be
        responsible for communicating with 3Shape Design Service of such
        compatibility issues. 3Shape Design Service may, but shall have no
        obligation, to assist Customer in system synchronicity and may charge a
        reasonable fee, as determined in its sole discretion, for such services.
        Customer Support. Customer is solely responsible for any customer
        support obligations to Users in connection with this Agreement. Access
        by Users to the Customer System are contracts solely between Customer
        and the User and Customer shall inform all Users before they subscribe
        or are provided access (in a written format approved in advance by
        3Shape Design Service) that 3Shape Design Service does not sponsor, and
        is no way associated with, the Customer System and that 3Shape Design
        Service will have no liability for the failure of the Customer System or
        Integration Software, including, without limitation, for the failure of
        the Customer System or Integration Software to connect or accurately
        transmit information or for any malfunction. Fees and Costs. Neither
        party shall be entitled to any compensation from the other party
        resulting from this Agreement, unless otherwise agreed to in writing.
        Without limiting the foregoing, upon any termination or expiration of
        this Agreement neither of the parties to this Agreement will be entitled
        to compensation for its efforts in promoting the Customer System or the
        generating goodwill inuring to the benefit of the other party. Customer
        will be solely responsible for any applicable sales taxes relating to or
        associated with the contents of this Agreement. Customer Data. Customer
        agrees and acknowledges that Customer is responsible for obtaining any
        necessary consents, releases, or other requirements under applicable law
        prior to transmitting any Customer Data through the 3Shape Design
        Service System. License. API License Grant. Subject to and conditioned
        on Customer&apos;s compliance with all terms and conditions set forth in
        this Agreement, 3Shape Design Service hereby grants to Customer a
        limited, revocable, non-exclusive, non-transferable, non-sublicensable
        license during the Term of the Agreement to use the API solely in
        connection with enabling the API to interact with Customer&apos;s System
        for Customer&apos;s internal business purposes. Customer acknowledges
        that other than the license expressly granted herein, no other license
        is granted including any implied licenses under this Agreement. Use
        Restrictions. Except as expressly authorized under this Agreement,
        Customer shall not, without the prior written consent of 3Shape Design
        Service: (i) copy, modify, download, or create derivative works of the
        API, API Documentation, or Integration Software, in whole or in part;
        (ii) rent, lease, lend, sell, license, sublicense, assign, distribute,
        publish, transfer, or otherwise make available the API, API
        Documentation, or Integration Software; (iii) reverse engineer,
        disassemble, decompile, decode, adapt, or otherwise attempt to derive or
        gain access to any software component of the API or Integration
        Software, in whole or in part; (iv) remove any proprietary notices from
        the API, API Documentation, or Integration Software; (v) use the API,
        API Documentation, or Integration Software in any manner or for any
        purpose that infringes, misappropriates, or otherwise violates any
        intellectual property right or other right of any person, or that
        violates any applicable law; (vi) combine or integrate the API or
        Integration Software with any software, technology, services, or
        materials not authorized by 3Shape Design Service; or (vii) use the API
        or Integration Software in any way the results in or could result in any
        security breach, or violation of privacy law or regulation by or with
        respect to 3Shape Design Service or any of its affiliates or 3Shape
        Design Service&apos;s informational technology security policies as may
        be published, provided, or set forth in the Statement of Work, from time
        to time. Further, Customer agrees to observe 3Shape Design
        Service&apos;s privacy policy currently posted at http://www.3Shape
        Design Service.com/privacy-policy in all respects. Reservation of
        Rights. 3Shape Design Service reserves all rights that are not expressly
        granted to Customer under this Agreement. Ownership. Customer. As
        between Customer and 3Shape Design Service, Customer retains all right,
        title, and interest in and to the Customer System, Customer Data, and
        all other Customer intellectual property. 3Shape Design Service agrees
        to duplicate in all copies and not to remove or destroy any copyright
        notices, other proprietary markings or confidentiality legends placed
        upon or contained within the Customer System and/or Customer Data.
        3Shape Design Service. Except for the Licenses granted herein, Customer
        acknowledges and agrees that it acquires no license to the 3Shape Design
        Service System. As between Customer and 3Shape Design Service, 3Shape
        Design Service retains all right, title, and interest in and to the
        3Shape Design Service System, the Integration Software, API, API
        Documentation, and all other 3Shape Design Service intellectual
        property. Customer agrees to duplicate in all copies and not to remove
        or destroy any copyright notices, other proprietary markings or
        confidentiality legends placed upon or contained within the 3Shape
        Design Service System, Integration Software, API, and/or API
        Documentation. Feedback. If Customer or any of its Users, employees,
        contractors, or agents sends or transmits any communications or
        materials to 3Shape Design Service suggesting or recommending changes to
        the API or the Integration Software, including without limitation, new
        features or functionality relating thereto, or any comments, questions,
        suggestions, or the like (&quot;Feedback&quot;), all such Feedback is
        and will be treated as non-confidential. Customer hereby assign to
        3Shape Design Service on its behalf, and on behalf of its Users,
        employees, contractors, and agents, all right, title, and interest in,
        and 3Shape Design Service is free to use, without any attribution or
        compensation, any ideas, know-how, concepts, techniques, or other
        intellectual property rights contained in the Feedback, for any purpose
        whatsoever. Warranties; Limitation of Liability; Indemnification.
        Customer Warranties. Customer represents and warrants that: (i) the
        Customer System and the provision of the Customer Data to 3Shape Design
        Service will not contain any disabling devices or code which impairs the
        use of the Customer Data by 3Shape Design Service or the Users, or
        otherwise impair the 3Shape Design Service System; (ii) the Customer
        Data and the provision thereof to 3Shape Design Service will conform in
        all material respects to the applicable requirements and specifications
        set forth in the Statement of Work; and (iii) the Customer Data and the
        provision thereof to and the use thereof by 3Shape Design Service does
        not and will not (a) misappropriate or infringe any patent, copyright,
        trademark, trade secret or other intellectual property right of any
        third party, or (b) violate any applicable law, including without
        limitation, the Health Insurance Portability and Accountability Act of
        1996 (&quot;HIPAA&quot;). 3Shape Design Service Warranties. 3Shape
        Design Service represents and warrants that the 3Shape Design Service
        System will not contain any disabling devices or code which is intended
        to impair the Customer System. DISCLAIMER. EXCEPT FOR THE LIMITED
        WARRANTY SET FORTH IN THIS SECTION 5 OR IN THE STATEMENT OF WORK, THE
        API, INTEGRATION SOFTWARE, AND API DOCUMENTATION ARE PROVIDED &quot;AS
        IS&quot; AND 3Shape Design Service HEREBY DISCLAIMS ALL OTHER
        WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. 3Shape
        Design Service SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
        NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING,
        USAGE, OR TRADE PRACTICE. 3Shape Design Service MAKES NO WARRANTY OF ANY
        KIND THAT THE API OR INTEGRATION SOFTWARE OR RESULTS OF THE USE THEREOF,
        WILL MEET CUSTOMER&apos;S, USER&apos;S, OR ANY OTHER PERSON&apos;S
        REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT,
        BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE
        SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
        Indemnification. Customer shall defend, indemnify, and hold harmless
        3Shape Design Service, its affiliates, officers, directors, members,
        managers, employees, agents and customers from and against any and all
        loss, liability, cost, damages and expense, including reasonable
        attorneys&apos; fees, which may be incurred by 3Shape Design Service to
        the extent any claims, actions, or demands by a third party, including
        without limitation, Users, arise out of or relate to: (i) a breach of
        this Agreement by Customer or any of its Users; (ii) Customer&apos;s
        gross negligence, willful misconduct, or willful misrepresentation;
        (iii) violation of applicable law; or (iv) the Customer System, the
        Customer Data or the provision thereof to 3Shape Design Service
        misappropriating or infringing any patent, copyright, trademark, trade
        secret or other intellectual property or proprietary right of any third
        party. EXCEPT WITH RESPECT TO THE INDEMNIFICATION OBLIGATIONS HEREIN,
        NEITHER PARTY WILL BE LIABLE TO THE OTHER FOR ANY INDIRECT, SPECIAL,
        PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR INCIDENTAL LOSSES OR
        DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOST
        SAVINGS OR LOSS OF USE OF FACILITIES OR EQUIPMENT, REGARDLESS OF WHETHER
        ARISING FROM BREACH OF CONTRACT, WARRANTY, TORT, STRICT LIABILITY OR
        OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE, OR
        IF SUCH LOSS OR DAMAGE COULD HAVE BEEN REASONABLY FORESEEN. NEITHER
        PARTY SHALL BE LIABLE FOR ANY FAILURE OR DELAY IN ITS PERFORMANCE DUE TO
        CIRCUMSTANCES BEYOND ITS CONTROL, INCLUDING, BUT NOT LIMITED TO, SYSTEM
        OUTAGES, SOFTWARE FAILURES, ACTS OF CIVIL OR MILITARY AUTHORITY,
        NATIONAL EMERGENCIES, TERRORISM, LABOR DIFFICULTIES, FIRE, FLOOD OR
        CATASTROPHE, ACTS OF GOD, INSURRECTION, WAR OR RIOTS. IN NO EVENT SHALL
        3Shape Design Service&apos;S AGGREGATE LIABILITY UNDER THIS AGREEMENT TO
        CUSTOMER OR TO ANY THIRD PARTY EXCEED FIFTY DOLLARS ($50) UNDER THIS
        AGREEMENT. ANY CLAIM CUSTOMER MAY HAVE ARISING OUT OF OR RELATING TO
        THIS AGREEMENT MUST BE BROUGHT WITHIN THIRTY (30) DAYS, OR A GREATER
        TIME IF REQUIRED UNDER APPLICABLE LAW, AFTER THE OCCURRENCE OF THE EVENT
        GIVING RISE TO SUCH CLAIM. FAILURE TO BRING SUCH CLAIM WITHIN THE
        ALLOTED TIME IN THE FORGOING SENTENCE SHALL BE DEEMED A WAIVER OF SUCH
        CLAIM AND CUSTOMER AGREES AND ACKNOWLEDGES THAT SUCH REQUIREMENT IS
        REASONABLE IN ALL RESPECTS. Confidentiality. Confidential Information.
        The parties acknowledge and agree that all information provided by
        either party under this Agreement to the other party which is marked as
        proprietary or confidential or which from its nature or the context in
        which it is given should reasonably be understood to be confidential
        shall be deemed &quot;Confidential Information&quot; for purposes of
        this Agreement. Notwithstanding the foregoing, &quot;Confidential
        Information&quot; shall not include information which the receiving
        party can demonstrate by its records is (i) already known by the
        receiving party without an obligation of confidentiality; (ii) publicly
        known or that becomes publicly known through no unauthorized act of the
        receiving party; (iii) rightfully received from a third party without an
        obligation of confidentiality; or (iv) independently developed without
        use of the disclosing party&apos;s Confidential Information.
        Obligations. Confidential Information will be held in strict confidence,
        will be used only for purposes of this Agreement and will not be
        disclosed by the receiving party, its agents, subcontractors or
        employees without the prior written consent of the other party. Each
        party shall protect Confidential Information using not less than the
        same care with which it treats its own Confidential Information of a
        like nature, but at all times shall use at least reasonable care.
        Notwithstanding the foregoing, either party may disclose Confidential
        Information to the extent required by law or court order, provided that
        such party promptly provides to the other party prior notice of such
        disclosure and reasonable assistance in obtaining an order protecting
        the Confidential Information from public disclosure. Equitable Remedy.
        The parties acknowledge that any threatened or actual breach of this
        Section shall constitute immediate, irreparable harm to the other party
        for which monetary damages will not be an adequate remedy and as to
        which equitable remedies shall be awarded by a court of competent
        jurisdiction without the requirement of posting a bond or undertaking or
        proving injury as a condition for relief. Confidential Information
        Disclaimer. IN FURNISHING CONFIDENTIAL INFORMATION HEREUNDER, THE
        DISCLOSING PARTY MAKES NO WARRANTY, REPRESENTATION OR GUARANTEE
        WHATSOEVER REGARDING THE COMPLETENESS OR ACCURACY OF SUCH CONFIDENTIAL
        INFORMATION. Term and Termination. Term. This Agreement commences on the
        date of execution of the Statement of Work and shall remain in effect
        until terminated as set forth herein (the &quot;Term&quot;).
        Termination. Customer may terminate this Agreement if 3Shape Design
        Service materially breaches this Agreement and fails to cure such breach
        within thirty (30) days after receiving written notice thereof. 3Shape
        Design Service may immediately terminate or suspend this Agreement, any
        rights granted herein, and/or the licenses under this Agreement, in its
        sole discretion at any time and for any reason, by providing notice to
        Customer or revoking access to the API and/or the Integration Software.
        Effects of Termination; Survival. Upon the termination or expiration of
        this Agreement for any reason: (i) all licenses granted hereunder shall
        terminate; (ii) Customer must cease using, destroy, reconfigure and/or
        reprogram and permanently erase from all Customer&apos;s System it
        directly or indirectly controls all copies of the API, API
        Documentation, and Integration Software; (iii) the parties will work in
        good faith to wind down the parties relationship in an expeditious and
        equitable manner, minimizing disruption in series to Users to the extent
        reasonably practicable; (iv) each party will return all of the
        Confidential Information of the other party or destroy with
        certification to the other party stating that such Confidential
        Information has been destroyed; and (v) Customer will promptly destroy
        any User data as required by applicable law, and only retain any User
        data in which Customer has received the appropriate consent /release and
        or such retention of User data is otherwise permitted under applicable
        law. Any terms that by their nature are intended to continue beyond the
        termination or expiration of this Agreement will survive. Termination
        will not limit any of 3Shape Design Service&apos;s rights or remedies at
        law or in equity. General. Export Regulation. The API and Integration
        Software may be subject to US export control laws, including the Export
        Control Reform Act and its associated regulations. Customer will not,
        directly or indirectly, export, re-export, or release the API and/or
        Integration Software to, or make the API and/or Integration Software
        accessible from, any jurisdiction or country to which export, re-export,
        or release is prohibited by law, rule, or regulation. Customer will
        comply with all applicable federal laws, regulations, and rules, and
        complete all required undertakings (including obtaining any necessary
        export license or other governmental approval), prior to exporting,
        re-exporting, releasing, or otherwise making the and/or Integration
        Software available outside the US. Governing Law. The laws of Arizona,
        excluding its choice of law rules, and applicable federal U.S. laws will
        govern the Agreement. Any legal action or proceeding arising under the
        Agreement will be brought exclusively in the Federal or states courts
        located in Maricopa County in the State of Arizona and the parties
        hereby consent to the personal jurisdiction and venue therein. Each
        party hereby irrevocably and unconditionally waives any objection to the
        laying of venue of any action, suit or proceeding arising out of this
        Agreement in the in the Federal or states courts located in Maricopa
        County in the State of Arizona and hereby further irrevocably and
        unconditionally waives and agrees not to plead or claim in any such
        court that any such action, suit or proceeding brought in any such court
        has been brought in an inconvenient forum. Attorneys&apos; Fees. In the
        event that any suit or action is instituted in relation to this
        Agreement, including without limitation to enforce any provision in this
        Agreement, the prevailing party in such dispute shall be entitled to
        recover from the losing party all fees, costs and expenses of enforcing
        any right of such prevailing party under or with respect to this
        Agreement, including without limitation, such reasonable fees and
        expenses of attorneys and accountants, which shall include, without
        limitation, all fees, costs and expenses of appeals. Notices. All
        notices permitted or required under this Agreement shall be in writing
        and shall be considered given: (i) when delivered personally; (ii) three
        (3) business days after mailing, when sent certified mail, postage
        prepaid, return receipt requested; (iii) one (1) business day after
        mailing when sent via a nationally recognized commercial overnight
        carrier, fees prepaid; or (iv) upon delivery when sent by facsimile
        transmission confirmed by first class mail. All notices will be sent to
        the parties at the addresses as the parties may specify from time to
        time by like notice. Entire Agreement. This Agreement, and the Statement
        of Work, constitutes the entire agreement and understanding between the
        parties hereto with respect to the subject matter hereof and supersedes
        all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, with respect to
        such subject matter. In the event there is a conflict in the terms
        between the Statement of Work and this Agreement, the terms and
        conditions of the Statement of Work shall take precedence with respect
        to the term in conflict. Waiver; Severability. The failure to require
        performance of any provision will not affect a party&apos;s right to
        require performance at any time thereafter; nor will waiver of a breach
        of any provision constitute a waiver of the provision itself or any
        future breach. If any provision or portion thereof is adjudged by a
        court of competent jurisdiction to be unenforceable, invalid or
        otherwise contrary to law, such provision or portion thereof will be
        interpreted so as to best accomplish its intended objectives and the
        remaining provisions will remain in full force and effect. Independent
        Contractors. The parties hereto are and will remain independent
        contractors and nothing herein will be deemed to create any agency,
        partnership, or joint venture relationship between the parties. Neither
        party will be deemed to be an employee or legal representative of the
        other nor will either party have any right or authority to create any
        obligation on behalf of the other party. Assignment. Customer will not
        assign or otherwise transfer its rights or delegate its obligations
        under the Agreement, in whole or in part, without the prior written
        consent of 3Shape Design Service, and any attempt to do so will be null
        and void. Subject to the foregoing, this Agreement will be binding on
        and inure to the benefit of each of the parties and their respective
        successors and permitted assigns. Counterparts. This Agreement may be
        executed in counterparts, including facsimile or electronically
        transmitted counterparts, each of which will be deemed an original and
        all of which when taken together will constitute one and the same
        instrument. Strict Construction. The parties, by executing below,
        acknowledge that the provisions and language of this Agreement have been
        negotiated by both parties and specifically agree that no provision of
        the Agreement shall be construed against a party by reason of such party
        having drafted such provision or the Agreement. Headings. The headings
        appearing herein are inserted only as a matter of convenience and as a
        reference, and in no way define, limit or describe the scope or intent
        of the applicable clause or this Agreement. Binding Obligation. This
        Agreement creates legal and binding contractual obligations between
        Customer and 3Shape Design Service. The rights, benefits and remedies of
        3Shape Design Service created by or through this Agreement may be
        enjoyed and enforced against Customer directly by 3Shape Design Service
        and/or any of 3Shape Design Service’s affiliates (either severally or
        jointly), as 3Shape Design Service at any time or from time to time may
        elect or determine, each such affiliate of 3Shape Design Service being
        an express third-party beneficiary of all such rights, benefits and
        remedies. However, Customer&apos;s contractual relationship created by
        this Agreement is solely with 3Shape Design Service, and creates no
        rights, benefits or remedies in Customers favor against any affiliate of
        3Shape Design Service or any other third party.
      </p>
    </div>
  );
}

export default ApiAgreement;
