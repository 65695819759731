import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import GuideSelectionPopup from './GuideSelectionPopup';

function GuideSelection({ index, selectedDesignType = null, margin = null }) {
  const { values } = useFormikContext();
  const { orderItems = [] } = values;
  return (
    <div
      style={{
        display: 'inline-block',
        verticalAlign: 'top',
        marginLeft: margin ? '8px' : '0',
        flexBasis: '25px',
        flexShrink: 0,
      }}
    >
      <GuideSelectionPopup
        designGuideCategories={selectedDesignType.designGuideCategories}
        currentOrderItem={orderItems[index]}
        index={index}
      />
    </div>
  );
}

GuideSelection.propTypes = {
  selectedDesignType: PropTypes.object,
  margin: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default GuideSelection;
