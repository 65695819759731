/* eslint-disable class-methods-use-this */
import PropTypes from 'prop-types';
import { Component } from 'react';
import OrdersListGrid from '../../../../Orders/OrdersList/OrdersListGrid';

class ResultsOrderCustomerGrid extends Component {
  onGridReady = (params) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.setColumnsFromStorage();
  };

  setColumnsFromStorage = () => {
    const oColumns = localStorage.getItem('oColumns');

    if (oColumns) {
      const columns = JSON.parse(oColumns);
      const hidden = columns.filter((col) => col.visible === false);

      hidden.forEach((col) => {
        this.gridColumnApi.setColumnVisible(col.colId, false);
      });
    } else {
      const allGridCols = this.gridColumnApi
        .getAllColumns()
        .filter((col) => col.visible === true && col.colId !== '0')
        .map((col) => ({
          colId: col.colId,
          visible: col.visible,
        }));

      localStorage.setItem('oColumns', JSON.stringify(allGridCols));
    }
  };

  onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onColumnVisible = (params) => {
    params.api.sizeColumnsToFit();

    const oColumns = localStorage.getItem('oColumns');

    if (oColumns) {
      const columns = JSON.parse(oColumns);
      const pCols = params.columns;

      pCols.forEach((pCol) => {
        const foundIndex = columns.findIndex((col) => col.colId === pCol.colId);

        if (foundIndex !== -1) {
          columns[foundIndex] = {
            ...columns[foundIndex],
            visible: pCol.visible,
          };
        }

        if (foundIndex === -1) {
          columns.push({ colId: pCol.colId, visible: pCol.visible });
        }
      });

      localStorage.setItem('oColumns', JSON.stringify(columns));
    }
  };

  onSelectionChanged = (event) => {
    this.setSelectedCases(event.api.getSelectedNodes());
  };

  onDisplayedColumnsChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  setSelectedCases = () => {
    // reintroduce functionality if needed
  };

  render() {
    const { reportDetails, gridOptions } = this.props;

    return (
      <OrdersListGrid
        gridOptions={gridOptions}
        rowData={reportDetails}
        onGridReady={this.onGridReady}
        onGridSizeChanged={this.onGridSizeChanged}
        onFirstDataRendered={this.onFirstDataRendered}
        onColumnVisible={this.onColumnVisible}
        onSelectionChanged={this.onSelectionChanged}
        onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
      />
    );
  }
}

ResultsOrderCustomerGrid.propTypes = {
  reportDetails: PropTypes.array.isRequired,
  gridOptions: PropTypes.object.isRequired,
};

export default ResultsOrderCustomerGrid;
