import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../../../../../transforms';
import { LanguageContext } from '../../../Context';

function LanguageDropdownSignIn() {
  const { locale, changeDefaultLanguage } = useContext(LanguageContext);
  const languages = languageOptions[locale];
  const { t } = useTranslation('formfields');

  function handleChange(e) {
    changeDefaultLanguage(e.target.value);
  }
  return (
    <div
      className={`select ${!languages ? 'is-loading' : ''}`}
      style={{ width: '100%' }}
    >
      <select
        onChange={handleChange}
        value={!locale ? `${t('Select a Language')}...` : locale}
        id="languageDropdownSignIn"
        name="languageDropdownSignIn"
        disabled={!languages}
        style={{ width: '100%' }}
      >
        <option disabled>{`${t('Select a Language')}...`}</option>
        {languages?.map((l) => (
          <option key={l.value} value={l.value}>
            {l.text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default memo(LanguageDropdownSignIn);
