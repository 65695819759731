import LegalShowNavMenu from '../../shared/NavMenus/Legal/LegalShowNavMenu';

const routes = [
  {
    path: ['/legal', '/legal/:type'],
    component: LegalShowNavMenu,
  },
];

export default routes;
