function initialValues(props) {
  const { currentUser } = props;

  return {
    id: currentUser.username || '',
    firstName: currentUser.name || '',
    lastName: currentUser.family_name || '',
    language: currentUser.language || 'en',
    preferredUsername: currentUser.preferred_username || '',
    phone: currentUser.phone_number || '',
  };
}

export default initialValues;
