import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../../../../transforms';

function FileViewStl(props) {
  const { selectedFile, toggleVersions } = props;

  return (
    <p style={{ marginTop: '1em', wordBreak: 'break-all' }}>
      <b>Name:</b> {selectedFile.originalFileName}
      <br />
      <b>Date Created:</b> {timestampFormatter(selectedFile.createdAt)}
      {toggleVersions && (
        <>
          <br />
          <b>Version:</b> {selectedFile.ver}
        </>
      )}
    </p>
  );
}

FileViewStl.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
};

export default FileViewStl;
