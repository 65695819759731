const matchedObjFiles = (latestObjs, redesignCount) => {
  const isObjFileCountMatchOrderCount = latestObjs.some(
    (file) => file.redesignCount === redesignCount,
  );

  let objFilesFromRedesignVersion = [];

  if (isObjFileCountMatchOrderCount) {
    // if it does then we show obj files which matches to orders redesign count
    objFilesFromRedesignVersion = latestObjs.filter(
      (item) => item.redesignCount === redesignCount,
    );
  } else {
    // otherwise find the latest redesign version of the obj file
    const highestRedesignCountAvailable = latestObjs.reduce(
      (count, file) =>
        file.redesignCount > count ? file.redesignCount : count,
      0,
    );

    // and filter all obj files according to last redesign count
    objFilesFromRedesignVersion = latestObjs.filter(
      (file) => file.redesignCount === highestRedesignCountAvailable,
    );
  }

  return objFilesFromRedesignVersion;
};

export default matchedObjFiles;
