import PropTypes from 'prop-types';
import { useState } from 'react';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingInvoicePostPaymentFormModal from './BillingInvoicePostPaymentFormModal';

function BillingInvoicePostPaymentForm({ selectedItem = null, refetch }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <BasicDropdownItem
        itemText="Post Payment"
        clickHandler={() => setModalOpen(true)}
        disabled={selectedItem && selectedItem.state === 'paid'}
      />
      {selectedItem && (
        <BillingInvoicePostPaymentFormModal
          modalOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          selectedItem={selectedItem}
          refetch={refetch}
          maxAmount={parseFloat(selectedItem.balanceDue)}
        />
      )}
    </>
  );
}

BillingInvoicePostPaymentForm.propTypes = {
  selectedItem: PropTypes.object,
  refetch: PropTypes.func.isRequired,
};

export default BillingInvoicePostPaymentForm;
