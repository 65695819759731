import { useApolloClient } from '@apollo/client';
import { DESTROY_CREDIT, UPDATE_CREDIT_STATUS } from '@fullcontour/shared-api';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../config/log';
import { CreateNotification } from '../../../shared';

function useCredit() {
  const client = useApolloClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [action, setAction] = useState(null);
  const [credit, setCredit] = useState({});

  const closeModal = () => {
    setAction(null);
    setCredit({});
    setModalState(false);
  };

  const openModal = (act, cred) => {
    setAction(act);
    setCredit(cred);
    setModalState(true);
  };

  const destroyCredit = async (cred, refetch) => {
    await client.mutate({
      mutation: DESTROY_CREDIT,
      variables: {
        input: {
          id: cred.id,
          clientMutationId: uuidv4(),
        },
      },
    });

    closeModal();
    refetch();
  };

  const statusUpdate = (statusAction, cred, refetch) => {
    client
      .mutate({
        mutation: UPDATE_CREDIT_STATUS,
        variables: {
          input: {
            input: {
              id: cred.id,
              statusAction,
            },
            clientMutationId: uuidv4(),
          },
        },
      })
      .then(() => {
        CreateNotification.createNotification({
          type: 'success',
          message: `Status was changed to "${statusAction}"`,
        });
        refetch();
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const onSelectionChanged = (event) => {
    const selectedItems = event.api.getSelectedNodes();
    const selected = selectedItems.length > 0 ? selectedItems[0].data : null;

    setSelectedItem(selected);
  };

  return {
    selectedItem,
    setSelectedItem,
    action,
    credit,
    closeModal,
    openModal,
    destroyCredit,
    statusUpdate,
    onSelectionChanged,
    modalState,
  };
}

export default useCredit;
