import { gql } from '@apollo/client';
import { AdminMessageInfo } from '../fragments';

export default gql`
  mutation destroyAdminMessage($input: AdminMessageDestroyInput!) {
    destroyAdminMessage(input: $input) {
      clientMutationId
      adminMessage {
        ...AdminMessageInfo
      }
    }
  }
  ${AdminMessageInfo}
`;
