import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BillingDetailsHeader(props) {
  const { customer } = props;

  const { t } = useTranslation('billing');

  return (
    <h4 className="title is-4 mb-6">
      {t('Billing Details for')} {customer.name}
    </h4>
  );
}

BillingDetailsHeader.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default BillingDetailsHeader;
