import PropTypes from 'prop-types';
import { memo } from 'react';
import ShowDetailsSubText from './ShowDetailsSubText';

function ShowDetails({ subHeader = null, subHeaderLabel = null }) {
  return (
    <div>
      <ShowDetailsSubText
        subHeader={subHeader}
        subHeaderLabel={subHeaderLabel}
      />
    </div>
  );
}

ShowDetails.propTypes = {
  subHeader: PropTypes.node,
  subHeaderLabel: PropTypes.string,
};

export default memo(ShowDetails);
