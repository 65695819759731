function fileIcon(filename) {
  if (
    filename.endsWith('.jpg') ||
    filename.endsWith('.png') ||
    filename.endsWith('.jpeg')
  ) {
    return 'bx-image';
  }

  if (
    filename.endsWith('.otp') ||
    filename.endsWith('.diff') ||
    filename.endsWith('.3sz')
  ) {
    return 'bxs-file';
  }

  if (filename.endsWith('.mp4') || filename.endsWith('.mov')) {
    return 'bx-video';
  }

  if (filename.endsWith('.pdf')) {
    return 'bxs-file-pdf';
  }

  if (filename.endsWith('.json')) {
    return 'bxs-file-json';
  }

  if (filename.endsWith('.zip')) {
    return 'bxs-file-archive';
  }

  if (
    filename.endsWith('.obj') ||
    filename.endsWith('.stl') ||
    filename.endsWith('.dcm')
  ) {
    return 'bxs-cube';
  }

  if (filename.endsWith('.mtl')) {
    return 'bx-cube';
  }

  if (filename.endsWith('.pts')) {
    return 'bx-file-blank';
  }

  if (filename.endsWith('.svg')) {
    return 'bxs-file-image';
  }

  if (filename.endsWith('.xml')) {
    return 'bx-code-curly';
  }

  return 'bx-folder';
}

export default fileIcon;
