import { gql } from '@apollo/client';
import { ErrorMessageInfo, InvoiceInfo } from '../fragments';

export default gql`
  mutation updateInvoiceMemo($input: InvoiceMemoUpdateInput!) {
    updateInvoiceMemo(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      invoice {
        ...InvoiceInfo
      }
    }
  }
  ${InvoiceInfo}
  ${ErrorMessageInfo}
`;
