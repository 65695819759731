import { BaseDefinition } from '..';

class ZipDefinition3ShapeManuf extends BaseDefinition {
  buildCase = ({ ...args }) => {
    const { mappedZipFiles, unzippedFiles } = args;

    this.mappedZipFiles = mappedZipFiles;
    this.unzippedFiles = unzippedFiles;

    return this.parseScan();
  };

  parseScan = () => {
    const { order } = this.parsedCase;
    const { files, software, ...restOrder } = order;
    const updatedSoftware = '3Shape Manufacturing Scan';
    const updatedFiles = [
      {
        originalFileName: this.file.name,
        fileType: 'manufacturing_scans',
        fileUrl: null,
      },
    ];

    const updatedOrder = {
      ...restOrder,
      software: updatedSoftware,
      files: updatedFiles,
    };

    const file = {
      fileType: 'manufacturing_scans',
      file: this.file,
    };

    const metaData = {
      isParsed: true,
      softwareCompany: '3Shape',
      manufacturer: true,
    };

    return { order: updatedOrder, file, metaData };
  };
}

export default ZipDefinition3ShapeManuf;
