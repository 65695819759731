import { CREATE_CREDIT_CARD } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
  refetchCustomerLocation,
}) {
  const result = await client.mutate({
    mutation: CREATE_CREDIT_CARD,
    variables,
  });
  const { createCreditCard } = result.data;
  if (createCreditCard.errorMessages.length) {
    setErrorMessages(createCreditCard.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    refetchCustomerLocation();
    closeModal();
  }
}
export default create;
