// import { useReactiveVar } from '@apollo/client';
import {
  overpanelCasesData,
  OverpanelFilesContext,
  // sendingOrders,
} from '@fullcontour/common';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function OverpanelHeaderRemoveAll() {
  const { files, removeAllFiles, overpanelOpen } = useContext(
    OverpanelFilesContext,
  );

  const { t } = useTranslation('designProfile');

  const removeFiles = () => {
    removeAllFiles();
    overpanelCasesData({});
  };

  // const submittingOrders = useReactiveVar(sendingOrders);
  return overpanelOpen && overpanelOpen !== 'pending' && files.length > 0 ? (
    <button
      type="button"
      className="button is-small mr-3 is-danger is-outlined"
      onClick={removeFiles}
    >
      <span className="icon">
        <i className="bx bx-trash" />
      </span>
      <span>{t('Remove All')}</span>
    </button>
  ) : null;
}

export default OverpanelHeaderRemoveAll;
