import PropTypes from 'prop-types';
import { memo } from 'react';

import ShowDetailsDateLabel from './ShowDetailsDateLabel';

function ShowDetailsDates({ createdAt = null, updatedAt = null }) {
  return (
    <>
      {createdAt && (
        <ShowDetailsDateLabel
          date={createdAt}
          style={{ marginBottom: 10 }}
          color="is-dark"
        />
      )}
      {updatedAt && (
        <ShowDetailsDateLabel
          date={updatedAt}
          style={{ marginBottom: 10 }}
          color="is-dark"
          created={false}
        />
      )}
    </>
  );
}

ShowDetailsDates.propTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default memo(ShowDetailsDates);
