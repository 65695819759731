import { useGuideCodeCardContext } from './context/GuideCodeCardContext';

function GuideCodeCardImage() {
  const { data } = useGuideCodeCardContext();

  return !data.category.showDropdown ? (
    <div className="card-image" style={{ height: 140, flex: 'none' }}>
      <figure className="image mx-auto is-flex my-0" style={{ height: '100%' }}>
        <img
          src={
            data.imageUrl ||
            'https://s3-us-west-2.amazonaws.com/fc2-development/assets/images/l/default.svg'
          }
          style={{ objectFit: 'contain' }}
          alt="card"
        />
      </figure>
    </div>
  ) : null;
}

export default GuideCodeCardImage;
