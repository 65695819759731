import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { statusColor } from '../../../../../../transforms';

function HeaderInfoPercentComplete(props) {
  const { state, isRush } = props;
  const color = statusColor(state, isRush);

  const { t } = useTranslation('navigation');

  return (
    <p
      className={`has-text-${color} mb-1 has-text-weight-bold`}
      style={{
        textTransform: 'capitalize',
        width: '100%',
      }}
    >
      <small>{t(state.replace(/_/g, ' '))}</small>
    </p>
  );
}

HeaderInfoPercentComplete.propTypes = {
  state: PropTypes.string.isRequired,
  isRush: PropTypes.bool.isRequired,
};

export default HeaderInfoPercentComplete;
