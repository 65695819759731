import { gql } from '@apollo/client';

export default gql`
  query siloRoutes {
    siloRoutes {
      id
      designTypeCategory {
        id
        slug
        name
      }
      customerLocation {
        id
        slug
        name
      }
      primarySilo {
        id
        name
        slug
      }
      secondarySilo {
        id
        name
        slug
      }
      catchAllSilo {
        id
        name
        slug
      }
    }
  }
`;
