import { gql } from '@apollo/client';
import { CustomerLocationInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCustomerLocation($input: CustomerLocationCreateInput!) {
    createCustomerLocation(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerLocation {
        ...CustomerLocationInfo
      }
    }
  }
  ${CustomerLocationInfo}
  ${ErrorMessageInfo}
`;
