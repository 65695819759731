import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderInfo } from '../fragments';

export default gql`
  mutation actionReassignSilo($input: ReassignSiloActionInput!) {
    actionReassignSilo(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orders {
        ...OrderInfo
      }
    }
  }
  ${OrderInfo}
  ${ErrorMessageInfo}
`;
