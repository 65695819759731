import { gql } from '@apollo/client';

export default gql`
  mutation destroyIntegration($input: IntegrationDestroyInput!) {
    destroyIntegration(input: $input) {
      clientMutationId
      integration {
        id
      }
    }
  }
`;
