import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BoxIcon from '../../../Icons/BoxIcon';

function OrderShowNavLink({ name, icon }) {
  const { t } = useTranslation('navigation');
  return (
    <>
      <div className="has-text-centered">
        <BoxIcon name={`bx-md ${icon}`} />
      </div>

      <p>{t(name)}</p>
    </>
  );
}

OrderShowNavLink.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default OrderShowNavLink;
