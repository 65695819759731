import * as Yup from 'yup';

function schema() {
  return Yup.object({
    reportTypeId: Yup.string().required('Report Type is Required'),
    reportTimeFrameId: Yup.string().required('Report Time Frame is Required'),
    singleDate: Yup.string().nullable(true),
    hexString: Yup.string().nullable(true),
    amount: Yup.number().nullable(true),
    customStart: Yup.string().nullable(true),
    customEnd: Yup.string().nullable(true),
    adminLocationIds: Yup.array().of(Yup.string()),
    customerOrganizationIds: Yup.array().of(Yup.string()),
    customerLocationIds: Yup.array().of(Yup.string()),
    customerTagIds: Yup.array().of(Yup.string()),
    designTypeIds: Yup.array().of(Yup.string()),
    turnaroundTimeIds: Yup.array().of(Yup.string()),
  });
}

export default schema;
