import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TempOrderActionsButtonRemoveItem({ removeTempOrder, tempOrder }) {
  const { t } = useTranslation('orders');

  return (
    <button
      type="button"
      className="button is-small mt-1 px-5 has-tooltip-arrow has-tooltip-left is-danger is-outlined"
      onClick={() => removeTempOrder(tempOrder.id)}
      data-tooltip={t('Remove this Case')}
    >
      <span className="icon">
        <i className="bx bx-trash" />
      </span>
    </button>
  );
}

TempOrderActionsButtonRemoveItem.propTypes = {
  removeTempOrder: PropTypes.func.isRequired,
  tempOrder: PropTypes.object.isRequired,
};

export default TempOrderActionsButtonRemoveItem;
