import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHelpers } from '../../../../config/apollo';
import { OverpanelDrawerContext } from '../../Context';
import Dropdown from './Dropdown';

function TurnaroundDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  disabled = false,
  turnaroundTimes,
  size = '',
}) {
  const helpFuncsData = useReactiveVar(getHelpers);

  const { t } = useTranslation('formfields');
  const [loading, setLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);

  const { setDrawerBody, toggle } = useContext(OverpanelDrawerContext);

  // eslint-disable-next-line no-unused-vars
  const infoClick = () => {
    setDrawerBody(
      `For some orders you can select between different turnaround times. Keep
      in mind that the faster the turnaround time, the more your order will
      cost. You can see the prices for each indication and turnaround time on
      your price list, which is found in the Location section of the platform.`,
    );
    toggle();
  };

  useEffect(() => {
    const filteredTimes = turnaroundTimes.map((time) => {
      const { defaultTurnaroundTime, ...rest } = time;
      return {
        ...rest,
        name: rest.text,
      };
    });
    setLoading(false);
    setLoadedData(filteredTimes);
  }, [turnaroundTimes]);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    if (helpFuncsData) {
      helpFuncsData.helpFuncs.next();
    }
  };

  return (
    <Dropdown
      name={name}
      // infoCallback={infoClick}
      label="Turnaround Time"
      errors={!!(errors.roleId && touched.roleId)}
      loading={loading}
      options={loadedData}
      onChange={(e) => handleChange(e)}
      placeholder={t('Select a Turnaround Time')}
      value={value}
      disabled={disabled || loading}
      required
      size={size}
    />
  );
}

TurnaroundDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  turnaroundTimes: PropTypes.array.isRequired,
  size: PropTypes.string,
};

export default memo(TurnaroundDropdown);
