import UserFormCustomer from '../../views/Users/UserForms/UserFormCustomer';
import UserShowInner from '../../views/Users/UserShow/UserShowInner';
import UsersListDataTable from '../../views/Users/UsersList/UsersListDataTable';

const routes = [
  {
    path: '/:root(users)',
    component: UsersListDataTable,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users){/:id}?/:action(edit|new)',
    component: UserFormCustomer,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users)/:id',
    component: UserShowInner,
    sl: true,
    so: true,
  },
];

export default routes;
