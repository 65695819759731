import PropTypes from 'prop-types';
import './FieldMetadata.css';
import FieldMetadataArches from './FieldMetadataArches';
import FieldMetadataDesignGuide from './FieldMetadataDesignGuide';
import FieldMetadataModifiers from './FieldMetadataModifiers';
import FieldMetadataTeeth from './FieldMetadataTeeth';

function FieldMetaData({
  numberingSystem,
  orderItem,
  customerLocation,
  index,
  selectedDesignType = null,
  labLocationGuideCategoryGroups = null,
}) {
  return (
    <div className="columns is-gapless" style={{ minWidth: '50%' }}>
      <div className="column">
        {selectedDesignType?.toothChart ||
        (!selectedDesignType?.toothChart && !selectedDesignType?.archChart) ? (
          <FieldMetadataTeeth
            orderItem={orderItem}
            selectedDesignType={selectedDesignType}
            numberingSystem={numberingSystem}
            index={index}
            customerLocation={customerLocation}
          />
        ) : (
          <FieldMetadataArches
            orderItem={orderItem}
            selectedDesignType={selectedDesignType}
            customerLocation={customerLocation}
            numberingSystem={numberingSystem}
            index={index}
          />
        )}
      </div>
      <div className="column is-6">
        <FieldMetadataDesignGuide
          orderItem={orderItem}
          index={index}
          selectedDesignType={selectedDesignType}
          labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
        />
      </div>
      <div className="column">
        <FieldMetadataModifiers
          orderItem={orderItem}
          index={index}
          selectedDesignType={selectedDesignType}
        />
      </div>
    </div>
  );
}

FieldMetaData.propTypes = {
  orderItem: PropTypes.object.isRequired,
  numberingSystem: PropTypes.string.isRequired,
  selectedDesignType: PropTypes.object,
  index: PropTypes.number.isRequired,
  customerLocation: PropTypes.object.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array,
};

export default FieldMetaData;
