import { gql } from '@apollo/client';

const IntegrationInfo = gql`
  fragment IntegrationInfo on Integration {
    id
    active
    credentials
    integrationTypeId
    integrationType {
      id
      name
      text
      symbol
    }
  }
`;

export default IntegrationInfo;
