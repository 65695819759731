/* eslint react/no-danger: 0 */
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { BaseLoader } from '../../shared';
import BusinessAssociatesAgreement from './Policies/BusinessAssociatesAgreement';
import PrivacyPolicy from './Policies/PrivacyPolicy';
import TermsAndConditions from './Policies/TermsAndConditions';
import TermsAndConditionsSmile from './Policies/TermsAndConditionsSmile';

function DisplayPolicy({ policy }) {
  if (policy === '3Shape Design Service Business Associate Agreement') {
    return <BusinessAssociatesAgreement />;
  }

  if (policy === '3Shape Design Service Privacy Policy') {
    return <PrivacyPolicy />;
  }

  if (policy === 'SmileSummary Terms and Conditions') {
    return <TermsAndConditionsSmile />;
  }

  return <TermsAndConditions />;
}

function Policy({ policy }) {
  return (
    <Suspense fallback={<BaseLoader />}>
      <DisplayPolicy policy={policy} />
    </Suspense>
  );
}

Policy.propTypes = {
  policy: PropTypes.string.isRequired,
};

DisplayPolicy.propTypes = {
  policy: PropTypes.string.isRequired,
};

export default Policy;
