import { gql } from '@apollo/client';
import { OrderInfoListDesigner } from '../fragments';

export const ORDERS_DESIGNER_SENT_FOR_DESIGN = gql`
  query sentForDesignOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    sentForDesignOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_DESIGN_IN_PROGRESS = gql`
  query designInProgressOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    designInProgressOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_RUSH_DESIGN = gql`
  query rushDesignOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    rushDesignOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_ON_HOLD = gql`
  query onHoldOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    onHoldOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_HOLD_RELEASED = gql`
  query holdReleasedOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    holdReleasedOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_REDESIGN = gql`
  query redesignOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    redesignOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_NEEDS_APPROVAL = gql`
  query needsApprovalOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    needsApprovalOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_SENT_FOR_APPROVAL = gql`
  query sentForApprovalOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    sentForApprovalOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_SENT_TO_MANUFACTURER = gql`
  query sentToManufacturerOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    sentToManufacturerOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_AT_MANUFACTURER = gql`
  query atManufacturerOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    atManufacturerOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_MANUFACTURING_HOLD = gql`
  query manufacturingHoldOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    manufacturingHoldOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_SHIPPED = gql`
  query shippedOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    shippedOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_READY_FOR_DOWNLOAD = gql`
  query readyForDownloadOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    readyForDownloadOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_CANCELLED = gql`
  query cancelledOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    cancelledOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER_COMPLETE = gql`
  query completeOrders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    completeOrders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
export const ORDERS_DESIGNER = gql`
  query orders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Int!]
  ) {
    orders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfoListDesigner
    }
  }
  ${OrderInfoListDesigner}
`;
