import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FeedbackStatusButtons({
  setBodyShow,
  currentFeedback = null,
  sendFeedback,
}) {
  const { t } = useTranslation('common');

  return (
    <div className="is-flex is-align-items-center">
      {t('Give us feedback')}
      <p className="buttons ml-2">
        <button
          type="button"
          className="button is-rounded p-4"
          onClick={() =>
            currentFeedback?.rating !== 5
              ? sendFeedback({ isPositive: true })
              : null
          }
        >
          <span className="icon is-small">
            <i
              className={`bx ${
                currentFeedback?.rating === 5
                  ? 'bxs-like has-text-primary'
                  : 'bx-like'
              }`}
            />
          </span>
        </button>
        <button
          type="button"
          className="button is-rounded p-4"
          onClick={() => setBodyShow((prev) => !prev)}
        >
          <span className="icon is-small">
            <i
              className={`bx ${
                currentFeedback?.rating === 0
                  ? 'bxs-dislike has-text-danger'
                  : 'bx-dislike'
              }`}
            />
          </span>
        </button>
      </p>
    </div>
  );
}

FeedbackStatusButtons.propTypes = {
  setBodyShow: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  currentFeedback: PropTypes.object,
};

export default FeedbackStatusButtons;
