import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ObjElementGingiva from '../ObjElements/ObjElementGingiva/ObjElementGingiva';
import ObjElementTooth from '../ObjElements/ObjElementTooth/ObjElementTooth';
import ObjElementToothOverlay from '../ObjElements/ObjElementTooth/ObjElementToothOverlay';

function ObjGroupInner({
  files,
  file,
  file: { id, originalFileName },
  index,
  overlay = false,
  iprTeeth = null,
}) {
  const lowerCaseName = useMemo(
    () => originalFileName.toLowerCase(),
    [originalFileName],
  );
  const rawName = useMemo(
    () => originalFileName.split('.obj')[0],
    [originalFileName],
  );

  const unn = useMemo(
    () => parseInt(originalFileName?.match(/\d+/gi), 10),
    [originalFileName],
  );

  const isUpper = useMemo(
    () => lowerCaseName.includes('upper'),
    [lowerCaseName],
  );

  const isLower = useMemo(
    () => lowerCaseName.includes('lower'),
    [lowerCaseName],
  );

  if (lowerCaseName.includes('attachment')) {
    return null;
  }

  if (lowerCaseName.includes('gingiva') && !overlay) {
    return (
      <ObjElementGingiva
        key={id}
        index={index}
        isUpper={isUpper}
        isLower={isLower}
        name={rawName}
      />
    );
  }

  /*
  // DO NOT SHOW GINGIVA OVERLAY
  if (lowerCaseName.includes('gingiva') && overlay) {
    return (
      <ObjElementGingivaOverlay
        key={id}
        index={index}
        isUpper={isUpper}
        isLower={isLower}
        name={rawName}
      />
    );
  }
  */

  if (!lowerCaseName.includes('gingiva') && !overlay) {
    return (
      <ObjElementTooth
        key={id}
        file={file}
        index={index}
        unn={unn}
        name={rawName}
        isUpper={isUpper}
        isLower={isLower}
        files={files}
        iprTeeth={iprTeeth}
      />
    );
  }

  if (!lowerCaseName.includes('gingiva') && overlay) {
    return (
      <ObjElementToothOverlay
        key={id}
        file={file}
        index={index}
        unn={unn}
        name={rawName}
        isUpper={isUpper}
        isLower={isLower}
      />
    );
  }

  return null;
}

ObjGroupInner.propTypes = {
  files: PropTypes.array.isRequired,
  file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  iprTeeth: PropTypes.object,
  overlay: PropTypes.bool,
};

export default ObjGroupInner;
