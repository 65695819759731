import { gql } from '@apollo/client';
import { PriceListInfo } from '../fragments';

export default gql`
  query priceLists($name: String) {
    priceLists(name: $name) {
      ...PriceListInfo
    }
  }
  ${PriceListInfo}
`;
