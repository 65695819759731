/* eslint-disable no-throw-literal */
import { useMutation } from '@apollo/client';
import { CREATE_GUIDE_CUSTOM_LOCATION } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { handleTryCatch } from '@fullcontour/common';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';
import updateCache from './updateCache';

const useCreateNewCode = (value) => {
  const { slug } = useParams();
  const { data } = useGuideCodeCardContext();
  const { guideCategoryGroupId, designType, category } = data;
  const [createCode, { loading }] = useMutation(CREATE_GUIDE_CUSTOM_LOCATION, {
    update: (
      cache,
      {
        data: {
          createGuideCustomCode: { guideCustomCode },
        },
      },
    ) => {
      cache.modify({
        fields: {
          labLocationGuideCategoryGroups(
            existingLabLocationGuideCategoryGroupsRefs = [],
          ) {
            return updateCache({
              existingLabLocationGuideCategoryGroupsRefs,
              guideCodeId: guideCustomCode.id,
              name: guideCustomCode.name,
              guideCategoryGroupId,
              guideCategoryId: category.id,
              designTypeId: designType.designTypeId,
            });
          },
        },
      });
    },
  });

  const createNewCode = handleTryCatch(
    async () => {
      const isCodeExists = category.guideCodes.some(
        (item) => item.name === value,
      );

      if (isCodeExists) {
        throw { customText: 'Guide code already exists' };
      }
      const variablesCreateCode = {
        input: {
          input: {
            guideCategoryId: category.id,
            name: value,
            ...(slug ? { locationSlug: slug } : {}),
          },
        },
      };
      const {
        data: {
          createGuideCustomCode: {
            guideCustomCode: { id },
          },
        },
      } = await createCode({ variables: variablesCreateCode });

      return id;
    },
    {
      successText: `Guide Code was successfully created`,
      errorText: 'Guide code was not created',
    },
  );

  return { createNewCode, loading };
};

export default useCreateNewCode;
