import { gql } from '@apollo/client';
import { ErrorMessageInfo, ManufacturerOrganizationInfo } from '../fragments';

export default gql`
  mutation updateManufacturerOrganization(
    $input: ManufacturerOrganizationUpdateInput!
  ) {
    updateManufacturerOrganization(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      manufacturerOrganization {
        ...ManufacturerOrganizationInfo
      }
    }
  }
  ${ManufacturerOrganizationInfo}
  ${ErrorMessageInfo}
`;
