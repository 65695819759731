import { useTranslation } from 'react-i18next';

function TeethChartLegend() {
  const { t } = useTranslation('orderactions');
  return (
    <div className="teeth-chart__legend">
      <div className="teeth-chart__legend-wrapper">
        {t('Inter-Proximal Reduction (mm)')}:
        <div className="teeth-chart__legend-IR" />
      </div>
      <div className="teeth-chart__legend-wrapper">
        {t('Attachments')}:
        <div className="teeth-chart__legend-attachment" />
      </div>
      <div className="teeth-chart__legend-wrapper">
        {t('Missing tooth')}:
        <div className="teeth-chart__missing-tooth teeth-chart__missing-tooth-margin" />
      </div>
    </div>
  );
}

export default TeethChartLegend;
