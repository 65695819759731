import { gql } from '@apollo/client';

export default gql`
  query customerOrganization($slug: String, $id: ID) {
    customerOrganization(slug: $slug, id: $id) {
      id
      locations {
        id
        name
        value
      }
    }
  }
`;
