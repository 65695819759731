import { gql } from '@apollo/client';
import { ModifierInfo } from '../fragments';

export default gql`
  query modifier($slug: String, $id: ID) {
    modifier(slug: $slug, id: $id) {
      ...ModifierInfo
    }
  }
  ${ModifierInfo}
`;
