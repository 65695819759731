import * as Yup from 'yup';

function schema(props) {
  const { tempOrder = {} } = props;
  return Yup.object({
    id: Yup.string().required(),
    intOrderId: Yup.string().required(),
    [`name${tempOrder.id}`]: Yup.string(),
    [`turnaroundTimeId${tempOrder.id}`]: Yup.string().required(
      'Turnaround time is required',
    ),
    [`instructions${tempOrder.id}`]: Yup.string(),
    [`requiresDesignApproval${tempOrder.id}`]: Yup.boolean().required(),
    software: Yup.string().required(),
    source: Yup.string(),
    orderItems: Yup.array()
      .of(
        Yup.object({
          designTypeId: Yup.string().min(5).required('Design Type is Required'),
          manufacturerOrganizationId: Yup.string()
            .min(5)
            .required('Destination is Required'),
          units: Yup.number().required(),
          teeth: Yup.array().of(Yup.number()),
          arches: Yup.array().of(Yup.string()),
          guideCodes: Yup.array().of(
            Yup.object({
              categoryId: Yup.string(),
              categoryName: Yup.string(),
              codes: Yup.array(),
            }),
          ),
          materialName: Yup.string(),
          splitFile: Yup.boolean().required(),
        }),
      )
      .required(),
    files: Yup.array()
      .of(
        Yup.object({
          fileUrl: Yup.string().required('File Url is required'),
          originalFileName: Yup.string().required(
            'Original file name is required',
          ),
          fileType: Yup.string().required('File Type is required'),
        }),
      )
      .required(),
  });
}

export default schema;
