import { graphql } from '@apollo/client/react/hoc';
import {
  CreateNotification,
  sendingOrders,
  UpdateCache,
} from '@fullcontour/common';
import {
  CREATE_CUSTOMER_ORDER,
  GET_CUSTOMER_ORDERS,
  GET_ORDERS_COUNT_BY_STATES,
} from '@fullcontour/shared-api';

const notification = new CreateNotification();
const cacheUpdate = new UpdateCache();

const withCreate = graphql(CREATE_CUSTOMER_ORDER, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createCustomerOrder } = data;
      if (createCustomerOrder.errorMessages.length) {
        props.setErrorMessages(createCustomerOrder.errorMessages);
        notification.createGroupNotification({
          singleClick: props.singleCase,
          type: 'error',
          text: props.file.name,
          total: props.files.length,
          errorMessage: 'Cases failed',
        });
      } else {
        props.clearErrorMessages(true);
        if (props.fileIndex !== undefined && props.fileIndex !== null) {
          props.removeFile(null, { fileIndex: props.fileIndex });
        }
        if (props.files.length === 1 && props.overpanelOpen) {
          setTimeout(() => {
            props.toggleOverpanel(false);
          }, 20000);
        }
      }

      props.setSubmitionIndicator(false);
    },
    refetchQueries: [
      {
        query: GET_ORDERS_COUNT_BY_STATES,
        fetchPolicy: 'network-only',
      },
    ],
    update: (cache, { data: { createCustomerOrder } }) => {
      if (!createCustomerOrder.errorMessages.length) {
        cacheUpdate.addToCacheHandler({
          order: createCustomerOrder.order,
          removeSubmitAll: props.removeSubmitAll,
          total: props.files.length,
          isOrders: true,
          toggleOverpanel: props.toggleOverpanel,
          progressInfo: sendingOrders,
          singleClick: props.singleCase,
          cache,
          query: GET_CUSTOMER_ORDERS,
        });
      }
    },
  }),
});

export default withCreate;
