import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  BaseLoader,
  FieldContainer,
  FormGridWrap,
  LanguageDropdown,
  Message,
  PhoneField,
  withCurrentUser,
  withFormWrap,
} from '../../../shared';
import { initialValues, schema, update } from './helpers';
import { useApolloClient } from '@apollo/client';

function BasicProfileForm({
  initialValues = () => {},
  schema = () => {},
  getVariables = () => {},
  skipQuery = () => {},
  match = {},
  currentUser = {},
  formSuccess = false,
  ...rest
}) {
  const client = useApolloClient();
  const { t } = useTranslation('profile');
  const app = import.meta.env.VITE_APP_NAME;
  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    update({ variables, client, ...rest });
    setSubmitting(false);
  }
  return currentUser ? (
    <Formik
      initialValues={initialValues({ currentUser })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {() => (
        <FormGridWrap match={match} hideHeader>
          <div className="block">
            <h4 className="title is-4">{t('Basic Info')}</h4>
          </div>
          <div className="block">
            <FieldContainer
              required
              name="firstName"
              label={app !== 'designer_portal' ? 'First Name' : 'Name'}
            >
              <Field
                id="firstName"
                name="firstName"
                required
                placeholder={t('First Name')}
                className="input"
              />
            </FieldContainer>
            {app !== 'designer_portal' && (
              <FieldContainer required name="lastName" label="Last Name">
                <Field
                  id="lastName"
                  name="lastName"
                  required
                  placeholder={t('Last Name')}
                  className="input"
                />
              </FieldContainer>
            )}
            <FieldContainer  name="preferredUsername" label="Username">
              <Field
                id="preferredUsername"
                name="preferredUsername"
                placeholder={t('Username')}
                className="input"
              />
            </FieldContainer>
            <Field
              id="language"
              name="language"
              required
              component={LanguageDropdown}
              enableContext
            />
            <PhoneField />
            {app === 'customer_portal' || app === 'manuf_portal' ? (
              <div>
                <h6 className="title is-6 mb-1 mt-5">
                  {t('Account Cancellation')}
                </h6>
                <p>
                  <small>
                    {t(
                      'To cancel your account and/or submit a request for data removal email us at',
                    )}{' '}
                    <a href="mailto: designservices@3shape.com">
                      designservices@3shape.com
                    </a>
                    . {t('Requests will be processed with 30 days')}.
                  </small>
                </p>
              </div>
            ) : null}
            {formSuccess && (
              <Message type="success" title={t('Profile Update')}>
                <p>{t('Your profile changes were successful')}</p>
              </Message>
            )}
          </div>
          <p style={{ float: 'left' }}>
            <small>v{import.meta.env.VITE_VERSION}</small>
          </p>
        </FormGridWrap>
      )}
    </Formik>
  ) : (
    <div style={{ marginTop: 75 }}>
      <BaseLoader title="Profile..." loading />
    </div>
  );
}

BasicProfileForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  formSuccess: PropTypes.bool.isRequired,
};

export default flowRight(
  withCurrentUser({}),
  withFormWrap({ initialValues, schema, refreshCurrentUser: true }),
)(BasicProfileForm);
