import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';

export const NUMERIC_SYSTEM = [
  { id: '10', name: 'Universal Numeric Notation', value: 'uns' },
  { id: '20', name: 'FDI Notation', value: 'fdi' },
  { id: '30', name: 'Palmer Notation', value: 'palmer' },
];

function NumberingSystemDropdown(props) {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue },
  } = props;

  const { t } = useTranslation('formfields');

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  return (
    <Dropdown
      name={name}
      label="Dental Notation"
      errors={!!(errors[name] && touched[name])}
      options={NUMERIC_SYSTEM}
      onChange={handleChange}
      placeholder={`${t('Select a Notation System')}...`}
      value={value}
      search
      required
    />
  );
}

NumberingSystemDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(NumberingSystemDropdown);
