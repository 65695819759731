import PropTypes from 'prop-types';

function ChartSelectionLabel({ toothChart }) {
  return (
    <p
      style={{
        fontSize: '0.8rem',
        fontWeight: '700',
        marginBottom: '0.35em',
        marginTop: 1,
      }}
    >
      {toothChart ? 'Teeth' : 'Arches'}
      <span
        style={{
          margin: '-.2em 0 0 .2em',
          color: '#E40000',
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      >
        *
      </span>
    </p>
  );
}

ChartSelectionLabel.propTypes = {
  toothChart: PropTypes.bool.isRequired,
};

export default ChartSelectionLabel;
