import { gql } from '@apollo/client';
import { DesignLocationInfo } from '../fragments';

export default gql`
  query designLocations($organizationId: ID) {
    designLocations(organizationId: $organizationId) {
      ...DesignLocationInfo
    }
  }
  ${DesignLocationInfo}
`;
