import { useFormikContext } from 'formik';
import { transformCodeToStoreInFormik } from '../utils';

function useHandleCardsActions({ setGuideCode, guideCodes, orderItemIndex }) {
  const { setFieldValue } = useFormikContext();

  const addDeleteItem = (codeToSave, codeToDisplay, isOther, error) => {
    const updatedCodes = [...guideCodes];
    const selectedCategoryIndex = guideCodes.findIndex(
      (item) => item.categoryId === codeToSave.categoryId,
    );

    if (isOther && (!codeToDisplay.name || error)) {
      updatedCodes.splice(selectedCategoryIndex, 1);
    } else if (selectedCategoryIndex !== -1) {
      updatedCodes[selectedCategoryIndex] = {
        ...updatedCodes[selectedCategoryIndex],
        ...codeToSave,
      };
    } else {
      updatedCodes.push(codeToSave);
    }

    setGuideCode(codeToDisplay);
    setFieldValue(`orderItems[${orderItemIndex}].guideCodes`, updatedCodes);
  };

  const handleChange = ({ data: guide }) => {
    if (!guide.id || !guide.name) {
      return;
    }

    const codeToSave = transformCodeToStoreInFormik(guide);

    const guideCodesModified = guideCodes.filter(
      (item) => item.codeId !== guide.id,
    );

    if (guideCodesModified.length === guideCodes.length) {
      guideCodesModified.push(codeToSave);
    }

    setFieldValue(
      `orderItems[${orderItemIndex}].guideCodes`,
      guideCodesModified,
    );
  };

  const handleDropdown = ({ data, value, isValueOther }) => {
    if (isValueOther) {
      const guideCodesWithRemovedCategory = guideCodes.filter(
        (item) => item.categoryId !== data.category.id,
      );
      setFieldValue(
        `orderItems[${orderItemIndex}].guideCodes`,
        guideCodesWithRemovedCategory,
      );
      setGuideCode({
        ...data,
        id: `other-${data.category.id}`,
        name: '',
        default: false,
        imageUrl: null,
        description: null,
      });
      return;
    }
    const selectedDropdownCode = data.category.guideCodes.find(
      (item) => item.id === value,
    );

    const codeToSave = transformCodeToStoreInFormik({
      ...data,
      ...selectedDropdownCode,
    });

    addDeleteItem(codeToSave, selectedDropdownCode);
  };

  const handleOtherValue = ({ data, value, error }) => {
    const isOtherValueExists = data.category.guideCodes.some(
      (item) => item.name === value,
    );
    addDeleteItem(
      {
        ...transformCodeToStoreInFormik({
          ...data,
          name: value,
          fileUrl: null,
          description: null,
        }),
        isOtherValueExists,
        otherUnit: data.category.otherUnit,
      },
      { ...data, name: value },
      true,
      error,
    );
  };

  return { handleOtherValue, handleDropdown, handleChange };
}

export default useHandleCardsActions;
