import { gql } from '@apollo/client';

export default gql`
  query customerLocationModifiers($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      id
      name
      slug
      labLocationModifiers {
        id
        price
        modifierId
        modifier {
          id
          name
          price
          modifierGroup {
            id
            name
          }
        }
      }
    }
    priceListModifierGroups(slug: $slug) {
      id
      name
      designTypes {
        id
        name
      }
      modifiers {
        id
        name
        price
      }
    }
  }
`;
