import { gql } from '@apollo/client';
import DesignTypeInfo from './DesignTypeInfo';
import GuideCategoryInfo from './GuideCategoryInfo';

const GuideCategoryGroupInfo = gql`
  fragment GuideCategoryGroupInfo on GuideCategoryGroup {
    createdAt,
    designTypes {
        ...DesignTypeInfo
    },
    guideCategories {
        ...GuideCategoryInfo
    },
    guideBooks {
      id,
      name
    }
    id,
    name,
    slug,
    updatedAt,
    selectMany,
    description
  }
  ${DesignTypeInfo},
  ${GuideCategoryInfo}
`;

export default GuideCategoryGroupInfo;
