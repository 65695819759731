import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { END_DATE, START_DATE } from 'react-dates/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangeSelect.css';

import { useTranslation } from 'react-i18next';
import DateRangeSelectPresets from './DateRangeSelectPresets';

function DateRangeSelect({
  onCalendarDatesChange,
  startDate,
  onCalendarFocusChange,
  focusedInput = null,
  endDate = null,
  showExtraPresets = false,
  anchorDirection = 'left',
  maximumNights = 30,
  hidePresets = false,
}) {
  const { t } = useTranslation('navigation');

  const [maximumEndDate, setMaximumEndDate] = useState(
    moment().add(maximumNights, 'days'),
  );

  let condition;

  function onDatesChange({
    startDate: start,
    endDate: end,
    selectedRange = 'custom',
  }) {
    onCalendarDatesChange({
      startDate: start,
      endDate: end,
      selectedRange,
    });
  }

  function renderDatePresets() {
    return !hidePresets ? (
      <DateRangeSelectPresets
        onDatesChange={onDatesChange}
        startDate={startDate}
        endDate={endDate}
        showExtraPresets={showExtraPresets}
      />
    ) : null;
  }

  useEffect(() => {
    if (startDate) {
      setMaximumEndDate(startDate.clone().add(maximumNights, 'days'));
    }
  }, [maximumNights, startDate]);

  if (focusedInput === END_DATE && maximumNights > 0) {
    condition = (day) =>
      moment().diff(day) < 0 || isInclusivelyAfterDay(day, maximumEndDate);
  }

  if (
    focusedInput === START_DATE ||
    (focusedInput === END_DATE && maximumNights < 1)
  ) {
    condition = (day) => moment().diff(day) < 0;
  }

  return (
    <div className="ordersCalWrap">
      <DateRangePicker
        startDateId="startDate"
        renderMonthText={(month) => t(moment(month).format('MMMM YYYY'))}
        endDateId="endDate"
        isOutsideRange={condition}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
        renderCalendarInfo={renderDatePresets}
        onDatesChange={onDatesChange}
        onFocusChange={onCalendarFocusChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        minimumNights={0}
        showDefaultInputIcon
        keepOpenOnDateSelect
        anchorDirection={anchorDirection}
      />
    </div>
  );
}

DateRangeSelect.propTypes = {
  focusedInput: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  onCalendarDatesChange: PropTypes.func.isRequired,
  onCalendarFocusChange: PropTypes.func.isRequired,
  showExtraPresets: PropTypes.bool,
  anchorDirection: PropTypes.string,
  maximumNights: PropTypes.number,
  hidePresets: PropTypes.bool,
};

export default DateRangeSelect;
