import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';

import './PasswordField.scss';

function PasswordInput({
  name = 'password',
  label = 'Password',
  placeholder = '',
  formField = false,
  onChange = null,
  value = null,
  autoComplete = null,
  setFieldValue = null,
  labled = false,
}) {
  const [type, setType] = useState('password');

  const firstRender = useRef(true);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    if (!formField) {
      return onChange(e);
    }
    return setFieldValue(name, e.target.value);
  };

  useEffect(() => {
    if (!firstRender.current) {
      inputRef.current.focus();
      const valueLength = inputRef.current.value.length;
      inputRef.current.setSelectionRange(valueLength, valueLength);
    } else {
      firstRender.current = false;
    }
  }, [type]);

  const changeType = () => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  return React.createElement(
    labled ? 'div' : React.Fragment,
    labled ? { className: 'field' } : {},
    <>
      {labled && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="control has-icons-left has-icons-right">
        <input
          name={name}
          id={name}
          ref={inputRef}
          className="input"
          type={type}
          placeholder={placeholder || label}
          onChange={handleChange}
          {...(autoComplete ? { autoComplete: 'new-password' } : {})}
          {...(formField ? { value } : {})}
        />
        <span className="icon is-small is-left">
          <i className="bx bxs-lock" />
        </span>
        {type === 'password' ? (
          <span
            className="icon is-small is-right is-clickable"
            onClick={changeType}
            role="presentation"
          >
            <i className="bx bxs-hide" />
          </span>
        ) : (
          <span
            className="icon is-small is-right is-clickable"
            onClick={changeType}
            role="presentation"
          >
            <i className="bx bxs-show" />
          </span>
        )}
      </div>
    </>,
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formField: PropTypes.bool,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  labled: PropTypes.func,
};

export default memo(PasswordInput);
