import { gql } from '@apollo/client';
import { ErrorMessageInfo, ViewerLinkInfo } from '../fragments';

export default gql`
  mutation createViewerLink($input: ApprovalLogViewerLinkCreateInput!) {
    createViewerLink(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      viewerLink {
        ...ViewerLinkInfo
      }
    }
  }
  ${ViewerLinkInfo}
  ${ErrorMessageInfo}
`;
