import PropTypes from 'prop-types';

function Tooth15({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 15)
    : upperArch;
  const variable = designType.toothChart ? 15 : 'upper';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[15]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st2 activeTooth ${selected ? 'toothSelected' : ''}` : 'st2'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M3156.1 2102.4c20.2-27.6 17.5-67.8 3.9-114.1 -51.9-118.8-145.1-144.4-290.3-52.8 -45.5 18.4-81.6 53.7-105.4 89 -23.4 34.7-45.1 51.4-33.5 112.8 26.7 103.4 72.7 184.5 203.3 168.5 51.9-6.3 97.7 7.3 163.3-44.1C3122.7 2219.7 3171.7 2222.5 3156.1 2102.4L3156.1 2102.4z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M3096.5 1949.1c-54.6-118.1-144-24.2-230.8 54.1 -17.8 21.4-40.3 34.8-47.7 73.4 -26.8 68 39.6 106.8 70.8 153.3 31.2 46.5 63.4 69.7 119.9 51.8 131.9-48.6 136.9-112.4 116.4-179.2 41.7-98.9 13.4-104.1 2.5-132.4C3117.2 1964.4 3106.9 1961.7 3096.5 1949.1L3096.5 1949.1z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2886.8 2004.6c13.3 7.2 22.8 7.1 32.4 7 25.6-3.8 36.3 9.8 39.5 32.3 0.3 14.1-3.4 29.7 18.4 36.6 18.8 17.2 9.4 25 7.1 35.2 0.5 16 1 26.7 1.5 35.2 0.4 57.4-15.3 56.1-25.2 76.1 -5.9 6.5-13.3 12.5-8.4 22.6l-11.2 9.9"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2973.9 1936.7c-5.4 11.8-10.6 23.5-19.6 35.3 -10 7.5-14.3 23.6-16.8 42.3"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M3074 1960.4c-19 0.8-38 1.6-42.2 17 -24 17.8-48.1 35.6-74.5 55.1"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M3109.6 2088.5l-54.9 7.2c-20.7 8.9-42.3 14.9-70.4 1.6"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M3001.6 2260.6l-14.1-12.6c3.9-36.1-3.3-41.6-14.2-36.6"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M2984.3 2121.2l-29.6-2.7c-13.1 9.1-26.3 14.4-39.4 19.8"
      />
    </>
  );
}

Tooth15.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth15;
