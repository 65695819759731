function RootLoader() {
  return (
    <div className="loader-wrapper is-active">
      {/* <img className="animate-flicker" width={"30%"} src="https://cdn.fullcontour.com/logos/3ShapeDesignServiceLogoGrey.svg"/> */}
      <div className="loader is-loading" />
    </div>
  );
}

export default RootLoader;
