import PropTypes from 'prop-types';

function GuideCloseBtn(props) {
  const { guideModalClose } = props;
  return (
    <button
      type="button"
      className="delete"
      aria-label="close"
      onClick={() => {
        guideModalClose();
      }}
    />
  );
}

GuideCloseBtn.propTypes = {
  guideModalClose: PropTypes.func.isRequired,
};

export default GuideCloseBtn;
