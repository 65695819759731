import { memo, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';
import { renderField } from './functions';

function FieldComponent({
  childFieldConfig,
  registeredComponents,
  errors,
  handleChange,
  values,
  stepChangeHandler,
  ...rest
}) {
  const [shouldShow, setShouldShow] = useState(true);
  const [canShowErrors, setCanShowErrors] = useState(false);
  const { setTouched, value, form, field, initialValue } = rest;
  const { doStep, touched, showAllErrors, linkedNames, initialValues } = form;
  const { name } = field;
  const componentToRender = useMemo(
    () =>
      renderField(childFieldConfig, registeredComponents, errors, {
        ...rest,
        handleChange,
        values,
        stepChangeHandler,
        setShouldShow,
        shouldShow,
        canShowErrors,
      }),
    [
      values,
      errors,
      shouldShow,
      showAllErrors,
      canShowErrors,
      childFieldConfig?.componentProps,
    ],
  );
  useEffect(() => {
    const isTouched = get(touched, name) !== undefined;
    if ((isTouched && !canShowErrors) || showAllErrors) {
      setCanShowErrors(true);
    }
    if (linkedNames[name]) {
      linkedNames[name].forEach((linkedName) => {
        if (
          get(values, linkedName) !== get(initialValues, linkedName) &&
          !isTouched
        ) {
          setTouched(true);
        }
      });
    } else if (!isTouched && value !== initialValue) {
      setTouched(true);
    }
    doStep(name);
  }, [touched, showAllErrors, value, errors]);
  return componentToRender;
}

export default memo(FieldComponent);
