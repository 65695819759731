import { gql } from '@apollo/client';
import { ModifierGroupInfo } from '../fragments';

export default gql`
  query modifierGroups {
    modifierGroups {
      ...ModifierGroupInfo
    }
  }
  ${ModifierGroupInfo}
`;
