import { useMutation } from '@apollo/client';
import { CREATE_GUIDE_CUSTOM_LOCATION } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import updateCache from '../GuideCodeCard/helpers/updateCache';
import applySelectedCodes from './utils/applySelectedCodes';

function GuideApplyBtn({
  guideModalClose = () => {},
  designType = {},
  applyToAll = false,
  values = {},
  orderItemIndex = null,
  currentOrderItem = null,
  form: { setFieldValue } = {},
}) {
  const { designTypeId } = currentOrderItem;
  const [loading, setLoading] = useState(false);

  const [createCode] = useMutation(CREATE_GUIDE_CUSTOM_LOCATION, {
    update: (
      cache,
      {
        data: {
          createGuideCustomCode: { guideCustomCode },
        },
      },
    ) => {
      cache.modify({
        fields: {
          labLocationGuideCategoryGroups(
            existingLabLocationGuideCategoryGroupsRefs = [],
          ) {
            return updateCache({
              guideCategoryGroupId:
                guideCustomCode.guideCategory.guideCategoryGroupId,
              designTypeId,
              guideCategoryId: guideCustomCode.guideCategoryId,
              existingLabLocationGuideCategoryGroupsRefs,
              guideCodeId: guideCustomCode.id,
              name: guideCustomCode.name,
            });
          },
        },
      });
    },
  });

  const applyHandler = () => {
    applySelectedCodes({
      setLoading,
      values,
      orderItemIndex,
      createCode,
      setFieldValue,
      guideModalClose,
      applyToAll,
      designType,
    });
  };

  return (
    <button
      type="button"
      onClick={applyHandler}
      className={`button mr-5 is-primary ${loading && 'is-loading'}`}
      data-cy="apply-design-guide-btn"
    >
      Apply
    </button>
  );
}

GuideApplyBtn.propTypes = {
  guideModalClose: PropTypes.func,
  designType: PropTypes.object,
  applyToAll: PropTypes.bool,
  values: PropTypes.object,
  orderItemIndex: PropTypes.number,
  currentOrderItem: PropTypes.object,
  form: PropTypes.object,
};

export default GuideApplyBtn;
