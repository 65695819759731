import PropTypes from 'prop-types';
import ModifierModalDropdown from './ModifierModalDropdown';

function ModifierModal({
  index,
  values,
  showModifierModal,
  modifierModalClose,
  modifierGroups = [],
  form: { setFieldValue, setFieldTouched },
}) {
  const orderItemIndex = index;
  const currentOrderItem = values.orderItems[index];
  return modifierGroups && modifierGroups.length ? (
    <div className={`modal ${showModifierModal ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-4 modal-card-title">Modifiers Selection</h4>
        </header>
        <section className="modal-card-body">
          {modifierGroups.map((group, groupIndex) =>
            group.modifiers && group.modifiers.length ? (
              <div key={group.id} className="mb-1">
                <label htmlFor={group.id}>{group.label}</label>
                <ModifierModalDropdown
                  modifiers={group.modifiers}
                  group={group}
                  groupIndex={groupIndex}
                  orderItemIndex={orderItemIndex}
                  currentOrderItem={currentOrderItem}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </div>
            ) : null,
          )}
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            type="button"
            data-cy="close-modifiers"
            onClick={() => {
              modifierModalClose();
            }}
            className="button is-dark"
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  ) : null;
}

export default ModifierModal;
ModifierModal.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  showModifierModal: PropTypes.bool.isRequired,
  modifierModalClose: PropTypes.func.isRequired,
  modifierGroups: PropTypes.array,
  form: PropTypes.object.isRequired,
};
