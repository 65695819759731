import { gql } from '@apollo/client';

export default gql`
  query viewerLinkAuth($linkHash: String!, $accessToken: String!) {
    viewerLinkAuth(linkHash: $linkHash, accessToken: $accessToken) {
      id
      linkHash
      accessToken
      createdAt
      order {
        id
        redesignCount
        location {
          id
          name
          website
          logoUrl
          viewerSetting {
            id
            theme
          }
        }
        objFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          sortOrder
          originalFileName
          category
          redesignCount
        }
      }
    }
  }
`;
