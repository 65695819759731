export const getDesignProfile = (id, designProfiles) =>
  designProfiles.find((profile) => profile.id === id);

export const getDesignPreference = (profile, preferenceName) =>
  profile.designPreferences.find((item) => item.name === preferenceName)?.value;

export const getDefaultValue = (pref) =>
  pref.values.find((item) => item.default)?.value;

function getPreferenceValue(designProfileId, preference, designProfiles) {
  const designProfile = getDesignProfile(designProfileId, designProfiles);
  if (!designProfile) {
    return null;
  }
  const designPreference = getDesignPreference(designProfile, preference.name);
  if (designPreference) {
    return designPreference;
  }
  return null;
}

export default getPreferenceValue;
