/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

function TurnAroundTimesDropdown(props) {
  const {
    field,
    index,
    values,
    value,
    dataSources: {
      customerLocation: {
        priceList: { priceListItems, turnaroundTimes },
      },
    },
    form: { setFieldValue },
  } = props;
  if (values.businessUnitName === '') {
    return 'Please first select a category';
  }
  if (values.orderItems[index].designTypeId === '') {
    return 'Please select your indication';
  }
  const times = useMemo(
    () =>
      turnaroundTimes.filter(({ id }) =>
        priceListItems.find(
          ({ turnaroundTimeId, designTypeId }) =>
            turnaroundTimeId === id &&
            designTypeId === values.orderItems[index].designTypeId,
        ),
      ),
    [values.orderItems[index].designTypeId],
  );
  useEffect(() => {
    if (!value && times.length === 1) {
      setTimeout(() => {
        setFieldValue(field.name, times[0].value);
      }, 100);
    }
  }, [values]);
  return times.length === 1 ? (
    <div>
      <p>
        We have automatically selected the only available turnaround time based
        on the design you are ordering. Your design should be completed by the
        time frame below:
      </p>
      <p style={{ fontWeight: 'bold', color: 'rgba(61, 172, 209, 0.9)' }}>
        {times[0].name}
      </p>
    </div>
  ) : (
    <div className="select">
      <select {...field}>
        <option value="">Please Select</option>
        {times.map(({ name, value }) => (
          <option key={name} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
TurnAroundTimesDropdown.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  dataSources: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
export default memo(TurnAroundTimesDropdown);
