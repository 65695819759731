import {
  OverpanelFilesContext,
  OverpanelSubmitContext,
} from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';

function OverpanelHeaderCancelAll(props) {
  const { overpanelOpen } = props;
  const { files } = useContext(OverpanelFilesContext);
  const { removeSubmitAll, submitAll } = useContext(OverpanelSubmitContext);

  return overpanelOpen &&
    overpanelOpen !== 'pending' &&
    files.length > 0 &&
    submitAll ? (
    <button
      type="button"
      className="button mr-2 is-danger"
      onClick={removeSubmitAll}
    >
      <span className="icon">
        <i className="bx bx-block" />
      </span>
      <span>Cancel All Submissions</span>
    </button>
  ) : null;
}

OverpanelHeaderCancelAll.propTypes = {
  overpanelOpen: PropTypes.bool.isRequired,
};

export default OverpanelHeaderCancelAll;
