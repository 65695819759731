function fullAddress(a) {
  return `${a.address},
  ${a.address2 || ''}
  ${a.city || ''}
  ${a.region || ''}
  ${a.postalCode || ''}
  ${a.country || ''}`;
}

export default fullAddress;
