import { gql } from '@apollo/client';
import { FeedbackCategoryInfo } from '../fragments';

export default gql`
  query feedbackCategory($id: ID!) {
    feedbackCategory(id: $id) {
      ...FeedbackCategoryInfo
    }
  }
  ${FeedbackCategoryInfo}
`;
