const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
const getFirstTwoWords = (query) =>
  Object.keys(query)[0].split('_').slice(0, 2).join('_');

const fetchOnStatus = (query, status) => {
  const prefix = getFirstTwoWords(query);
  if (!status) {
    return {
      queryToFetch: query[prefix],
      dataKey: 'orders',
    };
  }
  const key = `${prefix}_${status.toUpperCase()}`;
  const dataKey = `${status
    .split('_')
    .map((i, j) => (j ? capitalize(i) : i))
    .join('')}Orders`;

  return {
    queryToFetch: query[key],
    dataKey,
  };
};

export default fetchOnStatus;
