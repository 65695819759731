import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';

function LocationDependantDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  type,
  orgQuery,
  disabled = false,
  parentOrgId = null,
  placeholder = 'Please Select...',
  labelText = null,
  required = false,
}) {
  const { t } = useTranslation('formfields');
  const [optionsData, setOptionsData] = useState();

  const handleChange = (e) => {
    setFieldValue('locationId', e.target.value);
  };
  const { error, loading } = useQuery(orgQuery, {
    variables: { id: parentOrgId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      const data = [...d[`${type}Organization`].locations].sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1,
      );
      setOptionsData(data);
    },
  });

  return (
    <Dropdown
      name={name}
      label={
        labelText || `Parent ${type} ${type === 'design' ? 'silo' : 'location'}`
      }
      errors={!!(errors.locationId && touched.locationId)}
      options={loading ? [] : optionsData}
      onChange={handleChange}
      placeholder={t(placeholder)}
      value={value}
      disabled={disabled}
      required={required}
      loading={loading}
      error={error}
    />
  );
}

LocationDependantDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  orgQuery: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  parentOrgId: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
};

export default memo(LocationDependantDropdown);
