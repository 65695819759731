const palmerMapping = {
  1: [8, 'br'],
  2: [7, 'br'],
  3: [6, 'br'],
  4: [5, 'br'],
  5: [4, 'br'],
  6: [3, 'br'],
  7: [2, 'br'],
  8: [1, 'br'],
  9: [1, 'bl'],
  10: [2, 'bl'],
  11: [3, 'bl'],
  12: [4, 'bl'],
  13: [5, 'bl'],
  14: [6, 'bl'],
  15: [7, 'bl'],
  16: [8, 'bl'],
  17: [8, 'tl'],
  18: [7, 'tl'],
  19: [6, 'tl'],
  20: [5, 'tl'],
  21: [4, 'tl'],
  22: [3, 'tl'],
  23: [2, 'tl'],
  24: [1, 'tl'],
  25: [1, 'tr'],
  26: [2, 'tr'],
  27: [3, 'tr'],
  28: [4, 'tr'],
  29: [5, 'tr'],
  30: [6, 'tr'],
  31: [7, 'tr'],
  32: [8, 'tr'],
};

export default palmerMapping;
