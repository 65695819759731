/* eslint-disable func-names */
import * as Yup from 'yup';

function schema({ designTypes }) {
  return Yup.object({
    businessUnitName: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    instructions: Yup.string(),
    files: Yup.array().test(
      'check-required-files',
      'Required',
      function (value) {
        if (
          this.parent.businessUnitName &&
          this.parent.orderItems[0].designTypeId !== undefined
        ) {
          const selectedDesignType = designTypes[
            this.parent.businessUnitName
          ].find(({ id }) => id === this.parent.orderItems[0].designTypeId);
          if (selectedDesignType === undefined) {
            return false;
          }
          const folders = selectedDesignType.designTypeCaseFolders.map(
            (dCF) => ({
              ...dCF,
              ...selectedDesignType.caseFolders.find(
                (cF) => cF.id === dCF.caseFolderId,
              ),
            }),
          );
          const errors = [];
          folders.forEach(({ folderName, required, caseFolderId }) => {
            if (
              value &&
              !value.find(
                (file) => file && file.caseFolderId === caseFolderId,
              ) &&
              required
            ) {
              errors.push({ [caseFolderId]: `${folderName} is required.` });
            }
          });
          if (errors.length) {
            return this.createError({ message: errors, path: 'files' });
          }
          return true;
        }
        return false;
      },
    ),
    orderItems: Yup.array().of(
      Yup.object().shape({
        units: Yup.number(),
        materialName: Yup.string(),
        guideCodes: Yup.array().of(Yup.object()),
        teeth: Yup.array().of(Yup.number()),
        arches: Yup.array().of(Yup.string()),
        manufacturerOrganizationId: Yup.string().required('Required'),
        orderItemModifiersAttributes: Yup.array(),
        designTypeId: Yup.string().required('Required'),
        splitFile: Yup.boolean(),
        printerId: Yup.string(),
        teethChart: Yup.string().test(
          'checks-teeths-or-arches',
          'Please select at least 1 unit',
          function () {
            if (this.parent?.designTypeId) {
              const selectedDesignType = designTypes[
                this.options.context.businessUnitName
              ].find(({ id }) => id === this.parent.designTypeId);
              if (selectedDesignType !== undefined) {
                if (selectedDesignType.toothChart) {
                  if (this.parent.teeth.length > 0) {
                    return true;
                  }
                  return this.createError({
                    message: 'Please select at least one tooth',
                  });
                }
                if (selectedDesignType.archChart) {
                  if (this.parent.arches.length > 0) {
                    return true;
                  }
                  return this.createError({
                    message: 'Please select at least one arch',
                  });
                }
                return true;
              }
            }
            return this.createError({ message: 'Required' });
          },
        ),
      }),
    ),
    turnaroundTimeId: Yup.string().required('Required'), // x
    requiresDesignApproval: Yup.boolean(), // x,
    intOrderId: Yup.string(),
    software: Yup.string(),
  });
}

export default schema;
