import { gql } from '@apollo/client';
import { DebitInfo } from '../fragments';

export default gql`
  mutation destroyDebit($input: DebitDestroyInput!) {
    destroyDebit(input: $input) {
      clientMutationId
      debit {
        ...DebitInfo
      }
    }
  }
  ${DebitInfo}
`;
