import { gql } from '@apollo/client';
import { IntegrationInfo } from '../fragments';

export default gql`
  query integration($id: ID!) {
    integration(id: $id) {
      ...IntegrationInfo
    }
  }
  ${IntegrationInfo}
`;
