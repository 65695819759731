import { useQuery } from '@apollo/client';
import { GET_REGIONS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';
import Dropdown from './Dropdown';

function RegionDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  country = 'US',
  containerStyle = {},
}) {
  const [noOptions, setNoOptions] = useState(null);
  const { t } = useTranslation('formfields');
  const { locale } = useContext(LanguageContext);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  const { error, loading, data } = useQuery(GET_REGIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { country, locale },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ regions }) => {
      const na = 'N/A';
      if (!regions.length) {
        setNoOptions([
          {
            name: na,
            value: na,
          },
        ]);
        setFieldValue(name, na);
      } else {
        if (value === na) setFieldValue(name, '');
        setNoOptions(null);
      }
    },
  });

  return (
    <Dropdown
      name={name}
      label="State / Region"
      error={error}
      errors={!!(errors[name] && touched[name])}
      loading={loading}
      options={loading ? [] : noOptions || data?.regions}
      onChange={handleChange}
      placeholder={`${t('Choose a Region / State')}...`}
      value={value}
      required
      containerStyle={containerStyle}
      disabled={!(data && data.regions.length)}
    />
  );
}

RegionDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  country: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default memo(RegionDropdown);
