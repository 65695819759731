import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SearchResults({
  isNoValue,
  children,
  apologiesText = 'Sorry, we could not find any items with following text',
  searchValue = null,
}) {
  const { t } = useTranslation('navigation');
  return isNoValue && searchValue ? (
    <p>
      {t(apologiesText)}: <code>{searchValue}</code>
    </p>
  ) : (
    children
  );
}

SearchResults.propTypes = {
  children: PropTypes.node.isRequired,
  searchValue: PropTypes.string,
  isNoValue: PropTypes.bool.isRequired,
  apologiesText: PropTypes.string,
};

export default SearchResults;
