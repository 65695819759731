/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import { graphql } from '@apollo/client/react/hoc';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useState, memo } from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import handleTryCatch from '../../../HelperFunctions/handleTryCatch';
import BaseLoader from '../../../Loader/BaseLoader';
import FieldContainer from '../../FieldContainer/FieldContainer';
import {
  activeStyle,
  baseStyle,
  rejectedStyle,
} from '../FileDropzone/FileDropzoneStyle';

const dropStylesImage = {
  maxHeight: '100px',
  objectFit: 'cover',
  width: 'auto',
  flex: '1 1 calc((100% / 2) - 2rem)',
};

function FileDropzoneDesignGuide({
  field,
  form,
  signS3,
  t,
  labelText = null,
  multipleFiles = false,
  arrayOfObjects = false,
  hideText = false,
  accept = {
    'image/*': ['.jpeg', '.png', '.jpg'],
  },
}) {
  const { name, value } = field;
  const { setFieldValue, values } = form;

  const [{ file, loading, preview, previewMultipleFiles }, setState] = useState(
    {
      file: null,
      preview: null,
      previewMultipleFiles: null,
      loading: false,
    },
  );

  const signAndSubmit = handleTryCatch(async (filesArray) => {
    if (filesArray) {
      const promises = filesArray.map(async (item) => createLink(item.file));
      const fileLinks = await Promise.all(promises);
      setState((state) => ({ ...state, previewMultipleFiles: fileLinks }));
      setFieldValue(
        name,
        arrayOfObjects
          ? fileLinks.map((image) => ({
              imageUrl: image,
            }))
          : fileLinks,
      );
    } else {
      const url = await createLink(file);
      setFieldValue(name, url);
    }
    setState((state) => ({ ...state, loading: false }));
  });

  const uploadToS3 = handleTryCatch(async (signedRequest, file) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    await axios.put(signedRequest, file, options);
  });

  const createLink = handleTryCatch(async (file) => {
    const s3Key = `assets/images/design-guide/${formatFilename(file.name)}`;
    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: s3Key,
            acl: 'public-read',
            filetype: file.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });
    const { signedRequest, url } = response.data.signS3.s3Response;
    await uploadToS3(signedRequest, file);
    return url;
  });

  useEffect(() => () => URL.revokeObjectURL(preview), []);
  useEffect(() => {
    if (file && preview) {
      signAndSubmit();
    }
  }, [file, preview]);

  function onDrop(accepted) {
    setState((state) => ({ ...state, loading: true }));
    if (multipleFiles) {
      if (accepted.length) {
        const filesWithPreviews = accepted.map((item) => ({
          file: item,
          preview: URL.createObjectURL(item),
        }));
        signAndSubmit(filesWithPreviews);
      }
    } else {
      const file = accepted[0];
      if (file) {
        const droppedFile = URL.createObjectURL(file);
        setState((state) => ({
          ...state,
          file,
          preview: droppedFile,
        }));
      }
    }
  }

  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }

  function previewSrc() {
    if (preview) {
      return preview;
    }
    if (value) {
      return value;
    }
    return `https://s3-us-west-2.amazonaws.com/${
      import.meta.env.VITE_ASSET_BUCKET
    }/assets/images/l/default.svg`;
  }

  return (
    <FieldContainer name={name} label={labelText || name} hideLabel={hideText}>
      <Dropzone
        accept={accept}
        multiple={multipleFiles}
        onDrop={(accepted) => onDrop(accepted)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

          return (
            <figure
              style={{
                ...styles,
                ...(multipleFiles
                  ? {
                      padding: '20px',
                      width: '100%',
                      maxWidth: '400px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      justifyContent: previewMultipleFiles
                        ? 'space-between'
                        : 'center',
                    }
                  : {}),
              }}
              {...getRootProps()}
            >
              {loading ? (
                <div
                  style={{
                    height: '70px',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <BaseLoader hideText />
                </div>
              ) : multipleFiles && (previewMultipleFiles || values[name]) ? (
                [...(previewMultipleFiles || values[name])].map((item) => (
                  <figure className="image">
                    <img
                      src={item.imageUrl || item}
                      key={item.id || item}
                      style={dropStylesImage}
                      alt="preview"
                    />
                  </figure>
                ))
              ) : (
                <figure className="image">
                  <img
                    src={previewSrc()}
                    style={{
                      ...(multipleFiles
                        ? { ...dropStylesImage, flex: '0' }
                        : {}),
                    }}
                    alt="preview"
                  />
                </figure>
              )}
              <input {...getInputProps()} />
              {!hideText && (
                <figcaption
                  style={{
                    ...(multipleFiles
                      ? { width: '100%', marginTop: '10px' }
                      : {}),
                  }}
                >
                  {isDragActive ? (
                    <span>{`${t('Drop image here')}...`}</span>
                  ) : (
                    <span>
                      {multipleFiles
                        ? t(
                            `Drop one or more images here, or click / tap to select files to upload`,
                          )
                        : t(`Drop an image here, or click / tap to select a file to
                      upload`)}
                    </span>
                  )}
                </figcaption>
              )}
            </figure>
          );
        }}
      </Dropzone>
    </FieldContainer>
  );
}

FileDropzoneDesignGuide.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  labelText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  signS3: PropTypes.func.isRequired,
  multipleFiles: PropTypes.bool,
  hideText: PropTypes.bool,
  arrayOfObjects: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default flowRight(
  graphql(REQUEST_S3_SIGN, { name: 'signS3' }),
  withTranslation('formfields'),
)(memo(FileDropzoneDesignGuide));
