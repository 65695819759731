import PropTypes from 'prop-types';

import { PaginationContainer } from '../../../../../../shared';

function FolderViewStls({
  selectedFolder = null,
  toggleVersions,
  order,
  order: { name, hexOrderCode },
}) {
  const filteredData = toggleVersions
    ? selectedFolder.selectedItems
    : selectedFolder.selectedItems.filter(
        (item) => item.redesignCount === order.redesignCount,
      );
  return (
    <PaginationContainer
      itemsPerPage={4}
      itemWidth="50%"
      data={filteredData.sort((a, b) => b.ver - a.ver)}
      toggleVersions={toggleVersions}
      allFiles={order.files}
      name={name}
      hexOrderCode={hexOrderCode}
    />
  );
}

FolderViewStls.propTypes = {
  selectedFolder: PropTypes.object,
  order: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
};

export default FolderViewStls;
