import { gql } from '@apollo/client';

const ModifierInfo = gql`
  fragment ModifierInfo on Modifier {
    id
    name
    description
    isActive
    slug
    modifierGroupId
    price
    default
    modifierGroup {
      id
      name
      slug
    }
  }
`;

export default ModifierInfo;
