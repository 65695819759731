function useFindCurrentDesignType(props) {
  const {
    values,
    dataSources: { customerLocation },
    orderItemIndex,
  } = props;
  return customerLocation.priceList.designTypes.find(
    (item) => item.id === values.orderItems[orderItemIndex].designTypeId,
  );
}

export default useFindCurrentDesignType;
