function transformNestedData(
  nestedData,
  allowNested = true,
  transformFn = (key) => key,
) {
  const transformedData = {};
  if (nestedData && allowNested) {
    Object.entries(nestedData).forEach(([key, value]) => {
      value.forEach((id) => {
        if (!transformedData[id]) {
          transformedData[id] = [];
        }
        transformedData[id].push(transformFn(key));
      });
    });
  }
  return transformedData;
}

export default transformNestedData;
