import { gql } from '@apollo/client';
import { CreditCardInfo } from '../fragments';

export default gql`
  mutation destroyCreditCard($input: CreditCardDestroyInput!) {
    destroyCreditCard(input: $input) {
      clientMutationId
      creditCard {
        ...CreditCardInfo
      }
    }
  }
  ${CreditCardInfo}
`;
