import { useApolloClient } from '@apollo/client';
import { DESTROY_CREDIT_CARD } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BoxIcon from '../../../../shared/Icons/BoxIcon';

function BillingCreditCardDeleteButton({ cardId, refetch }) {
  const client = useApolloClient();
  const [confirmState, setConfirmState] = useState({ open: false });

  const show = () => setConfirmState({ open: true });

  const handleCancel = () => setConfirmState({ open: false });

  const destroyCreditCard = async () => {
    await client.mutate({
      mutation: DESTROY_CREDIT_CARD,
      variables: {
        input: {
          id: cardId,
          clientMutationId: uuidv4(),
        },
      },
    });

    refetch();
  };

  const handleConfirm = () => {
    setConfirmState({ open: false });
    destroyCreditCard();
  };

  return (
    <>
      <button
        type="button"
        onClick={show}
        className="button is-danger m-0 is-small is-outlined"
      >
        <BoxIcon className="is-size-4" name="bx-trash" />
      </button>

      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div
          className="modal-content"
          style={{ backgroundColor: '#fff', padding: '1em', borderRadius: 12 }}
        >
          <div className="block">
            <p>Are you sure you want to delete this credit card?</p>
          </div>
          <div className="block">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-danger is-outlined"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button is-primary"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCancel}
        />
      </div>
    </>
  );
}

BillingCreditCardDeleteButton.propTypes = {
  cardId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default memo(BillingCreditCardDeleteButton);
