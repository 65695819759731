import PropTypes from 'prop-types';

function Tooth3({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 3)
    : upperArch;
  const variable = designType.toothChart ? 3 : 'upper';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[3]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st1 activeTooth ${selected ? 'toothSelected' : ''}` : 'st1'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M606.2 1524.9c99-85.9 172-36.3 245 13.3 19.7 24.5 39 48.9 66 73.4 82.7 79.5 67.1 133.4 19.2 178.9 -29.8 33.1-58.4 65.6-73.5 91.4 -17.2 17.8-33.1 31.4-46.6 37.9 -63.7 28.6-88.1-11.3-123.9-31.3C564 1820.7 518 1726.3 551.1 1606.4 554.7 1560.2 576.5 1537.3 606.2 1524.9L606.2 1524.9z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M621.5 1629.1c-20.8-96.4 29.4-85.8 56.6-109.7 47.3-11.4 86.9-39.1 166.2 17.3 59 82.1 41.4 128.2 48.8 186 3.6 55.3-12.9 88.8-35.5 102.7 -5.6 29.8-4.3 58.3-53.7 84.4 -42.1 37.5-71.7 5.2-102.8-18.6 -46.8-12.6-54.3-43.4-53.3-86.1C552.5 1763.8 605.7 1685.6 621.5 1629.1L621.5 1629.1z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M806.2 1539.5c-9.7 11.4-22.6 12.9-25.5 45 4.4 32.9-13.7 65.7-31.2 98.5 -18.2 17.7-12.3 65.6-17.2 100 17.8 17 35.6 17.2 53.5 15.6l33.7 29.7"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M639.7 1641.9c12.1 13.1 21.1 28.2 54.9 26.9 19.3-2.5 38.5-3.7 57.7 8.6"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M852.4 1653.7c-36.9 6.3-91.4 3.8-101.5 23.7"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M660.4 1798.2c37.6 17 53.5-0.8 73.3-12.5"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M691.3 1850.4l17-52.1"
      />
    </>
  );
}

Tooth3.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth3;
