const downloadFiles = {
  admin_portal: {
    visible: true,
    statuses: {
      pending: [0, 10, 20, 30, 40],
      sent_for_design: [0, 10, 20, 30, 40],
      design_in_progress: [0, 10, 20, 30, 40],
      rush_design: [0, 10, 20, 30, 40],
      redesign: [0, 10, 20, 30, 40],
      hold_released: [0, 10, 20, 30, 40],
      on_hold: [0, 10, 20, 30, 40],
      needs_approval: [0, 10, 20, 30, 40],
      sent_for_approval: [0, 10, 20, 30, 40],
      ready_for_download: [0, 10, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40],
      at_manufacturer: [0, 10, 20, 30, 40],
      shipped: [0, 10, 20, 30, 40],
      manufacturing_hold: [0, 10, 20, 30, 40],
      cancelled: [0, 10, 20, 30, 40],
      complete: [0, 10, 20, 30, 40],
      closed: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: true,
    statuses: {
      pending: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_for_design: [0, 10, 20, 30, 40, 50, 60, 70],
      design_in_progress: [0, 10, 20, 30, 40, 50, 60, 70],
      rush_design: [0, 10, 20, 30, 40, 50, 60, 70],
      redesign: [0, 10, 20, 30, 40, 50, 60, 70],
      hold_released: [0, 10, 20, 30, 40, 50, 60, 70],
      on_hold: [0, 10, 20, 30, 40, 50, 60, 70],
      needs_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_for_approval: [0, 10, 20, 30, 40, 50, 60, 70],
      ready_for_download: [0, 10, 20, 30, 40, 50, 60, 70],
      sent_to_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      at_manufacturer: [0, 10, 20, 30, 40, 50, 60, 70],
      shipped: [0, 10, 20, 30, 40, 50, 60, 70],
      manufacturing_hold: [0, 10, 20, 30, 40, 50, 60, 70],
      cancelled: [0, 10, 20, 30, 40, 50, 60, 70],
      complete: [0, 10, 20, 30, 40, 50, 60, 70],
      closed: [0, 10, 20, 30, 40, 50, 60, 70],
    },
  },
  manuf_portal: {
    visible: true,
    statuses: {
      sent_to_manufacturer: [0, 10],
      at_manufacturer: [0, 10],
      shipped: [0, 10],
      manufacturing_hold: [0, 10],
      complete: [0, 10],
    },
  },
  designer_portal: {
    visible: true,
    statuses: {
      pending: [0, 5, 10, 20],
      sent_for_design: [0, 5, 10, 20],
      design_in_progress: [0, 5, 10, 20],
      rush_design: [0, 5, 10, 20],
      redesign: [0, 5, 10, 20],
      hold_released: [0, 5, 10, 20],
      on_hold: [0, 5, 10, 20],
      needs_approval: [0, 5, 10, 20],
      sent_for_approval: [0, 5, 10, 20],
      ready_for_download: [0, 5, 10, 20],
      sent_to_manufacturer: [0, 5, 10, 20],
      at_manufacturer: [0, 5, 10, 20],
      shipped: [0, 5, 10, 20],
      manufacturing_hold: [0, 5, 10, 20],
      cancelled: [0, 5, 10, 20],
      complete: [0, 5, 10, 20],
      closed: [0, 5, 10, 20],
    },
  },
};

export default downloadFiles;
