import { gql } from '@apollo/client';

const SignupTemplateInfo = gql`
  fragment SignupTemplateInfo on SignupTemplate {
    id
    name
    slug
    subType
    createdAt
    updatedAt
    signupSourceId
    tzStart
    tzEnd
    priceList {
      value
      text
    }
    priceListId
    organization {
      value
      text
    }
    organizationId
    turnaroundTime {
      value
      text
    }
    turnaroundTimeId
    paymentTerm {
      text
      value
    }
    paymentTermId
    numberingSystem
    roleTypeId
    guideBook {
      value
      text
    }
    guideBookId
    customerTagSignupTemplates {
      id
      customerTagId
    }
    customerTagIds
    customerTags {
      id
      name
    }
    designerTagSignupTemplates {
      id
      designerTagId
    }
    designerTagIds
    designerTags {
      id
      name
    }
    siloRouteTemplates {
      id
      designTypeCategoryId
      primarySiloTemplateId
      secondarySiloTemplateId
      catchAllSiloTemplateId
      designTypeCategory {
        id
        name
      }
    }
  }
`;

export default SignupTemplateInfo;
