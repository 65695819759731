import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function GuideSearch({ fitlerSearch, filteValue = '' }) {
  const [value, setValue] = useState(filteValue || '');

  const { t } = useTranslation('designProfile');

  useEffect(() => {
    if (!filteValue && value) {
      setValue('');
    }
  }, [filteValue, value]);

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setValue(val);
    fitlerSearch(val);
  };

  return (
    <div className="field mb-0" style={{ position: 'relative', width: 200 }}>
      <div className="control has-icons-right">
        <input
          data-cy="design-guide-search"
          className="input is-rounded"
          type="text"
          placeholder={t('Search...')}
          onChange={handleSearchChange}
          value={value}
        />
        <span className="icon is-right">
          <i className="bx bx-search" />
        </span>
      </div>
    </div>
  );
}

GuideSearch.propTypes = {
  fitlerSearch: PropTypes.func.isRequired,
  filteValue: PropTypes.string,
};

export default GuideSearch;
