import { ObjViewer } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { matchedObjFiles } from '../../../../../../../../transforms';
import {
  BoxIcon,
  OrthoStlViewer,
  OutsideClick,
} from '../../../../../../../shared';
import ElementImage from '../ElementImage/ElementImage';
import './ElementActions.css';

function ElementActionsModal({
  selectedFolder = null,
  selectedFile = null,
  order: { redesignCount, files, name, hexOrderCode },
  modalClose,
  modalVisible,
}) {
  const [photoIndex] = useState(0);

  if (selectedFolder && selectedFolder.fileType === 'quality_control_images') {
    return (
      <Lightbox
        styles={{ container: { backgroundColor: 'rgba(80, 80, 80, 0.87)' } }}
        open={modalVisible}
        close={modalClose}
        slides={[
          ...selectedFolder.selectedItems.map((img) => ({
            src: img.signedUrl,
          })),
        ]}
        index={photoIndex}
      />
    );
  }

  return selectedFile || selectedFolder ? (
    <div className={`modal ${modalVisible ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <OutsideClick fn={modalClose}>
        <div
          className="modal-content"
          style={{ width: '94vw', height: 'calc(100vh - 40px)' }}
        >
          {selectedFile &&
            selectedFile.fileType === 'quality_control_images' && (
              <>
                <ElementImage image={selectedFile} />
                <p style={{ marginTop: '1em' }}>
                  {selectedFile.originalFileName}
                </p>
              </>
            )}

          {selectedFile && selectedFile.fileType === 'stl_files' && (
            <>
              <OrthoStlViewer
                file={selectedFile}
                allFiles={files}
                containerStyle={{ height: '90vh' }}
                orderName={name}
                hexOrderCode={hexOrderCode}
              />
              <p style={{ marginTop: '1em' }}>
                {selectedFile.originalFileName}
              </p>
            </>
          )}

          {selectedFolder && selectedFolder.fileType === 'obj_files' && (
            <div className="columns">
              <div className="column">
                <ObjViewer
                  files={matchedObjFiles(
                    selectedFolder.selectedItems,
                    redesignCount,
                  )}
                />
              </div>
              <div className="column">
                <div style={{ marginLeft: '1em' }}>
                  <div className="block">
                    <h4 className="title is-4">How To Control</h4>
                  </div>

                  <div className="block is-flex is-flex-direction-row">
                    <BoxIcon name="bx-pointer bx-sm" />
                    <div className="ml-4">
                      <p>
                        Click and Drag to rotate the camera around the viewer
                      </p>
                    </div>
                  </div>
                  <div className="block is-flex is-flex-direction-row">
                    <BoxIcon name="bx-sort bx-sm" />
                    <div className="ml-4">
                      <p>Scroll up or down to zoom the camera in and out</p>
                    </div>
                  </div>
                  <div className="block is-flex is-flex-direction-row">
                    <BoxIcon name="bx-slider bx-sm" />
                    <div className="ml-4">
                      <p>
                        Use the slider or buttons at the bottom of the viewer to
                        see various stages of treatment
                      </p>
                    </div>
                  </div>
                  <div className="block is-flex is-flex-direction-row">
                    <BoxIcon name="bx-video bx-sm" />
                    <div className="ml-4">
                      <p>
                        Use the buttons at the top right of the viewer to change
                        your viewing perspective
                      </p>
                    </div>
                  </div>
                  <div className="block is-flex is-flex-direction-row">
                    <BoxIcon name="bx-low-vision bx-sm" />
                    <div className="ml-4">
                      <p>
                        Toggle the buttons on the top left of the viewer to show
                        or hide the upper or lower arch
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClick>
      <button
        className="modal-close is-large"
        aria-label="close"
        type="button"
        onClick={modalClose}
      />
    </div>
  ) : null;
}

ElementActionsModal.propTypes = {
  selectedFolder: PropTypes.object,
  selectedFile: PropTypes.object,
  order: PropTypes.object.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default ElementActionsModal;
