import PropTypes from 'prop-types';
import { ElementScan } from '../FilesShowElements/ElementScan';
import FolderViewImages from './FolderViewImages';
import FolderViewStls from './FolderViewStls';

function FilesShowFolderView({
  selectedFolder,
  selectedFolder: { fileType },
  order,
  toggleVersions,
  selectedFileError = null,
}) {
  if (
    fileType === 'initial_scans' ||
    fileType === 'finished_scans' ||
    fileType === 'manufacturer_scans'
  ) {
    return <ElementScan selectedFolder={selectedFolder} />;
  }

  if (fileType === 'quality_control_images') {
    return (
      <FolderViewImages
        selectedFileError={selectedFileError}
        selectedFolder={selectedFolder}
        toggleVersions={toggleVersions}
      />
    );
  }

  if (fileType === 'stl_files' || fileType === 'visualization_output') {
    return (
      <FolderViewStls
        selectedFolder={selectedFolder}
        order={order}
        toggleVersions={toggleVersions}
      />
    );
  }

  return null;
}

FilesShowFolderView.propTypes = {
  selectedFolder: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  toggleVersions: PropTypes.bool.isRequired,
  selectedFileError: PropTypes.string,
};

export default FilesShowFolderView;
