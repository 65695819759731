import { Line } from '@react-three/drei';
import PropTypes from 'prop-types';

function CutLineElementInner(props) {
  const { currentPts } = props;

  return currentPts ? (
    <Line
      points={currentPts}
      color="red"
      lineWidth={5}
      dashed={false}
      rotation={[0, (90 * Math.PI) / 180, 0]}
    />
  ) : null;
}

CutLineElementInner.propTypes = {
  currentPts: PropTypes.array.isRequired,
};

export default CutLineElementInner;
