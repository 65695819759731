import PropTypes from 'prop-types';
import { memo } from 'react';
import Styles from '../PriceListItemForm.module.css';

function ScrollingWrapper(props) {
  const { children } = props;
  return (
    <div className={Styles.tableContainer}>
      <div className={Styles.tableContainerInner}>
        <table className="table is-bordered is-striped is-fullwidth is-hoverable">
          {children}
        </table>
      </div>
    </div>
  );
}

ScrollingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ScrollingWrapper);
