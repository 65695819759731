import { useQuery } from '@apollo/client';
import { GET_CONTACTS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { BaseLoader } from '../../../shared';
import BillingContacts from '../BillingContacts/BillingContacts';

function BillingDetailsContacts(props) {
  const { customer, type } = props;

  const { loading, error, data, refetch } = useQuery(GET_CONTACTS, {
    variables: {
      contactableId: customer.id,
      contactableType: type === 'location' ? 'LabLocation' : 'LabOrganization',
      role: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <p>Error :(</p>;
  if (loading) return <BaseLoader title="Billing Contacts" loading />;

  return (
    <BillingContacts
      contacts={data.contacts}
      type={type}
      refetch={refetch}
      {...props}
    />
  );
}

BillingDetailsContacts.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingDetailsContacts;
