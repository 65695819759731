import { gql } from '@apollo/client';

const DesignTypeCategoryInfo = gql`
  fragment DesignTypeCategoryInfo on DesignTypeCategory {
    id
    name
    slug
    symbol
    key
    value
    text
    businessUnitId
    nonSpecialized
    businessUnit {
      id
      name
      slug
    }
    description
  }
`;

export default DesignTypeCategoryInfo;
