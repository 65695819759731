import PropTypes from 'prop-types';
import { useState } from 'react';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingInvoiceAttachCreditFormModal from './BillingInvoiceAttachCreditFormModal';

function BillingInvoiceAttachCreditForm({
  selectedItem = null,
  refetch,
  customer,
  type,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [maxAmount, setMaxAmount] = useState(0.0);

  return (
    <>
      <BasicDropdownItem
        itemText="Attach Credit"
        clickHandler={() => setModalOpen(true)}
        disabled={selectedItem && selectedItem.state === 'paid'}
      />
      {selectedItem && (
        <BillingInvoiceAttachCreditFormModal
          modalOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          selectedItem={selectedItem}
          refetch={refetch}
          customer={customer}
          type={type}
          maxAmount={parseFloat(maxAmount)}
          setMaxAmount={setMaxAmount}
        />
      )}
    </>
  );
}

BillingInvoiceAttachCreditForm.propTypes = {
  selectedItem: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingInvoiceAttachCreditForm;
