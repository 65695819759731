import PropTypes from 'prop-types';

import { CaseActionsButtons } from './CaseActionButtons';

function CaseActions(props) {
  const {
    parsedCase,
    fileIndex,
    triggerSubmit,
    cancelSubmit,
    signAndSubmit,
    arrayHelpers,
    submit,
  } = props;

  return (
    <div className="column is-narrow is-relative pt-4">
      <CaseActionsButtons
        parsedCase={parsedCase}
        fileIndex={fileIndex}
        triggerSubmit={triggerSubmit}
        cancelSubmit={cancelSubmit}
        signAndSubmit={signAndSubmit}
        arrayHelpers={arrayHelpers}
        submit={submit}
      />
    </div>
  );
}

CaseActions.propTypes = {
  parsedCase: PropTypes.object.isRequired,
  fileIndex: PropTypes.number.isRequired,
  triggerSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  signAndSubmit: PropTypes.func.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  submit: PropTypes.bool.isRequired,
};

export default CaseActions;
