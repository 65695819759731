import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionAdminUserDeactivate($input: AdminUserDeactivateActionInput!) {
    actionAdminUserDeactivate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      user {
        id
        state
      }
    }
  }
  ${ErrorMessageInfo}
`;
