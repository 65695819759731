import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCustomerLocationRoute(
    $input: CustomerLocationRouteUpdateInput!
  ) {
    updateCustomerLocationRoute(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerLocation {
        id
        name
        slug
        siloRoutes {
          id
          designTypeCategoryId
          primarySiloId
          secondarySiloId
          catchAllSiloId
          designTypeCategory {
            id
            name
          }
        }
        priceListCategories {
          id
          name
          designLocations {
            id
            name
            key
            text
            value
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
