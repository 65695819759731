import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignGuideOrderContext } from '../../../Context';
import CheckboxField from '../../../FormFields/CheckboxInputs/CheckboxField';

function GuideApplyToAll({
  designType,
  applyToAll,
  setApplyToAll,
  hideApplyAll = false,
}) {
  const { allowNested } = useContext(DesignGuideOrderContext);

  const { t } = useTranslation('designProfile');

  const toggleCheck = () => {
    setApplyToAll((prev) => !prev);
  };

  return designType.designTypeName && !hideApplyAll && !allowNested ? (
    <Field
      name="applyToAll"
      component={CheckboxField}
      checked={applyToAll}
      customToggle={toggleCheck}
      customTranslation
      labelText={t('Apply to all unsent {{designTypeName}} case items', {
        designTypeName: designType.designTypeName,
      })}
      size="is-small"
    />
  ) : null;
}

GuideApplyToAll.propTypes = {
  designType: PropTypes.object.isRequired,
  applyToAll: PropTypes.bool.isRequired,
  hideApplyAll: PropTypes.bool,
  setApplyToAll: PropTypes.func.isRequired,
};

export default GuideApplyToAll;
