import { gql } from '@apollo/client';

const CreditCardInfo = gql`
  fragment CreditCardInfo on CreditCard {
    id
    nameOnCard
    organizationId
    locationId
    defaultCard
    expirationDate
    brand
    maskedNumber
    createdAt
  }
`;

export default CreditCardInfo;
