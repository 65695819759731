import PropTypes from 'prop-types';

function SearchResultsItem({ result, resultRenderer: Component = null }) {
  return Component ? <Component {...result} /> : <li>{result.name}</li>;
}

SearchResultsItem.propTypes = {
  result: PropTypes.object.isRequired,
  resultRenderer: PropTypes.any,
};

export default SearchResultsItem;
