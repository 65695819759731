import { gql } from '@apollo/client';

const DebitInfo = gql`
  fragment DebitInfo on Debit {
    id
    debitNumber
    hexDebitNumber
    organizationId
    businessUnitId
    businessUnit {
      id
      name
    }
    organization {
      id
      name
      slug
    }
    locationId
    location {
      id
      name
      slug
    }
    invoice {
      id
      hexInvoiceNumber
    }
    applied
    reason
    amount
    createdAt
    updatedAt
  }
`;

export default DebitInfo;
