import PropTypes from 'prop-types';

function Tooth5({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 5)
    : upperArch;
  const variable = designType.toothChart ? 5 : 'upper';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[5]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st2 activeTooth ${selected ? 'toothSelected' : ''}` : 'st2'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M883.2 955c-81.6 28.4-87 67.6-87.4 107.5 -4.2 175.5 144.9 172.6 173.7 159.1 45.4 5.6 76.9 0.4 101.8-10.1 46.3-3.6 68.7-31.3 76.1-73.9 6.8-22.9 9.1-48.1-2.4-80.2 -8.4-17.9-2.7-35.1-54.2-55.3 -54.3-6-71.5-19.8-93.9-34.7C964.7 932.4 924.6 940 883.2 955L883.2 955z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M960.8 955.2c0.4 8.6 6.6 17.3-14.7 25.8 -37 30-34.5 52.2-26.1 73.2 17.1 32.5 11 47.9-6.2 55.2 -53.3 51.7-41.2 62.3-55.4 89.5"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M894.7 1172.2c49.8-7.1 90-26.8 101.1-84.2 5.9-33.3-13.4-52.2-39.5-67.3"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M1075.2 1037.4c-65.1 6.5-86.1 23.2-77.7 43.8 -0.2 54.1 17 41.3 29.2 58.7"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M967.1 1175.9c9.2-12 6.1-24.1 1.8-36.2"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M1021 1021.7c-22.7 20.9-25 31.7-25.1 41.3"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st2"
        d="M1096 1016.7c16 35.6 0.1 64.8 3.2 90.5 3 35.9-7.7 66.4-45.9 86.1 -23.5 13.2-52.1 15.9-77.6 17.9"
      />
    </>
  );
}

Tooth5.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth5;
