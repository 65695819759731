import { gql } from '@apollo/client';

const DesignPreferenceInfo = gql`
  fragment DesignPreferenceInfo on DesignPreference {
    id
    name
    slug
    values
    designPreferenceProfileId
  }
`;

export default DesignPreferenceInfo;
