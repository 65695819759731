import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import PaginationStep from './PaginationStep';

function Pagination({ totalAmount, perPage = 5, onPageChange = () => {} }) {
  const [currentPage, setCurrentStep] = useState(0);

  const changePage = useCallback(
    (e) => {
      const newPage = parseInt(e.target.getAttribute('data-page'), 10);
      if (newPage || newPage === 0) {
        setCurrentStep(newPage);
        onPageChange(newPage);
      }
    },
    [onPageChange],
  );

  return (
    <nav
      className="pagination"
      role="navigation"
      aria-label="pagination"
      style={{ width: '100%' }}
    >
      <ul className="pagination-list is-justify-content-center pl-0">
        {Array.from(Array(Math.ceil(totalAmount / perPage)).keys()).map(
          (item) => (
            <PaginationStep
              key={item}
              currentPage={currentPage}
              pageNum={item}
              pageHandler={changePage}
            />
          ),
        )}
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
