import { useApolloClient, useQuery } from '@apollo/client';
import { GET_ORDER_FILES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { OutsideClick, ReactPortal } from '../../../../shared';
import { statusUpdate } from '../../OrderActions/helpers';
import ReviewModalActions from './ReviewModalActions';
import ReviewModalBody from './ReviewModalBody';
import ReviewModalHeader from './ReviewModalHeader';

function ReviewModal({ modalOpen, closeModal, order = null, ...props }) {
  const [
    { acceptTerms, docApproval, message, patientApproval, redesign },
    setState,
  ] = useState({
    redesign: false,
    acceptTerms: false,
    message: null,
    docApproval: false,
    patientApproval: false,
  });

  const client = useApolloClient();

  const { error, loading, data } = useQuery(GET_ORDER_FILES, {
    variables: { orderId: order?.id },
    fetchPolicy: 'no-cache',
    skip: !order,
  });

  function closeAndReset() {
    setState((state) => ({
      ...state,
      redesign: false,
      acceptTerms: false,
      message: null,
      docApproval: false,
      patientApproval: false,
    }));
    closeModal();
  }

  function redesignOff() {
    setState((state) => ({ ...state, redesign: false }));
  }

  function redesignOn() {
    setState((state) => ({
      ...state,
      redesign: true,
      docApproval: false,
      patientApproval: false,
    }));
  }

  function docApprovalOff() {
    setState((state) => ({ ...state, docApproval: false }));
  }

  function docApprovalOn() {
    setState((state) => ({
      ...state,
      docApproval: true,
      redesign: false,
      patientApproval: false,
    }));
  }

  function patientApprovalOff() {
    setState((state) => ({ ...state, patientApproval: false }));
  }

  function patientApprovalOn() {
    setState((state) => ({
      ...state,
      docApproval: false,
      redesign: false,
      patientApproval: true,
    }));
  }

  function toggleTerms() {
    setState((state) => ({ ...state, acceptTerms: !acceptTerms }));
  }

  function changeMessage(e) {
    e.preventDefault();
    setState((state) => ({ ...state, message: e.target.value }));
  }

  function updateStatus(e) {
    statusUpdate(
      client,
      e.target.getAttribute('action'),
      order.id,
      message,
      true,
    );
    closeAndReset();
  }

  return (
    <ReactPortal wrapperId="review-case-modal" show={modalOpen}>
      <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <OutsideClick fn={closeModal}>
          <div
            className="modal-card"
            style={{ width: '80vw', height: '100vh' }}
          >
            <header className="modal-card-head">
              <ReviewModalHeader order={order} closeAndReset={closeAndReset} />
            </header>
            <section className="modal-card-body" style={{ paddingTop: 0 }}>
              <ReviewModalBody
                message={message}
                {...props}
                order={order}
                orderFiles={data?.orderFiles}
                redesign={redesign}
                toggleTerms={toggleTerms}
                error={error}
                loading={loading}
                docApproval={docApproval}
                redesignOff={redesignOff}
                docApprovalOff={docApprovalOff}
                patientApproval={patientApproval}
                patientApprovalOn={patientApprovalOn}
                patientApprovalOff={patientApprovalOff}
                changeMessage={changeMessage}
                // changeEmail={changeEmail}
                updateStatus={updateStatus}
                // createLog={createLog}
                closeAndReset={closeAndReset}
              />
            </section>
            <footer className="modal-card-foot">
              {import.meta.env.VITE_APP_NAME !== 'designer_portal' && (
                <ReviewModalActions
                  {...props}
                  order={order}
                  redesign={redesign}
                  toggleTerms={toggleTerms}
                  acceptTerms={acceptTerms}
                  redesignOn={redesignOn}
                  updateStatus={updateStatus}
                  docApproval={docApproval}
                  docApprovalOn={docApprovalOn}
                  patientApproval={patientApproval}
                  patientApprovalOn={patientApprovalOn}
                  closeAndReset={closeAndReset}
                />
              )}
            </footer>
          </div>
        </OutsideClick>
      </div>
    </ReactPortal>
  );
}

ReviewModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default memo(ReviewModal);
