function initialValues(props) {
  const { selectedItem = {} } = props;

  return {
    id: selectedItem.id,
    memo: selectedItem.memo || '',
  };
}

export default initialValues;
