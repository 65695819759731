import { gql } from '@apollo/client';

export default gql`
  query orderFileTypes {
    orderFileTypes {
      id
      name
      klass
    }
  }
`;
