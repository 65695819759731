import { gql } from '@apollo/client';

const ContactInfo = gql`
  fragment ContactInfo on Contact {
    id
    name
    title
    role
    email
    phone
    extension
    contactableId
    contactableType
    createdAt
  }
`;

export default ContactInfo;
