const updateCache = ({
  existingLabLocationGuideCategoryGroupsRefs,
  designTypeId,
  guideCategoryGroupId,
  guideCategoryId,
  favorite,
  defaulted,
  guideCodeId,
  name,
}) =>
  existingLabLocationGuideCategoryGroupsRefs.map((item) => {
    if (item.designTypeId === designTypeId) {
      return {
        ...item,
        guideCategoryGroups: item.guideCategoryGroups.map(
          (guideCategoryGroup) => {
            if (guideCategoryGroup.id === guideCategoryGroupId) {
              return {
                ...guideCategoryGroup,
                guideCategories: guideCategoryGroup.guideCategories.map(
                  (guideCategory) => {
                    if (guideCategory.id === guideCategoryId) {
                      if (favorite === false || favorite) {
                        return {
                          ...guideCategory,
                          favorite: !favorite,
                        };
                      }
                      if (name) {
                        return {
                          ...guideCategory,
                          guideCodes: [
                            ...guideCategory.guideCodes,
                            {
                              id: guideCodeId,
                              name,
                            },
                          ],
                        };
                      }
                      return {
                        ...guideCategory,
                        guideCodes: guideCategory.guideCodes.map(
                          (guideCode) => ({
                            ...guideCode,
                            default:
                              guideCode.id === guideCodeId ? !defaulted : false,
                          }),
                        ),
                      };
                    }
                    return guideCategory;
                  },
                ),
              };
            }
            return guideCategoryGroup;
          },
        ),
      };
    }
    return item;
  });

export default updateCache;
