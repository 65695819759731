import { gql } from '@apollo/client';
import { CustomerLocationInfo } from '../fragments';

export default gql`
  query customerLocation($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      ...CustomerLocationInfo
    }
  }
  ${CustomerLocationInfo}
`;
