function initialValues(props) {
  const { apiSettings = {}, type, entityId } = props;
  const app = import.meta.env.VITE_APP_NAME;

  let manufacturerOrganizationId;
  let labLocationId;

  if (type === 'manufacturer' || app === 'manuf_portal') {
    manufacturerOrganizationId =
      apiSettings.manufacturerOrganizationId || entityId;
  } else {
    labLocationId = apiSettings.labLocationId || entityId;
  }

  return {
    active:
      apiSettings.active !== null && apiSettings.active !== undefined
        ? apiSettings.active
        : true,
    termsAccepted:
      apiSettings.termsAccepted !== null &&
      apiSettings.termsAccepted !== undefined
        ? apiSettings.termsAccepted
        : false,
    fcClientId: apiSettings.fcClientId || '',
    fcClientSecret: apiSettings.fcClientSecret || '',
    clientId: apiSettings.clientId || '',
    clientSecret: apiSettings.clientSecret || '',
    authServer: apiSettings.authServer || '',
    username: apiSettings.username || '',
    password: apiSettings.password || '',
    callbackUrl: apiSettings.callbackUrl || '',
    authorizationType: apiSettings.authorizationType || '',
    grantType: apiSettings.grantType || '',
    tokenResponseKey: apiSettings.tokenResponseKey || '',
    labLocationId: labLocationId || '',
    manufacturerOrganizationId: manufacturerOrganizationId || '',
  };
}

export default initialValues;
