import PropTypes from 'prop-types';
import { TempOrderActionsButtons } from './TempOrderActionButtons';

function TempOrderActions({
  removeTempOrder,
  tempOrder,
  submitAll,
  setSingleCase,
}) {
  return (
    <div className="column is-narrow is-relative pt-4">
      <TempOrderActionsButtons
        removeTempOrder={removeTempOrder}
        tempOrder={tempOrder}
        submitAll={submitAll}
        setSingleCase={setSingleCase}
      />
    </div>
  );
}

TempOrderActions.propTypes = {
  removeTempOrder: PropTypes.func.isRequired,
  tempOrder: PropTypes.object.isRequired,
  submitAll: PropTypes.bool.isRequired,
  setSingleCase: PropTypes.func.isRequired,
};

export default TempOrderActions;
