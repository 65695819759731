import { gql } from '@apollo/client';
import { CreditInfo } from '../fragments';

export default gql`
  query credits(
    $labLocationId: ID
    $labOrganizationId: ID
    $applied: Boolean
    $excludeExpired: Boolean
  ) {
    credits(
      labLocationId: $labLocationId
      labOrganizationId: $labOrganizationId
      applied: $applied
      excludeExpired: $excludeExpired
    ) {
      ...CreditInfo
    }
  }
  ${CreditInfo}
`;
