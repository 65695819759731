import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderInfo } from '../fragments';

export default gql`
  mutation createCustomerOrderByTemp($input: OrderCustomerByTempCreateInput!) {
    createCustomerOrderByTemp(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        ...OrderInfo
      }
    }
  }
  ${OrderInfo}
  ${ErrorMessageInfo}
`;
