import OrganizationShowNavMenu from '../../shared/NavMenus/Organizations/OrganizationShowNavMenu';

const routes = [
  {
    path: [
      '/organizations',
      '/organizations/billing',
      '/organizations/billing/invoices/:hexInvoiceNumber',
    ],
    component: OrganizationShowNavMenu,
    so: true,
    sl: false,
  },
];

export default routes;
