import { useApolloClient } from '@apollo/client';
import { DESTROY_DEBIT, UPDATE_DEBIT_STATUS } from '@fullcontour/shared-api';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../config/log';
import { CreateNotification } from '../../../shared';

function useDebit() {
  const client = useApolloClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [action, setAction] = useState(null);
  const [debit, setDebit] = useState({});

  const closeModal = () => {
    setAction(null);
    setDebit({});
    setModalState(false);
  };

  const openModal = (act, deb) => {
    setAction(act);
    setDebit(deb);
    setModalState(true);
  };

  const destroyDebit = async (deb, refetch) => {
    await client.mutate({
      mutation: DESTROY_DEBIT,
      variables: {
        input: {
          id: deb.id,
          clientMutationId: uuidv4(),
        },
      },
    });

    closeModal();
    refetch();
  };

  const statusUpdate = (statusAction, deb, refetch) => {
    client
      .mutate({
        mutation: UPDATE_DEBIT_STATUS,
        variables: {
          input: {
            input: {
              id: deb.id,
              statusAction,
            },
            clientMutationId: uuidv4(),
          },
        },
      })
      .then(() => {
        CreateNotification.createNotification({
          type: 'success',
          message: `Status was changed to "${statusAction}"`,
        });
        refetch();
      })
      .catch((err) => {
        Log.error(err);
      });
  };

  const onSelectionChanged = (event) => {
    const selectedItems = event.api.getSelectedNodes();
    const selected = selectedItems.length > 0 ? selectedItems[0].data : null;

    setSelectedItem(selected);
  };

  return {
    selectedItem,
    setSelectedItem,
    action,
    debit,
    closeModal,
    openModal,
    destroyDebit,
    statusUpdate,
    onSelectionChanged,
    modalState,
  };
}

export default useDebit;
