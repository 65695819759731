import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsDesignerRedesign(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    masterDetail: true,
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Role',
        field: 'role',
        width: 200,
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Design Org',
        field: 'orgName',
        width: 200,
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Silo',
        field: 'locName',
        width: 200,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'redesignTotal',
        headerName: 'Redesign Total',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
    ],
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'orderCode',
            headerName: 'Orders',
            minWidth: 120,
            filter: 'agTextColumnFilter',
          },
          {
            field: 'redesignTotal',
            headerName: 'Redesign Total',
            minWidth: 120,
            type: 'numericColumn',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      getDetailRowData: (params) =>
        params.successCallback(params.data.orderRedesigns),
    },
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsDesignerRedesign.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsDesignerRedesign;
