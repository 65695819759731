import PropTypes from 'prop-types';
import Styles from './PreferenceMetadata.module.css';

function PreferenceMetadata({ value = null }) {
  return value ? (
    <span className={Styles.preferenceMetadata}>{value}</span>
  ) : null;
}

PreferenceMetadata.propTypes = {
  value: PropTypes.string,
};

export default PreferenceMetadata;
