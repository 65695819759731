/* eslint-disable no-use-before-define */
import { useMutation } from '@apollo/client';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FieldContainer } from '../../FieldContainer';
import { activeStyle, baseStyle, rejectedStyle } from './FileDropzoneStyle';

function FileDropzone({
  field: { name, value },
  form: { setFieldValue },
  labelText = null,
  descriptionText = null,
  accept = {
    'image/*': ['.jpeg', '.png', '.jpg'],
  },
}) {
  const { t } = useTranslation('formfields');
  const [preview, setPreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { isDragActive, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      accept,
      multiple: false,
      onDrop: (accepted) => onDrop(accepted),
    });

  const [signS3] = useMutation(REQUEST_S3_SIGN);

  useEffect(() => () => URL.revokeObjectURL(preview), []);

  const formatFilename = (filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  };

  const onDrop = async (accepted) => {
    const droppedFile = accepted[0];
    const previewImgURL = URL.createObjectURL(droppedFile);
    setPreview(previewImgURL);
    await signAndSubmit(droppedFile);
  };

  const uploadToS3 = async (signedRequest, droppedFile) => {
    setUploading(true);
    const options = {
      headers: {
        'Content-Type': droppedFile.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          setUploadProgress(100);
          setUploading(false);
        } else {
          setUploadProgress(percent);
        }
      },
    };

    await axios.put(signedRequest, droppedFile, options);
  };

  const signAndSubmit = async (droppedFile) => {
    const s3Key = `assets/images/l/${formatFilename(droppedFile.name)}`;

    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: droppedFile.name,
            filename: s3Key,
            acl: 'public-read',
            filetype: droppedFile.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = response.data.signS3.s3Response;

    await uploadToS3(signedRequest, droppedFile);

    setFieldValue(name, url);
  };

  const previewSrc = () => {
    if (preview) {
      return preview;
    }

    if (value !== '') {
      return value;
    }

    return `https://s3-us-west-2.amazonaws.com/${
      import.meta.env.VITE_ASSET_BUCKET
    }/assets/images/l/default.svg`;
  };

  let styles = { ...baseStyle };
  styles = isDragActive ? { ...styles, ...activeStyle } : styles;
  styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;
  return (
    <FieldContainer name={name} label={labelText || name}>
      <figure className="has-text-centered" style={styles} {...getRootProps()}>
        <img
          className="image is-fullwidth"
          src={previewSrc()}
          alt="upload preview"
          width="140px"
        />
        <input {...getInputProps()} />
        <figcaption>
          {!uploading && (
            <p style={{ fontSize: '0.9rem' }}>
              {isDragActive ? (
                <span>{`${t('Drop logo here')}...`}</span>
              ) : (
                <span>
                  {t(
                    descriptionText ||
                      `Drop a logo here, or click / tap to select a file to
                        upload`,
                  )}
                </span>
              )}
            </p>
          )}
          {uploading && (
            <progress
              value={uploadProgress}
              max="100"
              className={`progress is-small caseProgressBar ${
                uploadProgress > 99 ? 'is-success' : 'is-primary'
              }`}
            />
          )}
        </figcaption>
      </figure>
    </FieldContainer>
  );
}

FileDropzone.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  labelText: PropTypes.string,
  descriptionText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FileDropzone;
