import { useApolloClient, useReactiveVar } from '@apollo/client';
import { sendingTempOrders, UpdateCache } from '@fullcontour/common';
import {
  DESTROY_TEMP_ORDER,
  GET_TEMP_ORDERS_COUNT,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TempOrderCases } from './TempOrderCases';
import { TempOrderHeader } from './TempOrderHeader';

function TempOrdersList(props) {
  const {
    queriedTempOrders,
    customerLocation,
    softwareNodes,
    labLocationGuideCategoryGroups,
  } = props;
  const client = useApolloClient();
  const [submitAll, setSubmitAll] = useState(false);
  const submittingTempOrders = useReactiveVar(sendingTempOrders);

  const removeTempOrder = async (tempOrderId) => {
    await client.mutate({
      mutation: DESTROY_TEMP_ORDER,
      variables: {
        input: {
          id: tempOrderId,
          clientMutationId: uuidv4(),
        },
      },
      update: (cache, { data: { destroyTempOrder } }) => {
        if (!destroyTempOrder.errorMessages?.length) {
          UpdateCache.filterElementFromCache({
            cache,
            id: tempOrderId,
            field: 'tempOrders',
          });
        }
      },
      refetchQueries: [{ query: GET_TEMP_ORDERS_COUNT }],
    });
  };

  function removeAllTempOrders() {
    queriedTempOrders.forEach((tempOrder) => removeTempOrder(tempOrder.id));
  }

  return (
    <>
      <TempOrderHeader
        setSubmitAll={() => setSubmitAll(true)}
        removeAllTempOrders={removeAllTempOrders}
        tempOrders={queriedTempOrders}
        submitAll={submitAll}
      />
      {submittingTempOrders.totalCases ? (
        <progress
          value={submittingTempOrders.casesSent}
          max={submittingTempOrders.totalCases}
          className="progress is-primary"
          style={{
            width: '100%',
          }}
        >
          Sending Pending cases...
        </progress>
      ) : null}
      <TempOrderCases
        submitAll={submitAll}
        showDimmer={!!submittingTempOrders.totalCases}
        removeTempOrder={removeTempOrder}
        tempOrders={queriedTempOrders}
        labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
        customerLocation={customerLocation}
        softwareNodes={softwareNodes}
        {...props}
      />
    </>
  );
}

TempOrdersList.propTypes = {
  queriedTempOrders: PropTypes.array.isRequired,
  customerLocation: PropTypes.object.isRequired,
  softwareNodes: PropTypes.array.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array.isRequired,
};

TempOrdersList.defaulProps = {
  queriedTempOrders: [],
};

export default TempOrdersList;
