import { gql } from '@apollo/client';

export default gql`
  query orderByFilename($filename: String!, $byHex: Boolean) {
    orderByFilename(filename: $filename, byHex: $byHex) {
      id
      intOrderId
      labLocationId
      hexOrderCode
    }
  }
`;
