import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BaseLoader({
  title = '',
  titlePrefix = 'Loading ',
  hideText = false,
  inverted = false,
}) {
  const { t } = useTranslation('navigation');

  return (
    <div className={`loader-wrapper is-active ${inverted ? 'inverted' : ''}`}>
      <div className="loader is-loading" />
      {!hideText && (
        <div>
          <p className="loader-text">{t(`${titlePrefix}${title}`)}</p>
        </div>
      )}
    </div>
  );
}

BaseLoader.propTypes = {
  title: PropTypes.string,
  titlePrefix: PropTypes.string,
  hideText: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default BaseLoader;
