import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import CutLineElementInner from './CutLineElementInner';

function CutLineElement(props) {
  const { ptsData } = props;

  const currentPts = useMemo(() => ptsData.data, [ptsData]);

  return currentPts ? <CutLineElementInner currentPts={currentPts} /> : null;
}

CutLineElement.propTypes = {
  ptsData: PropTypes.object.isRequired,
};

export default memo(CutLineElement);
