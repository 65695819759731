import { gql } from '@apollo/client';

export default gql`
  query designTypesDropdown(
    $designPreferenceProfileId: ID
    $designPreferenceProfileBlank: Boolean
  ) {
    designTypes(
      designPreferenceProfileId: $designPreferenceProfileId
      designPreferenceProfileBlank: $designPreferenceProfileBlank
    ) {
      id
      name
      value
    }
  }
`;
