import * as Yup from 'yup';

function schema() {
  return Yup.object({
    email: Yup.string().email().required('Email is required'),
    subject: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
  });
}

export default schema;
