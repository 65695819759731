import CadFileForm from '@fullcontour/common/src/components/views/CadFiles/CadFileForm/CadFileForm';

const routes = [
  {
    path: '/locations/:slug/dme-files/:action',
    component: CadFileForm,
    sl: false,
    so: true,
  },
  {
    path: '/locations/dme-files/:action',
    component: CadFileForm,
    sl: true,
    so: false,
  },
];

export default routes;
