import { ChartSelection } from '@fullcontour/common';
import PropTypes from 'prop-types';
import FieldMetadataWrapper from './FieldMetadataWrapper';

function FieldMetadataArches({
  orderItem,
  selectedDesignType = null,

  customerLocation,
  index,
}) {
  return (
    <FieldMetadataWrapper
      title="Arches"
      //   infoText="Depending on the order type, you will see either an interactive arch
      // chart or tooth chart when you click on these icons."
    >
      <ChartSelection
        customerLocation={customerLocation}
        index={index}
        selectedDesignType={selectedDesignType}
      />
      {orderItem.arches && <span>{orderItem.arches.join(', ')}</span>}
    </FieldMetadataWrapper>
  );
}

FieldMetadataArches.propTypes = {
  orderItem: PropTypes.object.isRequired,
  selectedDesignType: PropTypes.object,
  customerLocation: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default FieldMetadataArches;
