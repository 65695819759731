/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { exportSelectedCsv } from '../helpers';

function OrderActionCsv(props) {
  const { actionTitle, details, showDetails, list, gridApi } = props;
  const { t } = useTranslation('orderactions');

  return (details && showDetails) || list ? (
    <a className="dropdown-item" onClick={() => exportSelectedCsv(gridApi)}>
      {t(actionTitle)}
    </a>
  ) : null;
}

OrderActionCsv.propTypes = {
  gridApi: PropTypes.object.isRequired,
  actionTitle: PropTypes.string.isRequired,
  details: PropTypes.bool.isRequired,
  list: PropTypes.bool.isRequired,
  showDetails: PropTypes.bool.isRequired,
};

export default OrderActionCsv;
