import accountMenuRoutes from './accountMenuRoutes';
import helpCenterRoutes from './helpCenterRoutes';
import locationRoutes from './locationRoutes';
import orderRoutes from './orderRoutes';
import organizationRoutes from './organizationRoutes';
import profileRoutes from './profileRoutes';
import reportRoutes from './reportRoutes';
import userRoutes from './userRoutes';

const combinedRoutes = [
  ...accountMenuRoutes,
  ...locationRoutes,
  ...organizationRoutes,
  ...reportRoutes,
  ...userRoutes,
  ...profileRoutes,
  ...orderRoutes,
  ...reportRoutes,
  ...helpCenterRoutes,
];

export default combinedRoutes;
