import { gql } from '@apollo/client';

const ErrorMessageInfo = gql`
  fragment ErrorMessageInfo on UserError {
    path
    message
  }
`;

export default ErrorMessageInfo;
