import PropTypes from 'prop-types';
import {
  InvoicesListQueryWrapper,
  useInvoice,
} from '../../Invoices/InvoicesList';

function BillingInvoices(props) {
  const {
    onSelectionChanged,
    selectedItem,
    statusUpdate,
    resendInvoice,
    resendInvoiceTransaction,
    modalState,
    openModal,
    closeModal,
    getCsv,
    downloadPDF,
  } = useInvoice();

  return (
    <InvoicesListQueryWrapper
      {...props}
      onSelectionChanged={onSelectionChanged}
      downloadPDF={downloadPDF}
      selectedItem={selectedItem}
      statusUpdate={statusUpdate}
      resendInvoice={resendInvoice}
      resendInvoiceTransaction={resendInvoiceTransaction}
      modalOpen={modalState}
      openModal={openModal}
      closeModal={closeModal}
      getCsv={getCsv}
    />
  );
}

BillingInvoices.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingInvoices;
