import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { typeName } from '../../../../transforms';
import { getRoot } from '../../../../utils/functions';

function AccountMenuNew() {
  const { type, action } = useParams();
  const root = getRoot();

  const { t } = useTranslation('manual');

  return (
    <div className="block" style={{ margin: '0.75em' }}>
      <h6 className="title is-6 is-capitalized">
        {t('newTypeHelpTitle', {
          action: action === 'new' ? t('add') : t('update'),
          type,
          typeName: typeName(root, type),
          updateOrCreate: action === 'new' ? t('create') : t('update'),
        })}
      </h6>
      <p className="mt-3">
        {t('addOrUpdate', {
          action: action === 'new' ? t('add') : t('update'),
          typeName: t(typeName(root, type)),
          type: t(type),
          updateOrCreate: action === 'new' ? t('create') : t('update'),
        })}
      </p>
    </div>
  );
}

export default AccountMenuNew;
