import { ACTION_REASSIGN_SILO } from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../config/log';

function reassignOrders(
  client,
  orderIds,
  designLocationId,
  setSuccess,
  setFailure,
  autoReassign,
  query,
  orderStatus,
) {
  client
    .mutate({
      mutation: ACTION_REASSIGN_SILO,
      variables: {
        input: {
          input: {
            orderIds,
            designLocationId,
            autoReassign,
          },
          clientMutationId: uuidv4(),
        },
      },
      refetchQueries: [
        {
          query,
          variables: { status: orderStatus },
        },
      ],
    })
    .then(() => {
      setSuccess();
    })
    .catch((err) => {
      Log.error(err);
      setFailure();
    });
}

export default reassignOrders;
