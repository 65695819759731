import PropTypes from 'prop-types';

function emailRenderer({ value }) {
  if (!value) {
    return null;
  }

  return <a href={`mailto:${value}`}>{value}</a>;
}

emailRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export default emailRenderer;
