import * as Yup from 'yup';

function schema(props) {
  if (props.action === 'New') {
    return Yup.object({
      id: Yup.string().nullable(true),
      nameOnCard: Yup.string().required('Required'),
      organizationId: Yup.string().required('Required'),
      locationId: Yup.string(),
      defaultCard: Yup.boolean().required(),
      expirationDate: Yup.string()
        .matches(
          /(0[1-9]|10|11|12)\/20[0-9]{2}$/,
          'Must be in the format MM/yyyy',
        )
        .required('Expiration Date is Required'),
      brand: Yup.string().required(),
      cardNumber: Yup.string().required('Credit Card Number is Required'),
      cvv: Yup.string()
        .matches(/^[0-9]{3,4}$/, 'CVV Must only be 3 or 4 digits')
        .required('CVV Code is Required'),
      billingAddress: Yup.object({
        address: Yup.string().required('Address is Required'),
        address2: Yup.string(),
        city: Yup.string(),
        region: Yup.string().required('Region is required'),
        postalCode: Yup.string(),
        country: Yup.string().required('Country is required'),
      }),
    });
  }

  return Yup.object({
    id: Yup.string().nullable(true),
    nameOnCard: Yup.string().required('Required'),
    organizationId: Yup.string().required('Required'),
    locationId: Yup.string().required('Required'),
    defaultCard: Yup.boolean().required(),
    expirationDate: Yup.string()
      .matches(
        /(0[1-9]|10|11|12)\/20[0-9]{2}$/,
        'Must be in the format MM/yyyy',
      )
      .required('Expiration Date is Required'),
    brand: Yup.string().required(),
    cardNumber: Yup.string(),
    cvv: Yup.string().matches(/^[0-9]{3,4}$/, 'CVV Must only be 3 or 4 digits'),
    billingAddress: Yup.object({
      address: Yup.string().required('Address is Required'),
      address2: Yup.string(),
      city: Yup.string(),
      region: Yup.string().required('Region is required'),
      postalCode: Yup.string(),
      country: Yup.string().required('Country is required'),
    }),
  });
}

export default schema;
