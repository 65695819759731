import { useAmplify } from '@fullcontour/auth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SignInSignUpButton({ app = null }) {
  const { isSignedIn, currentUser } = useAmplify();
  const { t } = useTranslation('signin');

  return app === 'customer_portal' && !isSignedIn && !currentUser ? (
    <div className="mt-5">
      {t("Don't have an account?")}{' '}
      <a href={import.meta.env.VITE_CUSTOMER_SIGNUP_URL}>{t('Sign up')}</a>
    </div>
  ) : null;
}

SignInSignUpButton.propTypes = {
  app: PropTypes.string,
};

export default SignInSignUpButton;
