import { BoxIcon, OverpanelFilesContext } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function CaseActionsButtonRemoveItem({ fileIndex }) {
  const { t } = useTranslation('orders');

  const { removeFile } = useContext(OverpanelFilesContext);

  return (
    <button
      type="button"
      className="button is-small mt-1 px-5 has-tooltip-arrow has-tooltip-left is-danger is-outlined"
      onClick={(event) => removeFile(event, { fileIndex })}
      data-tooltip={t('Remove this Case')}
    >
      <BoxIcon name="bx-trash" />
    </button>
  );
}

CaseActionsButtonRemoveItem.propTypes = {
  fileIndex: PropTypes.number.isRequired,
};

export default CaseActionsButtonRemoveItem;
