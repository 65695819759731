import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../../shared';
import ReviewModalBodyContent from './ReviewModalBodyContent';
import ReviewModalBodyDrApproval from './ReviewModalBodyDrApproval';
import ReviewModalBodyPatientLink from './ReviewModalBodyPatientLink';
import ReviewModalBodyRedesign from './ReviewModalBodyRedesign';

function ReviewModalBody({ error = null, loading = false, ...rest }) {
  if (error || loading) {
    return <LoadingOrError error={error} loading={loading} title="Files" />;
  }

  return (
    <div>
      <ReviewModalBodyContent {...rest} error={error} loading={loading} />
      <ReviewModalBodyRedesign {...rest} error={error} loading={loading} />
      <ReviewModalBodyDrApproval {...rest} error={error} loading={loading} />
      <ReviewModalBodyPatientLink {...rest} error={error} loading={loading} />
    </div>
  );
}

ReviewModalBody.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
};

export default ReviewModalBody;
