import { gql } from '@apollo/client';

const CustomerOrganizationInfo = gql`
  fragment CustomerOrganizationInfo on CustomerOrganization {
    id
    name
    value
    slug
    createdAt
    updatedAt
    shortName
    totalLocations
    language
    numberingSystem
    paymentTermId
    restricted
    priceListId
    address
    geoData
    website
    phone
    logoUrl
    administratorLocationId
    administratorLocation {
      id
      name
    }
    viewerSetting {
      simpleViewer
      theme
    }
  }
`;

export default CustomerOrganizationInfo;
