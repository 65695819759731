import { useQuery } from '@apollo/client';
import {
  CadFilesListDataTable,
  CurrentUserContext,
  getRoot,
  LoadingOrError,
  ShowBodySection,
} from '@fullcontour/common';
import { GET_CUSTOMER_LOC } from '@fullcontour/shared-api';
import { useContext } from 'react';
import { useParams } from 'react-router';
import {
  ShowContainer,
  ShowHeaderLeftContent,
} from '../../../shared/ShowElements';
import UsersListDataTable from '../../Users/UsersList/UsersListDataTable';
import OrganizationLink from './OrganizationLink';

function LocationShowInner(props) {
  const { slug } = useParams();
  const root = getRoot();

  const { currentUser } = useContext(CurrentUserContext);

  const { error, loading, data } = useQuery(GET_CUSTOMER_LOC, {
    fetchPolicy: 'cache-and-network',
    variables: { slug },
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Location" />;

  const { customerLocation: location } = data;

  let address;
  if (location) {
    address = location.address;
  }

  return location ? (
    <ShowContainer
      title={location.name}
      code={location.code}
      customerTier={location.customerTier}
      logoUrl={location.logoUrl}
      editLink={
        currentUser?.scopeToLocation ? `/${root}/edit` : `/${root}/${slug}/edit`
      }
      leftContent={
        <ShowHeaderLeftContent
          address={address}
          phone={location.phone}
          website={location.website}
          geoData={location.geoData}
        />
      }
      createdAt={location.createdAt}
      updatedAt={location.updatedAt}
      subHeaderLabel="Member Of:"
      subHeader={
        currentUser?.scopeToLocation ? (
          `${location.organization.name} Organization`
        ) : (
          <OrganizationLink type="customer" location={location} />
        )
      }
    >
      <>
        <ShowBodySection title="Users">
          <UsersListDataTable locationId={location.id} shortContainer />
        </ShowBodySection>

        <ShowBodySection
          title="DME Files"
          to={
            currentUser?.scopeToLocation
              ? `/locations/dme-files/new`
              : `/locations/${slug}/dme-files/new`
          }
          linkTitle="New DME File"
        >
          <CadFilesListDataTable cadFiles={location.cadFiles} {...props} />
        </ShowBodySection>
      </>
    </ShowContainer>
  ) : null;
}

export default LocationShowInner;
