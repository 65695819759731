import PropTypes from 'prop-types';

function orderLocationRenderer(props) {
  const { colDef, data } = props;

  const locationName = data?.location?.name || '';
  const locationCode = data?.location?.code || '';
  const locationSlug = data?.location?.slug || '';

  return (
    <a
      href={`/locations/customer/${locationSlug}`}
      style={{ color: '#1B77C8' }}
    >
      {colDef.headerName === 'Customer Code' ? locationCode : locationName}
    </a>
  );
}

orderLocationRenderer.propTypes = {
  colDef: PropTypes.shape({
    headerName: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    location: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
};

export default orderLocationRenderer;
