import { gql } from '@apollo/client';

export default gql`
  mutation destroyQuotaProfile($input: QuotaProfileDestroyInput!) {
    destroyQuotaProfile(input: $input) {
      clientMutationId
      quotaProfile {
        id
      }
    }
  }
`;
