import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

function StlElement({ downloadUrl }) {
  const stlRef = useRef();
  const geom = useLoader(STLLoader, downloadUrl);

  return (
    <mesh ref={stlRef} rotation={[-Math.PI / 2, 0, 0]}>
      <primitive object={geom} attach="geometry" />
      <meshPhysicalMaterial
        color={0xc3c3cd}
        reflectivity={0}
        clearcoat={0.15}
        metalness={0.9}
        wireframe={false}
        attach="material"
      />
    </mesh>
  );
}

StlElement.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
};

export default StlElement;
