import { gql } from '@apollo/client';
import { OrderHoldReasonInfo } from '../fragments';

export default gql`
  mutation destroyOrderHoldReason($input: OrderHoldReasonDestroyInput!) {
    destroyOrderHoldReason(input: $input) {
      clientMutationId
      orderHoldReason {
        ...OrderHoldReasonInfo
      }
    }
  }
  ${OrderHoldReasonInfo}
`;
