const initialValues = {
  businessUnitName: '', // x
  name: '', // x
  instructions: '', // x
  files: [],
  orderItems: [
    {
      units: 0,
      materialName: '',
      guideCodes: [],
      teeth: [],
      arches: [],
      manufacturerOrganizationId: '',
      orderItemModifiersAttributes: [], // x
      designTypeId: '', // x
      splitFile: false,
      printerId: '', // x
    },
  ], // x
  turnaroundTimeId: '', // x
  requiresDesignApproval: true, // x,
  software: '',
  intOrderId: '',
};
export default initialValues;
