import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ElementActions from './FilesShowElements/ElementActions/ElementActions';

function FilesShowHeader({
  selectedFolder = null,
  selectedFile = null,
  order,
  client,
  refetch,
  setSelectedToDefault,
}) {
  const { t } = useTranslation('caseFiles');

  let type;

  if (selectedFile) {
    type = selectedFile.fileType;
  }

  if (selectedFolder) {
    type = selectedFolder.fileType;
  }

  return (
    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-content-center">
      <div>
        <h5
          className="title is-5 has-text-primary"
          style={{
            marginBottom: 0,
            display: 'inline',
            verticalAlign: 'middle',
          }}
        >
          {t('File Details')}{' '}
        </h5>
        {(selectedFolder || selectedFile) && (
          <span className="tag" style={{ textTransform: 'capitalize' }}>
            {type.replace(/_/g, ' ')}
          </span>
        )}
      </div>

      <div>
        <ElementActions
          redesignCount={order.redesignCount}
          client={client}
          order={order}
          refetch={refetch}
          selectedFolder={selectedFolder}
          selectedFile={selectedFile}
          setSelectedToDefault={setSelectedToDefault}
        />
      </div>
    </div>
  );
}

FilesShowHeader.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
  order: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  setSelectedToDefault: PropTypes.func.isRequired,
};

export default FilesShowHeader;
