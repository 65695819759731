import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateGuideCategoryGroup($input: GuideCategoryGroupUpdateInput!) {
    updateGuideCategoryGroup(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      guideCategoryGroup {
        slug
      }
    }
  }
  ${ErrorMessageInfo}
`;
