import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function CadFileDestroyForm({ cadFileId, destroy }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        destroy({
          variables: {
            input: { clientMutationId: uuidv4(), id: cadFileId },
          },
        });
      }}
    >
      <button className="button is-danger is-outlined" type="submit">
        <span className="icon is-small">
          <i className="bx bx-trash" />
        </span>
      </button>
    </form>
  );
}

CadFileDestroyForm.propTypes = {
  cadFileId: PropTypes.string.isRequired,
  destroy: PropTypes.func.isRequired,
};

export default CadFileDestroyForm;
