import { useQuery } from '@apollo/client';
import { GET_MESSAGE_UPLOADER_ORDER } from '@fullcontour/shared-api';
import format from 'date-fns/format';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { BoxIcon, FieldContainer, FormSection } from '../../../../shared';
import BasicDropdown from '../../../../shared/FormFields/Dropdowns/BasicDropdown';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import './OrderShowMessages.css';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginLeft: '10px',
  position: 'relative',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  width: 60,
  height: 60,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  justifyContent: 'center',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const baseStyle = {
  display: 'flex',
  borderRadius: 2,
  border: '2px dashed rgba(0,0,0,0)',
  color: 'transparent',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  padding: '10px 20px',
};

const activeStyle = {
  borderColor: 'green',
};

const acceptStyle = {
  borderColor: '#1b77c8',
};

const rejectStyle = {
  borderColor: 'blue',
};

function OrderShowMessageFormCustomerInner({
  orderState,
  uploadPercentage,
  sendDisable,
  values,
  onSubmit,
  setValues,
  submitForm,
}) {
  const { files = [] } = values;
  const { t } = useTranslation('caseActivity');
  const deleteImagePreviewHandler = (id) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setValues({ ...values, files: updatedFiles });
  };
  const formatFilename = (filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  };
  const thumbs = useMemo(
    () =>
      files.map((file) => (
        <div style={thumb} key={file.id} className="messages__image-preview">
          <div style={thumbInner}>
            <img
              src={URL.createObjectURL(file)}
              alt={file.preview}
              style={img}
            />
            <BoxIcon
              name="bx-x bx-md"
              onClick={() => deleteImagePreviewHandler(file.id)}
            />
          </div>
        </div>
      )),
    [files],
  );
  const {
    getInputProps,
    getRootProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.svg'],
    },
    onDrop: (acceptedFiles) => {
      const updatedFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: uuidv4(),
            formattedFilename: formatFilename(file.name),
          }),
        ),
      ];
      setValues({ ...values, files: updatedFiles });
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      width: '100%',
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );
  const { id } = useParams();
  const { data } = useQuery(GET_MESSAGE_UPLOADER_ORDER, {
    variables: { id },
  });
  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (data) {
      setValues((state) => ({ ...state, orderData: data.order }));
    }
  }, [data]);
  const holdButtonHandler = (releaseHold) => {
    setValues((state) => ({
      ...state,
      releaseHold,
    }));

    submitForm();
  };
  const sendButton = (type) => (
    <button
      type={type}
      className={`button is-fullwidth is-primary ${
        sendDisable && 'is-loading'
      }`}
      disabled={sendDisable}
    >
      <span className="icon">
        <i className="bx bxs-paper-plane" />
      </span>
      <span>{t('Send', { ns: 'common' })}</span>
    </button>
  );
  return (
    <div {...getRootProps({ className: 'dropzone p-0', style })}>
      <form noValidate onSubmit={onSubmit}>
        {uploadPercentage > 0 && (
          <div style={{ width: '100%', position: 'absolute', left: 0, top: 0 }}>
            <progress
              value={uploadPercentage}
              max="100"
              className={`progress is-small ${
                uploadPercentage === 100 ? 'is-success' : 'is-primary'
              }`}
            />
          </div>
        )}
        <input {...getInputProps()} />
        <FormSection
          flex
          fullWidth
          className="my-0 px-5 py-0"
          style={{
            color: 'grey',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{ display: 'flex' }}
            className="messages__message-wrapper"
          >
            <FieldContainer name="message" hideLabel>
              <Field
                id="message"
                name="message"
                placeholder={t('Fill out a message...')}
                component="textarea"
                required
                rows="1"
                style={{
                  padding: '10px',
                  borderRadius: '20px',
                  maxWidth: '18vw',
                  minHeight: '40px',
                  minWidth: '200px',
                  borderColor: '#ccc',
                  outline: 'none',
                  width: '100%',
                }}
              />
            </FieldContainer>
            <FieldContainer flex required name="imageUrl" hideLabel>
              <>
                <label
                  htmlFor="file-upload"
                  onClick={() => open()}
                  onKeyDown={() => open()}
                  className="custom-file-upload"
                >
                  <BoxIcon name="bx-image-add bx-sm" />
                </label>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  disabled
                />
                <aside style={thumbsContainer}>{thumbs}</aside>
              </>
            </FieldContainer>
          </div>
          <div className="messages__btn-form">
            {orderState === 'on_hold' ? (
              <BasicDropdown
                contentClasses="is-pulled-right has-text-left is-align-content-center"
                iconName="bxs-paper-plane"
                dropdownClasses="is-up ml-2"
                buttonText="Send"
                disabled={sendDisable}
                buttonClasses={`is-fullwidth is-primary ${
                  sendDisable && 'is-loading'
                }`}
              >
                <BasicDropdownItem
                  itemText="Send without releasing the hold"
                  tabIndex={0}
                  clickHandler={() => holdButtonHandler(false)}
                  action="push_to_approved_status"
                />
                <BasicDropdownItem
                  itemText="Send and release the hold"
                  clickHandler={() => holdButtonHandler(true)}
                  action="complete_order"
                />
              </BasicDropdown>
            ) : (
              sendButton('submit')
            )}
          </div>
        </FormSection>
      </form>
    </div>
  );
}

OrderShowMessageFormCustomerInner.propTypes = {
  orderState: PropTypes.string.isRequired,
  uploadPercentage: PropTypes.number.isRequired,
  sendDisable: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        preview: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default OrderShowMessageFormCustomerInner;
