import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createGuideCategory($input: GuideCategoryCreateInput!) {
    createGuideCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      guideCategory {
        id
        guideCategoryGroup {
          slug
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
