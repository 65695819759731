import axios from 'axios';
import createSignedLink from '../components/shared/HelperFunctions/createSignedLink';

function filenameCheck(filename) {
  return (
    filename.toLowerCase().includes('default') ||
    filename.toLowerCase().includes('subsetup') ||
    filename.toLowerCase().includes('default') ||
    filename.toLowerCase().includes('with_attachments') ||
    filename.toLowerCase().includes('malocclusion') ||
    filename.includes('_OC') ||
    filename.toLowerCase().includes('retainer')
  );
}

function filenameScrub(fName) {
  let filename = fName;

  filename = filename.replace('with_attachments', 'template');
  filename = filename.replace('Malocclusion', 'template');
  filename = filename.replace('Original', '');
  filename.replace(/\d+-\d+-\d+_\d+-\d+-\d+_(AM|PM)_/, '');

  return filename;
}

async function latestPtsFile(files, stlFile, client, orderName, hexOrderCode) {
  if (files === null || files === undefined) {
    return null;
  }

  const stlName = stlFile.originalFileName.replace('.stl', '');

  const filteredFiles = files
    .filter((file) => file.originalFileName?.endsWith('.pts'))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  let fileFromRedesignVersion = [];

  // find the latest redesign version of the file
  const highestRedesignCountAvailable = filteredFiles.reduce(
    (count, file) => (file.redesignCount > count ? file.redesignCount : count),
    0,
  );

  // and filter all filteredFiles according to last redesign count
  fileFromRedesignVersion = filteredFiles.filter(
    (file) => file.redesignCount === highestRedesignCountAvailable,
  );

  const foundFile = fileFromRedesignVersion.find((file) => {
    const ptsName = file.originalFileName.replace('.pts', '');

    if (ptsName === stlName) {
      return ptsName === stlName;
    }

    let renamedPts = ptsName
      .replace(`${hexOrderCode}_${hexOrderCode}`, orderName)
      .replace(/\d+-\d+-\d+_\d+-\d+-\d+_(AM|PM)_/, '');

    if (filenameCheck(renamedPts)) {
      renamedPts = renamedPts.replace('Maxillary', 'Upper');
      renamedPts = renamedPts.replace('Mandibular', 'Lower');
    } else {
      renamedPts = renamedPts.replace('Maxillary', 'Final_Upper');
      renamedPts = renamedPts.replace('Mandibular', 'Final_Lower');
    }

    renamedPts = filenameScrub(renamedPts);

    return renamedPts === stlName;
  });

  if (foundFile) {
    const signedUrlData = await createSignedLink({
      client,
      foundFile,
    });

    const { data } = await axios.get(signedUrlData.signedUrl, {
      headers: {
        Accept: 'binary/octet-stream',
      },
    });

    let rawFile;
    if (data) {
      const rawFormattedData = data.split('\r\n').slice(1, -1).slice(0, -1);

      const formattedData = rawFormattedData.map((el) => {
        const lineItem = el.split(' ');

        const newLine = lineItem.map((i) => parseFloat(i));

        return [newLine[1], newLine[2], newLine[0]];
      });

      rawFile = { ...foundFile, data: formattedData };
    }

    return rawFile;
  }
  return null;
}

export default latestPtsFile;
