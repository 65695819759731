import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function HelpCenterNavMenu() {
  const { t } = useTranslation('navigation');

  return (
    <>
      <NavLink to="/getting-started">{t('Getting Started')}</NavLink>
      <a href="#submit-first-order">{t('Submitting a Case')}</a>
      <a href="#submit-dental-system">{t('Submitting via Dental System')}</a>
      <a href="#update-profile">{t('Update your profile')}</a>
      <a href="#update-design-preferences">{t('Edit design preferences')}</a>
    </>
  );
}

export default HelpCenterNavMenu;
