import { memo } from 'react';
import Proptypes from 'prop-types';

function Input({ error = null, field }) {
  return (
    <>
      {error && <span className="is-danger">{error}</span>}
      <input className="input" {...field} />
    </>
  );
}

Input.propTypes = {
  error: Proptypes.string,
  field: Proptypes.object.isRequired,
};

export default memo(Input);
