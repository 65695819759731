import { gql } from '@apollo/client';

export default gql`
  mutation destroyCustomerLocation($input: CustomerLocationDestroyInput!) {
    destroyCustomerLocation(input: $input) {
      clientMutationId
      location {
        id
      }
    }
  }
`;
