import { gql } from '@apollo/client';

const CustomerLocationInfo = gql`
  fragment CustomerLocationInfo on CustomerLocation {
    id
    name
    allowNested
    slug
    code
    createdAt
    updatedAt
    address
    showVisOutput
    geoData
    website
    suspended
    fullViewer
    approvalTerms
    numberingSystem
    organizationId
    customerTier
    paymentTermId
    priceListId
    priceListIsCustom
    guideBookId
    pdfAllMovementPerStage
    doctorApprovalLight
    source
    patientViewerLink
    inactiveBilling
    language
    viewerSetting {
      simpleViewer
      theme
    }
    customerTagLabLocations {
      id
      customerTagId
    }
    customerTagIds
    customerTags {
      id
      name
    }
    designerTagLabLocations {
      id
      designerTagId
    }
    designerTagIds
    designerTags {
      id
      name
    }
    cadFiles {
      id
      fileUrl
      s3Key
      filename
      createdAt
    }
    priceList {
      id
      name
    }
    turnaroundTimeId
    locationBilling
    phone
    logoUrl
    subType
    organization {
      id
      name
      slug
      text
      key
      value
      createdAt
      updatedAt
      administratorLocation {
        id
        name
      }
    }
    assignedManufacturers {
      id
      manufacturerOrganizationId
      isDefault
      manufacturerOrganization {
        id
        key
        text
        value
      }
    }
    enableSimpleUploader
  }
`;

export default CustomerLocationInfo;
