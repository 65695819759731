import { gql } from '@apollo/client';

export default gql`
  query tempOrdersCustomer($id: ID) {
    tempOrders {
      id
      name
      intOrderId
      files
      createdAt
      metaData
      source
      instructions
      softwareVersion
      orderItems
      location {
        id
        name
        code
        slug
      }
    }
    softwareNodes {
      id
      name
      nodeType
      nodes
      designType {
        id
        name
        disableInHouse
        billByTooth
        billByArch
        archChart
        toothChart
        manufacturers {
          id
          name
          isInHouse
        }
        modifierGroups {
          id
          name
          label
          modifiers {
            id
            default
            name
            text
            key
            value
          }
        }
      }
    }
    customerLocation(id: $id) {
      id
      turnaroundTimeId
      language
      allowNested
      numberingSystem
      allowNested
      assignedManufacturers {
        id
        isDefault
        manufacturerOrganization {
          id
          name
          isInHouse
          requires3ox
        }
      }
      priceList {
        id
        name
        slug
        priceListItems {
          id
          defaultTurnaroundTime
          turnaroundTimeId
          designTypeId
        }
        turnaroundTimes {
          id
          key
          value
          text
          priority
        }
        designTypes {
          id
          key
          value
          text
          billByTooth
          billByArch
          toothChart
          archChart
          disableInHouse
          designApproval
          manufacturers {
            id
            key
            value
            text
            isInHouse
          }
        }
      }
    }
  }
`;
