class BaseDefinition {
  constructor(file, currentUser) {
    this.file = file;
    this.currentUser = currentUser;
    this.parsedCase = {
      order: {
        intOrderId: this.file.name.substring(
          0,
          this.file.name.lastIndexOf('.'),
        ),
        instructions: '',
        requiresDesignApproval: false,
        orderItems: [
          {
            units: 1,
            materialName: '',
            guideCodes: [],
            teeth: [],
            arches: [],
            manufacturerOrganizationId: '',
            orderItemModifiersAttributes: [],
            designTypeId: '',
            splitFile: false,
          },
        ],
        files: [
          {
            originalFileName: this.file.name,
            fileType: 'initial_scans',
            fileUrl: null,
          },
        ],
        software: 'unknown',
      },
    };
  }

  buildCase = () => {
    const file = {
      fileType: 'initial_scans',
      file: this.file,
    };

    const metaData = {
      isParsed: false,
      softwareCompany: 'unknown',
      manufacturer: false,
    };

    return { order: this.parsedCase.order, file, metaData };
  };
}

export default BaseDefinition;
