import { gql } from '@apollo/client';

const OrderInfoListManufacturer = gql`
  fragment OrderInfoListManufacturer on Order {
    id
    createdAt
    hexOrderCode
    intOrderId
    isRush
    name
    percentComplete
    state
    itemsMetadata
    turnaroundTimeName
    redesignCount
    location {
      id
      name
      code
    }
  }
`;

export default OrderInfoListManufacturer;
