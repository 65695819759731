/* eslint-disable default-param-last */
import {
  GET_ORDERS_COUNT_BY_STATES,
  UPDATE_ORDER_STATUS,
} from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../config/log';
import {
  fetchOnStatus,
  queryFromAppName,
  UpdateCache,
} from '../../../../shared';
import { CreateNotification } from '../../../../shared/HelperFunctions';

const notification = new CreateNotification();

function statusUpdate(
  client,
  statusAction,
  id,
  message = null,
  fromApproval = false,
  selectedCases,
  status,
  mutationQuery,
) {
  const input = mutationQuery
    ? {
        id,
        statusAction: 'case_downloaded',
      }
    : {
        id,
        statusAction,
        message,
        fromApproval,
      };
  client
    .mutate({
      mutation: mutationQuery || UPDATE_ORDER_STATUS,
      variables: {
        input: {
          input,
          clientMutationId: uuidv4(),
        },
      },
      refetchQueries: [
        {
          query: GET_ORDERS_COUNT_BY_STATES,
          fetchPolicy: 'network-only',
        },
      ],
      update: (cache) => {
        if (status !== statusAction) {
          const url = window.location.pathname;

          const query = queryFromAppName();
          const { dataKey } = fetchOnStatus(
            query,
            status || url.split('/')[url.split('/').length - 1],
          );

          UpdateCache.filterElementFromCache({
            cache,
            id,
            field: dataKey,
          });
        }
      },
    })
    .then(({ data }) => {
      notification.createGroupNotification({
        type: 'success',
        messageSingle: `Case was changed to "${data.updateOrderStatus.order.state}"`,
        messagePlural: `Cases were changed to "${data.updateOrderStatus.order.state}"`,
        total: selectedCases,
      });
      Log.info(data);
    })
    .catch((err) => {
      Log.error(err);
    });
}

export default statusUpdate;
