import { gql } from '@apollo/client';
import { OrderInfo } from '../fragments';

export default gql`
  query orders(
    $status: String
    $startRange: String
    $endRange: String
    $states: [Integer]
  ) {
    orders(
      status: $status
      startRange: $startRange
      endRange: $endRange
      states: $states
    ) {
      ...OrderInfo
    }
  }
  ${OrderInfo}
`;
