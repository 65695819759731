import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionDesignUserDeactivate(
    $input: DesignUserDeactivateActionInput!
  ) {
    actionDesignUserDeactivate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      user {
        id
        state
      }
    }
  }
  ${ErrorMessageInfo}
`;
