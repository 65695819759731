const awsAuthConfig = {
  development: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:dcbb3c41-5313-4406-b141-752fb750aa64',
        userPoolId: 'us-west-2_54Lh8SLH2',
        userPoolClientId: '6obd0i4t60jqanlubpgamfq2d7',
      },
    },
  },
  staging: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:14ae7c9e-d7cf-4656-9ae8-411dd62afe1b',
        userPoolId: 'us-west-2_eMPrrsjJ1',
        userPoolClientId: '5cbj5lcu39fl67n4vttk3navht',
      },
    },
  },
  production: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:a8fb51c3-7848-400b-b37f-f33df4c5a440',
        userPoolId: 'us-west-2_vpoKha3v4',
        userPoolClientId: 'hd72v84pdalcq18d11oph4mb7',
      },
    },
  },
};

export default awsAuthConfig;
