import PropTypes from 'prop-types';

function SidebarOrderDetailsMenu(props) {
  const { children } = props;

  return (
    <aside
      className="menu"
      style={{
        position: 'fixed',
        width: 100,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderRadius: 0,
      }}
    >
      <ul className="menu-list">{children}</ul>
    </aside>
  );
}

SidebarOrderDetailsMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarOrderDetailsMenu;
