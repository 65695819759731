import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BillingContactFormModal from './BillingContactFormModal';

function BillingContactForm({
  modalOpen,
  openModal,
  closeModal,
  action = null,
  ...rest
}) {
  const props = { modalOpen, openModal, closeModal, action, ...rest };
  const { t } = useTranslation('billing');
  return (
    <>
      <button
        className="button is-small mt-5 is-primary"
        type="button"
        onClick={() => openModal('New')}
      >
        <span className="icon is-small">
          <i className="bx bx-plus-medical" />
        </span>
        <span>{t('New Billing Contact')}</span>
      </button>

      <BillingContactFormModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        {...props}
        action={action}
      />
    </>
  );
}

BillingContactForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
};

export default BillingContactForm;
