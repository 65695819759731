import { OverpanelUploadConsumer } from '@fullcontour/common';
import CaseUploader from './CaseUploader';

const withCaseUploader = (injectedProps) => (WrappedComponent) => {
  function WithCaseUploader(props) {
    return (
      <OverpanelUploadConsumer>
        {({
          uploadPercentage,
          setUploading,
          setUploadingOff,
          uploading,
          updateUploadProgress,
          setFailedUpload,
          removeFailedUpload,
        }) => (
          <CaseUploader
            uploadPercentage={uploadPercentage}
            setUploading={setUploading}
            setUploadingOff={setUploadingOff}
            uploading={uploading}
            updateUploadProgress={updateUploadProgress}
            setFailedUpload={setFailedUpload}
            removeFailedUpload={removeFailedUpload}
            {...injectedProps}
            {...props}
            render={(rest) => <WrappedComponent {...rest} />}
          />
        )}
      </OverpanelUploadConsumer>
    );
  }

  return WithCaseUploader;
};

export default withCaseUploader;
