import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createGuideCodeLabLocation(
    $input: GuideCodeLabLocationCreateInput!
  ) {
    createGuideCodeLabLocation(input: $input) {
      clientMutationId
      guideCodeLabLocation {
        default
        guideCodeId
        id
        labLocationId
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
