import PropTypes from 'prop-types';
import { memo } from 'react';
import { FieldContainer } from '../../FieldContainer';
import useFormikConnect from './hooks/useFormikConnect';

import './PasswordField.scss';
import PasswordInput from './PasswordInput';

const PasswordField = memo(
  ({ name, label, required, placeholder, inputOnly, onChange }) => {
    const { setFieldValue, value } = useFormikConnect(name);

    return (
      <FieldContainer {...{ name, label, required, placeholder }}>
        <PasswordInput
          formField
          {...{ setFieldValue, value, onChange, inputOnly, label, name }}
        />
      </FieldContainer>
    );
  },
);

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  inputOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

PasswordField.defaultProps = {
  name: 'password',
  label: 'Password',
  placeholder: '',
  required: false,
  inputOnly: false,
  onChange: null,
};

export default PasswordField;
