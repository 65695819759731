function searchHandler(groups, searchValue) {
  const lowerSearchValue = searchValue.toLowerCase();
  return groups.reduce((arr, group) => {
    if (group.name.includes(lowerSearchValue)) {
      arr.push(group);
    }

    const guideCodes = group.guideCategories.filter(
      (code) =>
        code.name.toLowerCase().includes(lowerSearchValue) ||
        code.description?.toLowerCase().includes(lowerSearchValue),
    );

    if (guideCodes.length) {
      const updatedItem = {
        ...group,
        guideCategories: guideCodes,
      };

      arr.push(updatedItem);
      return arr;
    }

    return arr;
  }, []);
}

export default searchHandler;
