/* eslint-disable no-unused-vars */
import { OverpanelDrawerContext } from '@fullcontour/common';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ManufacturerDropdown from './ManufacturerDropdown';

function ManufacturerSelection(props) {
  const { setDrawerBody, toggle } = useContext(OverpanelDrawerContext);

  const { availableManufacturers, index } = props;

  const infoClick = () => {
    setDrawerBody(
      `Check if the destination selected is correct. In case you need to add a
     3rd party manufacturer integration, please contact 3Shape Design Service customer
     support.`,
    );
    toggle();
  };

  return (
    <Field
      name={`orderItems[${index}].manufacturerOrganizationId`}
      required
      component={ManufacturerDropdown}
      data={availableManufacturers}
      // infoCallback={infoClick}
    />
  );
}

ManufacturerSelection.propTypes = {
  availableManufacturers: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default ManufacturerSelection;
