import { gql } from '@apollo/client';

export default gql`
  mutation actionOrderSendFollowUpEmail(
    $input: OrderSendFollowUpEmailActionInput!
  ) {
    actionOrderSendFollowUpEmail(input: $input) {
      clientMutationId
    }
  }
`;
