/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  AccordionSingle,
  DesignPreferencesProvider,
} from '../../../../../shared';

const GridItem = React.forwardRef((props, ref) => {
  const {
    title,
    style,
    component,
    layoutKey,
    setLayout,
    changeableLayout,
    operationName,
    isEmpty: empty,
    specialHeight,
    ...rest
  } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEmpty, setIsEmpty] = useState(empty);
  const [initiallyVisible, setInitiallyVisible] = useState(false);
  const accordionRef = useRef(null);
  const [triggerRerender, setTriggerRerender] = useState(false);

  useEffect(() => {
    if (changeableLayout) {
      setIsExpanded(true);
    }
  }, [changeableLayout]);

  useEffect(() => {
    setTimeout(
      () => {
        setInitiallyVisible(true);
        setLayout((prev) =>
          prev.map((item) => {
            if (item.i === layoutKey) {
              const minH = Math.ceil(
                accordionRef.current.getBoundingClientRect().height / 38 + 0.5,
              );
              return {
                ...item,
                h: isExpanded ? specialHeight || minH : minH,
                minH,
                maxH: minH,
                minW: 2,
              };
            }

            return item;
          }),
        );
      },
      !initiallyVisible ? 200 : 100,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isExpanded,
    layoutKey,
    setLayout,
    operationName,
    specialHeight,
    triggerRerender,
  ]);

  const titleClick = () => {
    if (!changeableLayout && !isEmpty) {
      setIsExpanded((prev) => !prev);
    }
  };

  return (
    <DesignPreferencesProvider value={{ setTriggerRerender, setIsEmpty }}>
      <div
        {...rest}
        style={{ background: 'transparent', ...style }}
        className={`prefSection card m-0 ${props.className} ${
          changeableLayout ? 'order-pref__grid-item' : ''
        }`}
        ref={ref}
      >
        <div
          ref={accordionRef}
          style={{
            ...(changeableLayout ? { cursor: 'move' } : {}),
          }}
        >
          <AccordionSingle
            noStyle
            isOpen
            title={title}
            onClick={titleClick}
            index={layoutKey}
            parentName="accordion"
          >
            {component}
          </AccordionSingle>
        </div>
      </div>
    </DesignPreferencesProvider>
  );
});

GridItem.propTypes = {
  className: PropTypes.string,
  operationName: PropTypes.string,
  isEmpty: PropTypes.bool,
  specialHeight: PropTypes.number,
  component: PropTypes.element.isRequired,
  layoutKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setLayout: PropTypes.func.isRequired,
  changeableLayout: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

GridItem.defaultProps = {
  specialHeight: null,
  className: null,
  operationName: null,
  style: {},
  isEmpty: false,
};

export default GridItem;
