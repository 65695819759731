import { gql } from '@apollo/client';

export default gql`
  mutation destroyOrderFile($input: OrderFileDestroyInput!) {
    destroyOrderFile(input: $input) {
      clientMutationId
      orderFiles {
        id
        fileUrl
        s3Key
        ver
        latestVer
        createdAt
        fileType
        orderId
        originalFileName
      }
    }
  }
`;
