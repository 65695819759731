import { gql } from '@apollo/client';

const GuideCategoryInfo = gql`
  fragment GuideCategoryInfo on GuideCategory {
    allowOther
    createdAt
    allowArchSelection
    allowToothSelection
    shortDescription
    description
    adminGuideCodes {
      default
      description
      guideCategoryId
      id
      imageUrl
      guideCategory {
        name
        id
        guideCategoryGroup {
          name
          id
        }
      }
      name
    }
    guideCategoryImages {
      imageUrl
      id
      createdAt
    }
    guideCategoryGroupId
    guideCodes {
      default
      description
      guideCategoryId
      id
      imageUrl
      guideCategory {
        name
        id
        guideCategoryGroup {
          name
          id
        }
      }
      name
    }
    id
    imageUrl
    name
    otherMax
    otherMin
    otherUnit
    slug
    updatedAt
    showDropdown
  }
`;

export default GuideCategoryInfo;
