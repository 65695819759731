import { useQuery } from '@apollo/client';
import { GET_LOCATION_GUIDE_CODE_GROUPS } from '@fullcontour/shared-api';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Tabs } from '../../../../shared';
import LocationGuideGroupModal from './LocationGuideGroupModal/LocationGuideGroupModal';
import LocationGuideGroupsInner from './LocationGuideGroupsInner';

import './LocationGuideGroups.scss';

function LocationDesignGuideGroups() {
  const [modal, setModal] = useState(false);

  const { slug } = useParams();

  const data = useQuery(GET_LOCATION_GUIDE_CODE_GROUPS, {
    variables: { locationSlug: slug },
  });

  return (
    <Tabs.TabPanel tabId="designGuideGroups">
      <LocationGuideGroupsInner setModal={setModal} data={data} />
      {modal && (
        <LocationGuideGroupModal
          setModal={setModal}
          modal={modal}
          refetch={data.refetch}
        />
      )}
    </Tabs.TabPanel>
  );
}

LocationDesignGuideGroups.propTypes = {};

export default LocationDesignGuideGroups;
