import { graphql } from '@apollo/client/react/hoc';
import { OrderDropzone, OverpanelSubmitProvider } from '@fullcontour/common';
import { GET_PRICE_LIST_OVERPANEL } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './Overpanel.css';
import { OverpanelCases } from './OverpanelCases';
import { OverpanelHeader } from './OverpanelHeader';

function Overpanel(props) {
  const { overpanelOpen, data } = props;

  const [submitAll, setSubmitAll] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <OverpanelSubmitProvider
      value={{
        submitAll,
        setSubmitAll: () => setSubmitAll(true),
        removeSubmitAll: () => setSubmitAll(false),
        errors,
        setErrors,
      }}
    >
      <div
        className={`overpanelContainer ${overpanelOpen ? 'open' : 'closed'}`}
      >
        <OrderDropzone overpanelOpen={overpanelOpen}>
          <OverpanelHeader overpanelOpen={overpanelOpen} {...props} />
          <OverpanelCases data={data} overpanelOpen={overpanelOpen} />
        </OrderDropzone>
      </div>
    </OverpanelSubmitProvider>
  );
}

Overpanel.propTypes = {
  overpanelOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default graphql(GET_PRICE_LIST_OVERPANEL)(Overpanel);
