function initialValues(props) {
  const { parsedCase: { order = {} } = {}, customerLocation } = props;

  return {
    intOrderId: order.intOrderId || '',
    name: order.name ? order.name.trim() : '',
    instructions: order.instructions || '',
    requiresDesignApproval: false,
    turnaroundTimeId: customerLocation.turnaroundTimeId || '',
    software: order.software,
    files:
      !Array.isArray(order.files) || !order.files.length ? [] : order.files,
    orderItems:
      !Array.isArray(order.orderItems) || !order.orderItems.length
        ? [
            {
              units: 1,
              materialName: '',
              teeth: [],
              arches: [],
              guideCodes: [],
              manufacturerOrganizationId: '',
              orderItemModifiersAttributes: [],
              designTypeId: '',
              splitFile: false,
            },
          ]
        : order.orderItems.map((oI) => ({ ...oI, guideCodes: [] })),
  };
}

export default initialValues;
