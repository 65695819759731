import { gql } from '@apollo/client';
import { ReportInfo } from '../fragments';

export default gql`
  query reports {
    reports {
      ...ReportInfo
    }
  }
  ${ReportInfo}
`;
