import { useContext } from 'react';
import { CurrentUserContext } from '../../Context';

function CurrentUserWrapper(props) {
  const { render, ...rest } = props;
  const { currentUser } = useContext(CurrentUserContext);

  return render({
    currentUser,
    ...rest,
  });
}

export default CurrentUserWrapper;
