import GuideBodyMainContent from './GuideBodyMainContent';
import GuideBodyMenu from './GuideBodyMenu';

import Styles from './styles/GuideBody.module.css';

function GuideBody(props) {
  return (
    <div className={Styles.guideBodyWrapper}>
      <div className={Styles.guideBodyMenuWrapper}>
        <GuideBodyMenu {...props} />
      </div>
      <div className={Styles.guideBodyMainContentMargin}>
        <GuideBodyMainContent {...props} />
      </div>
    </div>
  );
}

export default GuideBody;
