const findUserClass = (app) => {
  if (app === 'admin_portal') {
    return 'AdminUser';
  }

  if (app === 'customer_portal') {
    return 'User';
  }

  if (app === 'manuf_portal') {
    return 'ManufacturerUser';
  }

  return 'DesignUser';
};

export default findUserClass;
