import { useQuery } from '@apollo/client';
import { OverpanelFilesContext } from '@fullcontour/common';
import { GET_TEMP_ORDERS_COUNT } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function OverpanelHeaderToggleButton({ pendingCasesAmount = null }) {
  const { files, toggleOverpanel, overpanelOpen } = useContext(
    OverpanelFilesContext,
  );

  const { t } = useTranslation('orders');

  const { data } = useQuery(GET_TEMP_ORDERS_COUNT);

  const btnHandler = (stauts) => {
    toggleOverpanel(stauts);
  };

  const pedningCases = data && data.tempOrdersCount;

  const label = overpanelOpen ? 'Hide' : 'New Cases';
  const icon = overpanelOpen ? 'bx-chevron-down' : 'bx-chevron-up';

  return (
    <>
      {!overpanelOpen && (pedningCases || pendingCasesAmount) ? (
        <button
          type="button"
          data-testid="overpanelOpenTriggerPending"
          disabled={!pendingCasesAmount}
          className="button is-size-7 mr-3 is-white"
          onClick={() => btnHandler('pending')}
        >
          {pendingCasesAmount ? (
            <span className="icon">
              <i className={`bx ${icon}`} />
            </span>
          ) : null}
          <span>
            Pending cases
            {pendingCasesAmount ? (
              <span className="tag is-danger ml-2">{pendingCasesAmount}</span>
            ) : (
              <div className="loader is-loading ml-3 is-inline-block" />
            )}
          </span>
        </button>
      ) : null}
      {overpanelOpen !== 'pending' && (
        <button
          type="button"
          data-cy="overpanel-toggle"
          className={`button mr-3 has-text-weight-bold is-size-7 ${
            !overpanelOpen ? 'is-primary' : 'is-dark'
          }`}
          onClick={() => btnHandler('order')}
        >
          {pendingCasesAmount ? (
            <span className="icon">
              <i className={`bx ${icon}`} />
            </span>
          ) : null}
          <span>
            {t(label)}
            {files.length ? (
              <span className="tag is-danger ml-2 p-2"> {files.length}</span>
            ) : null}
          </span>
        </button>
      )}
    </>
  );
}

OverpanelHeaderToggleButton.propTypes = {
  pendingCasesAmount: PropTypes.number,
};

export default OverpanelHeaderToggleButton;
