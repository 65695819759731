import PropTypes from 'prop-types';
import BillingDetailsCreditCardsInner from './BillingDetailsCreditCardsInner';

function BillingDetailsCreditCards(props) {
  const { customer, type } = props;

  return <BillingDetailsCreditCardsInner customer={customer} type={type} />;
}

BillingDetailsCreditCards.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingDetailsCreditCards;
