/* eslint-disable no-unused-vars */
import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Preload,
  useContextBridge,
  AdaptiveDpr,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { OrthoContext } from '../../../shared/Context/OrthoContext';
import GridOverlay from './GridOverlay/GridOverlay';
import ThreeLoader from './Loader';
import ObjGroup from './ObjGroup/ObjGroup';
import Scene from './Scene';
import { EffectComposer, SSAO, Bloom, ToneMapping } from '@react-three/postprocessing'
import { suspend } from 'suspend-react'
const studio = import('@pmndrs/assets/hdri/studio.exr').then((module) => module.default)

function ThreeViewer({ orbit = null, cameraRef = null }) {
  const ContextBridge = useContextBridge(OrthoContext);

  return (
    <Canvas shadows gl={{ physicallyCorrectLights: true, antialias: true }} dpr={[1, 2]}>
      <ContextBridge>
        <Scene />
        <EffectComposer enableNormalPass>
          <SSAO radius={0.08} intensity={0.04} luminanceInfluence={0.3} color="black" />
          <Bloom
            luminanceThreshold={0.99}
            intensity={0.015}
            luminanceSmoothing={0.92}
            height={48}
          />
          <ToneMapping
            exposure={0.35}
            whitePoint={1}
            adaptationRate={1}
            mode={ToneMapping.ACES_FILMIC}
          />
        </EffectComposer>
        <Suspense fallback={<ThreeLoader loading />}>
        <ambientLight intensity={0.5} />
          <directionalLight
            position={[10, 10, 10]}
            intensity={0.4}
            castShadow
            shadow-mapSize={[4096, 4096]}
            shadow-bias={-0.0005}
          />
          <directionalLight
            position={[-10, 10, -10]}
            intensity={0.2}
            castShadow
            shadow-mapSize={[4096, 4096]}
            shadow-bias={-0.0005}
          />

          <Environment
            files={suspend(studio)}
            background={false}
            environmentIntensity={0.23}
            environmentRotation={[Math.PI / 2, 0, 0]}
          />
            <Preload all />
            <PerspectiveCamera
              makeDefault
              fov={26}
              near={0.1}
              far={1000}
              position={[0, 0, 140]}
              ref={cameraRef}
            />

            <ObjGroup />
            <OrbitControls
              ref={orbit}
              enableDamping
              dampingFactor={0.05}
              minDistance={50}
              maxDistance={200}
            />
            <GridOverlay />
        </Suspense>
      </ContextBridge>
      <AdaptiveDpr pixelated />
    </Canvas>
  );
}

ThreeViewer.propTypes = {
  orbit: PropTypes.object,
  cameraRef: PropTypes.object,
};

export default ThreeViewer;
