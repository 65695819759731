/* eslint-disable camelcase */
function initialValues(props) {
  const { tempOrder = {}, customerLocation } = props;

  return {
    id: tempOrder.id || '',
    intOrderId: tempOrder.intOrderId || '',
    [`name${tempOrder.id}`]: tempOrder.name ? tempOrder.name.trim() : '',
    [`instructions${tempOrder.id}`]: tempOrder.instructions || '',
    [`turnaroundTimeId${tempOrder.id}`]:
      customerLocation.turnaroundTimeId || '',
    [`requiresDesignApproval${tempOrder.id}`]: false,
    software: tempOrder.softwareVersion,
    source: tempOrder.source,
    orderItems:
      tempOrder.orderItems && tempOrder.orderItems.length > 0
        ? tempOrder.orderItems
        : [
            {
              units: 1,
              materialName: '',
              teeth: [],
              arches: [],
              guideCodes: [],
              manufacturerOrganizationId: '',
              designTypeId: '',
              splitFile: false,
            },
          ],
    files:
      tempOrder.files && tempOrder.files.length > 0
        ? tempOrder.files.map((file) => {
            const {
              __typename,
              original_filename,
              s3_key,
              file_type,
              file_url,
              ...rest
            } = file;

            return {
              ...rest,
              originalFileName: original_filename,
              fileType: file_type,
              s3Key: s3_key,
              fileUrl: file_url,
            };
          })
        : [],
  };
}

export default initialValues;
