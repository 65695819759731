import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderApprovalLogInfo } from '../fragments';

export default gql`
  mutation createOrderApprovalLog($input: OrderApprovalLogCreateInput!) {
    createOrderApprovalLog(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderApprovalLog {
        ...OrderApprovalLogInfo
      }
    }
  }
  ${OrderApprovalLogInfo}
  ${ErrorMessageInfo}
`;
