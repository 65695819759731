const fcDetector = {
  name: 'fcDetector',
  lookup() {
    return window.localStorage.getItem('defaultLanguage') || 'en';
  },
  cacheUserLanguage(lng) {
    window.localStorage.setItem('defaultLanguage', lng.split('-')[0]);
  },
};

export default fcDetector;
