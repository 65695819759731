import PropTypes from 'prop-types';
import { memo } from 'react';
import Styles from '../PriceListItemForm.module.css';
import PriceListItemValue from './PriceListItemValue';

function PriceListItemsTableBody(props) {
  const { listDesignTypes, listTurnaroundTimes, priceListItems } = props;

  return (
    <tbody>
      {listDesignTypes.map((type) => (
        <tr key={type.id}>
          <td className={Styles.designTypeCell}>{type.name}</td>
          {listTurnaroundTimes.map((time) => (
            <td key={time.id} className={Styles.tableCell}>
              <PriceListItemValue
                priceListItems={priceListItems}
                timeId={time.id}
                typeId={type.id}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

PriceListItemsTableBody.propTypes = {
  listTurnaroundTimes: PropTypes.array.isRequired,
  listDesignTypes: PropTypes.array.isRequired,
  priceListItems: PropTypes.array.isRequired,
};

export default memo(PriceListItemsTableBody);
