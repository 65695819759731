import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';
import Dropdown from './Dropdown';

function CountryDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  required = false,
}) {
  const { t } = useTranslation('formfields');
  const { locale } = useContext(LanguageContext);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  const { error, loading, data } = useQuery(GET_COUNTRIES, {
    variables: { locale },
  });

  return (
    <Dropdown
      name={name}
      label="Country"
      error={error}
      errors={!!(errors[name] && touched[name])}
      loading={loading}
      options={loading ? [] : data.countries}
      onChange={handleChange}
      placeholder={`${t('Choose a Country')}...`}
      value={value}
      required={required}
    />
  );
}

CountryDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  required: PropTypes.bool,
};

export default memo(CountryDropdown);
