import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { LoadingOrError } from '../../Loader';
import Styles from './DynamicMultiSelect.module.scss';

function DynamicMultiSelect({
  field: { name, value },
  form: { setFieldValue },
  query,
  dataname,
  height = 150,
  variables = {},
  required = true,
  label = '',
  originalDataCallBack = null,
  optionsUsed = null,
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const { loading, error, data } = useQuery(query, {
    variables,
    onCompleted: (response) => {
      let newData = response[dataname];
      if (optionsUsed) {
        newData = newData.filter((d) => !new Set(optionsUsed).has(d.id));
      }
      if (value && value.length) {
        newData = newData.filter((d) => !new Set(value).has(d.id));
      }
      setFilteredData(newData);
      setOriginalData(response[dataname]);
      if (originalDataCallBack) originalDataCallBack(response[dataname]);
    },
  });

  const updateOption = (e) => {
    const opts = e.target.options;
    const v = [];
    for (let i = 0, l = opts.length; i < l; i += 1) {
      if (opts[i].selected) {
        v.push(opts[i].value);
      }
    }
    setFieldValue(name, v);
  };

  const selectAll = () => {
    const ids = data[dataname].map((d) => d.id);
    setFieldValue(name, ids);
  };

  const removeAll = () => {
    setFieldValue(name, []);
  };

  const filterData = (e) => {
    const val = e.target.value;
    const filtered = originalData.filter(
      (d) => d.name.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
    setFilteredData(filtered);
  };

  function findLabel(id) {
    const foundItem = originalData.find((d) => d.id === id);
    if (foundItem) {
      return foundItem.name;
    }
    return null;
  }

  return (
    <>
      <div className={`field ${required ? 'required' : ''}`}>
        <label htmlFor={name} className="label">
          {`${label}` !== 'undefined' ? `${label}` : ''}
        </label>
        <div className="control has-icons-right">
          <input
            className={`input is-small ${Styles.MultiSelectSearch}`}
            placeholder="Filter..."
            onChange={filterData}
            type="text"
          />
          <span className="icon is-small is-right">
            <i className="bx bx-search" />
          </span>
        </div>
        <div className="control">
          <div className="select is-multiple" style={{ width: '100%' }}>
            {!loading && !error && (
              <select
                name={name}
                multiple
                className={Styles.MultiSelect}
                value={value}
                onChange={updateOption}
                style={{ height, minHeight: height }}
              >
                {[...filteredData]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        {loading || error ? (
          <div style={{ height }}>
            <LoadingOrError error={error} loading={loading} hideText />
          </div>
        ) : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexBasis: '50%' }}>
          {value &&
            value.length > 0 &&
            value.map((id) => (
              <span
                key={id}
                className="tag is-dark"
                style={{ marginBottom: 5, marginRight: 5 }}
              >
                {findLabel(id)}
              </span>
            ))}
        </div>
        <div
          style={{
            textAlign: 'right',
            flexBasis: '50%',
            justifyContent: 'end',
          }}
        >
          <button
            className="button is-small is-outlined is-danger"
            type="button"
            onClick={removeAll}
          >
            clear
          </button>
          <button
            className="button is-small is-primary"
            type="button"
            onClick={selectAll}
            style={{ marginLeft: 8 }}
          >
            select all
          </button>
        </div>
      </div>
    </>
  );
}

DynamicMultiSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  query: PropTypes.object.isRequired,
  dataname: PropTypes.string.isRequired,
  height: PropTypes.number,
  variables: PropTypes.object,
  required: PropTypes.bool,
  originalDataCallBack: PropTypes.func,
  optionsUsed: PropTypes.array,
};

export default DynamicMultiSelect;
