/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { DesignGuideOrderContext } from '../../../Context';
import GuideCodeCard from '../GuideCodeCard/GuideCodeCard';
import useHandleCardsActions from './hooks/useHandleCardsActions';
import { findCurrentCode } from './utils';

function GuideBodyCodeCard({
  guideCategory,
  guideCategoryGroupId,
  designType,
  filteValue = null,
  hideCreateOtherButton = false,
}) {
  const { values, setFieldValue } = useFormikContext();

  const {
    orderItemIndex,
    customerLocation: { numberingSystem },
  } = useContext(DesignGuideOrderContext);

  const { guideCodes } = values.orderItems[orderItemIndex];
  const [guideCode, setGuideCode] = useState(null);
  const isSelected = guideCodes?.some((item) => item.codeId === guideCode?.id);

  useEffect(() => {
    setGuideCode((prev = {}) => {
      const updatedCode = guideCategory.guideCodes.find(
        (item) => item.id === guideCode?.id,
      );
      setTimeout(() => {
        if (
          updatedCode &&
          updatedCode.default !== prev.default &&
          !updatedCode.default &&
          isSelected
        ) {
          setFieldValue(
            `orderItems[${orderItemIndex}].guideCodes`,
            guideCodes.filter((item) => item.codeId !== updatedCode.id),
          );
        }
      }, 150);
      return {
        ...updatedCode,
        ...findCurrentCode(guideCategory, guideCodes),
      };
    });
  }, [guideCategory]);

  const { handleOtherValue, handleDropdown, handleChange } =
    useHandleCardsActions({ setGuideCode, guideCodes, orderItemIndex });

  if (!guideCode) {
    return null;
  }
  const guideCodeFullData = {
    ...guideCode,
    briefDescription: guideCategory.showDropdown
      ? guideCode?.description || guideCategory.short_description
      : guideCategory.short_description,
    fullDescription: guideCategory.description,
    imageUrl: guideCode.imageUrl || guideCategory.imageUrl,
    category: guideCategory,
    categoryAdditionalInfo: {
      guideCategoryImages: guideCategory.guideCategoryImages,
      fullDescription: guideCategory.description,
    },
    guideCategoryGroupId,
    designType,
    searchValue: filteValue,
    numberingSystem,
    handleChange,
    isSelected,
  };

  return (
    <GuideCodeCard
      image={<GuideCodeCard.Image />}
      actionButtons={
        <GuideCodeCard.ActionButtons>
          <GuideCodeCard.ButtonNestedSelection />
          <GuideCodeCard.ButtonFavourites />
          <GuideCodeCard.ButtonInformation />
          <GuideCodeCard.ButtonDefaults
            helpers={{
              orderItemIndex,
              setFieldValue,
              guideCodes,
              setGuideCode,
              handleDropdown,
            }}
          />
        </GuideCodeCard.ActionButtons>
      }
      cardDropdown={
        <GuideCodeCard.Dropdown handleDropdown={handleDropdown}>
          <GuideCodeCard.OtherInput
            hideCreateOtherButton={hideCreateOtherButton}
            handleOtherValue={handleOtherValue}
          />
        </GuideCodeCard.Dropdown>
      }
      nestedLabels={<GuideCodeCard.NestedLabels />}
      footer={<GuideCodeCard.CardFooter />}
      data={guideCodeFullData}
      handleChange={handleChange}
      isSelected={isSelected}
    />
  );
}

GuideBodyCodeCard.propTypes = {
  designType: PropTypes.shape({
    designTypeId: PropTypes.string,
    designTypeName: PropTypes.string,
  }).isRequired,
  filteValue: PropTypes.string,
  guideCategory: PropTypes.object.isRequired,
  guideCategoryGroupId: PropTypes.string.isRequired,
  hideCreateOtherButton: PropTypes.bool,
};

export default GuideBodyCodeCard;
