import { CREATE_ORDER_APPROVAL_LOG } from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';

async function approvalLogCreate(
  client,
  orderId,
  doctorApproval,
  doctorEmail,
  dentalNotation,
) {
  const { data } = await client.mutate({
    mutation: CREATE_ORDER_APPROVAL_LOG,
    variables: {
      input: {
        input: {
          orderId,
          doctorApproval,
          doctorEmail,
          dentalNotation,
        },
        clientMutationId: uuidv4(),
      },
    },
  });

  return data;
}

export default approvalLogCreate;
