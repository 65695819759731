import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { timestampFormatter } from '../../../transforms';

function ShowDetailsDateLabel({
  date,
  created = true,
  color = 'grey',
  style = {},
}) {
  const { t } = useTranslation('showelements');

  return (
    <span className={`tag ${color}`} style={style}>
      {created ? t('created on') : t('updated at')} {timestampFormatter(date)}
    </span>
  );
}

ShowDetailsDateLabel.propTypes = {
  date: PropTypes.string.isRequired,
  created: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default memo(ShowDetailsDateLabel);
