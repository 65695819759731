import { Suspense, useContext } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { BaseLoader } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import sharedRoutes from './main';
import { createRoutesArray } from '../../utils/functions';

function SidebarBreadcrumbRoutes() {
  const { pathname } = useLocation();
  const { mainRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const scopedRoutes = mainRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }

    return route.so === true;
  });
  const Routes = useRoutes(
    createRoutesArray([...scopedRoutes, ...sharedRoutes], true, pathname),
  );
  return <Suspense fallback={<BaseLoader loading />}>{Routes}</Suspense>;
}

export default SidebarBreadcrumbRoutes;
