import { NavMenuLink } from '@fullcontour/common';

function OrganizationShowNavMenu() {
  return (
    <>
      <NavMenuLink to="/organizations" name="details" />
      <NavMenuLink to="/organizations/billing" name="billing details" />
    </>
  );
}

export default OrganizationShowNavMenu;
