import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideCategory($input: GuideCategoryDestroyInput!) {
    destroyGuideCategory(input: $input) {
      clientMutationId
      guideCategory {
        id
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
