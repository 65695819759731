import { gql } from '@apollo/client';

export default gql`
  mutation destroyPrinterMaterial($input: PrinterMaterialDestroyInput!) {
    destroyPrinterMaterial(input: $input) {
      printerMaterial {
        id
      }
    }
  }
`;
