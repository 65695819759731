function initialValues() {
  return {
    reportTypeId: '',
    reportTimeFrameId: '',
    singleDate: null,
    customStart: null,
    customEnd: null,
    hexString: null,
    amount: null,
    adminLocationIds: [],
    customerOrganizationIds: [],
    customerLocationIds: [],
    customerTagIds: [],
    designOrganizationIds: [],
    designLocationIds: [],
    designTypeIds: [],
    turnaroundTimeIds: [],
    manufacturerOrganizationIds: [],
  };
}

export default initialValues;
