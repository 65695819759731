import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FollowUpForm from './FollowUpForm';

function FollowUpModal({
  isModalActive = false,
  followUpModalActive = false,
  followUpModalData = null,
}) {
  const { t } = useTranslation('formheaders');

  return followUpModalData && isModalActive ? (
    <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t('Follow Up Case')} #{followUpModalData.hexOrderCode}
          </p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => followUpModalActive(false)}
          />
        </header>
        <div className="modal-card-body">
          <FollowUpForm
            followUpModalData={followUpModalData}
            followUpModalActive={followUpModalActive}
          />
        </div>
      </div>
    </div>
  ) : null;
}

FollowUpModal.propTypes = {
  followUpModalData: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired,
  ]),
  isModalActive: PropTypes.bool,
  followUpModalActive: PropTypes.func,
};

export default FollowUpModal;
