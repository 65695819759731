import { gql } from '@apollo/client';
import { QuotaProfileInfo } from '../fragments';

export default gql`
  query quotaProfile($id: ID!) {
    quotaProfile(id: $id) {
      ...QuotaProfileInfo
    }
  }
  ${QuotaProfileInfo}
`;
