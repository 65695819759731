import PropTypes from 'prop-types';

function InvoiceAddressFormatter(props) {
  const { name, address, phone } = props;
  return (
    <span>
      <b>{name}</b> <br />
      <span style={{ fontSize: '.75em' }}>
        {address.address ? (
          <>
            {address.address} <br />
          </>
        ) : (
          ''
        )}
        {address.address2 ? (
          <>
            {address.address2} <br />
          </>
        ) : (
          ''
        )}
        {address.city ? <>{address.city},&nbsp;</> : ''}
        {address.region ? <>{address.region}&nbsp;</> : ''}
        {address.postalCode ? (
          <>
            {address.postalCode} <br />
          </>
        ) : (
          ''
        )}
        {address.country ? (
          <>
            {address.country} <br />
          </>
        ) : (
          ''
        )}
        {phone ? (
          <>
            {phone} <br />
          </>
        ) : (
          ''
        )}
      </span>
    </span>
  );
}

InvoiceAddressFormatter.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
};

export default InvoiceAddressFormatter;
