import BasicProfileForm from '../../views/Profile/BasicProfile/BasicProfileForm';
import UpdatePasswordForm from '../../views/Profile/UpdatePassword/UpdatePasswordForm';
import NotificationsForm from '../../views/Profile/NotificationSettings/NotificationSettingsForm';

const routes = [
  {
    path: '/profile',
    component: BasicProfileForm,
  },
  {
    path: '/profile/notifications',
    component: NotificationsForm,
  },
  {
    path: '/profile/update-password',
    component: UpdatePasswordForm,
  },
];

export default routes;
