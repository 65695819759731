import { gql } from '@apollo/client';
import { InternalRejectionReasonInfo } from '../fragments';

export default gql`
  mutation destroyInternalRejectionReason(
    $input: InternalRejectionReasonDestroyInput!
  ) {
    destroyInternalRejectionReason(input: $input) {
      clientMutationId
      internalRejectionReason {
        ...InternalRejectionReasonInfo
      }
    }
  }
  ${InternalRejectionReasonInfo}
`;
