export function getIgnoredMessageIds(username) {
  if (localStorage.getItem('ignoredMessageIds')) {
    const ignoredMessageIds = JSON.parse(
      localStorage.getItem('ignoredMessageIds'),
    );
    if (ignoredMessageIds[username]) {
      return ignoredMessageIds[username];
    }
  }
  localStorage.setItem(
    'ignoredMessageIds',
    JSON.stringify({ [username]: { ids: [], dismissed: false } }),
  );
  return { ids: [], dismissed: false };
}

export function addIgnoredMessageId(id, username) {
  if (localStorage.getItem('ignoredMessageIds')) {
    const ignoredMessageIds = JSON.parse(
      localStorage.getItem('ignoredMessageIds'),
    );
    if (ignoredMessageIds[username]) {
      ignoredMessageIds[username].ids.push(id);
    } else {
      ignoredMessageIds[username] = { ids: [], dismissed: false };
    }
    localStorage.setItem(
      'ignoredMessageIds',
      JSON.stringify(ignoredMessageIds),
    );
  } else {
    localStorage.setItem('ignoredMessageIds', JSON.stringify({}));
  }
}

export function toggleDismissedMessages(username, shouldDismiss) {
  if (localStorage.getItem('ignoredMessageIds')) {
    const ignoredMessageIds = JSON.parse(
      localStorage.getItem('ignoredMessageIds'),
    );
    if (ignoredMessageIds[username]) {
      ignoredMessageIds[username].dismissed = shouldDismiss;
      localStorage.setItem(
        'ignoredMessageIds',
        JSON.stringify(ignoredMessageIds),
      );
    }
  }
}

export function removeNonDismissed(username) {
  if (localStorage.getItem('ignoredMessageIds')) {
    const ignoredMessageIds = JSON.parse(
      localStorage.getItem('ignoredMessageIds'),
    );
    if (ignoredMessageIds[username]) {
      delete ignoredMessageIds[username];
      localStorage.setItem(
        'ignoredMessageIds',
        JSON.stringify(ignoredMessageIds),
      );
    }
  }
}
