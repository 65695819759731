import { useQuery } from '@apollo/client';
import { GET_REPORT_TIME_FRAMES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../../shared/FormFields/Dropdowns/Dropdown';

function TimeFrameSelect(props) {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue },
    updateCustomDates,
    currentReportType,
  } = props;

  const { t } = useTranslation('formfields');
  const [timeFrames, setTimeFrames] = useState([]);

  const { error, loading, data } = useQuery(GET_REPORT_TIME_FRAMES, {
    fetchPolicy: 'cache-first',
    onCompleted: (d) => {
      if (currentReportType.singleDate) {
        setTimeFrames(
          d.reportTimeFrames.filter((time) => time.name === 'Single Date'),
        );
      } else {
        setTimeFrames(
          d.reportTimeFrames.filter((time) => time.name !== 'Single Date'),
        );
      }
    },
  });

  const handleChange = (e) => {
    const foundCustom = data.reportTimeFrames.find(
      (time) => time.id === e.target.value && time.name === 'Custom',
    );
    if (foundCustom) {
      updateCustomDates(true);
    }
    setFieldValue(name, e.target.value);
  };

  return (
    <Dropdown
      name={name}
      label="Time Period"
      errors={!!(errors[name] && touched[name])}
      error={error}
      loading={loading}
      options={loading ? [] : timeFrames}
      onChange={handleChange}
      placeholder={t('Select a Time Period...')}
      value={value}
      disabled={loading || error}
      search
      required
    />
  );
}

TimeFrameSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  updateCustomDates: PropTypes.func.isRequired,
  currentReportType: PropTypes.object.isRequired,
};

export default memo(TimeFrameSelect);
