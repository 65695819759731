import PropTypes from 'prop-types';

function foundItemIndex(priceListItems, timeId, typeId) {
  return priceListItems.findIndex(
    (item) => item.turnaroundTimeId === timeId && item.designTypeId === typeId,
  );
}

function PriceListItemValue(props) {
  const { timeId, typeId, priceListItems } = props;

  const foundItem = foundItemIndex(priceListItems, timeId, typeId);

  return foundItem !== -1 ? (
    <p
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '120%',
        margin: '9px 0 10px 0',
      }}
    >
      ${priceListItems[foundItem].price}
    </p>
  ) : null;
}

PriceListItemValue.propTypes = {
  timeId: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  priceListItems: PropTypes.array.isRequired,
};

export default PriceListItemValue;
