import PropTypes from 'prop-types';
import { memo } from 'react';
import Dropdown from './Dropdown';

function NoQueryDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  optionsData,
  placeholder = 'Select...',
  labelText = null,
  disabled = false,
  preserveOrder = false,
  hideLabel = false,
  containerStyle = {},
  dropdownStyle = {},
  containerClassNames = null,
}) {
  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  function formatOptionsData() {
    return !preserveOrder
      ? optionsData
          .map((option) => ({
            id: option.id,
            name: option.name,
            value: option.value,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : optionsData;
  }

  return (
    <Dropdown
      name={name}
      label={labelText || name}
      hideLabel={hideLabel}
      containerStyle={containerStyle}
      errors={!!(errors[name] && touched[name])}
      options={formatOptionsData()}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      dropdownStyle={dropdownStyle}
      containerClassNames={containerClassNames}
    />
  );
}

NoQueryDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  optionsData: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  preserveOrder: PropTypes.bool,
  containerStyle: PropTypes.object,
  dropdownStyle: PropTypes.object,
  containerClassNames: PropTypes.string,
};

export default memo(NoQueryDropdown);
