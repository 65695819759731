/* eslint-disable no-restricted-syntax */
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';

function DynamicFilterDropdown({
  field: { name: fieldName, value },
  form: { errors, touched, setFieldValue },
  dynamicQuery,
  dataName,
  placeholder = 'Select...',
  labelText = null,
  variables = {},
  disabled = false,
  hideLabel = false,
  style = {},
  search = false,
  required = false,
  filterElements = null,
  callbackFn = null,
  removeOptionIf = null,
}) {
  const [remoteData, setRemoteData] = useState([]);

  const { t } = useTranslation('formfields');

  const optionsHandler = (d) => {
    setRemoteData(
      d.reduce((arr, { id, value: itemValue, name, ...rest }) => {
        if (removeOptionIf) {
          for (const [key, val] of Object.entries(removeOptionIf)) {
            if (rest[key] === val) {
              return arr;
            }
          }
        }
        if (!(filterElements && filterElements[itemValue])) {
          arr.push({
            id,
            value: itemValue,
            name: t(name),
          });
        }
        return arr;
      }, []),
    );
  };

  const { error, loading, data } = useQuery(dynamicQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      optionsHandler(
        [...d[dataName]].sort((a, b) => a.name.localeCompare(b.name)),
      );
    },
  });

  const handleChange = (e) => {
    if (callbackFn) {
      callbackFn(e.target.value, remoteData);
    }
    setFieldValue(fieldName, e.target.value);
  };

  useEffect(() => {
    if (filterElements && data) {
      optionsHandler(data[Object.keys(data)[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterElements]);

  return (
    <Dropdown
      name={fieldName}
      label={labelText || fieldName}
      hideLabel={hideLabel}
      error={error}
      containerStyle={style}
      errors={!!(errors[fieldName] && touched[fieldName])}
      loading={loading}
      options={remoteData}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      search={search}
      required={required}
    />
  );
}

DynamicFilterDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  dynamicQuery: PropTypes.object.isRequired,
  dataName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  variables: PropTypes.object,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  style: PropTypes.object,
  search: PropTypes.bool,
  required: PropTypes.bool,
  filterElements: PropTypes.object,
  callbackFn: PropTypes.func,
  removeOptionIf: PropTypes.object,
};

export default memo(DynamicFilterDropdown);
