import PropTypes from 'prop-types';

import useCreateNewCode from '../helpers/useCreateNewCode';

function ButtonAddNewCode(props) {
  const { value, disabled } = props;

  const { createNewCode, loading } = useCreateNewCode(value);

  return (
    <div>
      <button
        type="button"
        className={`button mr-0 is-primary is-small ${
          loading ? 'is-loading' : ''
        }`}
        onClick={createNewCode}
        disabled={disabled || loading}
      >
        Create Guide Code
      </button>
    </div>
  );
}

ButtonAddNewCode.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default ButtonAddNewCode;
