import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsMaterialCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'material',
        headerName: 'Material',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'totalUnits',
        headerName: 'Total Units',
        minWidth: 100,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsMaterialCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsMaterialCustomer;
