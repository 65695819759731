import { gql } from '@apollo/client';

const AdminLocationInfo = gql`
  fragment AdminLocationInfo on AdministratorLocation {
    id
    name
    slug
    createdAt
    updatedAt
    address
    geoData
    phone
    organizationId
    logoUrl
    organization {
      id
      name
      slug
      createdAt
      updatedAt
    }
  }
`;

export default AdminLocationInfo;
