import { gql } from '@apollo/client';

export default gql`
  query orderActivity($id: ID!) {
    orderActivity(id: $id) {
      id
      affectedId
      affectedClass
      affectedObject
      eventVerb
      eventSubject
      userResponsible
      timeOfAction
      userLogo
    }
  }
`;
