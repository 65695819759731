import { useQuery } from '@apollo/client';
import {
  GET_CUSTOMER_LOC_API_SETTINGS,
  GET_MANUF_ORG_API_SETTINGS,
} from '@fullcontour/shared-api';
import { useState } from 'react';
import { useParams } from 'react-router';
import { LoadingOrError } from '../../../shared';
import ApiSettingsFormInner from './ApiSettingsFormInner';
import { getRoot } from '../../../../utils/functions';

function ApiSettingsForm() {
  const app = import.meta.env.VITE_APP_NAME;
  const { type, slug } = useParams();
  const root = getRoot();

  const [apiSettings, setApiSettings] = useState({});
  const [entityId, setEntityId] = useState(null);

  let query;
  let dataName;

  if (type === 'manufacturer' || app === 'manuf_portal') {
    query = GET_MANUF_ORG_API_SETTINGS;
    dataName = 'manufacturerOrganization';
  } else {
    query = GET_CUSTOMER_LOC_API_SETTINGS;
    dataName = 'customerLocation';
  }

  const { error, loading } = useQuery(query, {
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      if (d[dataName].apiSetting) {
        setApiSettings(d[dataName].apiSetting);
      }
      setEntityId(d[dataName].id);
    },
  });

  if (loading || error)
    return (
      <LoadingOrError error={error} loading={loading} title="API Settings" />
    );

  return (
    <ApiSettingsFormInner
      apiSettings={apiSettings}
      root={root}
      type={type}
      entityId={entityId}
    />
  );
}

export default ApiSettingsForm;
