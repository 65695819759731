import { gql } from '@apollo/client';
import { FeedbackCategoryInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createFeedbackCategory($input: FeedbackCategoryCreateInput!) {
    createFeedbackCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      feedbackCategory {
        ...FeedbackCategoryInfo
      }
    }
  }
  ${FeedbackCategoryInfo}
  ${ErrorMessageInfo}
`;
