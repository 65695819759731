import { gql } from '@apollo/client';
import { ModifierGroupInfo } from '../fragments';

export default gql`
  query modifierGroup($slug: String!) {
    modifierGroup(slug: $slug) {
      ...ModifierGroupInfo
    }
  }
  ${ModifierGroupInfo}
`;
