import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CustomerLocationContext,Info } from '../../../../shared';
import BillingCreditCardFormModal from './BillingCreditCardFormModal';
import { useContext } from 'react';

function BillingCreditCardForm({
  modalOpen,
  openModal,
  closeModal,
  action = null,
  refetch,
  customer,
  type,
  creditCard,
}) {
  const { refetchCustomerLocation } = useContext(CustomerLocationContext);
  const { t } = useTranslation('billing');
  return (
    <>
      <button
        className="button is-small is-primary"
        type="button"
        onClick={() => openModal('New')}
      >
        <span className="icon is-small">
          <i className="bx bx-plus-medical" />
        </span>
        <span>{t('Add Payment Method')}</span>
      </button>
      <Info
        className="has-tooltip-arrow has-tooltip-multiline "
        dataTooltip={t(
          'If you would like to apply changes to the existing Credit Card, please use the "Add Payment Method" option. It will allow to add a new Credit Card and after that you will be able to remove the previous one. If any challenges with payment methods please contact Support team via email, phone or chat.',
        )}
      />

      <BillingCreditCardFormModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        refetch={refetch}
        action={action}
        customer={customer}
        type={type}
        creditCard={creditCard}
        refetchCustomerLocation={refetchCustomerLocation}
      />
    </>
  );
}

BillingCreditCardForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  refetch: PropTypes.func.isRequired,
};

export default BillingCreditCardForm;
