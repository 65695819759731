import PropTypes from 'prop-types';
import PreferenceSection from './PreferenceSection';

function PreferenceComplaint(props) {
  const { patient } = props;

  return (
    <PreferenceSection title="Chief Complaint">
      <div>
        <p>
          {patient && patient.ChiefComplaint ? patient.ChiefComplaint : 'none'}
        </p>
      </div>
    </PreferenceSection>
  );
}

PreferenceComplaint.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PreferenceComplaint;
