import { gql } from '@apollo/client';

const ManufacturerOrganizationInfo = gql`
  fragment ManufacturerOrganizationInfo on ManufacturerOrganization {
    id
    name
    slug
    createdAt
    updatedAt
    address
    geoData
    hidden
    administratorLocationId
    administratorLocation {
      id
      name
    }
    phone
    logoUrl
    language
    shortName
    website
    designTypeIds
    manufacturerDesignTypes {
      id
      designTypeId
    }
  }
`;

export default ManufacturerOrganizationInfo;
