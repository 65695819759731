import { gql } from '@apollo/client';
import { AdminMessageInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createAdminMessage($input: AdminMessageCreateInput!) {
    createAdminMessage(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminMessage {
        ...AdminMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
  ${AdminMessageInfo}
`;
