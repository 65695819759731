import { gql } from '@apollo/client';
import { ErrorMessageInfo, TurnaroundTimeInfo } from '../fragments';

export default gql`
  mutation createTurnaroundTime($input: TurnaroundTimeCreateInput!) {
    createTurnaroundTime(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      turnaroundTime {
        ...TurnaroundTimeInfo
      }
    }
  }
  ${TurnaroundTimeInfo}
  ${ErrorMessageInfo}
`;
