import PropTypes from 'prop-types';
import FeedbackInner from './FeedbackInner';
import feedbackPermissions from './permissions';

function Feedback({ showFeedback = {}, ...rest }) {
  if (!feedbackPermissions.showFeedback({ ...showFeedback })) {
    return null;
  }

  return <FeedbackInner {...rest} />;
}

Feedback.propTypes = {
  showFeedback: PropTypes.object,
};

export default Feedback;
