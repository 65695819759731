import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  AuthLayout,
  ForgotPassword,
  ResetPassword,
  SignIn,
} from '@fullcontour/auth';
import NewPassword from '@fullcontour/auth/src/views/NewPassword';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import 'yet-another-react-lightbox/styles.css';
import { LanguageProvider, RouteProvider } from '../Context';
import { ErrorPages } from '../Errors';
import HelpCenterBar from '../HelpCenterBar/HelpCenterBar';
import LegalHOC from '../LegalHOC/LegalHOC';
import { AdminMessageModal } from '../../views';
import { ResponsiveContainer } from '../../layouts';
import useRedirect from '../../routing/useRedirect';

function AppHoc({
  children,
  app,
  values,
  routing = {},
  bodyElement = null,
  guidedFlow = null,
}) {
  const {
    tempCredentials,
    login,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    currentUser,
    isSignedIn,
    logOut,
    lastLocation,
    error,
    cleanError,
  } = values;
  useRedirect(routing?.redirects);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(
    window.localStorage.getItem('defaultLanguage') || 'en',
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);
  useEffect(() => {
    if (
      !!currentUser &&
      isSignedIn &&
      lastLocation &&
      (lastLocation === '/signin' || lastLocation === '/')
    ) {
      navigate('/orders');
    }
  }, [currentUser, isSignedIn, lastLocation]);
  function changeDefaultLanguage(value) {
    setLocale(value);
  }
  return (
    <LanguageProvider value={{ locale, changeDefaultLanguage }}>
      <RouteProvider value={routing}>
        <LegalHOC>
          <Routes>
            <Route
              path="/"
              element={
                currentUser && isSignedIn && <Navigate to="/orders" />
                // <AuthLayout app={app}>
                //   <SignIn
                //     login={login}
                //     currentUser={currentUser}
                //     app={app}
                //     lastLocation={lastLocation}
                //     error={error}
                //     cleanError={cleanError}
                //   />
                // </AuthLayout>
              }
            />
            <Route
              path="/signin"
              element={
                <AuthLayout app={app}>
                  <SignIn
                    login={login}
                    currentUser={currentUser}
                    app={app}
                    lastLocation={lastLocation}
                    error={error}
                    cleanError={cleanError}
                  />
                </AuthLayout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthLayout app={app} newPassword>
                  <ForgotPassword
                    error={error}
                    forgotPassword={forgotPassword}
                    cleanError={cleanError}
                  />
                </AuthLayout>
              }
            />
            <Route
              path="/new-password"
              element={
                <AuthLayout app={app} newPassword>
                  <NewPassword
                    changePassword={changePassword}
                    tempCredentials={tempCredentials}
                    currentUser={currentUser}
                    logOut={logOut}
                    cleanError={cleanError}
                    error={error}
                    login={login}
                  />
                </AuthLayout>
              }
            />
            <Route
              path="/reset-password"
              element={
                <AuthLayout app={app} newPassword>
                  <ResetPassword
                    resetPassword={forgotPasswordSubmit}
                    forgotPassword={forgotPassword}
                    tempCredentials={tempCredentials}
                    cleanError={cleanError}
                    error={error}
                    login={login}
                  />
                </AuthLayout>
              }
            />
            <Route path="/error-page/:error" element={<ErrorPages />} />
            {!!currentUser && isSignedIn && (
              <Route
                path="*"
                element={
                  <>
                    <AdminMessageModal />
                    <ResponsiveContainer
                      bodyElement={bodyElement}
                      guidedFlow={guidedFlow}
                    >
                      {children}
                    </ResponsiveContainer>
                  </>
                }
              />
            )}
          </Routes>
        </LegalHOC>
        <HelpCenterBar />
        <NotificationContainer />
      </RouteProvider>
    </LanguageProvider>
  );
}

AppHoc.propTypes = {
  app: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  routing: PropTypes.object,
  values: PropTypes.object.isRequired,
  bodyElement: PropTypes.any,
  guidedFlow: PropTypes.any,
};

export default memo(AppHoc);
