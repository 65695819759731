import { UPDATE_CUSTOMER_LOC_PREFS } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_CUSTOMER_LOC_PREFS,
    variables,
  });
  const { updateCustomerLocationDesignPreference } = result.data;
  if (updateCustomerLocationDesignPreference.errorMessages.length) {
    setErrorMessages(updateCustomerLocationDesignPreference.errorMessages);
  } else {
    clearErrorMessages();
    toggleEdit();
  }
}

export default update;
