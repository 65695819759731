import PropTypes from 'prop-types';

import { toothDisplayRange } from '../../../../../../../transforms';
import DataWrap from '../../OrderShowInfoDataWrapper';

function InfoItemBasic(props) {
  const { item } = props;
  return (
    <div className="column">
      <div>
        <div className="columns">
          <div className="column">
            <DataWrap
              title="Material / Shade"
              dataItem={`${item.materialName}${
                item.color ? ` / ${item.color}` : ''
              }`}
            />

            {item.teeth && item.teeth.length > 0 && (
              <DataWrap
                title="Teeth"
                dataItem={toothDisplayRange(item.teeth)}
              />
            )}
            {item.arches && item.arches.length > 0 && (
              <DataWrap title="Arches" dataItem={item.arches.join(', ')} />
            )}
            {item.splitFile && (
              <DataWrap
                title="Split File?"
                dataItem={item.splitFile ? 'Yes' : 'No'}
              />
            )}
            {item.modifiers.length > 0 && (
              <DataWrap
                title="Modifiers"
                dataItem={item.modifiers.map((mod) => mod.name).join(', ')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

InfoItemBasic.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InfoItemBasic;
