import PropTypes from 'prop-types';

import DataWrap from '../../OrderShowInfoDataWrapper';

function InfoItemDesign(props) {
  const { item } = props;
  return (
    <div className="column">
      <DataWrap title="Design Type" dataItem={item.designTypeName} />
    </div>
  );
}

InfoItemDesign.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InfoItemDesign;
