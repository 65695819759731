class BaseDefinition {
  constructor(file, designUserId, order, fileType = 'finished_scans') {
    this.file = file;
    this.designUserId = designUserId;
    this.parsedCase = {
      file: {
        originalFileName: this.file.name,
        fileType,
        fileUrl: null,
        orderId: order ? order.id : null,
      },
      designUserId,
      order: order || null,
      errors: order
        ? []
        : [
            {
              error: true,
              message: 'Case not found',
            },
          ],
    };
  }

  buildCase = (args) => {
    const { mappedZipFiles } = args || {};
    let completeCase = this.parsedCase;

    if (mappedZipFiles) {
      completeCase = { mappedZipFiles, ...this.parsedCase };
    }

    return completeCase;
  };
}

export default BaseDefinition;
