import {
  Center,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Preload,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { Suspense, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import ThreeLoader from '../OrthoViewer/ThreeViewer/Loader/index';
import CutLineControl from './CutLineControl';
import CutLineElement from './CutLineElement/CutLineElement';
import StlElement from './StlElement';
import Styles from './ThreeStlFiber.module.scss';

function ThreeStlFiber({ containerStyle = {}, file = null, ptsData = null }) {
  const orbitRef = useRef();

  const [cutLineVisible, setCutLineVisible] = useState(true);
  const [matchedHeight, setMatchedHeight] = useState(200);
  const { ref } = useResizeObserver({
    onResize: ({ width: wid }) => {
      setMatchedHeight(wid);
    },
  });

  return (
    <div
      className={Styles.mainCanvas}
      style={{ height: `${matchedHeight}px`, ...containerStyle }}
      ref={ref}
    >
      <Canvas shadows frameloop="demand">
        <Suspense fallback={<ThreeLoader loading />}>
          <Preload all />
          <Environment files="https://cdn.fullcontour.com/backgrounds/empty_warehouse_01_1k.hdr" />
          <PerspectiveCamera
            makeDefault
            fov={35}
            near={1}
            far={1000}
            position={[0, 0, 130]}
          />
          <Center>
            {file?.downloadUrl && <StlElement downloadUrl={file.downloadUrl} />}

            {ptsData && cutLineVisible && <CutLineElement ptsData={ptsData} />}
            <OrbitControls ref={orbitRef} />
          </Center>
        </Suspense>
      </Canvas>

      {matchedHeight > 400 && ptsData && (
        <CutLineControl
          setCutLineVisible={setCutLineVisible}
          cutLineVisible={cutLineVisible}
        />
      )}
    </div>
  );
}
ThreeStlFiber.propTypes = {
  containerStyle: PropTypes.object,
  file: PropTypes.object,
  ptsData: PropTypes.object,
};

export default ThreeStlFiber;
