import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';
import ButtonInformattionShared from './ButtonInformattionShared';

function ButtonInformation() {
  const {
    data: { category, fullDescription },
  } = useGuideCodeCardContext();

  return (
    <ButtonInformattionShared
      guideCategoryImages={category?.guideCategoryImages}
      description={fullDescription}
    />
  );
}

export default ButtonInformation;
