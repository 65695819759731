import { gql } from '@apollo/client';

export default gql`
  mutation destroyCustomerOrganization(
    $input: CustomerOrganizationDestroyInput!
  ) {
    destroyCustomerOrganization(input: $input) {
      clientMutationId
      organization {
        id
      }
    }
  }
`;
