import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CaseActionsButtonAddItem({ isParsed, submit, arrayHelpers }) {
  const { t } = useTranslation('orders');

  const addOrderItem = (e) => {
    e.preventDefault();

    arrayHelpers.push({
      units: 1,
      materialName: '',
      teeth: [],
      arches: [],
      manufacturerOrganizationId: '',
      designTypeId: '',
      splitFile: false,
      guideCodes: [],
    });
  };

  return !isParsed ? (
    <button
      type="button"
      onClick={addOrderItem}
      disabled={submit}
      data-cy="add-order-item"
      className="button is-small mt-1 px-5 has-tooltip-arrow has-tooltip-left"
      data-tooltip={t('Add an additional order item to this case')}
    >
      <BoxIcon name="bx-plus-medical" />
    </button>
  ) : null;
}

CaseActionsButtonAddItem.propTypes = {
  isParsed: PropTypes.bool.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  submit: PropTypes.bool.isRequired,
};

export default CaseActionsButtonAddItem;
