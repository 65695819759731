import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import OrthoStlViewer from '../../OrthoStlViewer/OrthoStlViewer';

import Pagination from './Pagination';

function PaginationContainer({
  itemsPerPage,
  data,
  name,
  hexOrderCode,
  toggleVersions = false,
  itemWidth = '25%',
  allFiles = null,
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const changePage = useCallback((num) => {
    setCurrentPage(num);
  }, []);

  const startingElement = currentPage * itemsPerPage;

  return (
    <>
      <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
        {data
          .slice(startingElement, startingElement + itemsPerPage)
          .map((item) => (
            <div
              key={item.id}
              style={{
                padding: '0.5rem',
                flexBasis: itemWidth,
                maxWidth: itemWidth,
                position: 'relative',
              }}
            >
              <OrthoStlViewer
                file={item}
                allFiles={allFiles}
                name={name}
                hexOrderCode={hexOrderCode}
              />
              <p
                className="has-text-white"
                style={{
                  wordBreak: 'break-all',
                  position: 'absolute',
                  left: 26,
                  bottom: 20,
                }}
              >
                <small style={{ fontSize: '65%' }}>
                  {item.originalFileName}
                  {toggleVersions && (
                    <>
                      <br />
                      Version: {item.ver}
                    </>
                  )}
                </small>
              </p>
            </div>
          ))}
      </div>
      <Pagination
        totalAmount={data.length}
        perPage={itemsPerPage}
        onPageChange={changePage}
      />
    </>
  );
}

PaginationContainer.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  itemWidth: PropTypes.string,
  data: PropTypes.array.isRequired,
  toggleVersions: PropTypes.bool,
  allFiles: PropTypes.array,
  name: PropTypes.string.isRequired,
  hexOrderCode: PropTypes.string.isRequired,
};

export default PaginationContainer;
