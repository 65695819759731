/* eslint-disable react/no-danger */
import { useQuery } from '@apollo/client';
import {
  GET_ORDER_HOLD_REASONS,
  GET_ORDER_HOLD_REASONS_DROPDOWN,
} from '@fullcontour/shared-api';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { LoadingOrError } from '../../../../shared';

function MessageModalFormHoldReasons(props) {
  const { label, placeholder, changeMessage, order, message, selectedCases } =
    props;

  const [dataForDropdown, setDataForDropdown] = useState([]);
  const [messageEditable, setMessageEditable] = useState(false);

  const { error, loading } = useQuery(
    order ? GET_ORDER_HOLD_REASONS_DROPDOWN : GET_ORDER_HOLD_REASONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { orderId: order?.id },
      onCompleted({ orderHoldReasons }) {
        if (order) {
          return setDataForDropdown(orderHoldReasons);
        }
        const designTypesObj = selectedCases.reduce(
          (designTypesObjAcc, { data: { itemsMetadata } }) => {
            const arrOfDesignTypes = itemsMetadata.design_types.split(',');
            arrOfDesignTypes.forEach((designType) => {
              // eslint-disable-next-line no-param-reassign
              designTypesObjAcc[designType.toLowerCase()] = true;
            });
            return designTypesObjAcc;
          },
          {},
        );
        orderHoldReasons.forEach(
          ({
            designTypes,
            message: holdMessage,
            name,
            requiresCustomMessage,
          }) => {
            if (designTypes.length) {
              designTypes.forEach((singleDesignType) => {
                if (designTypesObj[singleDesignType.name.toLowerCase()]) {
                  setDataForDropdown((prev) => [
                    ...prev,
                    {
                      id: name,
                      name,
                      value: holdMessage,
                      requiresCustomMessage,
                    },
                  ]);
                }
              });
            }
          },
        );
        return null;
      },
    },
  );

  const editableMessageHandler = (val) => {
    setMessageEditable(
      dataForDropdown.find((item) => item.value === val).requiresCustomMessage,
    );
  };

  useEffect(() => {
    if (message && dataForDropdown.length) {
      editableMessageHandler(dataForDropdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(e) {
    editableMessageHandler(e.target.value);
    changeMessage(e);
  }

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Case" />;

  return (
    <div>
      <h5 className="title is-5">{label}</h5>
      <div className="select" style={{ width: '100%' }}>
        <select
          style={{ width: '100%' }}
          onChange={(e) => handleChange(e)}
          defaultValue="Select a hold reason"
          id="holdReasonSelection"
          name="holdReasonSelection"
        >
          <option disabled>Select a hold reason</option>
          {dataForDropdown?.map((d) => (
            <option
              key={d.id || d.value}
              value={d.value}
              style={{ width: '100%' }}
            >
              {d.name}
            </option>
          ))}
        </select>
      </div>

      {messageEditable ? (
        <div className="mt-3">
          <textarea
            id="reasonDescription"
            name="reasonDescription"
            placeholder={placeholder}
            rows="4"
            className="textarea"
            value={message}
            onChange={changeMessage}
            disabled={!messageEditable}
          />
        </div>
      ) : (
        <div
          className="mt-2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(message, {
              ADD_ATTR: ['target', 'href', 'style'],
              ADD_TAG: ['a'],
            }),
          }}
        />
      )}
    </div>
  );
}

MessageModalFormHoldReasons.propTypes = {
  changeMessage: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedCases: PropTypes.array.isRequired,
};

export default memo(MessageModalFormHoldReasons);
