import { useReactiveVar } from '@apollo/client';
import { Dropdown, getHelpers } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function DesignTypeDropdown({
  field: { name, value },
  form: { setFieldValue, errors, touched },
  setSelectedDesignType,
  data,
  id,
  disabled = false,
  infoCallback = null,
}) {
  const helpFuncsData = useReactiveVar(getHelpers);

  const { t } = useTranslation('formfields');
  const placeholder = t('Design Type...');
  const label = t('Design Type');

  useEffect(() => {
    if (value && value !== '') {
      setSelectedDesignType(value);
    }
  }, [value]);

  function handleChange(e) {
    setFieldValue(name, e.target.value);
    if (helpFuncsData) {
      helpFuncsData?.helpFuncs?.next();
    }
  }

  const filteredDesignTypes = data.map((designType) => {
    const {
      acceptRushDesigns,
      mappedNames,
      billByTooth,
      billByArch,
      toothChart,
      archChart,
      designApproval,
      designGuideCategories,
      modifierGroups,
      disableInHouse,
      isInHouse,
      manufacturers,
      ...rest
    } = designType;
    return {
      ...rest,
      name: designType.text,
    };
  });

  return (
    <Dropdown
      id={id}
      infoCallback={infoCallback}
      name={name}
      label={label || name}
      error={errors[name]}
      errors={!!(errors[name] && touched[name])}
      options={filteredDesignTypes}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      search
      required
      containerClassNames="mb-1"
    />
  );
}

DesignTypeDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  infoCallback: PropTypes.func,
  setSelectedDesignType: PropTypes.func.isRequired,
};

export default memo(DesignTypeDropdown);
