const timeOptions = [
  { id: '12:00am', name: '12:00am', value: '12:00am' },
  { id: '12:30am', name: '12:30am', value: '12:30am' },
  { id: '1:00am', name: '1:00am', value: '1:00am' },
  { id: '1:30am', name: '1:30am', value: '1:30am' },
  { id: '2:00am', name: '2:00am', value: '2:00am' },
  { id: '2:30am', name: '2:30am', value: '2:30am' },
  { id: '3:00am', name: '3:00am', value: '3:00am' },
  { id: '3:30am', name: '3:30am', value: '3:30am' },
  { id: '4:00am', name: '4:00am', value: '4:00am' },
  { id: '4:30am', name: '4:30am', value: '4:30am' },
  { id: '5:00am', name: '5:00am', value: '5:00am' },
  { id: '5:30am', name: '5:30am', value: '5:30am' },
  { id: '6:00am', name: '6:00am', value: '6:00am' },
  { id: '6:30am', name: '6:30am', value: '6:30am' },
  { id: '7:00am', name: '7:00am', value: '7:00am' },
  { id: '7:30am', name: '7:30am', value: '7:30am' },
  { id: '8:00am', name: '8:00am', value: '8:00am' },
  { id: '8:30am', name: '8:30am', value: '8:30am' },
  { id: '9:00am', name: '9:00am', value: '9:00am' },
  { id: '9:30am', name: '9:30am', value: '9:30am' },
  { id: '10:00am', name: '10:00am', value: '10:00am' },
  { id: '10:30am', name: '10:30am', value: '10:30am' },
  { id: '11:00am', name: '11:00am', value: '11:00am' },
  { id: '11:30am', name: '11:30am', value: '11:30am' },
  { id: '12:00pm', name: '12:00pm', value: '12:00pm' },
  { id: '12:30pm', name: '12:30pm', value: '12:30pm' },
  { id: '1:00pm', name: '1:00pm', value: '1:00pm' },
  { id: '1:30pm', name: '1:30pm', value: '1:30pm' },
  { id: '2:00pm', name: '2:00pm', value: '2:00pm' },
  { id: '2:30pm', name: '2:30pm', value: '2:30pm' },
  { id: '3:00pm', name: '3:00pm', value: '3:00pm' },
  { id: '3:30pm', name: '3:30pm', value: '3:30pm' },
  { id: '4:00pm', name: '4:00pm', value: '4:00pm' },
  { id: '4:30pm', name: '4:30pm', value: '4:30pm' },
  { id: '5:00pm', name: '5:00pm', value: '5:00pm' },
  { id: '5:30pm', name: '5:30pm', value: '5:30pm' },
  { id: '6:00pm', name: '6:00pm', value: '6:00pm' },
  { id: '6:30pm', name: '6:30pm', value: '6:30pm' },
  { id: '7:00pm', name: '7:00pm', value: '7:00pm' },
  { id: '7:30pm', name: '7:30pm', value: '7:30pm' },
  { id: '8:00pm', name: '8:00pm', value: '8:00pm' },
  { id: '8:30pm', name: '8:30pm', value: '8:30pm' },
  { id: '9:00pm', name: '9:00pm', value: '9:00pm' },
  { id: '9:30pm', name: '9:30pm', value: '9:30pm' },
  { id: '10:00pm', name: '10:00pm', value: '10:00pm' },
  { id: '10:30pm', name: '10:30pm', value: '10:30pm' },
  { id: '11:00pm', name: '11:00pm', value: '11:00pm' },
  { id: '11:30pm', name: '11:30pm', value: '11:30pm' },
];

export default timeOptions;
