import { gql } from '@apollo/client';
import {
  DesignPreferenceInfo,
  DesignPreferenceProfileInfo,
  DesignTypeInfo,
} from '../fragments';

export default gql`
  query designPreferenceProfile($slug: String, $id: ID) {
    designPreferenceProfile(slug: $slug, id: $id) {
      ...DesignPreferenceProfileInfo
    }
  }
  ${DesignPreferenceProfileInfo}
  ${DesignPreferenceInfo}
  ${DesignTypeInfo}
`;
