import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormErrors from '../FieldContainer/FormErrors';

function CheckboxField({
  form: { setFieldValue, values },
  field: { name, value },
  type = 'checkbox',
  id = null,
  headerText = null,
  removeLabel = false,
  customTranslation = false,
  labelText = null,
  required = false,
  disabled = false,
  customToggle = null,
  useFieldName = false,
  onChange = null,
  checked = null,
  size = '',
}) {
  const { t } = useTranslation('formfields');

  let label = ' ';
  if (labelText && !removeLabel) {
    label = labelText;
  }
  if (!labelText && !removeLabel) {
    label = name;
  }

  function toggle(e) {
    if (onChange) {
      onChange(e, { value: name });
    }

    if (type === 'radio') {
      const children = Array.from(
        e.target.parentElement.parentElement.children,
      );
      children.map((item) =>
        item.querySelector(`input[name="${name}"]`)?.id
          ? setFieldValue(
              item.querySelector(`input[name="${name}"]`)?.id,
              false,
            )
          : null,
      );
      if (useFieldName) {
        setFieldValue(name, id);
      } else {
        setFieldValue(id, !value);
      }
    } else {
      setFieldValue(name, !value);
    }
  }

  return (
    <>
      {headerText && <h6 className="title is-6 mb-2">{t(headerText)}</h6>}
      <div className="is-flex is-align-items-center">
        <input
          id={type === 'radio' ? id : name}
          name={name}
          type={type}
          className={`is-checkradio is-primary ${size}`}
          disabled={disabled}
          onChange={customToggle || toggle}
          checked={
            // eslint-disable-next-line no-nested-ternary
            type === 'radio'
              ? useFieldName
                ? values[name] === id
                : values[id]
              : checked !== null
                ? checked
                : value
          }
          required={required}
        />
        <label className="checkbox" htmlFor={type === 'radio' ? id : name}>
          {customTranslation ? label : t(label)}{' '}
          {required && <span className="has-text-danger">*</span>}
        </label>
      </div>
      <FormErrors name={name} />
    </>
  );
}

CheckboxField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  headerText: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  removeLabel: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string,
  customToggle: PropTypes.func,
  useFieldName: PropTypes.bool,
  checked: PropTypes.bool,
  customTranslation: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default CheckboxField;
