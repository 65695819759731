function parsejobsElements(job) {
  return job.modelElements.map((element) => {
    const designTypeName = element.Items.replace(/([0-9]|-)/g, '').replace(
      /\s+$/,
      '',
    );
    return {
      splitFile: false,
      standardEl: element.ModelElementType === 'meIndicationRegular',
      modelJobId: element.ModelJobID,
      designTypeName,
      materialName: element.CacheMaterialName,
      color: element.CacheColor.toString(),
      manufacturerName: element.ManufName,
      toothIndications: [
        ...new Set(element.toothElements.map((el) => el.CacheToothTypeClass)),
      ],
      teeth: element.toothElements.map((el) => el.ToothNumber),
      consumed: false,
    };
  });
}

function tempOrderItems(modelJobs) {
  const tempItems = [];
  modelJobs.forEach((job) => {
    const jobTempItems = parsejobsElements(job);

    jobTempItems.forEach((pushable) => {
      tempItems.push(pushable);
    });
  });

  return tempItems;
}

export default tempOrderItems;
