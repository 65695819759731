import PropTypes from 'prop-types';
import Message from '../Components/Message/Message';

function ServerErrorsAmplify({ errors = '', title = '' }) {
  return errors && errors.length ? (
    <Message type="danger" title={title}>
      <p>{errors}</p>
    </Message>
  ) : null;
}

ServerErrorsAmplify.propTypes = {
  errors: PropTypes.string,
  title: PropTypes.string,
};

export default ServerErrorsAmplify;
