/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import { useFormikContext } from 'formik';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export function Persist({ name, exclude, isSessionStorage }) {
  const { values } = useFormikContext();
  const saveForm = debounce((data) => {
    if (isSessionStorage) {
      window.sessionStorage.setItem(name, JSON.stringify(data));
    } else {
      window.localStorage.setItem(name, JSON.stringify(data));
    }
  }, 500);
  const excludedData = Object.keys(values).reduce((obj, item) => {
    let objValue = values[item];
    if (exclude[item]) {
      objValue = '';
    }
    return { ...obj, [item]: objValue };
  }, {});
  useEffect(() => {
    saveForm(excludedData);
  }, [excludedData, saveForm]);

  return null;
}

Persist.propTypes = {
  name: PropTypes.string.isRequired,
  exclude: PropTypes.object,
  isSessionStorage: PropTypes.bool,
};

export default Persist;
