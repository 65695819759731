import { gql } from '@apollo/client';

export default gql`
  query customerOrganizationsMultiSelect($adminLocationIds: [ID!]) {
    customerOrganizations(adminLocationIds: $adminLocationIds) {
      id
      name
    }
  }
`;
