import PropTypes from 'prop-types';
import { useContext } from 'react';
import { OrderDetailsContext } from '../../../../../shared';
import ButtonInformattionShared from '../../../../../shared/DesignGuideSelect/components/GuideCodeCard/components/ButtonInformattionShared';
import OrderCodesNested from './OrderCodesNested';

function OrderCodes({ codes = [] }) {
  const { numberingSystem } = useContext(OrderDetailsContext);

  return (
    <div className="order-pref__codes-wrapper">
      {codes.map((code, idx) => (
        <div
          className="card"
          style={{ margin: 0, width: 'auto', maxWidth: 140 }}
          key={code.codeId || idx}
        >
          <div
            className="card-image"
            style={{
              height: 140,
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {code.categoryAdditionalInfo?.guideCategoryImages?.length ||
            code.categoryAdditionalInfo?.fullDescription ? (
              <div
                style={{ padding: 0, position: 'absolute', right: 5, top: 5 }}
              >
                <ButtonInformattionShared
                  guideCategoryImages={
                    code.categoryAdditionalInfo?.guideCategoryImages
                  }
                  description={code.categoryAdditionalInfo?.fullDescription}
                />
              </div>
            ) : null}

            <img
              src={
                code.fileUrl ||
                'https://s3-us-west-2.amazonaws.com/fc2-development/assets/images/l/default.svg'
              }
              style={{
                objectFit: 'contain',
                width: '100%',
              }}
              alt={code.fileUrl}
            />
          </div>
          <OrderCodesNested
            label="Teeth"
            items={code.nestedTeeth}
            numberingSystem={numberingSystem}
          />
          <OrderCodesNested label="Arches" items={code.nestedArches} />

          <header className="card-header">
            <p className="card-header-title">
              {code.showDropdown
                ? `${code.categoryName} - ${code.codes[0]}`
                : code.codes[0]}
            </p>
          </header>
          <div className="card-content">
            <p>{code.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

OrderCodes.propTypes = {
  codes: PropTypes.array,
};

export default OrderCodes;
