export const baseStyle = {
  position: 'relative',
  width: 200,
  height: 'auto',
  borderWidth: 2,
  borderColor: '#8e8e8e',
  borderStyle: 'dashed',
  borderRadius: 4,
  cursor: 'pointer',
  padding: '0.5em',
  margin: '1em 0',
};

export const activeStyle = {
  borderColor: '#1B77C8',
  backgroundColor: 'rgba(47, 139, 220, 0.1)',
};

export const rejectedStyle = {
  borderColor: '#1B77C8',
  backgroundColor: 'rgba(47, 139, 220, 0.1)',
};
