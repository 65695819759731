/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { withApollo } from '@apollo/client/react/hoc';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CreateNotification } from '../../../../shared';
import {
  checkPermissions,
  subscribeToOrder,
  unsubscribeToOrder,
} from '../helpers';

function ActionSubscribe({
  client,
  order = {
    orderSubscriptions: [],
  },
  currentUser,
  t,
  ...rest
}) {
  const props = { client, order, currentUser, t, ...rest };
  const [{ subscribeTitle, isSubscribed }, setState] = useState({
    isSubscribed: false,
    subscribeTitle: 'Subscribe to Case',
  });
  useEffect(() => {
    if (order) {
      findSubscriptions();
    }
  }, []);
  useEffect(() => {
    if (order && order.orderSubscriptions) {
      findSubscriptions();
    }
  }, [order]);

  function findSubscriptions() {
    const userClass = findUserClass();
    const subscribed = order.orderSubscriptions.some(
      (sub) =>
        sub.orderSubscriptionableType === userClass &&
        sub.orderSubscriptionableId === currentUser.username,
    );
    setState((state) => ({
      ...state,
      isSubscribed: subscribed,
      subscribeTitle: subscribed ? 'Unsubscribe to Case' : 'Subscribe to Case',
    }));
  }
  // eslint-disable-next-line class-methods-use-this
  function findUserClass() {
    const app = import.meta.env.VITE_APP_NAME;

    if (app === 'admin_portal') {
      return 'AdminUser';
    }

    if (app === 'customer_portal') {
      return 'User';
    }

    if (app === 'manuf_portal') {
      return 'ManufacturerUser';
    }

    return 'DesignUser';
  }
  function createSubscription() {
    subscribeToOrder(client, order.id);
  }
  function destroySubscription() {
    const userClass = findUserClass();
    const subscription = order.orderSubscriptions.find(
      (sub) =>
        sub.orderSubscriptionableType === userClass &&
        sub.orderSubscriptionableId === currentUser.username,
    );
    unsubscribeToOrder(client, subscription.id);
  }
  function subOrUnsub() {
    if (isSubscribed) {
      destroySubscription();
    }
    if (!isSubscribed) {
      createSubscription();
    }
    showMessage();
  }
  function showMessage() {
    CreateNotification.createNotification({
      type: 'success',
      message: `Succesfully ${isSubscribed ? 'unsubscribed' : 'subscribed'}`,
    });
  }
  return checkPermissions(props) ? (
    <a className="dropdown-item" onClick={subOrUnsub}>
      {t(subscribeTitle)}
    </a>
  ) : null;
}

ActionSubscribe.propTypes = {
  client: PropTypes.object.isRequired,
  order: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default flowRight(
  withApollo,
  withTranslation('orderactions'),
)(memo(ActionSubscribe));
