import { useParams } from 'react-router';
import NavMenuLink from '../NavMenuLink/NavMenuLink';

function UserShowNavMenu() {
  const { id } = useParams();

  return <NavMenuLink to={`/users/${id}`} name="details" />;
}

export default UserShowNavMenu;
