import { gql } from '@apollo/client';
import { ManufacturerUserInfo } from '../fragments';

export default gql`
  query manufacturerUsers($organizationId: String) {
    manufacturerUsers(organizationId: $organizationId) {
      ...ManufacturerUserInfo
    }
  }
  ${ManufacturerUserInfo}
`;
