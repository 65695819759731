import { BoxIcon, DesignGuideOrderContext } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import './GuideSelectionPopup.css';

function GuideSelectionPopup({
  designGuideCategories = [],
  index,
  currentOrderItem,
}) {
  const { guideModalOpen } = useContext(DesignGuideOrderContext);

  return (
    <button
      type="button"
      id="guide-btn"
      className="button is-outlined is-primary mr-3 py-4"
      onClick={() =>
        guideModalOpen(designGuideCategories, index, currentOrderItem)
      }
    >
      <BoxIcon
        className="icon is-flex is-align-items-center is-justify-content-center"
        name="bx-edit"
      />
    </button>
  );
}

GuideSelectionPopup.propTypes = {
  designGuideCategories: PropTypes.array,
  index: PropTypes.number.isRequired,
  currentOrderItem: PropTypes.object.isRequired,
};

export default GuideSelectionPopup;
