import { gql } from '@apollo/client';
import { ErrorMessageInfo, InternalRejectionReasonInfo } from '../fragments';

export default gql`
  mutation updateInternalRejectionReason(
    $input: InternalRejectionReasonUpdateInput!
  ) {
    updateInternalRejectionReason(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      internalRejectionReason {
        ...InternalRejectionReasonInfo
      }
    }
  }
  ${InternalRejectionReasonInfo}
  ${ErrorMessageInfo}
`;
