import { gql } from '@apollo/client';

const BusinessUnitInfo = gql`
  fragment BusinessUnitInfo on BusinessUnit {
    id
    name
    slug
    symbol
    value
    description
  }
`;

export default BusinessUnitInfo;
