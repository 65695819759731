import { gql } from '@apollo/client';
import { ModifierInfo } from '../fragments';

export default gql`
  query modifiers {
    modifiers {
      ...ModifierInfo
    }
  }
  ${ModifierInfo}
`;
