import { gql } from '@apollo/client';
import { CustomerOrganizationInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createCustomerOrganization(
    $input: CustomerOrganizationCreateInput!
  ) {
    createCustomerOrganization(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerOrganization {
        ...CustomerOrganizationInfo
      }
    }
  }
  ${CustomerOrganizationInfo}
  ${ErrorMessageInfo}
`;
