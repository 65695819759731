import designTypesGrouped from './designTypesGrouped';

const designTypeInfo = (designType, t) => {
  const designTypesGroupedData = designTypesGrouped(designType, t);
  const foundDesignType = Object.keys(designTypesGroupedData).find((item) =>
    designTypesGroupedData[item].types.has(designType),
  );

  return {
    content: foundDesignType
      ? designTypesGroupedData[foundDesignType].content
      : null,
  };
};

export default designTypeInfo;
