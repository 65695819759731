import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DropdownSelect } from '../../../../../shared';
import OrderActions from '../../../OrderActions';

function HeaderActionsMenu(props) {
  const { order } = props;

  const { t } = useTranslation('common');

  return (
    <DropdownSelect
      className="is-right"
      customOptions
      trigger={
        <button type="button" className="button is-primary">
          <span>{t('Actions')}</span>
          <span className="icon is-small">
            <i className="bx bx-chevron-down" />
          </span>
        </button>
      }
    >
      <OrderActions order={order} list={false} details {...props} />
    </DropdownSelect>
  );
}

HeaderActionsMenu.propTypes = {
  order: PropTypes.object.isRequired,
};

export default HeaderActionsMenu;
