import { gql } from '@apollo/client';

export default gql`
  query labLocationGuideCodeGroups($locationSlug: String, $id: ID) {
    labLocationGuideCodeGroups(locationSlug: $locationSlug, id: $id) {
      id
      name
      labLocationId
      guideCodes {
        id
        name
        description
        imageUrl
        guideCategory {
          name
          imageUrl
          allowArchSelection
          allowToothSelection
          description
          id
          showDropdown
          guideCategoryGroupId
          shortDescription
          guideCategoryGroup {
            designTypes {
              id
              name
            }
          }
          guideCodes {
            id
            name
            description
            imageUrl
          }
        }
      }
    }
  }
`;
