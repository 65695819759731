import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsDentalSystemCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      headerName: 'Organization',
      width: 200,
      sort: 'asc',
    },
    columnDefs: [
      {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Location',
      },
      {
        field: 'totalOrders',
        headerName: 'Total Orders',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsDentalSystemCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsDentalSystemCustomer;
