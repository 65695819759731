import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { withCurrentUser } from '../../../shared';
import ActionCsv from './ActionCsv';
import ActionDownload from './ActionDownload';
import ActionReassignSilo from './ActionReassignSilo';
import ActionResendFtp from './ActionResendFtp';
import ActionReviewCase from './ActionReviewCase';
import ActionSubscribe from './ActionSubscribe';
import ActionUpdateStatus from './ActionUpdateStatus';
import ActionWithMessage from './ActionWithMessage';
import {
  cancelOrder,
  downloadFiles,
  downloadMarkAtManuf,
  downloadMarkComplete,
  downloadMarkInProgress,
  markAtManuf,
  markComplete,
  markShipped,
  placeDesignHold,
  placeManufacturerHold,
  proceedAndRush,
  pushToFinished,
  reassignSilo,
  redesignAndRush,
  resendToFtp,
  reviewCase,
  rushOrder,
  sendForApproval,
  subscribe,
} from './permissions';
import { memo } from 'react';

function OrderActions({
  gridApi = {},
  selectedCases = [],
  order = null,
  ...rest
}) {
  const { root } = useParams();
  const props = { gridApi, selectedCases, order, ...rest };

  return (
    <>
      <ActionCsv actionTitle="Download CSV" showDetails={false} {...props} />
      <ActionDownload
        actionTitle="Download Files..."
        showList
        showDetails
        stateRoles={downloadFiles}
        {...props}
      />
      <ActionDownload
        actionTitle="Download and Mark At Manufacturer..."
        statusAction="start_manufacturing"
        showList
        showDetails
        stateRoles={downloadMarkAtManuf}
        {...props}
      />
      <ActionDownload
        actionTitle="Download and Mark In Progress..."
        statusAction="start_design"
        showList
        showDetails
        stateRoles={downloadMarkInProgress}
        {...props}
      />
      <ActionDownload
        actionTitle="Download and Mark Complete..."
        statusAction="complete_order"
        showList
        showDetails
        stateRoles={downloadMarkComplete}
        {...props}
      />
      <ActionWithMessage
        actionTitle="Hold..."
        messageTitle="Are you sure you want to place a hold?"
        label="Please select a reason for placing a hold on these case(s)"
        placeholder="Hold Reason"
        statusAction="place_a_design_hold"
        showList
        showDetails
        stateRoles={placeDesignHold}
        order={selectedCases.length === 1 ? selectedCases[0] : {}}
        {...props}
      />
      <ActionWithMessage
        actionTitle="Manufacturer Hold..."
        messageTitle="Are you sure you want to place a hold?"
        label="Please select a reason for placing a hold on these case(s)"
        placeholder="Hold Reason"
        statusAction="manufacturing_hold"
        showList
        showDetails
        stateRoles={placeManufacturerHold}
        order={selectedCases.length === 1 ? selectedCases[0] : {}}
        {...props}
      />

      <ActionWithMessage
        actionTitle="Release Hold..."
        messageTitle="Are you sure you want to release these cases?"
        label="Please provide a reason for releasing these cases"
        placeholder="Case Release Reason"
        statusAction="release_a_design_hold"
        messagePrefix="Case Release Reason:"
        showList
        showDetails
        stateRoles={proceedAndRush}
        {...props}
      />
      <ActionResendFtp
        actionTitle="Resend FTP..."
        messageTitle="Resend FTP"
        statusAction="resend_ftp"
        showList={false}
        showDetails
        stateRoles={resendToFtp}
        {...props}
      />
      <ActionReassignSilo
        actionTitle="Reassign Design Silo..."
        messageTitle="Reassign Design Silo"
        statusAction="reassign_order"
        showList
        showDetails
        stateRoles={reassignSilo}
        {...props}
      />
      {root !== 'orders' && (
        <ActionWithMessage
          actionTitle="Redesign..."
          messageTitle="Redesign Case"
          label="Please provide a reason why this case needs to be redesigned"
          placeholder="Redesign Reason"
          statusAction="redesign_and_rush"
          messagePrefix="Redesign Reason:"
          redesignFeedback
          showList
          showDetails
          stateRoles={redesignAndRush}
          {...props}
        />
      )}

      <ActionWithMessage
        actionTitle="Rush..."
        messageTitle="Rush Case"
        label="Please provide a reason why this case needs to be rushed"
        placeholder="Rush Reason"
        statusAction="start_a_rush"
        messagePrefix="Rush Reason:"
        showList
        showDetails
        stateRoles={rushOrder}
        {...props}
      />

      <ActionReviewCase
        showList={false}
        showDetails
        stateRoles={reviewCase}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Mark as Sent For Approval"
        statusAction="send_for_third_party_approval"
        showList={false}
        showDetails
        stateRoles={sendForApproval}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Mark At Manufacturer"
        statusAction="start_manufacturing"
        showList
        showDetails
        stateRoles={markAtManuf}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Mark As Shipped"
        statusAction="mark_as_shipped"
        showList
        showDetails
        stateRoles={markShipped}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Mark Complete"
        statusAction="complete_order"
        showList
        showDetails
        stateRoles={markComplete}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Push To Finish"
        statusAction="push_to_finished_status"
        showList
        showDetails
        stateRoles={pushToFinished}
        {...props}
      />
      <ActionUpdateStatus
        actionTitle="Cancel Case(s)"
        statusAction="cancel_order"
        showList
        showDetails
        stateRoles={cancelOrder}
        {...props}
      />
      <div style={{ borderBottom: '1px solid #ddd' }} />
      <ActionSubscribe
        showList={false}
        showDetails
        stateRoles={subscribe}
        {...props}
      />
    </>
  );
}

OrderActions.propTypes = {
  gridApi: PropTypes.object,
  selectedCases: PropTypes.array,
  order: PropTypes.object,
  list: PropTypes.bool.isRequired,
  details: PropTypes.bool.isRequired,
};

export default withCurrentUser({})(memo(OrderActions));
