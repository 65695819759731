import { ProfileListNavMenu } from '@fullcontour/common';

const routes = [
  {
    path: '/profile',
    component: ProfileListNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
