import { gql } from '@apollo/client';

export default gql`
  query customerTags {
    customerTags {
      id
      name
      slug
      locations {
        id
        name
        slug
      }
    }
  }
`;
