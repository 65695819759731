import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateOrderDesignerStatus($input: OrderDesignerStatusUpdateInput!) {
    updateOrderDesignerStatus(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        id
      }
    }
  }
  ${ErrorMessageInfo}
`;
