import { gql } from '@apollo/client';

export default gql`
  query manufacturerOrganizationsMultiSelect($adminLocationIds: [ID!]) {
    manufacturerOrganizations(adminLocationIds: $adminLocationIds) {
      id
      name
    }
  }
`;
