import OrderShowNavMenu from '../../shared/NavMenus/Orders/OrderShow/OrderShowNavMenu';

const routes = [
  {
    path: ['/orders/details/:id', '/orders/details/:id/:action'],
    component: OrderShowNavMenu,
  },
];

export default routes;
