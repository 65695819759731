import { gql } from '@apollo/client';

const FeedbackCategoryInfo = gql`
  fragment FeedbackCategoryInfo on FeedbackCategory {
    createdAt
    designTypes {
      id
      name
      slug
    }
    id
    name
    options {
      name
      id
      slug
    }
    slug
    updatedAt
  }
`;

export default FeedbackCategoryInfo;
