import { gql } from '@apollo/client';
import { ErrorMessageInfo, ModifierGroupInfo } from '../fragments';

export default gql`
  mutation updateModifierGroup($input: ModifierGroupUpdateInput!) {
    updateModifierGroup(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      modifierGroup {
        ...ModifierGroupInfo
      }
    }
  }
  ${ErrorMessageInfo}
  ${ModifierGroupInfo}
`;
