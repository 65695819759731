import { gql } from '@apollo/client';
import { CreditCardInfo } from '../fragments';

export default gql`
  query creditCards($labOrganizationId: ID, $labLocationId: ID) {
    creditCards(
      labOrganizationId: $labOrganizationId
      labLocationId: $labLocationId
    ) {
      ...CreditCardInfo
    }
  }
  ${CreditCardInfo}
`;
