import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';
import ObjElementToothInner from './ObjElementToothInner';

function ObjElementTooth({
  file,
  index,
  files,
  name,
  isUpper,
  isLower,
  unn,
  iprTeeth = null,
}) {
  const { currentOtp } = useContext(OrthoContext);

  const currentTooth = useMemo(() => {
    let cTooth;

    if (currentOtp) {
      cTooth = currentOtp.Teeth.find(
        (tooth) => parseInt(tooth.Unn, 10) === unn,
      );
    }

    return cTooth;
  }, [currentOtp, unn]);

  const obj = useLoader(OBJLoader, file.signedUrl);

  return currentTooth?.$type !== 'ExtractedTooth' ? (
    <ObjElementToothInner
      obj={obj}
      name={name}
      isUpper={isUpper}
      isLower={isLower}
      currentTooth={currentTooth}
      iprTooth={iprTeeth ? iprTeeth[currentTooth?.Unn] : null}
      index={index}
      files={files}
      unn={unn}
    />
  ) : null;
}

ObjElementTooth.propTypes = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  iprTeeth: PropTypes.object,
  name: PropTypes.string.isRequired,
  isUpper: PropTypes.bool.isRequired,
  isLower: PropTypes.bool.isRequired,
  unn: PropTypes.number.isRequired,
};

export default ObjElementTooth;
