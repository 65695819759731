import { useApolloClient } from '@apollo/client';
import { CREATE_VIEWER_LINK_APPROVAL } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  link: 'Copy Link',
  token: 'Copy Access Token',
};

function reducer(state, action) {
  switch (action.type) {
    case 'link':
      return {
        ...initialState,
        link: 'Copied!',
      };
    case 'token':
      return {
        ...initialState,
        token: 'Copied!',
      };
    case 'reset':
      return initialState;
    default: {
      return state;
    }
  }
}

function PatientLinkModal(props) {
  const { modalOpen, handleModalClose } = props;
  const [linkInfo, setLinkInfo] = useState(null);
  const timer = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { link, token } = state;

  const { id } = useParams();

  const client = useApolloClient();

  useEffect(() => {
    const fnc = async () => {
      const { data } = await client.mutate({
        mutation: CREATE_VIEWER_LINK_APPROVAL,
        skip: !modalOpen,
        variables: {
          input: {
            input: {
              id,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      const { createViewerLink: { viewerLink = {} } = {} } = data;

      setLinkInfo(viewerLink);
    };
    if (modalOpen) {
      fnc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const copyToClipboardHandler = (type) => {
    clearTimeout(timer.current);
    navigator.clipboard.writeText(
      type === 'link' ? linkInfo.link : linkInfo.accessToken,
    );
    dispatch({ type });
    timer.current = setTimeout(() => dispatch({ type: 'reset' }), 2000);
  };

  return linkInfo ? (
    <div className={`modal ${modalOpen ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Generate Patient Link</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={handleModalClose}
          />
        </header>
        <section className="modal-card-body">
          <p style={{ fontWeight: 'bold', marginBottom: 5 }}>Viewer Link:</p>
          <p>{linkInfo.link}</p>
          <p style={{ fontWeight: 'bold', marginBottom: 5 }}>Access Token:</p>
          <p>{linkInfo.accessToken}</p>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-primary"
            onClick={() => copyToClipboardHandler('link')}
          >
            {link}
          </button>

          <button
            type="button"
            className="button is-primary"
            onClick={() => copyToClipboardHandler('token')}
          >
            {token}
          </button>
        </footer>
      </div>
    </div>
  ) : null;
}

PatientLinkModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default PatientLinkModal;
