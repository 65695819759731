import axios from 'axios';
import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { withCurrentUser, withFormWrap } from '../../../../shared';
import { statusUpdate } from '../../OrderActions/helpers';
import { create, initialValues, schema } from './helpers';
import OrderShowMessageFormCustomerInner from './OrderShowMessageFormCustomerInner';
import './OrderShowMessages.css';
function OrderShowMessageFormCustomer({
  orderId,
  client,
  orderState,
  signS3,
  setUploadPercentage,
  uploadPercentage,
  setSendDisable,
  sendDisable,
  initialValues,
  schema,
  setErrorMessages,
  clearErrorMessages,
  refetch,
}) {
  async function handleSubmit(values, { resetForm, setSubmitting }) {
    setSendDisable(true);
    let valuesToSend = {};
    const images = [];
    if (values.releaseHold) {
      statusUpdate(client, 'hold_released', values.orderId);
    }
    if (values.files) {
      const promises = values.files.map(async (singleFile) => {
        const s3Key = `orders/${values.orderData.lab_location_id}/${values.orderData.intOrderId}/messages/${singleFile.formattedFilename}`;
        const response = await signS3({
          variables: {
            input: {
              input: {
                originalFilename: singleFile.name,
                filename: s3Key,
                acl: 'private',
                filetype: singleFile.type,
              },
              clientMutationId: uuidv4(),
            },
          },
        });
        const { signedRequest, url } = response.data.signS3.s3Response;
        const options = {
          headers: {
            'Content-Type': singleFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadPercentage(percent);
            if (percent === 100) {
              setUploadPercentage(0);
            }
          },
        };
        await axios.put(signedRequest, singleFile, options);
        images.push({
          imageUrl: url,
        });
      });
      await Promise.all(promises);
      valuesToSend = {
        ...valuesToSend,
        message: values.message,
        orderId: values.orderId,
        images,
      };
    } else {
      valuesToSend = {
        ...values,
        images,
      };
      delete valuesToSend.orderData;
      delete valuesToSend.releaseHold;
    }
    const variables = {
      variables: {
        input: { input: valuesToSend, clientMutationId: uuidv4() },
      },
    };

    create({
      variables,
      client,
      setErrorMessages,
      clearErrorMessages,
      refetch,
    });
    resetForm();
    setSubmitting(false);
    setSendDisable(false);
  }
  return (
    <Formik
      initialValues={initialValues({ orderId })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit: onSubmit,
        values,
        setValues,
        errors,
        initialValues,
        submitForm,
      }) => (
        <OrderShowMessageFormCustomerInner
          orderState={orderState}
          uploadPercentage={uploadPercentage}
          sendDisable={sendDisable}
          values={values}
          onSubmit={onSubmit}
          setValues={setValues}
          errors={errors}
          initialValues={initialValues}
          submitForm={submitForm}
        />
      )}
    </Formik>
  );
}

OrderShowMessageFormCustomer.propTypes = {
  handleSubmit: PropTypes.func,
  orderState: PropTypes.string.isRequired,
  uploadPercentage: PropTypes.number.isRequired,
  sendDisable: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  signS3: PropTypes.func.isRequired,
  setUploadPercentage: PropTypes.func.isRequired,
  setSendDisable: PropTypes.func.isRequired,
  initialValues: PropTypes.func.isRequired,
  schema: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  setErrorMessages: PropTypes.func.isRequired,
  clearErrorMessages: PropTypes.func.isRequired,
};

export default flowRight(
  withCurrentUser({}),
  withFormWrap({ initialValues, schema }),
)(OrderShowMessageFormCustomer);
