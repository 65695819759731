/* eslint-disable no-restricted-syntax */
const actionButtonsPermissions = (roleLevel, permissions) => {
  const appName = import.meta.env.VITE_APP_NAME;
  for (const key of Object.keys(permissions)) {
    if (key === appName) {
      return permissions[key] === '*' || permissions[key].includes(roleLevel);
    }
  }

  return false;
};

export default actionButtonsPermissions;
