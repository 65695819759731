import { gql } from '@apollo/client';
import { ErrorMessageInfo, SignupTemplateInfo } from '../fragments';

export default gql`
  mutation createSignupTemplate($input: SignupTemplateCreateInput!) {
    createSignupTemplate(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      signupTemplate {
        ...SignupTemplateInfo
      }
    }
  }
  ${SignupTemplateInfo}
  ${ErrorMessageInfo}
`;
