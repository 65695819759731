import { gql } from '@apollo/client';

export default gql`
  query ordersCountByStates(
    $status: [String!] = [
      "pending"
      "sent_for_design"
      "design_in_progress"
      "rush_design"
      "redesign"
      "hold_released"
      "on_hold"
      "needs_approval"
      "sent_for_approval"
      "ready_for_download"
      "sent_to_manufacturer"
      "at_manufacturer"
      "shipped"
      "manufacturing_hold"
      "cancelled"
      "complete"
      "closed"
    ]
  ) {
    ordersCountByStates(status: $status) {
      atManufacturer
      cancelled
      closed
      complete
      designInProgress
      holdReleased
      manufacturingHold
      needsApproval
      onHold
      pending
      readyForDownload
      redesign
      rushDesign
      sentForApproval
      sentForDesign
      sentToManufacturer
      shipped
    }
  }
`;
