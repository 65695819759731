import PropTypes from 'prop-types';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import FeedbackRedesignModal from './FeedbackRedesignModal';

function FeedbackRedesignButton(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation('common');

  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        type="button"
        className="button ml-2 is-primary"
      >
        {t('Redesign')}
      </button>

      {modalOpen && (
        <FeedbackRedesignModal
          {...props}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}

FeedbackRedesignButton.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FeedbackRedesignButton;
