import { gql } from '@apollo/client';

const QuotaProfileInfo = gql`
  fragment QuotaProfileInfo on QuotaProfile {
    id
    name
    designType {
      name
      id
    }
    quotaProfileItems {
      caseType
      dataProcessorInitialQuota
      dataProcessorRedesignQuota
      dentalTechInitialQuota
      dentalTechRedesignQuota
      id
      orthoAssistantInitialQuota
      orthoAssistantRedesignQuota
      orthoFinisherInitialQuota
      orthoFinisherRedesignQuota
      orthoQualityControlInitialQuota
      orthoQualityControlRedesignQuota
      postProcessorInitialQuota
      postProcessorRedesignQuota
      qualityControlInitialQuota
      qualityControlRedesignQuota
    }
    value
    createdAt
    updatedAt
  }
`;

export default QuotaProfileInfo;
