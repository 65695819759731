import { BoxIcon, OverpanelUploadContext } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function CaseActionsButtonResubmit({ signAndSubmit }) {
  const { failedUpload } = useContext(OverpanelUploadContext);
  const { t } = useTranslation('orders');

  return failedUpload ? (
    <button
      type="button"
      className="button is-small mt-1 px-5 has-tooltip-arrow has-tooltip-left"
      onClick={signAndSubmit}
      data-tooltip={t('Try Resending File')}
    >
      <BoxIcon name="bx-repeat" />
    </button>
  ) : null;
}

CaseActionsButtonResubmit.propTypes = {
  signAndSubmit: PropTypes.func.isRequired,
};

export default CaseActionsButtonResubmit;
