import PropTypes from 'prop-types';
import { memo } from 'react';

function MessageModalForm(props) {
  const { label, placeholder, changeMessage, message } = props;

  return (
    <div className="field mt-4">
      <label htmlFor="redesign-reason" className="label">
        {label}
      </label>
      <div className="control">
        <textarea
          rows={3}
          id="redesign-reason"
          placeholder={placeholder}
          onChange={changeMessage}
          className="textarea"
          value={message}
        />
      </div>
    </div>
  );
}

MessageModalForm.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  changeMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default memo(MessageModalForm);
