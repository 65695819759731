import { gql } from '@apollo/client';

export default gql`
  query adminLocationsAdmin {
    adminLocationsAdmin {
      id
      attributes
    }
  }
`;
