import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function FormSectionHeader({
  title = null,
  hideHeader = null,
  tileClass = null,
}) {
  const { t } = useTranslation('formfields');

  return !hideHeader && title && title !== '' ? (
    <h5 className={`title is-5 ${tileClass || 'has-text-info'} mb-1`}>
      {t(title)}
    </h5>
  ) : null;
}

FormSectionHeader.propTypes = {
  title: PropTypes.string,
  tileClass: PropTypes.string,
  hideHeader: PropTypes.bool,
};

export default memo(FormSectionHeader);
