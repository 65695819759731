import { useQuery } from '@apollo/client';
import { GET_CREDITS_DROPDOWN } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../shared/FormFields/Dropdowns/Dropdown';

function CreditsDropdown(props) {
  const {
    field: { name, value },
    form: { errors, setFieldValue },
    customer,
    type,
    setMaxAmount,
  } = props;

  const [credits, setCredits] = useState([]);
  const { t } = useTranslation('formfields');

  let variables = {
    applied: false,
    excludeExpired: true,
  };
  if (customer && type && type === 'location') {
    variables.labLocationId = customer.id;
  }
  if (customer && type && type === 'organization') {
    variables.labOrganizationId = customer.id;
  }

  const { error, loading } = useQuery(GET_CREDITS_DROPDOWN, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setCredits(data.credits);
    },
  });

  const handleChange = (e) => {
    const foundCredit = credits.find((credit) => credit.id === e.target.value);
    setMaxAmount(foundCredit.remainingBalance);
    setFieldValue(name, e.target.value);
  };

  return (
    <Dropdown
      name={name}
      label="Credit"
      error={error}
      errors={!!errors}
      loading={loading}
      options={credits}
      onChange={handleChange}
      placeholder={t('Select a Credit...')}
      value={value}
      required
    />
  );
}

CreditsDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  setMaxAmount: PropTypes.func.isRequired,
};

export default memo(CreditsDropdown);
