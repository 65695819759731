import { gql } from '@apollo/client';
import { ErrorMessageInfo, ReportInfo } from '../fragments';

export default gql`
  mutation createReport($input: ReportCreateInput!) {
    createReport(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      report {
        ...ReportInfo
      }
    }
  }
  ${ReportInfo}
  ${ErrorMessageInfo}
`;
