import { gql } from '@apollo/client';

export default gql`
  query nodeTypes {
    nodeTypes {
      id
      name
      value
    }
  }
`;
