import { getIn } from 'formik';
import PropTypes from 'prop-types';
import Dropdown from '../../../../shared/FormFields/Dropdowns/Dropdown';
import getPreferenceValue, {
  getDesignProfile,
} from './utils/getPreferenceValue';

function PreferenceDropdown({
  designProfileIndex,
  designPreferenceIndex,
  setFieldValue,
  values: { designProfiles },
  options,
  errors,
  touched,
  placeholder = 'Select...',
  labelText = null,
  preference,
  designProfileId,
}) {
  const name = `designProfiles[${designProfileIndex}].designPreferences[${designPreferenceIndex}]`;

  const updateDesignProfiles = (updatedProfile) =>
    designProfiles.map((profile) => {
      if (profile.id === updatedProfile.id) {
        return updatedProfile;
      }
      return profile;
    });

  const handleChange = (e) => {
    const designProfile = getDesignProfile(designProfileId, designProfiles);
    let foundElement = false;
    const updatedProfile = {
      ...designProfile,
      designPreferences: designProfile.designPreferences.map((pref) => {
        if (pref.name === preference.name) {
          foundElement = true;
          return {
            ...pref,
            value: e.target.value,
          };
        }
        return pref;
      }),
    };

    if (!foundElement) {
      updatedProfile.designPreferences.push({
        name: preference.name,
        value: e.target.value,
      });
    }
    const updatedDesignProfiles = updateDesignProfiles(updatedProfile);
    setFieldValue('designProfiles', updatedDesignProfiles);
  };

  return (
    <Dropdown
      name={name}
      label={labelText || name}
      error={getIn(errors, name)}
      errors={!!(getIn(errors, name) && getIn(touched, name))}
      options={options}
      onChange={handleChange}
      placeholder={placeholder}
      value={getPreferenceValue(designProfileId, preference, designProfiles)}
      required
    />
  );
}

PreferenceDropdown.propTypes = {
  designPreferenceIndex: PropTypes.number.isRequired,
  designProfileId: PropTypes.string.isRequired,
  designProfileIndex: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  preference: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PreferenceDropdown;
