import { gql } from '@apollo/client';

const SoftwareNodeInfo = gql`
  fragment SoftwareNodeInfo on SoftwareNode {
    id
    name
    slug
    nodes
    nodeType
    designTypeId
    parsableSoftwareId
    designType {
      id
      name
    }
    parsableSoftware {
      id
      name
    }
  }
`;

export default SoftwareNodeInfo;
