import { Tabs } from '../../../../shared';
import { LocationDesignGuideSelect } from '../components';
import LocationDesignGuideInfo from './LocationDesignGuideInfo';

import './LocationDesignGuide.scss';

function LocationDesignGuide() {
  return (
    <Tabs.TabPanel tabId="designGuide">
      <LocationDesignGuideInfo />
      <LocationDesignGuideSelect />
    </Tabs.TabPanel>
  );
}

LocationDesignGuide.propTypes = {};

export default LocationDesignGuide;
