import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateNotificationTypeUser(
    $input: NotificationTypeUserUpdateInput!
  ) {
    updateNotificationTypeUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      notificationTypeUsers {
        id
        notificationTypeId
        email
      }
    }
  }
  ${ErrorMessageInfo}
`;
