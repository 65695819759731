import { CREATE_ORDER_SUBSCRIPTION } from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../config/log';

function subscribeToOrder(client, orderId) {
  client
    .mutate({
      mutation: CREATE_ORDER_SUBSCRIPTION,
      variables: {
        input: {
          orderId,
          clientMutationId: uuidv4(),
        },
      },
    })
    .then(({ data }) => {
      Log.info(data);
    })
    .catch((err) => {
      Log.error(err);
    });
}

export default subscribeToOrder;
