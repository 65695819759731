import { gql } from '@apollo/client';

export default gql`
  query guideBooksDropdown {
    guideBooks {
      id
      name
      value
      parentGuideBookId
    }
  }
`;
