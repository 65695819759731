function checkPermissions(props) {
  const {
    stateRoles,
    params,
    currentUser,
    list,
    showList,
    details,
    showDetails,
    order,
  } = props;

  const app = import.meta.env.VITE_APP_NAME;
  const { visible } = stateRoles[app];
  const paramStatus = params && params.status ? params.status : null;

  const orderStatus = order && order.state ? order.state : null;

  const derivedStatus = orderStatus || paramStatus;

  const status =
    derivedStatus &&
    stateRoles[app].statuses &&
    stateRoles[app].statuses[derivedStatus];

  return (
    ((list && showList) || (details && showDetails)) &&
    visible &&
    status &&
    stateRoles[app].statuses[derivedStatus].some(
      (s) => s === currentUser.roleLevel,
    )
  );
}

export default checkPermissions;
