import { gql } from '@apollo/client';

export default gql`
  query customerLocationsMultiSelect($customerOrganizationIds: [ID!]) {
    customerLocations(customerOrganizationIds: $customerOrganizationIds) {
      id
      name
      customerTier
      organization {
        id
        name
      }
    }
  }
`;
