import { gql } from '@apollo/client';
import { ContactInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createContact($input: ContactCreateInput!) {
    createContact(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      contact {
        ...ContactInfo
      }
    }
  }
  ${ContactInfo}
  ${ErrorMessageInfo}
`;
