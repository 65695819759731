import { gql } from '@apollo/client';
import { AdminLocationInfo } from '../fragments';

export default gql`
  query adminLocation($slug: String!) {
    adminLocation(slug: $slug) {
      ...AdminLocationInfo
    }
  }
  ${AdminLocationInfo}
`;
