import { gql } from '@apollo/client';
import { CreditInfo } from '../fragments';

export default gql`
  mutation destroyCredit($input: CreditDestroyInput!) {
    destroyCredit(input: $input) {
      clientMutationId
      credit {
        ...CreditInfo
      }
    }
  }
  ${CreditInfo}
`;
