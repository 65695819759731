import { gql } from '@apollo/client';
import { ApiSettingInfo } from '../fragments';

export default gql`
  query manufacturerOrganization($slug: String) {
    manufacturerOrganization(slug: $slug) {
      id
      apiSetting {
        ...ApiSettingInfo
      }
    }
  }
  ${ApiSettingInfo}
`;
