import { gql } from '@apollo/client';

const ReportTimeFrameInfo = gql`
  fragment ReportTimeFrameInfo on ReportTimeFrame {
    id
    name
    # key
    value
    # text
  }
`;

export default ReportTimeFrameInfo;
