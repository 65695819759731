import { gql } from '@apollo/client';
import { AdminUserInfo } from '../fragments';

export default gql`
  query adminUsers($locationId: String, $organizationId: String) {
    adminUsers(locationId: $locationId, organizationId: $organizationId) {
      ...AdminUserInfo
    }
  }
  ${AdminUserInfo}
`;
