import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeBite(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Bite Correction" width={6}>
      <div
        style={{ width: '50%', display: 'inline-block', verticalAlign: 'top' }}
      >
        <div className="checkWrap">
          <NonFormCheckboxField
            name="correctDeepBite"
            labelText="Correct Deep Bite"
            readOnly
            checked={prescription.BiteCorrection.DeepBite}
          />
        </div>
        <div className="checkWrap">
          <NonFormCheckboxField
            name="correctOpenBite"
            labelText="Correct Open Bite"
            readOnly
            checked={prescription.BiteCorrection.OpenBite}
          />
        </div>
        <div className="checkWrap">
          <NonFormCheckboxField
            name="correctOverjet"
            labelText="Correct Overjet"
            readOnly
            checked={prescription.BiteCorrection.Overjet}
          />
        </div>
      </div>
      <div
        style={{
          width: 'calc(50% - 1em)',
          display: 'inline-block',
          verticalAlign: 'top',
          marginLeft: '1em',
        }}
      >
        <div className="checkWrap">
          <NonFormCheckboxField
            name="correctUnderBite"
            labelText="Correct Underbite"
            readOnly
            checked={prescription.BiteCorrection.Underbite}
          />
        </div>
        <div className="checkWrap">
          <NonFormCheckboxField
            name="correctCrossBite"
            labelText="Correct Crossbite"
            readOnly
            checked={prescription.BiteCorrection.Crossbite}
          />
        </div>
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeBite.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeBite;
