import PropTypes from 'prop-types';

function caseItemReasonRenderer(params) {
  const { value } = params;

  return value
    .map((a) => {
      const reasons = a.reasons
        .map((r) => `${r.category} - ${r.option}`)
        .join();

      return `${a.designType} - ${reasons}`;
    })
    .join('\n');
}

caseItemReasonRenderer.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        designType: PropTypes.string.isRequired,
        reasons: PropTypes.arrayOf(
          PropTypes.shape({
            category: PropTypes.string.isRequired,
            option: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default caseItemReasonRenderer;
