import { gql } from '@apollo/client';
import { AdminLocationInfo } from '../fragments';

export default gql`
  query adminLocations($name: String) {
    adminLocations(name: $name) {
      ...AdminLocationInfo
    }
  }
  ${AdminLocationInfo}
`;
