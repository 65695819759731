const reassignSilo = {
  admin_portal: {
    visible: true,
    statuses: {
      pending: [0, 10, 20, 30, 40],
      sent_for_design: [0, 10, 20, 30, 40],
      design_in_progress: [0, 10, 20, 30, 40],
      rush_design: [0, 10, 20, 30, 40],
      redesign: [0, 10, 20, 30, 40],
      hold_released: [0, 10, 20, 30, 40],
      on_hold: [0, 10, 20, 30, 40],
      needs_approval: [0, 10, 20, 30, 40],
      sent_for_approval: [0, 10, 20, 30, 40],
      ready_for_download: [0, 10, 20, 30, 40],
      sent_to_manufacturer: [0, 10, 20, 30, 40],
      at_manufacturer: [0, 10, 20, 30, 40],
      shipped: [0, 10, 20, 30, 40],
      manufacturing_hold: [0, 10, 20, 30, 40],
      cancelled: [0, 10, 20, 30, 40],
      complete: [0, 10, 20, 30, 40],
      closed: [0, 10, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: true,
    statuses: {
      pending: [0, 5, 10],
      sent_for_design: [0, 5, 10],
      design_in_progress: [0, 5, 10],
      rush_design: [0, 5, 10],
      redesign: [0, 5, 10],
      hold_released: [0, 5, 10],
      on_hold: [0, 5, 10],
      needs_approval: [0, 5, 10],
      sent_for_approval: [0, 5, 10],
      ready_for_download: [0, 5, 10],
      sent_to_manufacturer: [0, 5, 10],
      at_manufacturer: [0, 5, 10],
      shipped: [0, 5, 10],
      manufacturing_hold: [0, 5, 10],
      cancelled: [0, 5, 10],
      complete: [0, 5, 10],
      closed: [0, 5, 10],
    },
  },
};

export default reassignSilo;
