import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Suspense, useCallback, useEffect, useState } from 'react';
import OldThreeLoader from '../../shared/Loader';
import ThreeObj from './ThreeObj/index';

function ObjViewer({
  files,
  className = '',
  maxHeight = 'calc(80vh)',
  reverse = false,
  theme = 'dark',
  logoUrl = null,
  orderRedesignCount = null,
}) {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sliderVal, setSliderVal] = useState(0);
  const [playing, setPlaying] = useState(false);

  const sortAndOrderFiles = useCallback(() => {
    const sortedMtls = files.filter(
      (file) => file.originalFileName.endsWith('.mtl') && file.sortOrder < 600,
    );

    const upperObjs = files.filter(
      (file) =>
        file.category === 'upper' &&
        file.originalFileName.endsWith('.obj') &&
        file.sortOrder < 600,
    );

    const lowerObjs = files.filter(
      (file) =>
        file.category === 'lower' &&
        file.originalFileName.endsWith('.obj') &&
        file.sortOrder < 600,
    );

    let assignedStages = [];

    upperObjs.forEach((obj) => {
      const foundIndex = assignedStages.findIndex(
        (stage) => stage.sortOrder === obj.sortOrder,
      );

      if (foundIndex === -1) {
        assignedStages = [
          ...assignedStages,
          { sortOrder: obj.sortOrder, upper: { obj } },
        ];
      }

      if (foundIndex !== -1) {
        assignedStages[foundIndex] = {
          ...assignedStages[foundIndex],
          upper: { obj },
        };
      }
    });

    lowerObjs.forEach((obj) => {
      const foundIndex = assignedStages.findIndex(
        (stage) => stage.sortOrder === obj.sortOrder,
      );

      if (foundIndex === -1) {
        assignedStages = [
          ...assignedStages,
          { sortOrder: obj.sortOrder, lower: { obj } },
        ];
      }

      if (foundIndex !== -1) {
        assignedStages[foundIndex] = {
          ...assignedStages[foundIndex],
          lower: { obj },
        };
      }
    });

    sortedMtls.forEach((mtl) => {
      const foundIndex = assignedStages.findIndex(
        (stage) => stage.sortOrder === mtl.sortOrder,
      );

      if (
        foundIndex !== -1 &&
        mtl.category === 'upper' &&
        assignedStages[foundIndex].upper
      ) {
        const {
          upper: { obj },
          ...rest
        } = assignedStages[foundIndex];

        const replacementStage = { ...rest, upper: { obj, mtl } };

        assignedStages[foundIndex] = replacementStage;
      }

      if (
        foundIndex !== -1 &&
        mtl.category === 'lower' &&
        assignedStages[foundIndex].lower
      ) {
        const {
          lower: { obj },
          ...rest
        } = assignedStages[foundIndex];

        const replacementStage = { ...rest, lower: { obj, mtl } };

        assignedStages[foundIndex] = replacementStage;
      }
    });

    const newAssignedStages = assignedStages
      .sort((a, b) => {
        if (reverse) {
          return parseInt(b.sortOrder, 10) - parseInt(a.sortOrder, 10);
        }

        return parseInt(a.sortOrder, 10) - parseInt(b.sortOrder, 10);
      })
      .map((assigned, index) => {
        const { upper = null, lower = null } = assigned;
        let newAssigned = {
          ...assigned,
          index,
        };

        if (upper && upper.obj) {
          const { upper: oldUpper, ...restAssigned } = newAssigned;
          const { obj, ...rest } = upper;
          const newObj = { index, ...obj };
          const newUpper = { ...rest, obj: newObj };
          newAssigned = { upper: newUpper, ...restAssigned };
        }

        if (lower && lower.obj) {
          const { lower: oldLower, ...restAssigned } = newAssigned;
          const { obj, ...rest } = lower;
          const newObj = { index, ...obj };
          const newLower = { ...rest, obj: newObj };
          newAssigned = { lower: newLower, ...restAssigned };
        }

        return newAssigned;
      });

    setStages(newAssignedStages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, reverse, orderRedesignCount]);

  useEffect(() => {
    sortAndOrderFiles();
    return () => {
      setStages([]);
    };
  }, [orderRedesignCount, sortAndOrderFiles]);

  return (
    <div
      className={`${theme} ${className} objWrapper`}
      style={{
        width: '100%',
        minHeight: 100,
        height: maxHeight,
        overflow: 'hidden',
      }}
    >
      {stages.length ? (
        <Suspense fallback={<OldThreeLoader loading />}>
          <ThreeObj
            stages={stages}
            loading={loading}
            setLoading={setLoading}
            sliderVal={sliderVal}
            setSliderVal={setSliderVal}
            setPlaying={setPlaying}
            playing={playing}
            theme={theme}
            logoUrl={logoUrl}
          />
        </Suspense>
      ) : null}
      <OldThreeLoader
        title="Viewer..."
        subTitle="This may take a few moments, please be patient."
        loading={loading}
      />
    </div>
  );
}

ObjViewer.propTypes = exact({
  className: PropTypes.string,
  files: PropTypes.array.isRequired,
  maxHeight: PropTypes.string,
  orderRedesignCount: PropTypes.number,
  reverse: PropTypes.bool,
  theme: PropTypes.string,
  logoUrl: PropTypes.string,
});

export default ObjViewer;
