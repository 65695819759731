import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_USERS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NonFormDropdown } from '../FormFields';

function FeedbackCreatedByDropdown({
  locationId,
  setFeedbackUser,
  editUserId = null,
  feeedbackUser = null,
}) {
  const [userOptions, setUserOptions] = useState([]);

  const { loading, error } = useQuery(GET_CUSTOMER_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { locationId },
    onCompleted({ customerUsers }) {
      const options = customerUsers.map(({ id, firstName, lastName }) => ({
        value: id,
        name: `${firstName} ${lastName}`,
      }));
      if (
        !customerUsers.some((item) => item.value === feeedbackUser) &&
        feeedbackUser
      ) {
        options.push({
          value: feeedbackUser,
          name: 'Admin',
        });
      }
      setUserOptions(options);
    },
  });

  const feedbackUserHandler = (e) => {
    setFeedbackUser(e.target.value);
  };
  return editUserId ? (
    <div className="my-3">
      <p>
        <b>Feedback created by:</b>
      </p>
      <NonFormDropdown
        loading={loading}
        disabled={!!editUserId}
        clearable
        error={error}
        name="customerId"
        placeholder="Select user..."
        options={userOptions}
        onChange={feedbackUserHandler}
        value={feeedbackUser}
      />
    </div>
  ) : null;
}

FeedbackCreatedByDropdown.propTypes = {
  locationId: PropTypes.string.isRequired,
  feeedbackUser: PropTypes.string,
  editUserId: PropTypes.string,
  setFeedbackUser: PropTypes.func.isRequired,
};

export default FeedbackCreatedByDropdown;
