import { useMutation } from '@apollo/client';
import {
  CREATE_GUIDE_CUSTOM_LOCATION,
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
} from '@fullcontour/shared-api';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignGuideOrderContext } from '../../..';
import applySelectedCodes from './utils/applySelectedCodes';

function GuideApplyBtn(props) {
  const { guideModalClose, designType, applyToAll } = props;

  const { values, setFieldValue } = useFormikContext();
  const { orderItemIndex } = useContext(DesignGuideOrderContext);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('designProfile');

  const [createCode] = useMutation(CREATE_GUIDE_CUSTOM_LOCATION, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS }],
  });

  const applyHandler = () => {
    applySelectedCodes({
      setLoading,
      values,
      orderItemIndex,
      createCode,
      setFieldValue,
      guideModalClose,
      applyToAll,
      designType,
    });
  };

  return (
    <button
      type="button"
      onClick={applyHandler}
      className={`button mr-5 is-primary ${loading && 'is-loading'}`}
      data-cy="apply-design-guide-btn"
    >
      {t('Apply')}
    </button>
  );
}

GuideApplyBtn.propTypes = {
  designType: PropTypes.object.isRequired,
  guideModalClose: PropTypes.func.isRequired,
  applyToAll: PropTypes.bool.isRequired,
};

export default GuideApplyBtn;
