import PropTypes from 'prop-types';

import ElementImage from '../FilesShowElements/ElementImage/ElementImage';

function FolderViewImages({
  selectedFolder = null,
  toggleVersions,
  selectedFileError = null,
}) {
  return (
    <div style={{ paddingTop: '1em' }}>
      {selectedFolder.selectedItems
        .sort((a, b) => b.ver - a.ver)
        .map((item) => (
          <div
            className="column is-flex is-flex-direction-column"
            key={item.id}
            style={{ padding: '0.5rem' }}
          >
            <ElementImage image={item} selectedFileError={selectedFileError} />
            <p style={{ lineHeight: '1.0em', wordBreak: 'break-all' }}>
              <small style={{ fontSize: '65%' }}>
                {item.originalFileName}
                {toggleVersions && (
                  <>
                    <br />
                    Version: {item.ver}
                  </>
                )}
              </small>
            </p>
          </div>
        ))}
    </div>
  );
}

FolderViewImages.propTypes = {
  selectedFolder: PropTypes.object,
  toggleVersions: PropTypes.bool.isRequired,
  selectedFileError: PropTypes.string,
};

export default FolderViewImages;
