const navNamings = {
  at_manufacturer: 'atManufacturer',
  cancelled: 'cancelled',
  closed: 'closed',
  complete: 'complete',
  design_in_progress: 'designInProgress',
  hold_released: 'holdReleased',
  manufacturing_hold: 'manufacturingHold',
  needs_approval: 'needsApproval',
  on_hold: 'onHold',
  pending: 'pending',
  ready_for_download: 'readyForDownload',
  redesign: 'redesign',
  rush_design: 'rushDesign',
  sent_for_approval: 'sentForApproval',
  sent_for_design: 'sentForDesign',
  sent_to_manufacturer: 'sentToManufacturer',
  shipped: 'shipped',
};

export default navNamings;
