import { useMemo } from 'react';
import bodyTableDataGenerate from './bodyTableDataGenerate';
import headerTableDataGenerate from './headerTableDataGenerate';

const useTeethChartDataTransform = (
  stages,
  selectedStages,
  isTotal,
  total = {},
) => {
  const allTeeth = Array(32).fill(null);
  const allTeethData = useMemo(() => [...allTeeth], [allTeeth]);

  const currentStageTeeth = isTotal
    ? total.Teeth
    : stages[selectedStages - 1].Teeth;

  currentStageTeeth?.forEach((item) => {
    if (item) {
      allTeethData[item.Unn - 1] = item;
    }
  });

  const headerTableData = useMemo(() => {
    if (currentStageTeeth) {
      return headerTableDataGenerate(currentStageTeeth, isTotal);
    }

    return null;
  }, [currentStageTeeth, isTotal]);

  const bodyTeethData = useMemo(
    () => bodyTableDataGenerate(allTeethData),
    [allTeethData],
  );

  return { headerTableData, bodyTeethData };
};

export default useTeethChartDataTransform;
