import { gql } from '@apollo/client';
import { PriceListInfo } from '../fragments';

export default gql`
  query priceList($slug: String, $id: ID) {
    priceList(slug: $slug, id: $id) {
      ...PriceListInfo
    }
  }
  ${PriceListInfo}
`;
