import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
    turnaroundTimeId: Yup.string().required('Turnaround time is required'),
    instructions: Yup.string().nullable(true),
    requiresDesignApproval: Yup.boolean().required(),
    orderItems: Yup.array()
      .of(
        Yup.object({
          id: Yup.string().required('Case Item ID is Required'),
          designTypeId: Yup.string().required('Design Type is Required'),
          manufacturerOrganizationId: Yup.string().required(
            'Destination is Required',
          ),
          units: Yup.number().required(),
          teeth: Yup.array().of(Yup.number()).nullable(true),
          arches: Yup.array().of(Yup.string()).nullable(true),
          materialName: Yup.string().nullable(),
          splitFile: Yup.boolean().isRequired,
          _destroy: Yup.boolean(),
        }),
      )
      .required(),
  });
}

export default schema;
