/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import Tabs from '../../shared/Components/NewTabs';
import './Billing.css';
import { BillingCharges } from './BillingCharges';
import { BillingCredits } from './BillingCredits';
import { BillingDetails } from './BillingDetails';
import { BillingInvoices } from './BillingInvoices';

function Billing(props) {
  const { t } = useTranslation('billing');

  return (
    <div className="is-flex is-flex-direction-column is-justify-content-space-between">
      <BillingDetails {...props} />
      <Tabs>
        <div id={t('Invoices')} style={{ height: '100%' }}>
          <BillingInvoices id={t('Invoices')} {...props} />
        </div>
        <div id={t('Credits')} style={{ height: '100%' }}>
          <BillingCredits id={t('Credits')} {...props} />
        </div>
        <div id={t('Charges')} style={{ height: '100%' }}>
          <BillingCharges id={t('Charges')} {...props} />
        </div>
      </Tabs>
    </div>
  );
}

export default Billing;
