import { gql } from '@apollo/client';
import { ApiSettingInfo } from '../fragments';

export default gql`
  query customerLocation($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      id
      apiSetting {
        ...ApiSettingInfo
      }
    }
  }
  ${ApiSettingInfo}
`;
