import { gql } from '@apollo/client';
import { FeedbackCategoryInfo } from '../fragments';

export default gql`
  query feedbackCategories {
    feedbackCategories {
      ...FeedbackCategoryInfo
    }
  }
  ${FeedbackCategoryInfo}
`;
