import PropTypes from 'prop-types';
import ReviewModalTabs from './ReviewModalTabs';

function ReviewModalBodyContent({
  orderFiles = [],
  order = null,
  redesign,
  docApproval,
  patientApproval,
  ...props
}) {
  return !redesign && !docApproval && !patientApproval && order ? (
    <ReviewModalTabs orderFiles={orderFiles} order={order} {...props} />
  ) : null;
}

ReviewModalBodyContent.propTypes = {
  order: PropTypes.object,
  orderFiles: PropTypes.array,
  redesign: PropTypes.bool.isRequired,
  docApproval: PropTypes.bool.isRequired,
  patientApproval: PropTypes.bool.isRequired,
};

export default ReviewModalBodyContent;
