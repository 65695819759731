import { gql } from '@apollo/client';
import { ReportInfo } from '../fragments';

export default gql`
  mutation destroyReport($input: ReportDestroyInput!) {
    destroyReport(input: $input) {
      clientMutationId
      report {
        ...ReportInfo
      }
    }
  }
  ${ReportInfo}
`;
