import { useQuery } from '@apollo/client';
import {
  CurrentUserContext,
  emailRenderer,
  GridContainer,
  LoadingOrError,
  nameValueGetter,
  tagRenderer,
} from '@fullcontour/common';
import { GET_CUSTOMER_USERS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

function UsersListDataTable({
  shortContainer = false,
  locationId = null,
  organizationId = null,
}) {
  const { root } = useParams();

  const { t } = useTranslation('aggrid');

  const { currentUser } = useContext(CurrentUserContext);

  let variables = {};

  if (locationId) {
    variables = { ...variables, locationId };
  }

  if (organizationId) {
    variables = { ...variables, organizationId };
  }

  const { error, loading, data } = useQuery(GET_CUSTOMER_USERS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const hideOnLocation = root === 'locations';

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Users" />;

  const gridOptions = {
    rowData: data.customerUsers,
    defaultColDef: {
      floatingFilter: true,
    },
    context: {
      userType: 'customerUsers',
    },
    columnDefs: [
      {
        field: 'name',
        headerName: t('Name'),
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        valueGetter: nameValueGetter,
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.navigate(`/users/${params.data.id}`);
        },
      },
      {
        field: 'email',
        headerName: t('Email'),
        minWidth: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: emailRenderer,
      },
      {
        field: 'location.name',
        headerName: t('Location'),
        minWidth: 175,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        hide: hideOnLocation,
        onCellClicked: (params) => {
          params.context.navigate(
            [0, 10, 20, 30].includes(currentUser.roleLevel)
              ? `/locations/${params.data.location.slug}`
              : '/locations',
          );
        },
      },
      {
        field: 'role.name',
        headerName: t('Role'),
        minWidth: 150,
        filter: 'agTextColumnFilter',
        suppressHeaderMenuButton: true,
      },
      {
        field: 'state',
        headerName: 'Status',
        minWidth: 150,
        filter: 'agSetColumnFilter',
        suppressHeaderMenuButton: true,
        cellRenderer: tagRenderer,
      },
    ],
  };

  return (
    <GridContainer
      rootVal="users"
      gridOptions={gridOptions}
      to="/users/new"
      content="New User"
      shortContainer={shortContainer}
    />
  );
}

UsersListDataTable.propTypes = {
  shortContainer: PropTypes.bool,
  locationId: PropTypes.string,
  organizationId: PropTypes.string,
};

export default UsersListDataTable;
