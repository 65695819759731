import { gql } from '@apollo/client';

export default gql`
  query printer($id: ID, $slug: String) {
    printer(id: $id, slug: $slug) {
      id
      name
      description
      imageUrl
      slug
      printerBrandId
      printerMaterials {
        id
        name
        description
        imageUrl
      }
    }
  }
`;
