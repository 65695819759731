import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsDesignCategoryCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,

    columnDefs: [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Category',
        field: 'category',
        width: 200,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designType',
        headerName: 'Design Type',
        minWidth: 120,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'totalUnits',
        headerName: 'Total Units',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'totalPrice',
        headerName: 'Total Price',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',

        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsDesignCategoryCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsDesignCategoryCustomer;
