function initialValues(props) {
  const { userId } = props;

  return {
    id: userId || null,
    password: '',
    passwordConfirm: '',
  };
}

export default initialValues;
