import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import Log from '../log';

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_SERVER,
  credentials: 'same-origin',
});

const authLink = setContext(() => ({
  headers: {
    'x-admin-portal-version': import.meta.env.VITE_VERSION,
    'x-app-name': import.meta.env.VITE_APP_NAME,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { location } = window;
  Log.info(location, 'onError - history -  apolloClient');
  Log.info(graphQLErrors, 'onError - graphqlerrors -  apolloClient');
  Log.info(networkError, 'onError - networkerror -  apolloClient');
  if (graphQLErrors)
    graphQLErrors.map(({ message = '', status = 200 }) => {
      if (import.meta.env.VITE_UNAUTHORIZED === message || status === 401) {
        Log.warn(
          `You've attempted to access ${
            import.meta.env.VITE_UNAUTHORIZED
          } section`,
        );
        if (location.pathname !== '/signin') {
          location.href = `${location.origin}/signin`;
        }
      }
      if (import.meta.env.VITE_FORBIDDEN === message || status === 403) {
        Log.warn(`You've attempted a ${import.meta.env.VITE_FORBIDDEN} action`);
        location.href = `${location.origin}/error-page/403`;
      }
      return null;
    });
  if (networkError && networkError.statusCode === 401) {
    Log.warn(import.meta.env.VITE_UNAUTHORIZED);
    location.href = `${location.origin}/signin`;
  }
  if (networkError && networkError.statusCode === 403) {
    Log.warn(import.meta.env.VITE_FORBIDDEN);
  }
  /* if (networkError && networkError.statusCode >= 500) {
    Log.warn('SERVER ERROR');
   navigate(`/error-page/${networkError.statusCode}`);
  } */
});

const cache = new InMemoryCache();

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache,
});

export default apolloClient;
