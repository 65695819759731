import PropTypes from 'prop-types';
import { BoxIcon } from '../../../../../shared';

function CustomInstructions({
  preferences,
  orderItems,
  keepExistingCustomInstructions,
  customInstructions = '',
  jobCode = null,
  designTypeInstructions = [],
}) {
  return (
    <div>
      {jobCode && (
        <div className="prefSection">
          <h5 className="title is-5">Model Job ID</h5>
          <p className="has-text-danger has-text-weight-bold">{jobCode}</p>
        </div>
      )}
      {customInstructions || designTypeInstructions?.length ? (
        <div className="prefSection">
          {keepExistingCustomInstructions && (
            <>
              <h5 className="title is-5">Instructions</h5>
              <p>{customInstructions}</p>
            </>
          )}
          {designTypeInstructions?.length ? (
            designTypeInstructions
              .filter((item) =>
                orderItems.some(
                  (orderItem) => orderItem.designTypeId === item.designType.id,
                ),
              )
              .map((item) => (
                <div className="mb-5" key={item.id}>
                  <h6 className="title is-6 mb-1">{item.designType.name}</h6>
                  <p>{item.instruction}</p>
                </div>
              ))
          ) : (
            <p>None</p>
          )}
        </div>
      ) : (
        'No instructions'
      )}
      {Array.isArray(preferences) &&
        preferences.map((pref) => (
          <div key={pref.id} className="prefSection">
            <h5 className="title is-5">{pref.name}</h5>
            <table className="table is-bordered is-striped is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th>Preference</th>
                  <th>Value</th>
                  <th>Is Default?</th>
                </tr>
              </thead>

              <tbody>
                {[...pref.designPreferences]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((d) => (
                    <tr key={d.id}>
                      <td>{d.name}</td>
                      <td>{d.value}</td>
                      <td>
                        {d.default && (
                          <BoxIcon name="bx-check bx-md has-text-success" />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
}

CustomInstructions.propTypes = {
  jobCode: PropTypes.string,
  customInstructions: PropTypes.string,
  preferences: PropTypes.array.isRequired,
  designTypeInstructions: PropTypes.array,
  orderItems: PropTypes.array.isRequired,
  keepExistingCustomInstructions: PropTypes.bool.isRequired,
};

export default CustomInstructions;
