import { gql } from '@apollo/client';
import { ErrorMessageInfo, ModifierGroupInfo } from '../fragments';

export default gql`
  mutation createModifierGroup($input: ModifierGroupCreateInput!) {
    createModifierGroup(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      modifierGroup {
        ...ModifierGroupInfo
      }
    }
  }
  ${ErrorMessageInfo}
  ${ModifierGroupInfo}
`;
