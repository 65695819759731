import { gql } from '@apollo/client';
import { SignupTemplateInfo } from '../fragments';

export default gql`
  query signupTemplate($slug: String!) {
    signupTemplate(slug: $slug) {
      ...SignupTemplateInfo
    }
  }
  ${SignupTemplateInfo}
`;
