import { useQuery } from '@apollo/client';
import {
  addressValueGetter,
  AgGridContainer,
  imageRenderer,
  LoadingOrError,
  locationNameRenderer,
} from '@fullcontour/common';
import { GET_CUSTOMER_LOCS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';

function LocationsListDataTable({
  shortContainer = false,
  organizationId = null,
}) {
  let variables = {};

  if (organizationId) {
    variables = { organizationId };
  }

  const { error, loading, data } = useQuery(GET_CUSTOMER_LOCS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Locations" />;

  const gridOptions = {
    rowData: data.customerLocations,
    defaultColDef: {
      floatingFilter: true,
    },
    context: {
      locationType: 'customer',
    },
    columnDefs: [
      {
        field: 'logoUrl',
        headerName: '',
        cellStyle: { padding: '2px 4px', textAlign: 'center' },
        minWidth: 54,
        maxWidth: 54,
        suppressHeaderMenuButton: true,
        sortable: false,
        cellRenderer: imageRenderer,
      },
      {
        field: 'name',
        minWidth: 150,
        sort: 'asc',
        autoHeight: true,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.navigate(`/locations/${params.data.slug}`);
        },
        cellRenderer: locationNameRenderer,
      },
      {
        field: 'address',
        headerName: 'Address',
        minWidth: 175,
        filter: 'agTextColumnFilter',
        valueGetter: addressValueGetter,
        suppressHeaderMenuButton: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        type: 'dateColumn',
        suppressHeaderMenuButton: true,
      },
    ],
  };

  return (
    <div>
      <div className="columns">
        <div className="column">
          <AgGridContainer
            gridOptions={gridOptions}
            shortContainer={shortContainer}
          />
        </div>
      </div>
    </div>
  );
}

LocationsListDataTable.propTypes = {
  shortContainer: PropTypes.bool,
  organizationId: PropTypes.string,
};

export default LocationsListDataTable;
