import { gql } from '@apollo/client';

export default gql`
  query customerOrganizationsAdmin {
    customerOrganizationsAdmin {
      id
      attributes
    }
  }
`;
