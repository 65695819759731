export const guidedStepsOverpanel = (t) => [
  {
    content: (
      <p>Type your order name / number here. Try not to use patient name</p>
    ),
    offset: 0,
    target: '#name',
    disableBeacon: true,
    placement: 'bottom-start',
  },
  {
    content: <p>{t('Make sure that the Design Type selected is correct')}</p>,
    offset: 0,
    target: '#designTypeId0',
    placement: 'right-start',
  },
  {
    content: (
      <p>
        {t(`Depending on the order type, you will see either an interactive arch
        chart or tooth chart when you click on these icons.`)}
      </p>
    ),
    offset: 7,
    placement: 'left-start',
    target: '#teetharch',
  },
  {
    content: (
      <p>
        {t(`Click on this icon to choose your design preferences and ensure that
        3Shape Design Service designs the order according to your needs.`)}
      </p>
    ),
    offset: 0,
    placement: 'right-start',
    target: '#guide-btn',
  },
  {
    content: (
      <p>
        {t(`Modifiers are avaliable for certain design types, sometimes for an
        additional fee. Modifiers are services offered by 3Shape Design Service that go
        above and beyond designing the order, such as providing a live
        consultation.`)}
      </p>
    ),
    offset: 0,
    placement: 'left-start',
    target: '#modifier-btn',
  },
  {
    content: (
      <p>
        {t(`Check if the destination selected is correct. In case you need to add a
        3rd party manufacturer integration, please contact 3Shape Design Service customer
        support.`)}
      </p>
    ),
    offset: 0,
    placement: 'left-start',
    target: '#manufacturerOrganizationId',
  },
  {
    content: (
      <p>
        {t(`After selecting your preferences from the design guide, you are able to
        type in additional instructions. Please note that 3Shape Design Service’s
        designers are skilled dental technicians, but they are not always fluent
        in the same language as 3Shape Design Service customers. If you choose to include
        additional instructions, please make them clear and precise.`)}
      </p>
    ),
    offset: 0,
    target: '#instructions',
    placement: 'right-start',
  },
  {
    content: (
      <p>
        {t(`For some orders you can select between different turnaround times. Keep
        in mind that the faster the turnaround time, the more your order will
        cost. You can see the prices for each indication and turnaround time on
        your price list, which is found in the Location section of the platform.`)}
      </p>
    ),
    offset: 0,
    target: '#turnaroundTimeId',
    placement: 'right-start',
  },
  {
    content: (
      <p>
        {t(`Click on the checkbox if you want to require design approval for the
        order. If you choose to require design approval, your order will be sent
        to the “Needs Approval” folder where you can review the finished design.
        Once you have reviewed the design, you can decide whether to request a
        free re-design or approve the order.`)}
      </p>
    ),
    offset: 10,
    target: '#label-requiresDesignApproval0',
    placement: 'right-start',
  },
  {
    content: <p>Click here to send all orders listed in this page</p>,
    offset: 0,
    target: '#send-new-orders',
    placement: 'auto',
  },
];

export const guidedStepsNav = (t) => [
  {
    content: (
      <p>
        {t(`You can keep track of your orders status with the left bar.
          We will send you an email every time your orders will be ready for
          approval, ready for download of in case our designers will need more
          input to work on a case`)}
      </p>
    ),
    offset: 0,
    target: '#sent_for_design',
    placement: 'right-start',
    styles: {
      options: {
        zIndex: 50,
      },
    },
    locale: {
      last: 'Got it',
    },
  },
];

export const guideStepsOrderActivity = (t) => [
  {
    content: (
      <p>
        {t(
          `We've simplified your view by merging messages and activities. Click here to see your order messages`,
        )}
      </p>
    ),
    offset: 0,
    target: '#activityTab',
    placement: 'right-start',
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 50,
      },
    },
    locale: {
      last: 'Dismiss',
    },
  },
];
