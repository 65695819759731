import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function OrderShowActivity({ order: { designUserOrders = [] } }) {
  const updatedData = useMemo(
    () =>
      designUserOrders.reduce((obj, item) => {
        const dataToPush = {
          createdAt: item.createdAt,
          name: `${item.designUser.firstName} ${item.designUser.lastName}`,
          role: item.serviceRole
            .split('_')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' '),
        };
        if (obj[item.redesignCount]) {
          obj[item.redesignCount].push(dataToPush);
          return obj;
        }

        // eslint-disable-next-line no-param-reassign
        obj[item.redesignCount] = [dataToPush];
        return obj;
      }, {}),
    [designUserOrders],
  );
  return (
    <table
      className="table is-bordered is-striped is-fullwidth is-hoverable"
      style={{ marginTop: 30 }}
    >
      <thead>
        <tr>
          <th>Redesign</th>
          <th>Date</th>
          <th>Role</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(updatedData).map((key) =>
          updatedData[key].map((item, idx) => (
            <tr key={key}>
              {idx === 0 && (
                <>
                  <td rowSpan={updatedData[key].length}>{key}</td>
                  <td rowSpan={updatedData[key].length}>
                    {moment(item.createdAt).format('DD-MM-YYYY')}
                  </td>
                </>
              )}

              <td>{item.role}</td>
              <td>{item.name}</td>
            </tr>
          )),
        )}
      </tbody>
    </table>
  );
}

OrderShowActivity.propTypes = {
  designUserOrders: PropTypes.array,
  order: PropTypes.object.isRequired,
};

export default OrderShowActivity;
