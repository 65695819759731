import { useQuery } from '@apollo/client';
import {
  CREATE_PRICE_LIST,
  GET_PRICE_LISTS_DROPDOWN_WITH_PARENT,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloneModal from '../CloneModal/CloneModal';
import Dropdown from '../Dropdown';

function PriceListDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
}) {
  const { t } = useTranslation('formfields');

  const [showAll, setShowAll] = useState(false);
  const [filteredLists, setFilteredLists] = useState([]);
  const initialLoad = useRef(true);

  const { error, loading, data, refetch, networkStatus } = useQuery(
    GET_PRICE_LISTS_DROPDOWN_WITH_PARENT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (d) => {
        filterLists(d.priceLists, showAll);
      },
    },
  );

  const filterLists = useCallback(
    (priceLists, show) => {
      const sortedPriceList = [...data.priceLists].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      let lists;
      if (show) {
        lists = sortedPriceList.map((list) => {
          let newName;
          if (list.parentListId) {
            newName = list.name;
          }
          if (!list.parentListId) {
            newName = `${list.name} - Master List`;
          }
          return { id: list.id, name: newName, value: list.value };
        });
      }
      if (!show) {
        lists = sortedPriceList
          .filter((list) => !list.parentListId || list.id === value)
          .map((list) => ({
            id: list.id,
            name: list.name,
            value: list.value,
          }));

        const foundList = sortedPriceList.find(
          (list) => list.id === value && list.parentListId !== null,
        );

        if (foundList) {
          lists = [...lists, { name: `${name} - Child List` }];
        }
      }
      setFilteredLists(lists);
    },
    [data?.priceLists, name, value],
  );

  useEffect(() => {
    if (value && data && initialLoad.current) {
      filterLists(data.priceLists, showAll);
      initialLoad.current = false;
    }
  }, [value, data, filterLists, showAll]);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  const handleShowAll = (e) => {
    e.preventDefault();

    setShowAll((prev) => !prev);

    filterLists(data?.priceLists, !showAll);
  };

  return (
    <div className="field has-addons is-grouped">
      <div className="control is-expanded">
        <Dropdown
          name={name}
          label="Price List"
          error={error}
          errors={!!(errors[name] && touched[name])}
          loading={loading || networkStatus === 4}
          options={filteredLists}
          onChange={handleChange}
          placeholder={t(
            'Select a Price List by starting typing the name of the Price List...',
          )}
          value={value}
          required
          disabled={loading || networkStatus === 4}
          dropdownStyle={{ maxHeight: '1em' }}
        />
      </div>
      <div className="control is-align-self-flex-end">
        <button
          type="button"
          className={`button ${showAll && 'is-success'}`}
          onClick={handleShowAll}
        >
          {showAll ? 'Masters' : 'Show All'}
        </button>
      </div>
      <CloneModal
        cloneMutation={CREATE_PRICE_LIST}
        mutationDataName="createPriceList"
        dataName="priceList"
        valueToClone={value}
        options={filteredLists || []}
        cloneName="Price List"
        refetch={refetch}
        setParentFieldValue={setFieldValue}
        fieldName={name}
        loading={loading || networkStatus === 4}
        showClone={
          data?.priceLists?.length && value.id !== '' && value.id !== null
        }
        isPriceList
        showDescriptionField
      />
    </div>
  );
}

PriceListDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(PriceListDropdown);
