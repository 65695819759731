import HelpCenter from '../../views/HelpCenter/HelpCenter';

const routes = [
  {
    path: '/getting-started',
    component: HelpCenter,
    sl: true,
    so: true,
  },
];

export default routes;
