import format from 'date-fns/format';

function exportSelectedCsv(gridApi) {
  gridApi.exportDataAsCsv({
    fileName: `Orders_${format(new Date(), 'yyyy-MM-dd_hh:mm:ss')}`,
    onlySelected: true,
    allColumns: true,
    skipColumnGroupHeaders: true,
    processHeaderCallback: (params) => {
      const app = import.meta.env.VITE_APP_NAME;
      const colName = params.column.colDef.headerName || params.column.colId;
      if (
        (app === 'designer_portal' || app === 'admin_portal') &&
        colName === 'Time Due'
      ) {
        return 'dueAt';
      }

      if (
        (app === 'designer_portal' || app === 'admin_portal') &&
        colName === 'Total Units'
      ) {
        return 'Units';
      }

      if (
        (app === 'designer_portal' || app === 'admin_portal') &&
        colName === 'Teeth'
      ) {
        return 'Tooth #';
      }

      if (
        (app === 'designer_portal' || app === 'admin_portal') &&
        colName === 'Design Types'
      ) {
        return 'Design Type';
      }

      return colName;
    },
    processCellCallback: (params) => {
      if (
        params.column.colId === 'preferencesLink' &&
        import.meta.env.VITE_DESIGN_URL
      ) {
        return `${import.meta.env.VITE_DESIGN_URL}/orders/details/${
          params.node.data.hexOrderCode
        }/design-preferences`;
      }

      if (params.column.colId === 'hexOrderCode') {
        return `#${params.value}`;
      }

      if (params.column.colId === 'softwareVersion' && params.value) {
        return params.value.replace('-1', '');
      }

      return params.value;
    },
  });
}

export default exportSelectedCsv;
