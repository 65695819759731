import { flowRight } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
  Suspense,
  memo,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { BaseLoader, DropdownSelect } from '../../../shared';
import OrderActions from '../OrderActions';
import OrdersListGrid from './OrdersListGrid';
import './OrdersListGridContainer.css';
import OrdersListGridStateDropdown from './OrdersListGridStateDropdown';
import DateRangeSelect from '../../../shared/FormFields/DateInputs/DateRangeSelect/index';
import { getRoot } from '../../../../utils/functions';

function OrdersListGridContainer({
  gridOptions,
  t,
  startDate,
  onCalendarFocusChange,
  onCalendarDatesChange,
  selectedState,
  setSelectedState,
  loading,
  selectedRange = 'today',
  orders = null,
  focusedInput = null,
  endDate = null,
  i18n,
  match,
  location,
  tReady,
}) {
  const params = useParams();
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const gridApi = useRef(null);
  const [selectedCases, setSelectedCases] = useState([]);
  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.setGridOption('loading', orders === null);
      if (orders && orders.length === 0) {
        gridApi.current.showNoRowsOverlay();
      } else if (orders && orders.length > 0) {
        gridApi.current.setGridOption('loading', false);
      }
    }
  }, [orders]);

  const getStatesFromLocalStorage = useCallback(
    () => ({
      columnState:
        JSON.parse(localStorage.getItem('agGridColumnState')) || null,
      groupColumnState:
        JSON.parse(localStorage.getItem('agGridGroupColumnState')) || null,
      gridState: JSON.parse(localStorage.getItem('agGridState')) || null,
    }),
    [],
  );

  const saveOnLocalStorage = useCallback((key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  }, []);

  //#region Grid Events
  const onGridReady = useCallback(
    ({ api }) => {
      gridApi.current = api;
      gridApi.current.sizeColumnsToFit();
      const { columnState, groupColumnState } = getStatesFromLocalStorage();
      if (columnState) {
        gridApi.current.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
      if (groupColumnState) {
        gridApi.current.setColumnGroupState(groupColumnState);
      }
    },
    [getStatesFromLocalStorage],
  );
  const onGridSizeChanged = useCallback(() => {
    gridApi.current?.sizeColumnsToFit();
  }, []);
  const onFirstDataRendered = useCallback(() => {
    if (gridApi.current) {
      gridApi.current.sizeColumnsToFit();
      const { columnState, groupColumnState } = getStatesFromLocalStorage();
      if (columnState) {
        gridApi.current.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
      if (groupColumnState) {
        gridApi.current.setColumnGroupState(groupColumnState);
      }
    }
  }, [getStatesFromLocalStorage]);
  const onColumnVisible = useCallback(() => {
    gridApi.current?.sizeColumnsToFit();
  }, []);
  const onDisplayedColumnsChanged = useCallback(() => {
    if (gridApi.current) {
      saveOnLocalStorage('agGridColumnState', gridApi.current.getColumnState());
      saveOnLocalStorage(
        'agGridColumnGroupState',
        gridApi.current.getColumnGroupState(),
      );
    }
  }, [saveOnLocalStorage]);
  const onSelectionChanged = useCallback(() => {
    setSelectedCases(gridApi.current?.getSelectedNodes() || []);
  }, []);
  const saveColumnStates = useCallback(() => {
    if (gridApi.current) {
      const columnState = gridApi.current.getColumnState();
      const groupColumnState = gridApi.current.getColumnGroupState();
      saveOnLocalStorage('agGridColumnState', columnState);
      saveOnLocalStorage('agGridColumnGroupState', groupColumnState);
    }
  }, [saveOnLocalStorage]);
  const onGridPreDestroyed = useCallback(() => {
    if (gridApi.current) {
      saveOnLocalStorage('agGridState', gridApi.current.getState());
    }
    setSelectedCases([]);
  }, [saveOnLocalStorage]);
  //#endregion

  const handleDateRangeChange = useCallback(
    (range) => {
      const start = moment()
        .subtract(range === 'today' ? 0 : range === 'yesterday' ? 1 : 1, 'day')
        .startOf(range === 'last week' ? 'isoWeek' : undefined);
      const end = moment()
        .subtract(range === 'today' ? 0 : range === 'yesterday' ? 1 : 1, 'day')
        .endOf(range === 'last week' ? 'isoWeek' : undefined);

      onCalendarDatesChange({
        startDate: start,
        endDate: end,
        selectedRange: range,
      });
    },
    [onCalendarDatesChange],
  );

  return (
    <>
      <div className="is-flex is-flex-direction-row is-justify-content-flex-end mb-4 is-align-items-center">
        <h6 className="title is-6 mb-0" style={{ textTransform: 'capitalize' }}>
          {!params.status ? (
            <span>
              {t(
                `${selectedRange}${selectedRange !== 'custom' ? "'s " : ' Range '} Cases`,
              )}
            </span>
          ) : (
            <span>{t(params.status.replace(/_/g, ' '))}</span>
          )}
        </h6>
        <div style={{ flex: 1 }} />
        {downloadPercentage > 0 && downloading && (
          <progress
            className={`filesDownloadProgressprogress mr-3 is-small ${downloadPercentage === 100 ? 'is-success' : 'is-primary'}`}
            value={downloadPercentage}
            max="100"
          />
        )}
        {params.root === 'orders' && !params.status && (
          <Suspense fallback={<BaseLoader loading />}>
            <OrdersListGridStateDropdown
              setSelectedState={setSelectedState}
              selectedState={selectedState}
            />
            <DateRangeSelect
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              onCalendarDatesChange={onCalendarDatesChange}
              onCalendarFocusChange={onCalendarFocusChange}
            />
            <div className="buttons has-addons mb-0 mx-4">
              {['today', 'yesterday', 'last week'].map((range) => (
                <button
                  key={range}
                  type="button"
                  className={`button is-small mb-0 ${selectedRange === range ? 'is-active' : ''}`}
                  onClick={() => handleDateRangeChange(range)}
                >
                  {t(range.charAt(0).toUpperCase() + range.slice(1))}
                </button>
              ))}
            </div>
          </Suspense>
        )}
        <DropdownSelect
          className="is-right"
          customOptions
          disabled={selectedCases.length < 1}
          trigger={
            <button type="button" className="button is-outlined is-small">
              <span>{t('caseSelected', { count: selectedCases.length })}</span>
              <span className="icon is-small">
                <i className="bx bx-chevron-down" />
              </span>
            </button>
          }
        >
          <OrderActions
            selectedCases={selectedCases}
            gridApi={gridApi.current}
            params={params}
            list
            details={false}
            downloadPercentage={downloadPercentage}
            updateDownloadProgress={setDownloadPercentage}
            setDownloading={() => setDownloading(true)}
            setDownloadingOff={() => setDownloading(false)}
            i18n={i18n}
            match={match}
            location={location}
            tReady={tReady}
          />
        </DropdownSelect>
      </div>
      <OrdersListGrid
        gridOptions={gridOptions}
        rowData={orders}
        loading={loading}
        onColumnMoved={saveColumnStates}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        onColumnVisible={onColumnVisible}
        onSelectionChanged={onSelectionChanged}
        onDisplayedColumnsChanged={onDisplayedColumnsChanged}
        onColumnGroupOpened={saveColumnStates}
        onGridPreDestroyed={onGridPreDestroyed}
        onColumnResized={saveColumnStates}
      />
    </>
  );
}

OrdersListGridContainer.propTypes = {
  orders: PropTypes.array,
  gridOptions: PropTypes.object.isRequired,
  selectedRange: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  focusedInput: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  onCalendarDatesChange: PropTypes.func.isRequired,
  onCalendarFocusChange: PropTypes.func.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
};

const withParams = (WrappedComponent) => (props) => {
  const params = useParams();
  const root = getRoot();
  const location = useLocation();
  WrappedComponent.displayName = 'WrappedComponent';
  return (
    <WrappedComponent
      match={{ params: { ...params, root } }}
      location={location}
      {...props}
    />
  );
};

withParams.displayName = 'withParams';

export default flowRight(
  withTranslation('manual'),
  withParams,
)(memo(OrdersListGridContainer));
