import ParsingEngine from './ParsingEngine';

const withParsing = (injectedProps) => (WrappedComponent) => {
  function WithParsing(props) {
    return (
      <ParsingEngine
        {...injectedProps}
        {...props}
        render={(rest) => <WrappedComponent {...rest} />}
      />
    );
  }

  return WithParsing;
};

export default withParsing;
