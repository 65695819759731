function hideFCButtonInfo(designType) {
  return new Set([
    'bracketRemoval',
    'clearAlignersAnterior',
    'clearAlignersFullArch',
    'clearAlignerAnteriorRefinement',
    'clearAlignerFullArchRefinement',
    'clearRetainer',
    'guidedImplantSurgery',
    'modelCleanUp',
    'nightguard',
    'splint',
    'orthoStudyModel',
    'retainerModel',
    'sironaSTLConversion',
    'smileDesignVideo',
  ]).has(designType);
}
export default hideFCButtonInfo;
