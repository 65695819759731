import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    name: Yup.string().required('Required'),
    shortName: Yup.string()
      .max(3, 'Short Name must be at most 3 characters')
      .required('Required'),
    administratorLocationId: Yup.string().required('Required'),
    paymentTermId: Yup.string().required('Required'),
    website: Yup.string().url('Must be a valid URL'),
    address: Yup.object({
      address: Yup.string().required('Address is Required'),
      address2: Yup.string(),
      city: Yup.string(),
      region: Yup.string().required('Region is required'),
      postalCode: Yup.string(),
      country: Yup.string().required('Country is required'),
    }),
    language: Yup.string().required('Required'),
    phone: Yup.string(),
    logoUrl: Yup.string().url(),
  });
}

export default schema;
