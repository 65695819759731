function initialValues(props) {
  const { customer, type, debit = {} } = props;

  let orgId;
  let locId;

  if (type === 'location') {
    locId = customer.id;
    orgId = customer.organization.id;
  }

  if (type === 'organization') {
    locId = '';
    orgId = customer.id;
  }

  return {
    id: debit?.id || null,
    amount: debit?.amount || '',
    organizationId: debit?.organizationId || orgId,
    locationId: debit?.locationId || locId,
    reason: debit?.reason || '',
    businessUnitId: debit?.businessUnitId || null,
  };
}

export default initialValues;
