import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

function BeforeAfterButtons(props) {
  const { stages, baDisabled, playing, setPlaying, setTriggerIncrementOn } =
    props;

  const lastVal = stages[stages.length - 1];

  function updateSlider(e) {
    setTriggerIncrementOn({ target: { value: e.target.value } });
  }

  return (
    <div className="beforeAfterWrap">
      <button
        type="button"
        value={0}
        onClick={updateSlider}
        style={{ width: '38%', marginRight: '2%', color: '#fff' }}
        disabled={baDisabled || playing}
        className="beforeAfterButton"
      >
        Before
      </button>
      <div
        style={{
          width: '16%',
          marginRight: '2%',
          marginLeft: '2%',
          display: 'inline-block',
        }}
      >
        {stages.length > 2 && (
          <button
            type="button"
            style={{
              color: '#fff',
              margin: '0 auto',
              width: 36,
              display: 'block',
            }}
            onClick={() => (playing ? setPlaying(false) : setPlaying(true))}
            className="playPauseButton"
          >
            <span className="icon">
              <i className={`bx bx-md ${playing ? 'bxs-pause' : 'bxs-play'}`} />
            </span>
          </button>
        )}
      </div>
      <button
        type="button"
        value={lastVal.index}
        onClick={updateSlider}
        style={{ width: '38%', marginLeft: '2%', marginRight: 0 }}
        disabled={baDisabled || playing}
        className="beforeAfterButton"
      >
        After
      </button>
    </div>
  );
}

BeforeAfterButtons.propTypes = exact({
  stages: PropTypes.array.isRequired,
  baDisabled: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
  setTriggerIncrementOn: PropTypes.func.isRequired,
});

export default BeforeAfterButtons;
