import PropTypes from 'prop-types';
import { useContext } from 'react';
import { DesignGuideOrderContext } from '../../../../Context';
import { ChartSelectionNested } from '../../../../FormFields';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';

function ButtonNestedSelection({ customNested = false }) {
  const { data } = useGuideCodeCardContext();
  const { category, designType } = data;

  const { customerLocation, allowNested, designTypes } = useContext(
    DesignGuideOrderContext,
  );
  const { archChart, toothChart } = designTypes.find(
    (item) => item.id === designType.designTypeId,
  );

  const shouldRender =
    allowNested &&
    ((archChart && category.allowArchSelection) ||
      (toothChart && category.allowToothSelection));

  return shouldRender ? (
    <ChartSelectionNested
      numberingSystem={customerLocation.numberingSystem}
      allowArchSelection={category.allowArchSelection}
      allowToothSelection={category.allowToothSelection}
      currentDesignType={designType}
      guideCode={data}
      customNested={customNested}
    />
  ) : null;
}

ButtonNestedSelection.propTypes = {
  customNested: PropTypes.bool,
};

export default ButtonNestedSelection;
