const rushOrder = {
  admin_portal: {
    visible: true,
    statuses: {
      pending: [0, 10, 11, 20, 30, 40],
      sent_for_design: [0, 10, 11, 20, 30, 40],
      design_in_progress: [0, 10, 11, 20, 30, 40],
    },
  },
  customer_portal: {
    visible: false,
  },
  manuf_portal: {
    visible: false,
  },
  designer_portal: {
    visible: false,
  },
};

export default rushOrder;
