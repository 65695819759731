import OrderShow from '../../views/Orders/OrderShow/OrderShow';

const routes = [
  {
    path: ['/orders/details/:id', '/orders/details/:id/*'],
    component: OrderShow,
  },
];

export default routes;
