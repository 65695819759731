import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import {
  capitalizeFirstLetter,
  transformNestedData,
} from '../../../../transforms';
import { DesignGuideOrderContext } from '../../Context';
import { transformCodeToStoreInFormik } from '../../DesignGuideSelect/components/GuideBody/utils';
import { useGuideCodeCardContext } from '../../DesignGuideSelect/components/GuideCodeCard/context/GuideCodeCardContext';
import ChartSelectionPopup from './ChartSelectionPopup/ChartSelectionPopup';

function ChartSelectionNested({
  numberingSystem,
  allowArchSelection = false,
  allowToothSelection = false,
  currentDesignType,
  guideCode,
  customNested,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { orderItemIndex, designTypes } = useContext(DesignGuideOrderContext);
  const { data } = useGuideCodeCardContext();

  const applyNested = () => {
    const isCurrentNestedData =
      transformNestedData(
        values.orderItems[orderItemIndex][
          allowToothSelection ? 'nestedTeeth' : 'nestedArches'
        ],
      )[data.id]?.length > 0;
    if (!data.isSelected && isCurrentNestedData && !customNested) {
      setFieldValue(`orderItems[${orderItemIndex}].guideCodes`, [
        ...values.orderItems[orderItemIndex].guideCodes,
        transformCodeToStoreInFormik(data),
      ]);
    }
  };

  const modifiedGuideCode = {
    ...guideCode,
    id:
      guideCode.id === 'other'
        ? `${guideCode.id}-${guideCode.guideCategoryId}`
        : guideCode.id,
  };

  const toggleCode = (codeType) => (codeValue) => {
    const codeArray = values.orderItems[orderItemIndex][`${codeType}`];
    if (!codeArray.includes(codeValue)) {
      return null;
    }
    const nestedCodes =
      values.orderItems[orderItemIndex][
        `nested${capitalizeFirstLetter(codeType)}`
      ] || {};

    const existingCodes = nestedCodes[codeValue] || [];

    const updatedCodes = existingCodes.includes(modifiedGuideCode.id)
      ? existingCodes.filter((code) => code !== modifiedGuideCode.id)
      : [...existingCodes, modifiedGuideCode.id];

    const currentNestedCodes = {
      ...nestedCodes,
      [codeValue]: updatedCodes,
    };

    if (updatedCodes.length === 0) {
      delete currentNestedCodes[codeValue];
    }

    return setFieldValue(
      `orderItems[${orderItemIndex}].nested${capitalizeFirstLetter(codeType)}`,
      currentNestedCodes,
    );
  };

  const isEmpty =
    values.orderItems[orderItemIndex][allowToothSelection ? 'teeth' : 'arches']
      ?.length === 0;

  return (
    <ChartSelectionPopup
      isDisabled={!data.id || !data.name || isEmpty}
      isEmpty={isEmpty}
      orderItem={values.orderItems[orderItemIndex]}
      toggleArch={toggleCode('arches')}
      toggleTooth={toggleCode('teeth')}
      numberingSystem={numberingSystem}
      allowToothSelection={allowToothSelection}
      allowArchSelection={allowArchSelection}
      designType={designTypes.find(
        (item) => item.id === currentDesignType.designTypeId,
      )}
      nestedTeeth={values.orderItems[orderItemIndex].nestedTeeth}
      nestedArches={values.orderItems[orderItemIndex].nestedArches}
      guideCodeId={modifiedGuideCode.id}
      nested
      applyNested={applyNested}
    />
  );
}

ChartSelectionNested.propTypes = {
  allowArchSelection: PropTypes.bool,
  allowToothSelection: PropTypes.bool,
  numberingSystem: PropTypes.string.isRequired,
  currentDesignType: PropTypes.object.isRequired,
  guideCode: PropTypes.object.isRequired,
  customNested: PropTypes.bool.isRequired,
};

export default memo(ChartSelectionNested);
