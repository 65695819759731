import { gql } from '@apollo/client';

export default gql`
  query notificationTypes {
    notificationTypes {
      id
      name
      description
    }
    notificationTypeUsers {
      id
      notificationTypeId
      email
    }
  }
`;
