const feedbackPermissions = {
  showFeedback: ({
    state = '',
    allowedStates = [
      'sent_for_approval',
      'needs_approval',
      'ready_for_download',
      'complete',
    ],
    condition = true,
    app = '',
    allowedApps = ['customer_portal', 'admin_portal'],
  }) => {
    const validState = !state || new Set(allowedStates).has(state);
    const validApp = !app || new Set(allowedApps).has(app);

    return validState && validApp && condition;
  },
};

export default feedbackPermissions;
