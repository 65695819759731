import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCustomerTag($input: CustomerTagUpdateInput!) {
    updateCustomerTag(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      customerTag {
        id
        name
        slug
        locations {
          id
          name
          slug
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
