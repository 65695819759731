import { graphql } from '@apollo/client/react/hoc';
import { Log } from '@fullcontour/common';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useState, useEffect, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';

function CaseUploader(props) {
  const {
    render,
    signS3,
    // setValues,
    setFieldValue,
    // values,
    customerLocation,
    parsedCase: { file: caseFile, order },
    file,
    // uploadPercentage,
    setUploading,
    setUploadingOff,
    // uploading,
    updateUploadProgress,
    setFailedUpload,
    removeFailedUpload,
  } = props;
  const [state] = useState({
    formattedFilename: formatFilename(file.name),
  });
  const signal = axios.CancelToken.source();

  useEffect(() => {
    signAndSubmit();
    return () => {
      signal.cancel('Api is being canceled');
    };
  }, []);
  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }
  async function uploadToS3(signedRequest) {
    const options = uploadOptions();
    try {
      setUploading();
      await axios.put(signedRequest, file, options);
      setUploadingOff();
      removeFailedUpload();
    } catch (err) {
      if (axios.isCancel(err)) {
        Log.error('Error: ', err.message);
      } else {
        setFailedUpload();
      }
    }
  }
  function uploadOptions() {
    return {
      headers: {
        'Content-Type': 'binary/octet-stream',
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          updateUploadProgress(100);
        } else {
          updateUploadProgress(percent);
        }
      },
      cancelToken: signal.token,
      axios: {
        retry: { retries: 3 },
      },
    };
  }
  async function signAndSubmit() {
    const { formattedFilename } = state;
    const s3Key = `orders/${customerLocation.id}/${order.intOrderId}/${caseFile.fileType}/${formattedFilename}`;
    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: s3Key,
            acl: 'private',
            filetype: 'binary/octet-stream',
          },
          clientMutationId: uuidv4(),
        },
      },
    });
    const { signedRequest, url } = response.data.signS3.s3Response;
    const { fileUrl, ...restFile } = order.files[0];
    const fileValue = [{ fileUrl: url, s3Key, ...restFile }];
    setFieldValue('files', fileValue);
    await uploadToS3(signedRequest);
  }

  return render({
    signAndSubmit,
    ...props,
  });
}

CaseUploader.propTypes = {
  render: PropTypes.func.isRequired,
  signS3: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  customerLocation: PropTypes.object.isRequired,
  parsedCase: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  uploadPercentage: PropTypes.number.isRequired,
  setUploading: PropTypes.func.isRequired,
  setUploadingOff: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  setFailedUpload: PropTypes.func.isRequired,
  removeFailedUpload: PropTypes.func.isRequired,
};

export default graphql(REQUEST_S3_SIGN, { name: 'signS3' })(memo(CaseUploader));
