import { gql } from '@apollo/client';
import { DebitInfo } from '../fragments';

export default gql`
  query debits($labLocationId: ID, $labOrganizationId: ID) {
    debits(
      labLocationId: $labLocationId
      labOrganizationId: $labOrganizationId
    ) {
      ...DebitInfo
    }
  }
  ${DebitInfo}
`;
