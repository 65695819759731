import { gql } from '@apollo/client';
import { ErrorMessageInfo, ManufacturerOrganizationInfo } from '../fragments';

export default gql`
  mutation createManufacturerOrganization(
    $input: ManufacturerOrganizationCreateInput!
  ) {
    createManufacturerOrganization(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      manufacturerOrganization {
        ...ManufacturerOrganizationInfo
      }
    }
  }
  ${ManufacturerOrganizationInfo}
  ${ErrorMessageInfo}
`;
