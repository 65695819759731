const matchedConvertedFiles = (files, redesignCount) => {
  const isFileCountMatchOrderCount = files.some(
    (file) => file.redesignCount === redesignCount,
  );

  let filesFromRedesignVersion = [];

  if (isFileCountMatchOrderCount) {
    // if it does then we show files which matches to orders redesign count
    filesFromRedesignVersion = files.filter(
      (item) => item.redesignCount === redesignCount,
    );
  } else {
    // otherwise find the latest redesign version of the file
    const highestRedesignCountAvailable = files.reduce(
      (count, file) =>
        file.redesignCount > count ? file.redesignCount : count,
      0,
    );

    // and filter all files according to last redesign count
    filesFromRedesignVersion = files.filter(
      (file) => file.redesignCount === highestRedesignCountAvailable,
    );
  }

  return {
    diffFiles: filesFromRedesignVersion.filter((f) =>
      f.originalFileName.endsWith('.json'),
    ),
    objFiles: filesFromRedesignVersion.filter((f) =>
      f.originalFileName.endsWith('.obj'),
    ),
  };
};

export default matchedConvertedFiles;
