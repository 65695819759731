import { gql } from '@apollo/client';
import { GuideCategoryInfo } from '../fragments';

export default gql`
  query guideCategories {
    guideCategories {
      ...GuideCategoryInfo
    }
  }
  ${GuideCategoryInfo}
`;
