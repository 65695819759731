import { gql } from '@apollo/client';
import { DesignUserInfo } from '../fragments';

export default gql`
  query designUsersByRole($roleLevel: Int!) {
    designUsersByRole(roleLevel: $roleLevel) {
      ...DesignUserInfo
    }
  }
  ${DesignUserInfo}
`;
