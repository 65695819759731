import { gql } from '@apollo/client';

export default gql`
  query customerLocationBilling($slug: String, $id: ID) {
    customerLocation(slug: $slug, id: $id) {
      id
      slug
      locationBilling
      suspended
      name
      code
      billingAddress
      paymentTerm {
        id
        name
        isCreditCard
        termDelay
        invoiceMonthly
      }
      organization {
        id
        name
      }
    }
  }
`;
