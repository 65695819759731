import { gql } from '@apollo/client';

const TurnaroundTimeInfo = gql`
  fragment TurnaroundTimeInfo on TurnaroundTime {
    id
    name
    slug
    description
    hourly
    hourlyHourValue
    dailyDayValue
    dailyTimeValue
    active
    isDefault
    isRush
    key
    value
    text
  }
`;

export default TurnaroundTimeInfo;
