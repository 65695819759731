import { gql } from '@apollo/client';
import { DesignPreferenceInfo } from '../fragments';

export default gql`
  query designPreference($slug: String, $id: ID) {
    designPreference(slug: $slug, id: $id) {
      ...DesignPreferenceInfo
    }
  }
  ${DesignPreferenceInfo}
`;
