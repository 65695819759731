import PropTypes from 'prop-types';

function TruncateText({ text, maxLength = 20 }) {
  if (text.length > maxLength) {
    return (
      <span className="has-tooltip-arrow" data-tooltip={text}>
        {text}
      </span>
    );
  }

  return text;
}

TruncateText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

export default TruncateText;
