import { Field } from 'formik';
import PropTypes from 'prop-types';
import {
  CheckboxField,
  FieldContainer,
  TurnaroundDropdown,
} from '../../../../../shared';

function ShowCaseFields({turnaroundTimes}) {

  return (
    <>
      <FieldContainer name="name" label="Name">
        <Field
          id="name"
          name="name"
          placeholder="Name"
          size="mini"
          type="text"
          className="input is-small"
        />
      </FieldContainer>
      <FieldContainer name="instructions" label="Instructions">
        <Field
          id="instructions"
          name="instructions"
          placeholder="Instructions"
          component="textarea"
          rows="1"
          className="textarea is-small"
        />
      </FieldContainer>
      <Field
        name="requiresDesignApproval"
        required
        component={CheckboxField}
        labelText="Require Design Approval ?"
        headerText="Approval"
        size="is-small"
      />
      <Field
        name="turnaroundTimeId"
        required
        component={TurnaroundDropdown}
        turnaroundTimes={turnaroundTimes}
        size="is-small"
      />
    </>
  );
}

ShowCaseFields.propTypes = {
  turnaroundTimes: PropTypes.array.isRequired,
};

export default ShowCaseFields;
