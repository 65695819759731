import { gql } from '@apollo/client';
import { AdminUserInfo } from '../fragments';

export default gql`
  query adminUser($id: ID!) {
    adminUser(id: $id) {
      ...AdminUserInfo
    }
  }
  ${AdminUserInfo}
`;
