import { useReactiveVar } from '@apollo/client';
import { OverpanelFilesContext, pendingCasesAmount } from '@fullcontour/common';
import { useContext } from 'react';

function TempOrderHeaderHide() {
  const pedningCasesAmount = useReactiveVar(pendingCasesAmount);
  const { toggleOverpanel } = useContext(OverpanelFilesContext);

  return (
    <button
      type="button"
      className="button mr-3 has-text-weight-bold is-size-7 is-dark"
      onClick={() => toggleOverpanel('pending')}
    >
      <span className="icon">
        <i className="bx bx-chevron-down" />
      </span>

      <span>
        Hide
        <span className="tag is-danger ml-2 p-2"> {pedningCasesAmount}</span>
      </span>
    </button>
  );
}

export default TempOrderHeaderHide;
