import PropTypes from 'prop-types';

function FilesGridActions(props) {
  const app = import.meta.env.VITE_APP_NAME;
  const { toggleVersions, toggleAllVersions } = props;

  return app === 'admin_portal' || app === 'designer_portal' ? (
    <button
      type="button"
      className={`button is-small mb-3 is-${
        !toggleVersions ? 'dark' : 'success'
      }`}
      onClick={toggleAllVersions}
    >
      {toggleVersions ? 'Hide Versions' : 'Show Versions'}
    </button>
  ) : null;
}

FilesGridActions.propTypes = {
  toggleVersions: PropTypes.bool.isRequired,
  toggleAllVersions: PropTypes.func.isRequired,
};

export default FilesGridActions;
