import { format, toZonedTime as utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';

function ShowHeaderGeoDataClock(props) {
  const { geoData } = props;

  const date = new Date();
  const timeZone = geoData.timezone;
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'eeee, MMMM do, yyyy h:mm a';
  return format(zonedDate, pattern);
}

ShowHeaderGeoDataClock.propTypes = {
  geoData: PropTypes.object.isRequired,
};

export default ShowHeaderGeoDataClock;
