import { gql } from '@apollo/client';

export default gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      intOrderId
      labLocationId
    }
  }
`;
