import { GET_TURNAROUND_TIMES_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import DataPointWrapper from '../DataPoints/DataPointWrapper';
import { MultiSelect } from '../FormFields';

function DataPointsTurnaroundTimes(props) {
  const { inputParams } = props;
  const app = import.meta.env.VITE_APP_NAME;

  return inputParams.turnaroundTimes && app === 'admin_portal' ? (
    <DataPointWrapper>
      <Field
        name="turnaroundTimeIds"
        required
        component={MultiSelect}
        label="Turnaround Times"
        query={GET_TURNAROUND_TIMES_MULTI}
        dataname="turnaroundTimes"
      />
    </DataPointWrapper>
  ) : null;
}

DataPointsTurnaroundTimes.propTypes = {
  inputParams: PropTypes.object.isRequired,
};

export default DataPointsTurnaroundTimes;
