import PropTypes from 'prop-types';

function imageRenderer({ value }) {
  if (!value) {
    return null;
  }

  return (
    <img
      src={value}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        height: 'auto',
        display: 'inline-block',
      }}
      alt="Custom"
    />
  );
}
imageRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};
export default imageRenderer;
