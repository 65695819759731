import PropTypes from 'prop-types';
import OrderFormPreferences from '../OrderFormPreferences';

function CustomInstructions({ instructions = 'No instructions', orderId }) {
  const app = import.meta.env.VITE_APP_NAME;

  return (
    <>
      <p>{instructions || 'No instructions'}</p>
      {app === 'admin_portal' || app === 'designer_portal' ? (
        <OrderFormPreferences orderId={orderId} />
      ) : null}
    </>
  );
}

CustomInstructions.propTypes = {
  instructions: PropTypes.string,
  orderId: PropTypes.string.isRequired,
};

export default CustomInstructions;
