import { gql } from '@apollo/client';
import { AdminMessageInfo } from '../fragments';

export default gql`
  query adminMessage($slug: String!) {
    adminMessage(slug: $slug) {
      ...AdminMessageInfo
    }
  }
  ${AdminMessageInfo}
`;
