import PropTypes from 'prop-types';
import { Message } from '../../../shared';

function UpdatePasswordSuccess({ success, isNewUser = false }) {
  return success ? (
    <Message type="success" title="Password Update">
      <p>
        Your password change was successful
        {isNewUser ? '. Logging you in, in 3 seconds...' : null}
      </p>
    </Message>
  ) : null;
}

UpdatePasswordSuccess.propTypes = {
  success: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool,
};

export default UpdatePasswordSuccess;
