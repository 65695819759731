import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import isFriday from 'date-fns/isFriday';
import setISODay from 'date-fns/setISODay';
import startOfMonth from 'date-fns/startOfMonth';

const DATE_TEMPLATE = 'MMM dd, yyyy';
function nextBillingDate() {
  if (isFirstDayOfMonth(new Date()) || isFriday(new Date())) {
    return format(new Date(), DATE_TEMPLATE);
  }

  if (isBefore(endOfMonth(new Date()), endOfWeek(new Date()))) {
    return format(startOfMonth(addMonths(new Date(), 1)), DATE_TEMPLATE);
  }

  return format(setISODay(new Date(), 5), DATE_TEMPLATE);
}

export default nextBillingDate;
