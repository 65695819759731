import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation destroyGuideCategoryGroup($input: GuideCategoryGroupDestroyInput!) {
    destroyGuideCategoryGroup(input: $input) {
      clientMutationId
      guideCategoryGroup {
        id
      }
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
