import {
  OverpanelFilesContext,
  OverpanelSubmitContext,
  OverpanelUploadProvider,
} from '@fullcontour/common';
import { withParsing } from '@fullcontour/parsing-engine';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { OverpanelForm } from '../OverpanelForm';
import OverpanelCaseItemLoader from './OverpanelCaseItemLoader';
import useIsMounted from './useIsMounted';

function OverpanelCaseItem({
  file,
  fileIndex,
  customerLocation,
  parsedCase = null,
  labLocationGuideCategoryGroups = null,
}) {
  const { removeFile, files, toggleOverpanel, setParsedCase } = useContext(
    OverpanelFilesContext,
  );

  const { removeSubmitAll } = useContext(OverpanelSubmitContext);

  useEffect(() => {
    if (parsedCase && fileIndex === 0) {
      setParsedCase(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedCase]);

  const isMounted = useIsMounted();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [singleCase, setSingleCase] = useState(false);
  const [failedUpload, setFailedUpload] = useState(false);

  const [submitionIndicator, setSubmitionIndicator] = useState(false);

  const updateUploadProgress = (percentage) => {
    if (isMounted) {
      setUploadPercentage(percentage);
    }
  };

  return (
    <OverpanelUploadProvider
      value={{
        uploadPercentage,
        uploading,
        updateUploadProgress,
        setUploading: () => setUploading(true),
        setSignleCase: () => setSingleCase(true),
        setUploadingOff: () => setUploading(false),
        failedUpload,
        setFailedUpload: () => setFailedUpload(true),
        removeFailedUpload: () => setFailedUpload(false),
        submitionIndicator,
      }}
    >
      {parsedCase ? (
        <OverpanelForm
          file={file}
          files={files}
          submitionIndicator={submitionIndicator}
          labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
          setSubmitionIndicator={setSubmitionIndicator}
          singleCase={singleCase}
          customerLocation={customerLocation}
          fileIndex={fileIndex}
          parsedCase={parsedCase}
          removeFile={removeFile}
          toggleOverpanel={toggleOverpanel}
          removeSubmitAll={() => {
            removeSubmitAll();
            setUploadPercentage(0);
          }}
        />
      ) : (
        <OverpanelCaseItemLoader />
      )}
    </OverpanelUploadProvider>
  );
}

OverpanelCaseItem.propTypes = {
  file: PropTypes.object.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array,
  fileIndex: PropTypes.number.isRequired,
  parsedCase: PropTypes.object,
  customerLocation: PropTypes.object.isRequired,
};

export default withParsing({})(OverpanelCaseItem);
