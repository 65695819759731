import {
  CurrentUserContext,
  orderActionRenderer,
  orderApprovalValueGetter,
  OrdersListQueryWrapper,
  orderStatusRenderer,
  OverpanelFilesContext,
} from '@fullcontour/common';
import { GET_CUSTOMER_ORDERS } from '@fullcontour/shared-api';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

function OrdersList(props) {
  const { currentUser } = useContext(CurrentUserContext);
  const { toggleOverpanel } = useContext(OverpanelFilesContext);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state && state.openOverpanel) {
      toggleOverpanel('pending');
      navigate(pathname, { state: null });
    }
  }, []);

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'Case Details',
        marryChildren: true,
        children: [
          {
            autoHeight: true,
            field: 'hexOrderCode',
            headerName: 'Case ID',
            type: 'orderIdColumn',
          },
          {
            autoHeight: true,
            field: 'intOrderId',
            headerName: 'Filename',
            width: 175,
            minWidth: 175,
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'name',
            headerName: 'Name',
            width: 175,
            minWidth: 175,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Case Items',
        marryChildren: true,
        children: [
          {
            autoHeight: true,
            field: 'itemsMetadata.units',
            headerName: 'Total Units',
            width: 150,
            minWidth: 150,
            type: 'numericColumn',
            filter: 'agTextColumnFilter',
            headerClass: 'ag-left-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            headerTooltip:
              'Here you can see the total units for the case, please make sure that you have selected the total amount of teeth for the specific case',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.design_types',
            headerName: 'Design Types',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.teeth',
            headerName: 'Teeth',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.arches',
            headerName: 'Arches',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.modifiers',
            headerName: 'Modifiers',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.design_guide_codes',
            headerName: 'Design Guide Codes',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            autoHeight: true,
            field: 'itemsMetadata.materials',
            headerName: 'Materials',
            width: 150,
            minWidth: 150,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        autoHeight: true,
        field: 'requiresDesignApproval',
        headerName: 'Approval',
        width: 200,
        minWidth: 200,
        filter: 'agSetColumnFilter',
        suppressSpanHeaderHeight: true,
        columnGroupShow: 'closed',
        valueGetter: orderApprovalValueGetter,
      },
      {
        autoHeight: true,
        field: 'location.name',
        headerName: 'Location',
        hide: currentUser?.scopeToLocation,
        width: 150,
        minWidth: 150,
        filter: 'agSetColumnFilter',
      },
      {
        autoHeight: true,
        field: 'state',
        headerName: 'Status',
        width: 175,
        minWidth: 175,
        filter: 'agSetColumnFilter',
        suppressSpanHeaderHeight: true,
        cellRenderer: orderStatusRenderer,
      },
      {
        autoHeight: true,
        field: 'state',
        headerName: 'Action',
        width: 175,
        minWidth: 175,
        suppressSpanHeaderHeight: true,
        cellRenderer: orderActionRenderer,
      },
      {
        autoHeight: true,
        field: 'turnaroundTimeName',
        headerName: 'Turnaround',
        width: 200,
        minWidth: 200,
        filter: 'agSetColumnFilter',
        suppressSpanHeaderHeight: true,
      },
      {
        autoHeight: true,
        field: 'createdAt',
        headerName: 'Submitted',
        type: 'dateColumn',
        suppressSpanHeaderHeight: true,
      },
    ],
  };

  return (
    <OrdersListQueryWrapper
      gridOptions={gridOptions}
      query={GET_CUSTOMER_ORDERS}
      {...props}
    />
  );
}

export default OrdersList;
