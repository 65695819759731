import * as Yup from 'yup';

function schema({ action }) {
  return Yup.object({
    id: Yup.string().nullable(true),
    amount: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .typeError('Must be a valid Amount'),
    organizationId: Yup.string().required('Required'),
    locationId: Yup.string().required('Required'),
    reason: Yup.string().required('A Reason is Required'),
    ...(action === 'Edit'
      ? {}
      : {
          businessUnitId: Yup.string().required('Business unit is required.'),
        }),
  });
}

export default schema;
