import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsVolumeManufacturer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: false,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'name',
        headerName: 'name',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designedUnits',
        headerName: 'Designed Units',
        minWidth: 150,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'nonDesignedUnits',
        headerName: 'Non-Designed Units',
        minWidth: 150,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'totalUnits',
        headerName: 'Total Units',
        minWidth: 150,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'percentageDesigned',
        headerName: '% Designed',
        minWidth: 150,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `${p.value ? p.value.toFixed(2) : 0}%`,
      },
      {
        field: 'price',
        headerName: 'Price',
        minWidth: 150,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsVolumeManufacturer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsVolumeManufacturer;
