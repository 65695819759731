import { gql } from '@apollo/client';

export default gql`
  mutation destroyPrinter($input: PrinterDestroyInput!) {
    destroyPrinter(input: $input) {
      printer {
        id
      }
    }
  }
`;
