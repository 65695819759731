import NavMenuLink from '../NavMenuLink/NavMenuLink';

function ReportsListNavMenu() {
  return (
    <>
      <NavMenuLink to="/reports/new" name="new report" />
      <NavMenuLink to="/reports" name="saved reports" />
    </>
  );
}

export default ReportsListNavMenu;
