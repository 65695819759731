import PropTypes from 'prop-types';
import LanguageDropdownSignIn from '../../shared/FormFields/Dropdowns/LanguageDropdown/LanguageDropdownSignIn';
import Styles from './SignInLanguageSelect.module.css';

function SignInLanguageSelect({ authState = null }) {
  return authState && authState !== 'signedIn' && authState !== 'loading' ? (
    <div className={Styles.languageSelectContainer}>
      <LanguageDropdownSignIn />
    </div>
  ) : null;
}

SignInLanguageSelect.propTypes = {
  authState: PropTypes.string,
};

export default SignInLanguageSelect;
