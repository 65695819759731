import { gql } from '@apollo/client';

const ApiSettingInfo = gql`
  fragment ApiSettingInfo on ApiSetting {
    id
    active
    termsAccepted
    termsAceptedAt
    labLocationId
    manufacturerOrganizationId
    fcClientId
    fcClientSecret
    clientId
    clientSecret
    authServer
    username
    password
    callbackUrl
    authorizationType
    grantType
    tokenResponseKey
  }
`;

export default ApiSettingInfo;
