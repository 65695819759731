import { gql } from '@apollo/client';
import { SoftwareNodeInfo } from '../fragments';

export default gql`
  query softwareNodes {
    softwareNodes {
      ...SoftwareNodeInfo
    }
  }
  ${SoftwareNodeInfo}
`;
