/* eslint-disable no-lonely-if */
const aggregateInterproximalReductions = ({
  correctItemCount,
  distalReductionValue,
  mesialReductionValue,
  distalReductionValueNext,
  mesialReductionValueNext,
  mesialReductionValueVisible,
  distalReductionValueVisibleNext,
  mesialReductionValueVisibleNext,
  isNextMissing,
  distalReductionValuePrev,
  mesialReductionValuePrev,
  isCurrentMissing,
}) => {
  const borderStyle = '2px solid red';
  const style = {};
  let aggregatedValue;
  if (
    isCurrentMissing &&
    ((correctItemCount >= 1 && correctItemCount <= 8) ||
      (correctItemCount >= 25 && correctItemCount <= 32)) &&
    mesialReductionValuePrev &&
    distalReductionValueNext
  ) {
    aggregatedValue = mesialReductionValuePrev + distalReductionValueNext;
    style.borderRight = borderStyle;
    return {
      style:
        aggregatedValue && aggregatedValue.toFixed(2) >= 0.1 ? style : null,
      aggregatedValue: aggregatedValue
        ? parseFloat(aggregatedValue.toFixed(2))
        : null,
    };
  }

  if (
    ((correctItemCount >= 1 && correctItemCount <= 8) ||
      (correctItemCount >= 25 && correctItemCount <= 32)) &&
    !isNextMissing
  ) {
    if (distalReductionValueNext && !distalReductionValue) {
      if (!mesialReductionValueVisibleNext) {
        style.borderRight = borderStyle;
      }
      if (mesialReductionValue) {
        aggregatedValue = mesialReductionValue + distalReductionValueNext;
      } else {
        if (!mesialReductionValueVisibleNext) {
          aggregatedValue = distalReductionValueNext;
        }
      }
    } else {
      if (mesialReductionValue && mesialReductionValueVisible !== false) {
        if (
          distalReductionValueNext &&
          distalReductionValueVisibleNext !== false
        ) {
          aggregatedValue = mesialReductionValue + distalReductionValueNext;
        } else {
          aggregatedValue = mesialReductionValue;
        }

        style.borderRight = borderStyle;
      }
    }

    if (correctItemCount === 8 || correctItemCount === 25) {
      if (
        mesialReductionValue &&
        mesialReductionValueVisible !== false &&
        mesialReductionValueNext
      ) {
        aggregatedValue =
          mesialReductionValue +
          (mesialReductionValueVisibleNext ||
          mesialReductionValueVisibleNext === undefined
            ? mesialReductionValueNext
            : 0);
        style.borderRight = borderStyle;
      } else {
        style.borderRight = null;
        aggregatedValue = 0;
      }
    }
  } else if (!isNextMissing) {
    if (
      mesialReductionValueNext &&
      !mesialReductionValue &&
      mesialReductionValueVisibleNext &&
      !isCurrentMissing
    ) {
      style.borderRight = borderStyle;
      if (distalReductionValue) {
        aggregatedValue = distalReductionValue + mesialReductionValueNext;
      } else {
        aggregatedValue = mesialReductionValueNext;
      }
    } else {
      if (distalReductionValue) {
        if (mesialReductionValueNext) {
          aggregatedValue = distalReductionValue + mesialReductionValueNext;
        } else {
          if (!mesialReductionValueVisible) {
            aggregatedValue = distalReductionValue;
          }
        }

        if (!mesialReductionValueVisible) {
          style.borderRight = borderStyle;
        }
      }
    }
  } else {
    if (
      distalReductionValuePrev &&
      mesialReductionValueNext &&
      correctItemCount !== 26
    ) {
      aggregatedValue = distalReductionValuePrev + mesialReductionValueNext;
      style.borderRight = borderStyle;
      return {
        style:
          aggregatedValue && aggregatedValue.toFixed(2) >= 0.1 ? style : null,
        aggregatedValue: aggregatedValue
          ? parseFloat(aggregatedValue.toFixed(2))
          : null,
      };
    }

    if (distalReductionValue && mesialReductionValueNext && isNextMissing) {
      aggregatedValue = distalReductionValue + mesialReductionValueNext;
      style.borderRight = borderStyle;
      return {
        style:
          aggregatedValue && aggregatedValue.toFixed(2) >= 0.1 ? style : null,
        aggregatedValue: aggregatedValue
          ? parseFloat(aggregatedValue.toFixed(2))
          : null,
      };
    }
  }

  return {
    style: aggregatedValue && aggregatedValue.toFixed(2) >= 0.05 ? style : null,
    aggregatedValue: aggregatedValue
      ? parseFloat(aggregatedValue.toFixed(2))
      : null,
  };
};

export default aggregateInterproximalReductions;
