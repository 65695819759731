import PropTypes from 'prop-types';

import {
  PreferenceComplaint,
  PreferenceMedicalHistory,
  PreferenceTypeArches,
  PreferenceTypeBite,
  PreferenceTypeCrowding,
  PreferenceTypeInfo,
  PreferenceTypeMidline,
  PreferenceTypeRelation,
  PreferenceTypeSpecifications,
  PreferenceTypeTeeth,
} from './PreferenceTypes';

function Preferences(props) {
  const { prescription, patient } = props;

  return (
    <>
      <div className="columns">
        <PreferenceTypeArches prescription={prescription} />
        <PreferenceTypeCrowding prescription={prescription} />
        <PreferenceTypeSpecifications prescription={prescription} />
        <PreferenceTypeMidline prescription={prescription} />
        <PreferenceTypeRelation prescription={prescription} />
      </div>
      <div className="columns">
        <PreferenceTypeBite prescription={prescription} />
        <PreferenceTypeInfo prescription={prescription} />
        <PreferenceComplaint patient={patient} />
        <PreferenceMedicalHistory patient={patient} />
      </div>
      <div className="columns">
        <PreferenceTypeTeeth prescription={prescription} />
      </div>
    </>
  );
}

Preferences.propTypes = {
  prescription: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};

export default Preferences;
