import { gql } from '@apollo/client';

export default gql`
  query designTypeZooProtocolsDropdown {
    designTypeZooProtocols {
      id
      name
      value
    }
  }
`;
