import PropTypes from 'prop-types';

import { InfoItem } from './InfoItem';

function OrderShowInfoItems({
  order:{orderItems},
  order,
  designTypes,
  assignedManufacturers,
  setFieldValue,
  values
}) {
  return (
    <div className="column">
      <div>
        {orderItems.map((item, index) => (
          <InfoItem
            item={item}
            key={item.id}
            index={index}
            designTypes={[...designTypes]}
            assignedManufacturers={assignedManufacturers}
            setFieldValue={setFieldValue}
            values={values}
            order={order}
          />
        ))}
      </div>
    </div>
  );
}

OrderShowInfoItems.propTypes = {
  order: PropTypes.object.isRequired,
  designTypes: PropTypes.array.isRequired,
};

export default OrderShowInfoItems;
