import { CreditsListQueryWrapper, useCredit } from '../../Credits/CreditsList';

function BillingCredits(props) {
  const {
    selectedItem,
    action,
    credit,
    closeModal,
    openModal,
    destroyCredit,
    statusUpdate,
    onSelectionChanged,
    modalState,
    setSelectedItem,
  } = useCredit();

  return (
    <CreditsListQueryWrapper
      {...props}
      onSelectionChanged={onSelectionChanged}
      openModal={openModal}
      selectedItem={selectedItem}
      action={action}
      modalOpen={modalState}
      credit={credit}
      closeModal={closeModal}
      destroyCredit={destroyCredit}
      statusUpdate={statusUpdate}
      setSelectedItem={setSelectedItem}
    />
  );
}

export default BillingCredits;
