import { gql } from '@apollo/client';

const DesignOrganizationInfo = gql`
  fragment DesignOrganizationInfo on DesignOrganization {
    id
    name
    value
    slug
    email
    createdAt
    updatedAt
    shortName
    language
    logoUrl
    address
    geoData
    administratorLocationIds
    totalLocations
  }
`;

export default DesignOrganizationInfo;
