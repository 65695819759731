import { gql } from '@apollo/client';
import { QuotaProfileInfo } from '../fragments';

export default gql`
  query quotaProfiles {
    quotaProfiles {
      ...QuotaProfileInfo
    }
  }
  ${QuotaProfileInfo}
`;
