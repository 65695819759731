import { gql } from '@apollo/client';
import { DesignTypeInfo } from '../fragments';

export default gql`
  query designTypes {
    designTypes {
      ...DesignTypeInfo
    }
  }
  ${DesignTypeInfo}
`;
