import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { downloadModal, followUpModalActive } from '../../../../config/apollo';
import { statusColor } from '../../../../transforms';

function orderActionRenderer(props) {
  const { data, value, context } = props;

  const { t } = useTranslation('aggrid');

  const { root } = useParams();

  const app = import.meta.env.VITE_APP_NAME;

  const buttonOptions = {
    label: 'Open case details',
    link: `/${root}/details/${data.hexOrderCode}`,
    color: statusColor(value, data.isRush),
  };

  if (value === 'needs_approval') {
    buttonOptions.label = 'Review';
  }

  if (value === 'on_hold') {
    buttonOptions.label = 'Check on hold reason';
    buttonOptions.link = `/${root}/details/${data.hexOrderCode}/${
      app === 'customer_portal' ? 'activity' : 'messages'
    }`;
  }

  if (value === 'sent_for_approval') {
    buttonOptions.label = 'Follow-up';
  }

  if (value === 'ready_for_download') {
    buttonOptions.label = 'Download files';
    buttonOptions.link = `/${root}/details/${data.hexOrderCode}/messages`;
  }

  const invokeParent = () => {
    if (value === 'sent_for_approval') {
      followUpModalActive(data);
    } else if (value !== 'ready_for_download') {
      context.reviewCase(data);
    } else {
      downloadModal(data);
    }
  };

  const elementType =
    value.includes('approval') || value === 'ready_for_download'
      ? 'button'
      : Link;

  const { label, link } = buttonOptions;

  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      {React.createElement(
        elementType,
        {
          className: `button is-small is-dark-light is-fullwidth`,
          ...(elementType !== 'button'
            ? { to: link }
            : { onClick: invokeParent }),
        },
        t(label),
      )}
    </div>
  );
}

orderActionRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};

export default orderActionRenderer;
