/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import OutsideClick from '../../HelperFunctions/OutsideClick';

function DropdownSelect({
  className = '',
  children = null,
  trigger = null,
  disabled = null,
  noPadding = false,
  customOptions = false,
}) {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  const visibilityToggle = () => {
    if (!disabled) {
      setVisible((prev) => !prev);
    }
  };

  const closeDropdown = () => setVisible(false);

  return (
    <OutsideClick fn={closeDropdown}>
      <div
        ref={ref}
        className={`dropdown ${
          window.innerWidth - ref?.current?.getBoundingClientRect().right < 200
            ? 'is-right'
            : ''
        } ${visible ? 'is-active' : ''} ${className}`}
      >
        <div className="dropdown-trigger">
          {React.cloneElement(trigger, {
            'aria-haspopup': 'true',
            'aria-controls': 'dropdown-menu',
            ...(disabled ? { disabled: true } : {}),
            onClick: visibilityToggle,
          })}
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content has-text-left is-clickable">
            {customOptions
              ? children
              : React.Children.toArray(children)
                  .filter(
                    (child) =>
                      React.isValidElement(child) && !child.props.hideElement,
                  )
                  .map((child) => (
                    <a
                      className={`dropdown-item ${noPadding ? 'p-0' : ''}`}
                      onClick={closeDropdown}
                      key={child.key}
                    >
                      {child}
                    </a>
                  ))}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
}

DropdownSelect.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  trigger: PropTypes.node,
  disabled: PropTypes.bool,
  noPadding: PropTypes.bool,
  customOptions: PropTypes.bool,
};

export default DropdownSelect;
