import PropTypes from 'prop-types';

function Tooth22({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const active = designType.toothChart
    ? teeth.find((tooth) => Number(tooth) === 22)
    : lowerArch;
  const variable = designType.toothChart ? 22 : 'lower';
  const selectFunction = designType.toothChart ? toggleTooth : toggleArch;

  const selected = nestedTeeth[22]?.includes(guideCodeId);

  return (
    <>
      <path
        className={`${
          active ? `st1 activeTooth ${selected ? 'toothSelected' : ''}` : 'st1'
        }`}
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        d="M2406.6 4526.2c-57.7-8.1-108.2-8.4-118 35.7l-29.1 86.3c-20.9 65.8 1 52.5 4.5 73.3l43.2 50.7c16.5 16.7 34.3 32.6 73.4 33.4 57.2-2.1 115.1-3 127.4-85.7l4.9-96.6C2501 4577.2 2488 4531.7 2406.6 4526.2L2406.6 4526.2z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2477.5 4603.6c-17 53.7-27.6 115.4-79.8 125.2 -40.6 18.5-82.2 48.4-116.4-12.6"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2300.1 4686.8c20.7 12.3 42.1 26.7 44.8-7.9 3.7-36-11.8-52.6-18.3-78.4"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2441.3 4602.8c-4.8-21.5-20.7-37.9-55.3-45.5"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => selectFunction(variable)}
        className="st1"
        d="M2423.3 4651.1c-11.8 11.2-23.6 2.1-35.4-32.7 -6.2-26.4-17.1-33.6-27.7-42.2"
      />
    </>
  );
}

Tooth22.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.array.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth22;
