import PropTypes from 'prop-types';

function Arches({
  toggleArch,
  upperArch,
  lowerArch,
  nestedArches = null,
  guideCodeId,
}) {
  const selectedUpper = nestedArches.upper?.includes(guideCodeId);
  const selectedLower = nestedArches.lower?.includes(guideCodeId);
  return (
    <>
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => toggleArch('upper')}
        className={`${
          upperArch
            ? `st10 activeTooth ${selectedUpper ? 'upSelected' : ''}`
            : 'st10'
        }`}
        d="M404.9,2673.7h2841.6c0,0-93.3-2225.8-1420.8-2225.8S404.9,2673.7,404.9,2673.7z"
      />
      <path
        cursor="pointer"
        pointerEvents="all"
        onClick={() => toggleArch('lower')}
        className={`${
          lowerArch
            ? `st10 activeTooth ${selectedLower ? 'downSelected' : ''}`
            : 'st10'
        }`}
        d="M1807,4976.1c1327.5,0,1420.8-2225.8,1420.8-2225.8H386.2C386.2,2750.3,479.6,4976.1,1807,4976.1"
      />
    </>
  );
}

Arches.propTypes = {
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  nestedArches: PropTypes.object,
  guideCodeId: PropTypes.string.isRequired,
};

export default Arches;
