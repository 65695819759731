import aggregateInterproximalReductions from './aggregateInterproximalReductions';

const toothNumIdentification = (correctItemCount, shift) =>
  correctItemCount >= 16 ? correctItemCount - shift : correctItemCount + shift;

const headerIPR = (i, headerTableData) => {
  const correctItemCount = i + 1;

  const {
    type,
    distalReductionValue,
    mesialReductionValue,
    distalReductionValueVisible,
    mesialReductionValueVisible,
  } = headerTableData[correctItemCount]?.interproximalReduction || {};

  const {
    distalReductionValue: distalReductionValuePrev,
    mesialReductionValue: mesialReductionValuePrev,
    distalReductionValueVisible: distalReductionValueVisiblePrev,
    mesialReductionValueVisible: mesialReductionValueVisiblePrev,
  } = headerTableData[toothNumIdentification(correctItemCount, -1)]
    ?.interproximalReduction || {};

  const isCurrentMissing = !headerTableData[correctItemCount];
  const isNextMissing =
    !headerTableData[toothNumIdentification(correctItemCount, 1)];

  const {
    distalReductionValue: distalReductionValueNext,
    mesialReductionValue: mesialReductionValueNext,
    distalReductionValueVisible: distalReductionValueVisibleNext,
    mesialReductionValueVisible: mesialReductionValueVisibleNext,
  } = headerTableData[
    isNextMissing
      ? toothNumIdentification(correctItemCount, 2)
      : toothNumIdentification(correctItemCount, 1)
  ]?.interproximalReduction || {};

  const toothNum = toothNumIdentification(correctItemCount, 2);

  const { style, aggregatedValue } = aggregateInterproximalReductions({
    type,
    correctItemCount,
    distalReductionValue,
    mesialReductionValue,
    distalReductionValueNext,
    mesialReductionValueNext,
    distalReductionValueVisible,
    mesialReductionValueVisible,
    distalReductionValueVisibleNext,
    mesialReductionValueVisibleNext,
    distalReductionValuePrev,
    mesialReductionValuePrev,
    distalReductionValueVisiblePrev,
    mesialReductionValueVisiblePrev,
    isNextMissing: isNextMissing && ![1, 16, 17, 32].includes(toothNum),
    isCurrentMissing: isCurrentMissing && ![1, 16, 17, 32].includes(toothNum),
  });

  return { style, aggregatedValue, correctItemCount };
};

export default headerIPR;
