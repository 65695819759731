import { gql } from '@apollo/client';
import { DesignUserInfo } from '../fragments';

export default gql`
  query designUser($id: ID!) {
    designUser(id: $id) {
      ...DesignUserInfo
    }
  }
  ${DesignUserInfo}
`;
