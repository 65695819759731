import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FieldContainer } from '../FieldContainer';
import './PhoneField.styles.css';

const PhoneField = memo(
  ({ required }) => {
    const [field] = useField('phone');
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const { value } = field;

    const handleChange = (newValue) => {
      setFieldTouched('phone', true);
      setFieldValue('phone', newValue);
    };

    return (
      <FieldContainer name="phone" label="phone" required={required}>
        <PhoneInput
          enableAreaCodeStretch
          enableSearch
          disableSearchIcon
          country="us"
          required={required}
          value={value}
          onChange={handleChange}
          inputClass="input"
          inputStyle={{ width: '100%' }}
          inputProps={{
            required,
            name: 'phone',
          }}
        />
      </FieldContainer>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);

PhoneField.propTypes = {
  required: PropTypes.bool,
};

PhoneField.defaultProps = {
  required: false,
};

export default PhoneField;
