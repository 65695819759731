const headerTableDataGenerate = (currentStageTeeth, isTotal) =>
  currentStageTeeth.reduce((obj, item) => {
    const interproximalReduction = item.InterproximalReduction?.Value;
    // eslint-disable-next-line no-param-reassign
    obj[item.Unn] = {
      attachments: item.Attachments,
      interproximalReduction:
        (isTotal && interproximalReduction) || interproximalReduction?.isVisible
          ? {
              type: interproximalReduction.DistalReductionValue ? 'd' : 'm',
              distalReductionValue: interproximalReduction.DistalReductionValue,
              mesialReductionValue: interproximalReduction.MesialReductionValue,
              mesialReductionValueVisible:
                interproximalReduction.MesialReductionValueVisible,
              distalReductionValueVisible:
                interproximalReduction.DistalReductionValueVisible,
            }
          : null,
    };
    return obj;
  }, {});

export default headerTableDataGenerate;
