import { gql } from '@apollo/client';

export default gql`
  query adminLocationsDropdown {
    adminLocations {
      id
      name
      value
    }
  }
`;
