import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { handleTryCatch, UpdateCache } from '../../HelperFunctions';

const CONTAINER = gql`
  mutation container($type: String!) {
    container(type: $type) {
      id
    }
  }
`;

function ConfirmationModal({
  open,
  setModal,
  text = 'Are you sure?',
  data = null,
  clientOnly = false,
  callbacks = [],
  mutation = null,
}) {
  const [performAction, { loading }] = useMutation(
    data ? data.query : CONTAINER,
    {
      update: (cache, { data: response }) => {
        const dataName = response[Object.keys(response)[0]];

        if (!dataName.errorMessages?.length) {
          UpdateCache.filterElementFromCache({
            cache,
            id: data.id,
            field: data.field,
          });
        }
      },
    },
  );

  const handleAction = handleTryCatch(
    async () => {
      if (mutation) {
        return mutation();
      }
      if (!clientOnly) {
        const variables = {
          input: {
            id: data.id,
          },
        };
        const data = await performAction({ variables });
        return data;
      }

      return null;
    },
    {
      successText: data && data.successText,
      callbacks: [() => setModal(false), ...callbacks],
    },
  );
  return (
    <div className={`modal mt-6 ${open ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head pt-5 pb-6">
          <h6 className="title is-6 modal-card-title">{text}</h6>
        </header>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            type="button"
            className="button is-danger is-outlined"
            onClick={() => setModal(false)}
          >
            <span>No</span>
          </button>
          <button
            type="button"
            disabled={loading}
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleAction}
          >
            <span>Yes</span>
          </button>
        </footer>
      </div>
    </div>
  );
}

ConfirmationModal.propTypes = {
  text: PropTypes.string,
  callbacks: PropTypes.array,
  data: PropTypes.object,
  clientOnly: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  mutation: PropTypes.func,
};

export default ConfirmationModal;
