function initialValues(props) {
  const { selectedItem = {} } = props;

  return {
    invoiceId: selectedItem.id,
    creditId: '',
    amountUsed: '',
  };
}

export default initialValues;
