const findCurrentCode = (guideCategory, guideCodes) => {
  const {
    showDropdown,
    id: guideCategoryId,
    guideCodes: categoryGuideCodes,
  } = guideCategory;

  if (showDropdown) {
    const isCardOther = guideCodes?.find(
      (gc) => gc.codeId?.includes('other') && gc.categoryId === guideCategoryId,
    );

    if (isCardOther) {
      const {
        codeId: id,
        codes: [name],
      } = isCardOther;

      return {
        id,
        name,
        description: null,
        imageUrl: null,
        default: false,
        guideCategoryId,
      };
    }

    const currentCode = categoryGuideCodes.find((item) =>
      guideCodes.find((gc) => gc.codeId === item.id),
    );
    return currentCode || {};
  }

  return categoryGuideCodes[0];
};

export default findCurrentCode;
