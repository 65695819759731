function initialValues(props) {
  const { customer, type, creditCard = {} } = props;

  let orgId;
  let locId;

  if (type === 'location') {
    locId = customer.id;
    orgId = customer.organization.id;
  }

  if (type === 'organization') {
    locId = '';
    orgId = customer.id;
  }

  return {
    id: creditCard.id || null,
    nameOnCard: creditCard.nameOnCard || '',
    organizationId: creditCard.organizationId || orgId,
    locationId: creditCard.locationId || locId,
    defaultCard:
      creditCard.defaultCard !== null && creditCard.defaultCard !== undefined
        ? creditCard.defaultCard
        : true,
    expirationDate: creditCard.expirationDate || '',
    brand: creditCard.brand || '',
    cardNumber: creditCard.cardNumber || '',
    cvv: '',
    billingAddress: {
      address: customer.billingAddress.address || '',
      address2: customer.billingAddress.address2 || '',
      city: customer.billingAddress.city || '',
      region: customer.billingAddress.region || '',
      postalCode: customer.billingAddress.postalCode || '',
      country: customer.billingAddress.country || 'US',
    },
  };
}

export default initialValues;
