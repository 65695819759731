import PropTypes from 'prop-types';

function MenuLabelCount({ ordersCount, classProp = null }) {
  return ordersCount > 0 ? (
    <span className={`tag is-pulled-right ${classProp}-light`}>
      {ordersCount}
    </span>
  ) : null;
}

MenuLabelCount.propTypes = {
  classProp: PropTypes.string,
  ordersCount: PropTypes.number.isRequired,
};

export default MenuLabelCount;
