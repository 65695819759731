/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import TabsContext from './TabsContext';

function Tab({
  tabId,
  children = null,
  idx = null,
  isActive = false,
  ...rest
}) {
  const { selectedTabId, setSelectedTabId } = useContext(TabsContext);

  return (
    <li
      className={
        isActive || selectedTabId === tabId || (idx === 0 && !selectedTabId)
          ? 'is-active'
          : ''
      }
      {...rest}
    >
      <a
        role="button"
        data-testid={tabId}
        onClick={() => setSelectedTabId(tabId)}
      >
        {children}
      </a>
    </li>
  );
}

Tab.displayName = 'Tabs.Tab';

Tab.propTypes = {
  tabId: PropTypes.string.isRequired,
  idx: PropTypes.number,
  children: PropTypes.node,
  isActive: PropTypes.bool,
};

export default Tab;
