/* eslint-disable jsx-a11y/control-has-associated-label */
import { BoxIcon, OverpanelUploadContext } from '@fullcontour/common';
import { setNestedObjectValues, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function CaseActionsButtonSubmitInner(props) {
  const { triggerSubmit } = props;
  const { setSignleCase, submitionIndicator } = useContext(
    OverpanelUploadContext,
  );

  const { t } = useTranslation('orders');

  const formik = useFormikContext();

  const triggerHandler = async () => {
    const validationErrors = await formik.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
    if (!Object.values(formik.errors).length) {
      setSignleCase(true);
      triggerSubmit();
    }
  };

  return (
    <button
      type="button"
      data-cy="submit-single-case-btn"
      className={`button is-small px-5 has-tooltip-arrow has-tooltip-left is-primary ${
        submitionIndicator ? 'is-loading' : ''
      }`}
      onClick={triggerHandler}
      data-tooltip={t('Submit this Case')}
    >
      <BoxIcon name="bxs-paper-plane" />
    </button>
  );
}

CaseActionsButtonSubmitInner.propTypes = {
  triggerSubmit: PropTypes.func.isRequired,
};

export default CaseActionsButtonSubmitInner;
