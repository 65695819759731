import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function TempOrderActionsButtonSubmit({ submitAll, setSingleCase }) {
  const { t } = useTranslation('orders');
  const { submitForm } = useFormikContext();

  useEffect(() => {
    if (submitAll === true) {
      submitForm();
    }
  }, [submitAll, submitForm]);

  const submitHandler = (e) => {
    e.preventDefault();

    setSingleCase();
    submitForm();
  };

  return (
    <button
      type="button"
      className="button is-small m-0 px-5 has-tooltip-arrow has-tooltip-left is-primary"
      onClick={submitHandler}
      data-tooltip={t('Submit this Case')}
    >
      <span className="icon">
        <i className={`bx ${'bxs-paper-plane'}`} />
      </span>
    </button>
  );
}

TempOrderActionsButtonSubmit.propTypes = {
  setSingleCase: PropTypes.func.isRequired,
  submitAll: PropTypes.bool.isRequired,
};

export default TempOrderActionsButtonSubmit;
