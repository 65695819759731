import { gql } from '@apollo/client';

export default gql`
  query designUsersAdmin {
    designUsersAdmin {
      id
      attributes
    }
  }
`;
