import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createOrderMessage($input: OrderMessageCreateInput!) {
    createOrderMessage(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderMessage {
        id
        message
        imageUrl
        userName
        fileType
        s3Id
        createdAt
        images {
          createdAt
          id
          imageUrl
          updatedAt
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
