import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../Dropdown';
import timeOptions from './timeOptions';

function TimeDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  label = 'Time',
}) {
  const { t } = useTranslation('formfields');

  function handleChange(e) {
    setFieldValue(name, e.target.value);
  }

  return (
    <Dropdown
      name={name}
      label={label || name}
      error={!!(errors[name] && touched[name])}
      options={timeOptions}
      onChange={handleChange}
      placeholder={`${t('Select a Time')}...`}
      value={value}
    />
  );
}

TimeDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

export default memo(TimeDropdown);
