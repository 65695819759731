import { gql } from '@apollo/client';

const CreditInfo = gql`
  fragment CreditInfo on Credit {
    id
    creditNumber
    hexCreditNumber
    organizationId
    organization {
      id
      name
      slug
    }
    locationId
    location {
      id
      name
      slug
    }
    creditTransactions {
      id
      createdAt
      amountUsed
      invoice {
        id
        hexInvoiceNumber
      }
    }
    applied
    reason
    amount
    expiredAt
    expired
    remainingBalance
    createdAt
    updatedAt
  }
`;

export default CreditInfo;
