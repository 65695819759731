function BusinessAssociatesAgreement() {
  return (
    <>
      <p>
        By clicking accept below, you agree to be bound to the terms and
        conditions set forth in this Business Association Agreement
        (&quot;Agreement&quot;) governing the use and disclosure of Protected
        Health Information you provide, as a HIPAA Covered Entity, Business
        Associate of a HIPAA Covered Entity, or Subcontractor of a Business
        Associate to 3Shape Design ServiceS, LLC f/k/a FullContour, LLC, an
        Arizona limited liability company (&quot;The Company&quot;). This
        Agreement is effective immediately.
        <br />
        <br />
        From time to time herein, (i) Covered Entity, Business Associate, and
        Subcontractor shall collectively, and individually be referred to herein
        as &quot;Contracting Entity,&quot; and (ii) the Company, Business
        Associate, and Subcontractor shall collectively, and individually be
        referred to herein as &quot;Downstream Entity.&quot; From time to time
        herein, Contracting Entity and the Company shall collectively be
        referred to as &quot;Parties,&quot; and individually, each is a
        &quot;Party.&quot;
      </p>
      <br />
      <h6 className="title is-6">Recitals</h6>
      <p>
        A. The Company provides certain services (&quot;Services&quot;) to
        Contracting Entity which are outlined in the Company&apos;s Terms and
        Conditions to which this BAA is referenced, or any other written
        agreement between the parties to which this BAA is referenced, as
        applicable (&quot;Underlying Agreement&quot;).
        <br />
        <br />
        B. Contracting Entity may make available and/or transfer to Downstream
        Entity Protected Health Information (&quot;PHI&quot;) in conjunction
        with the Services, which Downstream Entity will use or disclose in the
        course of providing Services for Contracting Entity in accordance with
        this Agreement.
        <br />
        <br />
        NOW THEREFORE, in consideration of the Parties&apos; continuing
        obligations under the Health Insurance Portability and Accountability
        Act of 1996 and the regulations promulgated thereunder regarding privacy
        and security of PHI, and other good and valuable consideration, the
        receipt and sufficiency of which is hereby acknowledged, the Parties
        agree to the provisions of this Agreement as follows:
      </p>
      <br />
      <h6 className="title is-6">Agreement</h6>
      <p>
        The Parties agree to the terms and conditions of this Agreement in order
        to comply with the rules on handling of PHI under the HIPAA Standards
        for Privacy of Individually Identifiable Health Information, 45 C.F.R.
        Part 160 and Part 164, Subpart E (&quot;Privacy Standards&quot;), the
        HIPAA Security Standards, 45 C.F.R. Part 160 and Part 164, Subpart C
        (&quot;Security Standards&quot;), and the HIPAA Breach Notification
        Regulations, 45 C.F.R. Part 164, Subpart D (&quot;Breach Notification
        Regulations&quot;), all as amended from time to time (collectively the
        &quot;Requirements&quot;).
        <br />
        <br />
        Downstream Entity acknowledges and agrees that all Protected Health
        Information that is created or received by Contracting Entity and
        disclosed or made available in any form, including paper record, oral
        communication, audio recording, and electronic media by Contracting
        Entity or its operating units to Downstream Entity or is created or
        received by Downstream Entity on Contracting Entity&apos;s behalf shall
        be subject to this Agreement.
        <br />
        <br />
        1. <b>Definitions.</b> Except as otherwise defined herein, all
        capitalized terms in this Agreement shall have the definitions set forth
        in the current Requirements. In the event of an inconsistency between
        the provisions of this Agreement and mandatory provisions of the
        Requirements, as amended, the Requirements shall control. Where
        provisions of this Agreement are different than those mandated in the
        Requirements, but are nonetheless permitted by the Requirements, the
        provisions of this Agreement shall control.
        <br />
        <br />
        2. <b>Permitted Uses and Disclosures of PHI</b>
        <br />
        <br />
        2.1 Downstream Entity agrees to Use or Disclose PHI only as follows: (i)
        to perform Services as may be set forth in any agreements between the
        Parties, including the Underlying Agreement; (ii) as required by
        applicable law, rule or regulation, or by accrediting or credentialing
        organization to whom Contracting Entity is required to disclose such
        information; (iii) or as otherwise permitted under this Agreement or the
        Requirements. Downstream Entity agrees that it may not Use or Disclose
        Protected Health Information in a manner that would violate Subpart E of
        45 CFR Part 164 if done by Contracting Entity.
        <br />
        <br />
        2.2 Notwithstanding the prohibitions set forth in this Agreement,
        Downstream Entity may Use and Disclose PHI as follows: (i) if necessary,
        for their own proper management and administration or to carry out their
        legal responsibilities, provided that as to any such Disclosure, the
        following requirements are met:
        <br />
        <br />
        2.2.1 The Disclosure is required by law, not merely permitted by law; or
        <br />
        <br />
        2.2.2 Downstream Entity obtains reasonable written assurances from the
        person or party to whom the information is disclosed that it will be
        held confidentially and used or further disclosed only as required by
        law or for the purpose for which it was disclosed to the person or
        party, and the person or party notifies Downstream Entity of any
        instances of which it is aware in which the confidentiality of the
        information has been breached.
        <br />
        <br />
        2.3 Downstream Entity may Use or Disclose PHI for data aggregation
        services, if to be provided by Downstream Entity for the Health Care
        Operations of Contracting Entity pursuant to any agreements between the
        Parties evidencing their business relationship including without
        limitation, the Underlying Agreement. Contracting Entity acknowledges
        and agrees that it maintains no ownership or other rights in the
        aggregated data sets created or maintained by Downstream Entity.
        <br />
        <br />
        3. <b>Obligations and Activities of Downstream Entity</b>
        <br />
        <br />
        3.1 Downstream Entity shall comply with the HIPAA Security Standards,
        including the Security Standards&apos; Administrative, Physical, and
        Technical safeguard requirements to prevent use or disclosure of
        electronic PHI other than as provided for by this Agreement.
        <br />
        <br />
        3.2 Downstream Entity agrees to use appropriate safeguards, and comply
        with Subpart C of 45 CFR Part 164 with respect to electronic protected
        health information, to prevent Use or Disclosure of Protected Health
        Information other than as provided for by this Agreement.
        <br />
        <br />
        3.3 Downstream Entity agrees to mitigate, to the extent practicable, any
        harmful effect that is known to Downstream Entity of a use or disclosure
        of Protected Health Information by Downstream Entity in violation of the
        requirements of this Agreement.
        <br />
        <br />
        3.4 Downstream Entity shall report to Contracting Entity within a
        reasonable timeframe after discovering any unauthorized Use or
        Disclosure of Protected Health, any Security Incident, and any Breach of
        Unsecured PHI. Specifically, the following shall apply:
        <br />
        <br />
        3.4.1 A breach is considered discovered on the first day the Downstream
        Entity knows or should have known about it.
        <br />
        <br />
        3.4.2 In no case shall Downstream Entity notify Contracting Entity of
        any breach later than 24 hours after a breach is discovered.
        <br />
        <br />
        3.4.3 Downstream Entity shall notify the Contracting Entity of any and
        all breaches of Protected Health Information, and provide detailed
        information to Contracting Entity about the breach, along with the names
        and contact information of all individuals whose Protected Health
        Information was involved.
        <br />
        <br />
        3.4.4 For breaches determined to be caused by Downstream Entity, where
        such breaches require notifications to patients or consumers, the cost
        of such breach notifications shall be borne by Downstream Entity.
        <br />
        <br />
        3.5 Downstream Entity agrees, in accordance with 45 CFR
        164.502(e)(1)(ii) and 164.308(b)(2), if applicable, to ensure that any
        Subcontractors that create, receive, maintain, or transmit PHI on behalf
        of Downstream Entity agree to the same restrictions, conditions, and
        requirements that apply to the Downstream Entity with respect to such
        information.
        <br />
        <br />
        3.6 Downstream Entity agrees to apply HIPAA&apos;s Minimum Necessary
        Standard to all Uses, Disclosures, and requests for PHI, and to make
        reasonable efforts to limit the PHI to the minimum necessary to
        accomplish the intended purpose of the Use, Disclosure, or request.
        <br />
        <br />
        3.7 If applicable, Downstream Entity agrees to provide access, at the
        request of Contracting Entity, and in a reasonable time and manner, to
        PHI in a Designated Record Set, to Contracting Entity or, as directed by
        Contracting Entity, to an Individual in order to meet the requirements
        of 45 CFR § 164.524.
        <br />
        <br />
        3.8 If applicable, Downstream Entity agrees to make any amendment(s) to
        PHI in a Designated Record Set that Contracting Entity directs or agrees
        to pursuant to 45 CFR § 164.526 at the request of Contracting Entity or
        an Individual, and in a reasonable time and manner.
        <br />
        <br />
        3.9 Downstream Entity agrees to make internal practices, books, and
        records, including policies and procedures, relating to the Use and
        Disclosure of PHI received from, or created or received by Downstream
        Entity on behalf of Contracting Entity available to the Contracting
        Entity or to the United States Secretary of Health and Human Services or
        designate of the Secretary, in a reasonable time and manner, for
        purposes of the Secretary determining Contracting Entity&apos;s
        compliance with the Requirements.
        <br />
        <br />
        3.10 Downstream Entity agrees (i) to document such Disclosures of PHI
        and information related to such Disclosures as would be required for
        Contracting Entity to respond to a request by an Individual for an
        accounting of disclosures of PHI in accordance with 45 CFR § 164.528;
        and (ii) to provide such accounting to Contracting Entity within a
        reasonable time upon request.
        <br />
        <br />
        3.11 If applicable, Downstream Entity agrees to comply with the
        requirements of the &quot;Red Flags&quot; Rule and implement a compliant
        identity theft prevention program by or before the required &quot;Red
        Flags&quot; Rule compliance date, and ongoing thereafter.
        <br />
        <br />
        4. <b>Termination</b>
        <br />
        <br />
        4.1 Notwithstanding anything in this Agreement to the contrary,
        Contracting Entity may terminate this Agreement effective immediately if
        Downstream Entity has violated any material term of this Agreement.
        <br />
        <br />
        4.2 Notwithstanding anything in this Agreement to the contrary, if
        Contracting Entity reasonably believes Downstream Entity will violate a
        material term of this Agreement, Contracting Entity may terminate this
        Agreement upon ninety (90) days written notice (&quot;Notice
        Period&quot;) if the Downstream Entity fails to provide adequate written
        assurance within the Notice Period that it will not breach the cited
        term.
        <br />
        <br />
        4.3 Upon termination of this Agreement, or upon request of Contracting
        Entity, whichever occurs first, if feasible, Downstream Entity will
        return or destroy all PHI received from Contracting Entity or created,
        maintained, or received by Downstream Entity on behalf of Contracting
        Entity that Downstream Entity still maintains in any form. Downstream
        Entity will retain no copies of the PHI in any form or medium. If such
        return or destruction is not feasible, Downstream Entity will extend the
        protections of this Agreement to the information in perpetuity and limit
        further Uses and Disclosures to those purposes that make the return or
        destruction of the information not feasible.
        <br />
        <br />
        5. <b>Miscellaneous</b>
        <br />
        <br />
        5.1 The Parties to this Agreement do not intend to create any rights in
        any third parties.
        <br />
        <br />
        5.2 This Agreement constitutes the entire agreement between the Parties,
        and supersedes all prior or contemporaneous negotiations or agreements,
        whether oral or written, related to this subject matter.
        <br />
        <br />
        5.3 This Agreement may be amended or modified only in a writing signed
        by the Parties. No Party may assign its respective rights and
        obligations under this Agreement without the prior written consent of
        the other Party.
        <br />
        <br />
        5.4 None of the provisions of this Agreement are intended to create, nor
        will they be deemed to create any relationship between the Parties other
        than that of independent parties contracting with each other solely for
        the purposes of effecting the provisions of this Agreement and any other
        agreements between the Parties evidencing their business relationship.
        <br />
        <br />
        5.5 No change, waiver or discharge of any liability or obligation
        hereunder on any one or more occasions shall be deemed a waiver of
        performance of any continuing or other obligation, or shall prohibit
        enforcement of any obligation, on any other occasion. The Parties agree
        that, in the event that any documentation of the arrangement pursuant to
        which Downstream Entity provides services to Contracting Entity contains
        provisions relating to the Use or Disclosure of PHI which are more
        restrictive than the provisions of this Agreement, the provisions of the
        more restrictive documentation will control. The provisions of this
        Agreement are intended to establish the minimum requirements regarding
        Downstream Entity&apos;s Use and Disclosure of PHI.
        <br />
        <br />
        5.6 The obligations of Downstream Entity under this Section shall
        survive the expiration, termination, or cancellation of this Agreement,
        and/or the business relationship of the parties, and shall continue to
        bind Downstream Entity, its agents, employees, contractors, successors,
        and assigns as set forth herein.
        <br />
        <br />
        5.7 This Agreement shall be governed by the laws of the State of
        Arizona. Legal action or proceeding arising out of or relating to this
        Agreement shall be brought exclusively in a federal or state court of
        competent jurisdiction located in Maricopa County, Arizona, and both
        parties hereby expressly and irrevocably consent to be bound by the
        personal jurisdiction and venue of such courts. In the event that any
        provision of this Agreement is held by a court of competent jurisdiction
        to be invalid or unenforceable, the remainder of the provisions of this
        Agreement will remain in full force and effect.
        <br />
        <br />
        5.8 In the event a Party believes in good faith that any provision of
        this Agreement fails to comply with the then-current Requirements issued
        by HHS, such Party shall notify the other Party in writing, for a period
        of up to thirty days, the Parties shall address in good faith such
        concern and amend the terms of this Agreement, if necessary to bring it
        into compliance. If, after such thirty-day period, the Agreement fails
        to comply with the Requirements, either Party has the right to terminate
        upon written notice to the other Party.
      </p>
    </>
  );
}

export default BusinessAssociatesAgreement;
