import PropTypes from 'prop-types';

function InfoItemUnitPrice(props) {
  const { item } = props;

  return (
    <div className="column">
      <p className="pLabel">Units</p>
      <p>{item.units}</p>
    </div>
  );
}

InfoItemUnitPrice.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InfoItemUnitPrice;
