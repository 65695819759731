import { gql } from '@apollo/client';
import { CadFileInfo } from '../fragments';

export default gql`
  query cadFiles {
    cadFiles {
      ...CadFileInfo
    }
  }
  ${CadFileInfo}
`;
