import {
  AddressFields,
  CommonOrgFields,
  FileDropzone,
  FormGridWrap,
  FormSection,
  LoadingOrError,
  PhoneField,
  WebsiteField,
  withFormWrap,
} from '@fullcontour/common';
import { GET_CUSTOMER_ORG } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import { initialValues, schema, update } from './helpers/Customer';

function OrganizationFormCustomer({
  initialValues = () => {},
  schema = () => {},
  getVariables = () => {},
  skipQuery = () => {},
  match = {},
  separateId = null,
  ...rest
}) {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CUSTOMER_ORG, {
    skip: skipQuery ? skipQuery() : false,
    variables: getVariables ? getVariables() : {},
    fetchPolicy: 'cache-and-network',
  });
  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      delete variables.variables.input.input.id;
    }

    if (match.params.action && match.params.action === 'new') {
    } else {
      update({ variables, ...rest, client });
    }
  }
  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Printers" />;

  return (
    <Formik
      initialValues={initialValues({ data })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <FormGridWrap match={match}>
        <FormSection title="Basic Info">
          <CommonOrgFields />
          <div className="field is-grouped">
            <div className="control is-expanded">
              <WebsiteField />
            </div>
            <div className="control is-expanded">
              <PhoneField />
            </div>
          </div>
          <Field
            name="logoUrl"
            component={FileDropzone}
            labelText="Upload A Logo"
          />
        </FormSection>
        <AddressFields />
      </FormGridWrap>
    </Formik>
  );
}
OrganizationFormCustomer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  getVariables: PropTypes.func,
  skipQuery: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
};
export default flowRight(withFormWrap({ initialValues, schema }))(
  OrganizationFormCustomer,
);
