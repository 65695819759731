import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Feedback from '../../../../shared/Feedback/Feedback';

function ReviewModalHeader({ order }) {
  const { t } = useTranslation('orderactions');

  return (
    <div className="columns" style={{ width: '100%' }}>
      <div className="column">
        <i className="bx bx-check-circle" /> {t('Review Case')} #
        <span style={{ textTransform: 'uppercase' }}>{order.hexOrderCode}</span>
      </div>
      {import.meta.env.VITE_APP_NAME === 'customer_portal' && (
        <div className="column is-flex is-justify-content-end is-align-items-center">
          <Feedback data={order} />
        </div>
      )}
    </div>
  );
}

ReviewModalHeader.propTypes = {
  order: PropTypes.object.isRequired,
};

export default ReviewModalHeader;
