import { gql } from '@apollo/client';
import { ApiSettingInfo, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateApiSetting($input: ApiSettingUpdateInput!) {
    updateApiSetting(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      apiSetting {
        ...ApiSettingInfo
      }
    }
  }
  ${ApiSettingInfo}
  ${ErrorMessageInfo}
`;
