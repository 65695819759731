import { gql } from '@apollo/client';
import { CustomerLocationInfo } from '../fragments';

export default gql`
  mutation destroyCadFile($input: CadFileDestroyInput!) {
    destroyCadFile(input: $input) {
      clientMutationId
      customerLocation {
        ...CustomerLocationInfo
      }
    }
  }
  ${CustomerLocationInfo}
`;
