import { gql } from '@apollo/client';

export default gql`
  mutation createPrinter($input: PrinterBrandCreateInput!) {
    createPrinterBrand(input: $input) {
      errorMessages {
        message
      }
      printerBrand {
        id
        name
        description
        imageUrl
      }
    }
  }
`;
