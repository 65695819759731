import * as Yup from 'yup';

function schema() {
  return Yup.object({
    intOrderId: Yup.string().required(),
    name: Yup.string(),
    turnaroundTimeId: Yup.string().required('Turnaround time is required'),
    instructions: Yup.string(),
    requiresDesignApproval: Yup.boolean().required(),
    software: Yup.string().required(),
    orderItems: Yup.array()
      .of(
        Yup.object({
          designTypeId: Yup.string().min(4).required('Design Type is Required'),
          manufacturerOrganizationId: Yup.string()
            .min(4)
            .required('Destination is Required'),
          units: Yup.number().required(),
          teeth: Yup.array().of(Yup.number()),
          arches: Yup.array().of(Yup.string()),
          guideCodes: Yup.array().of(
            Yup.object({
              categoryId: Yup.string(),
              categoryName: Yup.string(),
              codes: Yup.array(),
            }),
          ),
          orderItemModifiersAttributes: Yup.array().of(
            Yup.object({
              modifierId: Yup.string(),
            }),
          ),
          materialName: Yup.string(),
          splitFile: Yup.boolean().required(),
        }),
      )
      .required(),
    files: Yup.array()
      .of(
        Yup.object({
          fileUrl: Yup.string().required('File Url is required'),
          originalFileName: Yup.string().required(
            'Original file name is required',
          ),
          fileType: Yup.string().required('File Type is required'),
        }),
      )
      .required(),
  });
}

export default schema;
