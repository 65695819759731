import { gql } from '@apollo/client';

export default gql`
  query signupTemplates {
    signupTemplates {
      id
      name
      slug
      createdAt
      updatedAt
      subType
      priceList {
        id
        name
      }
      organization {
        id
        name
      }
      turnaroundTime {
        id
        name
      }
      paymentTerm {
        id
        name
      }
    }
  }
`;
