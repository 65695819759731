import PropTypes from 'prop-types';
import NonFormCheckboxField from '../../../../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';
import PreferenceSection from './PreferenceSection';

function PreferenceTypeSpecification(props) {
  const { prescription } = props;

  return (
    <PreferenceSection title="Treatment Specifications">
      <div className="checkWrap">
        <NonFormCheckboxField
          name="allowIpr"
          readOnly
          checked={prescription.MayPerformIpr}
          labelText="Allow IPR"
        />
      </div>
      <div className="checkWrap">
        <NonFormCheckboxField
          name="allowAttachments"
          readOnly
          checked={prescription.MayPlaceAttachment}
          labelText="Allow Attachments"
        />
      </div>
    </PreferenceSection>
  );
}

PreferenceTypeSpecification.propTypes = {
  prescription: PropTypes.object.isRequired,
};

export default PreferenceTypeSpecification;
