import HeaderActionsMenu from './HeaderActionsMenu';

function HeaderActions(props) {
  return (
    <div className="has-text-right" style={{ verticalAlign: 'middle' }}>
      <HeaderActionsMenu {...props} />
    </div>
  );
}

export default HeaderActions;
