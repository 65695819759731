import { gql } from '@apollo/client';
import { ErrorMessageInfo, InvoiceInfo } from '../fragments';

export default gql`
  mutation createCreditTransaction($input: CreditTransactionCreateInput!) {
    createCreditTransaction(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      invoice {
        ...InvoiceInfo
      }
    }
  }
  ${InvoiceInfo}
  ${ErrorMessageInfo}
`;
