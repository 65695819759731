import { UPDATE_INVOICE_MEMO } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
}) {
  const result = await client.mutate({
    mutation: UPDATE_INVOICE_MEMO,
    variables,
  });
  const { updateInvoiceMemo } = result.data;
  if (updateInvoiceMemo.errorMessages.length) {
    setErrorMessages(updateInvoiceMemo.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    closeModal();
  }
}

export default update;
