import { sendingTempOrders } from '@fullcontour/common';
import PropTypes from 'prop-types';

function TempOrderHeaderSubmitAll(props) {
  const { tempOrders, setSubmitAll, submitAll } = props;

  const submitHandler = () => {
    setSubmitAll();
    sendingTempOrders({
      totalCases: tempOrders.length,
      casesSent: 0,
    });
  };

  return tempOrders.length > 0 && !submitAll ? (
    <button
      type="button"
      className="button is-primary is-size-7 mr-3"
      onClick={submitHandler}
    >
      <span className="icon">
        <i className="bx bxs-paper-plane" />
      </span>
      <span> Send All Cases</span>
    </button>
  ) : null;
}

TempOrderHeaderSubmitAll.propTypes = {
  tempOrders: PropTypes.array.isRequired,
  setSubmitAll: PropTypes.func.isRequired,
  submitAll: PropTypes.bool.isRequired,
};

export default TempOrderHeaderSubmitAll;
