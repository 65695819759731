import PropTypes from 'prop-types';

function OldThreeLoader({ title = '', loading = false, subTitle = null }) {
  return loading ? (
    <div className="loader-wrapper is-active">
      <div className="loader is-loading" />
      <div>
        <p className="loader-text">
          {`Generating ${title}`}
          {subTitle && (
            <>
              <br />
              <small style={{ fontSize: '65%' }}>{subTitle}</small>
            </>
          )}
        </p>
      </div>
    </div>
  ) : null;
}

OldThreeLoader.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  subTitle: PropTypes.string,
};

export default OldThreeLoader;
