import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateOrderApprovalLog($input: OrderApprovalLogUpdateInput!) {
    updateOrderApprovalLog(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderApprovalLog {
        id
        doctorName
        doctorEmail
        doctorRejected
        order {
          id
          communicateAccount {
            id
            isGuided
          }
          finishedScans {
            id
            fileUrl
            s3Key
            ver
            latestVer
            originalFileName
          }
        }
      }
    }
  }
  ${ErrorMessageInfo}
`;
