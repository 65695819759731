const orderPreferencesInitialLayout = (app, modifiers) => {
  const layout = [
    {
      i: 'order_instructions',
      x: 0,
      y: 0,
      w: 2,
      h: 2,
    },
    {
      i: 'order_specific_codes',
      x: 2,
      y: 0,
      w: 2,
      h: 2,
    },
    {
      i: 'custom_instructions',
      x: 0,
      y: 2,
      w: 2,
      h: 2,
    },
    {
      i: 'location_default_codes',
      x: 2,
      y: 2,
      w: 2,
      h: 2,
    },
    {
      i: 'feedback_timeline',
      x: 0,
      y: 4,
      w: 2,
      h: 2,
    },
  ];

  if (app === 'designer_portal') {
    layout.push({
      i: 'order_messages',
      x: 2,
      y: 6,
      w: 2,
      h: 6,
    });
  }

  if (modifiers) {
    layout.push({
      i: 'order_modifiers',
      x: 4,
      y: 4,
      w: 2,
      h: 2,
    });
  }

  return layout;
};

export default orderPreferencesInitialLayout;
