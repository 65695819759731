function statusColor(status, isRush) {
  if (status === 'pending') {
    return 'grey';
  }

  if (status === 'rush_design' || isRush) {
    return 'grey';
  }

  if (status === 'on_hold') {
    return 'danger';
  }

  if (status === 'needs_approval') {
    return 'warning';
  }
  if (status === 'ready_for_download') {
    return 'success';
  }

  return 'info';
}

export default statusColor;
