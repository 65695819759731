import { gql } from '@apollo/client';

export default gql`
  query manufacturerOrganizations {
    manufacturerOrganizations {
      id
      name
      slug
      createdAt
      administratorLocation {
        id
        name
      }
      logoUrl
      shortName
    }
  }
`;
