import { gql } from '@apollo/client';
import { ErrorMessageInfo, SoftwareNodeInfo } from '../fragments';

export default gql`
  mutation updateSoftwareNode($input: SoftwareNodeUpdateInput!) {
    updateSoftwareNode(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      softwareNode {
        ...SoftwareNodeInfo
      }
    }
  }
  ${SoftwareNodeInfo}
  ${ErrorMessageInfo}
`;
