import format from 'date-fns/format';
import timestampFormatter from './timestampFormatter';

function gridDateComparator(filterLocalDateAtMidnight, cellValue) {
  const cellDate = timestampFormatter(cellValue, false);
  const filterDate = format(filterLocalDateAtMidnight, 'MM/dd/yyyy');

  if (cellDate < filterDate) {
    return -1;
  }
  if (cellDate > filterDate) {
    return 1;
  }
  return 0;
}

export default gridDateComparator;
