import PropTypes from 'prop-types';

function GuideCloseBtn({ guideModalClose, helpFuncsData = {} }) {
  return (
    <button
      type="button"
      className="delete"
      aria-label="close"
      onClick={() => {
        helpFuncsData?.helpFuncs?.next();
        guideModalClose();
      }}
    />
  );
}

GuideCloseBtn.propTypes = {
  guideModalClose: PropTypes.func.isRequired,
  helpFuncsData: PropTypes.object,
};

export default GuideCloseBtn;
