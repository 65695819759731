import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().nullable(true),
    amount: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .typeError('Must be a valid Amount'),
    organizationId: Yup.string().required('Required'),
    locationId: Yup.string(),
    reason: Yup.string().required('A Reason is Required'),
  });
}

export default schema;
