import { gql } from '@apollo/client';

export default gql`
  mutation destroyManufacturerUser($input: ManufacturerUserDestroyInput!) {
    destroyManufacturerUser(input: $input) {
      clientMutationId
      user {
        id
      }
    }
  }
`;
