import { gql } from '@apollo/client';
import { OrderFileInfo } from '../fragments';

export default gql`
  query orderFiles($orderId: ID!) {
    orderFiles(orderId: $orderId) {
      ...OrderFileInfo
    }
  }
  ${OrderFileInfo}
`;
