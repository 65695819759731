import PropTypes from 'prop-types';

import AgGridContainer from '../AgGridContainer';
import GridActionsRow from './GridActionsRow';

function GridContainer({
  gridOptions,
  mutliColumn = false,
  children = null,
  actionAlign = 'right',
  rootVal = null,
  typeVal = null,
  to = null,
  content = null,
  shortContainer = false,
  deleteModal = false,
}) {
  return (
    <>
      <GridActionsRow
        mutliColumn={mutliColumn}
        rootVal={rootVal}
        typeVal={typeVal}
        actionAlign={actionAlign}
        to={to}
        content={content}
      >
        {children}
      </GridActionsRow>

      <AgGridContainer
        gridOptions={gridOptions}
        deleteModal={deleteModal}
        shortContainer={shortContainer}
      />
    </>
  );
}

GridContainer.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  children: PropTypes.node,
  mutliColumn: PropTypes.bool,
  rootVal: PropTypes.string,
  typeVal: PropTypes.string,
  actionAlign: PropTypes.string,
  to: PropTypes.string,
  content: PropTypes.string,
  shortContainer: PropTypes.bool,
  deleteModal: PropTypes.bool,
};

export default GridContainer;
