/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

function LocationGuidInfoVideo(props) {
  const { mimized, setMinimized } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  const player = useRef(null);

  const minimizeHandler = () => setMinimized((prev) => !prev);

  const playVideoHandler = () => {
    if (player?.current) {
      const video = player?.current;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
      setIsPlaying((prev) => !prev);
    }
  };
  return (
    <div
      className="pl-0 design-guide__video-wrapper"
      style={
        mimized
          ? {
              width: 500,
            }
          : {}
      }
    >
      <div className="p-1 is-relative">
        <video
          ref={player}
          src="https://fc2-production.s3.us-west-2.amazonaws.com/assets/videos/How+to+Use+Design+Guide+Codes+(4).mp4"
          width="100%"
          preload="auto"
          controls
          style={{ border: '1px solid #dddddd' }}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
        />
        {!isPlaying && (
          <button
            type="button"
            className="button video-play-button is-rounded p-0"
            onClick={playVideoHandler}
          >
            <img
              src="https://cdn.fullcontour.com/icons/video-play-button.png"
              alt="Video play button"
            />
          </button>
        )}

        <button
          onClick={minimizeHandler}
          type="button"
          className="button is-primary is-light minimize-video-btn"
        >
          {mimized ? 'Expand' : 'Minimize'}
        </button>
      </div>
    </div>
  );
}

LocationGuidInfoVideo.propTypes = {
  mimized: PropTypes.bool.isRequired,
  setMinimized: PropTypes.func.isRequired,
};

export default LocationGuidInfoVideo;
