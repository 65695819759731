import NavMenuLink from '../NavMenuLink/NavMenuLink';

function ProfileListNavMenu() {
  return (
    <>
      <NavMenuLink to="/profile" name="basic information" />
      <NavMenuLink to="/profile/update-password" name="update password" />
      <NavMenuLink to="/profile/notifications" name="notifications" />
    </>
  );
}

export default ProfileListNavMenu;
