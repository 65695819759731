import { gql } from '@apollo/client';
import { ErrorMessageInfo, OrderHoldReasonInfo } from '../fragments';

export default gql`
  mutation updateOrderHoldReason($input: OrderHoldReasonUpdateInput!) {
    updateOrderHoldReason(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      orderHoldReason {
        ...OrderHoldReasonInfo
      }
    }
  }
  ${OrderHoldReasonInfo}
  ${ErrorMessageInfo}
`;
